<script setup lang="ts">
import { phCaretLeft, phCaretRight, phUserBold } from '@/phosphor-icons';
import { useOnboardingStore } from '@/stores/onboarding';
import { useQuasar } from 'quasar';
import AskForHelp from './AskForHelp.vue';

const onboardingStore = useOnboardingStore();
const $q = useQuasar();

const askForHelp = () => {
  $q.dialog({
    component: AskForHelp,
  });
};
</script>

<template>
  <q-footer class="tw-bg-light-shade-2 tw-p-4">
    <q-toolbar>
      <q-toolbar-title v-if="!onboardingStore.hideHelpRequest">
        <div class="tw-flex tw-flex-row tw-items-center">
          <q-icon :name="phUserBold" class="tw-text-xl tw-text-primary-dark" />
          <button
            type="button"
            class="tw-ml-2 tw-text-lg tw-font-bold tw-leading-6 tw-text-primary-dark"
            @click="askForHelp"
          >
            Ask for Help
          </button>
        </div>
        <div class="tw-text-xs tw-font-semibold tw-text-dark-shade-3">
          Don't know how to do this? Invite someone from your business to help
        </div>
      </q-toolbar-title>
      <div class="tw-ml-auto">
        <q-btn
          @click="onboardingStore.onPrevious"
          color="primary"
          :icon="phCaretLeft"
          label="Previous"
          :ripple="false"
          no-caps
          outline
          unelevated
          v-if="onboardingStore.onPrevious"
          class="tw-text-lg tw-font-bold tw-leading-6"
        />
        <q-btn
          @click="onboardingStore.onNext"
          :loading="onboardingStore.isUpdating"
          type="submit"
          color="primary"
          :icon-right="phCaretRight"
          label="Next"
          :ripple="false"
          no-caps
          unelevated
          class="tw-ml-4 tw-text-lg tw-font-bold tw-leading-6"
        />
      </div>
    </q-toolbar>
  </q-footer>
</template>

<style scoped lang="postcss">
:deep(.q-btn .q-icon) {
  @apply !tw-text-xl;
}
</style>
