import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';

export const CampaignRoutes: RouteRecordRaw = {
  path: '/campaigns/',
  name: RouteName.CampaignsV1,
  component: () => import('../views/Campaigns/CampaignsV1.vue'),
  redirect: { name: RouteName.CampaignsList },
  meta: {
    title: 'Campaigns',
    group: RouteName.CampaignsV1,
    layout: Layout.Authenticated,
  },
  children: [
    {
      path: 'list',
      name: RouteName.CampaignsList,
      component: () => import('../views/Campaigns/CampaignListV2.vue'),
      meta: {
        title: 'Campaign-list',
      },
    },
    {
      path: 'templates',
      name: RouteName.CampaignTemplates,
      component: () => import('../views/Campaigns/CampaignTemplates.vue'),
      meta: {
        title: 'Campaign-templates',
      },
    },
    {
      path: 'docs',
      name: RouteName.CampaignDocuments,
      component: () => import('../views/Campaigns/CampaignDocuments.vue'),
      meta: {
        title: 'Campaign-docs',
      },
    },
  ],
};
