/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateDomainDto } from '../model/';
// @ts-ignore
import { Domain } from '../model/';
// @ts-ignore
import { ResponseObject } from '../model/';
// @ts-ignore
import { UpdateDomainDto } from '../model/';
/**
 * DomainApi - axios parameter creator
 * @export
 */
export const DomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Domain
         * @param {CreateDomainDto} createDomainDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerCreate: async (createDomainDto: CreateDomainDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDomainDto' is not null or undefined
            assertParamExists('domainsControllerCreate', 'createDomainDto', createDomainDto)
            const localVarPath = `/api/Domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDomainDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Domain
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Domain by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domainsControllerFindOne', 'id', id)
            const localVarPath = `/api/Domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save Domain with ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerSave: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domainsControllerSave', 'id', id)
            const localVarPath = `/api/Domains/save/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Domain by Id
         * @param {string} id 
         * @param {UpdateDomainDto} updateDomainDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerUpdate: async (id: string, updateDomainDto: UpdateDomainDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domainsControllerUpdate', 'id', id)
            // verify required parameter 'updateDomainDto' is not null or undefined
            assertParamExists('domainsControllerUpdate', 'updateDomainDto', updateDomainDto)
            const localVarPath = `/api/Domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDomainDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save Domain as default by ID
         * @param {string} id 
         * @param {boolean} defaultDomain 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerUpdateDefaultDomain: async (id: string, defaultDomain: boolean, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domainsControllerUpdateDefaultDomain', 'id', id)
            // verify required parameter 'defaultDomain' is not null or undefined
            assertParamExists('domainsControllerUpdateDefaultDomain', 'defaultDomain', defaultDomain)
            const localVarPath = `/api/Domains/set-default/{id}/{defaultDomain}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"defaultDomain"}}`, encodeURIComponent(String(defaultDomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Domain by Id
         * @param {string} id 
         * @param {string} domainId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerValidate: async (id: string, domainId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domainsControllerValidate', 'id', id)
            // verify required parameter 'domainId' is not null or undefined
            assertParamExists('domainsControllerValidate', 'domainId', domainId)
            const localVarPath = `/api/Domains/validate/{domainId}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"domainId"}}`, encodeURIComponent(String(domainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainApi - functional programming interface
 * @export
 */
export const DomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Domain
         * @param {CreateDomainDto} createDomainDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsControllerCreate(createDomainDto: CreateDomainDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsControllerCreate(createDomainDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Domain
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Domain>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Domain by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save Domain with ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsControllerSave(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsControllerSave(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Domain by Id
         * @param {string} id 
         * @param {UpdateDomainDto} updateDomainDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsControllerUpdate(id: string, updateDomainDto: UpdateDomainDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsControllerUpdate(id, updateDomainDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save Domain as default by ID
         * @param {string} id 
         * @param {boolean} defaultDomain 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsControllerUpdateDefaultDomain(id: string, defaultDomain: boolean, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsControllerUpdateDefaultDomain(id, defaultDomain, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate Domain by Id
         * @param {string} id 
         * @param {string} domainId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsControllerValidate(id: string, domainId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsControllerValidate(id, domainId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DomainApi - factory interface
 * @export
 */
export const DomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Domain
         * @param {CreateDomainDto} createDomainDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerCreate(createDomainDto: CreateDomainDto, xTenantId?: string, options?: any): AxiosPromise<Domain> {
            return localVarFp.domainsControllerCreate(createDomainDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Domain
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Domain>> {
            return localVarFp.domainsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Domain by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Domain> {
            return localVarFp.domainsControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save Domain with ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerSave(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.domainsControllerSave(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Domain by Id
         * @param {string} id 
         * @param {UpdateDomainDto} updateDomainDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerUpdate(id: string, updateDomainDto: UpdateDomainDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.domainsControllerUpdate(id, updateDomainDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save Domain as default by ID
         * @param {string} id 
         * @param {boolean} defaultDomain 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerUpdateDefaultDomain(id: string, defaultDomain: boolean, xTenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.domainsControllerUpdateDefaultDomain(id, defaultDomain, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Domain by Id
         * @param {string} id 
         * @param {string} domainId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsControllerValidate(id: string, domainId: string, xTenantId?: string, options?: any): AxiosPromise<ResponseObject> {
            return localVarFp.domainsControllerValidate(id, domainId, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for domainsControllerCreate operation in DomainApi.
 * @export
 * @interface DomainApiDomainsControllerCreateRequest
 */
export interface DomainApiDomainsControllerCreateRequest {
    /**
     * 
     * @type {CreateDomainDto}
     * @memberof DomainApiDomainsControllerCreate
     */
    readonly createDomainDto: CreateDomainDto

    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for domainsControllerFindAll operation in DomainApi.
 * @export
 * @interface DomainApiDomainsControllerFindAllRequest
 */
export interface DomainApiDomainsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for domainsControllerFindOne operation in DomainApi.
 * @export
 * @interface DomainApiDomainsControllerFindOneRequest
 */
export interface DomainApiDomainsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for domainsControllerSave operation in DomainApi.
 * @export
 * @interface DomainApiDomainsControllerSaveRequest
 */
export interface DomainApiDomainsControllerSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerSave
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerSave
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for domainsControllerUpdate operation in DomainApi.
 * @export
 * @interface DomainApiDomainsControllerUpdateRequest
 */
export interface DomainApiDomainsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateDomainDto}
     * @memberof DomainApiDomainsControllerUpdate
     */
    readonly updateDomainDto: UpdateDomainDto

    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for domainsControllerUpdateDefaultDomain operation in DomainApi.
 * @export
 * @interface DomainApiDomainsControllerUpdateDefaultDomainRequest
 */
export interface DomainApiDomainsControllerUpdateDefaultDomainRequest {
    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerUpdateDefaultDomain
     */
    readonly id: string

    /**
     * 
     * @type {boolean}
     * @memberof DomainApiDomainsControllerUpdateDefaultDomain
     */
    readonly defaultDomain: boolean

    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerUpdateDefaultDomain
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for domainsControllerValidate operation in DomainApi.
 * @export
 * @interface DomainApiDomainsControllerValidateRequest
 */
export interface DomainApiDomainsControllerValidateRequest {
    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerValidate
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerValidate
     */
    readonly domainId: string

    /**
     * 
     * @type {string}
     * @memberof DomainApiDomainsControllerValidate
     */
    readonly xTenantId?: string
}

/**
 * DomainApi - object-oriented interface
 * @export
 * @class DomainApi
 * @extends {BaseAPI}
 */
export class DomainApi extends BaseAPI {
    /**
     * 
     * @summary Create Domain
     * @param {DomainApiDomainsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainsControllerCreate(requestParameters: DomainApiDomainsControllerCreateRequest, options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration).domainsControllerCreate(requestParameters.createDomainDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Domain
     * @param {DomainApiDomainsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainsControllerFindAll(requestParameters: DomainApiDomainsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration).domainsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Domain by Id
     * @param {DomainApiDomainsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainsControllerFindOne(requestParameters: DomainApiDomainsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration).domainsControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save Domain with ID
     * @param {DomainApiDomainsControllerSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainsControllerSave(requestParameters: DomainApiDomainsControllerSaveRequest, options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration).domainsControllerSave(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Domain by Id
     * @param {DomainApiDomainsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainsControllerUpdate(requestParameters: DomainApiDomainsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration).domainsControllerUpdate(requestParameters.id, requestParameters.updateDomainDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save Domain as default by ID
     * @param {DomainApiDomainsControllerUpdateDefaultDomainRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainsControllerUpdateDefaultDomain(requestParameters: DomainApiDomainsControllerUpdateDefaultDomainRequest, options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration).domainsControllerUpdateDefaultDomain(requestParameters.id, requestParameters.defaultDomain, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate Domain by Id
     * @param {DomainApiDomainsControllerValidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainsControllerValidate(requestParameters: DomainApiDomainsControllerValidateRequest, options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration).domainsControllerValidate(requestParameters.id, requestParameters.domainId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
