/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppObject1ToObject2Map } from '../model/';
// @ts-ignore
import { CreateAppObject1ToObject2MapDto } from '../model/';
/**
 * AppObject1ToObject2MapApi - axios parameter creator
 * @export
 */
export const AppObject1ToObject2MapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create App Object 1 To Object 2 Map
         * @param {CreateAppObject1ToObject2MapDto} createAppObject1ToObject2MapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerCreate: async (createAppObject1ToObject2MapDto: CreateAppObject1ToObject2MapDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppObject1ToObject2MapDto' is not null or undefined
            assertParamExists('appObject1ToObject2MapControllerCreate', 'createAppObject1ToObject2MapDto', createAppObject1ToObject2MapDto)
            const localVarPath = `/api/app-object1-to-object2-map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppObject1ToObject2MapDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of App Object1 To Object2 Maps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-object1-to-object2-map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of App Object1 To Object2 Maps by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerFindAllByApp: async (appName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('appObject1ToObject2MapControllerFindAllByApp', 'appName', appName)
            const localVarPath = `/api/app-object1-to-object2-map/by-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appName !== undefined) {
                localVarQueryParameter['app_name'] = appName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove AppObject1ToObject2Map Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appObject1ToObject2MapControllerRemove', 'id', id)
            const localVarPath = `/api/app-object1-to-object2-map/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppObject1ToObject2MapApi - functional programming interface
 * @export
 */
export const AppObject1ToObject2MapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppObject1ToObject2MapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create App Object 1 To Object 2 Map
         * @param {CreateAppObject1ToObject2MapDto} createAppObject1ToObject2MapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObject1ToObject2MapControllerCreate(createAppObject1ToObject2MapDto: CreateAppObject1ToObject2MapDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppObject1ToObject2Map>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObject1ToObject2MapControllerCreate(createAppObject1ToObject2MapDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of App Object1 To Object2 Maps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObject1ToObject2MapControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppObject1ToObject2Map>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObject1ToObject2MapControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of App Object1 To Object2 Maps by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObject1ToObject2MapControllerFindAllByApp(appName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppObject1ToObject2Map>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObject1ToObject2MapControllerFindAllByApp(appName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove AppObject1ToObject2Map Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObject1ToObject2MapControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppObject1ToObject2Map>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObject1ToObject2MapControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppObject1ToObject2MapApi - factory interface
 * @export
 */
export const AppObject1ToObject2MapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppObject1ToObject2MapApiFp(configuration)
    return {
        /**
         * 
         * @summary Create App Object 1 To Object 2 Map
         * @param {CreateAppObject1ToObject2MapDto} createAppObject1ToObject2MapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerCreate(createAppObject1ToObject2MapDto: CreateAppObject1ToObject2MapDto, xTenantId?: string, options?: any): AxiosPromise<AppObject1ToObject2Map> {
            return localVarFp.appObject1ToObject2MapControllerCreate(createAppObject1ToObject2MapDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of App Object1 To Object2 Maps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AppObject1ToObject2Map>> {
            return localVarFp.appObject1ToObject2MapControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of App Object1 To Object2 Maps by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerFindAllByApp(appName: string, xTenantId?: string, options?: any): AxiosPromise<Array<AppObject1ToObject2Map>> {
            return localVarFp.appObject1ToObject2MapControllerFindAllByApp(appName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove AppObject1ToObject2Map Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObject1ToObject2MapControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<AppObject1ToObject2Map> {
            return localVarFp.appObject1ToObject2MapControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appObject1ToObject2MapControllerCreate operation in AppObject1ToObject2MapApi.
 * @export
 * @interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerCreateRequest
 */
export interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerCreateRequest {
    /**
     * 
     * @type {CreateAppObject1ToObject2MapDto}
     * @memberof AppObject1ToObject2MapApiAppObject1ToObject2MapControllerCreate
     */
    readonly createAppObject1ToObject2MapDto: CreateAppObject1ToObject2MapDto

    /**
     * 
     * @type {string}
     * @memberof AppObject1ToObject2MapApiAppObject1ToObject2MapControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appObject1ToObject2MapControllerFindAll operation in AppObject1ToObject2MapApi.
 * @export
 * @interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllRequest
 */
export interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appObject1ToObject2MapControllerFindAllByApp operation in AppObject1ToObject2MapApi.
 * @export
 * @interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllByAppRequest
 */
export interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllByAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllByApp
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllByApp
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appObject1ToObject2MapControllerRemove operation in AppObject1ToObject2MapApi.
 * @export
 * @interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerRemoveRequest
 */
export interface AppObject1ToObject2MapApiAppObject1ToObject2MapControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObject1ToObject2MapApiAppObject1ToObject2MapControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppObject1ToObject2MapApiAppObject1ToObject2MapControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * AppObject1ToObject2MapApi - object-oriented interface
 * @export
 * @class AppObject1ToObject2MapApi
 * @extends {BaseAPI}
 */
export class AppObject1ToObject2MapApi extends BaseAPI {
    /**
     * 
     * @summary Create App Object 1 To Object 2 Map
     * @param {AppObject1ToObject2MapApiAppObject1ToObject2MapControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObject1ToObject2MapApi
     */
    public appObject1ToObject2MapControllerCreate(requestParameters: AppObject1ToObject2MapApiAppObject1ToObject2MapControllerCreateRequest, options?: AxiosRequestConfig) {
        return AppObject1ToObject2MapApiFp(this.configuration).appObject1ToObject2MapControllerCreate(requestParameters.createAppObject1ToObject2MapDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of App Object1 To Object2 Maps
     * @param {AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObject1ToObject2MapApi
     */
    public appObject1ToObject2MapControllerFindAll(requestParameters: AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppObject1ToObject2MapApiFp(this.configuration).appObject1ToObject2MapControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of App Object1 To Object2 Maps by App
     * @param {AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllByAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObject1ToObject2MapApi
     */
    public appObject1ToObject2MapControllerFindAllByApp(requestParameters: AppObject1ToObject2MapApiAppObject1ToObject2MapControllerFindAllByAppRequest, options?: AxiosRequestConfig) {
        return AppObject1ToObject2MapApiFp(this.configuration).appObject1ToObject2MapControllerFindAllByApp(requestParameters.appName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove AppObject1ToObject2Map Against ID
     * @param {AppObject1ToObject2MapApiAppObject1ToObject2MapControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObject1ToObject2MapApi
     */
    public appObject1ToObject2MapControllerRemove(requestParameters: AppObject1ToObject2MapApiAppObject1ToObject2MapControllerRemoveRequest, options?: AxiosRequestConfig) {
        return AppObject1ToObject2MapApiFp(this.configuration).appObject1ToObject2MapControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
