<script setup lang="ts">
import { IMoreDetailsElement } from '@/types/dashboard';
import { computed } from 'vue';
import MoreDetailsItemElement from '../MoreDetailsItemElement.vue';

const props = defineProps<{ items: IMoreDetailsElement[] }>();

const colSpan = computed(() => {
  const length = props.items?.length || 0;
  return `tw-grid-cols-${length}`;
});
</script>

<template>
  <div class="tw-flex tw-rounded tw-bg-light-shade-1 tw-drop-shadow">
    <div class="tw-grid tw-grow tw-divide-gray-100 tw-self-center md:tw-divide-x" :class="colSpan">
      <MoreDetailsItemElement v-for="(item, index) in props.items" :key="index" :data="item" class="tw-col-span-1" />
    </div>
  </div>
</template>
