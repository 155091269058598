import { defineStore } from 'pinia';
import { ref } from 'vue';
import { C1Department } from '@/api/client';
import { c1DepartmentApi } from '@/api';

export const useC1DepartmentStore = defineStore('c1-department', () => {
  const isLoading = ref<boolean>(false);
  const c1Departments = ref<C1Department[]>([]);

  async function fetchC1Departments() {
    try {
      isLoading.value = true;
      const { data } = await c1DepartmentApi.c1DepartmentsControllerFindAll();
      c1Departments.value = data;
    } catch (e) {
      console.error('C1 Departments', e);
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    c1Departments,
    fetchC1Departments,
  };
});
