import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useConfigStore = defineStore('config', () => {
  const appDrawerOpen = ref(false);

  const toggleAppDrawer = () => {
    appDrawerOpen.value = !appDrawerOpen.value;
  };

  return {
    appDrawerOpen,
    toggleAppDrawer,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot));
}
