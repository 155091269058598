<script setup lang="ts">
import { AppNotification, AppNotificationTypeEnum, SourceEnum } from '@/api/client';
import { useReadOneQuery } from '@/composables/queries/appNotificationsQuery';
import { useNotificationDateHelper } from '@/composables/useNotificationDateHelper';
import { MessageSchema } from '@/lib/i18n';
import { capitalize, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const props = defineProps<{ item: AppNotification }>();

const emits = defineEmits(['onNavigate']);

const { formatNotificationDate } = useNotificationDateHelper();

const { isLoading, mutate } = useReadOneQuery();

const setActionLabel = computed(() => {
  if (props.item.type === AppNotificationTypeEnum.CsvDownload) {
    // return t('app_notifications.download');
    return '';
  } else {
    if (props.item.url?.trim()) {
      return t('app_notifications.view');
    } else {
      return t('app_notifications.read');
    }
  }
});

const onReadNotification = async (notification: AppNotification) => {
  mutate(notification.id);

  if (
    notification.type === AppNotificationTypeEnum.CsvDownload &&
    !notification.message.includes('Failed') &&
    !notification.message.includes('Download is in progress..')
  ) {
    window.open(props.item.url, '_blank');
  } else {
    if (notification.url) {
      emits('onNavigate', notification.url);
    }
  }
};

const notificationTargetName = computed(() => {
  if (props.item.health_score_source === SourceEnum.C2HealthScore) {
    return props.item.c2_name;
  }

  if (props.item.health_score_source === SourceEnum.U2HealthScore) {
    return props.item.u2_name;
  }
  return '';
});

const typeName = computed(() => {
  if (props.item.type === AppNotificationTypeEnum.CsvDownload) {
    return t('app_notifications.csv');
  } else {
    return capitalize(props.item.type);
  }
});

const isClickableItem = computed(() => {
  return (
    props.item.type === AppNotificationTypeEnum.CsvDownload &&
    !props.item.message.includes('Failed') &&
    !props.item.message.includes('Download is in progress..') &&
    props.item.url
  );
});
</script>

<template>
  <div>
    <div
      @click.prevent="isClickableItem ? onReadNotification(props.item) : null"
      :class="isClickableItem ? 'tw-cursor-pointer' : ''"
    >
      {{ props.item.message }}
    </div>
    <!-- <div>{{ props.item.message }}</div> -->
    <div class="tw-my-1 tw-flex tw-place-items-center tw-justify-between tw-text-xs">
      <span class="tw-rounded-sm tw-bg-gray-400 tw-px-1 tw-font-bold tw-text-white">{{
        // capitalize(props.item.type)
        typeName
      }}</span>
      <span v-if="props.item.c2_name || props.item.u2_name">{{ notificationTargetName }}</span>
    </div>
    <div class="tw-mt-1 tw-flex tw-place-items-center tw-justify-between tw-text-xs">
      <span class="tw-text-dark-shade-4">{{ formatNotificationDate(item.created_at) }}</span>
      <a href="" @click.prevent="onReadNotification(props.item)"
        ><q-spinner v-if="isLoading" color="primary" size="1em" /><span
          v-else
          class="tw-text-xs tw-font-bold tw-text-primary-dark"
          >{{ setActionLabel }}</span
        >
      </a>
    </div>
  </div>
</template>
