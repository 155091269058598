<script setup lang="ts">
import { useColorFormatter } from '@/composables/useColorFormatter';
import { computed } from 'vue';

const props = defineProps<{
  colorCode?: string | undefined;
  percentage?: number;
}>();

const percentageValue = computed(() => {
  if (props.percentage != null) {
    return props.percentage + '%';
  }
  return '';
});

const { getCaretStyle, getCaretType } = useColorFormatter();
</script>

<template>
  <div class="tw-flex tw-flex-col tw-px-3">
    <slot name="header"></slot>
    <div class="tw-flex tw-items-baseline tw-text-[2.5rem] tw-font-semibold tw-leading-[3.25rem] tw-text-dark-shade-2">
      <slot name="value"></slot>
      <q-icon
        :name="getCaretType(props.percentage)"
        class="tw-ml-1 tw-text-[0.625rem]"
        :class="getCaretStyle(props.percentage)"
      />
      <span class="tw-text-sm tw-font-semibold tw-text-dark-shade-2">{{ percentageValue }}</span>
    </div>

    <div class="tw-ml-1 tw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
