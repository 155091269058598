/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CopySuccessPlanDto } from '../model/';
// @ts-ignore
import { StatusSuccessPlanActionDto } from '../model/';
// @ts-ignore
import { StatusSuccessPlanDto } from '../model/';
// @ts-ignore
import { SuccessPlan } from '../model/';
// @ts-ignore
import { UpdateSuccessPlan } from '../model/';
/**
 * SuccessPlanApi - axios parameter creator
 * @export
 */
export const SuccessPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy current success-plan 
         * @param {CopySuccessPlanDto} copySuccessPlanDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerCopy: async (copySuccessPlanDto: CopySuccessPlanDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copySuccessPlanDto' is not null or undefined
            assertParamExists('successPlanControllerCopy', 'copySuccessPlanDto', copySuccessPlanDto)
            const localVarPath = `/api/success-plan/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copySuccessPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of success plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/success-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find success plan by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successPlanControllerFindOne', 'id', id)
            const localVarPath = `/api/success-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Task by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successPlanControllerRemove', 'id', id)
            const localVarPath = `/api/success-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Success-Plan by ID
         * @param {string} id 
         * @param {UpdateSuccessPlan} updateSuccessPlan 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerUpdate: async (id: string, updateSuccessPlan: UpdateSuccessPlan, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successPlanControllerUpdate', 'id', id)
            // verify required parameter 'updateSuccessPlan' is not null or undefined
            assertParamExists('successPlanControllerUpdate', 'updateSuccessPlan', updateSuccessPlan)
            const localVarPath = `/api/success-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSuccessPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Success Plan status by id
         * @param {string} id 
         * @param {StatusSuccessPlanActionDto} statusSuccessPlanActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerUpdateActionStatus: async (id: string, statusSuccessPlanActionDto: StatusSuccessPlanActionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successPlanControllerUpdateActionStatus', 'id', id)
            // verify required parameter 'statusSuccessPlanActionDto' is not null or undefined
            assertParamExists('successPlanControllerUpdateActionStatus', 'statusSuccessPlanActionDto', statusSuccessPlanActionDto)
            const localVarPath = `/api/success-plan/status-action/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusSuccessPlanActionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Success Plan status by id
         * @param {string} id 
         * @param {StatusSuccessPlanDto} statusSuccessPlanDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerUpdateStatus: async (id: string, statusSuccessPlanDto: StatusSuccessPlanDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successPlanControllerUpdateStatus', 'id', id)
            // verify required parameter 'statusSuccessPlanDto' is not null or undefined
            assertParamExists('successPlanControllerUpdateStatus', 'statusSuccessPlanDto', statusSuccessPlanDto)
            const localVarPath = `/api/success-plan/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusSuccessPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuccessPlanApi - functional programming interface
 * @export
 */
export const SuccessPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuccessPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy current success-plan 
         * @param {CopySuccessPlanDto} copySuccessPlanDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successPlanControllerCopy(copySuccessPlanDto: CopySuccessPlanDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuccessPlan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successPlanControllerCopy(copySuccessPlanDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of success plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successPlanControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuccessPlan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successPlanControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find success plan by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successPlanControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successPlanControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Task by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successPlanControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successPlanControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Success-Plan by ID
         * @param {string} id 
         * @param {UpdateSuccessPlan} updateSuccessPlan 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successPlanControllerUpdate(id: string, updateSuccessPlan: UpdateSuccessPlan, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successPlanControllerUpdate(id, updateSuccessPlan, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Success Plan status by id
         * @param {string} id 
         * @param {StatusSuccessPlanActionDto} statusSuccessPlanActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successPlanControllerUpdateActionStatus(id: string, statusSuccessPlanActionDto: StatusSuccessPlanActionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successPlanControllerUpdateActionStatus(id, statusSuccessPlanActionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Success Plan status by id
         * @param {string} id 
         * @param {StatusSuccessPlanDto} statusSuccessPlanDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successPlanControllerUpdateStatus(id: string, statusSuccessPlanDto: StatusSuccessPlanDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successPlanControllerUpdateStatus(id, statusSuccessPlanDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuccessPlanApi - factory interface
 * @export
 */
export const SuccessPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuccessPlanApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy current success-plan 
         * @param {CopySuccessPlanDto} copySuccessPlanDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerCopy(copySuccessPlanDto: CopySuccessPlanDto, xTenantId?: string, options?: any): AxiosPromise<Array<SuccessPlan>> {
            return localVarFp.successPlanControllerCopy(copySuccessPlanDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of success plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<SuccessPlan>> {
            return localVarFp.successPlanControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find success plan by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<SuccessPlan> {
            return localVarFp.successPlanControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Task by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<SuccessPlan> {
            return localVarFp.successPlanControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Success-Plan by ID
         * @param {string} id 
         * @param {UpdateSuccessPlan} updateSuccessPlan 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerUpdate(id: string, updateSuccessPlan: UpdateSuccessPlan, xTenantId?: string, options?: any): AxiosPromise<SuccessPlan> {
            return localVarFp.successPlanControllerUpdate(id, updateSuccessPlan, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Success Plan status by id
         * @param {string} id 
         * @param {StatusSuccessPlanActionDto} statusSuccessPlanActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerUpdateActionStatus(id: string, statusSuccessPlanActionDto: StatusSuccessPlanActionDto, xTenantId?: string, options?: any): AxiosPromise<SuccessPlan> {
            return localVarFp.successPlanControllerUpdateActionStatus(id, statusSuccessPlanActionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Success Plan status by id
         * @param {string} id 
         * @param {StatusSuccessPlanDto} statusSuccessPlanDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successPlanControllerUpdateStatus(id: string, statusSuccessPlanDto: StatusSuccessPlanDto, xTenantId?: string, options?: any): AxiosPromise<SuccessPlan> {
            return localVarFp.successPlanControllerUpdateStatus(id, statusSuccessPlanDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for successPlanControllerCopy operation in SuccessPlanApi.
 * @export
 * @interface SuccessPlanApiSuccessPlanControllerCopyRequest
 */
export interface SuccessPlanApiSuccessPlanControllerCopyRequest {
    /**
     * 
     * @type {CopySuccessPlanDto}
     * @memberof SuccessPlanApiSuccessPlanControllerCopy
     */
    readonly copySuccessPlanDto: CopySuccessPlanDto

    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerCopy
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for successPlanControllerFindAll operation in SuccessPlanApi.
 * @export
 * @interface SuccessPlanApiSuccessPlanControllerFindAllRequest
 */
export interface SuccessPlanApiSuccessPlanControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for successPlanControllerFindOne operation in SuccessPlanApi.
 * @export
 * @interface SuccessPlanApiSuccessPlanControllerFindOneRequest
 */
export interface SuccessPlanApiSuccessPlanControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for successPlanControllerRemove operation in SuccessPlanApi.
 * @export
 * @interface SuccessPlanApiSuccessPlanControllerRemoveRequest
 */
export interface SuccessPlanApiSuccessPlanControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for successPlanControllerUpdate operation in SuccessPlanApi.
 * @export
 * @interface SuccessPlanApiSuccessPlanControllerUpdateRequest
 */
export interface SuccessPlanApiSuccessPlanControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSuccessPlan}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdate
     */
    readonly updateSuccessPlan: UpdateSuccessPlan

    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for successPlanControllerUpdateActionStatus operation in SuccessPlanApi.
 * @export
 * @interface SuccessPlanApiSuccessPlanControllerUpdateActionStatusRequest
 */
export interface SuccessPlanApiSuccessPlanControllerUpdateActionStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdateActionStatus
     */
    readonly id: string

    /**
     * 
     * @type {StatusSuccessPlanActionDto}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdateActionStatus
     */
    readonly statusSuccessPlanActionDto: StatusSuccessPlanActionDto

    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdateActionStatus
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for successPlanControllerUpdateStatus operation in SuccessPlanApi.
 * @export
 * @interface SuccessPlanApiSuccessPlanControllerUpdateStatusRequest
 */
export interface SuccessPlanApiSuccessPlanControllerUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdateStatus
     */
    readonly id: string

    /**
     * 
     * @type {StatusSuccessPlanDto}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdateStatus
     */
    readonly statusSuccessPlanDto: StatusSuccessPlanDto

    /**
     * 
     * @type {string}
     * @memberof SuccessPlanApiSuccessPlanControllerUpdateStatus
     */
    readonly xTenantId?: string
}

/**
 * SuccessPlanApi - object-oriented interface
 * @export
 * @class SuccessPlanApi
 * @extends {BaseAPI}
 */
export class SuccessPlanApi extends BaseAPI {
    /**
     * 
     * @summary Copy current success-plan 
     * @param {SuccessPlanApiSuccessPlanControllerCopyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessPlanApi
     */
    public successPlanControllerCopy(requestParameters: SuccessPlanApiSuccessPlanControllerCopyRequest, options?: AxiosRequestConfig) {
        return SuccessPlanApiFp(this.configuration).successPlanControllerCopy(requestParameters.copySuccessPlanDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of success plans
     * @param {SuccessPlanApiSuccessPlanControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessPlanApi
     */
    public successPlanControllerFindAll(requestParameters: SuccessPlanApiSuccessPlanControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return SuccessPlanApiFp(this.configuration).successPlanControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find success plan by id
     * @param {SuccessPlanApiSuccessPlanControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessPlanApi
     */
    public successPlanControllerFindOne(requestParameters: SuccessPlanApiSuccessPlanControllerFindOneRequest, options?: AxiosRequestConfig) {
        return SuccessPlanApiFp(this.configuration).successPlanControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Task by Id
     * @param {SuccessPlanApiSuccessPlanControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessPlanApi
     */
    public successPlanControllerRemove(requestParameters: SuccessPlanApiSuccessPlanControllerRemoveRequest, options?: AxiosRequestConfig) {
        return SuccessPlanApiFp(this.configuration).successPlanControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Success-Plan by ID
     * @param {SuccessPlanApiSuccessPlanControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessPlanApi
     */
    public successPlanControllerUpdate(requestParameters: SuccessPlanApiSuccessPlanControllerUpdateRequest, options?: AxiosRequestConfig) {
        return SuccessPlanApiFp(this.configuration).successPlanControllerUpdate(requestParameters.id, requestParameters.updateSuccessPlan, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Success Plan status by id
     * @param {SuccessPlanApiSuccessPlanControllerUpdateActionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessPlanApi
     */
    public successPlanControllerUpdateActionStatus(requestParameters: SuccessPlanApiSuccessPlanControllerUpdateActionStatusRequest, options?: AxiosRequestConfig) {
        return SuccessPlanApiFp(this.configuration).successPlanControllerUpdateActionStatus(requestParameters.id, requestParameters.statusSuccessPlanActionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Success Plan status by id
     * @param {SuccessPlanApiSuccessPlanControllerUpdateStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessPlanApi
     */
    public successPlanControllerUpdateStatus(requestParameters: SuccessPlanApiSuccessPlanControllerUpdateStatusRequest, options?: AxiosRequestConfig) {
        return SuccessPlanApiFp(this.configuration).successPlanControllerUpdateStatus(requestParameters.id, requestParameters.statusSuccessPlanDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
