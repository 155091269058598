/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PlaybookMessage } from '../model/';
// @ts-ignore
import { UpdatePlaybookMessageDto } from '../model/';
/**
 * PlaybookMessageApi - axios parameter creator
 * @export
 */
export const PlaybookMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Playbook messages
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a playbook message
         * @param {string} id 
         * @param {UpdatePlaybookMessageDto} updatePlaybookMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageControllerUpdate: async (id: string, updatePlaybookMessageDto: UpdatePlaybookMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookMessageControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookMessageDto' is not null or undefined
            assertParamExists('playbookMessageControllerUpdate', 'updatePlaybookMessageDto', updatePlaybookMessageDto)
            const localVarPath = `/api/playbook-message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookMessageApi - functional programming interface
 * @export
 */
export const PlaybookMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Playbook messages
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookMessageControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookMessageControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a playbook message
         * @param {string} id 
         * @param {UpdatePlaybookMessageDto} updatePlaybookMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookMessageControllerUpdate(id: string, updatePlaybookMessageDto: UpdatePlaybookMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookMessageControllerUpdate(id, updatePlaybookMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookMessageApi - factory interface
 * @export
 */
export const PlaybookMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookMessageApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Playbook messages
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<PlaybookMessage>> {
            return localVarFp.playbookMessageControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a playbook message
         * @param {string} id 
         * @param {UpdatePlaybookMessageDto} updatePlaybookMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageControllerUpdate(id: string, updatePlaybookMessageDto: UpdatePlaybookMessageDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookMessage> {
            return localVarFp.playbookMessageControllerUpdate(id, updatePlaybookMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookMessageControllerFindAll operation in PlaybookMessageApi.
 * @export
 * @interface PlaybookMessageApiPlaybookMessageControllerFindAllRequest
 */
export interface PlaybookMessageApiPlaybookMessageControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageApiPlaybookMessageControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookMessageControllerUpdate operation in PlaybookMessageApi.
 * @export
 * @interface PlaybookMessageApiPlaybookMessageControllerUpdateRequest
 */
export interface PlaybookMessageApiPlaybookMessageControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageApiPlaybookMessageControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookMessageDto}
     * @memberof PlaybookMessageApiPlaybookMessageControllerUpdate
     */
    readonly updatePlaybookMessageDto: UpdatePlaybookMessageDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageApiPlaybookMessageControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlaybookMessageApi - object-oriented interface
 * @export
 * @class PlaybookMessageApi
 * @extends {BaseAPI}
 */
export class PlaybookMessageApi extends BaseAPI {
    /**
     * 
     * @summary List of Playbook messages
     * @param {PlaybookMessageApiPlaybookMessageControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookMessageApi
     */
    public playbookMessageControllerFindAll(requestParameters: PlaybookMessageApiPlaybookMessageControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookMessageApiFp(this.configuration).playbookMessageControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a playbook message
     * @param {PlaybookMessageApiPlaybookMessageControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookMessageApi
     */
    public playbookMessageControllerUpdate(requestParameters: PlaybookMessageApiPlaybookMessageControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookMessageApiFp(this.configuration).playbookMessageControllerUpdate(requestParameters.id, requestParameters.updatePlaybookMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
