/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateTaskDto } from '../model/';
// @ts-ignore
import { GenerateTextResponseDto } from '../model/';
// @ts-ignore
import { PaginatedTaskDto } from '../model/';
// @ts-ignore
import { RoleBaseTaskPaginatedDto } from '../model/';
// @ts-ignore
import { SourceEnum } from '../model/';
// @ts-ignore
import { Task } from '../model/';
// @ts-ignore
import { TaskFilterDto } from '../model/';
// @ts-ignore
import { TaskStatusCount } from '../model/';
// @ts-ignore
import { UpdateMultipleTaskStatusDto } from '../model/';
// @ts-ignore
import { UpdateTaskDto } from '../model/';
/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create task
         * @param {CreateTaskDto} createTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerCreate: async (createTaskDto: CreateTaskDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskDto' is not null or undefined
            assertParamExists('tasksControllerCreate', 'createTaskDto', createTaskDto)
            const localVarPath = `/api/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create task v2
         * @param {CreateTaskDto} createTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerCreateV2: async (createTaskDto: CreateTaskDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskDto' is not null or undefined
            assertParamExists('tasksControllerCreateV2', 'createTaskDto', createTaskDto)
            const localVarPath = `/api/v2/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zapbook Task Details CSV Download
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerDownloadTaskDataCsv: async (taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('tasksControllerDownloadTaskDataCsv', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/tasks/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Task Information CSV Download From Customer Details
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerDownloadTaskInfoFromCustomerDetails: async (taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('tasksControllerDownloadTaskInfoFromCustomerDetails', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/tasks/download-from-customer-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Task Information CSV Download From User Details
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerDownloadTaskInfoFromUserDetails: async (taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('tasksControllerDownloadTaskInfoFromUserDetails', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/tasks/download-from-user-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of tasks filter v2 with performance enhancement
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFilterV2: async (taskFilterDto: TaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('tasksControllerFilterV2', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/v2/tasks/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Tasks against filter and sorting in zapbook section
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFilterZapbook: async (taskFilterDto: TaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('tasksControllerFilterZapbook', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/tasks/filter-zapbook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of tasks
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindAll: async (source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('tasksControllerFindAll', 'source', source)
            const localVarPath = `/api/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (healthScoreId !== undefined) {
                localVarQueryParameter['healthScoreId'] = healthScoreId;
            }

            if (kpiId !== undefined) {
                localVarQueryParameter['kpiId'] = kpiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of tasks v2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindAllV2: async (source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('tasksControllerFindAllV2', 'source', source)
            const localVarPath = `/api/v2/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (healthScoreId !== undefined) {
                localVarQueryParameter['healthScoreId'] = healthScoreId;
            }

            if (kpiId !== undefined) {
                localVarQueryParameter['kpiId'] = kpiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Count tasks with status
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindCountWithStatus: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/task-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Count task with status v2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindCountWithStatusV2: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/tasks/task-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Task by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tasksControllerFindOne', 'id', id)
            const localVarPath = `/api/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerGenerateCommentText: async (pointers: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointers' is not null or undefined
            assertParamExists('tasksControllerGenerateCommentText', 'pointers', pointers)
            const localVarPath = `/api/tasks/generate-comment-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pointers !== undefined) {
                localVarQueryParameter['pointers'] = pointers;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerGenerateText: async (topic: string, pointers: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('tasksControllerGenerateText', 'topic', topic)
            // verify required parameter 'pointers' is not null or undefined
            assertParamExists('tasksControllerGenerateText', 'pointers', pointers)
            const localVarPath = `/api/tasks/generate-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (pointers !== undefined) {
                localVarQueryParameter['pointers'] = pointers;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft delete task
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerSoftDeleteTaskItem: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tasksControllerSoftDeleteTaskItem', 'id', id)
            const localVarPath = `/api/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Task by ID
         * @param {string} id 
         * @param {UpdateTaskDto} updateTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerUpdate: async (id: string, updateTaskDto: UpdateTaskDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tasksControllerUpdate', 'id', id)
            // verify required parameter 'updateTaskDto' is not null or undefined
            assertParamExists('tasksControllerUpdate', 'updateTaskDto', updateTaskDto)
            const localVarPath = `/api/tasks/update-task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Task status
         * @param {UpdateMultipleTaskStatusDto} updateMultipleTaskStatusDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerUpdateMultipleStatus: async (updateMultipleTaskStatusDto: UpdateMultipleTaskStatusDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMultipleTaskStatusDto' is not null or undefined
            assertParamExists('tasksControllerUpdateMultipleStatus', 'updateMultipleTaskStatusDto', updateMultipleTaskStatusDto)
            const localVarPath = `/api/tasks/update-multiple-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMultipleTaskStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create task
         * @param {CreateTaskDto} createTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerCreate(createTaskDto: CreateTaskDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerCreate(createTaskDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create task v2
         * @param {CreateTaskDto} createTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerCreateV2(createTaskDto: CreateTaskDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerCreateV2(createTaskDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Zapbook Task Details CSV Download
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerDownloadTaskDataCsv(taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerDownloadTaskDataCsv(taskFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Task Information CSV Download From Customer Details
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerDownloadTaskInfoFromCustomerDetails(taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerDownloadTaskInfoFromCustomerDetails(taskFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Task Information CSV Download From User Details
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerDownloadTaskInfoFromUserDetails(taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerDownloadTaskInfoFromUserDetails(taskFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of tasks filter v2 with performance enhancement
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerFilterV2(taskFilterDto: TaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseTaskPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerFilterV2(taskFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Tasks against filter and sorting in zapbook section
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerFilterZapbook(taskFilterDto: TaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerFilterZapbook(taskFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of tasks
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerFindAll(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerFindAll(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of tasks v2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerFindAllV2(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseTaskPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerFindAllV2(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Count tasks with status
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerFindCountWithStatus(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerFindCountWithStatus(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Count task with status v2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerFindCountWithStatusV2(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskStatusCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerFindCountWithStatusV2(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Task by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerGenerateCommentText(pointers: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerGenerateCommentText(pointers, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerGenerateText(topic: string, pointers: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerGenerateText(topic, pointers, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft delete task
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerSoftDeleteTaskItem(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerSoftDeleteTaskItem(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Task by ID
         * @param {string} id 
         * @param {UpdateTaskDto} updateTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerUpdate(id: string, updateTaskDto: UpdateTaskDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerUpdate(id, updateTaskDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Task status
         * @param {UpdateMultipleTaskStatusDto} updateMultipleTaskStatusDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerUpdateMultipleStatus(updateMultipleTaskStatusDto: UpdateMultipleTaskStatusDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerUpdateMultipleStatus(updateMultipleTaskStatusDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 
         * @summary Create task
         * @param {CreateTaskDto} createTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerCreate(createTaskDto: CreateTaskDto, xTenantId?: string, options?: any): AxiosPromise<Task> {
            return localVarFp.tasksControllerCreate(createTaskDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create task v2
         * @param {CreateTaskDto} createTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerCreateV2(createTaskDto: CreateTaskDto, xTenantId?: string, options?: any): AxiosPromise<Task> {
            return localVarFp.tasksControllerCreateV2(createTaskDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Zapbook Task Details CSV Download
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerDownloadTaskDataCsv(taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.tasksControllerDownloadTaskDataCsv(taskFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Task Information CSV Download From Customer Details
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerDownloadTaskInfoFromCustomerDetails(taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.tasksControllerDownloadTaskInfoFromCustomerDetails(taskFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Task Information CSV Download From User Details
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerDownloadTaskInfoFromUserDetails(taskFilterDto: TaskFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.tasksControllerDownloadTaskInfoFromUserDetails(taskFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of tasks filter v2 with performance enhancement
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFilterV2(taskFilterDto: TaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBaseTaskPaginatedDto> {
            return localVarFp.tasksControllerFilterV2(taskFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Tasks against filter and sorting in zapbook section
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFilterZapbook(taskFilterDto: TaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedTaskDto> {
            return localVarFp.tasksControllerFilterZapbook(taskFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of tasks
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindAll(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: any): AxiosPromise<PaginatedTaskDto> {
            return localVarFp.tasksControllerFindAll(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of tasks v2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindAllV2(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: any): AxiosPromise<RoleBaseTaskPaginatedDto> {
            return localVarFp.tasksControllerFindAllV2(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Count tasks with status
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindCountWithStatus(xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tasksControllerFindCountWithStatus(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Count task with status v2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindCountWithStatusV2(xTenantId?: string, options?: any): AxiosPromise<TaskStatusCount> {
            return localVarFp.tasksControllerFindCountWithStatusV2(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Task by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Task> {
            return localVarFp.tasksControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerGenerateCommentText(pointers: string, xTenantId?: string, options?: any): AxiosPromise<GenerateTextResponseDto> {
            return localVarFp.tasksControllerGenerateCommentText(pointers, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerGenerateText(topic: string, pointers: string, xTenantId?: string, options?: any): AxiosPromise<GenerateTextResponseDto> {
            return localVarFp.tasksControllerGenerateText(topic, pointers, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft delete task
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerSoftDeleteTaskItem(id: string, xTenantId?: string, options?: any): AxiosPromise<Task> {
            return localVarFp.tasksControllerSoftDeleteTaskItem(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Task by ID
         * @param {string} id 
         * @param {UpdateTaskDto} updateTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerUpdate(id: string, updateTaskDto: UpdateTaskDto, xTenantId?: string, options?: any): AxiosPromise<Task> {
            return localVarFp.tasksControllerUpdate(id, updateTaskDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Task status
         * @param {UpdateMultipleTaskStatusDto} updateMultipleTaskStatusDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerUpdateMultipleStatus(updateMultipleTaskStatusDto: UpdateMultipleTaskStatusDto, xTenantId?: string, options?: any): AxiosPromise<Array<Task>> {
            return localVarFp.tasksControllerUpdateMultipleStatus(updateMultipleTaskStatusDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for tasksControllerCreate operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerCreateRequest
 */
export interface TaskApiTasksControllerCreateRequest {
    /**
     * 
     * @type {CreateTaskDto}
     * @memberof TaskApiTasksControllerCreate
     */
    readonly createTaskDto: CreateTaskDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerCreateV2 operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerCreateV2Request
 */
export interface TaskApiTasksControllerCreateV2Request {
    /**
     * 
     * @type {CreateTaskDto}
     * @memberof TaskApiTasksControllerCreateV2
     */
    readonly createTaskDto: CreateTaskDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerCreateV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerDownloadTaskDataCsv operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerDownloadTaskDataCsvRequest
 */
export interface TaskApiTasksControllerDownloadTaskDataCsvRequest {
    /**
     * 
     * @type {TaskFilterDto}
     * @memberof TaskApiTasksControllerDownloadTaskDataCsv
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerDownloadTaskDataCsv
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof TaskApiTasksControllerDownloadTaskDataCsv
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof TaskApiTasksControllerDownloadTaskDataCsv
     */
    readonly descending?: boolean
}

/**
 * Request parameters for tasksControllerDownloadTaskInfoFromCustomerDetails operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerDownloadTaskInfoFromCustomerDetailsRequest
 */
export interface TaskApiTasksControllerDownloadTaskInfoFromCustomerDetailsRequest {
    /**
     * 
     * @type {TaskFilterDto}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromCustomerDetails
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromCustomerDetails
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromCustomerDetails
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromCustomerDetails
     */
    readonly descending?: boolean
}

/**
 * Request parameters for tasksControllerDownloadTaskInfoFromUserDetails operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerDownloadTaskInfoFromUserDetailsRequest
 */
export interface TaskApiTasksControllerDownloadTaskInfoFromUserDetailsRequest {
    /**
     * 
     * @type {TaskFilterDto}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromUserDetails
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromUserDetails
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromUserDetails
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof TaskApiTasksControllerDownloadTaskInfoFromUserDetails
     */
    readonly descending?: boolean
}

/**
 * Request parameters for tasksControllerFilterV2 operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerFilterV2Request
 */
export interface TaskApiTasksControllerFilterV2Request {
    /**
     * 
     * @type {TaskFilterDto}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterV2
     */
    readonly after?: string
}

/**
 * Request parameters for tasksControllerFilterZapbook operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerFilterZapbookRequest
 */
export interface TaskApiTasksControllerFilterZapbookRequest {
    /**
     * 
     * @type {TaskFilterDto}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFilterZapbook
     */
    readonly after?: string
}

/**
 * Request parameters for tasksControllerFindAll operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerFindAllRequest
 */
export interface TaskApiTasksControllerFindAllRequest {
    /**
     * 
     * @type {SourceEnum}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly source: SourceEnum

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly after?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly healthScoreId?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAll
     */
    readonly kpiId?: string
}

/**
 * Request parameters for tasksControllerFindAllV2 operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerFindAllV2Request
 */
export interface TaskApiTasksControllerFindAllV2Request {
    /**
     * 
     * @type {SourceEnum}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly source: SourceEnum

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly after?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly healthScoreId?: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindAllV2
     */
    readonly kpiId?: string
}

/**
 * Request parameters for tasksControllerFindCountWithStatus operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerFindCountWithStatusRequest
 */
export interface TaskApiTasksControllerFindCountWithStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindCountWithStatus
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerFindCountWithStatusV2 operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerFindCountWithStatusV2Request
 */
export interface TaskApiTasksControllerFindCountWithStatusV2Request {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindCountWithStatusV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerFindOne operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerFindOneRequest
 */
export interface TaskApiTasksControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerGenerateCommentText operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerGenerateCommentTextRequest
 */
export interface TaskApiTasksControllerGenerateCommentTextRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerGenerateCommentText
     */
    readonly pointers: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerGenerateCommentText
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerGenerateText operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerGenerateTextRequest
 */
export interface TaskApiTasksControllerGenerateTextRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerGenerateText
     */
    readonly topic: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerGenerateText
     */
    readonly pointers: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerGenerateText
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerSoftDeleteTaskItem operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerSoftDeleteTaskItemRequest
 */
export interface TaskApiTasksControllerSoftDeleteTaskItemRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerSoftDeleteTaskItem
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerSoftDeleteTaskItem
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerUpdate operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerUpdateRequest
 */
export interface TaskApiTasksControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTaskDto}
     * @memberof TaskApiTasksControllerUpdate
     */
    readonly updateTaskDto: UpdateTaskDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for tasksControllerUpdateMultipleStatus operation in TaskApi.
 * @export
 * @interface TaskApiTasksControllerUpdateMultipleStatusRequest
 */
export interface TaskApiTasksControllerUpdateMultipleStatusRequest {
    /**
     * 
     * @type {UpdateMultipleTaskStatusDto}
     * @memberof TaskApiTasksControllerUpdateMultipleStatus
     */
    readonly updateMultipleTaskStatusDto: UpdateMultipleTaskStatusDto

    /**
     * 
     * @type {string}
     * @memberof TaskApiTasksControllerUpdateMultipleStatus
     */
    readonly xTenantId?: string
}

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @summary Create task
     * @param {TaskApiTasksControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerCreate(requestParameters: TaskApiTasksControllerCreateRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerCreate(requestParameters.createTaskDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create task v2
     * @param {TaskApiTasksControllerCreateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerCreateV2(requestParameters: TaskApiTasksControllerCreateV2Request, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerCreateV2(requestParameters.createTaskDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Zapbook Task Details CSV Download
     * @param {TaskApiTasksControllerDownloadTaskDataCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerDownloadTaskDataCsv(requestParameters: TaskApiTasksControllerDownloadTaskDataCsvRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerDownloadTaskDataCsv(requestParameters.taskFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Task Information CSV Download From Customer Details
     * @param {TaskApiTasksControllerDownloadTaskInfoFromCustomerDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerDownloadTaskInfoFromCustomerDetails(requestParameters: TaskApiTasksControllerDownloadTaskInfoFromCustomerDetailsRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerDownloadTaskInfoFromCustomerDetails(requestParameters.taskFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Task Information CSV Download From User Details
     * @param {TaskApiTasksControllerDownloadTaskInfoFromUserDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerDownloadTaskInfoFromUserDetails(requestParameters: TaskApiTasksControllerDownloadTaskInfoFromUserDetailsRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerDownloadTaskInfoFromUserDetails(requestParameters.taskFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of tasks filter v2 with performance enhancement
     * @param {TaskApiTasksControllerFilterV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerFilterV2(requestParameters: TaskApiTasksControllerFilterV2Request, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerFilterV2(requestParameters.taskFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Tasks against filter and sorting in zapbook section
     * @param {TaskApiTasksControllerFilterZapbookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerFilterZapbook(requestParameters: TaskApiTasksControllerFilterZapbookRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerFilterZapbook(requestParameters.taskFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of tasks
     * @param {TaskApiTasksControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerFindAll(requestParameters: TaskApiTasksControllerFindAllRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerFindAll(requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.healthScoreId, requestParameters.kpiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of tasks v2
     * @param {TaskApiTasksControllerFindAllV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerFindAllV2(requestParameters: TaskApiTasksControllerFindAllV2Request, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerFindAllV2(requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.healthScoreId, requestParameters.kpiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Count tasks with status
     * @param {TaskApiTasksControllerFindCountWithStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerFindCountWithStatus(requestParameters: TaskApiTasksControllerFindCountWithStatusRequest = {}, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerFindCountWithStatus(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Count task with status v2
     * @param {TaskApiTasksControllerFindCountWithStatusV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerFindCountWithStatusV2(requestParameters: TaskApiTasksControllerFindCountWithStatusV2Request = {}, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerFindCountWithStatusV2(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Task by ID
     * @param {TaskApiTasksControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerFindOne(requestParameters: TaskApiTasksControllerFindOneRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskApiTasksControllerGenerateCommentTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerGenerateCommentText(requestParameters: TaskApiTasksControllerGenerateCommentTextRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerGenerateCommentText(requestParameters.pointers, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskApiTasksControllerGenerateTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerGenerateText(requestParameters: TaskApiTasksControllerGenerateTextRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerGenerateText(requestParameters.topic, requestParameters.pointers, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft delete task
     * @param {TaskApiTasksControllerSoftDeleteTaskItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerSoftDeleteTaskItem(requestParameters: TaskApiTasksControllerSoftDeleteTaskItemRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerSoftDeleteTaskItem(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Task by ID
     * @param {TaskApiTasksControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerUpdate(requestParameters: TaskApiTasksControllerUpdateRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerUpdate(requestParameters.id, requestParameters.updateTaskDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Task status
     * @param {TaskApiTasksControllerUpdateMultipleStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public tasksControllerUpdateMultipleStatus(requestParameters: TaskApiTasksControllerUpdateMultipleStatusRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).tasksControllerUpdateMultipleStatus(requestParameters.updateMultipleTaskStatusDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
