<script setup lang="ts">
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import SankeyChart from '../Charts/SankeyChart.vue';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { ChartData, ScriptableContext } from 'chart.js';
import { useHealthMovementChart } from '@/composables/useHealthMovementChart';
import { DashboardOverviewDto } from '@/api/client';
import { SankeyChartOption } from '@/types/dashboard';
import LoadingIndicator from '../LoadingIndicator.vue';
import PillToggle from '../PillToggle.vue';
import { phCircleFill } from '@/phosphor-icons';
import { useDateStore } from '@/stores/date';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const { dashboardInfo, isDashboardInfoLoading, sankeyFor } = storeToRefs(useDashboardV2Store());
const _dateStore = useDateStore();
const { iDate } = storeToRefs(_dateStore);

const _healthMovementChart = useHealthMovementChart();

const filterOptions = [
  { value: SankeyChartOption.customer, label: t(`sankey_chart_section.${SankeyChartOption.customer}`) },
  { value: SankeyChartOption.user, label: t(`sankey_chart_section.${SankeyChartOption.user}`) },
];

const fromDate = computed(() => {
  return _dateStore.previousDate(iDate.value);
});

const dataSet = computed(() => {
  const info = dashboardInfo.value;
  if (!info) {
    return {
      labels: [],
      datasets: [],
    } as ChartData<'sankey'>;
  }
  return getHealthScoreMovementChartAgainstFilter(info);
});

function getHealthScoreMovementChartAgainstFilter(healthScore: DashboardOverviewDto) {
  if (!healthScore) {
    return {
      labels: [],
      datasets: [],
    } as ChartData<'sankey'>;
  }
  let data = [];
  switch (sankeyFor.value) {
    case SankeyChartOption.user:
      data = [...populateDataAgainstU2(healthScore)];
      break;
    default:
      data = [...populateDataAgainstC2(healthScore)];
  }

  const label: { [key: string]: string } = {};
  const priority: { [key: string]: number } = {};
  const isInActiveFrom = data.find((x) => x?.from === 'inactive_dec');
  if (isInActiveFrom) {
    label['inactive_dec'] = t('grey');
    priority['inactive_dec'] = 1;
  }
  const isDowngradeFrom = data.find((x) => x?.from === 'downgrades_dec');
  if (isDowngradeFrom) {
    label['downgrades_dec'] = t('red');
    priority['downgrades_dec'] = 2;
  }
  const isUpgradeFrom = data.find((x) => x?.from === 'upgrades_dec');
  if (isUpgradeFrom) {
    label['upgrades_dec'] = t('green');
    priority['upgrades_dec'] = 4;
  }
  const isSteadyFrom = data.find((x) => x?.from === 'steady_dec');
  if (isSteadyFrom) {
    label['steady_dec'] = t('yellow');
    priority['steady_dec'] = 3;
  }
  const isChurnTo = data.find((x) => x?.to === 'churn_jan');
  if (isChurnTo) {
    label['churn_jan'] = t('black');
    priority['churn_jan'] = 0;
  }
  const isInActiveTo = data.find((x) => x?.to === 'inactive_jan');
  if (isInActiveTo) {
    label['inactive_jan'] = t('grey');
    priority['inactive_jan'] = 1;
  }
  const isDowngradesTo = data.find((x) => x?.to === 'downgrades_jan');
  if (isDowngradesTo) {
    label['downgrades_jan'] = t('red');
    priority['downgrades_jan'] = 2;
  }
  const isUpgradeTo = data.find((x) => x?.to === 'upgrades_jan');
  if (isUpgradeTo) {
    label['upgrades_jan'] = t('green');
    priority['upgrades_jan'] = 4;
  }
  const isSteadyTo = data.find((x) => x?.to === 'steady_jan');
  if (isSteadyTo) {
    label['steady_jan'] = t('yellow');
    priority['steady_jan'] = 3;
  }

  const chartDataInstance = {
    data,
    colorFrom: (c: ScriptableContext<'sankey'>) => _healthMovementChart.getColor(c.dataset.data[c.dataIndex]?.from),
    colorTo: (c: ScriptableContext<'sankey'>) => _healthMovementChart.getColor(c.dataset.data[c.dataIndex]?.to),
    colorMode: 'to',
    labels: label,
    priority,
    borderWidth: 0,
  };
  return {
    datasets: [chartDataInstance],
  } as ChartData<'sankey'>;
}

function populateDataAgainstC2(healthScore: DashboardOverviewDto) {
  const data = [];
  if (healthScore?.no_of_c2_grey_to_black || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'churn_jan', flow: healthScore.no_of_c2_grey_to_black });
  }
  if (healthScore?.no_of_c2_grey_to_grey || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'inactive_jan', flow: healthScore.no_of_c2_grey_to_grey });
  }
  if (healthScore?.no_of_c2_grey_to_red || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'downgrades_jan', flow: healthScore.no_of_c2_grey_to_red });
  }
  if (healthScore?.no_of_c2_grey_to_yellow || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'steady_jan', flow: healthScore.no_of_c2_grey_to_yellow });
  }
  if (healthScore?.no_of_c2_grey_to_green || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'upgrades_jan', flow: healthScore.no_of_c2_grey_to_green });
  }

  if (healthScore?.no_of_c2_red_to_black || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'churn_jan', flow: healthScore.no_of_c2_red_to_black });
  }
  if (healthScore?.no_of_c2_red_to_grey || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'inactive_jan', flow: healthScore.no_of_c2_red_to_grey });
  }
  if (healthScore?.no_of_c2_red_to_red || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'downgrades_jan', flow: healthScore.no_of_c2_red_to_red });
  }
  if (healthScore?.no_of_c2_red_to_yellow || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'steady_jan', flow: healthScore.no_of_c2_red_to_yellow });
  }
  if (healthScore?.no_of_c2_red_to_green || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'upgrades_jan', flow: healthScore.no_of_c2_red_to_green });
  }

  if (healthScore?.no_of_c2_green_to_black || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'churn_jan', flow: healthScore.no_of_c2_green_to_black });
  }
  if (healthScore?.no_of_c2_green_to_grey || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'inactive_jan', flow: healthScore.no_of_c2_green_to_grey });
  }
  if (healthScore?.no_of_c2_green_to_red || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'downgrades_jan', flow: healthScore.no_of_c2_green_to_red });
  }
  if (healthScore?.no_of_c2_green_to_yellow || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'steady_jan', flow: healthScore.no_of_c2_green_to_yellow });
  }
  if (healthScore?.no_of_c2_green_to_green || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'upgrades_jan', flow: healthScore.no_of_c2_green_to_green });
  }

  if (healthScore?.no_of_c2_yellow_to_black || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'churn_jan', flow: healthScore.no_of_c2_yellow_to_black });
  }
  if (healthScore?.no_of_c2_yellow_to_grey || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'inactive_jan', flow: healthScore.no_of_c2_yellow_to_grey });
  }
  if (healthScore?.no_of_c2_yellow_to_red || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'downgrades_jan', flow: healthScore.no_of_c2_yellow_to_red });
  }
  if (healthScore?.no_of_c2_yellow_to_yellow || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'steady_jan', flow: healthScore.no_of_c2_yellow_to_yellow });
  }
  if (healthScore?.no_of_c2_yellow_to_green || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'upgrades_jan', flow: healthScore.no_of_c2_yellow_to_green });
  }
  return data;
}

function populateDataAgainstU2(healthScore: DashboardOverviewDto) {
  const data = [];
  if (healthScore?.no_of_u2_grey_to_black || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'churn_jan', flow: healthScore.no_of_u2_grey_to_black });
  }
  if (healthScore?.no_of_u2_grey_to_grey || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'inactive_jan', flow: healthScore.no_of_u2_grey_to_grey });
  }
  if (healthScore?.no_of_u2_grey_to_red || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'downgrades_jan', flow: healthScore.no_of_u2_grey_to_red });
  }
  if (healthScore?.no_of_u2_grey_to_yellow || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'steady_jan', flow: healthScore.no_of_u2_grey_to_yellow });
  }
  if (healthScore?.no_of_u2_grey_to_green || 0 > 0) {
    data.push({ from: 'inactive_dec', to: 'upgrades_jan', flow: healthScore.no_of_u2_grey_to_green });
  }

  if (healthScore?.no_of_u2_red_to_black || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'churn_jan', flow: healthScore.no_of_u2_red_to_black });
  }
  if (healthScore?.no_of_u2_red_to_grey || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'inactive_jan', flow: healthScore.no_of_u2_red_to_grey });
  }
  if (healthScore?.no_of_u2_red_to_red || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'downgrades_jan', flow: healthScore.no_of_u2_red_to_red });
  }
  if (healthScore?.no_of_u2_red_to_yellow || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'steady_jan', flow: healthScore.no_of_u2_red_to_yellow });
  }
  if (healthScore?.no_of_u2_red_to_green || 0 > 0) {
    data.push({ from: 'downgrades_dec', to: 'upgrades_jan', flow: healthScore.no_of_u2_red_to_green });
  }

  if (healthScore?.no_of_u2_green_to_black || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'churn_jan', flow: healthScore.no_of_u2_green_to_black });
  }
  if (healthScore?.no_of_u2_green_to_grey || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'inactive_jan', flow: healthScore.no_of_u2_green_to_grey });
  }
  if (healthScore?.no_of_u2_green_to_red || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'downgrades_jan', flow: healthScore.no_of_u2_green_to_red });
  }
  if (healthScore?.no_of_u2_green_to_yellow || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'steady_jan', flow: healthScore.no_of_u2_green_to_yellow });
  }
  if (healthScore?.no_of_u2_green_to_green || 0 > 0) {
    data.push({ from: 'upgrades_dec', to: 'upgrades_jan', flow: healthScore.no_of_u2_green_to_green });
  }

  if (healthScore?.no_of_u2_yellow_to_black || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'churn_jan', flow: healthScore.no_of_u2_yellow_to_black });
  }
  if (healthScore?.no_of_u2_yellow_to_grey || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'inactive_jan', flow: healthScore.no_of_u2_yellow_to_grey });
  }
  if (healthScore?.no_of_u2_yellow_to_red || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'downgrades_jan', flow: healthScore.no_of_u2_yellow_to_red });
  }
  if (healthScore?.no_of_u2_yellow_to_yellow || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'steady_jan', flow: healthScore.no_of_u2_yellow_to_yellow });
  }
  if (healthScore?.no_of_u2_yellow_to_green || 0 > 0) {
    data.push({ from: 'steady_dec', to: 'upgrades_jan', flow: healthScore.no_of_u2_yellow_to_green });
  }
  return data;
}
</script>

<template>
  <SankeyChart :heading="t('sankey_chart_section.title')" :data-set="dataSet">
    <template #status>
      <LoadingIndicator v-if="isDashboardInfoLoading" class="tw-bg-light-shade-1" />
    </template>
    <template #filter v-if="!isDashboardInfoLoading">
      <PillToggle :options="filterOptions" v-model="sankeyFor" />
    </template>
    <template #footer>
      <div class="tw-flex tw-justify-between tw-text-sm">
        <span>{{ fromDate?.text }}</span>
        <span>{{ iDate?.text }}</span>
      </div>
      <div class="tw-mt-2 tw-flex tw-justify-end tw-pb-2">
        <div>
          <q-icon :name="phCircleFill" class="tw-mr-1 tw-fill-data-viz-semantic-green-graph tw-text-[0.375rem]" />
          <span class="tw-text-[0.625rem] tw-font-bold tw-text-dark-shade-3">{{ t('sankey.upgrades_dec') }}</span>
        </div>
        <div class="tw-ml-2">
          <q-icon :name="phCircleFill" class="tw-mr-1 tw-fill-data-viz-semantic-yellow-graph tw-text-[0.375rem]" />
          <span class="tw-text-[0.625rem] tw-font-bold tw-text-dark-shade-3">{{ t('sankey.steady_dec') }}</span>
        </div>
        <div class="tw-ml-2">
          <q-icon :name="phCircleFill" class="tw-mr-1 tw-fill-data-viz-semantic-red-graph tw-text-[0.375rem]" />
          <span class="tw-text-[0.625rem] tw-font-bold tw-text-dark-shade-3">{{ t('sankey.downgrades_dec') }}</span>
        </div>
        <div class="tw-ml-2">
          <q-icon :name="phCircleFill" class="tw-mr-1 tw-fill-grey-shade-1 tw-text-[0.375rem]" />
          <span class="tw-text-[0.625rem] tw-font-bold tw-text-dark-shade-3">{{ t('sankey.inactive_dec') }}</span>
        </div>
        <div class="tw-ml-2">
          <q-icon :name="phCircleFill" class="tw-mr-1 tw-fill-dark-shade-1 tw-text-[0.375rem]" />
          <span class="tw-text-[0.625rem] tw-font-bold tw-text-dark-shade-3">{{ t('sankey.churn_jan') }}</span>
        </div>
      </div>
    </template>
  </SankeyChart>
</template>
