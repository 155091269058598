/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EmailLogFilterDto } from '../model/';
// @ts-ignore
import { PaginatedEmailLog } from '../model/';
/**
 * EmailLogApi - axios parameter creator
 * @export
 */
export const EmailLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all email within last 30 days for current c2
         * @param {EmailLogFilterDto} emailLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailLogControllerFindAllC2Emails: async (emailLogFilterDto: EmailLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailLogFilterDto' is not null or undefined
            assertParamExists('emailLogControllerFindAllC2Emails', 'emailLogFilterDto', emailLogFilterDto)
            const localVarPath = `/api/email-log/c2-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailLogFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Date wise list of all c2 emails
         * @param {EmailLogFilterDto} emailLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailLogControllerFindAllDayWiseC2Emails: async (emailLogFilterDto: EmailLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailLogFilterDto' is not null or undefined
            assertParamExists('emailLogControllerFindAllDayWiseC2Emails', 'emailLogFilterDto', emailLogFilterDto)
            const localVarPath = `/api/email-log/c2-day-wise-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailLogFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailLogApi - functional programming interface
 * @export
 */
export const EmailLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of all email within last 30 days for current c2
         * @param {EmailLogFilterDto} emailLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailLogControllerFindAllC2Emails(emailLogFilterDto: EmailLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedEmailLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailLogControllerFindAllC2Emails(emailLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Date wise list of all c2 emails
         * @param {EmailLogFilterDto} emailLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailLogControllerFindAllDayWiseC2Emails(emailLogFilterDto: EmailLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedEmailLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailLogControllerFindAllDayWiseC2Emails(emailLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailLogApi - factory interface
 * @export
 */
export const EmailLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailLogApiFp(configuration)
    return {
        /**
         * 
         * @summary List of all email within last 30 days for current c2
         * @param {EmailLogFilterDto} emailLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailLogControllerFindAllC2Emails(emailLogFilterDto: EmailLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedEmailLog> {
            return localVarFp.emailLogControllerFindAllC2Emails(emailLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Date wise list of all c2 emails
         * @param {EmailLogFilterDto} emailLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailLogControllerFindAllDayWiseC2Emails(emailLogFilterDto: EmailLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedEmailLog> {
            return localVarFp.emailLogControllerFindAllDayWiseC2Emails(emailLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for emailLogControllerFindAllC2Emails operation in EmailLogApi.
 * @export
 * @interface EmailLogApiEmailLogControllerFindAllC2EmailsRequest
 */
export interface EmailLogApiEmailLogControllerFindAllC2EmailsRequest {
    /**
     * 
     * @type {EmailLogFilterDto}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly emailLogFilterDto: EmailLogFilterDto

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllC2Emails
     */
    readonly after?: string
}

/**
 * Request parameters for emailLogControllerFindAllDayWiseC2Emails operation in EmailLogApi.
 * @export
 * @interface EmailLogApiEmailLogControllerFindAllDayWiseC2EmailsRequest
 */
export interface EmailLogApiEmailLogControllerFindAllDayWiseC2EmailsRequest {
    /**
     * 
     * @type {EmailLogFilterDto}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly emailLogFilterDto: EmailLogFilterDto

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof EmailLogApiEmailLogControllerFindAllDayWiseC2Emails
     */
    readonly after?: string
}

/**
 * EmailLogApi - object-oriented interface
 * @export
 * @class EmailLogApi
 * @extends {BaseAPI}
 */
export class EmailLogApi extends BaseAPI {
    /**
     * 
     * @summary List of all email within last 30 days for current c2
     * @param {EmailLogApiEmailLogControllerFindAllC2EmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailLogApi
     */
    public emailLogControllerFindAllC2Emails(requestParameters: EmailLogApiEmailLogControllerFindAllC2EmailsRequest, options?: AxiosRequestConfig) {
        return EmailLogApiFp(this.configuration).emailLogControllerFindAllC2Emails(requestParameters.emailLogFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Date wise list of all c2 emails
     * @param {EmailLogApiEmailLogControllerFindAllDayWiseC2EmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailLogApi
     */
    public emailLogControllerFindAllDayWiseC2Emails(requestParameters: EmailLogApiEmailLogControllerFindAllDayWiseC2EmailsRequest, options?: AxiosRequestConfig) {
        return EmailLogApiFp(this.configuration).emailLogControllerFindAllDayWiseC2Emails(requestParameters.emailLogFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }
}
