<script setup lang="ts">
import { CreateHelpRequestDto, TaskPriorityEnum } from '@/api/client';
import useVuelidate from '@vuelidate/core';
import { helpers, required, email } from '@vuelidate/validators';
import { QSelect, useDialogPluginComponent, useQuasar } from 'quasar';
import { computed, onBeforeMount, ref } from 'vue';
import OnboardingTitle from './OnboardingTitle.vue';
import { phCalendar } from '@/phosphor-icons';
import { useU1Store } from '@/stores/u1';
import { useAccountStore } from '@/stores/account';
import { helpRequestApi } from '@/api';
import { useRoute } from 'vue-router';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { useC1DepartmentStore } from '@/stores/c1Department';
import { useU1RoleStore } from '@/stores/u1Role';

const emit = defineEmits(['update:modelValue', ...useDialogPluginComponent.emits]);

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const $q = useQuasar();

const route = useRoute();

const { dialogRef, onDialogHide } = useDialogPluginComponent();

const busy = ref(false);

const accountStore = useAccountStore();

const u1Store = useU1Store();
const c1DepartmentsStore = useC1DepartmentStore();
const u1RoleStore = useU1RoleStore();

const u1ListFilter = ref('');

const u1List = computed(() => {
  let list = u1Store.u1List.filter((v) => v.u1_master_id !== accountStore.account?.id);
  if (u1ListFilter.value) {
    list = list.filter(
      (v) =>
        v.name.toLocaleLowerCase().indexOf(u1ListFilter.value) > -1 ||
        v.email.toLocaleLowerCase().indexOf(u1ListFilter.value) > -1,
    );
  }
  return list;
});

const isNewUser = computed(() => {
  return formData.value.email && u1List.value.findIndex((x) => x.email === formData.value.email) === -1;
});

const filterU1List = (
  inputValue: string,
  update: (callbackFn: () => void, afterFn?: ((ref: QSelect) => void) | undefined) => void,
) => {
  update(() => {
    u1ListFilter.value = inputValue.toLocaleLowerCase();
  });
};

const formData = ref<CreateHelpRequestDto>({
  priority: TaskPriorityEnum.High,
  page_id: route.fullPath,
} as CreateHelpRequestDto);

const taskPriorityOptions = Object.values(TaskPriorityEnum).map((v) => {
  return { value: v, name: t(`task_priority.${v}`) };
});

const deadlineOptions = (value: string) => {
  const date = new Date(value).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  return date >= today;
};

const validDate = (value: string) => {
  const date = Date.parse(value);
  return !isNaN(date);
};

const validDeadline = (value: string) => {
  const date = new Date(value).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  return date >= today;
};

const rules = {
  email: {
    required: helpers.withMessage('Required', required),
    email: helpers.withMessage('Must be a valid email', email),
  },
  title: { required: helpers.withMessage('Required', required) },
  deadline: {
    required: helpers.withMessage('Required', required),
    validDate: helpers.withMessage('Must be a valid date', validDate),
    validDeadline: helpers.withMessage('Cannot be a past date', validDeadline),
  },
};

const v$ = useVuelidate(rules, formData);

const onSubmit = async () => {
  const valid = await v$.value.$validate();
  if (!valid) return;

  busy.value = true;
  try {
    await helpRequestApi.helpRequestsControllerCreate({ createHelpRequestDto: formData.value });
    emit('hide');
    $q.notify({
      color: 'positive',
      type: 'positive',
      message: 'Help request sent',
    });
  } catch (error) {
    console.log(error);
  } finally {
    busy.value = false;
  }
};

const onCancel = () => {
  emit('hide');
};

onBeforeMount(() => {
  u1Store.fetchU1List();
  u1RoleStore.fetchU1Roles();
  c1DepartmentsStore.fetchC1Departments();
});
</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" position="right" full-height persistent no-shake>
    <form
      @submit.prevent="onSubmit"
      class="!tw-pointer-events-auto tw-flex tw-h-screen tw-w-[40rem] tw-flex-col tw-bg-light-shade-1"
    >
      <OnboardingTitle text="Ask for Help" class="tw-mb-0 tw-border-b tw-px-8 tw-py-4" />

      <div class="tw-h-0 tw-flex-grow tw-overflow-y-auto tw-px-8 tw-py-4">
        <div class="tw-grid tw-max-w-[25rem] tw-grid-cols-1 tw-gap-y-2">
          <div>
            <label for="assignee_email" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3"
              >Select an user or enter a new email</label
            >

            <q-select
              for="assignee_email"
              v-model="formData.email"
              :options="u1List"
              option-label="name"
              option-value="email"
              new-value-mode="add-unique"
              input-debounce="0"
              autofocus
              emit-value
              map-options
              dense
              outlined
              use-input
              use-chips
              @filter="filterU1List"
            />
            <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error">
              {{ v$.email.$errors[0]?.$message }}
            </span>
          </div>

          <template v-if="isNewUser">
            <div>
              <label for="assignee_name" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">Name</label>
              <q-input for="assignee_name" v-model="formData.name" dense outlined stack-label />
              <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error"></span>
            </div>

            <div>
              <label for="assignee_department" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3"
                >Department</label
              >

              <q-select
                for="assignee_department"
                v-model="formData.c1_department_id"
                :options="c1DepartmentsStore.c1Departments"
                option-label="display_name"
                option-value="id"
                emit-value
                map-options
                dense
                outlined
              />
              <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error"></span>
            </div>

            <div>
              <label for="assignee_role" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">Role</label>

              <q-select
                for="assignee_role"
                v-model="formData.role_id"
                :options="u1RoleStore.u1Roles"
                option-label="display_name"
                option-value="id"
                emit-value
                map-options
                dense
                outlined
              />
              <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error"></span>
            </div>
          </template>

          <div>
            <label for="task_title" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">Title</label>
            <q-input for="task_title" v-model="formData.title" dense outlined stack-label />
            <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error">{{
              v$.title.$errors[0]?.$message
            }}</span>
          </div>

          <div>
            <label for="task_description" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">Description</label>
            <q-input type="textarea" for="task_description" v-model="formData.description" dense outlined stack-label />
            <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error"></span>
          </div>

          <div>
            <label for="task_priority" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">Priority</label>

            <q-select
              for="task_priority"
              v-model="formData.priority"
              :options="taskPriorityOptions"
              option-label="name"
              option-value="value"
              emit-value
              map-options
              dense
              outlined
              stack-label
            />
            <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error"></span>
          </div>

          <div class="task-deadline">
            <label for="task_deadline" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">Deadline</label>
            <q-input
              for="task_deadline"
              v-model="formData.deadline"
              mask="date"
              dense
              outlined
              stack-label
              class="!tw-cursor-pointer"
            >
              <template v-slot:append>
                <q-icon :name="phCalendar" />
              </template>

              <q-popup-proxy ref="qDeadlineProxy">
                <q-date v-model="formData.deadline" :options="deadlineOptions">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-input>

            <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error">
              {{ v$.deadline.$errors[0]?.$message }}
            </span>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-justify-end tw-bg-light-shade-2 tw-p-4">
        <q-btn type="button" color="primary" label="Cancel" outline no-caps class="tw-mr-2" @click="onCancel" />
        <q-btn type="submit" color="primary" label="Assign" :loading="busy" no-caps />
      </div>
    </form>
  </q-dialog>
</template>

<style scoped lang="postcss">
:deep(.q-dialog__inner--minimized) {
  @apply tw-p-0;
}

.task-deadline :deep(input) {
  @apply tw-cursor-pointer;
}

:deep(.q-chip) {
  @apply tw-m-0 tw-bg-primary-lighter tw-font-medium tw-text-dark-shade-1;
}

:deep(.q-chip__icon) {
  @apply tw-text-dark-shade-1;
}
</style>
