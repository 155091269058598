<script setup lang="ts">
import { MessageSchema } from '@/lib/i18n';
import { useDialogPluginComponent } from 'quasar';
import { useI18n } from 'vue-i18n';
import ModalTitle from '@/components/ModalTitle.vue';
import { ref } from 'vue';
import PersonalAccessToken from './DeveloperSettings/PersonalAccessToken.vue';

const { dialogRef, onDialogCancel } = useDialogPluginComponent();
const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});
const selectedTab = ref<string>('personal_access_token');
</script>

<template>
  <q-dialog ref="dialogRef" full-height position="right">
    <div class="tw-flex tw-h-4/5 tw-w-full tw-min-w-40 tw-flex-col tw-bg-white">
      <ModalTitle :title="t('developer_setting.title')" @on-close-button-click="onDialogCancel" />

      <q-tabs
        v-model="selectedTab"
        align="left"
        active-color="primary"
        active-bg-color="#E6F1F6"
        class="tw-border-b tw-bg-light-shade-1"
        :breakpoint="0"
        no-caps
        inline-label
        indicator-color="primary"
      >
        <q-tab
          name="personal_access_token"
          :label="t('developer_setting.tabs.personal_access_token')"
          :ripple="false"
          exact
        />
      </q-tabs>

      <div v-if="selectedTab === 'personal_access_token'" class="tw-h-0 tw-flex-grow tw-overflow-y-auto">
        <PersonalAccessToken />
      </div>
    </div>
  </q-dialog>
</template>
