/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MetadataDashboardDto } from '../model/';
/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date Date selection from dashboard
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {boolean} isRoleBaseEnabled 
         * @param {string} [selectedId] 
         * @param {string} [selectedName] 
         * @param {'C1health' | 'C2typehealth' | 'C2segmenthealth'} [selectionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerFindConfiguration: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', isRoleBaseEnabled: boolean, selectedId?: string, selectedName?: string, selectionType?: 'C1health' | 'C2typehealth' | 'C2segmenthealth', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('dashboardControllerFindConfiguration', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('dashboardControllerFindConfiguration', 'dateType', dateType)
            // verify required parameter 'isRoleBaseEnabled' is not null or undefined
            assertParamExists('dashboardControllerFindConfiguration', 'isRoleBaseEnabled', isRoleBaseEnabled)
            const localVarPath = `/api/dashboard/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (isRoleBaseEnabled !== undefined) {
                localVarQueryParameter['is_role_base_enabled'] = isRoleBaseEnabled;
            }

            if (selectedId !== undefined) {
                localVarQueryParameter['selected_id'] = selectedId;
            }

            if (selectedName !== undefined) {
                localVarQueryParameter['selected_name'] = selectedName;
            }

            if (selectionType !== undefined) {
                localVarQueryParameter['selection_type'] = selectionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date Date selection from dashboard
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {boolean} isRoleBaseEnabled 
         * @param {string} [selectedId] 
         * @param {string} [selectedName] 
         * @param {'C1health' | 'C2typehealth' | 'C2segmenthealth'} [selectionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerFindConfiguration(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', isRoleBaseEnabled: boolean, selectedId?: string, selectedName?: string, selectionType?: 'C1health' | 'C2typehealth' | 'C2segmenthealth', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetadataDashboardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerFindConfiguration(date, dateType, isRoleBaseEnabled, selectedId, selectedName, selectionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} date Date selection from dashboard
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {boolean} isRoleBaseEnabled 
         * @param {string} [selectedId] 
         * @param {string} [selectedName] 
         * @param {'C1health' | 'C2typehealth' | 'C2segmenthealth'} [selectionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerFindConfiguration(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', isRoleBaseEnabled: boolean, selectedId?: string, selectedName?: string, selectionType?: 'C1health' | 'C2typehealth' | 'C2segmenthealth', options?: any): AxiosPromise<MetadataDashboardDto> {
            return localVarFp.dashboardControllerFindConfiguration(date, dateType, isRoleBaseEnabled, selectedId, selectedName, selectionType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dashboardControllerFindConfiguration operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardControllerFindConfigurationRequest
 */
export interface DashboardApiDashboardControllerFindConfigurationRequest {
    /**
     * Date selection from dashboard
     * @type {string}
     * @memberof DashboardApiDashboardControllerFindConfiguration
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof DashboardApiDashboardControllerFindConfiguration
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiDashboardControllerFindConfiguration
     */
    readonly isRoleBaseEnabled: boolean

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardControllerFindConfiguration
     */
    readonly selectedId?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardControllerFindConfiguration
     */
    readonly selectedName?: string

    /**
     * 
     * @type {'C1health' | 'C2typehealth' | 'C2segmenthealth'}
     * @memberof DashboardApiDashboardControllerFindConfiguration
     */
    readonly selectionType?: 'C1health' | 'C2typehealth' | 'C2segmenthealth'
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {DashboardApiDashboardControllerFindConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerFindConfiguration(requestParameters: DashboardApiDashboardControllerFindConfigurationRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerFindConfiguration(requestParameters.date, requestParameters.dateType, requestParameters.isRoleBaseEnabled, requestParameters.selectedId, requestParameters.selectedName, requestParameters.selectionType, options).then((request) => request(this.axios, this.basePath));
    }
}
