/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C1Type } from '../model/';
/**
 * C1TypeApi - axios parameter creator
 * @export
 */
export const C1TypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List C1 Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1TypesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c1-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a C1 Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1TypesControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1TypesControllerFindOne', 'id', id)
            const localVarPath = `/api/c1-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C1TypeApi - functional programming interface
 * @export
 */
export const C1TypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C1TypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List C1 Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1TypesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C1Type>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1TypesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a C1 Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1TypesControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1Type>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1TypesControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C1TypeApi - factory interface
 * @export
 */
export const C1TypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C1TypeApiFp(configuration)
    return {
        /**
         * 
         * @summary List C1 Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1TypesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<C1Type>> {
            return localVarFp.c1TypesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a C1 Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1TypesControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<C1Type> {
            return localVarFp.c1TypesControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c1TypesControllerFindAll operation in C1TypeApi.
 * @export
 * @interface C1TypeApiC1TypesControllerFindAllRequest
 */
export interface C1TypeApiC1TypesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C1TypeApiC1TypesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1TypesControllerFindOne operation in C1TypeApi.
 * @export
 * @interface C1TypeApiC1TypesControllerFindOneRequest
 */
export interface C1TypeApiC1TypesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C1TypeApiC1TypesControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C1TypeApiC1TypesControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * C1TypeApi - object-oriented interface
 * @export
 * @class C1TypeApi
 * @extends {BaseAPI}
 */
export class C1TypeApi extends BaseAPI {
    /**
     * 
     * @summary List C1 Types
     * @param {C1TypeApiC1TypesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1TypeApi
     */
    public c1TypesControllerFindAll(requestParameters: C1TypeApiC1TypesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C1TypeApiFp(this.configuration).c1TypesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a C1 Type by Id
     * @param {C1TypeApiC1TypesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1TypeApi
     */
    public c1TypesControllerFindOne(requestParameters: C1TypeApiC1TypesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C1TypeApiFp(this.configuration).c1TypesControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
