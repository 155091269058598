/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppSuspectC2Match } from '../model/';
// @ts-ignore
import { CreateMultiManualMatchDto } from '../model/';
// @ts-ignore
import { UnMatchSuspectC2Dto } from '../model/';
// @ts-ignore
import { UnmatchAllC2SuspectsDto } from '../model/';
// @ts-ignore
import { UnmatchC2SuspectCountDto } from '../model/';
/**
 * AppSuspectC2MatchApi - axios parameter creator
 * @export
 */
export const AppSuspectC2MatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create App Suspect C2 Match Entry for Manual Match
         * @param {CreateMultiManualMatchDto} createMultiManualMatchDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerCreateMultiple: async (createMultiManualMatchDto: CreateMultiManualMatchDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMultiManualMatchDto' is not null or undefined
            assertParamExists('appSuspectC2MatchControllerCreateMultiple', 'createMultiManualMatchDto', createMultiManualMatchDto)
            const localVarPath = `/api/app-suspect-c2-match/create-multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMultiManualMatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of AppSuspectC2Matches
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-suspect-c2-match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Un-match all C2 suspects
         * @param {UnmatchAllC2SuspectsDto} unmatchAllC2SuspectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerUnMatchAllC2Suspects: async (unmatchAllC2SuspectsDto: UnmatchAllC2SuspectsDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unmatchAllC2SuspectsDto' is not null or undefined
            assertParamExists('appSuspectC2MatchControllerUnMatchAllC2Suspects', 'unmatchAllC2SuspectsDto', unmatchAllC2SuspectsDto)
            const localVarPath = `/api/app-suspect-c2-match/unmatch-all-c2-suspects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unmatchAllC2SuspectsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Un-match suspect C2
         * @param {UnMatchSuspectC2Dto} unMatchSuspectC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerUnMatchSuspect: async (unMatchSuspectC2Dto: UnMatchSuspectC2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unMatchSuspectC2Dto' is not null or undefined
            assertParamExists('appSuspectC2MatchControllerUnMatchSuspect', 'unMatchSuspectC2Dto', unMatchSuspectC2Dto)
            const localVarPath = `/api/app-suspect-c2-match/unmatch-suspect-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unMatchSuspectC2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppSuspectC2MatchApi - functional programming interface
 * @export
 */
export const AppSuspectC2MatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppSuspectC2MatchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create App Suspect C2 Match Entry for Manual Match
         * @param {CreateMultiManualMatchDto} createMultiManualMatchDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2MatchControllerCreateMultiple(createMultiManualMatchDto: CreateMultiManualMatchDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppSuspectC2Match>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2MatchControllerCreateMultiple(createMultiManualMatchDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of AppSuspectC2Matches
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2MatchControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppSuspectC2Match>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2MatchControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Un-match all C2 suspects
         * @param {UnmatchAllC2SuspectsDto} unmatchAllC2SuspectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2MatchControllerUnMatchAllC2Suspects(unmatchAllC2SuspectsDto: UnmatchAllC2SuspectsDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnmatchC2SuspectCountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2MatchControllerUnMatchAllC2Suspects(unmatchAllC2SuspectsDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Un-match suspect C2
         * @param {UnMatchSuspectC2Dto} unMatchSuspectC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2MatchControllerUnMatchSuspect(unMatchSuspectC2Dto: UnMatchSuspectC2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2MatchControllerUnMatchSuspect(unMatchSuspectC2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppSuspectC2MatchApi - factory interface
 * @export
 */
export const AppSuspectC2MatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppSuspectC2MatchApiFp(configuration)
    return {
        /**
         * 
         * @summary Create App Suspect C2 Match Entry for Manual Match
         * @param {CreateMultiManualMatchDto} createMultiManualMatchDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerCreateMultiple(createMultiManualMatchDto: CreateMultiManualMatchDto, xTenantId?: string, options?: any): AxiosPromise<Array<AppSuspectC2Match>> {
            return localVarFp.appSuspectC2MatchControllerCreateMultiple(createMultiManualMatchDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of AppSuspectC2Matches
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AppSuspectC2Match>> {
            return localVarFp.appSuspectC2MatchControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Un-match all C2 suspects
         * @param {UnmatchAllC2SuspectsDto} unmatchAllC2SuspectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerUnMatchAllC2Suspects(unmatchAllC2SuspectsDto: UnmatchAllC2SuspectsDto, xTenantId?: string, options?: any): AxiosPromise<UnmatchC2SuspectCountDto> {
            return localVarFp.appSuspectC2MatchControllerUnMatchAllC2Suspects(unmatchAllC2SuspectsDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Un-match suspect C2
         * @param {UnMatchSuspectC2Dto} unMatchSuspectC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2MatchControllerUnMatchSuspect(unMatchSuspectC2Dto: UnMatchSuspectC2Dto, xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.appSuspectC2MatchControllerUnMatchSuspect(unMatchSuspectC2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appSuspectC2MatchControllerCreateMultiple operation in AppSuspectC2MatchApi.
 * @export
 * @interface AppSuspectC2MatchApiAppSuspectC2MatchControllerCreateMultipleRequest
 */
export interface AppSuspectC2MatchApiAppSuspectC2MatchControllerCreateMultipleRequest {
    /**
     * 
     * @type {CreateMultiManualMatchDto}
     * @memberof AppSuspectC2MatchApiAppSuspectC2MatchControllerCreateMultiple
     */
    readonly createMultiManualMatchDto: CreateMultiManualMatchDto

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2MatchApiAppSuspectC2MatchControllerCreateMultiple
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appSuspectC2MatchControllerFindAll operation in AppSuspectC2MatchApi.
 * @export
 * @interface AppSuspectC2MatchApiAppSuspectC2MatchControllerFindAllRequest
 */
export interface AppSuspectC2MatchApiAppSuspectC2MatchControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2MatchApiAppSuspectC2MatchControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appSuspectC2MatchControllerUnMatchAllC2Suspects operation in AppSuspectC2MatchApi.
 * @export
 * @interface AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchAllC2SuspectsRequest
 */
export interface AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchAllC2SuspectsRequest {
    /**
     * 
     * @type {UnmatchAllC2SuspectsDto}
     * @memberof AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchAllC2Suspects
     */
    readonly unmatchAllC2SuspectsDto: UnmatchAllC2SuspectsDto

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchAllC2Suspects
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appSuspectC2MatchControllerUnMatchSuspect operation in AppSuspectC2MatchApi.
 * @export
 * @interface AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchSuspectRequest
 */
export interface AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchSuspectRequest {
    /**
     * 
     * @type {UnMatchSuspectC2Dto}
     * @memberof AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchSuspect
     */
    readonly unMatchSuspectC2Dto: UnMatchSuspectC2Dto

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchSuspect
     */
    readonly xTenantId?: string
}

/**
 * AppSuspectC2MatchApi - object-oriented interface
 * @export
 * @class AppSuspectC2MatchApi
 * @extends {BaseAPI}
 */
export class AppSuspectC2MatchApi extends BaseAPI {
    /**
     * 
     * @summary Create App Suspect C2 Match Entry for Manual Match
     * @param {AppSuspectC2MatchApiAppSuspectC2MatchControllerCreateMultipleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2MatchApi
     */
    public appSuspectC2MatchControllerCreateMultiple(requestParameters: AppSuspectC2MatchApiAppSuspectC2MatchControllerCreateMultipleRequest, options?: AxiosRequestConfig) {
        return AppSuspectC2MatchApiFp(this.configuration).appSuspectC2MatchControllerCreateMultiple(requestParameters.createMultiManualMatchDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of AppSuspectC2Matches
     * @param {AppSuspectC2MatchApiAppSuspectC2MatchControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2MatchApi
     */
    public appSuspectC2MatchControllerFindAll(requestParameters: AppSuspectC2MatchApiAppSuspectC2MatchControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppSuspectC2MatchApiFp(this.configuration).appSuspectC2MatchControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Un-match all C2 suspects
     * @param {AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchAllC2SuspectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2MatchApi
     */
    public appSuspectC2MatchControllerUnMatchAllC2Suspects(requestParameters: AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchAllC2SuspectsRequest, options?: AxiosRequestConfig) {
        return AppSuspectC2MatchApiFp(this.configuration).appSuspectC2MatchControllerUnMatchAllC2Suspects(requestParameters.unmatchAllC2SuspectsDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Un-match suspect C2
     * @param {AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchSuspectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2MatchApi
     */
    public appSuspectC2MatchControllerUnMatchSuspect(requestParameters: AppSuspectC2MatchApiAppSuspectC2MatchControllerUnMatchSuspectRequest, options?: AxiosRequestConfig) {
        return AppSuspectC2MatchApiFp(this.configuration).appSuspectC2MatchControllerUnMatchSuspect(requestParameters.unMatchSuspectC2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
