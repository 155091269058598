/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UpdateDataWarehouseDefinitionDto } from '../model/';
// @ts-ignore
import { WarehouseFieldDefinition } from '../model/';
/**
 * WarehouseFieldDefinitionApi - axios parameter creator
 * @export
 */
export const WarehouseFieldDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get definition info against app id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseFieldDefinitionControllerFindAllWithAppId: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('warehouseFieldDefinitionControllerFindAllWithAppId', 'appId', appId)
            const localVarPath = `/api/warehouse-field-definition/find-all-with-app-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Map or unmap custom fields
         * @param {UpdateDataWarehouseDefinitionDto} updateDataWarehouseDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseFieldDefinitionControllerUpdateCustomFieldsInfo: async (updateDataWarehouseDefinitionDto: UpdateDataWarehouseDefinitionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDataWarehouseDefinitionDto' is not null or undefined
            assertParamExists('warehouseFieldDefinitionControllerUpdateCustomFieldsInfo', 'updateDataWarehouseDefinitionDto', updateDataWarehouseDefinitionDto)
            const localVarPath = `/api/warehouse-field-definition/update-custom-field-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataWarehouseDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseFieldDefinitionApi - functional programming interface
 * @export
 */
export const WarehouseFieldDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseFieldDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get definition info against app id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseFieldDefinitionControllerFindAllWithAppId(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseFieldDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseFieldDefinitionControllerFindAllWithAppId(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Map or unmap custom fields
         * @param {UpdateDataWarehouseDefinitionDto} updateDataWarehouseDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseFieldDefinitionControllerUpdateCustomFieldsInfo(updateDataWarehouseDefinitionDto: UpdateDataWarehouseDefinitionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseFieldDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseFieldDefinitionControllerUpdateCustomFieldsInfo(updateDataWarehouseDefinitionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseFieldDefinitionApi - factory interface
 * @export
 */
export const WarehouseFieldDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseFieldDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get definition info against app id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseFieldDefinitionControllerFindAllWithAppId(appId: string, xTenantId?: string, options?: any): AxiosPromise<Array<WarehouseFieldDefinition>> {
            return localVarFp.warehouseFieldDefinitionControllerFindAllWithAppId(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Map or unmap custom fields
         * @param {UpdateDataWarehouseDefinitionDto} updateDataWarehouseDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseFieldDefinitionControllerUpdateCustomFieldsInfo(updateDataWarehouseDefinitionDto: UpdateDataWarehouseDefinitionDto, xTenantId?: string, options?: any): AxiosPromise<Array<WarehouseFieldDefinition>> {
            return localVarFp.warehouseFieldDefinitionControllerUpdateCustomFieldsInfo(updateDataWarehouseDefinitionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for warehouseFieldDefinitionControllerFindAllWithAppId operation in WarehouseFieldDefinitionApi.
 * @export
 * @interface WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerFindAllWithAppIdRequest
 */
export interface WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerFindAllWithAppIdRequest {
    /**
     * 
     * @type {string}
     * @memberof WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerFindAllWithAppId
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerFindAllWithAppId
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for warehouseFieldDefinitionControllerUpdateCustomFieldsInfo operation in WarehouseFieldDefinitionApi.
 * @export
 * @interface WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerUpdateCustomFieldsInfoRequest
 */
export interface WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerUpdateCustomFieldsInfoRequest {
    /**
     * 
     * @type {UpdateDataWarehouseDefinitionDto}
     * @memberof WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerUpdateCustomFieldsInfo
     */
    readonly updateDataWarehouseDefinitionDto: UpdateDataWarehouseDefinitionDto

    /**
     * 
     * @type {string}
     * @memberof WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerUpdateCustomFieldsInfo
     */
    readonly xTenantId?: string
}

/**
 * WarehouseFieldDefinitionApi - object-oriented interface
 * @export
 * @class WarehouseFieldDefinitionApi
 * @extends {BaseAPI}
 */
export class WarehouseFieldDefinitionApi extends BaseAPI {
    /**
     * 
     * @summary Get definition info against app id
     * @param {WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerFindAllWithAppIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseFieldDefinitionApi
     */
    public warehouseFieldDefinitionControllerFindAllWithAppId(requestParameters: WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerFindAllWithAppIdRequest, options?: AxiosRequestConfig) {
        return WarehouseFieldDefinitionApiFp(this.configuration).warehouseFieldDefinitionControllerFindAllWithAppId(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Map or unmap custom fields
     * @param {WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerUpdateCustomFieldsInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseFieldDefinitionApi
     */
    public warehouseFieldDefinitionControllerUpdateCustomFieldsInfo(requestParameters: WarehouseFieldDefinitionApiWarehouseFieldDefinitionControllerUpdateCustomFieldsInfoRequest, options?: AxiosRequestConfig) {
        return WarehouseFieldDefinitionApiFp(this.configuration).warehouseFieldDefinitionControllerUpdateCustomFieldsInfo(requestParameters.updateDataWarehouseDefinitionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
