<script setup lang="ts">
import {
  phBook,
  phChartBar,
  phUserFocus,
  phUsers,
  phGear,
  phBookOpen,
  phTrophy,
  phTelegramLogo,
  phLineSegments,
  phNotebook,
} from '@/phosphor-icons';
import { RouteName } from '@/router/route-name';
import { useConfigStore } from '@/stores/config';
import AppDrawerItem from './AppDrawerItem.vue';
import { useAccountStore } from '@/stores/account';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { mdiEmailOutline } from '@quasar/extras/mdi-v7';
import { U1AccessRightEnum } from '@/api/client';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { FeatureFlagNames } from '@zapscale/types';

const configStore = useConfigStore();
const { isEnabled: inboxEnabled } = useFeatureFlag(FeatureFlagNames.inbox);

const _accountStore = useAccountStore();
const { isRoleBaseAccessEnabled, activeU1, defaultSegment } = storeToRefs(_accountStore);

const { isEnabled: campaignEnabled } = useFeatureFlag(FeatureFlagNames.campaign);
const { isEnabled: surveyEnabled } = useFeatureFlag(FeatureFlagNames.survey);

const appDrawerItems = computed(() => {
  const data = [];
  data.push({ name: 'Dashboard', to: { name: RouteName.Dashboard }, group: 'Dashboard', icon: phChartBar });

  if (import.meta.env.VITE_FF_SEGMENT_HEALTH_SCORE === '1') {
    // show segment health score view
    data.push({
      name: 'Segment',
      to: { name: RouteName.SegmentHealth },
      group: RouteName.SegmentHealth,
      icon: phLineSegments,
    });
  }

  if (isRoleBaseAccessEnabled.value) {
    data.push({
      name: 'Customer',
      to: { name: RouteName.Customers, query: { c2_segment: defaultSegment.value?.id } },
      group: RouteName.Customers,
      icon: phUserFocus,
    });
    data.push({
      name: 'User',
      to: { name: RouteName.Users, query: { c2_segment: defaultSegment.value?.id } },
      group: RouteName.Users,
      icon: phUsers,
    });
  } else {
    data.push({
      name: 'Customer',
      to: { name: RouteName.Customers, query: { u1: activeU1.value?.id } },
      group: RouteName.Customers,
      icon: phUserFocus,
    });
    data.push({
      name: 'User',
      to: { name: RouteName.Users, query: { u1: activeU1.value?.id } },
      group: RouteName.Users,
      icon: phUsers,
    });
  }

  data.push({ name: 'Zapbook', to: { name: RouteName.Zapbook }, group: RouteName.Zapbook, icon: phBook });
  data.push({ name: 'Playbooks', to: { name: RouteName.Playbooks }, group: RouteName.Playbooks, icon: phBookOpen });
  if (import.meta.env.VITE_FF_SUCCESS_PLAN_ENABLED === '1') {
    data.push({
      name: 'Success Plan',
      to: { name: RouteName.SuccessPlan },
      group: RouteName.SuccessPlan,
      icon: phTrophy,
    });
  }
  if (inboxEnabled.value) {
    data.push({
      name: 'Inbox',
      to: { name: RouteName.InboxList },
      group: RouteName.InboxList,
      icon: mdiEmailOutline,
    });
  }

  if (campaignEnabled.value) {
    data.push({
      name: 'Campaigns',
      to: { name: RouteName.CampaignsV1 },
      group: RouteName.CampaignsV1,
      icon: phTelegramLogo,
    });
  }

  if (surveyEnabled.value) {
    data.push({
      name: 'Surveys',
      to: { name: RouteName.Surveys },
      group: RouteName.Surveys,
      icon: phNotebook,
    });
  }

  if (import.meta.env.VITE_FF_ROLE_BASED_ACCESS === '0' || activeU1.value?.access_right === U1AccessRightEnum.Admin) {
    data.push({ name: 'Settings', to: { name: RouteName.Onboarding }, group: RouteName.Onboarding, icon: phGear });
  }

  return data;
});
</script>

<template>
  <q-drawer
    v-model="configStore.appDrawerOpen"
    side="left"
    bordered
    show-if-above
    mini
    :mini-width="80"
    class="tw-bg-light-shade-1 tw-text-light-shade-1 lg:tw-bg-primary-dark"
    active-class="router-link-active"
  >
    <q-scroll-area class="fit">
      <q-list>
        <app-drawer-item v-for="item in appDrawerItems" :item="item" :key="item.name" />
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<style scoped lang="postcss">
:deep(.router-link-active, .router-link-active:hover) {
  @apply lg:tw-bg-boston-blue;
}

:deep(.router-link-active) {
  @apply before:tw-absolute before:tw-left-0 before:tw-h-8 before:tw-w-0.5 before:tw-bg-secondary before:tw-content-[''] before:lg:tw-h-11;
}
</style>
