<script setup lang="ts">
import { Chart, ChartData } from 'chart.js';
import { onMounted, ref, watch } from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

const props = defineProps<{
  heading: string;
  dataSet: ChartData<'line'>;
  isLoading: boolean;
  hideLabels?: boolean | undefined;
}>();

const dataSets = {} as ChartData<'line'>;
let chartInstance: Chart<'line'>;
const chartOpacityLevel = ref<string>('tw-opacity-40');

watch(
  () => props.isLoading,
  (isLoading) => {
    if (isLoading) {
      chartOpacityLevel.value = 'tw-opacity-40';
    } else {
      chartOpacityLevel.value = 'tw-opacity-100';
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.dataSet,
  (newVal) => {
    if (chartInstance) {
      updateChartData(newVal);
    }
  },
  {
    immediate: true,
  },
);

function updateChartData(dataSet: ChartData<'line'>) {
  if (chartInstance) {
    chartInstance.data.labels = dataSet.labels;
    chartInstance.data.datasets = dataSet.datasets;
    chartInstance.update();
  }
}

// const selection = ref<{ label: string; value: string }>();

const canvas = ref<HTMLCanvasElement>();

const drawChart = () => {
  const ctx = canvas.value?.getContext('2d');
  if (!ctx) return;

  chartInstance = new Chart(ctx, {
    type: 'line',
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: !(props.hideLabels === undefined ? false : props.hideLabels),
          align: 'end',
          position: 'bottom',
          labels: {
            boxHeight: 1,
            boxWidth: 12,
            color: '#666666',
            font: {
              family: 'Hanken Grotesk',
              size: 10,
              weight: '700',
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Hanken Grotesk',
              size: 14,
              weight: '600',
            },
          },
        },
        y: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            font: {
              family: 'Hanken Grotesk',
              size: 14,
              weight: '600',
            },
            stepSize: 25,
          },
        },
      },
    },
    data: dataSets,
  });
};

onMounted(() => {
  drawChart();
  updateChartData(props.dataSet);
});
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-shadow">
    <LoadingIndicator class="tw-bg-light-shade-1" v-if="props.isLoading" />
    <div class="tw-px-6" :class="chartOpacityLevel">
      <div class="tw-flex tw-items-center tw-justify-between tw-pt-4">
        <span class="tw-text-base tw-text-dark-shade-3">{{ props.heading }}</span>

        <!-- TODO #frmGourav change width after discussion with Amitava and also discuss the border color -->
        <slot name="dropdown"></slot>
      </div>

      <div class="tw-relative tw-h-48 tw-py-2.5" :class="props.hideLabels ? 'tw-pb-6' : ''">
        <canvas ref="canvas" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="postcss">
:deep(.q-item) {
  @apply tw-p-0;
}
</style>
