<script setup lang="ts">
import {
  C1HealthScore,
  C2TypeHealthScore,
  DashboardSelectionTypeEnum,
  HealthColorEnum,
  KpiNameEnum,
} from '@/api/client';
import { useHealthTrendChart } from '@/composables/useHealthTrendChart';
import { MessageSchema } from '@/lib/i18n';
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { useDateStore } from '@/stores/date';
import { BarChartOptions } from '@/types/dashboard';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import PillToggle from '../PillToggle.vue';
import { ChartData } from 'chart.js';
import LoadingIndicator from '../LoadingIndicator.vue';
import BarChart from '../Charts/BarChart.vue';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { IQueryParam } from '@/types/query.type';
import { useRouter } from 'vue-router';
import { RouteName } from '@/router/route-name';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const _healthTrendChart = useHealthTrendChart();

const { healthScoreList, barChartFor, isHealthScoreListLoading, dashboardFor } = storeToRefs(useDashboardV2Store());

const router = useRouter();
const _dateStore = useDateStore();
const { getDateString } = useDateFormatter();

const filterOptions = [
  { value: BarChartOptions.customer, label: t(`dashboard.bar_chart_section.${BarChartOptions.customer}`) },
  { value: BarChartOptions.user, label: t(`dashboard.bar_chart_section.${BarChartOptions.user}`) },
];

const dataSet = computed(() => {
  const listOfLabel: string[] = [];
  const chartDatasets = [..._healthTrendChart.barChartDataSet];
  chartDatasets.forEach((x) => {
    x.data = [] as number[];
    x.ids = [] as string[];
    x.counts = [] as number[];
  });
  if (healthScoreList.value?.length <= 0) {
    return {
      labels: listOfLabel,
      datasets: [...chartDatasets],
    };
  }

  const healthList = [...healthScoreList.value];
  const dateList = [...new Set(healthList.map((x) => x.date))].sort();
  const selectedFilter = barChartFor.value;
  for (let i = 0; i < dateList.length; i++) {
    const date = dateList[i];
    listOfLabel.push(_dateStore.getDateLabel(date));

    const healthScore = healthList?.find((x: C1HealthScore | C2TypeHealthScore) => x.date === date);
    if (!healthScore) {
      continue;
    }
    const goodChart = _healthTrendChart.barChartDataSet.find((x) => x.label === 'Good');
    goodChart?.ids.push(healthScore.id);

    const fairChart = _healthTrendChart.barChartDataSet.find((x) => x.label === 'Fair');
    fairChart?.ids.push(healthScore.id);

    const badChart = _healthTrendChart.barChartDataSet.find((x) => x.label === 'Bad');
    badChart?.ids.push(healthScore.id);

    const inactiveChart = _healthTrendChart.barChartDataSet.find((x) => x.label === 'Inactive');
    inactiveChart?.ids.push(healthScore.id);

    const churnChart = _healthTrendChart.barChartDataSet.find((x) => x.label === 'Churn');
    churnChart?.ids.push(healthScore.id);

    type HealthScoreObjectKey = keyof typeof healthScore;
    const percentInGreen = `percent_${selectedFilter}_in_green` as HealthScoreObjectKey;
    const percentInYellow = `percent_${selectedFilter}_in_yellow` as HealthScoreObjectKey;
    const percentInRed = `percent_${selectedFilter}_in_red` as HealthScoreObjectKey;
    const percentInGrey = `percent_${selectedFilter}_in_grey` as HealthScoreObjectKey;
    const percentInBlack = `percent_${selectedFilter}_in_black` as HealthScoreObjectKey;

    let goodPercentage = healthScore[percentInGreen] as number;
    let fairPercentage = healthScore[percentInYellow] as number;
    let badPercentage = healthScore[percentInRed] as number;
    let inactivePercentage = healthScore[percentInGrey] as number;
    let churnPercentage = healthScore[percentInBlack] as number;

    const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
    if (total !== 0) {
      const difference = Math.abs(total - 100);
      if (churnPercentage) {
        churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
      } else if (inactivePercentage) {
        inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
      } else if (badPercentage) {
        badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
      } else if (fairPercentage) {
        fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
      } else {
        goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
      }
    }
    goodChart?.data.push(Math.round(goodPercentage));
    fairChart?.data.push(Math.round(fairPercentage));
    badChart?.data.push(Math.round(badPercentage));
    inactiveChart?.data.push(Math.round(inactivePercentage));
    churnChart?.data.push(Math.round(churnPercentage));

    const totalInGreen = `total_${selectedFilter}_in_green` as HealthScoreObjectKey;
    const totalInYellow = `total_${selectedFilter}_in_yellow` as HealthScoreObjectKey;
    const totalInRed = `total_${selectedFilter}_in_red` as HealthScoreObjectKey;
    const totalInGrey = `total_${selectedFilter}_in_grey` as HealthScoreObjectKey;
    const totalInBlack = `total_${selectedFilter}_in_black` as HealthScoreObjectKey;

    goodChart?.counts.push(healthScore[totalInGreen] as number);
    fairChart?.counts.push(healthScore[totalInYellow] as number);
    badChart?.counts.push(healthScore[totalInRed] as number);
    inactiveChart?.counts.push(healthScore[totalInGrey] as number);
    churnChart?.counts.push(healthScore[totalInBlack] as number);
  }
  return {
    labels: listOfLabel,
    datasets: [..._healthTrendChart.barChartDataSet],
  } as ChartData<'bar'>;
});

function onBarItemClick(parentIndex: number, childIndex: number) {
  const dataset = dataSet.value.datasets[parentIndex] as unknown as {
    label: string;
    ids: string[];
  };
  const label = dataset.label;
  const id = dataset.ids[childIndex];
  const healthList = [...healthScoreList.value];
  const healthScore = healthList?.find((x) => x.id === id);
  if (!healthScore?.date) {
    return;
  }
  let health: HealthColorEnum | null = null;
  switch (label.toLowerCase()) {
    case 'good':
      health = HealthColorEnum.Green;
      break;
    case 'fair':
      health = HealthColorEnum.Yellow;
      break;
    case 'bad':
      health = HealthColorEnum.Red;
      break;
    case 'inactive':
      health = HealthColorEnum.Grey;
      break;
    case 'churn':
      health = HealthColorEnum.Black;
      break;
  }

  try {
    const dateString = getDateString(new Date(healthScore.date));
    const queryParam = {
      date: dateString,
      date_type: _dateStore.dateInfo?.type,
      // u1: [activeU1Id.value],
      kpi: [KpiNameEnum.Zs],
      c2_type: dashboardFor.value?.type === DashboardSelectionTypeEnum.C2typehealth ? dashboardFor.value.id : undefined,
      c2_segment:
        dashboardFor.value?.type === DashboardSelectionTypeEnum.C2segmenthealth ? dashboardFor.value.id : undefined,
    } as IQueryParam;
    if (health) {
      queryParam.health = health;
    }
    switch (barChartFor.value) {
      case BarChartOptions.customer:
        router.push({
          name: RouteName.Customers,
          query: { ...queryParam, custom_view: '' },
        });
        break;
      case BarChartOptions.user:
        router.push({
          name: RouteName.Users,
          query: { ...queryParam },
        });
        break;
    }
  } catch (e) {
    console.error('error', e);
  }
}
</script>

<template>
  <BarChart :data-set="dataSet" :heading="t('bar_chart_section.title')" @on-bar-chart-item-click="onBarItemClick">
    <template #status>
      <LoadingIndicator v-if="isHealthScoreListLoading" class="tw-bg-light-shade-1" />
    </template>
    <template #filter v-if="!isHealthScoreListLoading">
      <PillToggle :options="filterOptions" v-model="barChartFor" />
    </template>
  </BarChart>
</template>
