/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppObject } from '../model/';
// @ts-ignore
import { CreateCustomObjectDto } from '../model/';
// @ts-ignore
import { CustomObjectDto } from '../model/';
/**
 * AppObjectsApi - axios parameter creator
 * @export
 */
export const AppObjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create custom objects
         * @param {CreateCustomObjectDto} createCustomObjectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerCreateCustomObjects: async (createCustomObjectDto: CreateCustomObjectDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomObjectDto' is not null or undefined
            assertParamExists('appObjectsControllerCreateCustomObjects', 'createCustomObjectDto', createCustomObjectDto)
            const localVarPath = `/api/app-objects/create-custom-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomObjectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch custom objects from peripheral apps
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerFetchCustomAppObjects: async (appName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('appObjectsControllerFetchCustomAppObjects', 'appName', appName)
            const localVarPath = `/api/app-objects/fetch-custom-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appName !== undefined) {
                localVarQueryParameter['app_name'] = appName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of App Objects
         * @param {string} [xTenantId] 
         * @param {string} [appId] Optional field
         * @param {boolean} [isMaster] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerFindAll: async (xTenantId?: string, appId?: string, isMaster?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appId !== undefined) {
                localVarQueryParameter['app_id'] = appId;
            }

            if (isMaster !== undefined) {
                localVarQueryParameter['is_master'] = isMaster;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of App Objects by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerFindAllByApp: async (appName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('appObjectsControllerFindAllByApp', 'appName', appName)
            const localVarPath = `/api/app-objects/by-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appName !== undefined) {
                localVarQueryParameter['app_name'] = appName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppObjectsApi - functional programming interface
 * @export
 */
export const AppObjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppObjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create custom objects
         * @param {CreateCustomObjectDto} createCustomObjectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObjectsControllerCreateCustomObjects(createCustomObjectDto: CreateCustomObjectDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObjectsControllerCreateCustomObjects(createCustomObjectDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch custom objects from peripheral apps
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObjectsControllerFetchCustomAppObjects(appName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomObjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObjectsControllerFetchCustomAppObjects(appName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of App Objects
         * @param {string} [xTenantId] 
         * @param {string} [appId] Optional field
         * @param {boolean} [isMaster] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObjectsControllerFindAll(xTenantId?: string, appId?: string, isMaster?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObjectsControllerFindAll(xTenantId, appId, isMaster, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of App Objects by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObjectsControllerFindAllByApp(appName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObjectsControllerFindAllByApp(appName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppObjectsApi - factory interface
 * @export
 */
export const AppObjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppObjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create custom objects
         * @param {CreateCustomObjectDto} createCustomObjectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerCreateCustomObjects(createCustomObjectDto: CreateCustomObjectDto, xTenantId?: string, options?: any): AxiosPromise<Array<AppObject>> {
            return localVarFp.appObjectsControllerCreateCustomObjects(createCustomObjectDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch custom objects from peripheral apps
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerFetchCustomAppObjects(appName: string, xTenantId?: string, options?: any): AxiosPromise<Array<CustomObjectDto>> {
            return localVarFp.appObjectsControllerFetchCustomAppObjects(appName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of App Objects
         * @param {string} [xTenantId] 
         * @param {string} [appId] Optional field
         * @param {boolean} [isMaster] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerFindAll(xTenantId?: string, appId?: string, isMaster?: boolean, options?: any): AxiosPromise<Array<AppObject>> {
            return localVarFp.appObjectsControllerFindAll(xTenantId, appId, isMaster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of App Objects by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsControllerFindAllByApp(appName: string, xTenantId?: string, options?: any): AxiosPromise<Array<AppObject>> {
            return localVarFp.appObjectsControllerFindAllByApp(appName, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appObjectsControllerCreateCustomObjects operation in AppObjectsApi.
 * @export
 * @interface AppObjectsApiAppObjectsControllerCreateCustomObjectsRequest
 */
export interface AppObjectsApiAppObjectsControllerCreateCustomObjectsRequest {
    /**
     * 
     * @type {CreateCustomObjectDto}
     * @memberof AppObjectsApiAppObjectsControllerCreateCustomObjects
     */
    readonly createCustomObjectDto: CreateCustomObjectDto

    /**
     * 
     * @type {string}
     * @memberof AppObjectsApiAppObjectsControllerCreateCustomObjects
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appObjectsControllerFetchCustomAppObjects operation in AppObjectsApi.
 * @export
 * @interface AppObjectsApiAppObjectsControllerFetchCustomAppObjectsRequest
 */
export interface AppObjectsApiAppObjectsControllerFetchCustomAppObjectsRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObjectsApiAppObjectsControllerFetchCustomAppObjects
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof AppObjectsApiAppObjectsControllerFetchCustomAppObjects
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appObjectsControllerFindAll operation in AppObjectsApi.
 * @export
 * @interface AppObjectsApiAppObjectsControllerFindAllRequest
 */
export interface AppObjectsApiAppObjectsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObjectsApiAppObjectsControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof AppObjectsApiAppObjectsControllerFindAll
     */
    readonly appId?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof AppObjectsApiAppObjectsControllerFindAll
     */
    readonly isMaster?: boolean
}

/**
 * Request parameters for appObjectsControllerFindAllByApp operation in AppObjectsApi.
 * @export
 * @interface AppObjectsApiAppObjectsControllerFindAllByAppRequest
 */
export interface AppObjectsApiAppObjectsControllerFindAllByAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObjectsApiAppObjectsControllerFindAllByApp
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof AppObjectsApiAppObjectsControllerFindAllByApp
     */
    readonly xTenantId?: string
}

/**
 * AppObjectsApi - object-oriented interface
 * @export
 * @class AppObjectsApi
 * @extends {BaseAPI}
 */
export class AppObjectsApi extends BaseAPI {
    /**
     * 
     * @summary Create custom objects
     * @param {AppObjectsApiAppObjectsControllerCreateCustomObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObjectsApi
     */
    public appObjectsControllerCreateCustomObjects(requestParameters: AppObjectsApiAppObjectsControllerCreateCustomObjectsRequest, options?: AxiosRequestConfig) {
        return AppObjectsApiFp(this.configuration).appObjectsControllerCreateCustomObjects(requestParameters.createCustomObjectDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch custom objects from peripheral apps
     * @param {AppObjectsApiAppObjectsControllerFetchCustomAppObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObjectsApi
     */
    public appObjectsControllerFetchCustomAppObjects(requestParameters: AppObjectsApiAppObjectsControllerFetchCustomAppObjectsRequest, options?: AxiosRequestConfig) {
        return AppObjectsApiFp(this.configuration).appObjectsControllerFetchCustomAppObjects(requestParameters.appName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of App Objects
     * @param {AppObjectsApiAppObjectsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObjectsApi
     */
    public appObjectsControllerFindAll(requestParameters: AppObjectsApiAppObjectsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppObjectsApiFp(this.configuration).appObjectsControllerFindAll(requestParameters.xTenantId, requestParameters.appId, requestParameters.isMaster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of App Objects by App
     * @param {AppObjectsApiAppObjectsControllerFindAllByAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObjectsApi
     */
    public appObjectsControllerFindAllByApp(requestParameters: AppObjectsApiAppObjectsControllerFindAllByAppRequest, options?: AxiosRequestConfig) {
        return AppObjectsApiFp(this.configuration).appObjectsControllerFindAllByApp(requestParameters.appName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
