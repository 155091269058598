<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import AppDrawer from '@/components/AppDrawer.vue';
// import OnboardingStatus from '@/components/Onboarding/OnboardingStatus.vue';
import OnboardingFooter from '../components/Onboarding/OnboardingFooter.vue';
import OnboardingNavbar from '@/components/Onboarding/OnboardingNavbar.vue';
import { useAccountStore } from '@/stores/account';

const accountStore = useAccountStore();
</script>

<template>
  <q-layout view="hHh LpR lFr">
    <app-header />

    <app-drawer v-if="accountStore.activeC1Master?.onboarding_completed_at" />

    <!-- <onboarding-status /> -->

    <q-page-container class="tw-relative tw-pb-6">
      <div
        class="tw-fixed tw-left-0 tw-right-0 tw-z-50 tw-flex tw-flex-shrink-0 tw-items-center tw-border-b tw-bg-white tw-px-5 lg:tw-left-20"
      >
        <onboarding-navbar class="tw-px-8" />
      </div>

      <div class="tw-pt-14">
        <slot />
      </div>
    </q-page-container>

    <onboarding-footer />
  </q-layout>
</template>
