<script setup lang="ts">
import { MessageSchema } from '@/lib/i18n';
import { useKpiStore } from '@/stores/kpi';
import { KpiForType } from '@/types/kpi.type';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const props = defineProps<{
  kpiId?: string;
  kpiFor: KpiForType;
}>();

const { kpiList } = storeToRefs(useKpiStore());

const kpi = computed(() => {
  return kpiList.value?.find((x) => x.id === props.kpiId);
});

const kpiDefinition = computed(() => {
  if (!kpi.value) {
    return '';
  }
  return t(`${props.kpiFor}.definition.${kpi.value.name}`);
});
</script>
<template>
  <div class="tw-flex tw-w-96 tw-flex-col tw-px-4 tw-py-6">
    <span class="tw-text-base tw-font-bold tw-text-primary-dark">
      {{ kpi?.display_name || '' }}
    </span>
    <span class="tw-whitespace-pre-line tw-pt-2 tw-text-sm tw-font-semibold tw-text-dark-shade-1">{{
      kpiDefinition
    }}</span>
  </div>
</template>
