/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CommunicationLog } from '../model/';
// @ts-ignore
import { CommunicationThreadFilterDto } from '../model/';
// @ts-ignore
import { CommunicationThreadPaginationDto } from '../model/';
/**
 * CommunicationLogApi - axios parameter creator
 * @export
 */
export const CommunicationLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of communication logs
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/communication-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated communication threads for c2
         * @param {CommunicationThreadFilterDto} communicationThreadFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindAllCommunicationThreadForCurrentC2: async (communicationThreadFilterDto: CommunicationThreadFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'communicationThreadFilterDto' is not null or undefined
            assertParamExists('communicationLogControllerFindAllCommunicationThreadForCurrentC2', 'communicationThreadFilterDto', communicationThreadFilterDto)
            const localVarPath = `/api/communication-log/c2-communication-threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(communicationThreadFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated slack communication threads for c2
         * @param {CommunicationThreadFilterDto} communicationThreadFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2: async (communicationThreadFilterDto: CommunicationThreadFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'communicationThreadFilterDto' is not null or undefined
            assertParamExists('communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2', 'communicationThreadFilterDto', communicationThreadFilterDto)
            const localVarPath = `/api/communication-log/c2-slack-communication-threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(communicationThreadFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all slack thread communications
         * @param {string} conversationId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindSlackThreadCommunications: async (conversationId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('communicationLogControllerFindSlackThreadCommunications', 'conversationId', conversationId)
            const localVarPath = `/api/communication-log/{conversation_id}/slack-thread-communications`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all thread communications
         * @param {string} conversationId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindThreadCommunications: async (conversationId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('communicationLogControllerFindThreadCommunications', 'conversationId', conversationId)
            const localVarPath = `/api/communication-log/{conversation_id}/thread-communications`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunicationLogApi - functional programming interface
 * @export
 */
export const CommunicationLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunicationLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of communication logs
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationLogControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunicationLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationLogControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated communication threads for c2
         * @param {CommunicationThreadFilterDto} communicationThreadFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationLogControllerFindAllCommunicationThreadForCurrentC2(communicationThreadFilterDto: CommunicationThreadFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunicationThreadPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationLogControllerFindAllCommunicationThreadForCurrentC2(communicationThreadFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated slack communication threads for c2
         * @param {CommunicationThreadFilterDto} communicationThreadFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2(communicationThreadFilterDto: CommunicationThreadFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunicationThreadPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2(communicationThreadFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all slack thread communications
         * @param {string} conversationId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationLogControllerFindSlackThreadCommunications(conversationId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunicationLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationLogControllerFindSlackThreadCommunications(conversationId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all thread communications
         * @param {string} conversationId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationLogControllerFindThreadCommunications(conversationId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunicationLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationLogControllerFindThreadCommunications(conversationId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunicationLogApi - factory interface
 * @export
 */
export const CommunicationLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunicationLogApiFp(configuration)
    return {
        /**
         * 
         * @summary List of communication logs
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<CommunicationLog>> {
            return localVarFp.communicationLogControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated communication threads for c2
         * @param {CommunicationThreadFilterDto} communicationThreadFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindAllCommunicationThreadForCurrentC2(communicationThreadFilterDto: CommunicationThreadFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<CommunicationThreadPaginationDto> {
            return localVarFp.communicationLogControllerFindAllCommunicationThreadForCurrentC2(communicationThreadFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated slack communication threads for c2
         * @param {CommunicationThreadFilterDto} communicationThreadFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2(communicationThreadFilterDto: CommunicationThreadFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<CommunicationThreadPaginationDto> {
            return localVarFp.communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2(communicationThreadFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all slack thread communications
         * @param {string} conversationId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindSlackThreadCommunications(conversationId: string, xTenantId?: string, options?: any): AxiosPromise<Array<CommunicationLog>> {
            return localVarFp.communicationLogControllerFindSlackThreadCommunications(conversationId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all thread communications
         * @param {string} conversationId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationLogControllerFindThreadCommunications(conversationId: string, xTenantId?: string, options?: any): AxiosPromise<Array<CommunicationLog>> {
            return localVarFp.communicationLogControllerFindThreadCommunications(conversationId, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for communicationLogControllerFindAll operation in CommunicationLogApi.
 * @export
 * @interface CommunicationLogApiCommunicationLogControllerFindAllRequest
 */
export interface CommunicationLogApiCommunicationLogControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for communicationLogControllerFindAllCommunicationThreadForCurrentC2 operation in CommunicationLogApi.
 * @export
 * @interface CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2Request
 */
export interface CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2Request {
    /**
     * 
     * @type {CommunicationThreadFilterDto}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly communicationThreadFilterDto: CommunicationThreadFilterDto

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2
     */
    readonly after?: string
}

/**
 * Request parameters for communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2 operation in CommunicationLogApi.
 * @export
 * @interface CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2Request
 */
export interface CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2Request {
    /**
     * 
     * @type {CommunicationThreadFilterDto}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly communicationThreadFilterDto: CommunicationThreadFilterDto

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2
     */
    readonly after?: string
}

/**
 * Request parameters for communicationLogControllerFindSlackThreadCommunications operation in CommunicationLogApi.
 * @export
 * @interface CommunicationLogApiCommunicationLogControllerFindSlackThreadCommunicationsRequest
 */
export interface CommunicationLogApiCommunicationLogControllerFindSlackThreadCommunicationsRequest {
    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindSlackThreadCommunications
     */
    readonly conversationId: string

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindSlackThreadCommunications
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for communicationLogControllerFindThreadCommunications operation in CommunicationLogApi.
 * @export
 * @interface CommunicationLogApiCommunicationLogControllerFindThreadCommunicationsRequest
 */
export interface CommunicationLogApiCommunicationLogControllerFindThreadCommunicationsRequest {
    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindThreadCommunications
     */
    readonly conversationId: string

    /**
     * 
     * @type {string}
     * @memberof CommunicationLogApiCommunicationLogControllerFindThreadCommunications
     */
    readonly xTenantId?: string
}

/**
 * CommunicationLogApi - object-oriented interface
 * @export
 * @class CommunicationLogApi
 * @extends {BaseAPI}
 */
export class CommunicationLogApi extends BaseAPI {
    /**
     * 
     * @summary List of communication logs
     * @param {CommunicationLogApiCommunicationLogControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLogApi
     */
    public communicationLogControllerFindAll(requestParameters: CommunicationLogApiCommunicationLogControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return CommunicationLogApiFp(this.configuration).communicationLogControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated communication threads for c2
     * @param {CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLogApi
     */
    public communicationLogControllerFindAllCommunicationThreadForCurrentC2(requestParameters: CommunicationLogApiCommunicationLogControllerFindAllCommunicationThreadForCurrentC2Request, options?: AxiosRequestConfig) {
        return CommunicationLogApiFp(this.configuration).communicationLogControllerFindAllCommunicationThreadForCurrentC2(requestParameters.communicationThreadFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated slack communication threads for c2
     * @param {CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLogApi
     */
    public communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2(requestParameters: CommunicationLogApiCommunicationLogControllerFindAllSlackCommunicationThreadForCurrentC2Request, options?: AxiosRequestConfig) {
        return CommunicationLogApiFp(this.configuration).communicationLogControllerFindAllSlackCommunicationThreadForCurrentC2(requestParameters.communicationThreadFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all slack thread communications
     * @param {CommunicationLogApiCommunicationLogControllerFindSlackThreadCommunicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLogApi
     */
    public communicationLogControllerFindSlackThreadCommunications(requestParameters: CommunicationLogApiCommunicationLogControllerFindSlackThreadCommunicationsRequest, options?: AxiosRequestConfig) {
        return CommunicationLogApiFp(this.configuration).communicationLogControllerFindSlackThreadCommunications(requestParameters.conversationId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all thread communications
     * @param {CommunicationLogApiCommunicationLogControllerFindThreadCommunicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLogApi
     */
    public communicationLogControllerFindThreadCommunications(requestParameters: CommunicationLogApiCommunicationLogControllerFindThreadCommunicationsRequest, options?: AxiosRequestConfig) {
        return CommunicationLogApiFp(this.configuration).communicationLogControllerFindThreadCommunications(requestParameters.conversationId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
