import { App } from 'vue';
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import AppLayoutAuthenticated from '@/layouts/AppLayoutAuthenticated.vue';
import AppLayoutOnboarding from '@/layouts/AppLayoutOnboarding.vue';
import AppLayoutManage from '@/layouts/AppLayoutManage.vue';
import AppLayoutScratch from '@/layouts/AppLayoutScratch.vue';

export const registerLayouts = (app: App<Element>) => {
  app.component('AppLayoutDefault', AppLayoutDefault);
  app.component('AppLayoutAuthenticated', AppLayoutAuthenticated);
  app.component('AppLayoutOnboarding', AppLayoutOnboarding);
  app.component('AppLayoutManage', AppLayoutManage);
  app.component('AppLayoutScratch', AppLayoutScratch);
};
