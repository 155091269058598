/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BlacklistUserEmail } from '../model/';
// @ts-ignore
import { CreateBlacklistUserDto } from '../model/';
// @ts-ignore
import { PaginatedBlacklistUserDto } from '../model/';
/**
 * BlacklistUserEmailApi - axios parameter creator
 * @export
 */
export const BlacklistUserEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mark user blacklisted
         * @param {CreateBlacklistUserDto} createBlacklistUserDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerCreate: async (createBlacklistUserDto: CreateBlacklistUserDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBlacklistUserDto' is not null or undefined
            assertParamExists('blacklistUserEmailControllerCreate', 'createBlacklistUserDto', createBlacklistUserDto)
            const localVarPath = `/api/blacklist-user-email/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBlacklistUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Blacklist user email
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerDelete: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blacklistUserEmailControllerDelete', 'id', id)
            const localVarPath = `/api/blacklist-user-email/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all blacklisted user emails
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerFindAllWithPagination: async (xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/blacklist-user-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Blacklist user email reason
         * @param {string} id 
         * @param {CreateBlacklistUserDto} createBlacklistUserDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerUpdateReason: async (id: string, createBlacklistUserDto: CreateBlacklistUserDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blacklistUserEmailControllerUpdateReason', 'id', id)
            // verify required parameter 'createBlacklistUserDto' is not null or undefined
            assertParamExists('blacklistUserEmailControllerUpdateReason', 'createBlacklistUserDto', createBlacklistUserDto)
            const localVarPath = `/api/blacklist-user-email/update-reason/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBlacklistUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlacklistUserEmailApi - functional programming interface
 * @export
 */
export const BlacklistUserEmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlacklistUserEmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Mark user blacklisted
         * @param {CreateBlacklistUserDto} createBlacklistUserDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blacklistUserEmailControllerCreate(createBlacklistUserDto: CreateBlacklistUserDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlacklistUserEmail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blacklistUserEmailControllerCreate(createBlacklistUserDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Blacklist user email
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blacklistUserEmailControllerDelete(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlacklistUserEmail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blacklistUserEmailControllerDelete(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all blacklisted user emails
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blacklistUserEmailControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBlacklistUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blacklistUserEmailControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Blacklist user email reason
         * @param {string} id 
         * @param {CreateBlacklistUserDto} createBlacklistUserDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blacklistUserEmailControllerUpdateReason(id: string, createBlacklistUserDto: CreateBlacklistUserDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlacklistUserEmail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blacklistUserEmailControllerUpdateReason(id, createBlacklistUserDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlacklistUserEmailApi - factory interface
 * @export
 */
export const BlacklistUserEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlacklistUserEmailApiFp(configuration)
    return {
        /**
         * 
         * @summary Mark user blacklisted
         * @param {CreateBlacklistUserDto} createBlacklistUserDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerCreate(createBlacklistUserDto: CreateBlacklistUserDto, xTenantId?: string, options?: any): AxiosPromise<Array<BlacklistUserEmail>> {
            return localVarFp.blacklistUserEmailControllerCreate(createBlacklistUserDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Blacklist user email
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerDelete(id: string, xTenantId?: string, options?: any): AxiosPromise<BlacklistUserEmail> {
            return localVarFp.blacklistUserEmailControllerDelete(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all blacklisted user emails
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedBlacklistUserDto> {
            return localVarFp.blacklistUserEmailControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Blacklist user email reason
         * @param {string} id 
         * @param {CreateBlacklistUserDto} createBlacklistUserDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistUserEmailControllerUpdateReason(id: string, createBlacklistUserDto: CreateBlacklistUserDto, xTenantId?: string, options?: any): AxiosPromise<BlacklistUserEmail> {
            return localVarFp.blacklistUserEmailControllerUpdateReason(id, createBlacklistUserDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for blacklistUserEmailControllerCreate operation in BlacklistUserEmailApi.
 * @export
 * @interface BlacklistUserEmailApiBlacklistUserEmailControllerCreateRequest
 */
export interface BlacklistUserEmailApiBlacklistUserEmailControllerCreateRequest {
    /**
     * 
     * @type {CreateBlacklistUserDto}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerCreate
     */
    readonly createBlacklistUserDto: CreateBlacklistUserDto

    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for blacklistUserEmailControllerDelete operation in BlacklistUserEmailApi.
 * @export
 * @interface BlacklistUserEmailApiBlacklistUserEmailControllerDeleteRequest
 */
export interface BlacklistUserEmailApiBlacklistUserEmailControllerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerDelete
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for blacklistUserEmailControllerFindAllWithPagination operation in BlacklistUserEmailApi.
 * @export
 * @interface BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPaginationRequest
 */
export interface BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPaginationRequest {
    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPagination
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPagination
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPagination
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPagination
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPagination
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPagination
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPagination
     */
    readonly after?: string
}

/**
 * Request parameters for blacklistUserEmailControllerUpdateReason operation in BlacklistUserEmailApi.
 * @export
 * @interface BlacklistUserEmailApiBlacklistUserEmailControllerUpdateReasonRequest
 */
export interface BlacklistUserEmailApiBlacklistUserEmailControllerUpdateReasonRequest {
    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerUpdateReason
     */
    readonly id: string

    /**
     * 
     * @type {CreateBlacklistUserDto}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerUpdateReason
     */
    readonly createBlacklistUserDto: CreateBlacklistUserDto

    /**
     * 
     * @type {string}
     * @memberof BlacklistUserEmailApiBlacklistUserEmailControllerUpdateReason
     */
    readonly xTenantId?: string
}

/**
 * BlacklistUserEmailApi - object-oriented interface
 * @export
 * @class BlacklistUserEmailApi
 * @extends {BaseAPI}
 */
export class BlacklistUserEmailApi extends BaseAPI {
    /**
     * 
     * @summary Mark user blacklisted
     * @param {BlacklistUserEmailApiBlacklistUserEmailControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistUserEmailApi
     */
    public blacklistUserEmailControllerCreate(requestParameters: BlacklistUserEmailApiBlacklistUserEmailControllerCreateRequest, options?: AxiosRequestConfig) {
        return BlacklistUserEmailApiFp(this.configuration).blacklistUserEmailControllerCreate(requestParameters.createBlacklistUserDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Blacklist user email
     * @param {BlacklistUserEmailApiBlacklistUserEmailControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistUserEmailApi
     */
    public blacklistUserEmailControllerDelete(requestParameters: BlacklistUserEmailApiBlacklistUserEmailControllerDeleteRequest, options?: AxiosRequestConfig) {
        return BlacklistUserEmailApiFp(this.configuration).blacklistUserEmailControllerDelete(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all blacklisted user emails
     * @param {BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPaginationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistUserEmailApi
     */
    public blacklistUserEmailControllerFindAllWithPagination(requestParameters: BlacklistUserEmailApiBlacklistUserEmailControllerFindAllWithPaginationRequest = {}, options?: AxiosRequestConfig) {
        return BlacklistUserEmailApiFp(this.configuration).blacklistUserEmailControllerFindAllWithPagination(requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Blacklist user email reason
     * @param {BlacklistUserEmailApiBlacklistUserEmailControllerUpdateReasonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistUserEmailApi
     */
    public blacklistUserEmailControllerUpdateReason(requestParameters: BlacklistUserEmailApiBlacklistUserEmailControllerUpdateReasonRequest, options?: AxiosRequestConfig) {
        return BlacklistUserEmailApiFp(this.configuration).blacklistUserEmailControllerUpdateReason(requestParameters.id, requestParameters.createBlacklistUserDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
