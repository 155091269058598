<script setup lang="ts">
import { Chart, ChartData } from 'chart.js';
import { onMounted, ref, watch } from 'vue';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const props = defineProps<{
  heading: string;
  dataSet: ChartData<'sankey'>;
}>();

const canvas = ref<HTMLCanvasElement>();
let chartInstance: Chart<'sankey'>;

watch(
  () => props.dataSet,
  (newVal) => {
    if (chartInstance) {
      updateChartData(newVal);
    }
  },
  {
    immediate: true,
  },
);

const drawChart = () => {
  const ctx = canvas.value?.getContext('2d');
  if (!ctx) return;

  chartInstance = new Chart(ctx, {
    type: 'sankey',
    data: props.dataSet,
    options: {
      maintainAspectRatio: false,
      // onClick: function (e: MouseEvent, clickedElement: any[]) {
      //   const parentIndex = clickedElement[0].datasetIndex as number;
      //   const childIndex = clickedElement[0].index as number;
      //   // console.log('clicked');
      //   // emits('onBarChartItemClick', parentIndex, childIndex);
      // },
      // onHover: function (e: any) {
      //   const points = chartInstance.getElementsAtEventForMode(e, 'index', { axis: 'x', intersect: true }, false);
      //   if (points.length) {
      //     e.native.target.style.cursor = 'pointer';
      //   } else {
      //     e.native.target.style.cursor = 'default';
      //   }
      // },
      plugins: {
        tooltip: {
          caretSize: 0,
          enabled: true,
          usePointStyle: true,
          boxWidth: 3.5,
          boxPadding: 5,
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: function (ctx: any) {
              const element = chartInstance.getActiveElements()[0];
              const index = element.index;
              const dataset = ctx.dataset;
              const data = dataset.data[index];
              if (!data) {
                return '';
              }
              const count = data.flow;
              const from = t(`sankey.${data.from}`);
              const to = t(`sankey.${data.to}`);
              return `${count} - (${from} - ${to})`;
            },
          },
        },
      },
    },
  });
};

onMounted(() => {
  drawChart();
  updateChartData(props.dataSet);
});

function updateChartData(dataSet: ChartData<'sankey'>) {
  if (!chartInstance && dataSet.datasets) {
    return;
  }
  chartInstance.data.datasets = dataSet.datasets;
  chartInstance.data.labels = [];
  chartInstance.update();
}
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-px-6 tw-shadow">
    <slot name="status"></slot>
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-pt-4">
      <div class="tw-flex tw-flex-col">
        <span class="tw-text-base tw-text-dark-shade-3">{{ props.heading }}</span>
      </div>

      <slot name="filter"></slot>
    </div>

    <div class="tw-relative tw-mt-9 tw-h-48">
      <canvas ref="canvas" />
    </div>
    <slot name="footer"></slot>
  </div>
</template>
