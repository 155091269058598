/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppObjectsField } from '../model/';
// @ts-ignore
import { RemoveLinkedZapscaleObjectDto } from '../model/';
/**
 * AppObjectsFieldsApi - axios parameter creator
 * @export
 */
export const AppObjectsFieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of App Objects Fields
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsFieldsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-objects-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove linked zapscale object
         * @param {RemoveLinkedZapscaleObjectDto} removeLinkedZapscaleObjectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields: async (removeLinkedZapscaleObjectDto: RemoveLinkedZapscaleObjectDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeLinkedZapscaleObjectDto' is not null or undefined
            assertParamExists('appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields', 'removeLinkedZapscaleObjectDto', removeLinkedZapscaleObjectDto)
            const localVarPath = `/api/app-objects-fields/remove-linked-zapscale-object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeLinkedZapscaleObjectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Manually Marked Primary Field by U1
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsFieldsControllerRestoreManualPrimaryField: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appObjectsFieldsControllerRestoreManualPrimaryField', 'id', id)
            const localVarPath = `/api/app-objects-fields/{id}/reset-manual-primary-field`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppObjectsFieldsApi - functional programming interface
 * @export
 */
export const AppObjectsFieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppObjectsFieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of App Objects Fields
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObjectsFieldsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppObjectsField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObjectsFieldsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove linked zapscale object
         * @param {RemoveLinkedZapscaleObjectDto} removeLinkedZapscaleObjectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields(removeLinkedZapscaleObjectDto: RemoveLinkedZapscaleObjectDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppObjectsField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields(removeLinkedZapscaleObjectDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset Manually Marked Primary Field by U1
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appObjectsFieldsControllerRestoreManualPrimaryField(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppObjectsField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appObjectsFieldsControllerRestoreManualPrimaryField(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppObjectsFieldsApi - factory interface
 * @export
 */
export const AppObjectsFieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppObjectsFieldsApiFp(configuration)
    return {
        /**
         * 
         * @summary List of App Objects Fields
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsFieldsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AppObjectsField>> {
            return localVarFp.appObjectsFieldsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove linked zapscale object
         * @param {RemoveLinkedZapscaleObjectDto} removeLinkedZapscaleObjectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields(removeLinkedZapscaleObjectDto: RemoveLinkedZapscaleObjectDto, xTenantId?: string, options?: any): AxiosPromise<AppObjectsField> {
            return localVarFp.appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields(removeLinkedZapscaleObjectDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Manually Marked Primary Field by U1
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appObjectsFieldsControllerRestoreManualPrimaryField(id: string, xTenantId?: string, options?: any): AxiosPromise<AppObjectsField> {
            return localVarFp.appObjectsFieldsControllerRestoreManualPrimaryField(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appObjectsFieldsControllerFindAll operation in AppObjectsFieldsApi.
 * @export
 * @interface AppObjectsFieldsApiAppObjectsFieldsControllerFindAllRequest
 */
export interface AppObjectsFieldsApiAppObjectsFieldsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObjectsFieldsApiAppObjectsFieldsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields operation in AppObjectsFieldsApi.
 * @export
 * @interface AppObjectsFieldsApiAppObjectsFieldsControllerRemoveLinkedZapscaleObjectFieldsRequest
 */
export interface AppObjectsFieldsApiAppObjectsFieldsControllerRemoveLinkedZapscaleObjectFieldsRequest {
    /**
     * 
     * @type {RemoveLinkedZapscaleObjectDto}
     * @memberof AppObjectsFieldsApiAppObjectsFieldsControllerRemoveLinkedZapscaleObjectFields
     */
    readonly removeLinkedZapscaleObjectDto: RemoveLinkedZapscaleObjectDto

    /**
     * 
     * @type {string}
     * @memberof AppObjectsFieldsApiAppObjectsFieldsControllerRemoveLinkedZapscaleObjectFields
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appObjectsFieldsControllerRestoreManualPrimaryField operation in AppObjectsFieldsApi.
 * @export
 * @interface AppObjectsFieldsApiAppObjectsFieldsControllerRestoreManualPrimaryFieldRequest
 */
export interface AppObjectsFieldsApiAppObjectsFieldsControllerRestoreManualPrimaryFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof AppObjectsFieldsApiAppObjectsFieldsControllerRestoreManualPrimaryField
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppObjectsFieldsApiAppObjectsFieldsControllerRestoreManualPrimaryField
     */
    readonly xTenantId?: string
}

/**
 * AppObjectsFieldsApi - object-oriented interface
 * @export
 * @class AppObjectsFieldsApi
 * @extends {BaseAPI}
 */
export class AppObjectsFieldsApi extends BaseAPI {
    /**
     * 
     * @summary List of App Objects Fields
     * @param {AppObjectsFieldsApiAppObjectsFieldsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObjectsFieldsApi
     */
    public appObjectsFieldsControllerFindAll(requestParameters: AppObjectsFieldsApiAppObjectsFieldsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppObjectsFieldsApiFp(this.configuration).appObjectsFieldsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove linked zapscale object
     * @param {AppObjectsFieldsApiAppObjectsFieldsControllerRemoveLinkedZapscaleObjectFieldsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObjectsFieldsApi
     */
    public appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields(requestParameters: AppObjectsFieldsApiAppObjectsFieldsControllerRemoveLinkedZapscaleObjectFieldsRequest, options?: AxiosRequestConfig) {
        return AppObjectsFieldsApiFp(this.configuration).appObjectsFieldsControllerRemoveLinkedZapscaleObjectFields(requestParameters.removeLinkedZapscaleObjectDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Manually Marked Primary Field by U1
     * @param {AppObjectsFieldsApiAppObjectsFieldsControllerRestoreManualPrimaryFieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppObjectsFieldsApi
     */
    public appObjectsFieldsControllerRestoreManualPrimaryField(requestParameters: AppObjectsFieldsApiAppObjectsFieldsControllerRestoreManualPrimaryFieldRequest, options?: AxiosRequestConfig) {
        return AppObjectsFieldsApiFp(this.configuration).appObjectsFieldsControllerRestoreManualPrimaryField(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
