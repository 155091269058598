/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppUniqueFieldValue } from '../model/';
// @ts-ignore
import { UpdateAppUniqueFieldValueDto } from '../model/';
/**
 * AppUniqueFieldValuesApi - axios parameter creator
 * @export
 */
export const AppUniqueFieldValuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of App Unique Field Values
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-unique-field-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find All App Unique Field Values By App Name
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerFindAllByAppName: async (appName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('appUniqueFieldValuesControllerFindAllByAppName', 'appName', appName)
            const localVarPath = `/api/app-unique-field-values/by-app_name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appName !== undefined) {
                localVarQueryParameter['app_name'] = appName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find One App Unique Field Value With Field Identifier
         * @param {string} appName 
         * @param {string} fieldIdentifier 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerFindOneByFieldIdentifier: async (appName: string, fieldIdentifier: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('appUniqueFieldValuesControllerFindOneByFieldIdentifier', 'appName', appName)
            // verify required parameter 'fieldIdentifier' is not null or undefined
            assertParamExists('appUniqueFieldValuesControllerFindOneByFieldIdentifier', 'fieldIdentifier', fieldIdentifier)
            const localVarPath = `/api/app-unique-field-values/by-field-identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appName !== undefined) {
                localVarQueryParameter['app_name'] = appName;
            }

            if (fieldIdentifier !== undefined) {
                localVarQueryParameter['field_identifier'] = fieldIdentifier;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Manually Marked Primary Field by U1
         * @param {string} id 
         * @param {UpdateAppUniqueFieldValueDto} updateAppUniqueFieldValueDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerUpdateUserSelectedFields: async (id: string, updateAppUniqueFieldValueDto: UpdateAppUniqueFieldValueDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appUniqueFieldValuesControllerUpdateUserSelectedFields', 'id', id)
            // verify required parameter 'updateAppUniqueFieldValueDto' is not null or undefined
            assertParamExists('appUniqueFieldValuesControllerUpdateUserSelectedFields', 'updateAppUniqueFieldValueDto', updateAppUniqueFieldValueDto)
            const localVarPath = `/api/app-unique-field-values/{id}/update-user-selected-fields`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppUniqueFieldValueDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppUniqueFieldValuesApi - functional programming interface
 * @export
 */
export const AppUniqueFieldValuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppUniqueFieldValuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of App Unique Field Values
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUniqueFieldValuesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppUniqueFieldValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUniqueFieldValuesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find All App Unique Field Values By App Name
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUniqueFieldValuesControllerFindAllByAppName(appName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppUniqueFieldValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUniqueFieldValuesControllerFindAllByAppName(appName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find One App Unique Field Value With Field Identifier
         * @param {string} appName 
         * @param {string} fieldIdentifier 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUniqueFieldValuesControllerFindOneByFieldIdentifier(appName: string, fieldIdentifier: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUniqueFieldValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUniqueFieldValuesControllerFindOneByFieldIdentifier(appName, fieldIdentifier, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset Manually Marked Primary Field by U1
         * @param {string} id 
         * @param {UpdateAppUniqueFieldValueDto} updateAppUniqueFieldValueDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUniqueFieldValuesControllerUpdateUserSelectedFields(id: string, updateAppUniqueFieldValueDto: UpdateAppUniqueFieldValueDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUniqueFieldValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUniqueFieldValuesControllerUpdateUserSelectedFields(id, updateAppUniqueFieldValueDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppUniqueFieldValuesApi - factory interface
 * @export
 */
export const AppUniqueFieldValuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppUniqueFieldValuesApiFp(configuration)
    return {
        /**
         * 
         * @summary List of App Unique Field Values
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AppUniqueFieldValue>> {
            return localVarFp.appUniqueFieldValuesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find All App Unique Field Values By App Name
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerFindAllByAppName(appName: string, xTenantId?: string, options?: any): AxiosPromise<Array<AppUniqueFieldValue>> {
            return localVarFp.appUniqueFieldValuesControllerFindAllByAppName(appName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find One App Unique Field Value With Field Identifier
         * @param {string} appName 
         * @param {string} fieldIdentifier 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerFindOneByFieldIdentifier(appName: string, fieldIdentifier: string, xTenantId?: string, options?: any): AxiosPromise<AppUniqueFieldValue> {
            return localVarFp.appUniqueFieldValuesControllerFindOneByFieldIdentifier(appName, fieldIdentifier, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Manually Marked Primary Field by U1
         * @param {string} id 
         * @param {UpdateAppUniqueFieldValueDto} updateAppUniqueFieldValueDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUniqueFieldValuesControllerUpdateUserSelectedFields(id: string, updateAppUniqueFieldValueDto: UpdateAppUniqueFieldValueDto, xTenantId?: string, options?: any): AxiosPromise<AppUniqueFieldValue> {
            return localVarFp.appUniqueFieldValuesControllerUpdateUserSelectedFields(id, updateAppUniqueFieldValueDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appUniqueFieldValuesControllerFindAll operation in AppUniqueFieldValuesApi.
 * @export
 * @interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllRequest
 */
export interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appUniqueFieldValuesControllerFindAllByAppName operation in AppUniqueFieldValuesApi.
 * @export
 * @interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllByAppNameRequest
 */
export interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllByAppNameRequest {
    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllByAppName
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllByAppName
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appUniqueFieldValuesControllerFindOneByFieldIdentifier operation in AppUniqueFieldValuesApi.
 * @export
 * @interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindOneByFieldIdentifierRequest
 */
export interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindOneByFieldIdentifierRequest {
    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindOneByFieldIdentifier
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindOneByFieldIdentifier
     */
    readonly fieldIdentifier: string

    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindOneByFieldIdentifier
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appUniqueFieldValuesControllerUpdateUserSelectedFields operation in AppUniqueFieldValuesApi.
 * @export
 * @interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerUpdateUserSelectedFieldsRequest
 */
export interface AppUniqueFieldValuesApiAppUniqueFieldValuesControllerUpdateUserSelectedFieldsRequest {
    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerUpdateUserSelectedFields
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAppUniqueFieldValueDto}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerUpdateUserSelectedFields
     */
    readonly updateAppUniqueFieldValueDto: UpdateAppUniqueFieldValueDto

    /**
     * 
     * @type {string}
     * @memberof AppUniqueFieldValuesApiAppUniqueFieldValuesControllerUpdateUserSelectedFields
     */
    readonly xTenantId?: string
}

/**
 * AppUniqueFieldValuesApi - object-oriented interface
 * @export
 * @class AppUniqueFieldValuesApi
 * @extends {BaseAPI}
 */
export class AppUniqueFieldValuesApi extends BaseAPI {
    /**
     * 
     * @summary List of App Unique Field Values
     * @param {AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUniqueFieldValuesApi
     */
    public appUniqueFieldValuesControllerFindAll(requestParameters: AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppUniqueFieldValuesApiFp(this.configuration).appUniqueFieldValuesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find All App Unique Field Values By App Name
     * @param {AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllByAppNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUniqueFieldValuesApi
     */
    public appUniqueFieldValuesControllerFindAllByAppName(requestParameters: AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindAllByAppNameRequest, options?: AxiosRequestConfig) {
        return AppUniqueFieldValuesApiFp(this.configuration).appUniqueFieldValuesControllerFindAllByAppName(requestParameters.appName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find One App Unique Field Value With Field Identifier
     * @param {AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindOneByFieldIdentifierRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUniqueFieldValuesApi
     */
    public appUniqueFieldValuesControllerFindOneByFieldIdentifier(requestParameters: AppUniqueFieldValuesApiAppUniqueFieldValuesControllerFindOneByFieldIdentifierRequest, options?: AxiosRequestConfig) {
        return AppUniqueFieldValuesApiFp(this.configuration).appUniqueFieldValuesControllerFindOneByFieldIdentifier(requestParameters.appName, requestParameters.fieldIdentifier, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Manually Marked Primary Field by U1
     * @param {AppUniqueFieldValuesApiAppUniqueFieldValuesControllerUpdateUserSelectedFieldsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUniqueFieldValuesApi
     */
    public appUniqueFieldValuesControllerUpdateUserSelectedFields(requestParameters: AppUniqueFieldValuesApiAppUniqueFieldValuesControllerUpdateUserSelectedFieldsRequest, options?: AxiosRequestConfig) {
        return AppUniqueFieldValuesApiFp(this.configuration).appUniqueFieldValuesControllerUpdateUserSelectedFields(requestParameters.id, requestParameters.updateAppUniqueFieldValueDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
