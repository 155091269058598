/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateU2RoleDto } from '../model/';
// @ts-ignore
import { U2Role } from '../model/';
// @ts-ignore
import { UpdateU2RoleDto } from '../model/';
/**
 * U2RoleApi - axios parameter creator
 * @export
 */
export const U2RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create U2-Role
         * @param {CreateU2RoleDto} createU2RoleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerCreate: async (createU2RoleDto: CreateU2RoleDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createU2RoleDto' is not null or undefined
            assertParamExists('u2RolesControllerCreate', 'createU2RoleDto', createU2RoleDto)
            const localVarPath = `/api/u2-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createU2RoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of U2-Role
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u2-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a U2-Role against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2RolesControllerFindOne', 'id', id)
            const localVarPath = `/api/u2-roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a U2-Role against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2RolesControllerRemove', 'id', id)
            const localVarPath = `/api/u2-roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a U2-Role against ID
         * @param {string} id 
         * @param {UpdateU2RoleDto} updateU2RoleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerUpdate: async (id: string, updateU2RoleDto: UpdateU2RoleDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2RolesControllerUpdate', 'id', id)
            // verify required parameter 'updateU2RoleDto' is not null or undefined
            assertParamExists('u2RolesControllerUpdate', 'updateU2RoleDto', updateU2RoleDto)
            const localVarPath = `/api/u2-roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateU2RoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2RoleApi - functional programming interface
 * @export
 */
export const U2RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create U2-Role
         * @param {CreateU2RoleDto} createU2RoleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2RolesControllerCreate(createU2RoleDto: CreateU2RoleDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2RolesControllerCreate(createU2RoleDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of U2-Role
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2RolesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2RolesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a U2-Role against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2RolesControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2RolesControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a U2-Role against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2RolesControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2RolesControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a U2-Role against ID
         * @param {string} id 
         * @param {UpdateU2RoleDto} updateU2RoleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2RolesControllerUpdate(id: string, updateU2RoleDto: UpdateU2RoleDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2RolesControllerUpdate(id, updateU2RoleDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2RoleApi - factory interface
 * @export
 */
export const U2RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2RoleApiFp(configuration)
    return {
        /**
         * 
         * @summary Create U2-Role
         * @param {CreateU2RoleDto} createU2RoleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerCreate(createU2RoleDto: CreateU2RoleDto, xTenantId?: string, options?: any): AxiosPromise<U2Role> {
            return localVarFp.u2RolesControllerCreate(createU2RoleDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of U2-Role
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<U2Role>> {
            return localVarFp.u2RolesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a U2-Role against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<U2Role> {
            return localVarFp.u2RolesControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a U2-Role against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<U2Role> {
            return localVarFp.u2RolesControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a U2-Role against ID
         * @param {string} id 
         * @param {UpdateU2RoleDto} updateU2RoleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RolesControllerUpdate(id: string, updateU2RoleDto: UpdateU2RoleDto, xTenantId?: string, options?: any): AxiosPromise<U2Role> {
            return localVarFp.u2RolesControllerUpdate(id, updateU2RoleDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2RolesControllerCreate operation in U2RoleApi.
 * @export
 * @interface U2RoleApiU2RolesControllerCreateRequest
 */
export interface U2RoleApiU2RolesControllerCreateRequest {
    /**
     * 
     * @type {CreateU2RoleDto}
     * @memberof U2RoleApiU2RolesControllerCreate
     */
    readonly createU2RoleDto: CreateU2RoleDto

    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2RolesControllerFindAll operation in U2RoleApi.
 * @export
 * @interface U2RoleApiU2RolesControllerFindAllRequest
 */
export interface U2RoleApiU2RolesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2RolesControllerFindOne operation in U2RoleApi.
 * @export
 * @interface U2RoleApiU2RolesControllerFindOneRequest
 */
export interface U2RoleApiU2RolesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2RolesControllerRemove operation in U2RoleApi.
 * @export
 * @interface U2RoleApiU2RolesControllerRemoveRequest
 */
export interface U2RoleApiU2RolesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2RolesControllerUpdate operation in U2RoleApi.
 * @export
 * @interface U2RoleApiU2RolesControllerUpdateRequest
 */
export interface U2RoleApiU2RolesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateU2RoleDto}
     * @memberof U2RoleApiU2RolesControllerUpdate
     */
    readonly updateU2RoleDto: UpdateU2RoleDto

    /**
     * 
     * @type {string}
     * @memberof U2RoleApiU2RolesControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * U2RoleApi - object-oriented interface
 * @export
 * @class U2RoleApi
 * @extends {BaseAPI}
 */
export class U2RoleApi extends BaseAPI {
    /**
     * 
     * @summary Create U2-Role
     * @param {U2RoleApiU2RolesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2RoleApi
     */
    public u2RolesControllerCreate(requestParameters: U2RoleApiU2RolesControllerCreateRequest, options?: AxiosRequestConfig) {
        return U2RoleApiFp(this.configuration).u2RolesControllerCreate(requestParameters.createU2RoleDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of U2-Role
     * @param {U2RoleApiU2RolesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2RoleApi
     */
    public u2RolesControllerFindAll(requestParameters: U2RoleApiU2RolesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return U2RoleApiFp(this.configuration).u2RolesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a U2-Role against ID
     * @param {U2RoleApiU2RolesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2RoleApi
     */
    public u2RolesControllerFindOne(requestParameters: U2RoleApiU2RolesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return U2RoleApiFp(this.configuration).u2RolesControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a U2-Role against ID
     * @param {U2RoleApiU2RolesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2RoleApi
     */
    public u2RolesControllerRemove(requestParameters: U2RoleApiU2RolesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return U2RoleApiFp(this.configuration).u2RolesControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a U2-Role against ID
     * @param {U2RoleApiU2RolesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2RoleApi
     */
    public u2RolesControllerUpdate(requestParameters: U2RoleApiU2RolesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return U2RoleApiFp(this.configuration).u2RolesControllerUpdate(requestParameters.id, requestParameters.updateU2RoleDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
