<script setup lang="ts">
import { Chart, ChartData, ChartTypeRegistry } from 'chart.js';
import { onMounted, ref, watch } from 'vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PillToggle from '../PillToggle.vue';
import { useHealthTrendChart } from '@/composables/useHealthTrendChart';
import { useDashboardStore } from '@/stores/dashboard';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import LoadingIndicator from '../LoadingIndicator.vue';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});
const emits = defineEmits(['onBarChartItemClick']);
const props = defineProps<{ dataSet: ChartData<'bar'>; isLoading: boolean }>();
const healthTrendChartData = useHealthTrendChart();
const dashboardStore = useDashboardStore();

const canvas = ref<HTMLCanvasElement>();

let chartInstance: Chart<keyof ChartTypeRegistry, number[], unknown>;
const dataSets = {} as ChartData<'bar'>;
const chartOpacityLevel = ref<string>('tw-opacity-40');

watch(
  () => props.isLoading,
  (isLoading) => {
    if (isLoading) {
      chartOpacityLevel.value = 'tw-opacity-40';
    } else {
      chartOpacityLevel.value = 'tw-opacity-100';
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.dataSet,
  (newVal) => {
    updateChartData(newVal);
  },
  {
    immediate: true,
  },
);

function updateChartData(newVal: ChartData<'bar'>) {
  if (!chartInstance) {
    return;
  }
  chartInstance.data.labels = newVal.labels;
  chartInstance.data.datasets = newVal.datasets;
  chartInstance.update();
}

const drawChart = () => {
  const ctx = canvas.value?.getContext('2d');
  if (!ctx) return;

  chartInstance = new Chart(ctx, {
    type: 'bar',
    plugins: [ChartDataLabels],
    options: {
      maintainAspectRatio: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: function (e: MouseEvent, clickedElement: any[]) {
        const parentIndex = clickedElement[0].datasetIndex as number;
        const childIndex = clickedElement[0].index as number;
        emits('onBarChartItemClick', parentIndex, childIndex);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onHover: function (e: any) {
        const points = chartInstance.getElementsAtEventForMode(e, 'index', { axis: 'x', intersect: true }, false);
        if (points.length) {
          e.native.target.style.cursor = 'pointer';
        } else {
          e.native.target.style.cursor = 'default';
        }
      },
      plugins: {
        legend: {
          align: 'end',
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 3.5,
            color: '#666666',
            font: {
              family: 'Hanken Grotesk',
              size: 10,
              weight: '700',
            },
          },
        },
        tooltip: {
          caretSize: 0,
          enabled: true,
          usePointStyle: true,
          boxWidth: 3.5,
          boxPadding: 5,
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: function (ctx: any) {
              const element = chartInstance.getActiveElements()[0];
              const dataset = ctx.dataset;
              let label = dataset.label;
              const count = ctx.dataset.counts[element.index];
              return `${label} - ${count}`;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            font: {
              family: 'Hanken Grotesk',
              size: 14,
              weight: '600',
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
          max: 100,
        },
      },
    },
    data: dataSets,
  });
};

onMounted(() => {
  drawChart();
  if (props.dataSet) {
    updateChartData(props.dataSet);
  }
});
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-shadow">
    <LoadingIndicator class="tw-bg-light-shade-1" v-if="props.isLoading" />
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-px-6 tw-pt-4">
      <div class="tw-flex tw-flex-col">
        <span class="tw-text-base tw-text-dark-shade-3">{{ t('customer_user_health_trend') }}</span>

        <!-- <div class="tw-flex tw-items-center">
          <span class="tw-text-sm tw-text-dark-shade-1 tw-font-semibold">This Year</span>
          <q-icon :name="phTriangleFill" class="tw-text-[0.625rem] tw-fill-data-viz-semantic-green-content tw-mx-1" />
          <span class="tw-text-xs tw-font-semibold tw-text-dark-shade-2">12%</span>
        </div> -->
      </div>

      <PillToggle
        v-if="!props.isLoading"
        :options="healthTrendChartData.filterOptions"
        v-model="dashboardStore.healthTrendSelectedFilter"
      />
    </div>

    <div class="tw-relative tw-mt-9 tw-h-60">
      <canvas ref="canvas" />
      <!-- <CustomerHealthTrendTooltip ref="chartTooltip" /> -->
    </div>
  </div>
</template>
