/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AllU1Aggregation } from '../model/';
// @ts-ignore
import { FilterAllU1AggregationDto } from '../model/';
/**
 * AllU1AggregationApi - axios parameter creator
 * @export
 */
export const AllU1AggregationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of All-U1-Aggregation
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/all-u1-aggregations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get particular All-U1-Aggregation against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allU1AggregationsControllerFindOne', 'id', id)
            const localVarPath = `/api/all-u1-aggregations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one all-u1-aggregation by department id and role id with day_id
         * @param {number} dayId Day id for which u1 aggregation data will be shown
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} type Date type field for which u1 aggregation data will be shown
         * @param {string} c1DepartmentId 
         * @param {string} u1RoleId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindOneAllU1AggregationByQuery: async (dayId: number, type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', c1DepartmentId: string, u1RoleId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dayId' is not null or undefined
            assertParamExists('allU1AggregationsControllerFindOneAllU1AggregationByQuery', 'dayId', dayId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('allU1AggregationsControllerFindOneAllU1AggregationByQuery', 'type', type)
            // verify required parameter 'c1DepartmentId' is not null or undefined
            assertParamExists('allU1AggregationsControllerFindOneAllU1AggregationByQuery', 'c1DepartmentId', c1DepartmentId)
            // verify required parameter 'u1RoleId' is not null or undefined
            assertParamExists('allU1AggregationsControllerFindOneAllU1AggregationByQuery', 'u1RoleId', u1RoleId)
            const localVarPath = `/api/v2/all-u1-aggregations/find-one`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dayId !== undefined) {
                localVarQueryParameter['day_id'] = dayId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (c1DepartmentId !== undefined) {
                localVarQueryParameter['c1_department_id'] = c1DepartmentId;
            }

            if (u1RoleId !== undefined) {
                localVarQueryParameter['u1_role_id'] = u1RoleId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get U1-Aggregation average by date, department id and role id
         * @param {FilterAllU1AggregationDto} filterAllU1AggregationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindOneByDateInfo: async (filterAllU1AggregationDto: FilterAllU1AggregationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterAllU1AggregationDto' is not null or undefined
            assertParamExists('allU1AggregationsControllerFindOneByDateInfo', 'filterAllU1AggregationDto', filterAllU1AggregationDto)
            const localVarPath = `/api/all-u1-aggregations/by-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterAllU1AggregationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllU1AggregationApi - functional programming interface
 * @export
 */
export const AllU1AggregationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllU1AggregationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of All-U1-Aggregation
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allU1AggregationsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllU1Aggregation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allU1AggregationsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get particular All-U1-Aggregation against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allU1AggregationsControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllU1Aggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allU1AggregationsControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one all-u1-aggregation by department id and role id with day_id
         * @param {number} dayId Day id for which u1 aggregation data will be shown
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} type Date type field for which u1 aggregation data will be shown
         * @param {string} c1DepartmentId 
         * @param {string} u1RoleId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allU1AggregationsControllerFindOneAllU1AggregationByQuery(dayId: number, type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', c1DepartmentId: string, u1RoleId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllU1Aggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allU1AggregationsControllerFindOneAllU1AggregationByQuery(dayId, type, c1DepartmentId, u1RoleId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get U1-Aggregation average by date, department id and role id
         * @param {FilterAllU1AggregationDto} filterAllU1AggregationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allU1AggregationsControllerFindOneByDateInfo(filterAllU1AggregationDto: FilterAllU1AggregationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllU1Aggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allU1AggregationsControllerFindOneByDateInfo(filterAllU1AggregationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllU1AggregationApi - factory interface
 * @export
 */
export const AllU1AggregationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllU1AggregationApiFp(configuration)
    return {
        /**
         * 
         * @summary List of All-U1-Aggregation
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AllU1Aggregation>> {
            return localVarFp.allU1AggregationsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get particular All-U1-Aggregation against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<AllU1Aggregation> {
            return localVarFp.allU1AggregationsControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one all-u1-aggregation by department id and role id with day_id
         * @param {number} dayId Day id for which u1 aggregation data will be shown
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} type Date type field for which u1 aggregation data will be shown
         * @param {string} c1DepartmentId 
         * @param {string} u1RoleId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindOneAllU1AggregationByQuery(dayId: number, type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', c1DepartmentId: string, u1RoleId: string, xTenantId?: string, options?: any): AxiosPromise<AllU1Aggregation> {
            return localVarFp.allU1AggregationsControllerFindOneAllU1AggregationByQuery(dayId, type, c1DepartmentId, u1RoleId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get U1-Aggregation average by date, department id and role id
         * @param {FilterAllU1AggregationDto} filterAllU1AggregationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allU1AggregationsControllerFindOneByDateInfo(filterAllU1AggregationDto: FilterAllU1AggregationDto, xTenantId?: string, options?: any): AxiosPromise<AllU1Aggregation> {
            return localVarFp.allU1AggregationsControllerFindOneByDateInfo(filterAllU1AggregationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for allU1AggregationsControllerFindAll operation in AllU1AggregationApi.
 * @export
 * @interface AllU1AggregationApiAllU1AggregationsControllerFindAllRequest
 */
export interface AllU1AggregationApiAllU1AggregationsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for allU1AggregationsControllerFindOne operation in AllU1AggregationApi.
 * @export
 * @interface AllU1AggregationApiAllU1AggregationsControllerFindOneRequest
 */
export interface AllU1AggregationApiAllU1AggregationsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for allU1AggregationsControllerFindOneAllU1AggregationByQuery operation in AllU1AggregationApi.
 * @export
 * @interface AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQueryRequest
 */
export interface AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQueryRequest {
    /**
     * Day id for which u1 aggregation data will be shown
     * @type {number}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQuery
     */
    readonly dayId: number

    /**
     * Date type field for which u1 aggregation data will be shown
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQuery
     */
    readonly type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQuery
     */
    readonly c1DepartmentId: string

    /**
     * 
     * @type {string}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQuery
     */
    readonly u1RoleId: string

    /**
     * 
     * @type {string}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQuery
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for allU1AggregationsControllerFindOneByDateInfo operation in AllU1AggregationApi.
 * @export
 * @interface AllU1AggregationApiAllU1AggregationsControllerFindOneByDateInfoRequest
 */
export interface AllU1AggregationApiAllU1AggregationsControllerFindOneByDateInfoRequest {
    /**
     * 
     * @type {FilterAllU1AggregationDto}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOneByDateInfo
     */
    readonly filterAllU1AggregationDto: FilterAllU1AggregationDto

    /**
     * 
     * @type {string}
     * @memberof AllU1AggregationApiAllU1AggregationsControllerFindOneByDateInfo
     */
    readonly xTenantId?: string
}

/**
 * AllU1AggregationApi - object-oriented interface
 * @export
 * @class AllU1AggregationApi
 * @extends {BaseAPI}
 */
export class AllU1AggregationApi extends BaseAPI {
    /**
     * 
     * @summary List of All-U1-Aggregation
     * @param {AllU1AggregationApiAllU1AggregationsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllU1AggregationApi
     */
    public allU1AggregationsControllerFindAll(requestParameters: AllU1AggregationApiAllU1AggregationsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AllU1AggregationApiFp(this.configuration).allU1AggregationsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get particular All-U1-Aggregation against ID
     * @param {AllU1AggregationApiAllU1AggregationsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllU1AggregationApi
     */
    public allU1AggregationsControllerFindOne(requestParameters: AllU1AggregationApiAllU1AggregationsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return AllU1AggregationApiFp(this.configuration).allU1AggregationsControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one all-u1-aggregation by department id and role id with day_id
     * @param {AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllU1AggregationApi
     */
    public allU1AggregationsControllerFindOneAllU1AggregationByQuery(requestParameters: AllU1AggregationApiAllU1AggregationsControllerFindOneAllU1AggregationByQueryRequest, options?: AxiosRequestConfig) {
        return AllU1AggregationApiFp(this.configuration).allU1AggregationsControllerFindOneAllU1AggregationByQuery(requestParameters.dayId, requestParameters.type, requestParameters.c1DepartmentId, requestParameters.u1RoleId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get U1-Aggregation average by date, department id and role id
     * @param {AllU1AggregationApiAllU1AggregationsControllerFindOneByDateInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllU1AggregationApi
     */
    public allU1AggregationsControllerFindOneByDateInfo(requestParameters: AllU1AggregationApiAllU1AggregationsControllerFindOneByDateInfoRequest, options?: AxiosRequestConfig) {
        return AllU1AggregationApiFp(this.configuration).allU1AggregationsControllerFindOneByDateInfo(requestParameters.filterAllU1AggregationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
