<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { MessageSchema } from '@/lib/i18n';
import LoadingIndicator from '../LoadingIndicator.vue';
import OverviewItem from './OverviewItem.vue';
import { phInfoBold, phMoney, phUser, phWarning } from '@/phosphor-icons';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import KpiDefinition from '../KpiDefinition.vue';
import { storeToRefs } from 'pinia';
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { useQuasar } from 'quasar';
import MoreDetailsModal from './MoreDetailsModal.vue';
import AccessPermissionOverviewItem from './AccessPermissionOverviewItem.vue';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const $q = useQuasar();
const { isDashboardInfoLoading, dashboardInfo, showFinancialData } = storeToRefs(useDashboardV2Store());

const valueFormatter = useAmountFormatter();

function onMoreDetailsClick() {
  $q.dialog({
    component: MoreDetailsModal,
  });
}
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-px-6 tw-shadow">
    <LoadingIndicator v-if="isDashboardInfoLoading" class="tw-bg-light-shade-1" />
    <div class="tw-flex tw-items-baseline tw-justify-between tw-pt-4">
      <span class="tw-text-base tw-text-dark-shade-3">{{ t('overview_section.title') }}</span>
      <q-btn
        color="primary"
        class="tw-pt-0z tw-pr-0 tw-text-base tw-font-bold"
        :ripple="false"
        flat
        no-caps
        @click="onMoreDetailsClick"
        >{{ t('overview_section.more_details') }}
      </q-btn>
    </div>
    <div class="tw-grid tw-grid-cols-4 tw-justify-items-start">
      <!-- <div class="tw-grid tw-grid-cols-4 tw-items-center md:tw-divide-x tw-divide-gray-100"> -->
      <OverviewItem
        :value="dashboardInfo?.zapscore?.toString()"
        :percentage-change="dashboardInfo?.zs_percentage_change_from_last"
        :color-code="dashboardInfo?.zs_color_code"
      >
        <template v-slot:footer>
          <div class="xtw-ml-1 xtw-mt-1 tw-flex tw-text-sm tw-font-semibold tw-text-primary-dark">
            {{ t('overview_section.zapscore') }}
            <div class="tw-ml-1 tw-cursor-pointer">
              <q-icon :name="phInfoBold" class="tw-text-primary-dark" />
              <q-menu ref="kpi-range-menu" transition-show="fade" transition-hide="fade" transition-duration="300">
                <KpiDefinition :kpi-id="dashboardInfo?.zs_kpi_id" :kpi-for="'business_kpi'" />
              </q-menu>
            </div>
          </div>
        </template>
      </OverviewItem>

      <AccessPermissionOverviewItem
        :value="dashboardInfo?.total_acv != null ? valueFormatter.formatAmount(dashboardInfo?.total_acv) : '--'"
        :percentage-change="dashboardInfo?.total_acv_percentage_change_from_last"
        :icon-name="phMoney"
        :is-permissible="showFinancialData"
      >
        <template #permission v-if="!showFinancialData">
          <div class="tw-flex tw-grow tw-grid-flow-col tw-items-center">
            <q-icon :name="phWarning" size="xs" class="tw-text-data-viz-semantic-red-graph" />
            <span class="tw-text-xs tw-font-semibold tw-leading-6 tw-text-data-viz-semantic-red-content">{{
              t('permission_scope.access_denied')
            }}</span>
          </div>
        </template>
        <template v-slot:footer>
          <div class="tw-mt-1 tw-px-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3">
            {{ t('overview_section.annual_recurring_revenue') }}
          </div>
        </template>
      </AccessPermissionOverviewItem>

      <!-- <OverviewItem
        :value="dashboardInfo?.total_acv ? valueFormatter.formatAmount(dashboardInfo?.total_acv) : '--'"
        :percentage-change="dashboardInfo?.total_acv_percentage_change_from_last"
        :icon-name="phMoney"
      >
        <template v-slot:footer>
          <div class="tw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3 tw-px-1">
            {{ t('overview_section.annual_recurring_revenue') }}
          </div>
        </template>
      </OverviewItem> -->

      <OverviewItem
        :value="dashboardInfo?.total_c2s?.toString()"
        :percentage-change="dashboardInfo?.total_c2s_percentage_change_from_last"
        :icon-name="phUser"
        class="tw-ml-2"
      >
        <template v-slot:footer>
          <div class="tw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3">
            {{ t('overview_section.customers') }}
          </div>
        </template>
      </OverviewItem>

      <OverviewItem
        :value="dashboardInfo?.total_u2s?.toString()"
        :percentage-change="dashboardInfo?.total_u2s_percentage_change_from_last"
        :icon-name="phUser"
        class="tw-ml-2"
      >
        <template v-slot:footer>
          <div class="tw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3">
            {{ t('overview_section.users') }}
          </div>
        </template>
      </OverviewItem>
    </div>
  </div>
</template>
