/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProcessorLoad2JobDto } from '../model/';
// @ts-ignore
import { ProcessorValidationJobDto } from '../model/';
/**
 * ProcessorJobsApi - axios parameter creator
 * @export
 */
export const ProcessorJobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Execute Transformation Load 2 Job
         * @param {ProcessorLoad2JobDto} processorLoad2JobDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processorJobsControllerExecuteTransformationLoad2Job: async (processorLoad2JobDto: ProcessorLoad2JobDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processorLoad2JobDto' is not null or undefined
            assertParamExists('processorJobsControllerExecuteTransformationLoad2Job', 'processorLoad2JobDto', processorLoad2JobDto)
            const localVarPath = `/api/processor-jobs/transformation-load2-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processorLoad2JobDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Execute Transformation Validation Job
         * @param {ProcessorValidationJobDto} processorValidationJobDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processorJobsControllerExecuteTransformationValidationJob: async (processorValidationJobDto: ProcessorValidationJobDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processorValidationJobDto' is not null or undefined
            assertParamExists('processorJobsControllerExecuteTransformationValidationJob', 'processorValidationJobDto', processorValidationJobDto)
            const localVarPath = `/api/processor-jobs/transformation-validation-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processorValidationJobDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Execute U1 Segment Job
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processorJobsControllerExecuteU1SegmentJob: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/processor-jobs/u1-segment-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessorJobsApi - functional programming interface
 * @export
 */
export const ProcessorJobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessorJobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Execute Transformation Load 2 Job
         * @param {ProcessorLoad2JobDto} processorLoad2JobDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processorJobsControllerExecuteTransformationLoad2Job(processorLoad2JobDto: ProcessorLoad2JobDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processorJobsControllerExecuteTransformationLoad2Job(processorLoad2JobDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Execute Transformation Validation Job
         * @param {ProcessorValidationJobDto} processorValidationJobDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processorJobsControllerExecuteTransformationValidationJob(processorValidationJobDto: ProcessorValidationJobDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processorJobsControllerExecuteTransformationValidationJob(processorValidationJobDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Execute U1 Segment Job
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processorJobsControllerExecuteU1SegmentJob(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processorJobsControllerExecuteU1SegmentJob(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessorJobsApi - factory interface
 * @export
 */
export const ProcessorJobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessorJobsApiFp(configuration)
    return {
        /**
         * 
         * @summary Execute Transformation Load 2 Job
         * @param {ProcessorLoad2JobDto} processorLoad2JobDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processorJobsControllerExecuteTransformationLoad2Job(processorLoad2JobDto: ProcessorLoad2JobDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.processorJobsControllerExecuteTransformationLoad2Job(processorLoad2JobDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Execute Transformation Validation Job
         * @param {ProcessorValidationJobDto} processorValidationJobDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processorJobsControllerExecuteTransformationValidationJob(processorValidationJobDto: ProcessorValidationJobDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.processorJobsControllerExecuteTransformationValidationJob(processorValidationJobDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Execute U1 Segment Job
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processorJobsControllerExecuteU1SegmentJob(xTenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.processorJobsControllerExecuteU1SegmentJob(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for processorJobsControllerExecuteTransformationLoad2Job operation in ProcessorJobsApi.
 * @export
 * @interface ProcessorJobsApiProcessorJobsControllerExecuteTransformationLoad2JobRequest
 */
export interface ProcessorJobsApiProcessorJobsControllerExecuteTransformationLoad2JobRequest {
    /**
     * 
     * @type {ProcessorLoad2JobDto}
     * @memberof ProcessorJobsApiProcessorJobsControllerExecuteTransformationLoad2Job
     */
    readonly processorLoad2JobDto: ProcessorLoad2JobDto

    /**
     * 
     * @type {string}
     * @memberof ProcessorJobsApiProcessorJobsControllerExecuteTransformationLoad2Job
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for processorJobsControllerExecuteTransformationValidationJob operation in ProcessorJobsApi.
 * @export
 * @interface ProcessorJobsApiProcessorJobsControllerExecuteTransformationValidationJobRequest
 */
export interface ProcessorJobsApiProcessorJobsControllerExecuteTransformationValidationJobRequest {
    /**
     * 
     * @type {ProcessorValidationJobDto}
     * @memberof ProcessorJobsApiProcessorJobsControllerExecuteTransformationValidationJob
     */
    readonly processorValidationJobDto: ProcessorValidationJobDto

    /**
     * 
     * @type {string}
     * @memberof ProcessorJobsApiProcessorJobsControllerExecuteTransformationValidationJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for processorJobsControllerExecuteU1SegmentJob operation in ProcessorJobsApi.
 * @export
 * @interface ProcessorJobsApiProcessorJobsControllerExecuteU1SegmentJobRequest
 */
export interface ProcessorJobsApiProcessorJobsControllerExecuteU1SegmentJobRequest {
    /**
     * 
     * @type {string}
     * @memberof ProcessorJobsApiProcessorJobsControllerExecuteU1SegmentJob
     */
    readonly xTenantId?: string
}

/**
 * ProcessorJobsApi - object-oriented interface
 * @export
 * @class ProcessorJobsApi
 * @extends {BaseAPI}
 */
export class ProcessorJobsApi extends BaseAPI {
    /**
     * 
     * @summary Execute Transformation Load 2 Job
     * @param {ProcessorJobsApiProcessorJobsControllerExecuteTransformationLoad2JobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessorJobsApi
     */
    public processorJobsControllerExecuteTransformationLoad2Job(requestParameters: ProcessorJobsApiProcessorJobsControllerExecuteTransformationLoad2JobRequest, options?: AxiosRequestConfig) {
        return ProcessorJobsApiFp(this.configuration).processorJobsControllerExecuteTransformationLoad2Job(requestParameters.processorLoad2JobDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Execute Transformation Validation Job
     * @param {ProcessorJobsApiProcessorJobsControllerExecuteTransformationValidationJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessorJobsApi
     */
    public processorJobsControllerExecuteTransformationValidationJob(requestParameters: ProcessorJobsApiProcessorJobsControllerExecuteTransformationValidationJobRequest, options?: AxiosRequestConfig) {
        return ProcessorJobsApiFp(this.configuration).processorJobsControllerExecuteTransformationValidationJob(requestParameters.processorValidationJobDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Execute U1 Segment Job
     * @param {ProcessorJobsApiProcessorJobsControllerExecuteU1SegmentJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessorJobsApi
     */
    public processorJobsControllerExecuteU1SegmentJob(requestParameters: ProcessorJobsApiProcessorJobsControllerExecuteU1SegmentJobRequest = {}, options?: AxiosRequestConfig) {
        return ProcessorJobsApiFp(this.configuration).processorJobsControllerExecuteU1SegmentJob(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
