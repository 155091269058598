<script setup lang="ts">
import { ChartData, Chart, ChartTypeRegistry } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { watch, ref, onMounted } from 'vue';

const props = defineProps<{
  heading: string;
  dataSet: ChartData<'bar'>;
}>();
const emits = defineEmits(['onBarChartItemClick']);

const canvas = ref<HTMLCanvasElement>();
let chartInstance: Chart<keyof ChartTypeRegistry, number[], unknown>;
const dataSets = {} as ChartData<'bar'>;

watch(
  () => props.dataSet,
  (newVal) => {
    updateChartData(newVal);
  },
  {
    immediate: true,
  },
);

function updateChartData(newVal: ChartData<'bar'>) {
  if (!chartInstance) {
    return;
  }
  chartInstance.data.labels = newVal.labels;
  chartInstance.data.datasets = newVal.datasets;
  chartInstance.update();
}

onMounted(() => {
  drawChart();
  if (props.dataSet) {
    updateChartData(props.dataSet);
  }
});

const drawChart = () => {
  const ctx = canvas.value?.getContext('2d');
  if (!ctx) return;

  chartInstance = new Chart(ctx, {
    type: 'bar',
    plugins: [ChartDataLabels],
    options: {
      maintainAspectRatio: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: function (e: MouseEvent, clickedElement: any[]) {
        const parentIndex = clickedElement[0].datasetIndex as number;
        const childIndex = clickedElement[0].index as number;
        emits('onBarChartItemClick', parentIndex, childIndex);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onHover: function (e: any) {
        const points = chartInstance.getElementsAtEventForMode(e, 'index', { axis: 'x', intersect: true }, false);
        if (points.length) {
          e.native.target.style.cursor = 'pointer';
        } else {
          e.native.target.style.cursor = 'default';
        }
      },
      plugins: {
        legend: {
          align: 'end',
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 3.5,
            color: '#666666',
            font: {
              family: 'Hanken Grotesk',
              size: 10,
              weight: '700',
            },
          },
        },
        tooltip: {
          caretSize: 0,
          enabled: true,
          usePointStyle: true,
          boxWidth: 3.5,
          boxPadding: 5,
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: function (ctx: any) {
              const element = chartInstance.getActiveElements()[0];
              const dataset = ctx.dataset;
              let label = dataset.label;
              const count = ctx.dataset.counts[element.index];
              return `${label} - ${count}`;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            font: {
              family: 'Hanken Grotesk',
              size: 14,
              weight: '600',
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
          max: 100,
        },
      },
    },
    data: dataSets,
  });
};
</script>

<template>
  <div class="tw-relative tw-flex tw-flex-col tw-rounded tw-bg-light-shade-1 tw-px-6 tw-shadow">
    <slot name="status"></slot>
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-pt-4">
      <div class="tw-flex tw-flex-col">
        <span class="tw-text-base tw-text-dark-shade-3">{{ props.heading }}</span>
      </div>

      <slot name="filter"></slot>
    </div>

    <div class="tw-relative tw-mt-9 tw-grow">
      <canvas ref="canvas" />
    </div>
    <slot name="footer"></slot>
  </div>
</template>
