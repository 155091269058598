/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TransformationChangeLog } from '../model/';
/**
 * TransformationChangeLogApi - axios parameter creator
 * @export
 */
export const TransformationChangeLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find transformation change logs
         * @param {string} appId 
         * @param {string} mappingId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transformationChangeLogControllerFindChangeLogs: async (appId: string, mappingId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('transformationChangeLogControllerFindChangeLogs', 'appId', appId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('transformationChangeLogControllerFindChangeLogs', 'mappingId', mappingId)
            const localVarPath = `/api/transformation-change-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appId !== undefined) {
                localVarQueryParameter['app_id'] = appId;
            }

            if (mappingId !== undefined) {
                localVarQueryParameter['mapping_id'] = mappingId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransformationChangeLogApi - functional programming interface
 * @export
 */
export const TransformationChangeLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransformationChangeLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find transformation change logs
         * @param {string} appId 
         * @param {string} mappingId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transformationChangeLogControllerFindChangeLogs(appId: string, mappingId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransformationChangeLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transformationChangeLogControllerFindChangeLogs(appId, mappingId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransformationChangeLogApi - factory interface
 * @export
 */
export const TransformationChangeLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransformationChangeLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Find transformation change logs
         * @param {string} appId 
         * @param {string} mappingId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transformationChangeLogControllerFindChangeLogs(appId: string, mappingId: string, xTenantId?: string, options?: any): AxiosPromise<Array<TransformationChangeLog>> {
            return localVarFp.transformationChangeLogControllerFindChangeLogs(appId, mappingId, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for transformationChangeLogControllerFindChangeLogs operation in TransformationChangeLogApi.
 * @export
 * @interface TransformationChangeLogApiTransformationChangeLogControllerFindChangeLogsRequest
 */
export interface TransformationChangeLogApiTransformationChangeLogControllerFindChangeLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof TransformationChangeLogApiTransformationChangeLogControllerFindChangeLogs
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof TransformationChangeLogApiTransformationChangeLogControllerFindChangeLogs
     */
    readonly mappingId: string

    /**
     * 
     * @type {string}
     * @memberof TransformationChangeLogApiTransformationChangeLogControllerFindChangeLogs
     */
    readonly xTenantId?: string
}

/**
 * TransformationChangeLogApi - object-oriented interface
 * @export
 * @class TransformationChangeLogApi
 * @extends {BaseAPI}
 */
export class TransformationChangeLogApi extends BaseAPI {
    /**
     * 
     * @summary Find transformation change logs
     * @param {TransformationChangeLogApiTransformationChangeLogControllerFindChangeLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformationChangeLogApi
     */
    public transformationChangeLogControllerFindChangeLogs(requestParameters: TransformationChangeLogApiTransformationChangeLogControllerFindChangeLogsRequest, options?: AxiosRequestConfig) {
        return TransformationChangeLogApiFp(this.configuration).transformationChangeLogControllerFindChangeLogs(requestParameters.appId, requestParameters.mappingId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
