/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CampaignTemplateSchema } from '../model/';
// @ts-ignore
import { CopyTemplateDto } from '../model/';
/**
 * CampaignTemplateApi - axios parameter creator
 * @export
 */
export const CampaignTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy current template 
         * @param {CopyTemplateDto} copyTemplateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerCopy: async (copyTemplateDto: CopyTemplateDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyTemplateDto' is not null or undefined
            assertParamExists('campaignTemplateControllerCopy', 'copyTemplateDto', copyTemplateDto)
            const localVarPath = `/api/campaign-template/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all campaign templates
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign-template/all-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find campaign template by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignTemplateControllerFindOne', 'id', id)
            const localVarPath = `/api/campaign-template/template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete template by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignTemplateControllerRemove', 'id', id)
            const localVarPath = `/api/campaign-template/delete-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove selected attachment from template 
         * @param {string} id 
         * @param {number} index 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerRemoveAttachment: async (id: string, index: number, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignTemplateControllerRemoveAttachment', 'id', id)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('campaignTemplateControllerRemoveAttachment', 'index', index)
            const localVarPath = `/api/campaign-template/remove-attachment/{id}/{index}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignTemplateApi - functional programming interface
 * @export
 */
export const CampaignTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy current template 
         * @param {CopyTemplateDto} copyTemplateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTemplateControllerCopy(copyTemplateDto: CopyTemplateDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignTemplateSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTemplateControllerCopy(copyTemplateDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all campaign templates
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTemplateControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignTemplateSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTemplateControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find campaign template by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTemplateControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignTemplateSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTemplateControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete template by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTemplateControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignTemplateSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTemplateControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove selected attachment from template 
         * @param {string} id 
         * @param {number} index 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTemplateControllerRemoveAttachment(id: string, index: number, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignTemplateSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTemplateControllerRemoveAttachment(id, index, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignTemplateApi - factory interface
 * @export
 */
export const CampaignTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy current template 
         * @param {CopyTemplateDto} copyTemplateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerCopy(copyTemplateDto: CopyTemplateDto, xTenantId?: string, options?: any): AxiosPromise<Array<CampaignTemplateSchema>> {
            return localVarFp.campaignTemplateControllerCopy(copyTemplateDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all campaign templates
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<CampaignTemplateSchema>> {
            return localVarFp.campaignTemplateControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find campaign template by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<CampaignTemplateSchema> {
            return localVarFp.campaignTemplateControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete template by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<CampaignTemplateSchema> {
            return localVarFp.campaignTemplateControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove selected attachment from template 
         * @param {string} id 
         * @param {number} index 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTemplateControllerRemoveAttachment(id: string, index: number, xTenantId?: string, options?: any): AxiosPromise<CampaignTemplateSchema> {
            return localVarFp.campaignTemplateControllerRemoveAttachment(id, index, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for campaignTemplateControllerCopy operation in CampaignTemplateApi.
 * @export
 * @interface CampaignTemplateApiCampaignTemplateControllerCopyRequest
 */
export interface CampaignTemplateApiCampaignTemplateControllerCopyRequest {
    /**
     * 
     * @type {CopyTemplateDto}
     * @memberof CampaignTemplateApiCampaignTemplateControllerCopy
     */
    readonly copyTemplateDto: CopyTemplateDto

    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerCopy
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignTemplateControllerFindAll operation in CampaignTemplateApi.
 * @export
 * @interface CampaignTemplateApiCampaignTemplateControllerFindAllRequest
 */
export interface CampaignTemplateApiCampaignTemplateControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignTemplateControllerFindOne operation in CampaignTemplateApi.
 * @export
 * @interface CampaignTemplateApiCampaignTemplateControllerFindOneRequest
 */
export interface CampaignTemplateApiCampaignTemplateControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignTemplateControllerRemove operation in CampaignTemplateApi.
 * @export
 * @interface CampaignTemplateApiCampaignTemplateControllerRemoveRequest
 */
export interface CampaignTemplateApiCampaignTemplateControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignTemplateControllerRemoveAttachment operation in CampaignTemplateApi.
 * @export
 * @interface CampaignTemplateApiCampaignTemplateControllerRemoveAttachmentRequest
 */
export interface CampaignTemplateApiCampaignTemplateControllerRemoveAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerRemoveAttachment
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof CampaignTemplateApiCampaignTemplateControllerRemoveAttachment
     */
    readonly index: number

    /**
     * 
     * @type {string}
     * @memberof CampaignTemplateApiCampaignTemplateControllerRemoveAttachment
     */
    readonly xTenantId?: string
}

/**
 * CampaignTemplateApi - object-oriented interface
 * @export
 * @class CampaignTemplateApi
 * @extends {BaseAPI}
 */
export class CampaignTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Copy current template 
     * @param {CampaignTemplateApiCampaignTemplateControllerCopyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignTemplateApi
     */
    public campaignTemplateControllerCopy(requestParameters: CampaignTemplateApiCampaignTemplateControllerCopyRequest, options?: AxiosRequestConfig) {
        return CampaignTemplateApiFp(this.configuration).campaignTemplateControllerCopy(requestParameters.copyTemplateDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all campaign templates
     * @param {CampaignTemplateApiCampaignTemplateControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignTemplateApi
     */
    public campaignTemplateControllerFindAll(requestParameters: CampaignTemplateApiCampaignTemplateControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return CampaignTemplateApiFp(this.configuration).campaignTemplateControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find campaign template by id
     * @param {CampaignTemplateApiCampaignTemplateControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignTemplateApi
     */
    public campaignTemplateControllerFindOne(requestParameters: CampaignTemplateApiCampaignTemplateControllerFindOneRequest, options?: AxiosRequestConfig) {
        return CampaignTemplateApiFp(this.configuration).campaignTemplateControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete template by Id
     * @param {CampaignTemplateApiCampaignTemplateControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignTemplateApi
     */
    public campaignTemplateControllerRemove(requestParameters: CampaignTemplateApiCampaignTemplateControllerRemoveRequest, options?: AxiosRequestConfig) {
        return CampaignTemplateApiFp(this.configuration).campaignTemplateControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove selected attachment from template 
     * @param {CampaignTemplateApiCampaignTemplateControllerRemoveAttachmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignTemplateApi
     */
    public campaignTemplateControllerRemoveAttachment(requestParameters: CampaignTemplateApiCampaignTemplateControllerRemoveAttachmentRequest, options?: AxiosRequestConfig) {
        return CampaignTemplateApiFp(this.configuration).campaignTemplateControllerRemoveAttachment(requestParameters.id, requestParameters.index, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
