<script setup lang="ts">
import DialogFooter from '@/components/DialogFooter.vue';
import { MessageSchema } from '@/lib/i18n';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { phCalendar, phCopyBold, phCheckBold } from '@/phosphor-icons';
import { addDays, addYears, endOfDay, format } from 'date-fns';
import { CreatePersonalAccessTokenDto } from '@/api/client';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axios, { AxiosError } from 'axios';
import { useQuasar } from 'quasar';
import { ServerError, personalAccessTokenApi } from '@/api';
import { useClipboard } from '@vueuse/core';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const $q = useQuasar();

const emit = defineEmits(['onDoneClick']);

const formData = ref<CreatePersonalAccessTokenDto>({
  name: '',
  expires_at: '',
  user_defined: true,
});

const rules = {
  name: { required: helpers.withMessage('Required', required) },
  expires_at: { required: helpers.withMessage('Required', required) },
};

const v$ = useVuelidate(rules, formData);

const createdToken = ref<string>('');

const isCreating = ref<boolean>(false);

const onCreatePersonalToken = async () => {
  const valid = await v$.value.$validate();
  if (!valid) return;
  isCreating.value = true;
  try {
    const { data } = await personalAccessTokenApi.personalAccessTokensControllerCreate({
      createPersonalAccessTokenDto: formData.value,
    });

    if (data) {
      createdToken.value = data.token;
      notifyUser(true, t('developer_setting.personal_access_token.create_new.token_created_successfully'));
    } else {
      notifyUser(false, t('developer_setting.personal_access_token.create_new.failed_to_create_token'));
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const err = e as AxiosError<ServerError>;
      notifyUser(
        false,
        err.response?.data.message || t('developer_setting.personal_access_token.create_new.failed_to_create_token'),
      );
    } else {
      notifyUser(false, t('developer_setting.personal_access_token.create_new.failed_to_create_token'));
    }
  } finally {
    isCreating.value = false;
  }
};

const notifyUser = (isSuccess: boolean, message: string) => {
  const notificationType = isSuccess ? 'positive' : 'negative';
  $q.notify({
    type: notificationType,
    message: message,
    color: notificationType,
    timeout: 2500,
  });
};

const dateOptions = (date: any) => {
  const nextDate = endOfDay(addDays(new Date(), 1));
  const oneYearAfter = endOfDay(addYears(new Date(), 1));
  const dateLowerLimit = format(nextDate, 'yyyy/MM/dd');
  const dateUpperLimit = format(oneYearAfter, 'yyyy/MM/dd');
  return date >= dateLowerLimit && date <= dateUpperLimit;
};

const copyAccessToken = async () => {
  if (!createdToken.value) return;
  const { copy, copied } = useClipboard({ source: createdToken.value });
  await copy();
  if (copied.value) {
    notifyUser(true, 'Copied personal access token to clip board');
  }
};
</script>

<template>
  <div class="tw-flex tw-h-full tw-flex-col">
    <!-- Personal access token generated view -- START-->
    <div class="tw-border-2 tw-p-4 tw-font-semibold" v-if="createdToken">
      <div class="tw-text-gray-500">
        {{ t('developer_setting.personal_access_token.create_new.token_generation_msg') }}
      </div>
      <div class="tw-my-4 tw-rounded-md tw-border tw-border-blue-600 tw-bg-blue-50 tw-p-2 tw-text-primary-dark">
        {{ t('developer_setting.personal_access_token.create_new.make_sure_to_copy') }}
      </div>

      <div
        class="tw-my-4 tw-flex tw-place-items-center tw-justify-between tw-rounded-md tw-border tw-border-green-600 tw-bg-green-50 tw-p-2 tw-text-green-700"
      >
        <div class="tw-flex tw-place-items-center">
          <q-icon :name="phCheckBold" size="1.2rem" class="tw-mr-2 tw-text-green-600" />
          <span class="tw-text-md tw-font-extrabold">{{ createdToken }}</span>
        </div>

        <q-btn flat dense round :icon="phCopyBold" class="tw-text-primary" @click="copyAccessToken" />
      </div>
    </div>
    <!-- Personal access token generated view -- END-->

    <!-- Token Generation Form -- START-->
    <div class="tw-border-2 tw-p-4" v-else>
      <div class="tw-max-w-[30rem]">
        <div class="tw-mb-4">
          <label for="name" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">{{
            t('developer_setting.personal_access_token.create_new.name')
          }}</label>
          <q-input for="name" v-model="formData.name" dense outlined stack-label :disable="isCreating" />
          <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error">{{
            v$.name.$errors[0]?.$message
          }}</span>
        </div>

        <div class="tw-mb-4">
          <label for="expires_at" class="tw-text-sm tw-font-semibold tw-text-dark-shade-3">{{
            t('developer_setting.personal_access_token.create_new.expires_at')
          }}</label>
          <q-input for="expires_at" v-model="formData.expires_at" mask="date" dense outlined :disable="isCreating">
            <template v-slot:append>
              <q-icon :name="phCalendar" class="cursor-pointer"></q-icon>
            </template>
            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
              <q-date v-model="formData.expires_at" :options="dateOptions">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-input>
          <span class="tw-block tw-h-4 tw-text-xs tw-font-semibold tw-text-error">{{
            v$.expires_at.$errors[0]?.$message
          }}</span>
        </div>
      </div>
    </div>
    <!-- Token Generation Form -- END-->
  </div>
  <DialogFooter class="tw-mt-auto">
    <template v-slot:content>
      <q-btn
        v-if="createdToken"
        color="primary"
        :label="t('done')"
        :disable="isCreating"
        :loading="isCreating"
        @click="emit('onDoneClick')"
      />
      <q-btn
        v-else
        type="submit"
        color="primary"
        :label="t('developer_setting.personal_access_token.generate_token')"
        :disable="isCreating"
        :loading="isCreating"
        @click="onCreatePersonalToken"
      />
    </template>
  </DialogFooter>
</template>
