/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CsvIntegrationResponseDto } from '../model/';
// @ts-ignore
import { ShowCsvIntegrationDto } from '../model/';
/**
 * CsvIntegrationApi - axios parameter creator
 * @export
 */
export const CsvIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Csv-integration-By-Name
         * @param {string} appId 
         * @param {string} appTypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvIntegrationsControllerGetCsvIntegrationByName: async (appId: string, appTypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('csvIntegrationsControllerGetCsvIntegrationByName', 'appId', appId)
            // verify required parameter 'appTypeId' is not null or undefined
            assertParamExists('csvIntegrationsControllerGetCsvIntegrationByName', 'appTypeId', appTypeId)
            const localVarPath = `/api/csv-integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appId !== undefined) {
                localVarQueryParameter['app_id'] = appId;
            }

            if (appTypeId !== undefined) {
                localVarQueryParameter['app_type_id'] = appTypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload csv for peripheral integration
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {string} [appName] 
         * @param {string} [appId] 
         * @param {string} [appTypeId] 
         * @param {string} [reminderEmails] 
         * @param {number} [periodicity] 
         * @param {string} [priorities] 
         * @param {string} [prioritiesIds] 
         * @param {string} [resolvedstatus] 
         * @param {string} [resolvedstatusIds] 
         * @param {string} [selectedApp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvIntegrationsControllerImportTicketingCsv: async (xTenantId?: string, file?: any, appName?: string, appId?: string, appTypeId?: string, reminderEmails?: string, periodicity?: number, priorities?: string, prioritiesIds?: string, resolvedstatus?: string, resolvedstatusIds?: string, selectedApp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/csv-integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (appName !== undefined) { 
                localVarFormParams.append('app_name', appName as any);
            }
    
            if (appId !== undefined) { 
                localVarFormParams.append('app_id', appId as any);
            }
    
            if (appTypeId !== undefined) { 
                localVarFormParams.append('app_type_id', appTypeId as any);
            }
    
            if (reminderEmails !== undefined) { 
                localVarFormParams.append('reminder_emails', reminderEmails as any);
            }
    
            if (periodicity !== undefined) { 
                localVarFormParams.append('periodicity', periodicity as any);
            }
    
            if (priorities !== undefined) { 
                localVarFormParams.append('priorities', priorities as any);
            }
    
            if (prioritiesIds !== undefined) { 
                localVarFormParams.append('priorities_ids', prioritiesIds as any);
            }
    
            if (resolvedstatus !== undefined) { 
                localVarFormParams.append('resolvedstatus', resolvedstatus as any);
            }
    
            if (resolvedstatusIds !== undefined) { 
                localVarFormParams.append('resolvedstatus_ids', resolvedstatusIds as any);
            }
    
            if (selectedApp !== undefined) { 
                localVarFormParams.append('selected_app', selectedApp as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CsvIntegrationApi - functional programming interface
 * @export
 */
export const CsvIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CsvIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Csv-integration-By-Name
         * @param {string} appId 
         * @param {string} appTypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvIntegrationsControllerGetCsvIntegrationByName(appId: string, appTypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowCsvIntegrationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvIntegrationsControllerGetCsvIntegrationByName(appId, appTypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload csv for peripheral integration
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {string} [appName] 
         * @param {string} [appId] 
         * @param {string} [appTypeId] 
         * @param {string} [reminderEmails] 
         * @param {number} [periodicity] 
         * @param {string} [priorities] 
         * @param {string} [prioritiesIds] 
         * @param {string} [resolvedstatus] 
         * @param {string} [resolvedstatusIds] 
         * @param {string} [selectedApp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvIntegrationsControllerImportTicketingCsv(xTenantId?: string, file?: any, appName?: string, appId?: string, appTypeId?: string, reminderEmails?: string, periodicity?: number, priorities?: string, prioritiesIds?: string, resolvedstatus?: string, resolvedstatusIds?: string, selectedApp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvIntegrationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvIntegrationsControllerImportTicketingCsv(xTenantId, file, appName, appId, appTypeId, reminderEmails, periodicity, priorities, prioritiesIds, resolvedstatus, resolvedstatusIds, selectedApp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CsvIntegrationApi - factory interface
 * @export
 */
export const CsvIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CsvIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Csv-integration-By-Name
         * @param {string} appId 
         * @param {string} appTypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvIntegrationsControllerGetCsvIntegrationByName(appId: string, appTypeId: string, xTenantId?: string, options?: any): AxiosPromise<ShowCsvIntegrationDto> {
            return localVarFp.csvIntegrationsControllerGetCsvIntegrationByName(appId, appTypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload csv for peripheral integration
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {string} [appName] 
         * @param {string} [appId] 
         * @param {string} [appTypeId] 
         * @param {string} [reminderEmails] 
         * @param {number} [periodicity] 
         * @param {string} [priorities] 
         * @param {string} [prioritiesIds] 
         * @param {string} [resolvedstatus] 
         * @param {string} [resolvedstatusIds] 
         * @param {string} [selectedApp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvIntegrationsControllerImportTicketingCsv(xTenantId?: string, file?: any, appName?: string, appId?: string, appTypeId?: string, reminderEmails?: string, periodicity?: number, priorities?: string, prioritiesIds?: string, resolvedstatus?: string, resolvedstatusIds?: string, selectedApp?: string, options?: any): AxiosPromise<CsvIntegrationResponseDto> {
            return localVarFp.csvIntegrationsControllerImportTicketingCsv(xTenantId, file, appName, appId, appTypeId, reminderEmails, periodicity, priorities, prioritiesIds, resolvedstatus, resolvedstatusIds, selectedApp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for csvIntegrationsControllerGetCsvIntegrationByName operation in CsvIntegrationApi.
 * @export
 * @interface CsvIntegrationApiCsvIntegrationsControllerGetCsvIntegrationByNameRequest
 */
export interface CsvIntegrationApiCsvIntegrationsControllerGetCsvIntegrationByNameRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerGetCsvIntegrationByName
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerGetCsvIntegrationByName
     */
    readonly appTypeId: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerGetCsvIntegrationByName
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvIntegrationsControllerImportTicketingCsv operation in CsvIntegrationApi.
 * @export
 * @interface CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsvRequest
 */
export interface CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsvRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly appName?: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly appId?: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly appTypeId?: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly reminderEmails?: string

    /**
     * 
     * @type {number}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly periodicity?: number

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly priorities?: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly prioritiesIds?: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly resolvedstatus?: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly resolvedstatusIds?: string

    /**
     * 
     * @type {string}
     * @memberof CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsv
     */
    readonly selectedApp?: string
}

/**
 * CsvIntegrationApi - object-oriented interface
 * @export
 * @class CsvIntegrationApi
 * @extends {BaseAPI}
 */
export class CsvIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Get Csv-integration-By-Name
     * @param {CsvIntegrationApiCsvIntegrationsControllerGetCsvIntegrationByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvIntegrationApi
     */
    public csvIntegrationsControllerGetCsvIntegrationByName(requestParameters: CsvIntegrationApiCsvIntegrationsControllerGetCsvIntegrationByNameRequest, options?: AxiosRequestConfig) {
        return CsvIntegrationApiFp(this.configuration).csvIntegrationsControllerGetCsvIntegrationByName(requestParameters.appId, requestParameters.appTypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload csv for peripheral integration
     * @param {CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvIntegrationApi
     */
    public csvIntegrationsControllerImportTicketingCsv(requestParameters: CsvIntegrationApiCsvIntegrationsControllerImportTicketingCsvRequest = {}, options?: AxiosRequestConfig) {
        return CsvIntegrationApiFp(this.configuration).csvIntegrationsControllerImportTicketingCsv(requestParameters.xTenantId, requestParameters.file, requestParameters.appName, requestParameters.appId, requestParameters.appTypeId, requestParameters.reminderEmails, requestParameters.periodicity, requestParameters.priorities, requestParameters.prioritiesIds, requestParameters.resolvedstatus, requestParameters.resolvedstatusIds, requestParameters.selectedApp, options).then((request) => request(this.axios, this.basePath));
    }
}
