import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Kpi, KpiNameEnum } from '@/api/client';
import { kpiApi } from '@/api';

export const useKpiStore = defineStore('kpi', () => {
  const isLoading = ref<boolean>(false);
  const allKpiList = ref<Kpi[]>([]);

  async function fetchKpiList() {
    try {
      isLoading.value = true;
      const { data } = await kpiApi.kpiControllerFindAll();
      allKpiList.value = data;
    } catch (e) {
      console.error('Kpis', e);
    } finally {
      isLoading.value = false;
    }
  }

  const kpiList = computed(() => {
    return allKpiList.value.filter((v) => v.is_active);
  });

  const activeKpiList = computed(() => {
    return allKpiList.value.filter((v) => v.on_off_flag) || [];
  });

  const orderedActiveKpiList = computed(() => {
    const items = [] as Kpi[];
    const kpiLevel0 = activeKpiList.value?.filter((x) => x.kpi_level === 0) || [];
    const parentItems = activeKpiList.value?.filter((x) => x.kpi_level === 1) || [];
    const children = activeKpiList.value.filter((x) => x.kpi_level !== 1 && x.kpi_level !== 0) || [];
    parentItems.forEach((x) => {
      items.push(x);
      findChildList(children, x.id, items);
    });
    return [...kpiLevel0, ...items];
  });

  const orderedActiveUserKpiList = computed(() => {
    const items = [] as Kpi[];
    const kpiLevel0 = activeKpiList.value?.filter((x) => x.kpi_level === 0) || [];
    const parentItems =
      activeKpiList.value
        ?.filter((x) => x.kpi_level === 1)
        .filter((x) => x.name === KpiNameEnum.Uss || x.name === KpiNameEnum.Oss) || [];
    const children = activeKpiList.value.filter((x) => x.kpi_level !== 1 && x.kpi_level !== 0) || [];
    parentItems.forEach((x) => {
      items.push(x);
      findChildList(children, x.id, items);
    });
    return [...kpiLevel0, ...items];
  });

  function findChildList(children: Kpi[], parentKpiId: string, items: Kpi[]) {
    const child = children?.filter((kpi) => kpi?.parent_kpi_id === parentKpiId) || [];
    child.forEach((y) => {
      items.push(y);
      findChildList(children, y.id, items);
    });
  }

  return {
    isLoading,
    allKpiList,
    kpiList,
    orderedActiveKpiList,
    orderedActiveUserKpiList,
    fetchKpiList,
    activeKpiList,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useKpiStore, import.meta.hot));
}
