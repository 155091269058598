<script setup lang="ts">
import { IAppDrawerItem } from '@/types/layout';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps<{
  item: IAppDrawerItem;
}>();

const route = useRoute();

const isActive = computed(() => {
  if (!props.item.to) return;
  return route.meta.group === props.item.group || route.name === props.item.to.name;
});
</script>

<template>
  <q-item
    v-if="item.to"
    :to="item.to"
    class="tw-flex tw-flex-row tw-text-dark-shade-1 lg:tw-flex-col lg:tw-items-center lg:tw-py-[1.125rem] lg:tw-text-light-shade-1"
    active-class="router-link-active"
    :class="{ 'router-link-active': isActive }"
  >
    <q-item-section avatar>
      <q-icon :name="item.icon" />
    </q-item-section>

    <q-item-section style="display: flex" class="tw-font-semibold lg:tw-mt-2 lg:tw-text-xs">
      {{ item.name }}
    </q-item-section>
  </q-item>

  <q-item
    v-else
    clickable
    @click.prevent="item.click"
    class="tw-flex tw-flex-row tw-text-dark-shade-1 lg:tw-flex-col lg:tw-items-center lg:tw-py-[1.125rem] lg:tw-text-light-shade-1"
    active-class="router-link-active"
  >
    <q-item-section avatar>
      <q-icon :name="item.icon" />
    </q-item-section>

    <q-item-section style="display: flex" class="tw-font-semibold lg:tw-mt-2 lg:tw-text-xs">
      {{ item.name }}
    </q-item-section>
  </q-item>
</template>
