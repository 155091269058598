/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppPeripheralStatus } from '../model/';
/**
 * AppPeripheralStatusApi - axios parameter creator
 * @export
 */
export const AppPeripheralStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of App Peripheral Status
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-peripheral-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary App Peripheral Status by Peripheral App Name
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerFindByApp: async (appName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('appPeripheralStatusControllerFindByApp', 'appName', appName)
            const localVarPath = `/api/app-peripheral-status/by-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appName !== undefined) {
                localVarQueryParameter['app_name'] = appName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start on demand sync
         * @param {string} appName 
         * @param {string} mappingId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerForceInitialSync: async (appName: string, mappingId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('appPeripheralStatusControllerForceInitialSync', 'appName', appName)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('appPeripheralStatusControllerForceInitialSync', 'mappingId', mappingId)
            const localVarPath = `/api/app-peripheral-status/{appName}/items/{mappingId}/start-on-demand-sync`
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)))
                .replace(`{${"mappingId"}}`, encodeURIComponent(String(mappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update timestamp for Load 1 completion for app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerUpdateLoad1TimestampForApp: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('appPeripheralStatusControllerUpdateLoad1TimestampForApp', 'appId', appId)
            const localVarPath = `/api/app-peripheral-status/{app_id}/update-load1-timestamp`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update timestamp for Transformation and Load 2 completion for app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp', 'appId', appId)
            const localVarPath = `/api/app-peripheral-status/{app_id}/update-transformation-load2-timestamp`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update transformation completion timestamp
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerUpdateTransformationCompletionStatus: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appPeripheralStatusControllerUpdateTransformationCompletionStatus', 'id', id)
            const localVarPath = `/api/app-peripheral-status/{id}/update-transformation-timestamp`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppPeripheralStatusApi - functional programming interface
 * @export
 */
export const AppPeripheralStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppPeripheralStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of App Peripheral Status
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appPeripheralStatusControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppPeripheralStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appPeripheralStatusControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary App Peripheral Status by Peripheral App Name
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appPeripheralStatusControllerFindByApp(appName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppPeripheralStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appPeripheralStatusControllerFindByApp(appName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start on demand sync
         * @param {string} appName 
         * @param {string} mappingId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appPeripheralStatusControllerForceInitialSync(appName: string, mappingId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppPeripheralStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appPeripheralStatusControllerForceInitialSync(appName, mappingId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update timestamp for Load 1 completion for app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appPeripheralStatusControllerUpdateLoad1TimestampForApp(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppPeripheralStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appPeripheralStatusControllerUpdateLoad1TimestampForApp(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update timestamp for Transformation and Load 2 completion for app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppPeripheralStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update transformation completion timestamp
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appPeripheralStatusControllerUpdateTransformationCompletionStatus(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppPeripheralStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appPeripheralStatusControllerUpdateTransformationCompletionStatus(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppPeripheralStatusApi - factory interface
 * @export
 */
export const AppPeripheralStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppPeripheralStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary List of App Peripheral Status
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AppPeripheralStatus>> {
            return localVarFp.appPeripheralStatusControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary App Peripheral Status by Peripheral App Name
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerFindByApp(appName: string, xTenantId?: string, options?: any): AxiosPromise<AppPeripheralStatus> {
            return localVarFp.appPeripheralStatusControllerFindByApp(appName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start on demand sync
         * @param {string} appName 
         * @param {string} mappingId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerForceInitialSync(appName: string, mappingId: string, xTenantId?: string, options?: any): AxiosPromise<AppPeripheralStatus> {
            return localVarFp.appPeripheralStatusControllerForceInitialSync(appName, mappingId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update timestamp for Load 1 completion for app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerUpdateLoad1TimestampForApp(appId: string, xTenantId?: string, options?: any): AxiosPromise<AppPeripheralStatus> {
            return localVarFp.appPeripheralStatusControllerUpdateLoad1TimestampForApp(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update timestamp for Transformation and Load 2 completion for app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp(appId: string, xTenantId?: string, options?: any): AxiosPromise<AppPeripheralStatus> {
            return localVarFp.appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update transformation completion timestamp
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appPeripheralStatusControllerUpdateTransformationCompletionStatus(id: string, xTenantId?: string, options?: any): AxiosPromise<AppPeripheralStatus> {
            return localVarFp.appPeripheralStatusControllerUpdateTransformationCompletionStatus(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appPeripheralStatusControllerFindAll operation in AppPeripheralStatusApi.
 * @export
 * @interface AppPeripheralStatusApiAppPeripheralStatusControllerFindAllRequest
 */
export interface AppPeripheralStatusApiAppPeripheralStatusControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appPeripheralStatusControllerFindByApp operation in AppPeripheralStatusApi.
 * @export
 * @interface AppPeripheralStatusApiAppPeripheralStatusControllerFindByAppRequest
 */
export interface AppPeripheralStatusApiAppPeripheralStatusControllerFindByAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerFindByApp
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerFindByApp
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appPeripheralStatusControllerForceInitialSync operation in AppPeripheralStatusApi.
 * @export
 * @interface AppPeripheralStatusApiAppPeripheralStatusControllerForceInitialSyncRequest
 */
export interface AppPeripheralStatusApiAppPeripheralStatusControllerForceInitialSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerForceInitialSync
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerForceInitialSync
     */
    readonly mappingId: string

    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerForceInitialSync
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appPeripheralStatusControllerUpdateLoad1TimestampForApp operation in AppPeripheralStatusApi.
 * @export
 * @interface AppPeripheralStatusApiAppPeripheralStatusControllerUpdateLoad1TimestampForAppRequest
 */
export interface AppPeripheralStatusApiAppPeripheralStatusControllerUpdateLoad1TimestampForAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerUpdateLoad1TimestampForApp
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerUpdateLoad1TimestampForApp
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp operation in AppPeripheralStatusApi.
 * @export
 * @interface AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationAndLoad2TimestampRequest
 */
export interface AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationAndLoad2TimestampRequest {
    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appPeripheralStatusControllerUpdateTransformationCompletionStatus operation in AppPeripheralStatusApi.
 * @export
 * @interface AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationCompletionStatusRequest
 */
export interface AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationCompletionStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationCompletionStatus
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationCompletionStatus
     */
    readonly xTenantId?: string
}

/**
 * AppPeripheralStatusApi - object-oriented interface
 * @export
 * @class AppPeripheralStatusApi
 * @extends {BaseAPI}
 */
export class AppPeripheralStatusApi extends BaseAPI {
    /**
     * 
     * @summary List of App Peripheral Status
     * @param {AppPeripheralStatusApiAppPeripheralStatusControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPeripheralStatusApi
     */
    public appPeripheralStatusControllerFindAll(requestParameters: AppPeripheralStatusApiAppPeripheralStatusControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppPeripheralStatusApiFp(this.configuration).appPeripheralStatusControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary App Peripheral Status by Peripheral App Name
     * @param {AppPeripheralStatusApiAppPeripheralStatusControllerFindByAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPeripheralStatusApi
     */
    public appPeripheralStatusControllerFindByApp(requestParameters: AppPeripheralStatusApiAppPeripheralStatusControllerFindByAppRequest, options?: AxiosRequestConfig) {
        return AppPeripheralStatusApiFp(this.configuration).appPeripheralStatusControllerFindByApp(requestParameters.appName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start on demand sync
     * @param {AppPeripheralStatusApiAppPeripheralStatusControllerForceInitialSyncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPeripheralStatusApi
     */
    public appPeripheralStatusControllerForceInitialSync(requestParameters: AppPeripheralStatusApiAppPeripheralStatusControllerForceInitialSyncRequest, options?: AxiosRequestConfig) {
        return AppPeripheralStatusApiFp(this.configuration).appPeripheralStatusControllerForceInitialSync(requestParameters.appName, requestParameters.mappingId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update timestamp for Load 1 completion for app
     * @param {AppPeripheralStatusApiAppPeripheralStatusControllerUpdateLoad1TimestampForAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPeripheralStatusApi
     */
    public appPeripheralStatusControllerUpdateLoad1TimestampForApp(requestParameters: AppPeripheralStatusApiAppPeripheralStatusControllerUpdateLoad1TimestampForAppRequest, options?: AxiosRequestConfig) {
        return AppPeripheralStatusApiFp(this.configuration).appPeripheralStatusControllerUpdateLoad1TimestampForApp(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update timestamp for Transformation and Load 2 completion for app
     * @param {AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationAndLoad2TimestampRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPeripheralStatusApi
     */
    public appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp(requestParameters: AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationAndLoad2TimestampRequest, options?: AxiosRequestConfig) {
        return AppPeripheralStatusApiFp(this.configuration).appPeripheralStatusControllerUpdateTransformationAndLoad2Timestamp(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update transformation completion timestamp
     * @param {AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationCompletionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPeripheralStatusApi
     */
    public appPeripheralStatusControllerUpdateTransformationCompletionStatus(requestParameters: AppPeripheralStatusApiAppPeripheralStatusControllerUpdateTransformationCompletionStatusRequest, options?: AxiosRequestConfig) {
        return AppPeripheralStatusApiFp(this.configuration).appPeripheralStatusControllerUpdateTransformationCompletionStatus(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
