/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BlacklistScheduledEmailsDto } from '../model/';
// @ts-ignore
import { CampaignCustomerPaginationDto } from '../model/';
// @ts-ignore
import { CampaignEmailQueueUpdateDto } from '../model/';
// @ts-ignore
import { CampaignMailInfoDto } from '../model/';
// @ts-ignore
import { CampaignMailInfoV2Dto } from '../model/';
// @ts-ignore
import { CampaignRunCustomersDto } from '../model/';
// @ts-ignore
import { CampaignRunPaginationDto } from '../model/';
// @ts-ignore
import { CampaignRunStepUserActivityDto } from '../model/';
// @ts-ignore
import { CampaignSendTo } from '../model/';
// @ts-ignore
import { EmailQueueSchema } from '../model/';
// @ts-ignore
import { PaginatedScheduledEmailsDto } from '../model/';
// @ts-ignore
import { ScheduleCampaignCreateDto } from '../model/';
// @ts-ignore
import { ScheduleExternalCampaignCreateDto } from '../model/';
/**
 * EmailQueueApi - axios parameter creator
 * @export
 */
export const EmailQueueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Blacklist users from receiving scheduled campaign emails
         * @param {string} emailQueueId 
         * @param {BlacklistScheduledEmailsDto} blacklistScheduledEmailsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerBlacklistUsersScheduledCampaignMail: async (emailQueueId: string, blacklistScheduledEmailsDto: BlacklistScheduledEmailsDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailQueueId' is not null or undefined
            assertParamExists('emailQueueControllerBlacklistUsersScheduledCampaignMail', 'emailQueueId', emailQueueId)
            // verify required parameter 'blacklistScheduledEmailsDto' is not null or undefined
            assertParamExists('emailQueueControllerBlacklistUsersScheduledCampaignMail', 'blacklistScheduledEmailsDto', blacklistScheduledEmailsDto)
            const localVarPath = `/api/email-queue/blacklist-users-scheduled-campaign-mail/{emailQueueId}`
                .replace(`{${"emailQueueId"}}`, encodeURIComponent(String(emailQueueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blacklistScheduledEmailsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clears queue for fetching basic and detailed mails statistics
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerClearBasicStatsQueue: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/email-queue/clear-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Filter all scheduled mails for a campaign
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFilterScheduledMailsForCampaign: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailQueueControllerFilterScheduledMailsForCampaign', 'id', id)
            const localVarPath = `/api/email-queue/scheduled-mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All email queues with source campaign
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/email-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the sent campaign mails V2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindAllSentMailsOfACampaign: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailQueueControllerFindAllSentMailsOfACampaign', 'id', id)
            const localVarPath = `/api/email-queue/get-campaign-stats-v2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all email activity of campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindAndUpdateStatisticsByXMessageId: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/email-queue/get-all-campaign-email-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the stats of a campaign mail sent (c2-level campaign step) V2
         * @param {string} id 
         * @param {string} uniqueRunId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindEmailActivityAggregation: async (id: string, uniqueRunId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailQueueControllerFindEmailActivityAggregation', 'id', id)
            // verify required parameter 'uniqueRunId' is not null or undefined
            assertParamExists('emailQueueControllerFindEmailActivityAggregation', 'uniqueRunId', uniqueRunId)
            const localVarPath = `/api/email-queue/get-campaign-mail-sent-v2/{id}/{uniqueRunId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"uniqueRunId"}}`, encodeURIComponent(String(uniqueRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the stats of a campaign mail sent (c2-level campaign)
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindMailSentStatsOfACampaign: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailQueueControllerFindMailSentStatsOfACampaign', 'id', id)
            const localVarPath = `/api/email-queue/get-campaign-stats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Email queue C2 info for all campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindMailSentStatsOfAllCampaigns: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/email-queue/email-queue-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mail sent statistics for all campaigns V2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindMailSentStatsOfAllCampaignsV2: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/email-queue/email-queue-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique surveyed c2s, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindUniqueSurveyedC2s: async (xTenantId?: string, surveyId?: string, campaignRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/email-queue/surveyed-c2s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['surveyId'] = surveyId;
            }

            if (campaignRunId !== undefined) {
                localVarQueryParameter['campaignRunId'] = campaignRunId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique surveyed u2s, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindUniqueSurveyedU2s: async (xTenantId?: string, surveyId?: string, campaignRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/email-queue/surveyed-u2s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['surveyId'] = surveyId;
            }

            if (campaignRunId !== undefined) {
                localVarQueryParameter['campaignRunId'] = campaignRunId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all user stats of a campaign step V2
         * @param {string} campaignRunId 
         * @param {string} campaignId 
         * @param {string} c2Id 
         * @param {string} stepId 
         * @param {string} [xTenantId] 
         * @param {number} [batchNo] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerGetUsersEmailActivity: async (campaignRunId: string, campaignId: string, c2Id: string, stepId: string, xTenantId?: string, batchNo?: number, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignRunId' is not null or undefined
            assertParamExists('emailQueueControllerGetUsersEmailActivity', 'campaignRunId', campaignRunId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('emailQueueControllerGetUsersEmailActivity', 'campaignId', campaignId)
            // verify required parameter 'c2Id' is not null or undefined
            assertParamExists('emailQueueControllerGetUsersEmailActivity', 'c2Id', c2Id)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('emailQueueControllerGetUsersEmailActivity', 'stepId', stepId)
            const localVarPath = `/api/email-queue/get-user-stats/{campaignRunId}`
                .replace(`{${"campaignRunId"}}`, encodeURIComponent(String(campaignRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (c2Id !== undefined) {
                localVarQueryParameter['c2Id'] = c2Id;
            }

            if (stepId !== undefined) {
                localVarQueryParameter['stepId'] = stepId;
            }

            if (batchNo !== undefined) {
                localVarQueryParameter['batchNo'] = batchNo;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create email queue entry from c2 list
         * @param {ScheduleCampaignCreateDto} scheduleCampaignCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerInsertDocuments: async (scheduleCampaignCreateDto: ScheduleCampaignCreateDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleCampaignCreateDto' is not null or undefined
            assertParamExists('emailQueueControllerInsertDocuments', 'scheduleCampaignCreateDto', scheduleCampaignCreateDto)
            const localVarPath = `/api/email-queue/create-mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleCampaignCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create email queue entry from playbook
         * @param {ScheduleExternalCampaignCreateDto} scheduleExternalCampaignCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerInsertDocumentsForPlaybookCampaigns: async (scheduleExternalCampaignCreateDto: ScheduleExternalCampaignCreateDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleExternalCampaignCreateDto' is not null or undefined
            assertParamExists('emailQueueControllerInsertDocumentsForPlaybookCampaigns', 'scheduleExternalCampaignCreateDto', scheduleExternalCampaignCreateDto)
            const localVarPath = `/api/email-queue/create-mails-playbook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleExternalCampaignCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop scheduled campaign mail send
         * @param {string} emailQueueId 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerRemoveJob: async (emailQueueId: string, id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailQueueId' is not null or undefined
            assertParamExists('emailQueueControllerRemoveJob', 'emailQueueId', emailQueueId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailQueueControllerRemoveJob', 'id', id)
            const localVarPath = `/api/email-queue/stop-mail-send/{emailQueueId}/{id}`
                .replace(`{${"emailQueueId"}}`, encodeURIComponent(String(emailQueueId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedule campaign mail send
         * @param {string} emailQueueId 
         * @param {string} id 
         * @param {CampaignEmailQueueUpdateDto} campaignEmailQueueUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerRescheduleJob: async (emailQueueId: string, id: string, campaignEmailQueueUpdateDto: CampaignEmailQueueUpdateDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailQueueId' is not null or undefined
            assertParamExists('emailQueueControllerRescheduleJob', 'emailQueueId', emailQueueId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailQueueControllerRescheduleJob', 'id', id)
            // verify required parameter 'campaignEmailQueueUpdateDto' is not null or undefined
            assertParamExists('emailQueueControllerRescheduleJob', 'campaignEmailQueueUpdateDto', campaignEmailQueueUpdateDto)
            const localVarPath = `/api/email-queue/reschedule-mail-send/{emailQueueId}/{id}`
                .replace(`{${"emailQueueId"}}`, encodeURIComponent(String(emailQueueId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignEmailQueueUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Campaign Mail
         * @param {CampaignSendTo} campaignSendTo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerSendCampaignMailSendgrid: async (campaignSendTo: CampaignSendTo, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignSendTo' is not null or undefined
            assertParamExists('emailQueueControllerSendCampaignMailSendgrid', 'campaignSendTo', campaignSendTo)
            const localVarPath = `/api/email-queue/send-campaign-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignSendTo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unregister C1 from campaign emails sending state
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerUnregisterC1MailSendState: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/email-queue/unregister-c1-mail-send-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailQueueApi - functional programming interface
 * @export
 */
export const EmailQueueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailQueueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Blacklist users from receiving scheduled campaign emails
         * @param {string} emailQueueId 
         * @param {BlacklistScheduledEmailsDto} blacklistScheduledEmailsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerBlacklistUsersScheduledCampaignMail(emailQueueId: string, blacklistScheduledEmailsDto: BlacklistScheduledEmailsDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailQueueSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerBlacklistUsersScheduledCampaignMail(emailQueueId, blacklistScheduledEmailsDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clears queue for fetching basic and detailed mails statistics
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerClearBasicStatsQueue(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerClearBasicStatsQueue(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Filter all scheduled mails for a campaign
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFilterScheduledMailsForCampaign(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedScheduledEmailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFilterScheduledMailsForCampaign(id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary All email queues with source campaign
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmailQueueSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the sent campaign mails V2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindAllSentMailsOfACampaign(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignRunPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindAllSentMailsOfACampaign(id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all email activity of campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindAndUpdateStatisticsByXMessageId(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindAndUpdateStatisticsByXMessageId(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the stats of a campaign mail sent (c2-level campaign step) V2
         * @param {string} id 
         * @param {string} uniqueRunId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindEmailActivityAggregation(id: string, uniqueRunId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignRunCustomersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindEmailActivityAggregation(id, uniqueRunId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the stats of a campaign mail sent (c2-level campaign)
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindMailSentStatsOfACampaign(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCustomerPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindMailSentStatsOfACampaign(id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Email queue C2 info for all campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindMailSentStatsOfAllCampaigns(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignMailInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindMailSentStatsOfAllCampaigns(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mail sent statistics for all campaigns V2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindMailSentStatsOfAllCampaignsV2(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignMailInfoV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindMailSentStatsOfAllCampaignsV2(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique surveyed c2s, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindUniqueSurveyedC2s(xTenantId?: string, surveyId?: string, campaignRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindUniqueSurveyedC2s(xTenantId, surveyId, campaignRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique surveyed u2s, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerFindUniqueSurveyedU2s(xTenantId?: string, surveyId?: string, campaignRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerFindUniqueSurveyedU2s(xTenantId, surveyId, campaignRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all user stats of a campaign step V2
         * @param {string} campaignRunId 
         * @param {string} campaignId 
         * @param {string} c2Id 
         * @param {string} stepId 
         * @param {string} [xTenantId] 
         * @param {number} [batchNo] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerGetUsersEmailActivity(campaignRunId: string, campaignId: string, c2Id: string, stepId: string, xTenantId?: string, batchNo?: number, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignRunStepUserActivityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerGetUsersEmailActivity(campaignRunId, campaignId, c2Id, stepId, xTenantId, batchNo, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create email queue entry from c2 list
         * @param {ScheduleCampaignCreateDto} scheduleCampaignCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerInsertDocuments(scheduleCampaignCreateDto: ScheduleCampaignCreateDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerInsertDocuments(scheduleCampaignCreateDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create email queue entry from playbook
         * @param {ScheduleExternalCampaignCreateDto} scheduleExternalCampaignCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerInsertDocumentsForPlaybookCampaigns(scheduleExternalCampaignCreateDto: ScheduleExternalCampaignCreateDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerInsertDocumentsForPlaybookCampaigns(scheduleExternalCampaignCreateDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop scheduled campaign mail send
         * @param {string} emailQueueId 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerRemoveJob(emailQueueId: string, id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailQueueSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerRemoveJob(emailQueueId, id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reschedule campaign mail send
         * @param {string} emailQueueId 
         * @param {string} id 
         * @param {CampaignEmailQueueUpdateDto} campaignEmailQueueUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerRescheduleJob(emailQueueId: string, id: string, campaignEmailQueueUpdateDto: CampaignEmailQueueUpdateDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailQueueSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerRescheduleJob(emailQueueId, id, campaignEmailQueueUpdateDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send Campaign Mail
         * @param {CampaignSendTo} campaignSendTo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerSendCampaignMailSendgrid(campaignSendTo: CampaignSendTo, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerSendCampaignMailSendgrid(campaignSendTo, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unregister C1 from campaign emails sending state
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailQueueControllerUnregisterC1MailSendState(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailQueueControllerUnregisterC1MailSendState(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailQueueApi - factory interface
 * @export
 */
export const EmailQueueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailQueueApiFp(configuration)
    return {
        /**
         * 
         * @summary Blacklist users from receiving scheduled campaign emails
         * @param {string} emailQueueId 
         * @param {BlacklistScheduledEmailsDto} blacklistScheduledEmailsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerBlacklistUsersScheduledCampaignMail(emailQueueId: string, blacklistScheduledEmailsDto: BlacklistScheduledEmailsDto, xTenantId?: string, options?: any): AxiosPromise<EmailQueueSchema> {
            return localVarFp.emailQueueControllerBlacklistUsersScheduledCampaignMail(emailQueueId, blacklistScheduledEmailsDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clears queue for fetching basic and detailed mails statistics
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerClearBasicStatsQueue(xTenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailQueueControllerClearBasicStatsQueue(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Filter all scheduled mails for a campaign
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFilterScheduledMailsForCampaign(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedScheduledEmailsDto> {
            return localVarFp.emailQueueControllerFilterScheduledMailsForCampaign(id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All email queues with source campaign
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<EmailQueueSchema>> {
            return localVarFp.emailQueueControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the sent campaign mails V2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindAllSentMailsOfACampaign(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<CampaignRunPaginationDto> {
            return localVarFp.emailQueueControllerFindAllSentMailsOfACampaign(id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all email activity of campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindAndUpdateStatisticsByXMessageId(xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailQueueControllerFindAndUpdateStatisticsByXMessageId(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the stats of a campaign mail sent (c2-level campaign step) V2
         * @param {string} id 
         * @param {string} uniqueRunId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindEmailActivityAggregation(id: string, uniqueRunId: string, xTenantId?: string, options?: any): AxiosPromise<CampaignRunCustomersDto> {
            return localVarFp.emailQueueControllerFindEmailActivityAggregation(id, uniqueRunId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the stats of a campaign mail sent (c2-level campaign)
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindMailSentStatsOfACampaign(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<CampaignCustomerPaginationDto> {
            return localVarFp.emailQueueControllerFindMailSentStatsOfACampaign(id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Email queue C2 info for all campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindMailSentStatsOfAllCampaigns(xTenantId?: string, options?: any): AxiosPromise<CampaignMailInfoDto> {
            return localVarFp.emailQueueControllerFindMailSentStatsOfAllCampaigns(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mail sent statistics for all campaigns V2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindMailSentStatsOfAllCampaignsV2(xTenantId?: string, options?: any): AxiosPromise<CampaignMailInfoV2Dto> {
            return localVarFp.emailQueueControllerFindMailSentStatsOfAllCampaignsV2(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique surveyed c2s, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindUniqueSurveyedC2s(xTenantId?: string, surveyId?: string, campaignRunId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.emailQueueControllerFindUniqueSurveyedC2s(xTenantId, surveyId, campaignRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique surveyed u2s, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerFindUniqueSurveyedU2s(xTenantId?: string, surveyId?: string, campaignRunId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.emailQueueControllerFindUniqueSurveyedU2s(xTenantId, surveyId, campaignRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all user stats of a campaign step V2
         * @param {string} campaignRunId 
         * @param {string} campaignId 
         * @param {string} c2Id 
         * @param {string} stepId 
         * @param {string} [xTenantId] 
         * @param {number} [batchNo] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerGetUsersEmailActivity(campaignRunId: string, campaignId: string, c2Id: string, stepId: string, xTenantId?: string, batchNo?: number, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<CampaignRunStepUserActivityDto> {
            return localVarFp.emailQueueControllerGetUsersEmailActivity(campaignRunId, campaignId, c2Id, stepId, xTenantId, batchNo, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create email queue entry from c2 list
         * @param {ScheduleCampaignCreateDto} scheduleCampaignCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerInsertDocuments(scheduleCampaignCreateDto: ScheduleCampaignCreateDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailQueueControllerInsertDocuments(scheduleCampaignCreateDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create email queue entry from playbook
         * @param {ScheduleExternalCampaignCreateDto} scheduleExternalCampaignCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerInsertDocumentsForPlaybookCampaigns(scheduleExternalCampaignCreateDto: ScheduleExternalCampaignCreateDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailQueueControllerInsertDocumentsForPlaybookCampaigns(scheduleExternalCampaignCreateDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop scheduled campaign mail send
         * @param {string} emailQueueId 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerRemoveJob(emailQueueId: string, id: string, xTenantId?: string, options?: any): AxiosPromise<EmailQueueSchema> {
            return localVarFp.emailQueueControllerRemoveJob(emailQueueId, id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reschedule campaign mail send
         * @param {string} emailQueueId 
         * @param {string} id 
         * @param {CampaignEmailQueueUpdateDto} campaignEmailQueueUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerRescheduleJob(emailQueueId: string, id: string, campaignEmailQueueUpdateDto: CampaignEmailQueueUpdateDto, xTenantId?: string, options?: any): AxiosPromise<EmailQueueSchema> {
            return localVarFp.emailQueueControllerRescheduleJob(emailQueueId, id, campaignEmailQueueUpdateDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Campaign Mail
         * @param {CampaignSendTo} campaignSendTo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerSendCampaignMailSendgrid(campaignSendTo: CampaignSendTo, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.emailQueueControllerSendCampaignMailSendgrid(campaignSendTo, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unregister C1 from campaign emails sending state
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailQueueControllerUnregisterC1MailSendState(xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.emailQueueControllerUnregisterC1MailSendState(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for emailQueueControllerBlacklistUsersScheduledCampaignMail operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerBlacklistUsersScheduledCampaignMailRequest
 */
export interface EmailQueueApiEmailQueueControllerBlacklistUsersScheduledCampaignMailRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerBlacklistUsersScheduledCampaignMail
     */
    readonly emailQueueId: string

    /**
     * 
     * @type {BlacklistScheduledEmailsDto}
     * @memberof EmailQueueApiEmailQueueControllerBlacklistUsersScheduledCampaignMail
     */
    readonly blacklistScheduledEmailsDto: BlacklistScheduledEmailsDto

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerBlacklistUsersScheduledCampaignMail
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerClearBasicStatsQueue operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerClearBasicStatsQueueRequest
 */
export interface EmailQueueApiEmailQueueControllerClearBasicStatsQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerClearBasicStatsQueue
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerFilterScheduledMailsForCampaign operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaignRequest
 */
export interface EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaignRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaign
     */
    readonly after?: string
}

/**
 * Request parameters for emailQueueControllerFindAll operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindAllRequest
 */
export interface EmailQueueApiEmailQueueControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerFindAllSentMailsOfACampaign operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaignRequest
 */
export interface EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaignRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaign
     */
    readonly after?: string
}

/**
 * Request parameters for emailQueueControllerFindAndUpdateStatisticsByXMessageId operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindAndUpdateStatisticsByXMessageIdRequest
 */
export interface EmailQueueApiEmailQueueControllerFindAndUpdateStatisticsByXMessageIdRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindAndUpdateStatisticsByXMessageId
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerFindEmailActivityAggregation operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindEmailActivityAggregationRequest
 */
export interface EmailQueueApiEmailQueueControllerFindEmailActivityAggregationRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindEmailActivityAggregation
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindEmailActivityAggregation
     */
    readonly uniqueRunId: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindEmailActivityAggregation
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerFindMailSentStatsOfACampaign operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaignRequest
 */
export interface EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaignRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaign
     */
    readonly after?: string
}

/**
 * Request parameters for emailQueueControllerFindMailSentStatsOfAllCampaigns operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsRequest
 */
export interface EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaigns
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerFindMailSentStatsOfAllCampaignsV2 operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsV2Request
 */
export interface EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsV2Request {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerFindUniqueSurveyedC2s operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindUniqueSurveyedC2sRequest
 */
export interface EmailQueueApiEmailQueueControllerFindUniqueSurveyedC2sRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindUniqueSurveyedC2s
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindUniqueSurveyedC2s
     */
    readonly surveyId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindUniqueSurveyedC2s
     */
    readonly campaignRunId?: string
}

/**
 * Request parameters for emailQueueControllerFindUniqueSurveyedU2s operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerFindUniqueSurveyedU2sRequest
 */
export interface EmailQueueApiEmailQueueControllerFindUniqueSurveyedU2sRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindUniqueSurveyedU2s
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindUniqueSurveyedU2s
     */
    readonly surveyId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerFindUniqueSurveyedU2s
     */
    readonly campaignRunId?: string
}

/**
 * Request parameters for emailQueueControllerGetUsersEmailActivity operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerGetUsersEmailActivityRequest
 */
export interface EmailQueueApiEmailQueueControllerGetUsersEmailActivityRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly campaignRunId: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly campaignId: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly c2Id: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly stepId: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly batchNo?: number

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerGetUsersEmailActivity
     */
    readonly after?: string
}

/**
 * Request parameters for emailQueueControllerInsertDocuments operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerInsertDocumentsRequest
 */
export interface EmailQueueApiEmailQueueControllerInsertDocumentsRequest {
    /**
     * 
     * @type {ScheduleCampaignCreateDto}
     * @memberof EmailQueueApiEmailQueueControllerInsertDocuments
     */
    readonly scheduleCampaignCreateDto: ScheduleCampaignCreateDto

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerInsertDocuments
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerInsertDocumentsForPlaybookCampaigns operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerInsertDocumentsForPlaybookCampaignsRequest
 */
export interface EmailQueueApiEmailQueueControllerInsertDocumentsForPlaybookCampaignsRequest {
    /**
     * 
     * @type {ScheduleExternalCampaignCreateDto}
     * @memberof EmailQueueApiEmailQueueControllerInsertDocumentsForPlaybookCampaigns
     */
    readonly scheduleExternalCampaignCreateDto: ScheduleExternalCampaignCreateDto

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerInsertDocumentsForPlaybookCampaigns
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerRemoveJob operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerRemoveJobRequest
 */
export interface EmailQueueApiEmailQueueControllerRemoveJobRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerRemoveJob
     */
    readonly emailQueueId: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerRemoveJob
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerRemoveJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerRescheduleJob operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerRescheduleJobRequest
 */
export interface EmailQueueApiEmailQueueControllerRescheduleJobRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerRescheduleJob
     */
    readonly emailQueueId: string

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerRescheduleJob
     */
    readonly id: string

    /**
     * 
     * @type {CampaignEmailQueueUpdateDto}
     * @memberof EmailQueueApiEmailQueueControllerRescheduleJob
     */
    readonly campaignEmailQueueUpdateDto: CampaignEmailQueueUpdateDto

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerRescheduleJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerSendCampaignMailSendgrid operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerSendCampaignMailSendgridRequest
 */
export interface EmailQueueApiEmailQueueControllerSendCampaignMailSendgridRequest {
    /**
     * 
     * @type {CampaignSendTo}
     * @memberof EmailQueueApiEmailQueueControllerSendCampaignMailSendgrid
     */
    readonly campaignSendTo: CampaignSendTo

    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerSendCampaignMailSendgrid
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for emailQueueControllerUnregisterC1MailSendState operation in EmailQueueApi.
 * @export
 * @interface EmailQueueApiEmailQueueControllerUnregisterC1MailSendStateRequest
 */
export interface EmailQueueApiEmailQueueControllerUnregisterC1MailSendStateRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQueueApiEmailQueueControllerUnregisterC1MailSendState
     */
    readonly xTenantId?: string
}

/**
 * EmailQueueApi - object-oriented interface
 * @export
 * @class EmailQueueApi
 * @extends {BaseAPI}
 */
export class EmailQueueApi extends BaseAPI {
    /**
     * 
     * @summary Blacklist users from receiving scheduled campaign emails
     * @param {EmailQueueApiEmailQueueControllerBlacklistUsersScheduledCampaignMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerBlacklistUsersScheduledCampaignMail(requestParameters: EmailQueueApiEmailQueueControllerBlacklistUsersScheduledCampaignMailRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerBlacklistUsersScheduledCampaignMail(requestParameters.emailQueueId, requestParameters.blacklistScheduledEmailsDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clears queue for fetching basic and detailed mails statistics
     * @param {EmailQueueApiEmailQueueControllerClearBasicStatsQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerClearBasicStatsQueue(requestParameters: EmailQueueApiEmailQueueControllerClearBasicStatsQueueRequest = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerClearBasicStatsQueue(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Filter all scheduled mails for a campaign
     * @param {EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFilterScheduledMailsForCampaign(requestParameters: EmailQueueApiEmailQueueControllerFilterScheduledMailsForCampaignRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFilterScheduledMailsForCampaign(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All email queues with source campaign
     * @param {EmailQueueApiEmailQueueControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindAll(requestParameters: EmailQueueApiEmailQueueControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the sent campaign mails V2
     * @param {EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindAllSentMailsOfACampaign(requestParameters: EmailQueueApiEmailQueueControllerFindAllSentMailsOfACampaignRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindAllSentMailsOfACampaign(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all email activity of campaigns
     * @param {EmailQueueApiEmailQueueControllerFindAndUpdateStatisticsByXMessageIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindAndUpdateStatisticsByXMessageId(requestParameters: EmailQueueApiEmailQueueControllerFindAndUpdateStatisticsByXMessageIdRequest = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindAndUpdateStatisticsByXMessageId(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the stats of a campaign mail sent (c2-level campaign step) V2
     * @param {EmailQueueApiEmailQueueControllerFindEmailActivityAggregationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindEmailActivityAggregation(requestParameters: EmailQueueApiEmailQueueControllerFindEmailActivityAggregationRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindEmailActivityAggregation(requestParameters.id, requestParameters.uniqueRunId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the stats of a campaign mail sent (c2-level campaign)
     * @param {EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindMailSentStatsOfACampaign(requestParameters: EmailQueueApiEmailQueueControllerFindMailSentStatsOfACampaignRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindMailSentStatsOfACampaign(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Email queue C2 info for all campaigns
     * @param {EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindMailSentStatsOfAllCampaigns(requestParameters: EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsRequest = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindMailSentStatsOfAllCampaigns(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mail sent statistics for all campaigns V2
     * @param {EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindMailSentStatsOfAllCampaignsV2(requestParameters: EmailQueueApiEmailQueueControllerFindMailSentStatsOfAllCampaignsV2Request = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindMailSentStatsOfAllCampaignsV2(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique surveyed c2s, with optional survey id and campaign run id fields
     * @param {EmailQueueApiEmailQueueControllerFindUniqueSurveyedC2sRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindUniqueSurveyedC2s(requestParameters: EmailQueueApiEmailQueueControllerFindUniqueSurveyedC2sRequest = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindUniqueSurveyedC2s(requestParameters.xTenantId, requestParameters.surveyId, requestParameters.campaignRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique surveyed u2s, with optional survey id and campaign run id fields
     * @param {EmailQueueApiEmailQueueControllerFindUniqueSurveyedU2sRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerFindUniqueSurveyedU2s(requestParameters: EmailQueueApiEmailQueueControllerFindUniqueSurveyedU2sRequest = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerFindUniqueSurveyedU2s(requestParameters.xTenantId, requestParameters.surveyId, requestParameters.campaignRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all user stats of a campaign step V2
     * @param {EmailQueueApiEmailQueueControllerGetUsersEmailActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerGetUsersEmailActivity(requestParameters: EmailQueueApiEmailQueueControllerGetUsersEmailActivityRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerGetUsersEmailActivity(requestParameters.campaignRunId, requestParameters.campaignId, requestParameters.c2Id, requestParameters.stepId, requestParameters.xTenantId, requestParameters.batchNo, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create email queue entry from c2 list
     * @param {EmailQueueApiEmailQueueControllerInsertDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerInsertDocuments(requestParameters: EmailQueueApiEmailQueueControllerInsertDocumentsRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerInsertDocuments(requestParameters.scheduleCampaignCreateDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create email queue entry from playbook
     * @param {EmailQueueApiEmailQueueControllerInsertDocumentsForPlaybookCampaignsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerInsertDocumentsForPlaybookCampaigns(requestParameters: EmailQueueApiEmailQueueControllerInsertDocumentsForPlaybookCampaignsRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerInsertDocumentsForPlaybookCampaigns(requestParameters.scheduleExternalCampaignCreateDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop scheduled campaign mail send
     * @param {EmailQueueApiEmailQueueControllerRemoveJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerRemoveJob(requestParameters: EmailQueueApiEmailQueueControllerRemoveJobRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerRemoveJob(requestParameters.emailQueueId, requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reschedule campaign mail send
     * @param {EmailQueueApiEmailQueueControllerRescheduleJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerRescheduleJob(requestParameters: EmailQueueApiEmailQueueControllerRescheduleJobRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerRescheduleJob(requestParameters.emailQueueId, requestParameters.id, requestParameters.campaignEmailQueueUpdateDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Campaign Mail
     * @param {EmailQueueApiEmailQueueControllerSendCampaignMailSendgridRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerSendCampaignMailSendgrid(requestParameters: EmailQueueApiEmailQueueControllerSendCampaignMailSendgridRequest, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerSendCampaignMailSendgrid(requestParameters.campaignSendTo, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unregister C1 from campaign emails sending state
     * @param {EmailQueueApiEmailQueueControllerUnregisterC1MailSendStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailQueueApi
     */
    public emailQueueControllerUnregisterC1MailSendState(requestParameters: EmailQueueApiEmailQueueControllerUnregisterC1MailSendStateRequest = {}, options?: AxiosRequestConfig) {
        return EmailQueueApiFp(this.configuration).emailQueueControllerUnregisterC1MailSendState(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
