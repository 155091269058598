/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedPlanToProductMappingDto } from '../model/';
// @ts-ignore
import { PlanToProductMapping } from '../model/';
// @ts-ignore
import { PlanToProductMappingFilterDto } from '../model/';
// @ts-ignore
import { UpdateAllPlanToProductMappingDto } from '../model/';
// @ts-ignore
import { UpdatePlanToProductMappingDto } from '../model/';
/**
 * PlanToProductMappingApi - axios parameter creator
 * @export
 */
export const PlanToProductMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy Row to all
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerAssignToAll: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planToProductMappingControllerAssignToAll', 'id', id)
            const localVarPath = `/api/plan-to-product-mapping/{id}/assign-to-all`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Count of all plan to product mappings with product info by app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerFetchAssignedCount: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('planToProductMappingControllerFetchAssignedCount', 'appId', appId)
            const localVarPath = `/api/plan-to-product-mapping/assigned-count/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Filter app wise plan to product mapping
         * @param {PlanToProductMappingFilterDto} planToProductMappingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerFilterPlanToProductMapping: async (planToProductMappingFilterDto: PlanToProductMappingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planToProductMappingFilterDto' is not null or undefined
            assertParamExists('planToProductMappingControllerFilterPlanToProductMapping', 'planToProductMappingFilterDto', planToProductMappingFilterDto)
            const localVarPath = `/api/plan-to-product-mapping/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(planToProductMappingFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of plan to product mappings by app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerFindAllByApp: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('planToProductMappingControllerFindAllByApp', 'appId', appId)
            const localVarPath = `/api/plan-to-product-mapping/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update plan to product mapping
         * @param {string} id 
         * @param {UpdatePlanToProductMappingDto} updatePlanToProductMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerUpdatePlanToProductMapping: async (id: string, updatePlanToProductMappingDto: UpdatePlanToProductMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planToProductMappingControllerUpdatePlanToProductMapping', 'id', id)
            // verify required parameter 'updatePlanToProductMappingDto' is not null or undefined
            assertParamExists('planToProductMappingControllerUpdatePlanToProductMapping', 'updatePlanToProductMappingDto', updatePlanToProductMappingDto)
            const localVarPath = `/api/plan-to-product-mapping/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlanToProductMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update plan to product mappings list
         * @param {UpdateAllPlanToProductMappingDto} updateAllPlanToProductMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerUpdatePlanToProductMappingList: async (updateAllPlanToProductMappingDto: UpdateAllPlanToProductMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAllPlanToProductMappingDto' is not null or undefined
            assertParamExists('planToProductMappingControllerUpdatePlanToProductMappingList', 'updateAllPlanToProductMappingDto', updateAllPlanToProductMappingDto)
            const localVarPath = `/api/plan-to-product-mapping/update-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllPlanToProductMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanToProductMappingApi - functional programming interface
 * @export
 */
export const PlanToProductMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanToProductMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy Row to all
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planToProductMappingControllerAssignToAll(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanToProductMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planToProductMappingControllerAssignToAll(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Count of all plan to product mappings with product info by app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planToProductMappingControllerFetchAssignedCount(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planToProductMappingControllerFetchAssignedCount(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Filter app wise plan to product mapping
         * @param {PlanToProductMappingFilterDto} planToProductMappingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planToProductMappingControllerFilterPlanToProductMapping(planToProductMappingFilterDto: PlanToProductMappingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPlanToProductMappingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planToProductMappingControllerFilterPlanToProductMapping(planToProductMappingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of plan to product mappings by app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planToProductMappingControllerFindAllByApp(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanToProductMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planToProductMappingControllerFindAllByApp(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update plan to product mapping
         * @param {string} id 
         * @param {UpdatePlanToProductMappingDto} updatePlanToProductMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planToProductMappingControllerUpdatePlanToProductMapping(id: string, updatePlanToProductMappingDto: UpdatePlanToProductMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanToProductMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planToProductMappingControllerUpdatePlanToProductMapping(id, updatePlanToProductMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update plan to product mappings list
         * @param {UpdateAllPlanToProductMappingDto} updateAllPlanToProductMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planToProductMappingControllerUpdatePlanToProductMappingList(updateAllPlanToProductMappingDto: UpdateAllPlanToProductMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planToProductMappingControllerUpdatePlanToProductMappingList(updateAllPlanToProductMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanToProductMappingApi - factory interface
 * @export
 */
export const PlanToProductMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanToProductMappingApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy Row to all
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerAssignToAll(id: string, xTenantId?: string, options?: any): AxiosPromise<PlanToProductMapping> {
            return localVarFp.planToProductMappingControllerAssignToAll(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Count of all plan to product mappings with product info by app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerFetchAssignedCount(appId: string, xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.planToProductMappingControllerFetchAssignedCount(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Filter app wise plan to product mapping
         * @param {PlanToProductMappingFilterDto} planToProductMappingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerFilterPlanToProductMapping(planToProductMappingFilterDto: PlanToProductMappingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedPlanToProductMappingDto> {
            return localVarFp.planToProductMappingControllerFilterPlanToProductMapping(planToProductMappingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of plan to product mappings by app
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerFindAllByApp(appId: string, xTenantId?: string, options?: any): AxiosPromise<Array<PlanToProductMapping>> {
            return localVarFp.planToProductMappingControllerFindAllByApp(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update plan to product mapping
         * @param {string} id 
         * @param {UpdatePlanToProductMappingDto} updatePlanToProductMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerUpdatePlanToProductMapping(id: string, updatePlanToProductMappingDto: UpdatePlanToProductMappingDto, xTenantId?: string, options?: any): AxiosPromise<PlanToProductMapping> {
            return localVarFp.planToProductMappingControllerUpdatePlanToProductMapping(id, updatePlanToProductMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update plan to product mappings list
         * @param {UpdateAllPlanToProductMappingDto} updateAllPlanToProductMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planToProductMappingControllerUpdatePlanToProductMappingList(updateAllPlanToProductMappingDto: UpdateAllPlanToProductMappingDto, xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.planToProductMappingControllerUpdatePlanToProductMappingList(updateAllPlanToProductMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for planToProductMappingControllerAssignToAll operation in PlanToProductMappingApi.
 * @export
 * @interface PlanToProductMappingApiPlanToProductMappingControllerAssignToAllRequest
 */
export interface PlanToProductMappingApiPlanToProductMappingControllerAssignToAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerAssignToAll
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerAssignToAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for planToProductMappingControllerFetchAssignedCount operation in PlanToProductMappingApi.
 * @export
 * @interface PlanToProductMappingApiPlanToProductMappingControllerFetchAssignedCountRequest
 */
export interface PlanToProductMappingApiPlanToProductMappingControllerFetchAssignedCountRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFetchAssignedCount
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFetchAssignedCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for planToProductMappingControllerFilterPlanToProductMapping operation in PlanToProductMappingApi.
 * @export
 * @interface PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMappingRequest
 */
export interface PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMappingRequest {
    /**
     * 
     * @type {PlanToProductMappingFilterDto}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly planToProductMappingFilterDto: PlanToProductMappingFilterDto

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMapping
     */
    readonly after?: string
}

/**
 * Request parameters for planToProductMappingControllerFindAllByApp operation in PlanToProductMappingApi.
 * @export
 * @interface PlanToProductMappingApiPlanToProductMappingControllerFindAllByAppRequest
 */
export interface PlanToProductMappingApiPlanToProductMappingControllerFindAllByAppRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFindAllByApp
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerFindAllByApp
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for planToProductMappingControllerUpdatePlanToProductMapping operation in PlanToProductMappingApi.
 * @export
 * @interface PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingRequest
 */
export interface PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMapping
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlanToProductMappingDto}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMapping
     */
    readonly updatePlanToProductMappingDto: UpdatePlanToProductMappingDto

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMapping
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for planToProductMappingControllerUpdatePlanToProductMappingList operation in PlanToProductMappingApi.
 * @export
 * @interface PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingListRequest
 */
export interface PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingListRequest {
    /**
     * 
     * @type {UpdateAllPlanToProductMappingDto}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingList
     */
    readonly updateAllPlanToProductMappingDto: UpdateAllPlanToProductMappingDto

    /**
     * 
     * @type {string}
     * @memberof PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingList
     */
    readonly xTenantId?: string
}

/**
 * PlanToProductMappingApi - object-oriented interface
 * @export
 * @class PlanToProductMappingApi
 * @extends {BaseAPI}
 */
export class PlanToProductMappingApi extends BaseAPI {
    /**
     * 
     * @summary Copy Row to all
     * @param {PlanToProductMappingApiPlanToProductMappingControllerAssignToAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanToProductMappingApi
     */
    public planToProductMappingControllerAssignToAll(requestParameters: PlanToProductMappingApiPlanToProductMappingControllerAssignToAllRequest, options?: AxiosRequestConfig) {
        return PlanToProductMappingApiFp(this.configuration).planToProductMappingControllerAssignToAll(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Count of all plan to product mappings with product info by app
     * @param {PlanToProductMappingApiPlanToProductMappingControllerFetchAssignedCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanToProductMappingApi
     */
    public planToProductMappingControllerFetchAssignedCount(requestParameters: PlanToProductMappingApiPlanToProductMappingControllerFetchAssignedCountRequest, options?: AxiosRequestConfig) {
        return PlanToProductMappingApiFp(this.configuration).planToProductMappingControllerFetchAssignedCount(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Filter app wise plan to product mapping
     * @param {PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMappingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanToProductMappingApi
     */
    public planToProductMappingControllerFilterPlanToProductMapping(requestParameters: PlanToProductMappingApiPlanToProductMappingControllerFilterPlanToProductMappingRequest, options?: AxiosRequestConfig) {
        return PlanToProductMappingApiFp(this.configuration).planToProductMappingControllerFilterPlanToProductMapping(requestParameters.planToProductMappingFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of plan to product mappings by app
     * @param {PlanToProductMappingApiPlanToProductMappingControllerFindAllByAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanToProductMappingApi
     */
    public planToProductMappingControllerFindAllByApp(requestParameters: PlanToProductMappingApiPlanToProductMappingControllerFindAllByAppRequest, options?: AxiosRequestConfig) {
        return PlanToProductMappingApiFp(this.configuration).planToProductMappingControllerFindAllByApp(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update plan to product mapping
     * @param {PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanToProductMappingApi
     */
    public planToProductMappingControllerUpdatePlanToProductMapping(requestParameters: PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingRequest, options?: AxiosRequestConfig) {
        return PlanToProductMappingApiFp(this.configuration).planToProductMappingControllerUpdatePlanToProductMapping(requestParameters.id, requestParameters.updatePlanToProductMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update plan to product mappings list
     * @param {PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanToProductMappingApi
     */
    public planToProductMappingControllerUpdatePlanToProductMappingList(requestParameters: PlanToProductMappingApiPlanToProductMappingControllerUpdatePlanToProductMappingListRequest, options?: AxiosRequestConfig) {
        return PlanToProductMappingApiFp(this.configuration).planToProductMappingControllerUpdatePlanToProductMappingList(requestParameters.updateAllPlanToProductMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
