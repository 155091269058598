import { featureFlagsApi } from '@/api';
import { queryOptions } from '@tanstack/vue-query';

export const featureFlagQueryOptions = {
  list: () => {
    return queryOptions({
      queryKey: ['list-feature-flags'],
      queryFn: async () => {
        const { data } = await featureFlagsApi.featureFlagsControllerFindAll();
        return data;
      },
    });
  },

  findAllEnabledKeysForC1: () => {
    return queryOptions({
      queryKey: ['enabled-feature-flag-keys-for-c1'],
      queryFn: async () => {
        const { data } = await featureFlagsApi.featureFlagsControllerFindAllEnabledForC1();
        return data;
      },
    });
  },
};
