<script setup lang="ts">
import { C1HealthScore, C2TypeHealthScore, DateTypeEnum, Kpi } from '@/api/client';
import LineChart from '@/components/Charts/LineChart.vue';
import { MessageSchema } from '@/lib/i18n';
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { useDateStore } from '@/stores/date';
import { useKpiStore } from '@/stores/kpi';
import { LineChartType } from '@/types/linechart.type';
import { ChartData } from 'chart.js';
import { addDays, addMonths, addQuarters, addWeeks, addYears, isSunday, parseJSON } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const { dashboardFor, isHealthScoreListLoading, healthScoreList, areaChartFor } = storeToRefs(useDashboardV2Store());
const _dateStore = useDateStore();
const { dateInfo } = storeToRefs(_dateStore);

const _kpiStore = useKpiStore();
const { orderedActiveKpiList } = storeToRefs(_kpiStore);

const dataSet = computed(() => {
  const listOfLabel: string[] = [];
  if (healthScoreList.value.length <= 0) {
    return {
      labels: listOfLabel,
      datasets: [],
    };
  }
  const healthList = [...healthScoreList.value];
  // Add virtual first element when the c1HealthScoreList's length is 1
  if (healthList.length === 1) {
    const lastElement = healthList[0];
    const virtualElement = dashboardFor.value.id === '-' ? ({} as C1HealthScore) : ({} as C2TypeHealthScore);
    switch (dateInfo.value?.type) {
      case DateTypeEnum.Week:
        virtualElement.date = addWeeks(parseJSON(lastElement.date), -1).toISOString();
        break;
      case DateTypeEnum.Month:
        virtualElement.date = addMonths(parseJSON(lastElement.date), -1).toISOString();
        break;
      case DateTypeEnum.Quarter:
        virtualElement.date = addQuarters(parseJSON(lastElement.date), -1).toISOString();
        break;
      case DateTypeEnum.Year:
        virtualElement.date = addYears(parseJSON(lastElement.date), -1).toISOString();
        break;
      default:
        {
          // If previous day is Sunday, set Friday as previous day
          let previousDay = addDays(parseJSON(lastElement.date), -1);
          if (isSunday(previousDay)) {
            previousDay = addDays(previousDay, -2);
          }
          virtualElement.date = previousDay.toISOString();
        }

        break;
    }
    healthList.unshift(virtualElement);
  }

  const dateList = [...new Set(healthList.map((x) => x.date))].sort();
  const data: number[] = [];
  for (let i = 0; i < dateList.length; i++) {
    listOfLabel.push(_dateStore.getDateLabel(dateList[i]));
    const healthScore = healthList.find((x) => x.date === dateList[i]);
    if (!healthScore) {
      data.push(0);
      continue;
    }
    const selectedKpi = areaChartFor.value;
    type HealthScoreObjectKey = keyof typeof healthScore;
    const scoreKey = `${selectedKpi}_score` as HealthScoreObjectKey;
    const value = healthScore[scoreKey] as number;
    data.push(value);
  }
  const chartColor = LineChartType.OVERALL_SCORE.color;
  const c1TypeTrend = [];
  c1TypeTrend.push({
    label: 'Overall Score',
    data: data,
    fill: true,
    backgroundColor: chartColor,
    borderColor: chartColor,
    pointRadius: 0,
    tension: 0.1,
  });
  return {
    labels: [...listOfLabel],
    datasets: [...c1TypeTrend],
  } as ChartData<'line'>;
});

function kpiOptionStyle(kpi: Kpi) {
  let padding = 16;
  const style = { 'padding-left': `${padding}px`, 'font-weight': 'normal' };
  if (kpi.kpi_level !== 0 && kpi.kpi_level !== 1) {
    padding = padding * kpi.kpi_level;
    style['padding-left'] = `${padding}px`;
  }
  const hasChildren = orderedActiveKpiList.value.filter((x) => x.parent_kpi_id === kpi.id)?.length > 0;
  if (hasChildren || kpi.kpi_level === 0 || kpi.kpi_level === 1) {
    style['font-weight'] = 'bold';
  }
  return style;
}
</script>

<template>
  <LineChart
    :heading="t('area_chart_section.title')"
    :data-set="dataSet"
    :is-loading="isHealthScoreListLoading"
    :hide-labels="true"
  >
    <template v-slot:dropdown>
      <q-select
        outlined
        input-debounce="0"
        v-model="areaChartFor"
        emit-value
        map-options
        :options="orderedActiveKpiList"
        :dense="true"
        option-value="name"
        class="tw-col-span-5"
      >
        <template v-slot:selected>
          <span class="tw-truncate tw-text-sm tw-font-semibold tw-text-dark-shade-4">
            {{ orderedActiveKpiList?.find((x) => x.name === areaChartFor)?.display_name }}
          </span>
        </template>
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps" :style="kpiOptionStyle(scope.opt)" class="tw-min-w-[25rem]">
            <q-item-section> {{ scope.opt.display_name }} </q-item-section>
          </q-item>
        </template>
      </q-select>
    </template>
  </LineChart>
</template>
