/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCustomKpiInfoDto } from '../model/';
// @ts-ignore
import { Kpi } from '../model/';
// @ts-ignore
import { UpdateCustomKpiRuleDto } from '../model/';
/**
 * CustomKpiRuleApi - axios parameter creator
 * @export
 */
export const CustomKpiRuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Custom-KPI with related information in outcome success and custom-kpi-rule
         * @param {CreateCustomKpiInfoDto} createCustomKpiInfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customKpiRuleControllerCreate: async (createCustomKpiInfoDto: CreateCustomKpiInfoDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomKpiInfoDto' is not null or undefined
            assertParamExists('customKpiRuleControllerCreate', 'createCustomKpiInfoDto', createCustomKpiInfoDto)
            const localVarPath = `/api/custom-kpi-rule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomKpiInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update custom-kpi rule
         * @param {UpdateCustomKpiRuleDto} updateCustomKpiRuleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customKpiRuleControllerCustomKPIRuleUpdate: async (updateCustomKpiRuleDto: UpdateCustomKpiRuleDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomKpiRuleDto' is not null or undefined
            assertParamExists('customKpiRuleControllerCustomKPIRuleUpdate', 'updateCustomKpiRuleDto', updateCustomKpiRuleDto)
            const localVarPath = `/api/custom-kpi-rule/custom-kpi-rule-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomKpiRuleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomKpiRuleApi - functional programming interface
 * @export
 */
export const CustomKpiRuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomKpiRuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Custom-KPI with related information in outcome success and custom-kpi-rule
         * @param {CreateCustomKpiInfoDto} createCustomKpiInfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customKpiRuleControllerCreate(createCustomKpiInfoDto: CreateCustomKpiInfoDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Kpi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customKpiRuleControllerCreate(createCustomKpiInfoDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update custom-kpi rule
         * @param {UpdateCustomKpiRuleDto} updateCustomKpiRuleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customKpiRuleControllerCustomKPIRuleUpdate(updateCustomKpiRuleDto: UpdateCustomKpiRuleDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customKpiRuleControllerCustomKPIRuleUpdate(updateCustomKpiRuleDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomKpiRuleApi - factory interface
 * @export
 */
export const CustomKpiRuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomKpiRuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Custom-KPI with related information in outcome success and custom-kpi-rule
         * @param {CreateCustomKpiInfoDto} createCustomKpiInfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customKpiRuleControllerCreate(createCustomKpiInfoDto: CreateCustomKpiInfoDto, xTenantId?: string, options?: any): AxiosPromise<Kpi> {
            return localVarFp.customKpiRuleControllerCreate(createCustomKpiInfoDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update custom-kpi rule
         * @param {UpdateCustomKpiRuleDto} updateCustomKpiRuleDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customKpiRuleControllerCustomKPIRuleUpdate(updateCustomKpiRuleDto: UpdateCustomKpiRuleDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.customKpiRuleControllerCustomKPIRuleUpdate(updateCustomKpiRuleDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for customKpiRuleControllerCreate operation in CustomKpiRuleApi.
 * @export
 * @interface CustomKpiRuleApiCustomKpiRuleControllerCreateRequest
 */
export interface CustomKpiRuleApiCustomKpiRuleControllerCreateRequest {
    /**
     * 
     * @type {CreateCustomKpiInfoDto}
     * @memberof CustomKpiRuleApiCustomKpiRuleControllerCreate
     */
    readonly createCustomKpiInfoDto: CreateCustomKpiInfoDto

    /**
     * 
     * @type {string}
     * @memberof CustomKpiRuleApiCustomKpiRuleControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customKpiRuleControllerCustomKPIRuleUpdate operation in CustomKpiRuleApi.
 * @export
 * @interface CustomKpiRuleApiCustomKpiRuleControllerCustomKPIRuleUpdateRequest
 */
export interface CustomKpiRuleApiCustomKpiRuleControllerCustomKPIRuleUpdateRequest {
    /**
     * 
     * @type {UpdateCustomKpiRuleDto}
     * @memberof CustomKpiRuleApiCustomKpiRuleControllerCustomKPIRuleUpdate
     */
    readonly updateCustomKpiRuleDto: UpdateCustomKpiRuleDto

    /**
     * 
     * @type {string}
     * @memberof CustomKpiRuleApiCustomKpiRuleControllerCustomKPIRuleUpdate
     */
    readonly xTenantId?: string
}

/**
 * CustomKpiRuleApi - object-oriented interface
 * @export
 * @class CustomKpiRuleApi
 * @extends {BaseAPI}
 */
export class CustomKpiRuleApi extends BaseAPI {
    /**
     * 
     * @summary Create Custom-KPI with related information in outcome success and custom-kpi-rule
     * @param {CustomKpiRuleApiCustomKpiRuleControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomKpiRuleApi
     */
    public customKpiRuleControllerCreate(requestParameters: CustomKpiRuleApiCustomKpiRuleControllerCreateRequest, options?: AxiosRequestConfig) {
        return CustomKpiRuleApiFp(this.configuration).customKpiRuleControllerCreate(requestParameters.createCustomKpiInfoDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update custom-kpi rule
     * @param {CustomKpiRuleApiCustomKpiRuleControllerCustomKPIRuleUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomKpiRuleApi
     */
    public customKpiRuleControllerCustomKPIRuleUpdate(requestParameters: CustomKpiRuleApiCustomKpiRuleControllerCustomKPIRuleUpdateRequest, options?: AxiosRequestConfig) {
        return CustomKpiRuleApiFp(this.configuration).customKpiRuleControllerCustomKPIRuleUpdate(requestParameters.updateCustomKpiRuleDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
