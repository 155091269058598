/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RoleBaseU1Aggregation } from '../model/';
// @ts-ignore
import { U1Aggregation } from '../model/';
/**
 * U1AggregationApi - axios parameter creator
 * @export
 */
export const U1AggregationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of U1Aggregations
         * @param {string} u1RoleId 
         * @param {string} c1DepartmentId 
         * @param {string} [xTenantId] 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} [type] Optional date type field, if provided list will be displayed for that date
         * @param {string} [dateUtc] Optional date utc field, if provided list will be displayed for that date
         * @param {string} [date] Optional date field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindAll: async (u1RoleId: string, c1DepartmentId: string, xTenantId?: string, type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', dateUtc?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u1RoleId' is not null or undefined
            assertParamExists('u1AggregationsControllerFindAll', 'u1RoleId', u1RoleId)
            // verify required parameter 'c1DepartmentId' is not null or undefined
            assertParamExists('u1AggregationsControllerFindAll', 'c1DepartmentId', c1DepartmentId)
            const localVarPath = `/api/u1-aggregations/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (u1RoleId !== undefined) {
                localVarQueryParameter['u1_role_id'] = u1RoleId;
            }

            if (c1DepartmentId !== undefined) {
                localVarQueryParameter['c1_department_id'] = c1DepartmentId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dateUtc !== undefined) {
                localVarQueryParameter['dateUtc'] = (dateUtc as any instanceof Date) ?
                    (dateUtc as any).toISOString() :
                    dateUtc;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of u1 aggregations for zapbook v2
         * @param {string} u1RoleId 
         * @param {string} c1DepartmentId 
         * @param {number} dayId Day id for which u1 aggregation data will be shown
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} type Date type field for which u1 aggregation data will be shown
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindAllU1Aggregation: async (u1RoleId: string, c1DepartmentId: string, dayId: number, type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u1RoleId' is not null or undefined
            assertParamExists('u1AggregationsControllerFindAllU1Aggregation', 'u1RoleId', u1RoleId)
            // verify required parameter 'c1DepartmentId' is not null or undefined
            assertParamExists('u1AggregationsControllerFindAllU1Aggregation', 'c1DepartmentId', c1DepartmentId)
            // verify required parameter 'dayId' is not null or undefined
            assertParamExists('u1AggregationsControllerFindAllU1Aggregation', 'dayId', dayId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('u1AggregationsControllerFindAllU1Aggregation', 'type', type)
            const localVarPath = `/api/v2/u1-aggregations/find-all-u1-aggregations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (u1RoleId !== undefined) {
                localVarQueryParameter['u1_role_id'] = u1RoleId;
            }

            if (c1DepartmentId !== undefined) {
                localVarQueryParameter['c1_department_id'] = c1DepartmentId;
            }

            if (dayId !== undefined) {
                localVarQueryParameter['day_id'] = dayId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find latest u1 aggregation for zapbook v2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindLatestU1Aggregation: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/u1-aggregations/find-latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a particular U1Aggregation against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1AggregationsControllerFindOne', 'id', id)
            const localVarPath = `/api/u1-aggregations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get U1-Aggregation by date
         * @param {string} [xTenantId] 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} [type] Optional date type field, if provided list will be displayed for that date
         * @param {string} [dateUtc] Optional date utc field, if provided list will be displayed for that date
         * @param {string} [date] Optional date field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindOneByDateInfo: async (xTenantId?: string, type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', dateUtc?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1-aggregations/find-by-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dateUtc !== undefined) {
                localVarQueryParameter['dateUtc'] = (dateUtc as any instanceof Date) ?
                    (dateUtc as any).toISOString() :
                    dateUtc;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U1AggregationApi - functional programming interface
 * @export
 */
export const U1AggregationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U1AggregationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of U1Aggregations
         * @param {string} u1RoleId 
         * @param {string} c1DepartmentId 
         * @param {string} [xTenantId] 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} [type] Optional date type field, if provided list will be displayed for that date
         * @param {string} [dateUtc] Optional date utc field, if provided list will be displayed for that date
         * @param {string} [date] Optional date field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1AggregationsControllerFindAll(u1RoleId: string, c1DepartmentId: string, xTenantId?: string, type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', dateUtc?: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U1Aggregation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1AggregationsControllerFindAll(u1RoleId, c1DepartmentId, xTenantId, type, dateUtc, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of u1 aggregations for zapbook v2
         * @param {string} u1RoleId 
         * @param {string} c1DepartmentId 
         * @param {number} dayId Day id for which u1 aggregation data will be shown
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} type Date type field for which u1 aggregation data will be shown
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1AggregationsControllerFindAllU1Aggregation(u1RoleId: string, c1DepartmentId: string, dayId: number, type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U1Aggregation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1AggregationsControllerFindAllU1Aggregation(u1RoleId, c1DepartmentId, dayId, type, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find latest u1 aggregation for zapbook v2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1AggregationsControllerFindLatestU1Aggregation(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseU1Aggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1AggregationsControllerFindLatestU1Aggregation(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a particular U1Aggregation against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1AggregationsControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1Aggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1AggregationsControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get U1-Aggregation by date
         * @param {string} [xTenantId] 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} [type] Optional date type field, if provided list will be displayed for that date
         * @param {string} [dateUtc] Optional date utc field, if provided list will be displayed for that date
         * @param {string} [date] Optional date field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1AggregationsControllerFindOneByDateInfo(xTenantId?: string, type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', dateUtc?: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1Aggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1AggregationsControllerFindOneByDateInfo(xTenantId, type, dateUtc, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U1AggregationApi - factory interface
 * @export
 */
export const U1AggregationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U1AggregationApiFp(configuration)
    return {
        /**
         * 
         * @summary List of U1Aggregations
         * @param {string} u1RoleId 
         * @param {string} c1DepartmentId 
         * @param {string} [xTenantId] 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} [type] Optional date type field, if provided list will be displayed for that date
         * @param {string} [dateUtc] Optional date utc field, if provided list will be displayed for that date
         * @param {string} [date] Optional date field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindAll(u1RoleId: string, c1DepartmentId: string, xTenantId?: string, type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', dateUtc?: string, date?: string, options?: any): AxiosPromise<Array<U1Aggregation>> {
            return localVarFp.u1AggregationsControllerFindAll(u1RoleId, c1DepartmentId, xTenantId, type, dateUtc, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of u1 aggregations for zapbook v2
         * @param {string} u1RoleId 
         * @param {string} c1DepartmentId 
         * @param {number} dayId Day id for which u1 aggregation data will be shown
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} type Date type field for which u1 aggregation data will be shown
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindAllU1Aggregation(u1RoleId: string, c1DepartmentId: string, dayId: number, type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<U1Aggregation>> {
            return localVarFp.u1AggregationsControllerFindAllU1Aggregation(u1RoleId, c1DepartmentId, dayId, type, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find latest u1 aggregation for zapbook v2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindLatestU1Aggregation(xTenantId?: string, options?: any): AxiosPromise<RoleBaseU1Aggregation> {
            return localVarFp.u1AggregationsControllerFindLatestU1Aggregation(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a particular U1Aggregation against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<U1Aggregation> {
            return localVarFp.u1AggregationsControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get U1-Aggregation by date
         * @param {string} [xTenantId] 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} [type] Optional date type field, if provided list will be displayed for that date
         * @param {string} [dateUtc] Optional date utc field, if provided list will be displayed for that date
         * @param {string} [date] Optional date field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1AggregationsControllerFindOneByDateInfo(xTenantId?: string, type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', dateUtc?: string, date?: string, options?: any): AxiosPromise<U1Aggregation> {
            return localVarFp.u1AggregationsControllerFindOneByDateInfo(xTenantId, type, dateUtc, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u1AggregationsControllerFindAll operation in U1AggregationApi.
 * @export
 * @interface U1AggregationApiU1AggregationsControllerFindAllRequest
 */
export interface U1AggregationApiU1AggregationsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAll
     */
    readonly u1RoleId: string

    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAll
     */
    readonly c1DepartmentId: string

    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional date type field, if provided list will be displayed for that date
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U1AggregationApiU1AggregationsControllerFindAll
     */
    readonly type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * Optional date utc field, if provided list will be displayed for that date
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAll
     */
    readonly dateUtc?: string

    /**
     * Optional date field, if provided list will be displayed for that date
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAll
     */
    readonly date?: string
}

/**
 * Request parameters for u1AggregationsControllerFindAllU1Aggregation operation in U1AggregationApi.
 * @export
 * @interface U1AggregationApiU1AggregationsControllerFindAllU1AggregationRequest
 */
export interface U1AggregationApiU1AggregationsControllerFindAllU1AggregationRequest {
    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAllU1Aggregation
     */
    readonly u1RoleId: string

    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAllU1Aggregation
     */
    readonly c1DepartmentId: string

    /**
     * Day id for which u1 aggregation data will be shown
     * @type {number}
     * @memberof U1AggregationApiU1AggregationsControllerFindAllU1Aggregation
     */
    readonly dayId: number

    /**
     * Date type field for which u1 aggregation data will be shown
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U1AggregationApiU1AggregationsControllerFindAllU1Aggregation
     */
    readonly type: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindAllU1Aggregation
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1AggregationsControllerFindLatestU1Aggregation operation in U1AggregationApi.
 * @export
 * @interface U1AggregationApiU1AggregationsControllerFindLatestU1AggregationRequest
 */
export interface U1AggregationApiU1AggregationsControllerFindLatestU1AggregationRequest {
    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindLatestU1Aggregation
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1AggregationsControllerFindOne operation in U1AggregationApi.
 * @export
 * @interface U1AggregationApiU1AggregationsControllerFindOneRequest
 */
export interface U1AggregationApiU1AggregationsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1AggregationsControllerFindOneByDateInfo operation in U1AggregationApi.
 * @export
 * @interface U1AggregationApiU1AggregationsControllerFindOneByDateInfoRequest
 */
export interface U1AggregationApiU1AggregationsControllerFindOneByDateInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindOneByDateInfo
     */
    readonly xTenantId?: string

    /**
     * Optional date type field, if provided list will be displayed for that date
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U1AggregationApiU1AggregationsControllerFindOneByDateInfo
     */
    readonly type?: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * Optional date utc field, if provided list will be displayed for that date
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindOneByDateInfo
     */
    readonly dateUtc?: string

    /**
     * Optional date field, if provided list will be displayed for that date
     * @type {string}
     * @memberof U1AggregationApiU1AggregationsControllerFindOneByDateInfo
     */
    readonly date?: string
}

/**
 * U1AggregationApi - object-oriented interface
 * @export
 * @class U1AggregationApi
 * @extends {BaseAPI}
 */
export class U1AggregationApi extends BaseAPI {
    /**
     * 
     * @summary List of U1Aggregations
     * @param {U1AggregationApiU1AggregationsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1AggregationApi
     */
    public u1AggregationsControllerFindAll(requestParameters: U1AggregationApiU1AggregationsControllerFindAllRequest, options?: AxiosRequestConfig) {
        return U1AggregationApiFp(this.configuration).u1AggregationsControllerFindAll(requestParameters.u1RoleId, requestParameters.c1DepartmentId, requestParameters.xTenantId, requestParameters.type, requestParameters.dateUtc, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of u1 aggregations for zapbook v2
     * @param {U1AggregationApiU1AggregationsControllerFindAllU1AggregationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1AggregationApi
     */
    public u1AggregationsControllerFindAllU1Aggregation(requestParameters: U1AggregationApiU1AggregationsControllerFindAllU1AggregationRequest, options?: AxiosRequestConfig) {
        return U1AggregationApiFp(this.configuration).u1AggregationsControllerFindAllU1Aggregation(requestParameters.u1RoleId, requestParameters.c1DepartmentId, requestParameters.dayId, requestParameters.type, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find latest u1 aggregation for zapbook v2
     * @param {U1AggregationApiU1AggregationsControllerFindLatestU1AggregationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1AggregationApi
     */
    public u1AggregationsControllerFindLatestU1Aggregation(requestParameters: U1AggregationApiU1AggregationsControllerFindLatestU1AggregationRequest = {}, options?: AxiosRequestConfig) {
        return U1AggregationApiFp(this.configuration).u1AggregationsControllerFindLatestU1Aggregation(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a particular U1Aggregation against ID
     * @param {U1AggregationApiU1AggregationsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1AggregationApi
     */
    public u1AggregationsControllerFindOne(requestParameters: U1AggregationApiU1AggregationsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return U1AggregationApiFp(this.configuration).u1AggregationsControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get U1-Aggregation by date
     * @param {U1AggregationApiU1AggregationsControllerFindOneByDateInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1AggregationApi
     */
    public u1AggregationsControllerFindOneByDateInfo(requestParameters: U1AggregationApiU1AggregationsControllerFindOneByDateInfoRequest = {}, options?: AxiosRequestConfig) {
        return U1AggregationApiFp(this.configuration).u1AggregationsControllerFindOneByDateInfo(requestParameters.xTenantId, requestParameters.type, requestParameters.dateUtc, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}
