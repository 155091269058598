<script setup lang="ts">
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { ICustomersTableCaret } from '@/types/customerslist.type';
import { storeToRefs } from 'pinia';
import { QTdProps } from 'quasar';
import { computed } from 'vue';
import KpiNumberCaret from '../CustomerDetails/CustomerDetailsOverview/KpiNumberCaret.vue';
import { useKpiStore } from '@/stores/kpi';
// import TdBarGraph from '../Customers/TdBarGraph.vue';
import { DashboardSelectionTypeEnum, KpiNameEnum, SourceEnum } from '@/api/client';
import { phInfoBold } from '@/phosphor-icons';
import KpiDefinition from '../KpiDefinition.vue';
import TableCellGraph from '../TableCellGraph.vue';
import { IQueryParam } from '@/types/query.type';
import { RouteName } from '@/router/route-name';
import { useRouter } from 'vue-router';
import { useAccountStore } from '@/stores/account';
import { CustomerCustomViewSourceEnum } from '@zapscale/types';

const props = defineProps<{ tableProps: QTdProps['props'] }>();

const { dashboardInfo, dashboardFor } = storeToRefs(useDashboardV2Store());
const { activeKpiList } = storeToRefs(useKpiStore());
const { customerCustomViews } = storeToRefs(useAccountStore());

const router = useRouter();

const kpiId = computed(() => {
  return activeKpiList.value.find((x) => x.name === props.tableProps.row.name)?.id;
});

const kpiScore = computed(() => {
  const info = dashboardInfo.value;
  if (info == null) {
    return null;
  }
  type HealthScoreObjectKey = keyof typeof info;

  const scoreKey = `${props.tableProps.row.name}_score` as HealthScoreObjectKey;
  const scorePercentage = `${props.tableProps.row.name}_percentage_change_from_last` as HealthScoreObjectKey;
  const scoreColorCode = `${props.tableProps.row.name}_color_code` as HealthScoreObjectKey;

  return {
    value: info[scoreKey],
    percentage: info[scorePercentage],
    isLarge: false,
    colorCode: info[scoreColorCode],
    isNavigational: false,
  } as ICustomersTableCaret;
});

const kpiHealthInfo = computed(() => {
  const info = dashboardInfo.value;
  if (info == null) {
    return null;
  }
  type HealthScoreObjectKey = keyof typeof info;
  const percentInGrey = `${props.tableProps.row.name}_percent_of_c2s_in_grey` as HealthScoreObjectKey;
  const noInGrey = `${props.tableProps.row.name}_no_of_c2s_in_grey` as HealthScoreObjectKey;
  const percentInRed = `${props.tableProps.row.name}_percent_of_c2s_in_red` as HealthScoreObjectKey;
  const noInRed = `${props.tableProps.row.name}_no_of_c2s_in_red` as HealthScoreObjectKey;
  const percentInYellow = `${props.tableProps.row.name}_percent_of_c2s_in_yellow` as HealthScoreObjectKey;
  const noInYellow = `${props.tableProps.row.name}_no_of_c2s_in_yellow` as HealthScoreObjectKey;
  const percentInGreen = `${props.tableProps.row.name}_percent_of_c2s_in_green` as HealthScoreObjectKey;
  const noInGreen = `${props.tableProps.row.name}_no_of_c2s_in_green` as HealthScoreObjectKey;

  return {
    kpiId,
    percentInGrey: info[percentInGrey] as number,
    noInGrey: info[noInGrey] as number,
    percentInRed: info[percentInRed] as number,
    noInRed: info[noInRed] as number,
    percentInYellow: info[percentInYellow] as number,
    noInYellow: info[noInYellow] as number,
    percentInGreen: info[percentInGreen] as number,
    noInGreen: info[noInGreen] as number,
    percentInBlack: 0,
    noInBlack: 0,
  };
});

function navigateToCustomerListScreen(queryParam: IQueryParam) {
  const selectedId = dashboardFor.value?.id || undefined;
  let customerView = '';
  switch (props.tableProps.row.name) {
    case KpiNameEnum.Uss:
      customerView =
        customerCustomViews.value?.find(
          (x) =>
            x.name === 'Product Usage Health' && x.source?.toString() === CustomerCustomViewSourceEnum.ZS?.toString(),
        )?.id || '';
      break;
    case KpiNameEnum.Bts:
      customerView =
        customerCustomViews.value?.find(
          (x) => x.name === 'Ticketing Health' && x.source?.toString() === CustomerCustomViewSourceEnum.ZS?.toString(),
        )?.id || '';
      break;
    case KpiNameEnum.Frs:
      customerView =
        customerCustomViews.value?.find(
          (x) =>
            x.name === 'Feature Request Health' && x.source?.toString() === CustomerCustomViewSourceEnum.ZS?.toString(),
        )?.id || '';
      break;
    case KpiNameEnum.Pas:
      customerView =
        customerCustomViews.value?.find(
          (x) =>
            x.name === 'Billing and Invoice Health' &&
            x.source?.toString() === CustomerCustomViewSourceEnum.ZS?.toString(),
        )?.id || '';
      break;
    case KpiNameEnum.Ccs:
      customerView =
        customerCustomViews.value?.find(
          (x) =>
            x.name === 'Communication Health' && x.source?.toString() === CustomerCustomViewSourceEnum.ZS?.toString(),
        )?.id || '';
      break;
    default:
      customerView = '';
  }
  const param = {
    ...queryParam,
    custom_view: customerView,
    c2_type: dashboardFor.value?.type === DashboardSelectionTypeEnum.C2typehealth ? selectedId : undefined,
    c2_segment: dashboardFor.value?.type === DashboardSelectionTypeEnum.C2segmenthealth ? selectedId : undefined,
  };

  const routeName = RouteName.Customers;
  router.push({
    name: routeName,
    query: { ...param },
  });
}
</script>

<template>
  <q-tr :props="props.tableProps">
    <q-td key="kpiName" :props="props.tableProps" class="tw-text-sm tw-font-semibold tw-leading-5 tw-text-dark-shade-2">
      <div class="tw-flex">
        {{ props.tableProps.row.display_name }}
        <div class="tw-cursor-pointer">
          <q-icon :name="phInfoBold" class="tw-ml-2.5 tw-text-primary"> </q-icon>
          <q-menu ref="kpi-range-menu" transition-show="fade" transition-hide="fade" transition-duration="300">
            <KpiDefinition :kpi-id="kpiId" :kpi-for="'business_kpi'" />
          </q-menu>
        </div>
      </div>
    </q-td>

    <q-td key="customer_health" :props="props.tableProps">
      <div v-if="kpiHealthInfo">
        <TableCellGraph
          :kpi-id="kpiId"
          :source="SourceEnum.C1HealthScore"
          :date="dashboardInfo?.date || ''"
          :green="kpiHealthInfo.percentInGreen || 0"
          :red="kpiHealthInfo.percentInRed || 0"
          :yellow="kpiHealthInfo.percentInYellow || 0"
          :grey="kpiHealthInfo.percentInGrey || 0"
          :green-count="kpiHealthInfo.noInGreen || 0"
          :grey-count="kpiHealthInfo.noInGrey || 0"
          :red-count="kpiHealthInfo.noInRed || 0"
          :yellow-count="kpiHealthInfo.noInYellow || 0"
          :black-count="0"
          :black="0"
          :is-clickable="true"
          @on-bar-item-click="navigateToCustomerListScreen"
        />
      </div>
    </q-td>

    <q-td key="kpiScore" :props="props.tableProps">
      <div v-if="kpiScore">
        <KpiNumberCaret :data="kpiScore" />
      </div>
      <div v-else>--</div>
    </q-td>
  </q-tr>
</template>
