/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CSHandfeelLog } from '../model/';
// @ts-ignore
import { CreateCsHandfeelLogDto } from '../model/';
// @ts-ignore
import { PaginatedCSHandFeelLogsDto } from '../model/';
/**
 * CSHandfeelLogsApi - axios parameter creator
 * @export
 */
export const CSHandfeelLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create CS Handfeel Log
         * @param {CreateCsHandfeelLogDto} createCsHandfeelLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csHandfeelLogsControllerCreate: async (createCsHandfeelLogDto: CreateCsHandfeelLogDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCsHandfeelLogDto' is not null or undefined
            assertParamExists('csHandfeelLogsControllerCreate', 'createCsHandfeelLogDto', createCsHandfeelLogDto)
            const localVarPath = `/api/cs-handfeel-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCsHandfeelLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find historical gutfeel data by c2 id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id', 'id', id)
            const localVarPath = `/api/cs-handfeel-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Latest Handfeel for C2
         * @param {string} c2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csHandfeelLogsControllerFindLatestHandfeelForC2: async (c2Id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2Id' is not null or undefined
            assertParamExists('csHandfeelLogsControllerFindLatestHandfeelForC2', 'c2Id', c2Id)
            const localVarPath = `/api/cs-handfeel-logs/find-latest-for-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2Id !== undefined) {
                localVarQueryParameter['c2_id'] = c2Id;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CSHandfeelLogsApi - functional programming interface
 * @export
 */
export const CSHandfeelLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CSHandfeelLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create CS Handfeel Log
         * @param {CreateCsHandfeelLogDto} createCsHandfeelLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csHandfeelLogsControllerCreate(createCsHandfeelLogDto: CreateCsHandfeelLogDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CSHandfeelLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csHandfeelLogsControllerCreate(createCsHandfeelLogDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find historical gutfeel data by c2 id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCSHandFeelLogsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id(id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Latest Handfeel for C2
         * @param {string} c2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csHandfeelLogsControllerFindLatestHandfeelForC2(c2Id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CSHandfeelLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csHandfeelLogsControllerFindLatestHandfeelForC2(c2Id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CSHandfeelLogsApi - factory interface
 * @export
 */
export const CSHandfeelLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CSHandfeelLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create CS Handfeel Log
         * @param {CreateCsHandfeelLogDto} createCsHandfeelLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csHandfeelLogsControllerCreate(createCsHandfeelLogDto: CreateCsHandfeelLogDto, xTenantId?: string, options?: any): AxiosPromise<CSHandfeelLog> {
            return localVarFp.csHandfeelLogsControllerCreate(createCsHandfeelLogDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find historical gutfeel data by c2 id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedCSHandFeelLogsDto> {
            return localVarFp.csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id(id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Latest Handfeel for C2
         * @param {string} c2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csHandfeelLogsControllerFindLatestHandfeelForC2(c2Id: string, xTenantId?: string, options?: any): AxiosPromise<CSHandfeelLog> {
            return localVarFp.csHandfeelLogsControllerFindLatestHandfeelForC2(c2Id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for csHandfeelLogsControllerCreate operation in CSHandfeelLogsApi.
 * @export
 * @interface CSHandfeelLogsApiCsHandfeelLogsControllerCreateRequest
 */
export interface CSHandfeelLogsApiCsHandfeelLogsControllerCreateRequest {
    /**
     * 
     * @type {CreateCsHandfeelLogDto}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerCreate
     */
    readonly createCsHandfeelLogDto: CreateCsHandfeelLogDto

    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id operation in CSHandfeelLogsApi.
 * @export
 * @interface CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2IdRequest
 */
export interface CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2IdRequest {
    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id
     */
    readonly after?: string
}

/**
 * Request parameters for csHandfeelLogsControllerFindLatestHandfeelForC2 operation in CSHandfeelLogsApi.
 * @export
 * @interface CSHandfeelLogsApiCsHandfeelLogsControllerFindLatestHandfeelForC2Request
 */
export interface CSHandfeelLogsApiCsHandfeelLogsControllerFindLatestHandfeelForC2Request {
    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindLatestHandfeelForC2
     */
    readonly c2Id: string

    /**
     * 
     * @type {string}
     * @memberof CSHandfeelLogsApiCsHandfeelLogsControllerFindLatestHandfeelForC2
     */
    readonly xTenantId?: string
}

/**
 * CSHandfeelLogsApi - object-oriented interface
 * @export
 * @class CSHandfeelLogsApi
 * @extends {BaseAPI}
 */
export class CSHandfeelLogsApi extends BaseAPI {
    /**
     * 
     * @summary Create CS Handfeel Log
     * @param {CSHandfeelLogsApiCsHandfeelLogsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSHandfeelLogsApi
     */
    public csHandfeelLogsControllerCreate(requestParameters: CSHandfeelLogsApiCsHandfeelLogsControllerCreateRequest, options?: AxiosRequestConfig) {
        return CSHandfeelLogsApiFp(this.configuration).csHandfeelLogsControllerCreate(requestParameters.createCsHandfeelLogDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find historical gutfeel data by c2 id
     * @param {CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2IdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSHandfeelLogsApi
     */
    public csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id(requestParameters: CSHandfeelLogsApiCsHandfeelLogsControllerFindCsHandFeelLogEntriesByC2IdRequest, options?: AxiosRequestConfig) {
        return CSHandfeelLogsApiFp(this.configuration).csHandfeelLogsControllerFindCsHandFeelLogEntriesByC2Id(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Latest Handfeel for C2
     * @param {CSHandfeelLogsApiCsHandfeelLogsControllerFindLatestHandfeelForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSHandfeelLogsApi
     */
    public csHandfeelLogsControllerFindLatestHandfeelForC2(requestParameters: CSHandfeelLogsApiCsHandfeelLogsControllerFindLatestHandfeelForC2Request, options?: AxiosRequestConfig) {
        return CSHandfeelLogsApiFp(this.configuration).csHandfeelLogsControllerFindLatestHandfeelForC2(requestParameters.c2Id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
