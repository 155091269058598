/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C1HealthScore } from '../model/';
// @ts-ignore
import { DateInfo } from '../model/';
// @ts-ignore
import { RoleBaseDashboardOverviewDto } from '../model/';
/**
 * C1HealthScoreApi - axios parameter creator
 * @export
 */
export const C1HealthScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of C1 Health Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c1-health-scores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C1-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindAllByDateRange: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c1HealthScoresControllerFindAllByDateRange', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c1HealthScoresControllerFindAllByDateRange', 'dateType', dateType)
            const localVarPath = `/api/c1-health-scores/date-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C1 Health Score by DateInfo
         * @param {DateInfo} dateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindByDateInfo: async (dateInfo: DateInfo, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateInfo' is not null or undefined
            assertParamExists('c1HealthScoresControllerFindByDateInfo', 'dateInfo', dateInfo)
            const localVarPath = `/api/c1-health-scores/date-filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C1 Health Score against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1HealthScoresControllerFindOne', 'id', id)
            const localVarPath = `/api/c1-health-scores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C1 Health score by 
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindOneByDateInfo: async (date: string, dateType: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c1HealthScoresControllerFindOneByDateInfo', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c1HealthScoresControllerFindOneByDateInfo', 'dateType', dateType)
            const localVarPath = `/api/c1-health-scores/date-filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C1HealthScoreApi - functional programming interface
 * @export
 */
export const C1HealthScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C1HealthScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of C1 Health Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1HealthScoresControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C1HealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1HealthScoresControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C1-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1HealthScoresControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C1HealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1HealthScoresControllerFindAllByDateRange(date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C1 Health Score by DateInfo
         * @param {DateInfo} dateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1HealthScoresControllerFindByDateInfo(dateInfo: DateInfo, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1HealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1HealthScoresControllerFindByDateInfo(dateInfo, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C1 Health Score against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1HealthScoresControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1HealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1HealthScoresControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C1 Health score by 
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1HealthScoresControllerFindOneByDateInfo(date: string, dateType: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseDashboardOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1HealthScoresControllerFindOneByDateInfo(date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C1HealthScoreApi - factory interface
 * @export
 */
export const C1HealthScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C1HealthScoreApiFp(configuration)
    return {
        /**
         * 
         * @summary List of C1 Health Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<C1HealthScore>> {
            return localVarFp.c1HealthScoresControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C1-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<C1HealthScore>> {
            return localVarFp.c1HealthScoresControllerFindAllByDateRange(date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C1 Health Score by DateInfo
         * @param {DateInfo} dateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindByDateInfo(dateInfo: DateInfo, xTenantId?: string, options?: any): AxiosPromise<C1HealthScore> {
            return localVarFp.c1HealthScoresControllerFindByDateInfo(dateInfo, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C1 Health Score against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<C1HealthScore> {
            return localVarFp.c1HealthScoresControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C1 Health score by 
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1HealthScoresControllerFindOneByDateInfo(date: string, dateType: string, xTenantId?: string, options?: any): AxiosPromise<RoleBaseDashboardOverviewDto> {
            return localVarFp.c1HealthScoresControllerFindOneByDateInfo(date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c1HealthScoresControllerFindAll operation in C1HealthScoreApi.
 * @export
 * @interface C1HealthScoreApiC1HealthScoresControllerFindAllRequest
 */
export interface C1HealthScoreApiC1HealthScoresControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1HealthScoresControllerFindAllByDateRange operation in C1HealthScoreApi.
 * @export
 * @interface C1HealthScoreApiC1HealthScoresControllerFindAllByDateRangeRequest
 */
export interface C1HealthScoreApiC1HealthScoresControllerFindAllByDateRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindAllByDateRange
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindAllByDateRange
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindAllByDateRange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1HealthScoresControllerFindByDateInfo operation in C1HealthScoreApi.
 * @export
 * @interface C1HealthScoreApiC1HealthScoresControllerFindByDateInfoRequest
 */
export interface C1HealthScoreApiC1HealthScoresControllerFindByDateInfoRequest {
    /**
     * 
     * @type {DateInfo}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindByDateInfo
     */
    readonly dateInfo: DateInfo

    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindByDateInfo
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1HealthScoresControllerFindOne operation in C1HealthScoreApi.
 * @export
 * @interface C1HealthScoreApiC1HealthScoresControllerFindOneRequest
 */
export interface C1HealthScoreApiC1HealthScoresControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1HealthScoresControllerFindOneByDateInfo operation in C1HealthScoreApi.
 * @export
 * @interface C1HealthScoreApiC1HealthScoresControllerFindOneByDateInfoRequest
 */
export interface C1HealthScoreApiC1HealthScoresControllerFindOneByDateInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindOneByDateInfo
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindOneByDateInfo
     */
    readonly dateType: string

    /**
     * 
     * @type {string}
     * @memberof C1HealthScoreApiC1HealthScoresControllerFindOneByDateInfo
     */
    readonly xTenantId?: string
}

/**
 * C1HealthScoreApi - object-oriented interface
 * @export
 * @class C1HealthScoreApi
 * @extends {BaseAPI}
 */
export class C1HealthScoreApi extends BaseAPI {
    /**
     * 
     * @summary List of C1 Health Score
     * @param {C1HealthScoreApiC1HealthScoresControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1HealthScoreApi
     */
    public c1HealthScoresControllerFindAll(requestParameters: C1HealthScoreApiC1HealthScoresControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C1HealthScoreApiFp(this.configuration).c1HealthScoresControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C1-Health-Score by date range
     * @param {C1HealthScoreApiC1HealthScoresControllerFindAllByDateRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1HealthScoreApi
     */
    public c1HealthScoresControllerFindAllByDateRange(requestParameters: C1HealthScoreApiC1HealthScoresControllerFindAllByDateRangeRequest, options?: AxiosRequestConfig) {
        return C1HealthScoreApiFp(this.configuration).c1HealthScoresControllerFindAllByDateRange(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C1 Health Score by DateInfo
     * @param {C1HealthScoreApiC1HealthScoresControllerFindByDateInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1HealthScoreApi
     */
    public c1HealthScoresControllerFindByDateInfo(requestParameters: C1HealthScoreApiC1HealthScoresControllerFindByDateInfoRequest, options?: AxiosRequestConfig) {
        return C1HealthScoreApiFp(this.configuration).c1HealthScoresControllerFindByDateInfo(requestParameters.dateInfo, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C1 Health Score against ID
     * @param {C1HealthScoreApiC1HealthScoresControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1HealthScoreApi
     */
    public c1HealthScoresControllerFindOne(requestParameters: C1HealthScoreApiC1HealthScoresControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C1HealthScoreApiFp(this.configuration).c1HealthScoresControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C1 Health score by 
     * @param {C1HealthScoreApiC1HealthScoresControllerFindOneByDateInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1HealthScoreApi
     */
    public c1HealthScoresControllerFindOneByDateInfo(requestParameters: C1HealthScoreApiC1HealthScoresControllerFindOneByDateInfoRequest, options?: AxiosRequestConfig) {
        return C1HealthScoreApiFp(this.configuration).c1HealthScoresControllerFindOneByDateInfo(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
