import { onboardingStepsApi } from '@/api';
import { OnboardingStep } from '@/api/client';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export enum OnboardingScreen {
  Profile = 'Profile',
  BusinessInfo = 'BusinessInfo',
  SaasOverview = 'SaasOverview',
  SaasSitemap = 'SaasSitemap',
  SaasTrackerCode = 'SaasTrackerCode',
}

export const useOnboardingStore = defineStore('onboarding', () => {
  const activeScreen = ref<OnboardingScreen>();

  const setActiveScreen = (value: OnboardingScreen) => {
    activeScreen.value = value;
  };

  const onboardingSteps = ref<OnboardingStep>({} as OnboardingStep);

  const isOnboarding = ref(false);

  const setIsOnboarding = (value: boolean) => {
    isOnboarding.value = value;
  };

  const fetchOnboardingSteps = async () => {
    const { data } = await onboardingStepsApi.onboardingStepsControllerFindOne();
    onboardingSteps.value = data;
  };

  const hideHelpRequest = ref(false);
  const onNext = ref<() => void>();
  const onPrevious = ref<() => void>();
  const isUpdating = ref(false);

  return {
    activeScreen,
    onboardingSteps,
    isOnboarding,
    setActiveScreen,
    setIsOnboarding,
    fetchOnboardingSteps,
    hideHelpRequest,
    onNext,
    onPrevious,
    isUpdating,
  };
});
