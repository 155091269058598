<script setup lang="ts">
import { useRouter } from 'vue-router';
import { RouteName } from '@/router/route-name';
import { onMounted, computed, ref, watch } from 'vue';
import { select, scaleBand, scaleLinear, scaleOrdinal, stack } from 'd3';
import { phCaretDown } from '@/phosphor-icons';
import { useC1HealthScoreStore } from '@/stores/c1HealthScore';
import { useCustomerUserHealthDataSet } from '@/composables/useCustomerUserHealthDataSet';
import { ICustomerUserHealthData } from '@/types/dashboard';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { useDateStore } from '@/stores/date';
import { storeToRefs } from 'pinia';
import { IQueryParam } from '@/types/query.type';
import { HealthColorEnum, KpiNameEnum } from '@/api/client';
import { useU2TypeStore } from '@/stores/u2Type';

import { useAccountStore } from '@/stores/account';
import LoadingIndicator from '../LoadingIndicator.vue';
//import { mdiConsoleNetwork } from '@quasar/extras/mdi-v6';

interface ICustomerHealthInfo {
  goodC2Ids: string[];
  fairC2Ids: string[];
  badC2Ids: string[];
  inactiveC2Ids: string[];
  churnC2Ids: string[];
}

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const router = useRouter();
const c1HealthScoreStore = useC1HealthScoreStore();
const customerUserHealthDataSet = useCustomerUserHealthDataSet();

const { formatNumberRoundOfDecimal } = useAmountFormatter();

const _dateStore = useDateStore();
const { dateInfo } = storeToRefs(_dateStore);

const _accountStore = useAccountStore();
const { activeU1Id } = storeToRefs(_accountStore);

const _u2TypeStore = useU2TypeStore();
const { u2Types } = storeToRefs(_u2TypeStore);

const _c1HealthScoreStore = useC1HealthScoreStore();
const { c2ZapscoreColorAggregationList, c2ZapscoreColorAggregationListLoading, isLoading } =
  storeToRefs(_c1HealthScoreStore);

const chart = ref<HTMLDivElement>();
const tooltip = ref<HTMLDivElement>();
const gapBetweenBars = 2;
const gapBetweenCharts = 8;
const textPadding = 5;
const labelOffset = 10;
// const marginPercentage = 5;
const legendDotSizeParameters = 5;
const tooltipOffsetAdjustX = 100;
const tooltipOffsetAdjustY = 25;
const lowerCutOffPercentageShow = 10;

const filterOptions = computed(() => {
  return customerUserHealthDataSet.filterOptions;
});
const selectedFilter = ref(filterOptions.value[0]);

function onItemClick(option: { label: string; value: string }) {
  selectedFilter.value = option;
  populateChart();
}

const customerHealthInfo = computed(() => {
  const goodC2Ids = c2ZapscoreColorAggregationList.value.find((x) => x.color_code === 'green')?.c2_app_ids || [];
  const badC2Ids = c2ZapscoreColorAggregationList.value.find((x) => x.color_code === 'red')?.c2_app_ids || [];
  const fairC2Ids = c2ZapscoreColorAggregationList.value.find((x) => x.color_code === 'yellow')?.c2_app_ids || [];
  const inactiveC2Ids = c2ZapscoreColorAggregationList.value.find((x) => x.color_code === 'grey')?.c2_app_ids || [];
  const churnC2Ids = c2ZapscoreColorAggregationList.value.find((x) => x.color_code === 'black')?.c2_app_ids || [];

  const healthInfo = {
    goodC2Ids,
    badC2Ids,
    churnC2Ids,
    fairC2Ids,
    inactiveC2Ids,
  } as ICustomerHealthInfo;
  return healthInfo;
});

watch(
  () => c1HealthScoreStore.c1HealthScore,
  (newValue) => {
    if (chart.value && newValue?.id) {
      populateChart();
    }
  },
);

function populateChart() {
  const c1HealthScore = c1HealthScoreStore.c1HealthScore;
  if (chart.value && c1HealthScore?.id) {
    const dataSet = customerUserHealthDataSet.prepareChart(c1HealthScore, selectedFilter.value.value);
    chart.value.innerHTML = '';
    renderChart(chart.value, dataSet);
  }
}

const svgWidth = ref(100);
const svgHeight = ref(100);

onMounted(() => {
  svgWidth.value = chart.value?.clientWidth || 200;
  svgHeight.value = chart.value?.clientHeight || 200;
  populateChart();
});

const vertBarWidth = computed(() => {
  const width = svgWidth.value * 0.12;
  return width;
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tooltipHTML(horiBarUserCount: Record<string, any>) {
  return `
    <div class="tooltipHeader">
        ${horiBarUserCount['group']} (${horiBarUserCount['vertBarLabel']['percentage']}%, ${
    horiBarUserCount['vertBarLabel']['value']
  })
    </div>
    <div class="rTable">
        <div class="rTableRow">
            <div class="rTableCell groupDot">
            <div class="dot good"></div>
        </div>
        <div class="rTableCell groupName">
            <div class="">Good (${String(horiBarUserCount['percentage'][0])}%)</div>
        </div>
        <div class="rTableCell groupNumber">
            <div class="">${horiBarUserCount['good']}</div>
        </div>
    </div>
    <div class="rTableRow">
        <div class="rTableCell groupDot">
            <div class="dot fair"></div>
        </div>
        <div class="rTableCell groupName">
            <div class="">Fair (${String(horiBarUserCount['percentage'][1])}%)</div>
        </div>
        <div class="rTableCell groupNumber">
            <div class="">${horiBarUserCount['fair']}</div>
        </div>
    </div>
    <div class="rTableRow">
        <div class="rTableCell groupDot">
            <div class="dot bad"></div>
        </div>
        <div class="rTableCell groupName">
            <div class="">Bad (${String(horiBarUserCount['percentage'][2])}%)</div>
        </div>
        <div class="rTableCell groupNumber">
            <div class="">${horiBarUserCount['bad']}</div>
        </div>
    </div>
    <div class="rTableRow">
        <div class="rTableCell groupDot">
            <div class="dot inactive"></div>
        </div>
        <div class="rTableCell groupName">
            <div class="">Inactive (${String(horiBarUserCount['percentage'][3])}%)</div>
        </div>
        <div class="rTableCell groupNumber">
            <div class="">${horiBarUserCount['inactive']}</div>
        </div>
    </div>
    </div>
    <div class="tooltipAnchorContainer">
    </div>
    `;
}

function renderChart(chart: HTMLDivElement, customerUserHealth: ICustomerUserHealthData[]) {
  if (!customerUserHealth || customerUserHealth?.length <= 0) {
    return;
  }
  const width = svgWidth.value;
  const height = svgHeight.value;

  const svg = select(chart)
    .append('div')
    .classed('svg-container', true)
    .append('svg')
    .attr('preserveAspectRatio', 'xMinYMin meet')
    // .attr('viewBox', '0 0 600 400')
    // Class to make it responsive.
    .classed('svg-content-responsive', true)

    .attr('width', width)
    .attr('height', height);
  // .attr('transform', `translate(${margin.left},${margin.top})`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customerUserHealthData: Array<Record<string, any>> = [{ group: 'customers' }];
  // const customerUserHealthData: Array<Record<string, number>> = [{ group: -9999 }];
  customerUserHealth.map((customer) => {
    customerUserHealthData[0][customer.group] = +formatNumberRoundOfDecimal(customer.percentage);
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var vertBarLabels: Array<Record<string, any>> = [];
  let vertBarPercentageTotal = 0;
  const customerSubgroups = customerUserHealth.map((customer) => {
    vertBarPercentageTotal += customer.percentage;
    // if (customer.percentage < lowerCutOffPercentageShow) {
    //   vertBarLabels.push(''); // Don't show if the box height is too low.
    // } else {
    vertBarLabels.push({ percentage: formatNumberRoundOfDecimal(customer.percentage), value: customer.value });
    // vertBarLabels.push({ percentage: 111, value: 123 });
    // }

    return customer.group;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groups: any[] = ['customers'];
  // const groups: any[] = [-9999];

  var vertBarComponentHeights: number[] = [];

  // Add X axis
  const x = scaleBand().domain(groups).padding(0.2);

  // Add Y axis
  const y = scaleLinear()
    .domain([0, 100])
    .range([height - 20, 0]);

  // color palette = one color per subgroup
  const colors: string[] = ['#4AC18D', '#FEBB2A', '#FF625E', '#D0D0D0', '#444444'];
  const colorStates: Record<string, string> = {
    '#4AC18D': 'good',
    '#FEBB2A': 'fair',
    '#FF625E': 'bad',
    '#D0D0D0': 'inactive',
    '#444444': 'churn',
  };
  const color = scaleOrdinal().domain(customerSubgroups).range(colors);

  const dataSeriesFun = stack().keys(customerSubgroups);
  const dataSeries = dataSeriesFun(customerUserHealthData);

  // Show the bars
  var sameColoredVertGroups = svg
    .append('g')
    .selectAll('.vertBarGroup')
    // Enter in the stack data = loop key per key = group per group
    .data(dataSeries)
    .enter()
    .append('g');

  sameColoredVertGroups.attr('class', 'vertBarGroup');

  sameColoredVertGroups
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr('fill', function (d): any {
      return color(d.key);
    })
    .on('click', function (event, d) {
      let health: HealthColorEnum | null = null;
      switch (d.key.toLowerCase()) {
        case 'good':
          health = HealthColorEnum.Green;
          break;
        case 'fair':
          health = HealthColorEnum.Yellow;
          break;
        case 'bad':
          health = HealthColorEnum.Red;
          break;
        case 'inactive':
          health = HealthColorEnum.Grey;
          break;
        case 'churn':
          health = HealthColorEnum.Black;
          break;
      }
      if (!dateInfo.value) {
        return;
      }

      const queryParam = {
        date: dateInfo.value?.date,
        date_type: dateInfo.value?.type,
        u1: [activeU1Id.value],
      } as IQueryParam;

      if (health) {
        queryParam.health = health;
        queryParam.kpi = [KpiNameEnum.Zs];
      }
      router.push({
        name: RouteName.Customers,
        query: { ...queryParam },
      });
    });

  var vertBars = sameColoredVertGroups
    .selectAll('.vertBar')
    // enter a second time = loop subgroup per subgroup to add all rectangles
    .data((d) => d)
    .enter();

  vertBars
    .append('rect')
    .classed('vertBar', true)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr('x', function (d): any {
      return x(String(d.data.group));
    })
    .attr('y', function (d) {
      var retVal = y(d[1]);
      return retVal;
    })
    .attr('height', function (d) {
      var h = y(d[0]) - y(d[1]);
      if (h < 0) {
        h = 0;
      }
      vertBarComponentHeights.push(h);
      if (h > 0) {
        const height = h - gapBetweenBars;
        return height;
      }
      return h;
    })
    .attr('width', vertBarWidth.value);

  if (vertBarPercentageTotal != 0) {
    var labelIndex = 0;
    vertBars
      .data((d) => d)
      .append('text')
      // eslint-disable-next-line
      .attr('x', (d: any) => {
        return vertBarWidth.value / 2;
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .attr('y', function (d, i) {
        return y(d[0]) - gapBetweenBars - textPadding;
      })
      .attr('text-anchor', 'middle')
      .attr('class', 'barText')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .text(function (d) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const vertBarLabelObj: Record<string, any> = vertBarLabels[labelIndex];
        var vertBarLabel = '';
        if (vertBarLabelObj['percentage'] >= lowerCutOffPercentageShow) {
          vertBarLabel = `${vertBarLabelObj['percentage']}%   ${vertBarLabelObj['value']}`;
        }
        labelIndex++;
        return vertBarLabel;
      });
  }

  // * Horizontal bar
  // *************** horizontal bars on the right
  var horiBarLabels: Array<string[]> = [];
  var horiBarUserCounts: Array<Record<string, string | number | string[]>> = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customerUserData: Array<Record<string, any>> = customerUserHealth.map((customer, index) => {
    groups.push(customer.group);
    var total = 0;
    var retObj: Record<string, string | number> = { group: customer.group };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var horiUserCount: Record<string, any> = {
      group: customer.group,
      url: customer.url,
      vertBarLabel: vertBarLabels[index],
    };
    var horiLabels: string[] = [];
    customer.users.map((user) => {
      retObj[user.group] = user.percentage;
      horiUserCount[user.group] = user.count;
      // horiUserCount['percentage'] = `${formatNumberRoundOfDecimal(user.percentage)}`;
      total += user.percentage;
      // if (user.percentage == 0) {
      //   horiLabels.push('');
      // } else {
      horiLabels.push(String(formatNumberRoundOfDecimal(user.percentage)));
      // }
    });
    horiBarLabels.push(horiLabels);
    horiUserCount['percentage'] = horiLabels;
    horiBarUserCounts.push(horiUserCount);

    for (let k in retObj) {
      if (k !== 'group') {
        if (total == 0 || isNaN(total)) {
          retObj[k] = 0; // save from divide by zero. percentage is zero in this case.
        }
      }
    }

    return retObj;
  });

  var customerSubgroupsHori = customerUserData.map((customer) => {
    return customer.group;
  });
  customerSubgroupsHori.pop(); // this is to remove "churn" as it's not there in the right chart

  const xHori = scaleLinear()
    .domain([0, 100])
    .range([svgWidth.value - vertBarWidth.value - gapBetweenBars, 0]);

  // Add Y axis
  // const yHori = scaleBand().domain(groups).range([svgHeight.value, 0]);

  const dataSeriesHori = stack().keys(customerSubgroupsHori)(customerUserData);

  // Show the bars
  var sameColoredHoriGroups = svg
    .append('g')
    .selectAll('.horiBarGroup')
    // Enter in the stack data = loop key per key = group per group
    .data(dataSeriesHori)
    .enter()
    .append('g');

  sameColoredHoriGroups.attr('class', 'horiBarGroup');

  sameColoredHoriGroups
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr('fill', function (d): any {
      return color(d.key);
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr('class', function (d): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return colorStates[color(d.key) as any];
    });

  var horiBars = sameColoredHoriGroups
    .selectAll('.horiBar')
    // enter a second time = loop subgroup per subgroup to add all rectangles
    .data((d) => d)
    .enter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var tooltipCountNumbers = function (dataGroup: string): Record<string, any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var retNumbers: Record<string, any>;
    horiBarUserCounts.forEach(function (currentValue) {
      if (currentValue.group === dataGroup) {
        retNumbers = currentValue;
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return retNumbers!;
  };

  horiBars
    .append('rect')
    .classed('horiBar', true)
    .attr('x', (d) => {
      const result = xHori(d[1]) + (vertBarWidth.value + gapBetweenCharts);
      if (isNaN(result)) {
        return 0;
      } else {
        return result;
      }
    })
    .attr('y', function (d, i) {
      var bandwidth = 0;
      for (var index = 0; index <= i; index++) {
        bandwidth += vertBarComponentHeights[index];
      }
      return height - bandwidth - 20;
    })
    .attr('height', function (d, i) {
      const value = vertBarComponentHeights[i] - gapBetweenBars;
      if (value < 0) {
        return 0;
      }
      return value;
    })
    .attr('width', function (d) {
      const value = xHori(d[0]) - xHori(d[1]);
      if (isNaN(value)) {
        return 0;
      } else {
        return value;
      }
    })
    .on('mouseover', function () {
      tooltip.value?.setAttribute('style', 'display: block;');
    })
    .on('mouseout', function () {
      tooltip.value?.setAttribute('style', 'display: none;');
    })
    .on('mousemove', function (event: MouseEvent, d) {
      var xPosition = event.offsetX - tooltipOffsetAdjustX; //tooltip css width from style
      var yPosition = event.offsetY - tooltipOffsetAdjustY; // tooltip css height from style

      tooltip.value?.setAttribute('style', 'opacity: 1;');

      var currentNumbers: Record<string, number | string> = tooltipCountNumbers(String(d.data.group));
      if (tooltip.value) {
        tooltip.value.innerHTML = tooltipHTML(currentNumbers);
        tooltip.value?.setAttribute('style', `left: ${xPosition}px; top: ${yPosition}px; display: block;`);
      }
    })
    .on('click', function (event: MouseEvent, d) {
      const userHealth = select(this.parentNode as HTMLElement).attr('class');
      const customerHealth = d.data.group.toString();
      let c2AppIds = [] as string[];
      switch (customerHealth) {
        case 'good':
          c2AppIds = customerHealthInfo.value?.goodC2Ids;
          break;
        case 'bad':
          c2AppIds = customerHealthInfo.value?.badC2Ids;
          break;
        case 'inactive':
          c2AppIds = customerHealthInfo.value?.inactiveC2Ids;
          break;
        case 'fair':
          c2AppIds = customerHealthInfo.value?.fairC2Ids;
          break;
        case 'churn':
          c2AppIds = customerHealthInfo.value?.churnC2Ids;
          break;
        default:
          c2AppIds = [];
          break;
      }

      let health: HealthColorEnum | null = null;
      switch (userHealth) {
        case 'good':
          health = HealthColorEnum.Green;
          break;
        case 'fair':
          health = HealthColorEnum.Yellow;
          break;
        case 'bad':
          health = HealthColorEnum.Red;
          break;
        case 'inactive':
          health = HealthColorEnum.Grey;
          break;
        case 'churn':
          health = HealthColorEnum.Black;
          break;
        default:
          health = null;
          break;
      }
      if (!dateInfo.value || !health || c2AppIds.length <= 0) {
        return;
      }
      const queryParam = {
        date: dateInfo.value?.date,
        date_type: dateInfo.value?.type,
        u1: [activeU1Id.value],
        c2: c2AppIds,
      } as IQueryParam;
      if (health) {
        queryParam.health = health;
        queryParam.kpi = [KpiNameEnum.Zs];
      }
      const u2Type = u2Types.value.find((x) => x.name === selectedFilter.value?.value);
      if (u2Type) {
        queryParam.u2_type = u2Type.id;
      }
      router.push({
        name: RouteName.Users,
        query: { ...queryParam },
      });
    });
  // eslint-disable-next-line

  //////// add text labels /////

  var labelIndexHori = 0;
  horiBars
    .data((d) => d)
    .append('text')
    .attr('x', function (d) {
      const result = xHori(d[1]) + vertBarWidth.value + gapBetweenCharts + 25;
      if (isNaN(result)) {
        return 0;
      }
      return result;
    })
    .attr('y', function (d, i) {
      var bandwidth = 0;
      for (var index = 0; index < i; index++) {
        bandwidth += vertBarComponentHeights[index];
      }
      return height - bandwidth - gapBetweenBars - 25;
    })
    .attr('text-anchor', 'middle')
    .attr('class', 'barText')
    .text(function (d, i) {
      var horiLabel = horiBarLabels[i][labelIndexHori];
      if (i == 4) {
        labelIndexHori = labelIndexHori + 1;
      }

      if (
        Number(horiLabel) < lowerCutOffPercentageShow ||
        Number(vertBarLabels[i].percentage) < lowerCutOffPercentageShow
      ) {
        // In 2 cases where the bar height is short or bar width is short
        // there are chances that the text overflows the space, so we don't show any labels
        return '';
      }
      return horiLabel + '%';
    });

  // ////////// legends

  var legends = svg
    .append('g')
    .selectAll('.legend')
    .attr('text-anchor', 'end')
    .data(customerSubgroups.reverse())
    // .data(customerSubgroups)
    .enter()
    .append('g')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr('fill', function (d: string, i: number): any {
      var c = colors[4 - i];
      return c;
    })
    .attr('class', 'legend')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .attr('transform', function (d, i) {
      return 'translate(' + 1 + ', ' + 1 + ')';
    });

  const legendTextWidths: Array<number> = [];
  const legendTextPositions: Array<number> = [];

  legends
    .append('text')
    .text(function (d) {
      return d;
    })
    .attr('x', function (d, i) {
      const textWidth = this.getBBox().width;
      legendTextWidths.push(textWidth);
      const sum = legendTextWidths.reduce((partialSum, num) => partialSum + num, 0);
      const xPosition = width - sum - 8 * i;
      legendTextPositions.push(xPosition);
      return xPosition;
    })
    .attr('y', height - 12)
    .attr('dy', '0.0em')
    .attr('fill', '#666')
    .attr('font-size', '10px')
    .attr('font-weight', '700')
    .attr('font-family', 'Hanken Grotesk')
    .attr('text-anchor', 'start')
    .classed('legendText', true);

  legends
    .append('rect')
    .attr('x', function (d, i) {
      return legendTextPositions[i] - legendDotSizeParameters - 3;
    })
    .attr('y', height - 17)
    .attr('width', legendDotSizeParameters)
    .attr('height', legendDotSizeParameters)
    .attr('rx', legendDotSizeParameters);

  //// Labels
  var labels = svg
    .append('g')
    .selectAll('.label')
    .attr('text-anchor', 'end')
    .data(['Customers', 'Users of customers'])
    .enter()
    .append('g')
    .attr('class', 'label')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .attr('transform', function (d, i) {
      return 'translate(' + 1 + ', ' + 1 + ')';
    });

  labels
    .append('text')
    .text(function (d) {
      return d;
    })
    .attr('x', function (d, i) {
      if (i == 0) {
        return labelOffset;
      }
      return vertBarWidth.value + gapBetweenBars + labelOffset;
    })
    .attr('y', height - 12)
    .attr('dy', '0.0em')
    .attr('fill', '#666')
    .attr('font-size', '10px')
    .attr('font-weight', '800')
    .attr('font-family', 'Hanken Grotesk')
    .attr('text-anchor', 'start')
    .classed('legendText', true);
}
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-shadow">
    <LoadingIndicator class="tw-bg-light-shade-1" v-if="c2ZapscoreColorAggregationListLoading || isLoading" />
    <!-- <q-linear-progress -->
    <!-- indeterminate -->
    <!-- color="warning" -->
    <!-- v-if="props.isLoading" -->
    <!-- class="tw-absolute tw-top-0 tw-left-0 tw-right-0" -->
    <!-- /> -->
    <div class="tw-relative tw-px-6">
      <div class="tw-flex tw-items-center tw-justify-between tw-pt-4">
        <span class="tw-text-base tw-font-medium tw-text-dark-shade-3">{{ t('revenue_threat_vs_health') }} </span>

        <q-btn-dropdown
          dense
          outline
          :ripple="false"
          class="ellipsis tw-text-dark-shade-1"
          no-caps
          :no-wrap="false"
          color="#E1E1E1"
          :dropdown-icon="phCaretDown"
        >
          <template v-slot:label>
            <div class="row items-left wrap ellipsis tw-px-1 tw-py-2">
              <div class="tw-text-sm tw-font-semibold tw-text-dark-shade-1">
                {{ selectedFilter?.label }}
              </div>
            </div>
          </template>

          <q-list>
            <q-item
              clickable
              v-close-popup
              @click="onItemClick(option)"
              v-for="option in filterOptions"
              :key="option.value"
            >
              <q-item-section
                :class="option.label === selectedFilter?.label ? 'tw-bg-primary-dark tw-px-0' : 'tw-bg-light-shade-1'"
              >
                <q-item-label
                  class="tw-px-2 tw-text-sm tw-font-semibold"
                  :class="option.label === selectedFilter?.label ? 'tw-text-light-shade-1' : 'tw-text-dark-shade-2'"
                  >{{ option.label }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>

      <div class="tw-relative tw-py-2.5">
        <div ref="chart" class="tw-relative tw-h-60 tw-bg-light-shade-1 tw-text-white"></div>
      </div>
      <div class="tooltip" ref="tooltip"></div>
    </div>
  </div>
</template>

<style>
#chart {
  height: 400px;
  width: 600px;
}

:deep(.q-item) {
  padding-left: 0 0 0 0;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.barText {
  font: 10px 'Hanken Grotesk';
  font-weight: 700;
  fill: #fffffe;
}

div.tooltip {
  pointer-events: none;
  position: absolute;
  width: 120px;
  height: 104px;
  background: #101010;
  border-radius: 4px;
  overflow-wrap: break-word;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: #fffffe;
  display: none;
  /* opacity: 0; */
}

/* table */
.rTable {
  display: table;
  width: 100%;
}
.rTableRow {
  display: table-row;
}
.rTableHeading {
  display: table-header-group;
  background-color: #ddd;
}
.rTableCell,
.rTableHead {
  display: table-cell;
  padding: 3px;
}
.rTableHeading {
  display: table-header-group;
  background-color: #ddd;
  font-weight: bold;
}
.rTableFoot {
  display: table-footer-group;
  font-weight: bold;
  background-color: #ddd;
}
.rTableBody {
  display: table-row-group;
}
.dot {
  height: 6px;
  width: 6px;
  border-radius: 4px;
  display: inline-block;
}
.good {
  background-color: #4ac18d;
}
.fair {
  background-color: #f8b729;
}
.bad {
  background-color: #f15d59;
}
.inactive {
  background-color: #d0d0d0;
}
.tooltipHeader {
  color: #e1e1e1;
  padding: 5px;
}
.groupName {
  text-align: left;
}
.groupNumber {
  text-align: right;
}
.groupDot {
  text-align: center;
}
.tooltipAnchorContainer {
  text-align: right;
  padding: 7px;
}

.tooltipAnchor {
  color: #e6f1f6;
  font-weight: bold;
  text-decoration: none;
  pointer-events: auto;
  cursor: pointer;
}

.horiBar {
  cursor: pointer;
}

.vertBar {
  cursor: pointer;
}

.legendText {
  text-transform: capitalize;
}
</style>

<style scoped lang="postcss">
:deep(.q-item) {
  @apply tw-p-0;
}
</style>
