{
    // "kpi_name": {
    //     "zs": "ZapScore",
    //     "uss": "Product Usage Percentile Score",
    //     "pvps": "Unique Page Visited Percentile Score",
    //     "fups": "Features Used Percentile Score",
    //     "pvvcs": "Total Pages Visited per User Percentile Score",
    //     "stvcs": "Total Time Spent per User Percentile Score",
    //     "oss": "Business Outcomes Percentile Score",
    //     "pss": "Ticketing / Feature Request / Billing / Emails Health Percentile Score",
    //     "bts": "Ticket Tracking Percentile Score",
    //     "bls": "Ticket Log Percentile Score",
    //     "ulpb": "100 - %User Logging Ticket Percentile Score",
    //     "p1pb": "100 - %P1 Tickets Percentile Score",
    //     "brs": "%Ticket Resolution Percentile Score",
    //     "frs": "Feature Request Percentile Score",
    //     "frls": "Feature Request Log Percentile Score",
    //     "pfrds": "%Features Delivered Percentile Score",
    //     "pas": "Subscription & Billing Percentile Score",
    //     "pfps": "% of full payments on time Percentile Score",
    //     "pdcs": "% Dues Cleared Percentile Score",
    //     "ppos": "100 - % of 90+ days of overdue payments Percentile Score",
    //     "ccs": "Customer Communication Score Percentile Score",
    //     "cfs": "Communication Frequency Percentile Score",
    //     "dslcwc": "100 - days since last communication with customer Percentile Score",
    //     "upss": "Upsell Percentile Score",
    //     "uurs": "Upsell Readiness Percentile Score",
    //     "puers": "% Entitlement limit reached Percentile Score",
    //     "wmglqp": "% Entitlement growth in last 4 weeks Percentile Score",
    //     "uuts": "Upsell Tailwind Percentile Score",
    //     "pdmghs": "% decision makers in good health Percentile Score",
    //     "pighs": "% influencers in good health Percentile Score",
    //     "hfs": "CS Gut feel Percentile Score",

    //     "ppots": "% of payments on time",
    //     "nls": "No of Logins Score",
    //     "f1us": "Feature #1 Use Score",
    //     "f2us": "Feature #2 Use Score",
    //     "npvs": "No of Page Visit Score",
    //     "f3us": "Feature #3 Use Score",
    //     "f4us": "Feature #4 Use Score",
    //     "f5us": "Feature #5 Use Score",
    //     "ttps": "Total Time on Product Score"
    // },

    "kpi_commentary_user": {
        "zs": "All KPIs combined for Usage, Outcomes, Ticketing, Feature Requests, Billing and Subscription, Emails, Upsell and CS Handfeel - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "uss": "All the KPIs combined for pages visited per user, features used, time spent per user - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "oss": "All the KPIs combined - this user gets a score of {score} that puts user at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "pvps": "This User visited {pvpsTotalNoOfUniquePagesVisited} unique pages that puts this user at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "pvvcs": "This User visited {pvvcsTotalNoOfPageVisits} pages that puts this user at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})", 
        "stvcs": "This User spent {stvcsTotalSessionsTime} minutes on your product that puts this user at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "fups": "This User used {fupsTotalNoOfUniqueFeaturesUsed} features out of {fupsTotalFeatures} features that puts this user at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})"
    },

    "kpi_commentary": {
        "zs": "All KPIs combined for Usage, Outcomes, Ticketing, Feature Requests, Billing and Subscription, Emails, Upsell and CS Handfeel - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "uss": "All the KPIs combined for pages visited per user, features used, time spent per user - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "pvps": "This Customer visited {pvpsTotalNoOfUniquePagesVisited} unique pages out of {pvpsTotalPages} unique pages that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "fups": "This Customer used {fupsTotalNoOfUniqueFeaturesUsed} features out of {fupsTotalFeatures} features that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "pvvcs": "This Customer visited {pvvcsTotalNoOfPageVisits} pages {pvvcsMeanPageVisits} pages per user that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})", 
        "stvcs": "This Customer spent {stvcsTotalSessionsTime} minutes on your product {stvcsSessionTimeSd} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "oss": "All the KPIs combined - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "pss": "All the KPIs combined for Ticketing / Feature Requests / Subscription Billing / Email apps - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "bts": "All the KPIs combined for tickets logged, tickets resolved, users logging tickets and P1 tickets  - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "bls": "This Customer logged {noOfBugsLogged} issues with {stvcsTotalSessionsTime} minutes on the product {calculatedString} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "ulpb": "{noOfU2sLoggingBugs} out of {totalU2s} users of this Customer logged issues {calculatedPercentage} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "p1pb": "This Customer logged {noOfBugsP1} high priority issues out of total {noOfBugsLogged} issues {calculatedPercentage} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "brs": "{noOfBugsResolved} out of {noOfBugsLogged} issues were resolved for this Customer {calculatedPercentage} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "frs": "All the KPIs combined for Features Logged and features delivered - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "frls": "This Customer logged {noOfFeatureRequests} feature requests while using {noOfFeatures} features on your product that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "pfrds": "{noOfFeatureRequestsResolved} out of {noOfFeatureRequests} Feature Requests were delivered for this Customer {calculatedPercentage} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "pas": "All the KPIs combined for payments on time, dues cleared and overdue payments - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "pfps": "{noOfInvoicesPaidFullOnTime} out of {rollingNoOfInvoices} billed invoices were paid full and paid on-time by this Customer {percentage} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "pdcs": "{rollingInvoiceValuePaid} out of {rollingInvoiceValueDue} dues were paid by this Customer {calculatedPercentage} that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "ppos": "{noOfInvoicesOverdue90DaysPlus} out of {noOfInvoicesOverdue} billed invoices are overdue by 90 days or more {calculatedPercentage} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "ccs": "All the KPIs combined for frequency of communication and days since last comms - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "cfs": "This customer is sending {noOfEmailsOrCommunication} emails  a standard deviation of {emailsOrCommunicationAvg} emails per 30 days that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "dslcwc": "It has been {daysSinceLastCommunication} days since last comms with this customer that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "upss": "All the KPIs combined for Upsell Readiness and Upsell Tailwind - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "uurs": "All the KPIs combined for Entitlement limit reached and growth of entitlements - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "puers": "This Customer has utilized {calculatedPercentage} of the entitlement limit ({entitlementObjectHighLimit} out of {totalU2s}) that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "wmglqp": "The Entitlement consumption has grown by {weeklyGrowthRate} (up from {entitlementObjectHighLimit} to {weeksToUserLimitBreach}) in last 4 weeks for this customer that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "uuts": "All the KPIs combined for decision makers and influencers in good health - this customer gets a score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "pdmghs": "{noOfGreenDecisionMakers} out of {totalNoOfDecisionMakers} decision makers are in good health {calculatedPercentage} for this customer that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",
        "pighs": "{noOfGreenInfluencers} out of {totalNoOfInfluencers} influencers are in good health {calculatedPercentage} for this customer that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "hfs": "Your CS team has given this customer a gut feel health score of {score} that puts customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "intercom_cfs": "This customer is sending {noOfIntercomCommunication} intercom conversations a standard deviation of {intercomCommunicationAvg} intercom conversations per 30 days that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "intercom_dslcwc": "It has been {daysSinceLastCommunication} days since last intercom conversation with this customer that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "slack_cfs": "This customer is sending {noOfSlackCommunication} slack conversations a standard deviation of {slackCommunicationAvg} slack conversations per 30 days that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})",

        "slack_dslcwc": "It has been {daysSinceLastCommunication} days since last slack conversation with this customer that puts this customer at {percentile} percentile among all {c2Type} customers which is {color} ({kpiRange})"
    },

    "kpi_range": "up to {redRangeEnd} percentile = RED, {yellowRange} = YELLOW, {greenRange} = GREEN"
}