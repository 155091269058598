import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInWeeks,
  format,
  isSameYear,
} from 'date-fns';

export const useNotificationDateHelper = () => {
  const formatNotificationDate = (notificationDateStr: string) => {
    const currentDate = new Date();
    const notificationDate = new Date(notificationDateStr);

    const isJustNow = differenceInMinutes(currentDate, notificationDate) < 2;

    const isWithInOneHour = differenceInHours(currentDate, notificationDate) < 1;

    const isWithIn24Hrs = differenceInHours(currentDate, notificationDate) < 24;

    const isWithIn2Weeks = differenceInWeeks(currentDate, notificationDate) < 2;

    if (isJustNow) {
      return `Just now`;
    }

    if (isWithInOneHour) {
      return `${differenceInMinutes(currentDate, notificationDate)} minutes ago`;
    }

    if (isWithIn24Hrs) {
      return `${format(notificationDate, 'hh:mm aaa')} (${differenceInHours(currentDate, notificationDate)} hours ago)`;
    }

    if (isWithIn2Weeks) {
      return `${format(notificationDate, 'EEE, dd MMM, hh:mm aaa')} (${differenceInDays(
        currentDate,
        notificationDate,
      )} days ago)`;
    }

    if (isSameYear(currentDate, notificationDate)) {
      return format(notificationDate, 'EEE, dd MMM, hh:mm aaa');
    }

    return format(notificationDate, 'EEE, dd MMM yyyy, hh:mm aaa');
  };

  return {
    formatNotificationDate,
  };
};
