<script setup lang="ts">
import { MessageSchema } from '@/lib/i18n';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import PersonalAccessTokenList from './PersonalAccessToken/PersonalAccessTokenList.vue';
import CreatePersonalAccessToken from './PersonalAccessToken/CreatePersonalAccessToken.vue';
import { phPlus, phArrowLeft } from '@/phosphor-icons';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

enum PersonalAccessTokenScreenType {
  list = 'list',
  add_new = 'add_new',
}

const slide = ref<string>(PersonalAccessTokenScreenType.list);

const onToggleSlide = () => {
  if (slide.value === PersonalAccessTokenScreenType.list) {
    slide.value = PersonalAccessTokenScreenType.add_new;
  } else {
    slide.value = PersonalAccessTokenScreenType.list;
  }
};

const headingText = computed(() => {
  if (slide.value === PersonalAccessTokenScreenType.list) {
    return t('developer_setting.personal_access_token.list.heading');
  } else {
    return t('developer_setting.personal_access_token.create_new.heading');
  }
});

const toggleBtnText = computed(() => {
  switch (slide.value) {
    case PersonalAccessTokenScreenType.list:
      return t('developer_setting.personal_access_token.generate_new_token');
    case PersonalAccessTokenScreenType.add_new:
      return t('developer_setting.back');
    default:
      return t('developer_setting.back');
  }
});

const toggleBtnIcon = computed(() => {
  switch (slide.value) {
    case PersonalAccessTokenScreenType.list:
      return phPlus;
    case PersonalAccessTokenScreenType.add_new:
      return phArrowLeft;
    default:
      return phArrowLeft;
  }
});

const onCreateTokenDone = () => {
  slide.value = PersonalAccessTokenScreenType.list;
};
</script>

<template>
  <div class="tw-flex tw-h-full tw-flex-col tw-bg-light-shade-2">
    <div class="tw-mt-4 tw-flex tw-flex-row tw-place-items-center tw-justify-between tw-bg-gray-200 tw-px-4 tw-py-2">
      <span class="tw-font-semibold">{{ headingText }}</span>
      <q-btn
        outline
        dense
        color="primary"
        no-caps
        :label="toggleBtnText"
        @click="onToggleSlide"
        :icon="toggleBtnIcon"
        class="tw-px-2"
      />
    </div>
    <PersonalAccessTokenList v-if="slide === PersonalAccessTokenScreenType.list" />
    <CreatePersonalAccessToken @on-done-click="onCreateTokenDone" v-else />
  </div>
</template>
