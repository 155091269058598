export interface IKpiRowDashboard {
  kpiId: string;
  kpiName: string;
  kpiDisplayName: string;
  kpiDescription: string;
  kpiScore: number;
  kpiPercentageChange: number;
  kpiColorCode?: string;
  kpiCommentary?: string;
  steadyCustScore?: number;
  steadyCustPercentageChange?: number;
  steadyCustColorCode?: string;
  onboardingCustScore?: number;
  onboardingCustPercentageChange?: number;
  onboardingCustColorCode?: string;
  trialCustScore?: number;
  trialCustPercentageChange?: number;
  trialCustColorCode?: string;
  percentInGrey: number;
  percentInRed: number;
  percentInYellow: number;
  percentInGreen: number;
  noInGrey: number;
  noInRed: number;
  noInYellow: number;
  noInGreen: number;
  date: string;
}
export interface ICustomerUserHealthData {
  group: string;
  percentage: number;
  value: string;
  url: string;
  users: { group: string; percentage: number; value: string; count: number }[];
}

export enum MoreDetailsElementType {
  SCORE = 'SCORE',
  TIME = 'TIME',
  PERCENTAGE = 'PERCENTAGE',
}
export interface IMoreDetailsElement {
  headerIcon?: string | undefined;
  header?: string | undefined;
  type: MoreDetailsElementType;
  value?: number | undefined;
  percentage?: number | undefined;
  footer?: string | undefined;
}

export enum BarChartOptions {
  customer = 'c2s',
  user = 'u2s',
}

export enum SankeyChartOption {
  customer = 'customer',
  user = 'user',
}

export enum D3ChartOptions {
  all = 'all',
  decision_maker = 'decision_maker',
  influencer = 'influencer',
  normal_user = 'normal_user',
}

export enum VerticalChartOption {
  customer = 'customer',
  arr = 'arr',
}
