/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2DataSettings } from '../model/';
// @ts-ignore
import { C2DataSettingsUpdateDto } from '../model/';
/**
 * C2DataSettingsApi - axios parameter creator
 * @export
 */
export const C2DataSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create C2 data setting selection log
         * @param {C2DataSettingsUpdateDto} c2DataSettingsUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DataSettingsControllerCreate: async (c2DataSettingsUpdateDto: C2DataSettingsUpdateDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2DataSettingsUpdateDto' is not null or undefined
            assertParamExists('c2DataSettingsControllerCreate', 'c2DataSettingsUpdateDto', c2DataSettingsUpdateDto)
            const localVarPath = `/api/c2-data-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2DataSettingsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Selected setting
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DataSettingsControllerFindSelectedSetting: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-data-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2DataSettingsApi - functional programming interface
 * @export
 */
export const C2DataSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2DataSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create C2 data setting selection log
         * @param {C2DataSettingsUpdateDto} c2DataSettingsUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2DataSettingsControllerCreate(c2DataSettingsUpdateDto: C2DataSettingsUpdateDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2DataSettingsControllerCreate(c2DataSettingsUpdateDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Selected setting
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2DataSettingsControllerFindSelectedSetting(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2DataSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2DataSettingsControllerFindSelectedSetting(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2DataSettingsApi - factory interface
 * @export
 */
export const C2DataSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2DataSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create C2 data setting selection log
         * @param {C2DataSettingsUpdateDto} c2DataSettingsUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DataSettingsControllerCreate(c2DataSettingsUpdateDto: C2DataSettingsUpdateDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.c2DataSettingsControllerCreate(c2DataSettingsUpdateDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Selected setting
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DataSettingsControllerFindSelectedSetting(xTenantId?: string, options?: any): AxiosPromise<C2DataSettings> {
            return localVarFp.c2DataSettingsControllerFindSelectedSetting(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2DataSettingsControllerCreate operation in C2DataSettingsApi.
 * @export
 * @interface C2DataSettingsApiC2DataSettingsControllerCreateRequest
 */
export interface C2DataSettingsApiC2DataSettingsControllerCreateRequest {
    /**
     * 
     * @type {C2DataSettingsUpdateDto}
     * @memberof C2DataSettingsApiC2DataSettingsControllerCreate
     */
    readonly c2DataSettingsUpdateDto: C2DataSettingsUpdateDto

    /**
     * 
     * @type {string}
     * @memberof C2DataSettingsApiC2DataSettingsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2DataSettingsControllerFindSelectedSetting operation in C2DataSettingsApi.
 * @export
 * @interface C2DataSettingsApiC2DataSettingsControllerFindSelectedSettingRequest
 */
export interface C2DataSettingsApiC2DataSettingsControllerFindSelectedSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof C2DataSettingsApiC2DataSettingsControllerFindSelectedSetting
     */
    readonly xTenantId?: string
}

/**
 * C2DataSettingsApi - object-oriented interface
 * @export
 * @class C2DataSettingsApi
 * @extends {BaseAPI}
 */
export class C2DataSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Create C2 data setting selection log
     * @param {C2DataSettingsApiC2DataSettingsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2DataSettingsApi
     */
    public c2DataSettingsControllerCreate(requestParameters: C2DataSettingsApiC2DataSettingsControllerCreateRequest, options?: AxiosRequestConfig) {
        return C2DataSettingsApiFp(this.configuration).c2DataSettingsControllerCreate(requestParameters.c2DataSettingsUpdateDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Selected setting
     * @param {C2DataSettingsApiC2DataSettingsControllerFindSelectedSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2DataSettingsApi
     */
    public c2DataSettingsControllerFindSelectedSetting(requestParameters: C2DataSettingsApiC2DataSettingsControllerFindSelectedSettingRequest = {}, options?: AxiosRequestConfig) {
        return C2DataSettingsApiFp(this.configuration).c2DataSettingsControllerFindSelectedSetting(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
