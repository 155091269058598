<script setup lang="ts">
import { Kpi, KpiNameEnum } from '@/api/client';
import { MessageSchema } from '@/lib/i18n';
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { useKpiStore } from '@/stores/kpi';
import { storeToRefs } from 'pinia';
import { QTableProps } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import TableItem from './TableItem.vue';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const columns = computed(() => {
  const columnData: QTableProps['columns'] = [
    { label: t('table_section.kpi'), name: 'kpiName', field: 'kpiName', align: 'left' as const },
    {
      label: t('table_section.customer_health'),
      name: 'customer_health',
      field: 'customer_health',
      align: 'left' as const,
    },
    { label: t('table_section.kpi_score'), name: 'kpiScore', field: 'kpiScore', align: 'left' as const },
  ];
  return columnData;
});

const { isDashboardInfoLoading, dashboardInfo } = storeToRefs(useDashboardV2Store());
const { activeKpiList, isLoading: isKpiLoading } = storeToRefs(useKpiStore());

const kpiList = computed(() => {
  const list: Kpi[] = [] as Kpi[];
  if (!dashboardInfo.value) {
    return list;
  }
  const uss = activeKpiList.value.find((x) => x.name === KpiNameEnum.Uss);
  if (uss) {
    list.push(uss);
  }
  const bts = activeKpiList.value.find((x) => x.name === KpiNameEnum.Bts);
  if (bts) {
    list.push(bts);
  }
  const frs = activeKpiList.value.find((x) => x.name === KpiNameEnum.Frs);
  if (frs) {
    list.push(frs);
  }
  const pas = activeKpiList.value.find((x) => x.name === KpiNameEnum.Pas);
  if (pas) {
    list.push(pas);
  }
  const ccs = activeKpiList.value.find((x) => x.name === KpiNameEnum.Ccs);
  if (ccs) {
    list.push(ccs);
  }
  const upss = activeKpiList.value.find((x) => x.name === KpiNameEnum.Upss);
  if (upss) {
    list.push(upss);
  }
  const hfs = activeKpiList.value.find((x) => x.name === KpiNameEnum.Hfs);
  if (hfs) {
    list.push(hfs);
  }
  return list;
});
</script>

<template>
  <q-table
    :rows="kpiList"
    :columns="columns"
    :loading="isDashboardInfoLoading && isKpiLoading"
    hide-bottom
    :rows-per-page-options="[0]"
    no-data-label="I didn't find anything for you"
    class="my-sticky-header-table no-shadow tw-my-3 tw-border tw-border-light-shade-4"
  >
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          <span class="tw-text-sm tw-font-semibold tw-leading-5 tw-text-dark-shade-4">{{ col.label }}</span>
        </q-th>
      </q-tr>
    </template>

    <template v-slot:body="props">
      <TableItem v-bind:table-props="props" />
    </template>
  </q-table>
</template>
