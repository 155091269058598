<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import DatePicker from '@/components/DatePicker.vue';
import { useDateStore } from '@/stores/date';
import { IDate } from '@/types/date.type';
import { format } from 'date-fns';

const props = defineProps<{ headerName: string; isDisabledDatePicker?: boolean | undefined }>();

const emits = defineEmits(['onDateChange']);

const dateStore = useDateStore();

async function onDateFilterApplied(appliedIDate: IDate) {
  const dateText = format(appliedIDate.endDate, 'yyyy-MM-dd');
  const dateType = appliedIDate.type;
  emits('onDateChange', dateText, dateType);
}
</script>

<template>
  <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-border-b tw-bg-light-shade-1 tw-px-6 tw-py-3">
    <div class="tw-text-xl tw-font-semibold tw-text-dark-shade-2" v-if="props.headerName.length > 0">
      {{ props.headerName }}
    </div>
    <slot name="details"></slot>
    <div class="tw-flex tw-grow tw-items-center tw-justify-end">
      <slot name="right"></slot>
      <DatePicker
        v-if="!props.isDisabledDatePicker"
        :selection="dateStore.iDate"
        @on-date-filter-applied="onDateFilterApplied"
      />
    </div>
  </div>
</template>
