/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2Segment } from '../model/';
// @ts-ignore
import { CopyC2SegmentDto } from '../model/';
// @ts-ignore
import { CreateC2SegmentDto } from '../model/';
// @ts-ignore
import { UpdateC2SegmentDto } from '../model/';
/**
 * C2SegmentApi - axios parameter creator
 * @export
 */
export const C2SegmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy current segment 
         * @param {CopyC2SegmentDto} copyC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerCopy: async (copyC2SegmentDto: CopyC2SegmentDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyC2SegmentDto' is not null or undefined
            assertParamExists('c2SegmentControllerCopy', 'copyC2SegmentDto', copyC2SegmentDto)
            const localVarPath = `/api/c2-segment/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyC2SegmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create C2Segment
         * @param {CreateC2SegmentDto} createC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerCreate: async (createC2SegmentDto: CreateC2SegmentDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC2SegmentDto' is not null or undefined
            assertParamExists('c2SegmentControllerCreate', 'createC2SegmentDto', createC2SegmentDto)
            const localVarPath = `/api/c2-segment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2SegmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2Segments
         * @param {string} roleBaseCurrentDate To provide response on the basic of role base integration
         * @param {string} [xTenantId] 
         * @param {Array<string>} [ids] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerFindAll: async (roleBaseCurrentDate: string, xTenantId?: string, ids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleBaseCurrentDate' is not null or undefined
            assertParamExists('c2SegmentControllerFindAll', 'roleBaseCurrentDate', roleBaseCurrentDate)
            const localVarPath = `/api/c2-segment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleBaseCurrentDate !== undefined) {
                localVarQueryParameter['role_base_current_date'] = (roleBaseCurrentDate as any instanceof Date) ?
                    (roleBaseCurrentDate as any).toISOString() :
                    roleBaseCurrentDate;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2 Segments with shared segments
         * @param {string} roleBaseCurrentDate To provide response on the basic of role base integration
         * @param {string} [xTenantId] 
         * @param {Array<string>} [ids] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerFindAllWithShared: async (roleBaseCurrentDate: string, xTenantId?: string, ids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleBaseCurrentDate' is not null or undefined
            assertParamExists('c2SegmentControllerFindAllWithShared', 'roleBaseCurrentDate', roleBaseCurrentDate)
            const localVarPath = `/api/c2-segment/find-all-with-shared`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleBaseCurrentDate !== undefined) {
                localVarQueryParameter['role_base_current_date'] = (roleBaseCurrentDate as any instanceof Date) ?
                    (roleBaseCurrentDate as any).toISOString() :
                    roleBaseCurrentDate;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find C2Segment by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2SegmentControllerFindOne', 'id', id)
            const localVarPath = `/api/c2-segment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete C2Segment by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2SegmentControllerRemove', 'id', id)
            const localVarPath = `/api/c2-segment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update C2Segment by Id
         * @param {string} id 
         * @param {UpdateC2SegmentDto} updateC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerUpdate: async (id: string, updateC2SegmentDto: UpdateC2SegmentDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2SegmentControllerUpdate', 'id', id)
            // verify required parameter 'updateC2SegmentDto' is not null or undefined
            assertParamExists('c2SegmentControllerUpdate', 'updateC2SegmentDto', updateC2SegmentDto)
            const localVarPath = `/api/c2-segment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2SegmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2SegmentApi - functional programming interface
 * @export
 */
export const C2SegmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2SegmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy current segment 
         * @param {CopyC2SegmentDto} copyC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentControllerCopy(copyC2SegmentDto: CopyC2SegmentDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2Segment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentControllerCopy(copyC2SegmentDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create C2Segment
         * @param {CreateC2SegmentDto} createC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentControllerCreate(createC2SegmentDto: CreateC2SegmentDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Segment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentControllerCreate(createC2SegmentDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2Segments
         * @param {string} roleBaseCurrentDate To provide response on the basic of role base integration
         * @param {string} [xTenantId] 
         * @param {Array<string>} [ids] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentControllerFindAll(roleBaseCurrentDate: string, xTenantId?: string, ids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2Segment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentControllerFindAll(roleBaseCurrentDate, xTenantId, ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2 Segments with shared segments
         * @param {string} roleBaseCurrentDate To provide response on the basic of role base integration
         * @param {string} [xTenantId] 
         * @param {Array<string>} [ids] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentControllerFindAllWithShared(roleBaseCurrentDate: string, xTenantId?: string, ids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2Segment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentControllerFindAllWithShared(roleBaseCurrentDate, xTenantId, ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find C2Segment by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Segment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete C2Segment by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Segment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update C2Segment by Id
         * @param {string} id 
         * @param {UpdateC2SegmentDto} updateC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentControllerUpdate(id: string, updateC2SegmentDto: UpdateC2SegmentDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Segment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentControllerUpdate(id, updateC2SegmentDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2SegmentApi - factory interface
 * @export
 */
export const C2SegmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2SegmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy current segment 
         * @param {CopyC2SegmentDto} copyC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerCopy(copyC2SegmentDto: CopyC2SegmentDto, xTenantId?: string, options?: any): AxiosPromise<Array<C2Segment>> {
            return localVarFp.c2SegmentControllerCopy(copyC2SegmentDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create C2Segment
         * @param {CreateC2SegmentDto} createC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerCreate(createC2SegmentDto: CreateC2SegmentDto, xTenantId?: string, options?: any): AxiosPromise<C2Segment> {
            return localVarFp.c2SegmentControllerCreate(createC2SegmentDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2Segments
         * @param {string} roleBaseCurrentDate To provide response on the basic of role base integration
         * @param {string} [xTenantId] 
         * @param {Array<string>} [ids] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerFindAll(roleBaseCurrentDate: string, xTenantId?: string, ids?: Array<string>, options?: any): AxiosPromise<Array<C2Segment>> {
            return localVarFp.c2SegmentControllerFindAll(roleBaseCurrentDate, xTenantId, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2 Segments with shared segments
         * @param {string} roleBaseCurrentDate To provide response on the basic of role base integration
         * @param {string} [xTenantId] 
         * @param {Array<string>} [ids] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerFindAllWithShared(roleBaseCurrentDate: string, xTenantId?: string, ids?: Array<string>, options?: any): AxiosPromise<Array<C2Segment>> {
            return localVarFp.c2SegmentControllerFindAllWithShared(roleBaseCurrentDate, xTenantId, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find C2Segment by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<C2Segment> {
            return localVarFp.c2SegmentControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete C2Segment by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<C2Segment> {
            return localVarFp.c2SegmentControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update C2Segment by Id
         * @param {string} id 
         * @param {UpdateC2SegmentDto} updateC2SegmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentControllerUpdate(id: string, updateC2SegmentDto: UpdateC2SegmentDto, xTenantId?: string, options?: any): AxiosPromise<C2Segment> {
            return localVarFp.c2SegmentControllerUpdate(id, updateC2SegmentDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2SegmentControllerCopy operation in C2SegmentApi.
 * @export
 * @interface C2SegmentApiC2SegmentControllerCopyRequest
 */
export interface C2SegmentApiC2SegmentControllerCopyRequest {
    /**
     * 
     * @type {CopyC2SegmentDto}
     * @memberof C2SegmentApiC2SegmentControllerCopy
     */
    readonly copyC2SegmentDto: CopyC2SegmentDto

    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerCopy
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentControllerCreate operation in C2SegmentApi.
 * @export
 * @interface C2SegmentApiC2SegmentControllerCreateRequest
 */
export interface C2SegmentApiC2SegmentControllerCreateRequest {
    /**
     * 
     * @type {CreateC2SegmentDto}
     * @memberof C2SegmentApiC2SegmentControllerCreate
     */
    readonly createC2SegmentDto: CreateC2SegmentDto

    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentControllerFindAll operation in C2SegmentApi.
 * @export
 * @interface C2SegmentApiC2SegmentControllerFindAllRequest
 */
export interface C2SegmentApiC2SegmentControllerFindAllRequest {
    /**
     * To provide response on the basic of role base integration
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerFindAll
     */
    readonly roleBaseCurrentDate: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof C2SegmentApiC2SegmentControllerFindAll
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for c2SegmentControllerFindAllWithShared operation in C2SegmentApi.
 * @export
 * @interface C2SegmentApiC2SegmentControllerFindAllWithSharedRequest
 */
export interface C2SegmentApiC2SegmentControllerFindAllWithSharedRequest {
    /**
     * To provide response on the basic of role base integration
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerFindAllWithShared
     */
    readonly roleBaseCurrentDate: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerFindAllWithShared
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof C2SegmentApiC2SegmentControllerFindAllWithShared
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for c2SegmentControllerFindOne operation in C2SegmentApi.
 * @export
 * @interface C2SegmentApiC2SegmentControllerFindOneRequest
 */
export interface C2SegmentApiC2SegmentControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentControllerRemove operation in C2SegmentApi.
 * @export
 * @interface C2SegmentApiC2SegmentControllerRemoveRequest
 */
export interface C2SegmentApiC2SegmentControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentControllerUpdate operation in C2SegmentApi.
 * @export
 * @interface C2SegmentApiC2SegmentControllerUpdateRequest
 */
export interface C2SegmentApiC2SegmentControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2SegmentDto}
     * @memberof C2SegmentApiC2SegmentControllerUpdate
     */
    readonly updateC2SegmentDto: UpdateC2SegmentDto

    /**
     * 
     * @type {string}
     * @memberof C2SegmentApiC2SegmentControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * C2SegmentApi - object-oriented interface
 * @export
 * @class C2SegmentApi
 * @extends {BaseAPI}
 */
export class C2SegmentApi extends BaseAPI {
    /**
     * 
     * @summary Copy current segment 
     * @param {C2SegmentApiC2SegmentControllerCopyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentApi
     */
    public c2SegmentControllerCopy(requestParameters: C2SegmentApiC2SegmentControllerCopyRequest, options?: AxiosRequestConfig) {
        return C2SegmentApiFp(this.configuration).c2SegmentControllerCopy(requestParameters.copyC2SegmentDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create C2Segment
     * @param {C2SegmentApiC2SegmentControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentApi
     */
    public c2SegmentControllerCreate(requestParameters: C2SegmentApiC2SegmentControllerCreateRequest, options?: AxiosRequestConfig) {
        return C2SegmentApiFp(this.configuration).c2SegmentControllerCreate(requestParameters.createC2SegmentDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2Segments
     * @param {C2SegmentApiC2SegmentControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentApi
     */
    public c2SegmentControllerFindAll(requestParameters: C2SegmentApiC2SegmentControllerFindAllRequest, options?: AxiosRequestConfig) {
        return C2SegmentApiFp(this.configuration).c2SegmentControllerFindAll(requestParameters.roleBaseCurrentDate, requestParameters.xTenantId, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2 Segments with shared segments
     * @param {C2SegmentApiC2SegmentControllerFindAllWithSharedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentApi
     */
    public c2SegmentControllerFindAllWithShared(requestParameters: C2SegmentApiC2SegmentControllerFindAllWithSharedRequest, options?: AxiosRequestConfig) {
        return C2SegmentApiFp(this.configuration).c2SegmentControllerFindAllWithShared(requestParameters.roleBaseCurrentDate, requestParameters.xTenantId, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find C2Segment by Id
     * @param {C2SegmentApiC2SegmentControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentApi
     */
    public c2SegmentControllerFindOne(requestParameters: C2SegmentApiC2SegmentControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C2SegmentApiFp(this.configuration).c2SegmentControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete C2Segment by Id
     * @param {C2SegmentApiC2SegmentControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentApi
     */
    public c2SegmentControllerRemove(requestParameters: C2SegmentApiC2SegmentControllerRemoveRequest, options?: AxiosRequestConfig) {
        return C2SegmentApiFp(this.configuration).c2SegmentControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update C2Segment by Id
     * @param {C2SegmentApiC2SegmentControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentApi
     */
    public c2SegmentControllerUpdate(requestParameters: C2SegmentApiC2SegmentControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C2SegmentApiFp(this.configuration).c2SegmentControllerUpdate(requestParameters.id, requestParameters.updateC2SegmentDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
