/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2Type } from '../model/';
// @ts-ignore
import { CompositeUpdateC2TypeDto } from '../model/';
// @ts-ignore
import { CreateC2TypeDto } from '../model/';
// @ts-ignore
import { CreateC2TypeV2Dto } from '../model/';
// @ts-ignore
import { ToggleSectionDto } from '../model/';
// @ts-ignore
import { UpdateC2TypeDto } from '../model/';
// @ts-ignore
import { UpdateC2TypeV2Dto } from '../model/';
/**
 * C2TypeApi - axios parameter creator
 * @export
 */
export const C2TypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a C2 Type
         * @param {CreateC2TypeDto} createC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerCreate: async (createC2TypeDto: CreateC2TypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC2TypeDto' is not null or undefined
            assertParamExists('c2TypesControllerCreate', 'createC2TypeDto', createC2TypeDto)
            const localVarPath = `/api/c2-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2TypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a C2 Type V2
         * @param {CreateC2TypeV2Dto} createC2TypeV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerCreateV2: async (createC2TypeV2Dto: CreateC2TypeV2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC2TypeV2Dto' is not null or undefined
            assertParamExists('c2TypesControllerCreateV2', 'createC2TypeV2Dto', createC2TypeV2Dto)
            const localVarPath = `/api/v2/c2-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2TypeV2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2 Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of c2 types on the basic of date info passed
         * @param {string} date Required field
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType Date type field for which u1 aggregation data will be shown
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindAllV2: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2TypesControllerFindAllV2', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2TypesControllerFindAllV2', 'dateType', dateType)
            const localVarPath = `/api/v2/c2-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of c2 types with query params V3
         * @param {string} [xTenantId] 
         * @param {boolean} [isActive] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindAllV3: async (xTenantId?: string, isActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/c2-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a C2 Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2TypesControllerFindOne', 'id', id)
            const localVarPath = `/api/c2-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle section
         * @param {ToggleSectionDto} toggleSectionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerToggleSection: async (toggleSectionDto: ToggleSectionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toggleSectionDto' is not null or undefined
            assertParamExists('c2TypesControllerToggleSection', 'toggleSectionDto', toggleSectionDto)
            const localVarPath = `/api/c2-types/toggle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toggleSectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a C2 Type
         * @param {string} id 
         * @param {UpdateC2TypeDto} updateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerUpdate: async (id: string, updateC2TypeDto: UpdateC2TypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2TypesControllerUpdate', 'id', id)
            // verify required parameter 'updateC2TypeDto' is not null or undefined
            assertParamExists('c2TypesControllerUpdate', 'updateC2TypeDto', updateC2TypeDto)
            const localVarPath = `/api/c2-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2TypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update All C2 Types
         * @param {CompositeUpdateC2TypeDto} compositeUpdateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerUpdateAllC2Type: async (compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'compositeUpdateC2TypeDto' is not null or undefined
            assertParamExists('c2TypesControllerUpdateAllC2Type', 'compositeUpdateC2TypeDto', compositeUpdateC2TypeDto)
            const localVarPath = `/api/c2-types/update-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compositeUpdateC2TypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update active state of C2 Type
         * @param {string} id 
         * @param {UpdateC2TypeV2Dto} updateC2TypeV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerUpdateV2: async (id: string, updateC2TypeV2Dto: UpdateC2TypeV2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2TypesControllerUpdateV2', 'id', id)
            // verify required parameter 'updateC2TypeV2Dto' is not null or undefined
            assertParamExists('c2TypesControllerUpdateV2', 'updateC2TypeV2Dto', updateC2TypeV2Dto)
            const localVarPath = `/api/v2/c2-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2TypeV2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2TypeApi - functional programming interface
 * @export
 */
export const C2TypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2TypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a C2 Type
         * @param {CreateC2TypeDto} createC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerCreate(createC2TypeDto: CreateC2TypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Type>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerCreate(createC2TypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a C2 Type V2
         * @param {CreateC2TypeV2Dto} createC2TypeV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerCreateV2(createC2TypeV2Dto: CreateC2TypeV2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Type>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerCreateV2(createC2TypeV2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2 Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2Type>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of c2 types on the basic of date info passed
         * @param {string} date Required field
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType Date type field for which u1 aggregation data will be shown
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerFindAllV2(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2Type>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerFindAllV2(date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of c2 types with query params V3
         * @param {string} [xTenantId] 
         * @param {boolean} [isActive] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerFindAllV3(xTenantId?: string, isActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2Type>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerFindAllV3(xTenantId, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a C2 Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Type>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle section
         * @param {ToggleSectionDto} toggleSectionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerToggleSection(toggleSectionDto: ToggleSectionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Type>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerToggleSection(toggleSectionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a C2 Type
         * @param {string} id 
         * @param {UpdateC2TypeDto} updateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerUpdate(id: string, updateC2TypeDto: UpdateC2TypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Type>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerUpdate(id, updateC2TypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update All C2 Types
         * @param {CompositeUpdateC2TypeDto} compositeUpdateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerUpdateAllC2Type(compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2Type>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerUpdateAllC2Type(compositeUpdateC2TypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update active state of C2 Type
         * @param {string} id 
         * @param {UpdateC2TypeV2Dto} updateC2TypeV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypesControllerUpdateV2(id: string, updateC2TypeV2Dto: UpdateC2TypeV2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2Type>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypesControllerUpdateV2(id, updateC2TypeV2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2TypeApi - factory interface
 * @export
 */
export const C2TypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2TypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a C2 Type
         * @param {CreateC2TypeDto} createC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerCreate(createC2TypeDto: CreateC2TypeDto, xTenantId?: string, options?: any): AxiosPromise<C2Type> {
            return localVarFp.c2TypesControllerCreate(createC2TypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a C2 Type V2
         * @param {CreateC2TypeV2Dto} createC2TypeV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerCreateV2(createC2TypeV2Dto: CreateC2TypeV2Dto, xTenantId?: string, options?: any): AxiosPromise<C2Type> {
            return localVarFp.c2TypesControllerCreateV2(createC2TypeV2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2 Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<C2Type>> {
            return localVarFp.c2TypesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of c2 types on the basic of date info passed
         * @param {string} date Required field
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType Date type field for which u1 aggregation data will be shown
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindAllV2(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<C2Type>> {
            return localVarFp.c2TypesControllerFindAllV2(date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of c2 types with query params V3
         * @param {string} [xTenantId] 
         * @param {boolean} [isActive] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindAllV3(xTenantId?: string, isActive?: boolean, options?: any): AxiosPromise<Array<C2Type>> {
            return localVarFp.c2TypesControllerFindAllV3(xTenantId, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a C2 Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<C2Type> {
            return localVarFp.c2TypesControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle section
         * @param {ToggleSectionDto} toggleSectionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerToggleSection(toggleSectionDto: ToggleSectionDto, xTenantId?: string, options?: any): AxiosPromise<C2Type> {
            return localVarFp.c2TypesControllerToggleSection(toggleSectionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a C2 Type
         * @param {string} id 
         * @param {UpdateC2TypeDto} updateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerUpdate(id: string, updateC2TypeDto: UpdateC2TypeDto, xTenantId?: string, options?: any): AxiosPromise<C2Type> {
            return localVarFp.c2TypesControllerUpdate(id, updateC2TypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update All C2 Types
         * @param {CompositeUpdateC2TypeDto} compositeUpdateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerUpdateAllC2Type(compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto, xTenantId?: string, options?: any): AxiosPromise<Array<C2Type>> {
            return localVarFp.c2TypesControllerUpdateAllC2Type(compositeUpdateC2TypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update active state of C2 Type
         * @param {string} id 
         * @param {UpdateC2TypeV2Dto} updateC2TypeV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypesControllerUpdateV2(id: string, updateC2TypeV2Dto: UpdateC2TypeV2Dto, xTenantId?: string, options?: any): AxiosPromise<C2Type> {
            return localVarFp.c2TypesControllerUpdateV2(id, updateC2TypeV2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2TypesControllerCreate operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerCreateRequest
 */
export interface C2TypeApiC2TypesControllerCreateRequest {
    /**
     * 
     * @type {CreateC2TypeDto}
     * @memberof C2TypeApiC2TypesControllerCreate
     */
    readonly createC2TypeDto: CreateC2TypeDto

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerCreateV2 operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerCreateV2Request
 */
export interface C2TypeApiC2TypesControllerCreateV2Request {
    /**
     * 
     * @type {CreateC2TypeV2Dto}
     * @memberof C2TypeApiC2TypesControllerCreateV2
     */
    readonly createC2TypeV2Dto: CreateC2TypeV2Dto

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerCreateV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerFindAll operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerFindAllRequest
 */
export interface C2TypeApiC2TypesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerFindAllV2 operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerFindAllV2Request
 */
export interface C2TypeApiC2TypesControllerFindAllV2Request {
    /**
     * Required field
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerFindAllV2
     */
    readonly date: string

    /**
     * Date type field for which u1 aggregation data will be shown
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof C2TypeApiC2TypesControllerFindAllV2
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerFindAllV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerFindAllV3 operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerFindAllV3Request
 */
export interface C2TypeApiC2TypesControllerFindAllV3Request {
    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerFindAllV3
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof C2TypeApiC2TypesControllerFindAllV3
     */
    readonly isActive?: boolean
}

/**
 * Request parameters for c2TypesControllerFindOne operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerFindOneRequest
 */
export interface C2TypeApiC2TypesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerToggleSection operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerToggleSectionRequest
 */
export interface C2TypeApiC2TypesControllerToggleSectionRequest {
    /**
     * 
     * @type {ToggleSectionDto}
     * @memberof C2TypeApiC2TypesControllerToggleSection
     */
    readonly toggleSectionDto: ToggleSectionDto

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerToggleSection
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerUpdate operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerUpdateRequest
 */
export interface C2TypeApiC2TypesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2TypeDto}
     * @memberof C2TypeApiC2TypesControllerUpdate
     */
    readonly updateC2TypeDto: UpdateC2TypeDto

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerUpdateAllC2Type operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerUpdateAllC2TypeRequest
 */
export interface C2TypeApiC2TypesControllerUpdateAllC2TypeRequest {
    /**
     * 
     * @type {CompositeUpdateC2TypeDto}
     * @memberof C2TypeApiC2TypesControllerUpdateAllC2Type
     */
    readonly compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerUpdateAllC2Type
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypesControllerUpdateV2 operation in C2TypeApi.
 * @export
 * @interface C2TypeApiC2TypesControllerUpdateV2Request
 */
export interface C2TypeApiC2TypesControllerUpdateV2Request {
    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerUpdateV2
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2TypeV2Dto}
     * @memberof C2TypeApiC2TypesControllerUpdateV2
     */
    readonly updateC2TypeV2Dto: UpdateC2TypeV2Dto

    /**
     * 
     * @type {string}
     * @memberof C2TypeApiC2TypesControllerUpdateV2
     */
    readonly xTenantId?: string
}

/**
 * C2TypeApi - object-oriented interface
 * @export
 * @class C2TypeApi
 * @extends {BaseAPI}
 */
export class C2TypeApi extends BaseAPI {
    /**
     * 
     * @summary Create a C2 Type
     * @param {C2TypeApiC2TypesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerCreate(requestParameters: C2TypeApiC2TypesControllerCreateRequest, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerCreate(requestParameters.createC2TypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a C2 Type V2
     * @param {C2TypeApiC2TypesControllerCreateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerCreateV2(requestParameters: C2TypeApiC2TypesControllerCreateV2Request, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerCreateV2(requestParameters.createC2TypeV2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2 Types
     * @param {C2TypeApiC2TypesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerFindAll(requestParameters: C2TypeApiC2TypesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of c2 types on the basic of date info passed
     * @param {C2TypeApiC2TypesControllerFindAllV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerFindAllV2(requestParameters: C2TypeApiC2TypesControllerFindAllV2Request, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerFindAllV2(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of c2 types with query params V3
     * @param {C2TypeApiC2TypesControllerFindAllV3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerFindAllV3(requestParameters: C2TypeApiC2TypesControllerFindAllV3Request = {}, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerFindAllV3(requestParameters.xTenantId, requestParameters.isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a C2 Type by Id
     * @param {C2TypeApiC2TypesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerFindOne(requestParameters: C2TypeApiC2TypesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle section
     * @param {C2TypeApiC2TypesControllerToggleSectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerToggleSection(requestParameters: C2TypeApiC2TypesControllerToggleSectionRequest, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerToggleSection(requestParameters.toggleSectionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a C2 Type
     * @param {C2TypeApiC2TypesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerUpdate(requestParameters: C2TypeApiC2TypesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerUpdate(requestParameters.id, requestParameters.updateC2TypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update All C2 Types
     * @param {C2TypeApiC2TypesControllerUpdateAllC2TypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerUpdateAllC2Type(requestParameters: C2TypeApiC2TypesControllerUpdateAllC2TypeRequest, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerUpdateAllC2Type(requestParameters.compositeUpdateC2TypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update active state of C2 Type
     * @param {C2TypeApiC2TypesControllerUpdateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeApi
     */
    public c2TypesControllerUpdateV2(requestParameters: C2TypeApiC2TypesControllerUpdateV2Request, options?: AxiosRequestConfig) {
        return C2TypeApiFp(this.configuration).c2TypesControllerUpdateV2(requestParameters.id, requestParameters.updateC2TypeV2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
