import {
  ServerError,
  c1HealthScoreApi,
  c2HealthScoreApi,
  c2SegmentHealthScoreApi,
  c2TypeHealthScoreApi,
  dashboardApi,
} from '@/api';
import {
  C1HealthScore,
  C2HealthScoreZapColorAggregation,
  C2Segment,
  C2SegmentHealthScore,
  C2TypeHealthScore,
  DashboardOverviewDto,
  DashboardSelectionTypeEnum,
  DateInfo,
  DateTypeEnum,
  KpiNameEnum,
  C2TypeDashboardDto,
} from '@/api/client';
import { BarChartOptions, D3ChartOptions, SankeyChartOption, VerticalChartOption } from '@/types/dashboard';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { endOfDay, formatISO, isLastDayOfMonth, lastDayOfMonth, subMonths } from 'date-fns';
import axios, { AxiosError } from 'axios';

export interface IDashFilterOption {
  id: string;
  name: string;
  type: DashboardSelectionTypeEnum;
}

export const useDashboardV2Store = defineStore('dashboardv2', () => {
  const isDashboardInfoLoading = ref<boolean>(false);
  const dashboardInfo = ref<DashboardOverviewDto>();
  const noRecordFound = ref<boolean>(false);
  const isRoleBaseAccessEnabled = ref<boolean>(false);

  const dashboardC2Types = ref<C2TypeDashboardDto[]>([]);
  const dashboardC2Segments = ref<C2Segment[]>([]);

  const c2ZapscoreColorAggregationListLoading = ref<boolean>(false);
  const c2ZapscoreColorAggregationList = ref<C2HealthScoreZapColorAggregation[]>([]);

  const dashboardFor = ref<IDashFilterOption>();

  const isHealthScoreListLoading = ref<boolean>(false);
  const healthScoreList = ref<C1HealthScore[] | C2TypeHealthScore[] | C2SegmentHealthScore[]>([]);
  const isMovementTrendListLoading = ref<boolean>(false);
  const movementTrendList = ref<C1HealthScore[] | C2TypeHealthScore[] | C2SegmentHealthScore[]>([]);

  const areaChartFor = ref<string>(KpiNameEnum.Zs);
  const barChartFor = ref<string>(BarChartOptions.customer);
  const d3ChartFor = ref<string>(D3ChartOptions.all);
  const sankeyFor = ref<string>(SankeyChartOption.customer);
  const verticalChartFor = ref<string>(VerticalChartOption.customer);

  const showFinancialData = ref<boolean>(false);

  const noAggregationRecordFound = ref<boolean>(false);

  async function syncDashboardMetadata(dateInfo: DateInfo) {
    isDashboardInfoLoading.value = true;
    isHealthScoreListLoading.value = true;
    isMovementTrendListLoading.value = true;
    noRecordFound.value = false;
    noAggregationRecordFound.value = false;
    try {
      const { data } = await dashboardApi.dashboardControllerFindConfiguration({
        date: dateInfo.date,
        dateType: dateInfo.type,
        isRoleBaseEnabled: isRoleBaseAccessEnabled.value,
        selectedId: dashboardFor.value?.id || undefined,
        selectionType: dashboardFor.value?.type || undefined,
        selectedName: dashboardFor.value?.name || undefined,
      });

      isRoleBaseAccessEnabled.value = data.is_role_base_enabled;
      dashboardFor.value = {
        id: data.selection_id,
        type: data.selection_type,
        name: data.selection_name,
      };
      dashboardC2Segments.value = data.c2_segments || [];

      if (data.show_customer_types) {
        dashboardC2Types.value = data.c2_types || [];
      } else {
        dashboardC2Types.value = [];
      }
    } catch (e) {
      console.error('e', e);
    }
  }

  /**  Fetch to see overview, sankey and table information and part of d3 chart; */
  async function fetchDashboardInfo(dateInfo: DateInfo) {
    if (!dashboardFor.value) {
      return;
    }
    noRecordFound.value = false;
    isDashboardInfoLoading.value = true;
    dashboardInfo.value = undefined;
    noAggregationRecordFound.value = false;
    try {
      if (dashboardFor.value.id === '-' && dashboardFor.value.type === DashboardSelectionTypeEnum.C1health) {
        const { data } = await c1HealthScoreApi.c1HealthScoresControllerFindOneByDateInfo({
          date: dateInfo.date,
          dateType: dateInfo.type,
        });
        dashboardInfo.value = data.data;
        showFinancialData.value = data.show_financial_data;
      } else if (dashboardFor.value.type === DashboardSelectionTypeEnum.C2typehealth) {
        const { data } = await c2TypeHealthScoreApi.c2TypeHealthScoreControllerFindByTypeDateInfo({
          date: dateInfo.date,
          dateType: dateInfo.type,
          c2TypeId: dashboardFor.value.id,
        });
        dashboardInfo.value = data.data;
        showFinancialData.value = data.show_financial_data;
      } else if (dashboardFor.value.type === DashboardSelectionTypeEnum.C2segmenthealth) {
        const { data } = await c2SegmentHealthScoreApi.c2SegmentHealthScoreControllerFindBySegmentDateInfo({
          date: dateInfo.date,
          dateType: dateInfo.type,
          segmentId: dashboardFor.value.id,
        });
        dashboardInfo.value = data.data;
        showFinancialData.value = data.show_financial_data;
      }
      noRecordFound.value = false;
    } catch (e) {
      noRecordFound.value = true;
      if (axios.isAxiosError(e)) {
        const err = e as AxiosError<ServerError>;
        if (err.response?.status === 404) {
          noAggregationRecordFound.value = true;
        } else {
          noAggregationRecordFound.value = false;
        }
      }
      console.log('e', e);
    } finally {
      isDashboardInfoLoading.value = false;
    }
  }

  /**  Fetch to see Area chart, bar chart data; */
  async function fetchHealthScoreList(dateInfo: DateInfo) {
    if (!dashboardFor.value) {
      return;
    }
    healthScoreList.value = [];
    isHealthScoreListLoading.value = true;
    isMovementTrendListLoading.value = true;
    try {
      if (dashboardFor.value.id === '-' && dashboardFor.value.type === DashboardSelectionTypeEnum.C1health) {
        const { data } = await c1HealthScoreApi.c1HealthScoresControllerFindAllByDateRange({
          date: dateInfo.date,
          dateType: dateInfo.type,
        });
        healthScoreList.value = data;
      } else if (dashboardFor.value.type === DashboardSelectionTypeEnum.C2typehealth) {
        // fetch c2 type healthscore list
        const { data } = await c2TypeHealthScoreApi.c2TypeHealthScoreControllerFindAllByDateRange({
          dateType: dateInfo.type,
          date: dateInfo.date,
          c2TypeId: dashboardFor.value.id,
        });
        healthScoreList.value = data;
      } else if (dashboardFor.value.type === DashboardSelectionTypeEnum.C2segmenthealth) {
        const { data } = await c2SegmentHealthScoreApi.c2SegmentHealthScoreControllerFindAllByDateRange({
          date: dateInfo.date,
          dateType: dateInfo.type,
          segmentId: dashboardFor.value.id,
        });
        healthScoreList.value = data;
      }
    } catch (e) {
      console.error(e);
    } finally {
      isHealthScoreListLoading.value = false;
      fetchMovementTrendListForDayWeek(dateInfo);
    }
  }

  /**  Fetch to see revenue and churn chart data;*/
  async function fetchMovementTrendListForDayWeek(dateInfo: DateInfo) {
    if (!dashboardFor.value) {
      return;
    }
    if (dateInfo.type !== DateTypeEnum.Day && dateInfo.type !== DateTypeEnum.Week) {
      movementTrendList.value = healthScoreList.value;
      isMovementTrendListLoading.value = false;
      return;
    }
    movementTrendList.value = [];
    isMovementTrendListLoading.value = true;
    const dateType = DateTypeEnum.Month;
    let date = dateInfo.date;
    const isLastDayOfMonths = isLastDayOfMonth(new Date(dateInfo.date));
    if (!isLastDayOfMonths) {
      const prevMonth = subMonths(new Date(dateInfo.date), 1);
      let prevMonthLastDayOfMonth = lastDayOfMonth(prevMonth);
      prevMonthLastDayOfMonth = endOfDay(prevMonthLastDayOfMonth);
      date = formatISO(prevMonthLastDayOfMonth);
    }
    try {
      if (dashboardFor.value.id === '-' && dashboardFor.value.type === DashboardSelectionTypeEnum.C1health) {
        const { data } = await c1HealthScoreApi.c1HealthScoresControllerFindAllByDateRange({ date, dateType });
        movementTrendList.value = data;
      } else if (dashboardFor.value.type === DashboardSelectionTypeEnum.C2typehealth) {
        const { data } = await c2TypeHealthScoreApi.c2TypeHealthScoreControllerFindAllByDateRange({
          dateType,
          date,
          c2TypeId: dashboardFor.value.id,
        });
        movementTrendList.value = data;
      } else if (dashboardFor.value.type === DashboardSelectionTypeEnum.C2segmenthealth) {
        const { data } = await c2SegmentHealthScoreApi.c2SegmentHealthScoreControllerFindAllByDateRange({
          date,
          dateType,
          segmentId: dashboardFor.value.id,
        });
        movementTrendList.value = data;
      }
    } catch (e) {
      console.error('customer movement trend c1 health score list', e);
    } finally {
      isMovementTrendListLoading.value = false;
    }
  }

  /**  Fetch to navigate to customer list and user list screen from d3 chart*/
  async function fetchC2HealthScoreList(dateInfo: DateInfo) {
    if (!dashboardFor.value) {
      return;
    }
    try {
      c2ZapscoreColorAggregationListLoading.value = true;
      const { data } = await c2HealthScoreApi.c2HealthScoreControllerFindAllZapscoreColorAggregationForDate({
        type: dateInfo.type,
        dateUtc: dateInfo.dateUtc,
        date: dateInfo.date,
        c2TypeId:
          dashboardFor.value.type === DashboardSelectionTypeEnum.C2typehealth ? dashboardFor.value.id : undefined,
        segmentId:
          dashboardFor.value.type === DashboardSelectionTypeEnum.C2segmenthealth ? dashboardFor.value.id : undefined,
      });
      c2ZapscoreColorAggregationList.value = data;
    } catch (e) {
      console.error('fetch c2 health score', e);
    } finally {
      c2ZapscoreColorAggregationListLoading.value = false;
    }
  }

  return {
    dashboardFor,
    // c2Types,
    dashboardC2Segments,
    dashboardC2Types,
    noRecordFound,
    isDashboardInfoLoading,
    dashboardInfo,
    // fetchDateWiseCustomerType,
    fetchDashboardInfo,
    fetchHealthScoreList,
    fetchC2HealthScoreList,
    isHealthScoreListLoading,
    healthScoreList,
    isMovementTrendListLoading,
    movementTrendList,
    areaChartFor,
    barChartFor,
    d3ChartFor,
    sankeyFor,
    verticalChartFor,
    c2ZapscoreColorAggregationListLoading,
    syncDashboardMetadata,
    c2ZapscoreColorAggregationList,
    // showC2TypeFilter,
    showFinancialData,
    noAggregationRecordFound,
  };
});
