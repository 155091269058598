/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AllC2FeatureDataFilterDto } from '../model/';
// @ts-ignore
import { C2FeatureDataDto } from '../model/';
// @ts-ignore
import { C2FeatureDataFilterDto } from '../model/';
// @ts-ignore
import { DateTypeEnum } from '../model/';
/**
 * C2FeatureDataApi - axios parameter creator
 * @export
 */
export const C2FeatureDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all feature data against feature and date id
         * @param {C2FeatureDataFilterDto} c2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FeatureDataControllerFindAllFeatureData: async (c2FeatureDataFilterDto: C2FeatureDataFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2FeatureDataFilterDto' is not null or undefined
            assertParamExists('c2FeatureDataControllerFindAllFeatureData', 'c2FeatureDataFilterDto', c2FeatureDataFilterDto)
            const localVarPath = `/api/c2-feature-data/history-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2FeatureDataFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All Product feature usage log by c2 type or segment in dashboard
         * @param {DateTypeEnum} type 
         * @param {string} dateUtc 
         * @param {string} date 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {string} [segmentId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FeatureDataControllerFindAllProductFeatureForDashboard: async (type: DateTypeEnum, dateUtc: string, date: string, xTenantId?: string, c2TypeId?: string, segmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('c2FeatureDataControllerFindAllProductFeatureForDashboard', 'type', type)
            // verify required parameter 'dateUtc' is not null or undefined
            assertParamExists('c2FeatureDataControllerFindAllProductFeatureForDashboard', 'dateUtc', dateUtc)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2FeatureDataControllerFindAllProductFeatureForDashboard', 'date', date)
            const localVarPath = `/api/c2-feature-data/dashboard-feature-usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dateUtc !== undefined) {
                localVarQueryParameter['dateUtc'] = (dateUtc as any instanceof Date) ?
                    (dateUtc as any).toISOString() :
                    dateUtc;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2_type_id'] = c2TypeId;
            }

            if (segmentId !== undefined) {
                localVarQueryParameter['segment_id'] = segmentId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all feature usage data for dashboard
         * @param {AllC2FeatureDataFilterDto} allC2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FeatureDataControllerFindAllProductFeatureUsage: async (allC2FeatureDataFilterDto: AllC2FeatureDataFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allC2FeatureDataFilterDto' is not null or undefined
            assertParamExists('c2FeatureDataControllerFindAllProductFeatureUsage', 'allC2FeatureDataFilterDto', allC2FeatureDataFilterDto)
            const localVarPath = `/api/c2-feature-data/history-all-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allC2FeatureDataFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2FeatureDataApi - functional programming interface
 * @export
 */
export const C2FeatureDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2FeatureDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of all feature data against feature and date id
         * @param {C2FeatureDataFilterDto} c2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2FeatureDataControllerFindAllFeatureData(c2FeatureDataFilterDto: C2FeatureDataFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2FeatureDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2FeatureDataControllerFindAllFeatureData(c2FeatureDataFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary All Product feature usage log by c2 type or segment in dashboard
         * @param {DateTypeEnum} type 
         * @param {string} dateUtc 
         * @param {string} date 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {string} [segmentId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2FeatureDataControllerFindAllProductFeatureForDashboard(type: DateTypeEnum, dateUtc: string, date: string, xTenantId?: string, c2TypeId?: string, segmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2FeatureDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2FeatureDataControllerFindAllProductFeatureForDashboard(type, dateUtc, date, xTenantId, c2TypeId, segmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all feature usage data for dashboard
         * @param {AllC2FeatureDataFilterDto} allC2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2FeatureDataControllerFindAllProductFeatureUsage(allC2FeatureDataFilterDto: AllC2FeatureDataFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2FeatureDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2FeatureDataControllerFindAllProductFeatureUsage(allC2FeatureDataFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2FeatureDataApi - factory interface
 * @export
 */
export const C2FeatureDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2FeatureDataApiFp(configuration)
    return {
        /**
         * 
         * @summary List of all feature data against feature and date id
         * @param {C2FeatureDataFilterDto} c2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FeatureDataControllerFindAllFeatureData(c2FeatureDataFilterDto: C2FeatureDataFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<C2FeatureDataDto> {
            return localVarFp.c2FeatureDataControllerFindAllFeatureData(c2FeatureDataFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All Product feature usage log by c2 type or segment in dashboard
         * @param {DateTypeEnum} type 
         * @param {string} dateUtc 
         * @param {string} date 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {string} [segmentId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FeatureDataControllerFindAllProductFeatureForDashboard(type: DateTypeEnum, dateUtc: string, date: string, xTenantId?: string, c2TypeId?: string, segmentId?: string, options?: any): AxiosPromise<C2FeatureDataDto> {
            return localVarFp.c2FeatureDataControllerFindAllProductFeatureForDashboard(type, dateUtc, date, xTenantId, c2TypeId, segmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all feature usage data for dashboard
         * @param {AllC2FeatureDataFilterDto} allC2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FeatureDataControllerFindAllProductFeatureUsage(allC2FeatureDataFilterDto: AllC2FeatureDataFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<C2FeatureDataDto> {
            return localVarFp.c2FeatureDataControllerFindAllProductFeatureUsage(allC2FeatureDataFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2FeatureDataControllerFindAllFeatureData operation in C2FeatureDataApi.
 * @export
 * @interface C2FeatureDataApiC2FeatureDataControllerFindAllFeatureDataRequest
 */
export interface C2FeatureDataApiC2FeatureDataControllerFindAllFeatureDataRequest {
    /**
     * 
     * @type {C2FeatureDataFilterDto}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly c2FeatureDataFilterDto: C2FeatureDataFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllFeatureData
     */
    readonly after?: string
}

/**
 * Request parameters for c2FeatureDataControllerFindAllProductFeatureForDashboard operation in C2FeatureDataApi.
 * @export
 * @interface C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboardRequest
 */
export interface C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboardRequest {
    /**
     * 
     * @type {DateTypeEnum}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboard
     */
    readonly type: DateTypeEnum

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboard
     */
    readonly dateUtc: string

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboard
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboard
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboard
     */
    readonly c2TypeId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboard
     */
    readonly segmentId?: string
}

/**
 * Request parameters for c2FeatureDataControllerFindAllProductFeatureUsage operation in C2FeatureDataApi.
 * @export
 * @interface C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsageRequest
 */
export interface C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsageRequest {
    /**
     * 
     * @type {AllC2FeatureDataFilterDto}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly allC2FeatureDataFilterDto: AllC2FeatureDataFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsage
     */
    readonly after?: string
}

/**
 * C2FeatureDataApi - object-oriented interface
 * @export
 * @class C2FeatureDataApi
 * @extends {BaseAPI}
 */
export class C2FeatureDataApi extends BaseAPI {
    /**
     * 
     * @summary List of all feature data against feature and date id
     * @param {C2FeatureDataApiC2FeatureDataControllerFindAllFeatureDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2FeatureDataApi
     */
    public c2FeatureDataControllerFindAllFeatureData(requestParameters: C2FeatureDataApiC2FeatureDataControllerFindAllFeatureDataRequest, options?: AxiosRequestConfig) {
        return C2FeatureDataApiFp(this.configuration).c2FeatureDataControllerFindAllFeatureData(requestParameters.c2FeatureDataFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All Product feature usage log by c2 type or segment in dashboard
     * @param {C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2FeatureDataApi
     */
    public c2FeatureDataControllerFindAllProductFeatureForDashboard(requestParameters: C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureForDashboardRequest, options?: AxiosRequestConfig) {
        return C2FeatureDataApiFp(this.configuration).c2FeatureDataControllerFindAllProductFeatureForDashboard(requestParameters.type, requestParameters.dateUtc, requestParameters.date, requestParameters.xTenantId, requestParameters.c2TypeId, requestParameters.segmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all feature usage data for dashboard
     * @param {C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2FeatureDataApi
     */
    public c2FeatureDataControllerFindAllProductFeatureUsage(requestParameters: C2FeatureDataApiC2FeatureDataControllerFindAllProductFeatureUsageRequest, options?: AxiosRequestConfig) {
        return C2FeatureDataApiFp(this.configuration).c2FeatureDataControllerFindAllProductFeatureUsage(requestParameters.allC2FeatureDataFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }
}
