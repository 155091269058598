/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeleteU2Dto } from '../model/';
// @ts-ignore
import { PaginatedU2Dto } from '../model/';
// @ts-ignore
import { ProjectedU2Dto } from '../model/';
// @ts-ignore
import { U2 } from '../model/';
// @ts-ignore
import { U2AppIdListDto } from '../model/';
// @ts-ignore
import { UpdateU2Dto } from '../model/';
/**
 * U2Api - axios parameter creator
 * @export
 */
export const U2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user using id
         * @param {DeleteU2Dto} deleteU2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerDeleteUser: async (deleteU2Dto: DeleteU2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteU2Dto' is not null or undefined
            assertParamExists('u2ControllerDeleteUser', 'deleteU2Dto', deleteU2Dto)
            const localVarPath = `/api/u2/delete-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteU2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U2 for current segment
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [u2Ids] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {string} [u2AppId] Optional field
         * @param {Array<string>} [u2AppIds] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAll: async (xTenantId?: string, name?: string, u2Ids?: Array<string>, c2AppIds?: Array<string>, u2AppId?: string, u2AppIds?: Array<string>, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (u2Ids) {
                localVarQueryParameter['u2_ids'] = u2Ids;
            }

            if (c2AppIds) {
                localVarQueryParameter['c2_app_ids'] = c2AppIds;
            }

            if (u2AppId !== undefined) {
                localVarQueryParameter['u2_app_id'] = u2AppId;
            }

            if (u2AppIds) {
                localVarQueryParameter['u2_app_ids'] = u2AppIds;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of u2s with all information
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllU2s: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u2/all-u2s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find List of U2s from U2 App Id for Throttle
         * @param {U2AppIdListDto} u2AppIdListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllU2sForThrottle: async (u2AppIdListDto: U2AppIdListDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2AppIdListDto' is not null or undefined
            assertParamExists('u2ControllerFindAllU2sForThrottle', 'u2AppIdListDto', u2AppIdListDto)
            const localVarPath = `/api/u2/u2-list-for-throttle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(u2AppIdListDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saas app user list
         * @param {string} u2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllUsersForCSV: async (u2TypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2TypeId' is not null or undefined
            assertParamExists('u2ControllerFindAllUsersForCSV', 'u2TypeId', u2TypeId)
            const localVarPath = `/api/u2/saas-app-user-list-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (u2TypeId !== undefined) {
                localVarQueryParameter['u2TypeId'] = u2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U2
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [u2Ids] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {string} [u2AppId] Optional field
         * @param {Array<string>} [u2AppIds] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllV1: async (xTenantId?: string, name?: string, u2Ids?: Array<string>, c2AppIds?: Array<string>, u2AppId?: string, u2AppIds?: Array<string>, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/u2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (u2Ids) {
                localVarQueryParameter['u2_ids'] = u2Ids;
            }

            if (c2AppIds) {
                localVarQueryParameter['c2_app_ids'] = c2AppIds;
            }

            if (u2AppId !== undefined) {
                localVarQueryParameter['u2_app_id'] = u2AppId;
            }

            if (u2AppIds) {
                localVarQueryParameter['u2_app_ids'] = u2AppIds;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated List of U2
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllWithPagination: async (xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u2/paginated-u2-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find U2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2ControllerFindOne', 'id', id)
            const localVarPath = `/api/u2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find total count of users
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindTotalCount: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u2/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete U2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2ControllerRemove', 'id', id)
            const localVarPath = `/api/u2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update U2
         * @param {string} id 
         * @param {UpdateU2Dto} updateU2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerUpdate: async (id: string, updateU2Dto: UpdateU2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2ControllerUpdate', 'id', id)
            // verify required parameter 'updateU2Dto' is not null or undefined
            assertParamExists('u2ControllerUpdate', 'updateU2Dto', updateU2Dto)
            const localVarPath = `/api/u2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateU2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2Api - functional programming interface
 * @export
 */
export const U2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete user using id
         * @param {DeleteU2Dto} deleteU2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerDeleteUser(deleteU2Dto: DeleteU2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerDeleteUser(deleteU2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U2 for current segment
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [u2Ids] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {string} [u2AppId] Optional field
         * @param {Array<string>} [u2AppIds] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindAll(xTenantId?: string, name?: string, u2Ids?: Array<string>, c2AppIds?: Array<string>, u2AppId?: string, u2AppIds?: Array<string>, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectedU2Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindAll(xTenantId, name, u2Ids, c2AppIds, u2AppId, u2AppIds, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of u2s with all information
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindAllU2s(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindAllU2s(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find List of U2s from U2 App Id for Throttle
         * @param {U2AppIdListDto} u2AppIdListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindAllU2sForThrottle(u2AppIdListDto: U2AppIdListDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindAllU2sForThrottle(u2AppIdListDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saas app user list
         * @param {string} u2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindAllUsersForCSV(u2TypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindAllUsersForCSV(u2TypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U2
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [u2Ids] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {string} [u2AppId] Optional field
         * @param {Array<string>} [u2AppIds] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindAllV1(xTenantId?: string, name?: string, u2Ids?: Array<string>, c2AppIds?: Array<string>, u2AppId?: string, u2AppIds?: Array<string>, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectedU2Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindAllV1(xTenantId, name, u2Ids, c2AppIds, u2AppId, u2AppIds, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated List of U2
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedU2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find U2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find total count of users
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerFindTotalCount(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerFindTotalCount(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete U2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update U2
         * @param {string} id 
         * @param {UpdateU2Dto} updateU2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ControllerUpdate(id: string, updateU2Dto: UpdateU2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ControllerUpdate(id, updateU2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2Api - factory interface
 * @export
 */
export const U2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete user using id
         * @param {DeleteU2Dto} deleteU2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerDeleteUser(deleteU2Dto: DeleteU2Dto, xTenantId?: string, options?: any): AxiosPromise<U2> {
            return localVarFp.u2ControllerDeleteUser(deleteU2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U2 for current segment
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [u2Ids] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {string} [u2AppId] Optional field
         * @param {Array<string>} [u2AppIds] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAll(xTenantId?: string, name?: string, u2Ids?: Array<string>, c2AppIds?: Array<string>, u2AppId?: string, u2AppIds?: Array<string>, date?: string, options?: any): AxiosPromise<Array<ProjectedU2Dto>> {
            return localVarFp.u2ControllerFindAll(xTenantId, name, u2Ids, c2AppIds, u2AppId, u2AppIds, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of u2s with all information
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllU2s(xTenantId?: string, options?: any): AxiosPromise<Array<U2>> {
            return localVarFp.u2ControllerFindAllU2s(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find List of U2s from U2 App Id for Throttle
         * @param {U2AppIdListDto} u2AppIdListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllU2sForThrottle(u2AppIdListDto: U2AppIdListDto, xTenantId?: string, options?: any): AxiosPromise<Array<U2>> {
            return localVarFp.u2ControllerFindAllU2sForThrottle(u2AppIdListDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saas app user list
         * @param {string} u2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllUsersForCSV(u2TypeId: string, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.u2ControllerFindAllUsersForCSV(u2TypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U2
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [u2Ids] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {string} [u2AppId] Optional field
         * @param {Array<string>} [u2AppIds] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllV1(xTenantId?: string, name?: string, u2Ids?: Array<string>, c2AppIds?: Array<string>, u2AppId?: string, u2AppIds?: Array<string>, date?: string, options?: any): AxiosPromise<Array<ProjectedU2Dto>> {
            return localVarFp.u2ControllerFindAllV1(xTenantId, name, u2Ids, c2AppIds, u2AppId, u2AppIds, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated List of U2
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedU2Dto> {
            return localVarFp.u2ControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find U2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<U2> {
            return localVarFp.u2ControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find total count of users
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerFindTotalCount(xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.u2ControllerFindTotalCount(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete U2
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<U2> {
            return localVarFp.u2ControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update U2
         * @param {string} id 
         * @param {UpdateU2Dto} updateU2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ControllerUpdate(id: string, updateU2Dto: UpdateU2Dto, xTenantId?: string, options?: any): AxiosPromise<U2> {
            return localVarFp.u2ControllerUpdate(id, updateU2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2ControllerDeleteUser operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerDeleteUserRequest
 */
export interface U2ApiU2ControllerDeleteUserRequest {
    /**
     * 
     * @type {DeleteU2Dto}
     * @memberof U2ApiU2ControllerDeleteUser
     */
    readonly deleteU2Dto: DeleteU2Dto

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerDeleteUser
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ControllerFindAll operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindAllRequest
 */
export interface U2ApiU2ControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof U2ApiU2ControllerFindAll
     */
    readonly name?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof U2ApiU2ControllerFindAll
     */
    readonly u2Ids?: Array<string>

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof U2ApiU2ControllerFindAll
     */
    readonly c2AppIds?: Array<string>

    /**
     * Optional field
     * @type {string}
     * @memberof U2ApiU2ControllerFindAll
     */
    readonly u2AppId?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof U2ApiU2ControllerFindAll
     */
    readonly u2AppIds?: Array<string>

    /**
     * Optional date utc field, if provided list will be displayed for that date
     * @type {string}
     * @memberof U2ApiU2ControllerFindAll
     */
    readonly date?: string
}

/**
 * Request parameters for u2ControllerFindAllU2s operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindAllU2sRequest
 */
export interface U2ApiU2ControllerFindAllU2sRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllU2s
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ControllerFindAllU2sForThrottle operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindAllU2sForThrottleRequest
 */
export interface U2ApiU2ControllerFindAllU2sForThrottleRequest {
    /**
     * 
     * @type {U2AppIdListDto}
     * @memberof U2ApiU2ControllerFindAllU2sForThrottle
     */
    readonly u2AppIdListDto: U2AppIdListDto

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllU2sForThrottle
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ControllerFindAllUsersForCSV operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindAllUsersForCSVRequest
 */
export interface U2ApiU2ControllerFindAllUsersForCSVRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllUsersForCSV
     */
    readonly u2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllUsersForCSV
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ControllerFindAllV1 operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindAllV1Request
 */
export interface U2ApiU2ControllerFindAllV1Request {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllV1
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllV1
     */
    readonly name?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof U2ApiU2ControllerFindAllV1
     */
    readonly u2Ids?: Array<string>

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof U2ApiU2ControllerFindAllV1
     */
    readonly c2AppIds?: Array<string>

    /**
     * Optional field
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllV1
     */
    readonly u2AppId?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof U2ApiU2ControllerFindAllV1
     */
    readonly u2AppIds?: Array<string>

    /**
     * Optional date utc field, if provided list will be displayed for that date
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllV1
     */
    readonly date?: string
}

/**
 * Request parameters for u2ControllerFindAllWithPagination operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindAllWithPaginationRequest
 */
export interface U2ApiU2ControllerFindAllWithPaginationRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllWithPagination
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof U2ApiU2ControllerFindAllWithPagination
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof U2ApiU2ControllerFindAllWithPagination
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllWithPagination
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof U2ApiU2ControllerFindAllWithPagination
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllWithPagination
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindAllWithPagination
     */
    readonly after?: string
}

/**
 * Request parameters for u2ControllerFindOne operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindOneRequest
 */
export interface U2ApiU2ControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ControllerFindTotalCount operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerFindTotalCountRequest
 */
export interface U2ApiU2ControllerFindTotalCountRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerFindTotalCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ControllerRemove operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerRemoveRequest
 */
export interface U2ApiU2ControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ControllerUpdate operation in U2Api.
 * @export
 * @interface U2ApiU2ControllerUpdateRequest
 */
export interface U2ApiU2ControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateU2Dto}
     * @memberof U2ApiU2ControllerUpdate
     */
    readonly updateU2Dto: UpdateU2Dto

    /**
     * 
     * @type {string}
     * @memberof U2ApiU2ControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * U2Api - object-oriented interface
 * @export
 * @class U2Api
 * @extends {BaseAPI}
 */
export class U2Api extends BaseAPI {
    /**
     * 
     * @summary Delete user using id
     * @param {U2ApiU2ControllerDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerDeleteUser(requestParameters: U2ApiU2ControllerDeleteUserRequest, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerDeleteUser(requestParameters.deleteU2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U2 for current segment
     * @param {U2ApiU2ControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindAll(requestParameters: U2ApiU2ControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindAll(requestParameters.xTenantId, requestParameters.name, requestParameters.u2Ids, requestParameters.c2AppIds, requestParameters.u2AppId, requestParameters.u2AppIds, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of u2s with all information
     * @param {U2ApiU2ControllerFindAllU2sRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindAllU2s(requestParameters: U2ApiU2ControllerFindAllU2sRequest = {}, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindAllU2s(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find List of U2s from U2 App Id for Throttle
     * @param {U2ApiU2ControllerFindAllU2sForThrottleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindAllU2sForThrottle(requestParameters: U2ApiU2ControllerFindAllU2sForThrottleRequest, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindAllU2sForThrottle(requestParameters.u2AppIdListDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saas app user list
     * @param {U2ApiU2ControllerFindAllUsersForCSVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindAllUsersForCSV(requestParameters: U2ApiU2ControllerFindAllUsersForCSVRequest, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindAllUsersForCSV(requestParameters.u2TypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U2
     * @param {U2ApiU2ControllerFindAllV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindAllV1(requestParameters: U2ApiU2ControllerFindAllV1Request = {}, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindAllV1(requestParameters.xTenantId, requestParameters.name, requestParameters.u2Ids, requestParameters.c2AppIds, requestParameters.u2AppId, requestParameters.u2AppIds, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated List of U2
     * @param {U2ApiU2ControllerFindAllWithPaginationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindAllWithPagination(requestParameters: U2ApiU2ControllerFindAllWithPaginationRequest = {}, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindAllWithPagination(requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find U2
     * @param {U2ApiU2ControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindOne(requestParameters: U2ApiU2ControllerFindOneRequest, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find total count of users
     * @param {U2ApiU2ControllerFindTotalCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerFindTotalCount(requestParameters: U2ApiU2ControllerFindTotalCountRequest = {}, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerFindTotalCount(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete U2
     * @param {U2ApiU2ControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerRemove(requestParameters: U2ApiU2ControllerRemoveRequest, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update U2
     * @param {U2ApiU2ControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2Api
     */
    public u2ControllerUpdate(requestParameters: U2ApiU2ControllerUpdateRequest, options?: AxiosRequestConfig) {
        return U2ApiFp(this.configuration).u2ControllerUpdate(requestParameters.id, requestParameters.updateU2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
