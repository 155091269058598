/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptHelpRequestResponseDto } from '../model/';
// @ts-ignore
import { CreateHelpRequestDto } from '../model/';
/**
 * HelpRequestApi - axios parameter creator
 * @export
 */
export const HelpRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHelpRequestDto} createHelpRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpRequestsControllerCreate: async (createHelpRequestDto: CreateHelpRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHelpRequestDto' is not null or undefined
            assertParamExists('helpRequestsControllerCreate', 'createHelpRequestDto', createHelpRequestDto)
            const localVarPath = `/api/help-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHelpRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpRequestsControllerUpdate: async (token: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('helpRequestsControllerUpdate', 'token', token)
            const localVarPath = `/api/help-requests/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpRequestApi - functional programming interface
 * @export
 */
export const HelpRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHelpRequestDto} createHelpRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpRequestsControllerCreate(createHelpRequestDto: CreateHelpRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpRequestsControllerCreate(createHelpRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpRequestsControllerUpdate(token: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptHelpRequestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpRequestsControllerUpdate(token, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpRequestApi - factory interface
 * @export
 */
export const HelpRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHelpRequestDto} createHelpRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpRequestsControllerCreate(createHelpRequestDto: CreateHelpRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.helpRequestsControllerCreate(createHelpRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpRequestsControllerUpdate(token: string, xTenantId?: string, options?: any): AxiosPromise<AcceptHelpRequestResponseDto> {
            return localVarFp.helpRequestsControllerUpdate(token, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for helpRequestsControllerCreate operation in HelpRequestApi.
 * @export
 * @interface HelpRequestApiHelpRequestsControllerCreateRequest
 */
export interface HelpRequestApiHelpRequestsControllerCreateRequest {
    /**
     * 
     * @type {CreateHelpRequestDto}
     * @memberof HelpRequestApiHelpRequestsControllerCreate
     */
    readonly createHelpRequestDto: CreateHelpRequestDto
}

/**
 * Request parameters for helpRequestsControllerUpdate operation in HelpRequestApi.
 * @export
 * @interface HelpRequestApiHelpRequestsControllerUpdateRequest
 */
export interface HelpRequestApiHelpRequestsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof HelpRequestApiHelpRequestsControllerUpdate
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof HelpRequestApiHelpRequestsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * HelpRequestApi - object-oriented interface
 * @export
 * @class HelpRequestApi
 * @extends {BaseAPI}
 */
export class HelpRequestApi extends BaseAPI {
    /**
     * 
     * @param {HelpRequestApiHelpRequestsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpRequestApi
     */
    public helpRequestsControllerCreate(requestParameters: HelpRequestApiHelpRequestsControllerCreateRequest, options?: AxiosRequestConfig) {
        return HelpRequestApiFp(this.configuration).helpRequestsControllerCreate(requestParameters.createHelpRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HelpRequestApiHelpRequestsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpRequestApi
     */
    public helpRequestsControllerUpdate(requestParameters: HelpRequestApiHelpRequestsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return HelpRequestApiFp(this.configuration).helpRequestsControllerUpdate(requestParameters.token, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
