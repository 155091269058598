/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PlaybookAudience } from '../model/';
/**
 * PlaybookAudienceApi - axios parameter creator
 * @export
 */
export const PlaybookAudienceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of playbook audience
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookAudienceControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-audience`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookAudienceApi - functional programming interface
 * @export
 */
export const PlaybookAudienceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookAudienceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of playbook audience
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookAudienceControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookAudience>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookAudienceControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookAudienceApi - factory interface
 * @export
 */
export const PlaybookAudienceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookAudienceApiFp(configuration)
    return {
        /**
         * 
         * @summary List of playbook audience
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookAudienceControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<PlaybookAudience>> {
            return localVarFp.playbookAudienceControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookAudienceControllerFindAll operation in PlaybookAudienceApi.
 * @export
 * @interface PlaybookAudienceApiPlaybookAudienceControllerFindAllRequest
 */
export interface PlaybookAudienceApiPlaybookAudienceControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookAudienceApiPlaybookAudienceControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * PlaybookAudienceApi - object-oriented interface
 * @export
 * @class PlaybookAudienceApi
 * @extends {BaseAPI}
 */
export class PlaybookAudienceApi extends BaseAPI {
    /**
     * 
     * @summary List of playbook audience
     * @param {PlaybookAudienceApiPlaybookAudienceControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookAudienceApi
     */
    public playbookAudienceControllerFindAll(requestParameters: PlaybookAudienceApiPlaybookAudienceControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookAudienceApiFp(this.configuration).playbookAudienceControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
