<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import PillToggle from '../PillToggle.vue';
import { Chart, ChartData, ChartTypeRegistry } from 'chart.js';
import { useMovementTrendChart } from '@/composables/useMovementTrendChart';
import { useDashboardStore } from '@/stores/dashboard';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useI18n } from 'vue-i18n';
import { MessageSchema } from '@/lib/i18n';
import LoadingIndicator from '../LoadingIndicator.vue';
import { useAmountFormatter } from '@/composables/useAmountFormatter';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const props = defineProps<{ dataSet: ChartData<'bar'>; isLoading: boolean }>();
const movementTrendChartData = useMovementTrendChart();
const dashboardStore = useDashboardStore();
const amountFormatter = useAmountFormatter();

const canvas = ref<HTMLCanvasElement>();

let chartInstance: Chart<keyof ChartTypeRegistry, number[], unknown>;
const dataSets = {} as ChartData<'bar'>;
const chartOpacityLevel = ref<string>('tw-opacity-40');

watch(
  () => props.isLoading,
  (isLoading) => {
    if (isLoading) {
      chartOpacityLevel.value = 'tw-opacity-40';
    } else {
      chartOpacityLevel.value = 'tw-opacity-100';
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.dataSet,
  (newVal) => {
    updateChartData(newVal);
  },
  {
    immediate: true,
  },
);

function updateChartData(newVal: ChartData<'bar'>) {
  if (chartInstance) {
    chartInstance.data.labels = newVal.labels;
    chartInstance.data.datasets = newVal.datasets;
    chartInstance.update();
  }
}

const drawChart = () => {
  const ctx = canvas.value?.getContext('2d');
  if (!ctx) return;

  chartInstance = new Chart(ctx, {
    type: 'bar',
    plugins: [ChartDataLabels],
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          align: 'end',
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 3.5,
            color: '#666666',
            font: {
              family: 'Hanken Grotesk',
              size: 10,
              weight: '700',
            },
          },
        },
        tooltip: {
          caretSize: 0,
          enabled: true,
          usePointStyle: true,
          boxPadding: 5,
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: function (ctx: any) {
              const element = chartInstance.getActiveElements()[0];
              const dataset = ctx.dataset;
              let label = dataset.label;
              let value = '';
              switch (label) {
                case 'New Customers':
                  if (dashboardStore.movementTrendSelectedFilter === movementTrendChartData.filterOptions[1].value) {
                    value = t('churn_and_revenue_trend_graph_tooltip.new_acv', {
                      value: amountFormatter.formatAmount(
                        ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                      ),
                    });
                  } else {
                    value = t('churn_and_revenue_trend_graph_tooltip.new_customers', {
                      value: ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                    });
                  }
                  break;
                case 'Churned Customers':
                  if (dashboardStore.movementTrendSelectedFilter === movementTrendChartData.filterOptions[1].value) {
                    value = t('churn_and_revenue_trend_graph_tooltip.churned_acv', {
                      value: amountFormatter.formatAmount(
                        ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                      ),
                    });
                  } else {
                    value = t('churn_and_revenue_trend_graph_tooltip.churned_customers', {
                      value: ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                    });
                  }
                  break;
                default:
                  if (dashboardStore.movementTrendSelectedFilter === movementTrendChartData.filterOptions[1].value) {
                    value = t('churn_and_revenue_trend_graph_tooltip.total_acv', {
                      value: amountFormatter.formatAmount(ctx.dataset.data[element.index][1]),
                    });
                  } else {
                    value = t('churn_and_revenue_trend_graph_tooltip.total_customers', {
                      value: ctx.dataset.data[element.index][1],
                    });
                  }
                  break;
              }
              return value;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Hanken Grotesk',
              size: 14,
              weight: '600',
            },
          },
        },
        y: {
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
    data: dataSets,
  });
};

onMounted(() => {
  drawChart();
  if (props.dataSet) {
    updateChartData(props.dataSet);
  }
});
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-shadow">
    <LoadingIndicator class="tw-bg-light-shade-1" v-if="props.isLoading" />
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-px-6 tw-pt-4">
      <div class="tw-flex tw-flex-col">
        <span class="tw-text-base tw-text-dark-shade-3">{{ t('churn_and_revenue_trends') }}</span>

        <!-- <div class="tw-flex tw-items-center">
          <span class="tw-text-sm tw-text-dark-shade-1 tw-font-semibold">This Year</span>
          <q-icon :name="phTriangleFill" class="tw-text-[0.625rem] tw-fill-data-viz-semantic-green-content tw-mx-1" />
          <span class="tw-text-xs tw-font-semibold tw-text-dark-shade-2">12%</span>
        </div> -->
      </div>

      <PillToggle
        v-if="!props.isLoading"
        :options="movementTrendChartData.filterOptions"
        v-model="dashboardStore.movementTrendSelectedFilter"
      />
    </div>

    <div class="tw-relative tw-mt-9 tw-h-60">
      <canvas ref="canvas" />
      <!-- <CustomerHealthTrendTooltip ref="chartTooltip" /> -->
    </div>
  </div>
</template>
