<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { IDate } from '@/types/date.type';
import { addYears, format } from 'date-fns';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/account';

const { onboardingCompletedAt } = storeToRefs(useAccountStore());
// const date = ref<string>();
const props = defineProps<{ selectedDate: IDate }>();
const emits = defineEmits(['onDateSelection']);
const currentDate = ref<string>(format(new Date(), 'yyyy/MM/dd'));
const startYear = ref<string>();

const { getIDateDayObj } = useDateFormatter();
const date = ref<string>(props.selectedDate.text);

onMounted(() => {
  if (onboardingCompletedAt.value) {
    startYear.value = format(onboardingCompletedAt.value, 'yyyy/MM/dd');
  } else {
    const currentYear = addYears(new Date(), -19);
    startYear.value = `${currentYear.getFullYear()}/01/01`;
  }
});

watch(
  () => date.value,
  (newVal: string) => {
    const date = new Date(newVal);
    emits('onDateSelection', getIDateDayObj(date));
  },
);

function optionsFn(date: string) {
  // return date >= `${startYear.value}` && date < currentDate.value && !isWeekend(new Date(date));
  return date >= `${startYear.value}` && date < currentDate.value;
}

const minDateNavigation = computed(() => {
  if (onboardingCompletedAt.value) {
    return format(onboardingCompletedAt.value, 'yyyy/MM');
  } else {
    const currentYear = addYears(new Date(), -19);
    return `${currentYear.getFullYear()}/01`;
  }
});
</script>
<template>
  <div class="tw-flex tw-items-center tw-justify-center">
    <q-date
      v-model="date"
      no-unset
      :options="optionsFn"
      minimal
      flat
      class="tw-bg-light-shade-2"
      :navigation-min-year-month="minDateNavigation"
    />
  </div>
</template>
<style scoped lang="postcss">
:deep(.q-btn__content) {
  @apply tw-text-xs tw-font-semibold;
}

:deep(.q-date__calendar-item button) {
  @apply tw-rounded-sm;
}
</style>
