import { useHealthTrendChart } from '@/composables/useHealthTrendChart';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useC2TypeStore } from '@/stores/c2Type';
import { useC1HealthScoreStore } from '@/stores/c1HealthScore';
import { useC2TypeHealthScore } from '@/stores/c2TypeHealthScore';
import { IKpiRowDashboard } from '@/types/dashboard';
import { useMovementTrendChart } from '@/composables/useMovementTrendChart';
import { useKpiStore } from './kpi';
import { Kpi, KpiNameEnum } from '@/api/client';
import { c2Api } from '@/api';

export const useDashboardStore = defineStore('dashboard', () => {
  const c2TypeStore = useC2TypeStore();
  const c1HealthScoreStore = useC1HealthScoreStore();
  const c2TypeHealthScoreStore = useC2TypeHealthScore();
  const kpiStore = useKpiStore();

  const { filterOptions: healthTrendFilterOptions } = useHealthTrendChart();
  const { filterOptions: movementTrendFilterOptions } = useMovementTrendChart();

  const healthTrendSelectedFilter = ref<string>(healthTrendFilterOptions[0].value);
  const movementTrendSelectedFilter = ref<string>(movementTrendFilterOptions[0].value);

  const c2Count = ref<number>(1);

  async function fetchC2Count() {
    const { data } = await c2Api.c2ControllerFindTotalCount();
    c2Count.value = data;
  }

  const selectedLineChartFilter = ref<Kpi>({ name: KpiNameEnum.Zs } as Kpi);

  const typeTrendSelectedFilter = ref<{
    label: string;
    value: string;
  }>();
  const customerUserHealthSelectedFilter = ref<{
    label: string;
    value: string;
  }>();
  const healthMovementSelectedFilter = ref<{
    label: string;
    value: string;
  }>();

  function updateTypeTrendFilterSelection(selection: { label: string; value: string }) {
    typeTrendSelectedFilter.value = selection;
  }

  function updateHealthMovementSelectedFilter(selection: { label: string; value: string }) {
    healthMovementSelectedFilter.value = selection;
  }

  function updateCustomerUserHealthSelectedFilter(selection: { label: string; value: string }) {
    customerUserHealthSelectedFilter.value = selection;
  }

  const isTableLoading = computed(() => {
    return c2TypeStore.isLoading || c2TypeHealthScoreStore.isLoadingListSameDay || c1HealthScoreStore.isLoading;
  });

  const tableRows = computed(() => {
    const rows: IKpiRowDashboard[] = [];
    const c1HealthScore = c1HealthScoreStore.c1HealthScore;
    const c2Types = c2TypeStore.c2Types;
    const c2TypeHealthScore = c2TypeHealthScoreStore.c2TypeHealthScoresSameDay;
    if (!c1HealthScore || c2TypeHealthScore.length <= 0 || c2Types.length <= 0) {
      return rows;
    }
    const steadyC2 = c2TypeHealthScore.find((x) => x.c2_type_id === c2Types.find((x) => x.name === 'steady')?.id);
    const onboardingC2 = c2TypeHealthScore.find(
      (x) => x.c2_type_id === c2Types.find((x) => x.name === 'onboarding')?.id,
    );
    const trialC2 = c2TypeHealthScore.find((x) => x.c2_type_id === c2Types.find((x) => x.name === 'trial')?.id);

    const kpiUss = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Uss);
    if (kpiUss) {
      const usageSuccess = {
        kpiName: kpiUss.name,
        kpiDisplayName: kpiUss.display_name,
        kpiDescription: kpiUss.description,
        kpiId: c1HealthScore?.uss_kpi_id,
        kpiScore: c1HealthScore.usage_success_score,
        kpiPercentageChange: c1HealthScore.uss_percentage_change_from_last,
        kpiColorCode: c1HealthScore.uss_color_code,
        kpiCommentary: c1HealthScore.uss_commentary,
        steadyCustScore: steadyC2?.usage_success_score,
        steadyCustColorCode: steadyC2?.uss_color_code,
        steadyCustPercentageChange: steadyC2?.uss_percentage_change_from_last,
        onboardingCustScore: onboardingC2?.usage_success_score,
        onboardingCustColorCode: onboardingC2?.uss_color_code,
        onboardingCustPercentageChange: onboardingC2?.uss_percentage_change_from_last,
        trialCustScore: trialC2?.usage_success_score,
        trialCustColorCode: trialC2?.uss_color_code,
        trialCustPercentageChange: trialC2?.uss_percentage_change_from_last,
        percentInGrey: c1HealthScore.usage_success_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.usage_success_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.usage_success_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.usage_success_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.usage_success_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.usage_success_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.usage_success_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.usage_success_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard;
      rows.push(usageSuccess);
    }

    const kpiOss = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Oss);
    if (kpiOss) {
      //TODO Add outcome commentary
      const outcomeSuccess = {
        kpiName: kpiOss.name,
        kpiDisplayName: kpiOss.display_name,
        kpiDescription: kpiOss.description,
        kpiId: c1HealthScore.oss_kpi_id,
        kpiScore: c1HealthScore.outcome_success_score,
        kpiPercentageChange: c1HealthScore.oss_percent_change_from_last,
        kpiColorCode: c1HealthScore.oss_color_code,
        kpiCommentary: '',
        steadyCustScore: steadyC2?.outcome_success_score,
        steadyCustColorCode: steadyC2?.oss_color_code,
        steadyCustPercentageChange: steadyC2?.oss_percent_change_from_last,
        onboardingCustScore: onboardingC2?.outcome_success_score,
        onboardingCustColorCode: onboardingC2?.oss_color_code,
        onboardingCustPercentageChange: onboardingC2?.oss_percent_change_from_last,
        trialCustScore: trialC2?.outcome_success_score,
        trialCustColorCode: trialC2?.oss_color_code,
        trialCustPercentageChange: trialC2?.oss_percent_change_from_last,
        percentInGrey: c1HealthScore.outcome_success_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.outcome_success_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.outcome_success_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.outcome_success_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.outcome_success_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.outcome_success_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.outcome_success_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.outcome_success_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard;
      rows.push(outcomeSuccess);
    }

    const kpiBts = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Bts);
    if (kpiBts) {
      rows.push({
        kpiName: kpiBts.name,
        kpiDisplayName: kpiBts.display_name,
        kpiDescription: kpiBts.description,
        kpiId: c1HealthScore.bts_kpi_id,
        kpiScore: c1HealthScore.bug_tracking_score,
        kpiPercentageChange: c1HealthScore.bts_percentage_change_from_last,
        kpiColorCode: c1HealthScore.bts_color_code,
        kpiCommentary: c1HealthScore.bts_commentary,
        steadyCustScore: steadyC2?.bug_tracking_score,
        steadyCustColorCode: steadyC2?.bts_color_code,
        steadyCustPercentageChange: steadyC2?.bts_percentage_change_from_last,
        onboardingCustScore: onboardingC2?.bug_tracking_score,
        onboardingCustColorCode: onboardingC2?.bts_color_code,
        onboardingCustPercentageChange: onboardingC2?.bts_percentage_change_from_last,
        trialCustScore: trialC2?.bug_tracking_score,
        trialCustColorCode: trialC2?.bts_color_code,
        trialCustPercentageChange: trialC2?.bts_percentage_change_from_last,
        percentInGrey: c1HealthScore.peripheral_success_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.peripheral_success_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.peripheral_success_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.peripheral_success_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.peripheral_success_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.peripheral_success_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.peripheral_success_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.peripheral_success_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard);
    }
    const kpiFrs = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Frs);
    if (kpiFrs) {
      rows.push({
        kpiName: kpiFrs.name,
        kpiDisplayName: kpiFrs.display_name,
        kpiDescription: kpiFrs.description,
        kpiId: c1HealthScore.frs_kpi_id,
        kpiScore: c1HealthScore.feature_request_score,
        kpiPercentageChange: c1HealthScore.frs_percentage_change_from_last,
        kpiColorCode: c1HealthScore.frs_color_code,
        kpiCommentary: c1HealthScore.frs_commentary,
        steadyCustScore: steadyC2?.feature_request_score,
        steadyCustColorCode: steadyC2?.frs_color_code,
        steadyCustPercentageChange: steadyC2?.frs_percentage_change_from_last,
        onboardingCustScore: onboardingC2?.feature_request_score,
        onboardingCustColorCode: onboardingC2?.frs_color_code,
        onboardingCustPercentageChange: onboardingC2?.frs_percentage_change_from_last,
        trialCustScore: trialC2?.feature_request_score,
        trialCustColorCode: trialC2?.frs_color_code,
        trialCustPercentageChange: trialC2?.frs_percentage_change_from_last,
        percentInGrey: c1HealthScore.peripheral_success_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.peripheral_success_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.peripheral_success_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.peripheral_success_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.peripheral_success_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.peripheral_success_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.peripheral_success_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.peripheral_success_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard);
    }
    const kpiPas = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Pas);
    if (kpiPas) {
      rows.push({
        kpiName: kpiPas.name,
        kpiDisplayName: kpiPas.display_name,
        kpiDescription: kpiPas.description,
        kpiId: c1HealthScore.pas_kpi_id,
        kpiScore: c1HealthScore.payment_app_score,
        kpiPercentageChange: c1HealthScore.pas_percentage_change_from_last,
        kpiColorCode: c1HealthScore.pas_color_code,
        kpiCommentary: c1HealthScore.pas_commentary,
        steadyCustScore: steadyC2?.payment_app_score,
        steadyCustColorCode: steadyC2?.pas_color_code,
        steadyCustPercentageChange: steadyC2?.pas_percentage_change_from_last,
        onboardingCustScore: onboardingC2?.payment_app_score,
        onboardingCustColorCode: onboardingC2?.pas_color_code,
        onboardingCustPercentageChange: onboardingC2?.pas_percentage_change_from_last,
        trialCustScore: trialC2?.payment_app_score,
        trialCustColorCode: trialC2?.pas_color_code,
        trialCustPercentageChange: trialC2?.pas_percentage_change_from_last,
        percentInGrey: c1HealthScore.peripheral_success_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.peripheral_success_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.peripheral_success_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.peripheral_success_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.peripheral_success_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.peripheral_success_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.peripheral_success_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.peripheral_success_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard);
    }
    const kpiCcs = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Ccs);
    if (kpiCcs) {
      rows.push({
        kpiName: kpiCcs.name,
        kpiDisplayName: kpiCcs.display_name,
        kpiDescription: kpiCcs.description,
        kpiId: c1HealthScore.ccs_kpi_id,
        kpiScore: c1HealthScore.customer_communication_score,
        kpiPercentageChange: c1HealthScore.ccs_percentage_change_from_last,
        kpiColorCode: c1HealthScore.ccs_color_code,
        kpiCommentary: c1HealthScore.ccs_commentary,
        steadyCustScore: steadyC2?.customer_communication_score,
        steadyCustColorCode: steadyC2?.ccs_color_code,
        steadyCustPercentageChange: steadyC2?.ccs_percentage_change_from_last,
        onboardingCustScore: onboardingC2?.customer_communication_score,
        onboardingCustColorCode: onboardingC2?.ccs_color_code,
        onboardingCustPercentageChange: onboardingC2?.ccs_percentage_change_from_last,
        trialCustScore: trialC2?.customer_communication_score,
        trialCustColorCode: trialC2?.ccs_color_code,
        trialCustPercentageChange: trialC2?.ccs_percentage_change_from_last,
        percentInGrey: c1HealthScore.peripheral_success_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.peripheral_success_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.peripheral_success_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.peripheral_success_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.peripheral_success_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.peripheral_success_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.peripheral_success_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.peripheral_success_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard);
    }

    const kpiUpss = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Upss);
    if (kpiUpss) {
      const upsellScore = {
        kpiName: kpiUpss.name,
        kpiDisplayName: kpiUpss.display_name,
        kpiDescription: kpiUpss.description,
        kpiId: c1HealthScore.upss_kpi_id,
        kpiScore: c1HealthScore.upsell_success_score,
        kpiPercentageChange: c1HealthScore.upss_percentage_change_from_last,
        kpiColorCode: c1HealthScore.upss_color_code,
        kpiCommentary: c1HealthScore.upss_commentary,
        steadyCustScore: steadyC2?.upsell_success_score,
        steadyCustColorCode: steadyC2?.upss_color_code,
        steadyCustPercentageChange: steadyC2?.upss_percentage_change_from_last,
        onboardingCustScore: onboardingC2?.upsell_success_score,
        onboardingCustColorCode: onboardingC2?.upss_color_code,
        onboardingCustPercentageChange: onboardingC2?.upss_percentage_change_from_last,
        trialCustScore: trialC2?.upsell_success_score,
        trialCustColorCode: trialC2?.upss_color_code,
        trialCustPercentageChange: trialC2?.upss_percentage_change_from_last,
        percentInGrey: c1HealthScore.upsell_success_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.upsell_success_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.upsell_success_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.upsell_success_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.upsell_success_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.upsell_success_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.upsell_success_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.upsell_success_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard;
      rows.push(upsellScore);
    }

    const kpiHfs = kpiStore.activeKpiList.find((x) => x.name === KpiNameEnum.Hfs);
    if (kpiHfs) {
      //TODO Add handfell commentary
      const csHandfeel = {
        kpiName: kpiHfs.name,
        kpiDisplayName: kpiHfs.display_name,
        kpiDescription: kpiHfs.description,
        kpiId: c1HealthScore.hfs_kpi_id,
        kpiScore: c1HealthScore.handfeel_score,
        kpiPercentageChange: c1HealthScore.hfs_percentage_change_from_last,
        kpiColorCode: c1HealthScore.hfs_color_code,
        kpiCommentary: '',
        steadyCustScore: steadyC2?.handfeel_score,
        steadyCustColorCode: steadyC2?.hfs_color_code,
        steadyCustPercentageChange: steadyC2?.hfs_percentage_change_from_last,
        onboardingCustScore: onboardingC2?.handfeel_score,
        onboardingCustColorCode: onboardingC2?.hfs_color_code,
        onboardingCustPercentageChange: onboardingC2?.hfs_percentage_change_from_last,
        trialCustScore: trialC2?.handfeel_score,
        trialCustColorCode: trialC2?.hfs_color_code,
        trialCustPercentageChange: trialC2?.hfs_percentage_change_from_last,
        percentInGrey: c1HealthScore.cs_handfeel_score_percent_of_c2s_in_grey,
        percentInRed: c1HealthScore.cs_handfeel_score_percent_of_c2s_in_red,
        percentInYellow: c1HealthScore.cs_handfeel_score_percent_of_c2s_in_yellow,
        percentInGreen: c1HealthScore.cs_handfeel_score_percent_of_c2s_in_green,
        noInGreen: c1HealthScore.cs_handfeel_score_no_of_c2s_in_green,
        noInYellow: c1HealthScore.cs_handfeel_score_no_of_c2s_in_yellow,
        noInRed: c1HealthScore.cs_handfeel_score_no_of_c2s_in_red,
        noInGrey: c1HealthScore.cs_handfeel_score_no_of_c2s_in_grey,
        date: c1HealthScore.date,
      } as IKpiRowDashboard;
      rows.push(csHandfeel);
    }
    return rows;
  });

  return {
    typeTrendSelectedFilter,
    healthTrendSelectedFilter,
    movementTrendSelectedFilter,
    healthMovementSelectedFilter,
    customerUserHealthSelectedFilter,
    updateHealthMovementSelectedFilter,
    updateTypeTrendFilterSelection,
    updateCustomerUserHealthSelectedFilter,
    isTableLoading,
    tableRows,
    c2Count,
    selectedLineChartFilter,
    fetchC2Count,
  };
});
