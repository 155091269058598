/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCustomFieldDefinitionDto } from '../model/';
// @ts-ignore
import { CustomFieldsDefinition } from '../model/';
// @ts-ignore
import { DeleteCustomFieldDefinitionDto } from '../model/';
// @ts-ignore
import { PaginatedCustomFieldDto } from '../model/';
// @ts-ignore
import { UpdateCustomFieldOrderingDto } from '../model/';
// @ts-ignore
import { UpdateCustomFieldVisibilityDto } from '../model/';
// @ts-ignore
import { UpdateCustomFieldsDefinitionDto } from '../model/';
/**
 * CustomFieldsDefinitionApi - axios parameter creator
 * @export
 */
export const CustomFieldsDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new Custom Field Definition
         * @param {CreateCustomFieldDefinitionDto} createCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerCreateDefinition: async (createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomFieldDefinitionDto' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerCreateDefinition', 'createCustomFieldDefinitionDto', createCustomFieldDefinitionDto)
            const localVarPath = `/api/custom-fields-definition/create-custom-field-defination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomFieldDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of All Custom field Definitions
         * @param {string} [xTenantId] 
         * @param {string} [source] optional field to filter custom fields definitions by source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindAll: async (xTenantId?: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/custom-fields-definition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of All Custom field Definitions with log values
         * @param {string} id C2 Id
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [searchName] search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindAllWithValues: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, searchName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerFindAllWithValues', 'id', id)
            const localVarPath = `/api/custom-fields-definition/find-all-with-latest-log-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (searchName !== undefined) {
                localVarQueryParameter['searchName'] = searchName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  Version 2 - List of All Custom field Definitions with log values
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [searchName] search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindAllWithValuesV2: async (xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, searchName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/custom-fields-definition/find-all-with-latest-log-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (searchName !== undefined) {
                localVarQueryParameter['searchName'] = searchName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find custom fields definition with custom field name
         * @param {string} name mandatory field
         * @param {string} source mandatory field
         * @param {string} [xTenantId] 
         * @param {string} [type] optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName: async (name: string, source: string, xTenantId?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName', 'name', name)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName', 'source', source)
            const localVarPath = `/api/custom-fields-definition/find-by-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get zapscale custom fields by source
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindZsCustomFieldsBySource: async (source: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerFindZsCustomFieldsBySource', 'source', source)
            const localVarPath = `/api/custom-fields-definition/find-zs-fields-by-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft Delete Custom Field Definition
         * @param {DeleteCustomFieldDefinitionDto} deleteCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerSoftDeleteDefinition: async (deleteCustomFieldDefinitionDto: DeleteCustomFieldDefinitionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteCustomFieldDefinitionDto' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerSoftDeleteDefinition', 'deleteCustomFieldDefinitionDto', deleteCustomFieldDefinitionDto)
            const localVarPath = `/api/custom-fields-definition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCustomFieldDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update custom fields definition order
         * @param {UpdateCustomFieldsDefinitionDto} updateCustomFieldsDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder: async (updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomFieldsDefinitionDto' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder', 'updateCustomFieldsDefinitionDto', updateCustomFieldsDefinitionDto)
            const localVarPath = `/api/custom-fields-definition/update-definition-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomFieldsDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update custom fields definition visibility
         * @param {UpdateCustomFieldsDefinitionDto} updateCustomFieldsDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility: async (updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomFieldsDefinitionDto' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility', 'updateCustomFieldsDefinitionDto', updateCustomFieldsDefinitionDto)
            const localVarPath = `/api/custom-fields-definition/update-definition-visibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomFieldsDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Custom Fields Ordering
         * @param {UpdateCustomFieldOrderingDto} updateCustomFieldOrderingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsOrdering: async (updateCustomFieldOrderingDto: UpdateCustomFieldOrderingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomFieldOrderingDto' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerUpdateCustomFieldsOrdering', 'updateCustomFieldOrderingDto', updateCustomFieldOrderingDto)
            const localVarPath = `/api/custom-fields-definition/update-custom-field-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomFieldOrderingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Custom Fields Visibility
         * @param {UpdateCustomFieldVisibilityDto} updateCustomFieldVisibilityDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsVisibility: async (updateCustomFieldVisibilityDto: UpdateCustomFieldVisibilityDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomFieldVisibilityDto' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerUpdateCustomFieldsVisibility', 'updateCustomFieldVisibilityDto', updateCustomFieldVisibilityDto)
            const localVarPath = `/api/custom-fields-definition/update-custom-field-visibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomFieldVisibilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Custom Field Definition
         * @param {CreateCustomFieldDefinitionDto} createCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateDefinition: async (createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomFieldDefinitionDto' is not null or undefined
            assertParamExists('customFieldsDefinitionControllerUpdateDefinition', 'createCustomFieldDefinitionDto', createCustomFieldDefinitionDto)
            const localVarPath = `/api/custom-fields-definition/update-custom-field-definition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomFieldDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomFieldsDefinitionApi - functional programming interface
 * @export
 */
export const CustomFieldsDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomFieldsDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new Custom Field Definition
         * @param {CreateCustomFieldDefinitionDto} createCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerCreateDefinition(createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldsDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerCreateDefinition(createCustomFieldDefinitionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of All Custom field Definitions
         * @param {string} [xTenantId] 
         * @param {string} [source] optional field to filter custom fields definitions by source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerFindAll(xTenantId?: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomFieldsDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerFindAll(xTenantId, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of All Custom field Definitions with log values
         * @param {string} id C2 Id
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [searchName] search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerFindAllWithValues(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, searchName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCustomFieldDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerFindAllWithValues(id, xTenantId, skip, limit, sortBy, descending, before, after, searchName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  Version 2 - List of All Custom field Definitions with log values
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [searchName] search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerFindAllWithValuesV2(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, searchName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCustomFieldDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerFindAllWithValuesV2(xTenantId, skip, limit, sortBy, descending, before, after, searchName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find custom fields definition with custom field name
         * @param {string} name mandatory field
         * @param {string} source mandatory field
         * @param {string} [xTenantId] 
         * @param {string} [type] optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName(name: string, source: string, xTenantId?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldsDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName(name, source, xTenantId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get zapscale custom fields by source
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerFindZsCustomFieldsBySource(source: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomFieldsDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerFindZsCustomFieldsBySource(source, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft Delete Custom Field Definition
         * @param {DeleteCustomFieldDefinitionDto} deleteCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerSoftDeleteDefinition(deleteCustomFieldDefinitionDto: DeleteCustomFieldDefinitionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldsDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerSoftDeleteDefinition(deleteCustomFieldDefinitionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update custom fields definition order
         * @param {UpdateCustomFieldsDefinitionDto} updateCustomFieldsDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder(updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder(updateCustomFieldsDefinitionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update custom fields definition visibility
         * @param {UpdateCustomFieldsDefinitionDto} updateCustomFieldsDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility(updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility(updateCustomFieldsDefinitionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Custom Fields Ordering
         * @param {UpdateCustomFieldOrderingDto} updateCustomFieldOrderingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerUpdateCustomFieldsOrdering(updateCustomFieldOrderingDto: UpdateCustomFieldOrderingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCustomFieldOrderingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerUpdateCustomFieldsOrdering(updateCustomFieldOrderingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Custom Fields Visibility
         * @param {UpdateCustomFieldVisibilityDto} updateCustomFieldVisibilityDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerUpdateCustomFieldsVisibility(updateCustomFieldVisibilityDto: UpdateCustomFieldVisibilityDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCustomFieldVisibilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerUpdateCustomFieldsVisibility(updateCustomFieldVisibilityDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Custom Field Definition
         * @param {CreateCustomFieldDefinitionDto} createCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsDefinitionControllerUpdateDefinition(createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldsDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsDefinitionControllerUpdateDefinition(createCustomFieldDefinitionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomFieldsDefinitionApi - factory interface
 * @export
 */
export const CustomFieldsDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomFieldsDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new Custom Field Definition
         * @param {CreateCustomFieldDefinitionDto} createCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerCreateDefinition(createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto, xTenantId?: string, options?: any): AxiosPromise<CustomFieldsDefinition> {
            return localVarFp.customFieldsDefinitionControllerCreateDefinition(createCustomFieldDefinitionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of All Custom field Definitions
         * @param {string} [xTenantId] 
         * @param {string} [source] optional field to filter custom fields definitions by source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindAll(xTenantId?: string, source?: string, options?: any): AxiosPromise<Array<CustomFieldsDefinition>> {
            return localVarFp.customFieldsDefinitionControllerFindAll(xTenantId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of All Custom field Definitions with log values
         * @param {string} id C2 Id
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [searchName] search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindAllWithValues(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, searchName?: string, options?: any): AxiosPromise<PaginatedCustomFieldDto> {
            return localVarFp.customFieldsDefinitionControllerFindAllWithValues(id, xTenantId, skip, limit, sortBy, descending, before, after, searchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  Version 2 - List of All Custom field Definitions with log values
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [searchName] search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindAllWithValuesV2(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, searchName?: string, options?: any): AxiosPromise<PaginatedCustomFieldDto> {
            return localVarFp.customFieldsDefinitionControllerFindAllWithValuesV2(xTenantId, skip, limit, sortBy, descending, before, after, searchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find custom fields definition with custom field name
         * @param {string} name mandatory field
         * @param {string} source mandatory field
         * @param {string} [xTenantId] 
         * @param {string} [type] optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName(name: string, source: string, xTenantId?: string, type?: string, options?: any): AxiosPromise<CustomFieldsDefinition> {
            return localVarFp.customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName(name, source, xTenantId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get zapscale custom fields by source
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerFindZsCustomFieldsBySource(source: string, xTenantId?: string, options?: any): AxiosPromise<Array<CustomFieldsDefinition>> {
            return localVarFp.customFieldsDefinitionControllerFindZsCustomFieldsBySource(source, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft Delete Custom Field Definition
         * @param {DeleteCustomFieldDefinitionDto} deleteCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerSoftDeleteDefinition(deleteCustomFieldDefinitionDto: DeleteCustomFieldDefinitionDto, xTenantId?: string, options?: any): AxiosPromise<CustomFieldsDefinition> {
            return localVarFp.customFieldsDefinitionControllerSoftDeleteDefinition(deleteCustomFieldDefinitionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update custom fields definition order
         * @param {UpdateCustomFieldsDefinitionDto} updateCustomFieldsDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder(updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder(updateCustomFieldsDefinitionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update custom fields definition visibility
         * @param {UpdateCustomFieldsDefinitionDto} updateCustomFieldsDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility(updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility(updateCustomFieldsDefinitionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Custom Fields Ordering
         * @param {UpdateCustomFieldOrderingDto} updateCustomFieldOrderingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsOrdering(updateCustomFieldOrderingDto: UpdateCustomFieldOrderingDto, xTenantId?: string, options?: any): AxiosPromise<UpdateCustomFieldOrderingDto> {
            return localVarFp.customFieldsDefinitionControllerUpdateCustomFieldsOrdering(updateCustomFieldOrderingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Custom Fields Visibility
         * @param {UpdateCustomFieldVisibilityDto} updateCustomFieldVisibilityDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateCustomFieldsVisibility(updateCustomFieldVisibilityDto: UpdateCustomFieldVisibilityDto, xTenantId?: string, options?: any): AxiosPromise<UpdateCustomFieldVisibilityDto> {
            return localVarFp.customFieldsDefinitionControllerUpdateCustomFieldsVisibility(updateCustomFieldVisibilityDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Custom Field Definition
         * @param {CreateCustomFieldDefinitionDto} createCustomFieldDefinitionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsDefinitionControllerUpdateDefinition(createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto, xTenantId?: string, options?: any): AxiosPromise<CustomFieldsDefinition> {
            return localVarFp.customFieldsDefinitionControllerUpdateDefinition(createCustomFieldDefinitionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for customFieldsDefinitionControllerCreateDefinition operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerCreateDefinitionRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerCreateDefinitionRequest {
    /**
     * 
     * @type {CreateCustomFieldDefinitionDto}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerCreateDefinition
     */
    readonly createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerCreateDefinition
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerFindAll operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * optional field to filter custom fields definitions by source
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAll
     */
    readonly source?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerFindAllWithValues operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesRequest {
    /**
     * C2 Id
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly after?: string

    /**
     * search query
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValues
     */
    readonly searchName?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerFindAllWithValuesV2 operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2Request
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2Request {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly after?: string

    /**
     * search query
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2
     */
    readonly searchName?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithNameRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithNameRequest {
    /**
     * mandatory field
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithName
     */
    readonly name: string

    /**
     * mandatory field
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithName
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithName
     */
    readonly xTenantId?: string

    /**
     * optional field
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithName
     */
    readonly type?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerFindZsCustomFieldsBySource operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindZsCustomFieldsBySourceRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindZsCustomFieldsBySourceRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindZsCustomFieldsBySource
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindZsCustomFieldsBySource
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerSoftDeleteDefinition operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerSoftDeleteDefinitionRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerSoftDeleteDefinitionRequest {
    /**
     * 
     * @type {DeleteCustomFieldDefinitionDto}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerSoftDeleteDefinition
     */
    readonly deleteCustomFieldDefinitionDto: DeleteCustomFieldDefinitionDto

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerSoftDeleteDefinition
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrderRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrderRequest {
    /**
     * 
     * @type {UpdateCustomFieldsDefinitionDto}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder
     */
    readonly updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibilityRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibilityRequest {
    /**
     * 
     * @type {UpdateCustomFieldsDefinitionDto}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility
     */
    readonly updateCustomFieldsDefinitionDto: UpdateCustomFieldsDefinitionDto

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerUpdateCustomFieldsOrdering operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsOrderingRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsOrderingRequest {
    /**
     * 
     * @type {UpdateCustomFieldOrderingDto}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsOrdering
     */
    readonly updateCustomFieldOrderingDto: UpdateCustomFieldOrderingDto

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsOrdering
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerUpdateCustomFieldsVisibility operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsVisibilityRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsVisibilityRequest {
    /**
     * 
     * @type {UpdateCustomFieldVisibilityDto}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsVisibility
     */
    readonly updateCustomFieldVisibilityDto: UpdateCustomFieldVisibilityDto

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsVisibility
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customFieldsDefinitionControllerUpdateDefinition operation in CustomFieldsDefinitionApi.
 * @export
 * @interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateDefinitionRequest
 */
export interface CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateDefinitionRequest {
    /**
     * 
     * @type {CreateCustomFieldDefinitionDto}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateDefinition
     */
    readonly createCustomFieldDefinitionDto: CreateCustomFieldDefinitionDto

    /**
     * 
     * @type {string}
     * @memberof CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateDefinition
     */
    readonly xTenantId?: string
}

/**
 * CustomFieldsDefinitionApi - object-oriented interface
 * @export
 * @class CustomFieldsDefinitionApi
 * @extends {BaseAPI}
 */
export class CustomFieldsDefinitionApi extends BaseAPI {
    /**
     * 
     * @summary Create new Custom Field Definition
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerCreateDefinitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerCreateDefinition(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerCreateDefinitionRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerCreateDefinition(requestParameters.createCustomFieldDefinitionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of All Custom field Definitions
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerFindAll(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerFindAll(requestParameters.xTenantId, requestParameters.source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of All Custom field Definitions with log values
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerFindAllWithValues(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerFindAllWithValues(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.searchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  Version 2 - List of All Custom field Definitions with log values
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerFindAllWithValuesV2(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindAllWithValuesV2Request = {}, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerFindAllWithValuesV2(requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.searchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find custom fields definition with custom field name
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindCustomFieldsDefinitionWithNameRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerFindCustomFieldsDefinitionWithName(requestParameters.name, requestParameters.source, requestParameters.xTenantId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get zapscale custom fields by source
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindZsCustomFieldsBySourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerFindZsCustomFieldsBySource(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerFindZsCustomFieldsBySourceRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerFindZsCustomFieldsBySource(requestParameters.source, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft Delete Custom Field Definition
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerSoftDeleteDefinitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerSoftDeleteDefinition(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerSoftDeleteDefinitionRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerSoftDeleteDefinition(requestParameters.deleteCustomFieldDefinitionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update custom fields definition order
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrderRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerUpdateCustomFieldsDefinitionOrder(requestParameters.updateCustomFieldsDefinitionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update custom fields definition visibility
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibilityRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerUpdateCustomFieldsDefinitionVisibility(requestParameters.updateCustomFieldsDefinitionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Custom Fields Ordering
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsOrderingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerUpdateCustomFieldsOrdering(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsOrderingRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerUpdateCustomFieldsOrdering(requestParameters.updateCustomFieldOrderingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Custom Fields Visibility
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsVisibilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerUpdateCustomFieldsVisibility(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateCustomFieldsVisibilityRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerUpdateCustomFieldsVisibility(requestParameters.updateCustomFieldVisibilityDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Custom Field Definition
     * @param {CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateDefinitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsDefinitionApi
     */
    public customFieldsDefinitionControllerUpdateDefinition(requestParameters: CustomFieldsDefinitionApiCustomFieldsDefinitionControllerUpdateDefinitionRequest, options?: AxiosRequestConfig) {
        return CustomFieldsDefinitionApiFp(this.configuration).customFieldsDefinitionControllerUpdateDefinition(requestParameters.createCustomFieldDefinitionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
