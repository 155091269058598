/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePersonalAccessTokenDto } from '../model/';
// @ts-ignore
import { NewPersonalAccessTokenDto } from '../model/';
// @ts-ignore
import { PaginatedPersonalAccessTokenDto } from '../model/';
// @ts-ignore
import { PersonalAccessToken } from '../model/';
/**
 * PersonalAccessTokenApi - axios parameter creator
 * @export
 */
export const PersonalAccessTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePersonalAccessTokenDto} createPersonalAccessTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalAccessTokensControllerCreate: async (createPersonalAccessTokenDto: CreatePersonalAccessTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPersonalAccessTokenDto' is not null or undefined
            assertParamExists('personalAccessTokensControllerCreate', 'createPersonalAccessTokenDto', createPersonalAccessTokenDto)
            const localVarPath = `/api/personal-access-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPersonalAccessTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated list of all user defined tokens for current U1
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalAccessTokensControllerFindAll: async (skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/personal-access-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete personal access token by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalAccessTokensControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personalAccessTokensControllerRemove', 'id', id)
            const localVarPath = `/api/personal-access-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonalAccessTokenApi - functional programming interface
 * @export
 */
export const PersonalAccessTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonalAccessTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePersonalAccessTokenDto} createPersonalAccessTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalAccessTokensControllerCreate(createPersonalAccessTokenDto: CreatePersonalAccessTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewPersonalAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalAccessTokensControllerCreate(createPersonalAccessTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated list of all user defined tokens for current U1
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalAccessTokensControllerFindAll(skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPersonalAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalAccessTokensControllerFindAll(skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete personal access token by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalAccessTokensControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonalAccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalAccessTokensControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonalAccessTokenApi - factory interface
 * @export
 */
export const PersonalAccessTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonalAccessTokenApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePersonalAccessTokenDto} createPersonalAccessTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalAccessTokensControllerCreate(createPersonalAccessTokenDto: CreatePersonalAccessTokenDto, options?: any): AxiosPromise<NewPersonalAccessTokenDto> {
            return localVarFp.personalAccessTokensControllerCreate(createPersonalAccessTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated list of all user defined tokens for current U1
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalAccessTokensControllerFindAll(skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedPersonalAccessTokenDto> {
            return localVarFp.personalAccessTokensControllerFindAll(skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete personal access token by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalAccessTokensControllerRemove(id: string, options?: any): AxiosPromise<PersonalAccessToken> {
            return localVarFp.personalAccessTokensControllerRemove(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for personalAccessTokensControllerCreate operation in PersonalAccessTokenApi.
 * @export
 * @interface PersonalAccessTokenApiPersonalAccessTokensControllerCreateRequest
 */
export interface PersonalAccessTokenApiPersonalAccessTokensControllerCreateRequest {
    /**
     * 
     * @type {CreatePersonalAccessTokenDto}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerCreate
     */
    readonly createPersonalAccessTokenDto: CreatePersonalAccessTokenDto
}

/**
 * Request parameters for personalAccessTokensControllerFindAll operation in PersonalAccessTokenApi.
 * @export
 * @interface PersonalAccessTokenApiPersonalAccessTokensControllerFindAllRequest
 */
export interface PersonalAccessTokenApiPersonalAccessTokensControllerFindAllRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerFindAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerFindAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerFindAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerFindAll
     */
    readonly after?: string
}

/**
 * Request parameters for personalAccessTokensControllerRemove operation in PersonalAccessTokenApi.
 * @export
 * @interface PersonalAccessTokenApiPersonalAccessTokensControllerRemoveRequest
 */
export interface PersonalAccessTokenApiPersonalAccessTokensControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessTokenApiPersonalAccessTokensControllerRemove
     */
    readonly id: string
}

/**
 * PersonalAccessTokenApi - object-oriented interface
 * @export
 * @class PersonalAccessTokenApi
 * @extends {BaseAPI}
 */
export class PersonalAccessTokenApi extends BaseAPI {
    /**
     * 
     * @param {PersonalAccessTokenApiPersonalAccessTokensControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalAccessTokenApi
     */
    public personalAccessTokensControllerCreate(requestParameters: PersonalAccessTokenApiPersonalAccessTokensControllerCreateRequest, options?: AxiosRequestConfig) {
        return PersonalAccessTokenApiFp(this.configuration).personalAccessTokensControllerCreate(requestParameters.createPersonalAccessTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated list of all user defined tokens for current U1
     * @param {PersonalAccessTokenApiPersonalAccessTokensControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalAccessTokenApi
     */
    public personalAccessTokensControllerFindAll(requestParameters: PersonalAccessTokenApiPersonalAccessTokensControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PersonalAccessTokenApiFp(this.configuration).personalAccessTokensControllerFindAll(requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete personal access token by id
     * @param {PersonalAccessTokenApiPersonalAccessTokensControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalAccessTokenApi
     */
    public personalAccessTokensControllerRemove(requestParameters: PersonalAccessTokenApiPersonalAccessTokensControllerRemoveRequest, options?: AxiosRequestConfig) {
        return PersonalAccessTokenApiFp(this.configuration).personalAccessTokensControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
