<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: string;
  options: {
    label: string;
    value: string;
  }[];
  isDisabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const selected = computed(() => {
  return props.modelValue ? props.options.find((f) => f.value === props.modelValue) : props.options[0];
});

const isSelected = (value: string) => {
  return selected.value?.value === value;
};
</script>

<template>
  <div
    class="tw-flex tw-max-w-fit tw-rounded-3xl tw-bg-light-shade-3 tw-text-xs tw-font-semibold"
    :class="[isDisabled ? 'tw-cursor-not-allowed' : '']"
  >
    <button
      v-for="(option, idx) in options"
      :key="idx"
      class="tw-rounded-3xl tw-px-3 tw-py-1"
      :class="[
        isSelected(option.value)
          ? 'tw-z-50 tw-bg-primary-dark tw-text-center tw-text-light-shade-1'
          : 'tw-bg-light-shade-3 tw-text-right tw-text-dark-shade-2',
        isDisabled ? 'tw-pointer-events-none tw-opacity-60' : '',
      ]"
      @click="emit('update:modelValue', option.value)"
    >
      <q-tooltip transition-show="fade" transition-hide="fade" class="tw-bg-dark-shade-1 tw-text-[0.625rem]">{{
        option.label
      }}</q-tooltip>
      {{ option.label }}
    </button>
  </div>
</template>
