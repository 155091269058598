import { RouteName } from '@/router/route-name';
import { LocationAsRelativeRaw, RouteLocationOptions, RouteQueryAndHash } from 'vue-router';

export enum Layout {
  Default = 'AppLayoutDefault',
  Authenticated = 'AppLayoutAuthenticated',
  Onboarding = 'AppLayoutOnboarding',
  Manage = 'AppLayoutManage',
  Scratch = 'AppLayoutScratch',
}

export interface IAppDrawerItem {
  name: string;
  to?: RouteQueryAndHash & LocationAsRelativeRaw & RouteLocationOptions;
  icon: string;
  group?: RouteName | string;
  click?: () => void;
}
