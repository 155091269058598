/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ZapscaleUniversalObject } from '../model/';
// @ts-ignore
import { ZapscaleUniversalObjectTypeNameEnum } from '../model/';
/**
 * ZapscaleUniversalObjectApi - axios parameter creator
 * @export
 */
export const ZapscaleUniversalObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Zapscale Universal Object
         * @param {string} [xTenantId] 
         * @param {ZapscaleUniversalObjectTypeNameEnum} [universalObjectTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleUniversalObjectControllerFindAll: async (xTenantId?: string, universalObjectTypeName?: ZapscaleUniversalObjectTypeNameEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/zapscale-universal-object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (universalObjectTypeName !== undefined) {
                localVarQueryParameter['universal_object_type_name'] = universalObjectTypeName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zapscale Universal Object against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleUniversalObjectControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zapscaleUniversalObjectControllerFindOne', 'id', id)
            const localVarPath = `/api/zapscale-universal-object/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZapscaleUniversalObjectApi - functional programming interface
 * @export
 */
export const ZapscaleUniversalObjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZapscaleUniversalObjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Zapscale Universal Object
         * @param {string} [xTenantId] 
         * @param {ZapscaleUniversalObjectTypeNameEnum} [universalObjectTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleUniversalObjectControllerFindAll(xTenantId?: string, universalObjectTypeName?: ZapscaleUniversalObjectTypeNameEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleUniversalObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleUniversalObjectControllerFindAll(xTenantId, universalObjectTypeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Zapscale Universal Object against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleUniversalObjectControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleUniversalObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleUniversalObjectControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZapscaleUniversalObjectApi - factory interface
 * @export
 */
export const ZapscaleUniversalObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZapscaleUniversalObjectApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Zapscale Universal Object
         * @param {string} [xTenantId] 
         * @param {ZapscaleUniversalObjectTypeNameEnum} [universalObjectTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleUniversalObjectControllerFindAll(xTenantId?: string, universalObjectTypeName?: ZapscaleUniversalObjectTypeNameEnum, options?: any): AxiosPromise<Array<ZapscaleUniversalObject>> {
            return localVarFp.zapscaleUniversalObjectControllerFindAll(xTenantId, universalObjectTypeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Zapscale Universal Object against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleUniversalObjectControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<ZapscaleUniversalObject> {
            return localVarFp.zapscaleUniversalObjectControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for zapscaleUniversalObjectControllerFindAll operation in ZapscaleUniversalObjectApi.
 * @export
 * @interface ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindAllRequest
 */
export interface ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {ZapscaleUniversalObjectTypeNameEnum}
     * @memberof ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindAll
     */
    readonly universalObjectTypeName?: ZapscaleUniversalObjectTypeNameEnum
}

/**
 * Request parameters for zapscaleUniversalObjectControllerFindOne operation in ZapscaleUniversalObjectApi.
 * @export
 * @interface ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindOneRequest
 */
export interface ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * ZapscaleUniversalObjectApi - object-oriented interface
 * @export
 * @class ZapscaleUniversalObjectApi
 * @extends {BaseAPI}
 */
export class ZapscaleUniversalObjectApi extends BaseAPI {
    /**
     * 
     * @summary List of Zapscale Universal Object
     * @param {ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleUniversalObjectApi
     */
    public zapscaleUniversalObjectControllerFindAll(requestParameters: ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return ZapscaleUniversalObjectApiFp(this.configuration).zapscaleUniversalObjectControllerFindAll(requestParameters.xTenantId, requestParameters.universalObjectTypeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Zapscale Universal Object against Id
     * @param {ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleUniversalObjectApi
     */
    public zapscaleUniversalObjectControllerFindOne(requestParameters: ZapscaleUniversalObjectApiZapscaleUniversalObjectControllerFindOneRequest, options?: AxiosRequestConfig) {
        return ZapscaleUniversalObjectApiFp(this.configuration).zapscaleUniversalObjectControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
