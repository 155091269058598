/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum AppTypeNameEnum {
    SaasProduct = 'saas_product',
    Crm = 'crm',
    BugTrackingApp = 'bug_tracking_app',
    SubscriptionAndPaymentApp = 'subscription_and_payment_app',
    EmailAndCommunication = 'email_and_communication',
    FeatureRequestApp = 'feature_request_app',
    Productivity = 'productivity',
    Meeting = 'meeting',
    ProductUsage = 'product_usage',
    DataWarehouse = 'data_warehouse'
}


