/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePlaybookC2EmailStepDto } from '../model/';
// @ts-ignore
import { PlaybookC2EmailStep } from '../model/';
// @ts-ignore
import { UpdatePlaybookC2EmailStepDto } from '../model/';
/**
 * PlaybookC2EmailStepApi - axios parameter creator
 * @export
 */
export const PlaybookC2EmailStepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  Create a Playbook C1 Email Step
         * @param {CreatePlaybookC2EmailStepDto} createPlaybookC2EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerCreate: async (createPlaybookC2EmailStepDto: CreatePlaybookC2EmailStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlaybookC2EmailStepDto' is not null or undefined
            assertParamExists('playbookC2EmailStepControllerCreate', 'createPlaybookC2EmailStepDto', createPlaybookC2EmailStepDto)
            const localVarPath = `/api/playbook-c2-email-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaybookC2EmailStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Playbook C1 Email Steps
         * @param {string} [xTenantId] 
         * @param {string} [playbookC2EmailId] Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerFindAll: async (xTenantId?: string, playbookC2EmailId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-c2-email-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (playbookC2EmailId !== undefined) {
                localVarQueryParameter['playbook_c2_email_id'] = playbookC2EmailId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Playbook-C22Email-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookC2EmailStepControllerRemove', 'id', id)
            const localVarPath = `/api/playbook-c2-email-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Playbook-C2-Email-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookC2EmailStepDto} updatePlaybookC2EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerUpdate: async (id: string, updatePlaybookC2EmailStepDto: UpdatePlaybookC2EmailStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookC2EmailStepControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookC2EmailStepDto' is not null or undefined
            assertParamExists('playbookC2EmailStepControllerUpdate', 'updatePlaybookC2EmailStepDto', updatePlaybookC2EmailStepDto)
            const localVarPath = `/api/playbook-c2-email-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookC2EmailStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookC2EmailStepApi - functional programming interface
 * @export
 */
export const PlaybookC2EmailStepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookC2EmailStepApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook C1 Email Step
         * @param {CreatePlaybookC2EmailStepDto} createPlaybookC2EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC2EmailStepControllerCreate(createPlaybookC2EmailStepDto: CreatePlaybookC2EmailStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookC2EmailStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC2EmailStepControllerCreate(createPlaybookC2EmailStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Playbook C1 Email Steps
         * @param {string} [xTenantId] 
         * @param {string} [playbookC2EmailId] Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC2EmailStepControllerFindAll(xTenantId?: string, playbookC2EmailId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookC2EmailStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC2EmailStepControllerFindAll(xTenantId, playbookC2EmailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Playbook-C22Email-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC2EmailStepControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookC2EmailStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC2EmailStepControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Playbook-C2-Email-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookC2EmailStepDto} updatePlaybookC2EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC2EmailStepControllerUpdate(id: string, updatePlaybookC2EmailStepDto: UpdatePlaybookC2EmailStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookC2EmailStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC2EmailStepControllerUpdate(id, updatePlaybookC2EmailStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookC2EmailStepApi - factory interface
 * @export
 */
export const PlaybookC2EmailStepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookC2EmailStepApiFp(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook C1 Email Step
         * @param {CreatePlaybookC2EmailStepDto} createPlaybookC2EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerCreate(createPlaybookC2EmailStepDto: CreatePlaybookC2EmailStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookC2EmailStep> {
            return localVarFp.playbookC2EmailStepControllerCreate(createPlaybookC2EmailStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Playbook C1 Email Steps
         * @param {string} [xTenantId] 
         * @param {string} [playbookC2EmailId] Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerFindAll(xTenantId?: string, playbookC2EmailId?: string, options?: any): AxiosPromise<Array<PlaybookC2EmailStep>> {
            return localVarFp.playbookC2EmailStepControllerFindAll(xTenantId, playbookC2EmailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Playbook-C22Email-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<PlaybookC2EmailStep> {
            return localVarFp.playbookC2EmailStepControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Playbook-C2-Email-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookC2EmailStepDto} updatePlaybookC2EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC2EmailStepControllerUpdate(id: string, updatePlaybookC2EmailStepDto: UpdatePlaybookC2EmailStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookC2EmailStep> {
            return localVarFp.playbookC2EmailStepControllerUpdate(id, updatePlaybookC2EmailStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookC2EmailStepControllerCreate operation in PlaybookC2EmailStepApi.
 * @export
 * @interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerCreateRequest
 */
export interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerCreateRequest {
    /**
     * 
     * @type {CreatePlaybookC2EmailStepDto}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerCreate
     */
    readonly createPlaybookC2EmailStepDto: CreatePlaybookC2EmailStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookC2EmailStepControllerFindAll operation in PlaybookC2EmailStepApi.
 * @export
 * @interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerFindAllRequest
 */
export interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
     * @type {string}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerFindAll
     */
    readonly playbookC2EmailId?: string
}

/**
 * Request parameters for playbookC2EmailStepControllerRemove operation in PlaybookC2EmailStepApi.
 * @export
 * @interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerRemoveRequest
 */
export interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookC2EmailStepControllerUpdate operation in PlaybookC2EmailStepApi.
 * @export
 * @interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerUpdateRequest
 */
export interface PlaybookC2EmailStepApiPlaybookC2EmailStepControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookC2EmailStepDto}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerUpdate
     */
    readonly updatePlaybookC2EmailStepDto: UpdatePlaybookC2EmailStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookC2EmailStepApiPlaybookC2EmailStepControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlaybookC2EmailStepApi - object-oriented interface
 * @export
 * @class PlaybookC2EmailStepApi
 * @extends {BaseAPI}
 */
export class PlaybookC2EmailStepApi extends BaseAPI {
    /**
     * 
     * @summary  Create a Playbook C1 Email Step
     * @param {PlaybookC2EmailStepApiPlaybookC2EmailStepControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC2EmailStepApi
     */
    public playbookC2EmailStepControllerCreate(requestParameters: PlaybookC2EmailStepApiPlaybookC2EmailStepControllerCreateRequest, options?: AxiosRequestConfig) {
        return PlaybookC2EmailStepApiFp(this.configuration).playbookC2EmailStepControllerCreate(requestParameters.createPlaybookC2EmailStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Playbook C1 Email Steps
     * @param {PlaybookC2EmailStepApiPlaybookC2EmailStepControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC2EmailStepApi
     */
    public playbookC2EmailStepControllerFindAll(requestParameters: PlaybookC2EmailStepApiPlaybookC2EmailStepControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookC2EmailStepApiFp(this.configuration).playbookC2EmailStepControllerFindAll(requestParameters.xTenantId, requestParameters.playbookC2EmailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Playbook-C22Email-Step by id
     * @param {PlaybookC2EmailStepApiPlaybookC2EmailStepControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC2EmailStepApi
     */
    public playbookC2EmailStepControllerRemove(requestParameters: PlaybookC2EmailStepApiPlaybookC2EmailStepControllerRemoveRequest, options?: AxiosRequestConfig) {
        return PlaybookC2EmailStepApiFp(this.configuration).playbookC2EmailStepControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Playbook-C2-Email-Step by id
     * @param {PlaybookC2EmailStepApiPlaybookC2EmailStepControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC2EmailStepApi
     */
    public playbookC2EmailStepControllerUpdate(requestParameters: PlaybookC2EmailStepApiPlaybookC2EmailStepControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookC2EmailStepApiFp(this.configuration).playbookC2EmailStepControllerUpdate(requestParameters.id, requestParameters.updatePlaybookC2EmailStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
