import { createI18n } from 'vue-i18n';
import enUS from '@/locales/en-US.json';
import kpiEnUS from '@/locales/kpi-en-US.json';

export type MessageSchema = typeof enUS;
export type KpiMessageSchema = typeof kpiEnUS;

export const i18n = createI18n<[MessageSchema, KpiMessageSchema], 'en-US'>({
  locale: 'en-US',
  messages: {
    'en-US': { ...enUS, ...kpiEnUS },
    // 'kpi-en-US': kpiEnUS,
  },
});
