/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2TypeHealthScore } from '../model/';
// @ts-ignore
import { RoleBaseDashboardOverviewDto } from '../model/';
/**
 * C2TypeHealthScoreApi - axios parameter creator
 * @export
 */
export const C2TypeHealthScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all C2-Type-Health-Score against particular date
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindAll: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindAll', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindAll', 'dateType', dateType)
            const localVarPath = `/api/c2-type-health-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2-Type-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindAllByDateRange: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, c2TypeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindAllByDateRange', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindAllByDateRange', 'dateType', dateType)
            const localVarPath = `/api/c2-type-health-score/date-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2_type_id'] = c2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2 Type Health Score by Date Info and C2 Type ID.
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindByTypeDateInfo: async (date: string, dateType: string, c2TypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindByTypeDateInfo', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindByTypeDateInfo', 'dateType', dateType)
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindByTypeDateInfo', 'c2TypeId', c2TypeId)
            const localVarPath = `/api/c2-type-health-score/date-filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2_type_id'] = c2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2-Type-Health-Score-against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindOne', 'id', id)
            const localVarPath = `/api/c2-type-health-score/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2-Type-Health-Score-against C2TypeId
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindOneByDate: async (c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindOneByDate', 'c2TypeId', c2TypeId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindOneByDate', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2TypeHealthScoreControllerFindOneByDate', 'dateType', dateType)
            const localVarPath = `/api/c2-type-health-score/{c2TypeId}/by-type-id`
                .replace(`{${"c2TypeId"}}`, encodeURIComponent(String(c2TypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2TypeHealthScoreApi - functional programming interface
 * @export
 */
export const C2TypeHealthScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2TypeHealthScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of all C2-Type-Health-Score against particular date
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypeHealthScoreControllerFindAll(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2TypeHealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypeHealthScoreControllerFindAll(date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2-Type-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypeHealthScoreControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, c2TypeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2TypeHealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypeHealthScoreControllerFindAllByDateRange(date, dateType, xTenantId, c2TypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2 Type Health Score by Date Info and C2 Type ID.
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypeHealthScoreControllerFindByTypeDateInfo(date: string, dateType: string, c2TypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseDashboardOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypeHealthScoreControllerFindByTypeDateInfo(date, dateType, c2TypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2-Type-Health-Score-against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypeHealthScoreControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2TypeHealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypeHealthScoreControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2-Type-Health-Score-against C2TypeId
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2TypeHealthScoreControllerFindOneByDate(c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2TypeHealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2TypeHealthScoreControllerFindOneByDate(c2TypeId, date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2TypeHealthScoreApi - factory interface
 * @export
 */
export const C2TypeHealthScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2TypeHealthScoreApiFp(configuration)
    return {
        /**
         * 
         * @summary List of all C2-Type-Health-Score against particular date
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindAll(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<C2TypeHealthScore>> {
            return localVarFp.c2TypeHealthScoreControllerFindAll(date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2-Type-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {string} [c2TypeId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, c2TypeId?: string, options?: any): AxiosPromise<Array<C2TypeHealthScore>> {
            return localVarFp.c2TypeHealthScoreControllerFindAllByDateRange(date, dateType, xTenantId, c2TypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2 Type Health Score by Date Info and C2 Type ID.
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindByTypeDateInfo(date: string, dateType: string, c2TypeId: string, xTenantId?: string, options?: any): AxiosPromise<RoleBaseDashboardOverviewDto> {
            return localVarFp.c2TypeHealthScoreControllerFindByTypeDateInfo(date, dateType, c2TypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2-Type-Health-Score-against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<C2TypeHealthScore> {
            return localVarFp.c2TypeHealthScoreControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2-Type-Health-Score-against C2TypeId
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2TypeHealthScoreControllerFindOneByDate(c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<C2TypeHealthScore> {
            return localVarFp.c2TypeHealthScoreControllerFindOneByDate(c2TypeId, date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2TypeHealthScoreControllerFindAll operation in C2TypeHealthScoreApi.
 * @export
 * @interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllRequest
 */
export interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAll
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAll
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypeHealthScoreControllerFindAllByDateRange operation in C2TypeHealthScoreApi.
 * @export
 * @interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRangeRequest
 */
export interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRange
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRange
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRange
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRange
     */
    readonly c2TypeId?: string
}

/**
 * Request parameters for c2TypeHealthScoreControllerFindByTypeDateInfo operation in C2TypeHealthScoreApi.
 * @export
 * @interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfoRequest
 */
export interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfo
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfo
     */
    readonly dateType: string

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfo
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfo
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypeHealthScoreControllerFindOne operation in C2TypeHealthScoreApi.
 * @export
 * @interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneRequest
 */
export interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2TypeHealthScoreControllerFindOneByDate operation in C2TypeHealthScoreApi.
 * @export
 * @interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDateRequest
 */
export interface C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDateRequest {
    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDate
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDate
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDate
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDate
     */
    readonly xTenantId?: string
}

/**
 * C2TypeHealthScoreApi - object-oriented interface
 * @export
 * @class C2TypeHealthScoreApi
 * @extends {BaseAPI}
 */
export class C2TypeHealthScoreApi extends BaseAPI {
    /**
     * 
     * @summary List of all C2-Type-Health-Score against particular date
     * @param {C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeHealthScoreApi
     */
    public c2TypeHealthScoreControllerFindAll(requestParameters: C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllRequest, options?: AxiosRequestConfig) {
        return C2TypeHealthScoreApiFp(this.configuration).c2TypeHealthScoreControllerFindAll(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2-Type-Health-Score by date range
     * @param {C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeHealthScoreApi
     */
    public c2TypeHealthScoreControllerFindAllByDateRange(requestParameters: C2TypeHealthScoreApiC2TypeHealthScoreControllerFindAllByDateRangeRequest, options?: AxiosRequestConfig) {
        return C2TypeHealthScoreApiFp(this.configuration).c2TypeHealthScoreControllerFindAllByDateRange(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, requestParameters.c2TypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2 Type Health Score by Date Info and C2 Type ID.
     * @param {C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeHealthScoreApi
     */
    public c2TypeHealthScoreControllerFindByTypeDateInfo(requestParameters: C2TypeHealthScoreApiC2TypeHealthScoreControllerFindByTypeDateInfoRequest, options?: AxiosRequestConfig) {
        return C2TypeHealthScoreApiFp(this.configuration).c2TypeHealthScoreControllerFindByTypeDateInfo(requestParameters.date, requestParameters.dateType, requestParameters.c2TypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2-Type-Health-Score-against Id
     * @param {C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeHealthScoreApi
     */
    public c2TypeHealthScoreControllerFindOne(requestParameters: C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C2TypeHealthScoreApiFp(this.configuration).c2TypeHealthScoreControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2-Type-Health-Score-against C2TypeId
     * @param {C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2TypeHealthScoreApi
     */
    public c2TypeHealthScoreControllerFindOneByDate(requestParameters: C2TypeHealthScoreApiC2TypeHealthScoreControllerFindOneByDateRequest, options?: AxiosRequestConfig) {
        return C2TypeHealthScoreApiFp(this.configuration).c2TypeHealthScoreControllerFindOneByDate(requestParameters.c2TypeId, requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
