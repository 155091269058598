import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';
import { Layout } from '@/types/layout';

export const FeatureFlagRoutes: RouteRecordRaw[] = [
  {
    path: '/feature-flags',
    name: RouteName.FeatureFlagList,
    component: () => import('../views/FeatureFlags/FeatureFlagListView.vue'),
    meta: {
      title: 'Feature Flags',
      group: RouteName.FeatureFlagList,
      layout: Layout.Scratch,
    },
    children: [
      {
        path: ':id',
        name: RouteName.FeatureFlagDetail,
        component: () => import('../views/FeatureFlags/FeatureFlagDetailView.vue'),
        meta: {
          title: 'Feature Flags',
          group: RouteName.FeatureFlagList,
        },
      },
    ],
  },
];
