/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * CSEmailApi - axios parameter creator
 * @export
 */
export const CSEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get U1 authentication confirmation code
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csEmailControllerGetU1AutomationConfirmationCode: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cs-email/u1_automation_confirmation_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Email webhook
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csEmailControllerSave: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cs-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CSEmailApi - functional programming interface
 * @export
 */
export const CSEmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CSEmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get U1 authentication confirmation code
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csEmailControllerGetU1AutomationConfirmationCode(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csEmailControllerGetU1AutomationConfirmationCode(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Email webhook
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csEmailControllerSave(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csEmailControllerSave(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CSEmailApi - factory interface
 * @export
 */
export const CSEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CSEmailApiFp(configuration)
    return {
        /**
         * 
         * @summary Get U1 authentication confirmation code
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csEmailControllerGetU1AutomationConfirmationCode(xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.csEmailControllerGetU1AutomationConfirmationCode(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Email webhook
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csEmailControllerSave(xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csEmailControllerSave(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for csEmailControllerGetU1AutomationConfirmationCode operation in CSEmailApi.
 * @export
 * @interface CSEmailApiCsEmailControllerGetU1AutomationConfirmationCodeRequest
 */
export interface CSEmailApiCsEmailControllerGetU1AutomationConfirmationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof CSEmailApiCsEmailControllerGetU1AutomationConfirmationCode
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csEmailControllerSave operation in CSEmailApi.
 * @export
 * @interface CSEmailApiCsEmailControllerSaveRequest
 */
export interface CSEmailApiCsEmailControllerSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof CSEmailApiCsEmailControllerSave
     */
    readonly xTenantId?: string
}

/**
 * CSEmailApi - object-oriented interface
 * @export
 * @class CSEmailApi
 * @extends {BaseAPI}
 */
export class CSEmailApi extends BaseAPI {
    /**
     * 
     * @summary Get U1 authentication confirmation code
     * @param {CSEmailApiCsEmailControllerGetU1AutomationConfirmationCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSEmailApi
     */
    public csEmailControllerGetU1AutomationConfirmationCode(requestParameters: CSEmailApiCsEmailControllerGetU1AutomationConfirmationCodeRequest = {}, options?: AxiosRequestConfig) {
        return CSEmailApiFp(this.configuration).csEmailControllerGetU1AutomationConfirmationCode(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Email webhook
     * @param {CSEmailApiCsEmailControllerSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSEmailApi
     */
    public csEmailControllerSave(requestParameters: CSEmailApiCsEmailControllerSaveRequest = {}, options?: AxiosRequestConfig) {
        return CSEmailApiFp(this.configuration).csEmailControllerSave(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
