import { AppTypeNameEnum, KpiNameEnum, PeripheralApp } from '@/api/client';

export class PeripheralAppType {
  static readonly CRM = new PeripheralAppType('peripheral_app_type_names.crm', 'crm_title', AppTypeNameEnum.Crm, '');
  static readonly BUG_TRACKING = new PeripheralAppType(
    'peripheral_app_type_names.ticketing',
    'ticketing',
    AppTypeNameEnum.BugTrackingApp,
    KpiNameEnum.Bts,
  );
  static readonly EMAIL_SYSTEM = new PeripheralAppType(
    'peripheral_app_type_names.email_system',
    'email_system',
    AppTypeNameEnum.EmailAndCommunication,
    KpiNameEnum.Ccs,
  );
  static readonly FEATURE_REQUESTS = new PeripheralAppType(
    'peripheral_app_type_names.feature_requests',
    'feature_requests',
    AppTypeNameEnum.FeatureRequestApp,
    KpiNameEnum.Frs,
  );
  static readonly PAYMENT_SYSTEM = new PeripheralAppType(
    'peripheral_app_type_names.payment_system',
    'payment_system',
    AppTypeNameEnum.SubscriptionAndPaymentApp,
    KpiNameEnum.Pas,
  );
  // private to disallow creating other instances of this type
  private constructor(
    private readonly name: string,
    public readonly title: string,
    public readonly type: string,
    public readonly kpiName: string,
  ) {}

  toString() {
    return this.name;
  }
}

export interface IKpiHealthRange {
  kpi_id: string;
  red_end: number;
  yellow_start: number;
  yellow_end: number;
  green_start: number;
}

export interface UpdatePeripheralAppCallback {
  (status: boolean, message: string): void;
}

export interface IPayloadData {
  userId: string;
  userName: string;
  solutionId: string;
  app_name: string;
  securityCode: string;
  isTestUser: boolean;
  email?: string | undefined;
  integrationType?: string | undefined;
}

export interface IAppsForAutoIntegrationCallback {
  (apps: PeripheralApp[], isSuccess: boolean, failureMsg?: string): void;
}

export interface IPeripheralOAuthCallback {
  is_success: boolean;
  app_name: string;
  state: string;
  failure_msg?: string | undefined;
}

export interface ICommSystemIntegrationStatus {
  u1_id: string;
  u1_name: string;
  u1_role_id: string;
  u1_role_name: string;
  c1_department_id: string;
  c1_department_name: string;
  integrated_apps: string[];
}
