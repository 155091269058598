<script setup lang="ts">
import { useFetchAppNotificationsQuery, useReadAllQuery } from '@/composables/queries/appNotificationsQuery';
import { phBellSimple, phInfoFill } from '@/phosphor-icons';
import AppNotificationItem from './AppNotificationComponents/AppNotificationItem.vue';
import { useI18n } from 'vue-i18n';
import { MessageSchema } from '@/lib/i18n';
import EmptyState from './EmptyState.vue';
import { QMenu } from 'quasar';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { AppNotificationTypeEnum } from '@/api/client';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const { data: notifications } = useFetchAppNotificationsQuery();
const { isLoading, mutate } = useReadAllQuery();

const router = useRouter();
const notificationsMenu = ref<QMenu>();

const onReadAll = () => {
  const notificationIds = notifications.value?.map((x) => x.id) ?? [];
  if (notificationIds.length) mutate(notificationIds);
};

const onNavigate = (url: string) => {
  router.replace({ path: url });
  notificationsMenu.value?.hide();
};

const getNotificationBtnBorder = computed(() => {
  if (notifications.value?.length) return 'tw-border-sky-500';
  else return '';
});

const changeNotificationColor = (notifications: any) => {
  if (
    notifications.type === AppNotificationTypeEnum.CsvDownload &&
    (notifications.message.includes('failed') || notifications.message.includes('Failed'))
  ) {
    return 'tw-text-data-viz-semantic-red-content';
  } else {
    return 'tw-text-data-viz-semantic-green-content';
  }
};
</script>

<template>
  <div class="tw-relative tw-inline-flex">
    <span class="tw-absolute tw-right-0 tw-top-0 tw-flex tw-h-2.5 tw-w-2.5" v-if="notifications?.length">
      <span
        class="tw-absolute tw-inline-flex tw-h-full tw-w-full tw-animate-ping tw-rounded-full tw-bg-sky-500 tw-opacity-75"
      ></span>
      <span class="tw-relative tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-sky-500"></span>
    </span>
    <button
      class="tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border hover:tw-bg-gray-100"
      :class="getNotificationBtnBorder"
    >
      <q-icon :name="phBellSimple" class="tw-h-4 tw-w-4" />
      <q-menu ref="notificationsMenu" class="tw-w-96 tw-rounded tw-border tw-bg-light-shade-1 tw-shadow">
        <div class="tw-flex tw-flex-col tw-bg-light-shade-1 tw-shadow">
          <div class="tw-flex tw-place-items-center tw-px-4 tw-py-3 tw-font-semibold tw-text-dark-shade-4">
            <span>{{ t('app_notifications.title') }} </span>
            <span
              class="tw-ml-1 tw-rounded-lg tw-bg-primary tw-px-2 tw-text-sm tw-font-bold tw-text-white"
              v-if="notifications?.length"
              >{{ notifications?.length }}</span
            >
          </div>
          <q-separator />
          <q-scroll-area v-if="notifications?.length" class="tw-h-[20rem]">
            <q-list separator class="tw-text-sm tw-font-medium tw-text-dark-shade-1">
              <q-item v-for="item in notifications" :key="item.id">
                <q-item-section class="tw-min-w-0" avatar>
                  <q-icon size="1.5rem" :name="phInfoFill" :class="changeNotificationColor(item)" />
                  <!-- class="tw-text-data-viz-semantic-green-content" -->
                </q-item-section>
                <q-item-section><AppNotificationItem :item="item" @on-navigate="onNavigate" /></q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
          <EmptyState
            v-else
            class="tw-h-[20rem]"
            header="No Notifications"
            description="You do not have any unread notification"
          ></EmptyState>
          <q-separator />
          <div v-if="notifications?.length" class="tw-flex tw-flex-row-reverse tw-px-4 tw-py-2">
            <q-btn
              flat
              dense
              :loading="isLoading"
              :label="t('app_notifications.read_all')"
              no-caps
              class="tw-tex-xs tw-text-primary-dark"
              @click="onReadAll"
            />
          </div>
        </div>
      </q-menu>
    </button>
  </div>
</template>
