import {
  phCheck,
  phWarning,
  phInfo,
  phArrowUp,
  phArrowRight,
  phArrowDown,
  phArrowLeft,
  phCaretDown,
  phAperture,
  phSliders,
  phSquaresFour,
  phArrowClockwise,
  phCaretLeft,
  phCaretRight,
  phCaretUp,
  phSquareFill,
  phXCircle,
  phClock,
  phCalendarX,
  phLinkSimpleHorizontal,
  phArrowsOut,
  phChatCenteredDotsFill,
  phPrinter,
  phArrowUUpLeft,
  phArrowUUpRight,
  phCode,
  phTextBBold,
  phTextItalic,
  phTextStrikethrough,
  phTextUnderline,
  phListBullets,
  phListNumbers,
  phAlignLeft,
  phTextAlignLeft,
  phTextAlignCenter,
  phTextAlignRight,
  phTextAlignJustify,
  phTextAa,
  phMinus,
  phTextH,
  phTextHOne,
  phTextHTwo,
  phTextHThree,
  phTextHFour,
  phTextHFive,
  phTextHSix,
  phNumberSquareOne,
  phNumberSquareTwo,
  phNumberSquareThree,
  phNumberSquareFour,
  phNumberSquareFive,
  phNumberSquareSix,
  phNumberSquareSeven,
  phPlus,
  phX,
  phSkipBackCircle,
  phSkipForwardCircle,
  phSkipBack,
  phSkipForward,
  phStar,
  phNotePencil,
  phPlay,
  phPlusCircle,
  phCloudArrowUp,
  phTrash,
  phChecks,
} from '@/phosphor-icons';

import {
  mdiFormatClear,
  mdiFormatColorText,
  mdiFormatFont,
  mdiFormatIndentDecrease,
  mdiFormatIndentIncrease,
  mdiFormatSize,
  mdiFormatSubscript,
  mdiFormatSuperscript,
} from '@quasar/extras/mdi-v6';

export default {
  name: 'svg-ionicons-v6',
  type: {
    positive: phCheck,
    negative: phWarning,
    info: phInfo,
    warning: phWarning,
  },
  arrow: {
    up: phArrowUp,
    right: phArrowRight,
    down: phArrowDown,
    left: phArrowLeft,
    dropdown: phCaretDown,
  },
  chevron: {
    left: phArrowLeft,
    right: phArrowRight,
  },
  colorPicker: {
    spectrum: phAperture,
    tune: phSliders,
    palette: phSquaresFour,
  },
  pullToRefresh: {
    icon: phArrowClockwise,
  },
  carousel: {
    left: phCaretLeft,
    right: phCaretRight,
    up: phCaretUp,
    down: phCaretDown,
    navigationIcon: phSquareFill,
  },
  chip: {
    remove: phXCircle,
    selected: phCheck,
  },
  datetime: {
    arrowLeft: phCaretLeft,
    arrowRight: phCaretRight,
    now: phClock,
    today: phCalendarX,
  },
  editor: {
    // requires Material icons for some as Phosphor simply does not have everything needed
    hyperlink: phLinkSimpleHorizontal,
    toggleFullscreen: phArrowsOut,
    quote: phChatCenteredDotsFill,
    print: phPrinter,
    undo: phArrowUUpLeft,
    redo: phArrowUUpRight,
    code: phCode,
    viewSource: phCode,

    bold: phTextBBold,
    italic: phTextItalic,
    strikethrough: phTextStrikethrough,
    underline: phTextUnderline,
    unorderedList: phListBullets,
    orderedList: phListNumbers,
    subscript: mdiFormatSubscript,
    superscript: mdiFormatSuperscript,
    left: phTextAlignLeft,
    center: phTextAlignCenter,
    right: phTextAlignRight,
    justify: phTextAlignJustify,
    outdent: mdiFormatIndentDecrease,
    indent: mdiFormatIndentIncrease,
    removeFormat: mdiFormatClear,
    formatting: mdiFormatColorText,
    fontSize: phTextAa,
    align: phAlignLeft,
    hr: phMinus,
    heading: phTextH,
    heading1: phTextHOne,
    heading2: phTextHTwo,
    heading3: phTextHThree,
    heading4: phTextHFour,
    heading5: phTextHFive,
    heading6: phTextHSix,
    size: mdiFormatSize,
    size1: phNumberSquareOne,
    size2: phNumberSquareTwo,
    size3: phNumberSquareThree,
    size4: phNumberSquareFour,
    size5: phNumberSquareFive,
    size6: phNumberSquareSix,
    size7: phNumberSquareSeven,
    font: mdiFormatFont,
  },
  expansionItem: {
    icon: phCaretDown,
    denseIcon: phCaretDown,
  },
  fab: {
    icon: phPlus,
    activeIcon: phX,
  },
  field: {
    clear: phXCircle,
    error: phWarning,
  },
  pagination: {
    first: phSkipBackCircle,
    prev: phSkipBack,
    next: phSkipForward,
    last: phSkipForwardCircle,
  },
  rating: {
    icon: phStar,
  },
  stepper: {
    done: phCheck,
    active: phNotePencil,
    error: phWarning,
  },
  tabs: {
    left: phCaretLeft,
    right: phCaretRight,
    up: phCaretUp,
    down: phCaretDown,
  },
  table: {
    arrowUp: phArrowUp,
    warning: phWarning,
    firstPage: phSkipBackCircle,
    prevPage: phSkipBack,
    nextPage: phSkipForward,
    lastPage: phSkipForwardCircle,
  },
  tree: {
    icon: phPlay,
  },
  uploader: {
    done: phCheck,
    clear: phX,
    add: phPlusCircle,
    upload: phCloudArrowUp,
    removeQueue: phTrash,
    removeUploaded: phChecks,
  },
};
