import { Layout } from '@/types/layout';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DashboardContainer from '../views/DashboardContainer.vue';
// import DashboardV2 from '../views/DashboardV2.vue';
import { CustomerDetailsRoutes, CustomerRoutes } from './customer-routes';
import { OnboardingRoutes, OnboardingRoutesRoleBased } from './onboarding-routes';
import { RouteName } from './route-name';
import { UserRoutes, UsersRoutes } from '@/router/user-routes';
import {
  ZapbookRoutes,
  ZapbookV2Routes,
  // ZapbookV2ActionRoute,
  // ZapbookV2MessageRoute,
  // ZapbookV2PerformanceRoute,
  // ZapbookV2SuccessPlanRoute,
  // ZapbookV2TaskRoute,
} from '@/router/zapbook-routes';
import { PlaybookRoutes } from './playbook-routes';
import { SuccessPlanRoutes } from './success-plan-routes';
import { InboxRoutes } from './inbox-routes';
import { CampaignRoutes } from './campaign-routes';
import { ManageRoutes } from './manage-routes';
import { FeatureFlagRoutes } from './feature-flag-routes';
import { SegmentRoutes } from './segment-routes';
import { CsatSurveyDetailsRoute, NpsSurveyDetailsRoute, NpsSurveyStatisticsRoute, SurveyRoutes } from './survey-routes';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: RouteName.Dashboard,
    component: DashboardContainer,
    meta: {
      title: 'Dashboard',
      layout: Layout.Authenticated,
    },
  },
  // {
  //   path: '/segment-health',
  //   name: RouteName.SegmentHealth,
  //   component: () => import('../views/SegmentHealth.vue'),
  //   meta: {
  //     title: 'Segment Health',
  //     group: RouteName.SegmentHealth,
  //     layout: Layout.Authenticated,
  //   },
  // },
  {
    path: '/editor',
    name: 'Editor',
    component: () => import('../views/Editor.vue'),
    meta: {
      skipAuth: true,
    },
  },
  {
    path: '/register',
    name: RouteName.Register,
    component: () => import('../views/Register.vue'),
    props: (route) => ({ redirectTo: route.query.redirect_to }),
    meta: {
      skipAuth: true,
      title: 'Register',
    },
  },
  {
    path: '/login',
    name: RouteName.Login,
    component: () => import('../views/Login.vue'),
    props: (route) => ({ redirectTo: route.query.redirect_to }),
    meta: {
      skipAuth: true,
      title: 'Login',
    },
  },
  {
    path: '/login/:provider/verify',
    name: RouteName.SsoLoginVerify,
    component: () => import('../views/SsoLoginVerify.vue'),
    meta: {
      skipAuth: true,
      title: 'Login',
    },
  },
  {
    path: '/register/:provider/verify',
    name: RouteName.SsoRegisterVerify,
    component: () => import('../views/SsoRegisterVerify.vue'),
    meta: {
      skipAuth: true,
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: RouteName.Logout,
    component: () => import('../views/Logout.vue'),
    meta: {
      skipAuth: true,
    },
  },
  {
    path: '/verify-email',
    name: RouteName.VerifyEmail,
    component: () => import('../views/VerifyEmail.vue'),
    meta: {
      skipAuth: true,
      title: 'Verify your email',
    },
  },
  {
    path: '/reset-password',
    name: RouteName.ResetPasswordRequest,
    component: () => import('../views/ResetPasswordRequest.vue'),
    meta: {
      skipAuth: true,
      title: 'Reset password',
    },
  },
  {
    path: '/reset-password/:token',
    name: RouteName.ResetPassword,
    component: () => import('../views/ResetPassword.vue'),
    props: true,
    meta: {
      skipAuth: true,
      title: 'Create new password',
    },
  },
  {
    path: '/get-started',
    name: 'GetStarted',
    component: () => import('../views/GetStarted.vue'),
    meta: {
      appDrawer: false,
      title: 'Get Started',
    },
  },
  {
    path: '/accept-invitation/:token',
    name: RouteName.AcceptInvitation,
    component: () => import('../views/AcceptInvitation.vue'),
    props: true,
    meta: {
      skipAuth: true,
      title: 'Accept invitation',
    },
  },
  {
    path: '/accept-help-request/:token',
    name: RouteName.AcceptHelpRequest,
    component: () => import('../views/AcceptHelpRequest.vue'),
    props: true,
    meta: {
      skipAuth: true,
      title: 'Accept help request',
    },
  },
  {
    path: '/search',
    component: () => import('@/views/Search/SearchOverview.vue'),
    props: (route) => ({ redirectTo: route.query.redirect_to }),
    meta: {
      title: 'Search',
      layout: Layout.Authenticated,
    },
    children: [
      {
        path: '',
        name: RouteName.Search,
        component: () => import('@/views/Search/SearchResultView.vue'),
      },
    ],
  },
  CustomerRoutes,
  CustomerDetailsRoutes,
  UsersRoutes,
  UserRoutes,
  SegmentRoutes,
  PlaybookRoutes,
  SuccessPlanRoutes,
  CampaignRoutes,
  ...InboxRoutes,
  ManageRoutes,
  ...FeatureFlagRoutes,
  ...SurveyRoutes,
  NpsSurveyDetailsRoute,
  CsatSurveyDetailsRoute,
  NpsSurveyStatisticsRoute,
];

if (import.meta.env.VITE_FF_ZAPBOOK_V2_ENABLED === '1') {
  routes.push(ZapbookV2Routes);
} else {
  routes.push(ZapbookRoutes);
}

if (import.meta.env.VITE_FF_ROLE_BASED_ACCESS === '1') {
  routes.push(OnboardingRoutesRoleBased);
} else {
  routes.push(OnboardingRoutes);
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
