/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateProductUsageMappingDto } from '../model/';
// @ts-ignore
import { ProductUsageMapping } from '../model/';
// @ts-ignore
import { UpdateProductUsageMappingDto } from '../model/';
/**
 * ProductUsageMappingApi - axios parameter creator
 * @export
 */
export const ProductUsageMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Product Usage Mapping
         * @param {CreateProductUsageMappingDto} createProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerCreateProductUsageMapping: async (createProductUsageMappingDto: CreateProductUsageMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductUsageMappingDto' is not null or undefined
            assertParamExists('productUsageMappingControllerCreateProductUsageMapping', 'createProductUsageMappingDto', createProductUsageMappingDto)
            const localVarPath = `/api/product-usage-mapping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductUsageMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary App wise list of product usage mappings
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerFindAll: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('productUsageMappingControllerFindAll', 'appId', appId)
            const localVarPath = `/api/product-usage-mapping/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Mapping against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productUsageMappingControllerRemove', 'id', id)
            const localVarPath = `/api/product-usage-mapping/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Product Usage Mapping
         * @param {string} id 
         * @param {UpdateProductUsageMappingDto} updateProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerUpdateProductUsageMapping: async (id: string, updateProductUsageMappingDto: UpdateProductUsageMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productUsageMappingControllerUpdateProductUsageMapping', 'id', id)
            // verify required parameter 'updateProductUsageMappingDto' is not null or undefined
            assertParamExists('productUsageMappingControllerUpdateProductUsageMapping', 'updateProductUsageMappingDto', updateProductUsageMappingDto)
            const localVarPath = `/api/product-usage-mapping/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductUsageMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Insert or Update Product Usage Mappings
         * @param {Array<UpdateProductUsageMappingDto>} updateProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerUpsertProductUsageMappings: async (updateProductUsageMappingDto: Array<UpdateProductUsageMappingDto>, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProductUsageMappingDto' is not null or undefined
            assertParamExists('productUsageMappingControllerUpsertProductUsageMappings', 'updateProductUsageMappingDto', updateProductUsageMappingDto)
            const localVarPath = `/api/product-usage-mapping/upsert-mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductUsageMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductUsageMappingApi - functional programming interface
 * @export
 */
export const ProductUsageMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductUsageMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Product Usage Mapping
         * @param {CreateProductUsageMappingDto} createProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUsageMappingControllerCreateProductUsageMapping(createProductUsageMappingDto: CreateProductUsageMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUsageMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUsageMappingControllerCreateProductUsageMapping(createProductUsageMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary App wise list of product usage mappings
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUsageMappingControllerFindAll(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductUsageMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUsageMappingControllerFindAll(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Mapping against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUsageMappingControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUsageMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUsageMappingControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Product Usage Mapping
         * @param {string} id 
         * @param {UpdateProductUsageMappingDto} updateProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUsageMappingControllerUpdateProductUsageMapping(id: string, updateProductUsageMappingDto: UpdateProductUsageMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUsageMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUsageMappingControllerUpdateProductUsageMapping(id, updateProductUsageMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Insert or Update Product Usage Mappings
         * @param {Array<UpdateProductUsageMappingDto>} updateProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUsageMappingControllerUpsertProductUsageMappings(updateProductUsageMappingDto: Array<UpdateProductUsageMappingDto>, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUsageMappingControllerUpsertProductUsageMappings(updateProductUsageMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductUsageMappingApi - factory interface
 * @export
 */
export const ProductUsageMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductUsageMappingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Product Usage Mapping
         * @param {CreateProductUsageMappingDto} createProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerCreateProductUsageMapping(createProductUsageMappingDto: CreateProductUsageMappingDto, xTenantId?: string, options?: any): AxiosPromise<ProductUsageMapping> {
            return localVarFp.productUsageMappingControllerCreateProductUsageMapping(createProductUsageMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary App wise list of product usage mappings
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerFindAll(appId: string, xTenantId?: string, options?: any): AxiosPromise<Array<ProductUsageMapping>> {
            return localVarFp.productUsageMappingControllerFindAll(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Mapping against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<ProductUsageMapping> {
            return localVarFp.productUsageMappingControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Product Usage Mapping
         * @param {string} id 
         * @param {UpdateProductUsageMappingDto} updateProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerUpdateProductUsageMapping(id: string, updateProductUsageMappingDto: UpdateProductUsageMappingDto, xTenantId?: string, options?: any): AxiosPromise<ProductUsageMapping> {
            return localVarFp.productUsageMappingControllerUpdateProductUsageMapping(id, updateProductUsageMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Insert or Update Product Usage Mappings
         * @param {Array<UpdateProductUsageMappingDto>} updateProductUsageMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUsageMappingControllerUpsertProductUsageMappings(updateProductUsageMappingDto: Array<UpdateProductUsageMappingDto>, xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.productUsageMappingControllerUpsertProductUsageMappings(updateProductUsageMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productUsageMappingControllerCreateProductUsageMapping operation in ProductUsageMappingApi.
 * @export
 * @interface ProductUsageMappingApiProductUsageMappingControllerCreateProductUsageMappingRequest
 */
export interface ProductUsageMappingApiProductUsageMappingControllerCreateProductUsageMappingRequest {
    /**
     * 
     * @type {CreateProductUsageMappingDto}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerCreateProductUsageMapping
     */
    readonly createProductUsageMappingDto: CreateProductUsageMappingDto

    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerCreateProductUsageMapping
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for productUsageMappingControllerFindAll operation in ProductUsageMappingApi.
 * @export
 * @interface ProductUsageMappingApiProductUsageMappingControllerFindAllRequest
 */
export interface ProductUsageMappingApiProductUsageMappingControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerFindAll
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for productUsageMappingControllerRemove operation in ProductUsageMappingApi.
 * @export
 * @interface ProductUsageMappingApiProductUsageMappingControllerRemoveRequest
 */
export interface ProductUsageMappingApiProductUsageMappingControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for productUsageMappingControllerUpdateProductUsageMapping operation in ProductUsageMappingApi.
 * @export
 * @interface ProductUsageMappingApiProductUsageMappingControllerUpdateProductUsageMappingRequest
 */
export interface ProductUsageMappingApiProductUsageMappingControllerUpdateProductUsageMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerUpdateProductUsageMapping
     */
    readonly id: string

    /**
     * 
     * @type {UpdateProductUsageMappingDto}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerUpdateProductUsageMapping
     */
    readonly updateProductUsageMappingDto: UpdateProductUsageMappingDto

    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerUpdateProductUsageMapping
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for productUsageMappingControllerUpsertProductUsageMappings operation in ProductUsageMappingApi.
 * @export
 * @interface ProductUsageMappingApiProductUsageMappingControllerUpsertProductUsageMappingsRequest
 */
export interface ProductUsageMappingApiProductUsageMappingControllerUpsertProductUsageMappingsRequest {
    /**
     * 
     * @type {Array<UpdateProductUsageMappingDto>}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerUpsertProductUsageMappings
     */
    readonly updateProductUsageMappingDto: Array<UpdateProductUsageMappingDto>

    /**
     * 
     * @type {string}
     * @memberof ProductUsageMappingApiProductUsageMappingControllerUpsertProductUsageMappings
     */
    readonly xTenantId?: string
}

/**
 * ProductUsageMappingApi - object-oriented interface
 * @export
 * @class ProductUsageMappingApi
 * @extends {BaseAPI}
 */
export class ProductUsageMappingApi extends BaseAPI {
    /**
     * 
     * @summary Create Product Usage Mapping
     * @param {ProductUsageMappingApiProductUsageMappingControllerCreateProductUsageMappingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUsageMappingApi
     */
    public productUsageMappingControllerCreateProductUsageMapping(requestParameters: ProductUsageMappingApiProductUsageMappingControllerCreateProductUsageMappingRequest, options?: AxiosRequestConfig) {
        return ProductUsageMappingApiFp(this.configuration).productUsageMappingControllerCreateProductUsageMapping(requestParameters.createProductUsageMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary App wise list of product usage mappings
     * @param {ProductUsageMappingApiProductUsageMappingControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUsageMappingApi
     */
    public productUsageMappingControllerFindAll(requestParameters: ProductUsageMappingApiProductUsageMappingControllerFindAllRequest, options?: AxiosRequestConfig) {
        return ProductUsageMappingApiFp(this.configuration).productUsageMappingControllerFindAll(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Mapping against ID
     * @param {ProductUsageMappingApiProductUsageMappingControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUsageMappingApi
     */
    public productUsageMappingControllerRemove(requestParameters: ProductUsageMappingApiProductUsageMappingControllerRemoveRequest, options?: AxiosRequestConfig) {
        return ProductUsageMappingApiFp(this.configuration).productUsageMappingControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Product Usage Mapping
     * @param {ProductUsageMappingApiProductUsageMappingControllerUpdateProductUsageMappingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUsageMappingApi
     */
    public productUsageMappingControllerUpdateProductUsageMapping(requestParameters: ProductUsageMappingApiProductUsageMappingControllerUpdateProductUsageMappingRequest, options?: AxiosRequestConfig) {
        return ProductUsageMappingApiFp(this.configuration).productUsageMappingControllerUpdateProductUsageMapping(requestParameters.id, requestParameters.updateProductUsageMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Insert or Update Product Usage Mappings
     * @param {ProductUsageMappingApiProductUsageMappingControllerUpsertProductUsageMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUsageMappingApi
     */
    public productUsageMappingControllerUpsertProductUsageMappings(requestParameters: ProductUsageMappingApiProductUsageMappingControllerUpsertProductUsageMappingsRequest, options?: AxiosRequestConfig) {
        return ProductUsageMappingApiFp(this.configuration).productUsageMappingControllerUpsertProductUsageMappings(requestParameters.updateProductUsageMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
