<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts"></script>

<template>
  <svg viewBox="0 0 166 16" class="tw-h-4 tw-w-auto" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1266_39767)">
      <g style="mix-blend-mode: multiply">
        <path
          d="M65.0138 15.9603C64.5058 15.9398 64.0077 15.8132 63.5516 15.5886C63.0955 15.364 62.6915 15.0464 62.3655 14.6562C61.9261 14.0677 61.6431 13.3774 61.543 12.6499V11.8976C61.6518 11.5911 61.8426 11.3205 62.0947 11.1151C62.3587 10.9845 62.6588 10.9457 62.9474 11.0048C63.5191 11.1653 63.81 11.4662 63.81 11.8976C63.8012 12.2868 63.9057 12.6702 64.111 13.001C64.3218 13.3217 64.6431 13.5538 65.0138 13.653H77.222C77.8484 13.5296 78.4222 13.2182 78.8671 12.7603C79.0603 12.4528 79.1962 12.113 79.2684 11.7571V10.8643C79.2516 10.5912 79.1756 10.3251 79.0457 10.0843C78.9158 9.84353 78.7351 9.6339 78.516 9.46997C78.1551 9.18685 77.7262 9.00351 77.2721 8.93831H66.0069C64.6432 8.87046 63.3577 8.28155 62.4157 7.29317C61.9562 6.73791 61.6547 6.06914 61.543 5.35712V3.86244C61.7107 2.90187 62.2071 2.02949 62.9474 1.39473C63.8296 0.754914 64.8706 0.369739 65.9568 0.28125H77.9041C78.913 0.34141 79.8669 0.7614 80.5925 1.46495C81.2558 2.22526 81.6133 3.20471 81.5956 4.21354C81.5763 4.37527 81.5245 4.53145 81.4435 4.67277C81.3625 4.81408 81.2539 4.93766 81.1242 5.03612C80.83 5.23537 80.4735 5.32093 80.121 5.27687C79.8054 5.16814 79.541 4.94664 79.3787 4.65492C79.2591 4.33285 79.1881 3.99477 79.1681 3.65178C79.1681 3.20037 78.7467 2.85931 78.0044 2.64865H65.8665C65.2249 2.7287 64.6302 3.02608 64.1812 3.49129C63.9403 3.87583 63.8149 4.32153 63.8201 4.7753C63.8223 5.00357 63.8757 5.22844 63.9764 5.43331C64.0771 5.63819 64.2225 5.81783 64.4019 5.959C64.8333 6.2715 65.3375 6.46835 65.8665 6.53078H77.2119C78.5198 6.6583 79.7327 7.27013 80.6126 8.24614C81.1926 9.0114 81.5408 9.92708 81.6157 10.8844V11.6468C81.5806 12.2666 81.4223 12.8733 81.1502 13.4313C80.8781 13.9893 80.4975 14.4876 80.0307 14.8969C79.1575 15.5518 78.111 15.9355 77.0213 16.0004L65.0138 15.9603Z"
          fill="#414042"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M86.8224 15.9198C86.2398 15.8505 85.6673 15.7124 85.1171 15.5085C84.5636 15.3095 84.0628 14.987 83.6525 14.5655C83.3466 14.2277 83.0991 13.8412 82.9203 13.422C82.7389 13.0225 82.6107 12.6009 82.5391 12.168V4.62446C82.6334 4.01973 82.8053 3.42969 83.0507 2.86898C83.3059 2.29534 83.6666 1.77472 84.114 1.33418C84.4811 0.99454 84.9057 0.722813 85.3679 0.531673C85.8343 0.355278 86.3247 0.250438 86.8224 0.220703H99.151C99.6826 0.296244 100.204 0.430829 100.706 0.621959C101.227 0.839559 101.686 1.18445 102.04 1.62509C102.34 2.00805 102.559 2.44846 102.682 2.91914C102.799 3.39448 102.837 3.88607 102.792 4.37368C102.721 4.54211 102.623 4.69791 102.501 4.83512C102.384 4.96982 102.241 5.07907 102.08 5.15613L101.398 5.21631L100.796 4.86522C100.678 4.72823 100.588 4.56877 100.533 4.3964C100.478 4.22402 100.458 4.04228 100.475 3.86208C100.489 3.68025 100.455 3.49802 100.378 3.33289C100.3 3.16776 100.182 3.02529 100.034 2.91914L99.2613 2.56804H86.8224C86.5899 2.60766 86.3639 2.67853 86.1503 2.7787C85.9123 2.88657 85.6976 3.03991 85.5184 3.23011C85.3269 3.49038 85.1686 3.77342 85.0469 4.07274C84.9299 4.36486 84.8556 4.6723 84.8262 4.98559V11.6464C84.8532 11.8867 84.9105 12.1227 84.9967 12.3486C85.0782 12.5814 85.193 12.801 85.3378 13.0006C85.5393 13.1423 85.7505 13.2697 85.9698 13.3818C86.185 13.4847 86.4153 13.5524 86.6519 13.5825H99.2613L100.034 13.111C100.187 12.8203 100.266 12.4965 100.264 12.168C100.26 11.821 100.385 11.4848 100.616 11.2251C100.77 11.1077 100.943 11.0163 101.127 10.9542C101.332 10.9081 101.544 10.9081 101.749 10.9542C101.901 11.0313 102.045 11.1219 102.18 11.2251C102.317 11.3203 102.427 11.4479 102.501 11.5962L102.612 12.489C102.581 12.995 102.459 13.4913 102.251 13.9536C102.022 14.4431 101.677 14.8699 101.248 15.1975C100.939 15.4143 100.601 15.5866 100.244 15.7091C99.8805 15.8327 99.5035 15.9134 99.1209 15.9499L86.8224 15.9198Z"
          fill="#414042"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M125.051 15.8803C124.73 15.8308 124.438 15.6669 124.228 15.4188L124.008 14.9975V1.07398C124.093 0.817533 124.257 0.594774 124.477 0.437897C124.697 0.281019 124.961 0.198148 125.232 0.201256C125.407 0.216683 125.577 0.2705 125.73 0.358903C125.882 0.447306 126.014 0.568136 126.114 0.712853L126.295 1.07398V13.5329H143.107C143.392 13.5814 143.65 13.7325 143.831 13.9577C144.012 14.1829 144.105 14.4667 144.091 14.7554C144.078 15.0442 143.959 15.318 143.757 15.5252C143.556 15.7324 143.285 15.8587 142.997 15.8803H125.051Z"
          fill="#414042"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M146.007 2.61856L145.314 2.28752C145.203 2.13206 145.118 1.95918 145.064 1.77593C145.003 1.61108 144.983 1.43434 145.004 1.26012C145.025 1.08589 145.087 0.919123 145.185 0.773404C145.283 0.627685 145.414 0.507144 145.567 0.421616C145.72 0.336087 145.891 0.28799 146.067 0.28125H164.063C164.355 0.318912 164.621 0.466583 164.808 0.694062C164.994 0.921541 165.087 1.21165 165.066 1.50508C165.038 1.65739 164.99 1.80562 164.926 1.94646C164.865 2.08954 164.776 2.21911 164.665 2.32765L164.063 2.61856H146.007ZM146.067 15.9603C145.92 15.9191 145.776 15.8688 145.635 15.8098C145.481 15.7408 145.346 15.6338 145.244 15.4988L145.024 15.0775V7.43361C145.087 7.20381 145.215 6.99722 145.393 6.83878C145.571 6.68035 145.791 6.57686 146.027 6.54082H162.368L163.01 6.90194C163.125 7.05093 163.216 7.21672 163.281 7.39348C163.332 7.59422 163.332 7.80466 163.281 8.00539L162.99 8.53705C162.877 8.61143 162.76 8.67844 162.639 8.73768C162.509 8.80073 162.375 8.85437 162.237 8.89818H147.311V13.653H164.103C164.4 13.6884 164.671 13.8383 164.858 14.0707C165.046 14.3031 165.135 14.5997 165.106 14.8969C165.074 15.18 164.946 15.4435 164.743 15.6441C164.541 15.8447 164.276 15.9703 163.993 16.0004L146.067 15.9603Z"
          fill="#414042"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M121.91 16.0001C121.756 15.9706 121.605 15.927 121.459 15.8697C121.303 15.8106 121.163 15.7182 121.047 15.5988L118.339 11.4258H107.696L104.927 15.659C104.807 15.763 104.667 15.8417 104.516 15.8906C104.365 15.9394 104.205 15.9573 104.047 15.9433C103.889 15.9293 103.735 15.8835 103.595 15.8088C103.455 15.7342 103.331 15.632 103.232 15.5086C103.122 15.3708 103.047 15.2093 103.011 15.0371C102.981 14.8611 102.981 14.6814 103.011 14.5054L106.02 9.71044C106.09 9.49385 106.24 9.31244 106.44 9.20405C106.64 9.09565 106.874 9.06863 107.094 9.12862H116.794L112.009 2.01639C111.866 1.75792 111.818 1.45716 111.875 1.16717C111.932 0.877192 112.09 0.616626 112.32 0.431438C112.428 0.336455 112.555 0.264512 112.692 0.219981C112.829 0.175449 112.974 0.159257 113.118 0.172392C113.261 0.185528 113.4 0.227717 113.527 0.296391C113.654 0.365066 113.765 0.458791 113.855 0.571876L122.883 14.0941C122.992 14.2906 123.049 14.5115 123.049 14.7361C123.049 14.9608 122.992 15.1817 122.883 15.3781C122.727 15.5917 122.525 15.7668 122.291 15.8898C122.167 15.9349 122.039 15.9717 121.91 16.0001Z"
          fill="#414042"
        />
      </g>
      <path
        d="M1.11348 15.8397C0.790354 15.7968 0.495971 15.6316 0.29091 15.3782L0.0702191 14.9569C0.00427438 14.7641 -0.00428855 14.5562 0.0455678 14.3585C0.0954242 14.1609 0.201557 13.982 0.351096 13.8435L15.3981 2.47793H1.00313C0.775348 2.43378 0.563016 2.33098 0.387097 2.17969C0.211178 2.0284 0.0777543 1.83385 0 1.61523V1.01336C0.0605864 0.78416 0.188597 0.578436 0.367451 0.422833C0.546305 0.26723 0.767761 0.168915 1.00313 0.140625H19.0997C19.7517 0.200813 20.1028 0.6121 20.1028 1.36445C20.0892 1.53754 20.0404 1.70602 19.9593 1.85954C19.8782 2.01307 19.7666 2.14841 19.6314 2.25724L4.81505 13.4422H19.1398C19.4342 13.4823 19.7021 13.6334 19.8887 13.8647C20.0752 14.096 20.1662 14.3899 20.1429 14.6861C20.1103 14.9692 19.9823 15.2327 19.7799 15.4333C19.5775 15.6338 19.3128 15.7595 19.0295 15.7895L1.11348 15.8397Z"
        fill="#EE305F"
      />
      <path
        d="M40.176 13.9235L31.1478 0.401256C30.9157 0.171201 30.6106 0.0293001 30.2851 0C29.9632 0.0315556 29.662 0.173292 29.4325 0.401256L20.4042 13.9235C20.2954 14.1218 20.2383 14.3443 20.2383 14.5705C20.2383 14.7967 20.2954 15.0193 20.4042 15.2176L20.5146 15.3379C20.6293 15.4996 20.7792 15.6332 20.9529 15.7288C21.1266 15.8243 21.3197 15.8793 21.5177 15.8897H27.9578C28.0769 15.8909 28.1944 15.8619 28.2992 15.8053C28.404 15.7487 28.4927 15.6663 28.5569 15.566C28.6211 15.4657 28.6587 15.3507 28.6662 15.2318C28.6737 15.113 28.6509 14.9941 28.5999 14.8865L28.3691 14.4151C28.2507 14.1595 28.0606 13.9439 27.822 13.7943C27.5834 13.6448 27.3065 13.5677 27.0249 13.5724H23.3535L27.9578 6.71098L30.255 3.29028L37.1265 13.5724H33.5854C33.3036 13.5664 33.0262 13.643 32.7873 13.7927C32.5485 13.9424 32.3586 14.1588 32.2412 14.4151L32.0105 14.8865C31.9598 14.9934 31.937 15.1113 31.944 15.2293C31.9511 15.3474 31.9878 15.4617 32.0508 15.5618C32.1139 15.6618 32.2011 15.7443 32.3046 15.8016C32.408 15.859 32.5242 15.8892 32.6425 15.8897H39.0926C39.2911 15.8816 39.485 15.8275 39.6591 15.7318C39.8332 15.636 39.9826 15.5012 40.0958 15.3379L40.2162 15.2176C40.319 15.0159 40.3692 14.7916 40.3622 14.5654C40.3551 14.3392 40.2911 14.1184 40.176 13.9235Z"
        fill="#46B86D"
      />
      <path
        d="M41.4484 15.8392C41.1252 15.7962 40.8308 15.6311 40.6258 15.3777L40.4051 14.9564V8.13508C40.4392 7.89405 40.5597 7.67363 40.7443 7.51491C40.9288 7.35619 41.1648 7.26999 41.4082 7.27238H56.1543C56.781 7.19091 57.3522 6.87118 57.7493 6.37959C57.987 6.07358 58.1268 5.70301 58.1506 5.31627V4.45358C58.1338 4.19356 58.0648 3.93959 57.9476 3.7069C57.8304 3.4742 57.6674 3.26756 57.4684 3.09935C57.0714 2.78745 56.6022 2.58046 56.1042 2.49746H41.3781C41.1503 2.4533 40.938 2.35051 40.7621 2.19922C40.5862 2.04793 40.4528 1.85337 40.375 1.63476V1.03288C40.438 0.804968 40.5667 0.600659 40.7451 0.445453C40.9235 0.290246 41.1437 0.191019 41.3781 0.160156H55.8935C57.2207 0.234837 58.4663 0.825202 59.3643 1.8053C59.9608 2.58051 60.3104 3.51723 60.3675 4.4937V5.27615C60.3474 5.89742 60.2019 6.50822 59.9399 7.0719C59.6779 7.63558 59.3047 8.14052 58.8427 8.55639C58.0041 9.14626 57.032 9.51854 56.0139 9.63978H42.6922V14.9564C42.5631 15.277 42.3346 15.5477 42.0402 15.7288L41.4484 15.8392Z"
        fill="#FBB615"
      />
    </g>
    <defs>
      <clipPath id="clip0_1266_39767">
        <rect width="165.111" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
