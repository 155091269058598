/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateAppEventDto } from '../model/';
// @ts-ignore
import { CreateCustomEventDto } from '../model/';
// @ts-ignore
import { PageAttribute } from '../model/';
// @ts-ignore
import { PaginatedAppEventsDto } from '../model/';
// @ts-ignore
import { UpdateAppEventDto } from '../model/';
/**
 * AppEventsApi - axios parameter creator
 * @export
 */
export const AppEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAppEventDto} createAppEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerCreate: async (createAppEventDto: CreateAppEventDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppEventDto' is not null or undefined
            assertParamExists('appEventsControllerCreate', 'createAppEventDto', createAppEventDto)
            const localVarPath = `/api/app-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateCustomEventDto>} createCustomEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerCreateCustomEvents: async (createCustomEventDto: Array<CreateCustomEventDto>, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomEventDto' is not null or undefined
            assertParamExists('appEventsControllerCreateCustomEvents', 'createCustomEventDto', createCustomEventDto)
            const localVarPath = `/api/app-events/custom-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [productId] 
         * @param {string} [name] 
         * @param {boolean} [includeCustomEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerFindAll: async (xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, productId?: string, name?: string, includeCustomEvents?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (includeCustomEvents !== undefined) {
                localVarQueryParameter['include_custom_events'] = includeCustomEvents;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appEventsControllerFindOne', 'id', id)
            const localVarPath = `/api/app-events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerFindUsage: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appEventsControllerFindUsage', 'id', id)
            const localVarPath = `/api/app-events/{id}/usage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appEventsControllerRemove', 'id', id)
            const localVarPath = `/api/app-events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppEventDto} updateAppEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerUpdateOne: async (id: string, updateAppEventDto: UpdateAppEventDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appEventsControllerUpdateOne', 'id', id)
            // verify required parameter 'updateAppEventDto' is not null or undefined
            assertParamExists('appEventsControllerUpdateOne', 'updateAppEventDto', updateAppEventDto)
            const localVarPath = `/api/app-events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppEventsApi - functional programming interface
 * @export
 */
export const AppEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAppEventDto} createAppEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appEventsControllerCreate(createAppEventDto: CreateAppEventDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appEventsControllerCreate(createAppEventDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateCustomEventDto>} createCustomEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appEventsControllerCreateCustomEvents(createCustomEventDto: Array<CreateCustomEventDto>, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appEventsControllerCreateCustomEvents(createCustomEventDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [productId] 
         * @param {string} [name] 
         * @param {boolean} [includeCustomEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appEventsControllerFindAll(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, productId?: string, name?: string, includeCustomEvents?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAppEventsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appEventsControllerFindAll(xTenantId, skip, limit, sortBy, descending, before, after, productId, name, includeCustomEvents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appEventsControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appEventsControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appEventsControllerFindUsage(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appEventsControllerFindUsage(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appEventsControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appEventsControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppEventDto} updateAppEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appEventsControllerUpdateOne(id: string, updateAppEventDto: UpdateAppEventDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appEventsControllerUpdateOne(id, updateAppEventDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppEventsApi - factory interface
 * @export
 */
export const AppEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppEventsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAppEventDto} createAppEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerCreate(createAppEventDto: CreateAppEventDto, xTenantId?: string, options?: any): AxiosPromise<PageAttribute> {
            return localVarFp.appEventsControllerCreate(createAppEventDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateCustomEventDto>} createCustomEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerCreateCustomEvents(createCustomEventDto: Array<CreateCustomEventDto>, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.appEventsControllerCreateCustomEvents(createCustomEventDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [productId] 
         * @param {string} [name] 
         * @param {boolean} [includeCustomEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerFindAll(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, productId?: string, name?: string, includeCustomEvents?: boolean, options?: any): AxiosPromise<PaginatedAppEventsDto> {
            return localVarFp.appEventsControllerFindAll(xTenantId, skip, limit, sortBy, descending, before, after, productId, name, includeCustomEvents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<PageAttribute> {
            return localVarFp.appEventsControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerFindUsage(id: string, xTenantId?: string, options?: any): AxiosPromise<PageAttribute> {
            return localVarFp.appEventsControllerFindUsage(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<PageAttribute> {
            return localVarFp.appEventsControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppEventDto} updateAppEventDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appEventsControllerUpdateOne(id: string, updateAppEventDto: UpdateAppEventDto, xTenantId?: string, options?: any): AxiosPromise<PageAttribute> {
            return localVarFp.appEventsControllerUpdateOne(id, updateAppEventDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appEventsControllerCreate operation in AppEventsApi.
 * @export
 * @interface AppEventsApiAppEventsControllerCreateRequest
 */
export interface AppEventsApiAppEventsControllerCreateRequest {
    /**
     * 
     * @type {CreateAppEventDto}
     * @memberof AppEventsApiAppEventsControllerCreate
     */
    readonly createAppEventDto: CreateAppEventDto

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appEventsControllerCreateCustomEvents operation in AppEventsApi.
 * @export
 * @interface AppEventsApiAppEventsControllerCreateCustomEventsRequest
 */
export interface AppEventsApiAppEventsControllerCreateCustomEventsRequest {
    /**
     * 
     * @type {Array<CreateCustomEventDto>}
     * @memberof AppEventsApiAppEventsControllerCreateCustomEvents
     */
    readonly createCustomEventDto: Array<CreateCustomEventDto>

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerCreateCustomEvents
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appEventsControllerFindAll operation in AppEventsApi.
 * @export
 * @interface AppEventsApiAppEventsControllerFindAllRequest
 */
export interface AppEventsApiAppEventsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly after?: string

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly productId?: string

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof AppEventsApiAppEventsControllerFindAll
     */
    readonly includeCustomEvents?: boolean
}

/**
 * Request parameters for appEventsControllerFindOne operation in AppEventsApi.
 * @export
 * @interface AppEventsApiAppEventsControllerFindOneRequest
 */
export interface AppEventsApiAppEventsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appEventsControllerFindUsage operation in AppEventsApi.
 * @export
 * @interface AppEventsApiAppEventsControllerFindUsageRequest
 */
export interface AppEventsApiAppEventsControllerFindUsageRequest {
    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindUsage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerFindUsage
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appEventsControllerRemove operation in AppEventsApi.
 * @export
 * @interface AppEventsApiAppEventsControllerRemoveRequest
 */
export interface AppEventsApiAppEventsControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appEventsControllerUpdateOne operation in AppEventsApi.
 * @export
 * @interface AppEventsApiAppEventsControllerUpdateOneRequest
 */
export interface AppEventsApiAppEventsControllerUpdateOneRequest {
    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerUpdateOne
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAppEventDto}
     * @memberof AppEventsApiAppEventsControllerUpdateOne
     */
    readonly updateAppEventDto: UpdateAppEventDto

    /**
     * 
     * @type {string}
     * @memberof AppEventsApiAppEventsControllerUpdateOne
     */
    readonly xTenantId?: string
}

/**
 * AppEventsApi - object-oriented interface
 * @export
 * @class AppEventsApi
 * @extends {BaseAPI}
 */
export class AppEventsApi extends BaseAPI {
    /**
     * 
     * @param {AppEventsApiAppEventsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppEventsApi
     */
    public appEventsControllerCreate(requestParameters: AppEventsApiAppEventsControllerCreateRequest, options?: AxiosRequestConfig) {
        return AppEventsApiFp(this.configuration).appEventsControllerCreate(requestParameters.createAppEventDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppEventsApiAppEventsControllerCreateCustomEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppEventsApi
     */
    public appEventsControllerCreateCustomEvents(requestParameters: AppEventsApiAppEventsControllerCreateCustomEventsRequest, options?: AxiosRequestConfig) {
        return AppEventsApiFp(this.configuration).appEventsControllerCreateCustomEvents(requestParameters.createCustomEventDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppEventsApiAppEventsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppEventsApi
     */
    public appEventsControllerFindAll(requestParameters: AppEventsApiAppEventsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppEventsApiFp(this.configuration).appEventsControllerFindAll(requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.productId, requestParameters.name, requestParameters.includeCustomEvents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppEventsApiAppEventsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppEventsApi
     */
    public appEventsControllerFindOne(requestParameters: AppEventsApiAppEventsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return AppEventsApiFp(this.configuration).appEventsControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppEventsApiAppEventsControllerFindUsageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppEventsApi
     */
    public appEventsControllerFindUsage(requestParameters: AppEventsApiAppEventsControllerFindUsageRequest, options?: AxiosRequestConfig) {
        return AppEventsApiFp(this.configuration).appEventsControllerFindUsage(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppEventsApiAppEventsControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppEventsApi
     */
    public appEventsControllerRemove(requestParameters: AppEventsApiAppEventsControllerRemoveRequest, options?: AxiosRequestConfig) {
        return AppEventsApiFp(this.configuration).appEventsControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppEventsApiAppEventsControllerUpdateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppEventsApi
     */
    public appEventsControllerUpdateOne(requestParameters: AppEventsApiAppEventsControllerUpdateOneRequest, options?: AxiosRequestConfig) {
        return AppEventsApiFp(this.configuration).appEventsControllerUpdateOne(requestParameters.id, requestParameters.updateAppEventDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
