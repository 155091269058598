<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ message?: string }>();

const displayMessage = computed(() => {
  return props.message;
});
</script>

<template>
  <q-inner-loading
    showing
    color="primary"
    class="tw-z-10 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2"
    size="md"
    :label="displayMessage"
  />
</template>
