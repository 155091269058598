import { phTriangleFill } from '@/phosphor-icons';
import { MessagePriorityEnum, SuspectC2MatcherTypeEnum, TaskPriorityEnum, TaskStatusEnum } from '@/api/client';

export const useColorFormatter = () => {
  function getColorCodeAgainstString(value?: string) {
    if (!value) {
      return '';
    }
    switch (value.toLowerCase()) {
      case 'green':
        return ' tw-text-data-viz-semantic-green-content';
      case 'red':
        return ' tw-text-data-viz-semantic-red-content ';
      case 'yellow':
        return ' tw-text-data-viz-semantic-yellow-content ';
      case 'grey':
        return ' tw-text-grey-shade-1 ';
      default:
        return ' tw-text-black ';
    }
  }

  function getCaretType(percentage: number | undefined) {
    if (percentage === undefined) {
      return '';
    }
    if (percentage !== 0) {
      return phTriangleFill;
    } else {
      return phTriangleFill;
    }
  }

  function getCaretStyle(percentage: number | undefined) {
    if (percentage === undefined) {
      return 'tw-opacity-0';
      // return '';
    }
    if (percentage === 0) {
      return 'tw-fill-dark-shade-4 tw-rotate-90';
    } else if (percentage > 0) {
      return 'tw-fill-data-viz-semantic-green-content';
    } else {
      return 'tw-fill-data-viz-semantic-red-content tw-rotate-180';
    }
  }

  function getPriorityColorCode(priority: TaskPriorityEnum) {
    switch (priority) {
      case TaskPriorityEnum.High: {
        return 'tw-text-data-viz-semantic-red-content';
      }
      case TaskPriorityEnum.Medium: {
        return 'tw-text-data-viz-semantic-yellow-content';
      }
      case TaskPriorityEnum.Low: {
        return 'tw-text-data-viz-semantic-green-content';
      }
    }
  }
  function getMessagePriorityColorCode(priority: MessagePriorityEnum) {
    switch (priority) {
      case MessagePriorityEnum.High: {
        return 'tw-text-data-viz-semantic-red-content';
      }
      case MessagePriorityEnum.Medium: {
        return 'tw-text-data-viz-semantic-yellow-content';
      }
      case MessagePriorityEnum.Low: {
        return 'tw-text-data-viz-semantic-green-content';
      }
    }
  }

  function getStatusColorCode(status: TaskStatusEnum) {
    switch (status) {
      case TaskStatusEnum.New:
        return 'tw-bg-primary-lighter tw-text-primary-dark tw-decoration-primary-dark';
      case TaskStatusEnum.Working:
        return 'tw-bg-warning-lighter tw-text-warning tw-decoration-warning';
      case TaskStatusEnum.Done:
        return 'tw-bg-success-lighter tw-text-success tw-decoration-success';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setVisibility(value: any) {
    if (value === undefined) {
      return 'tw-opacity-0';
    }
    return 'tw-opacity-100';
  }

  const getSuspectC2MatcherColor = (matcherName: string) => {
    switch (matcherName) {
      case SuspectC2MatcherTypeEnum.UniqueId:
        return 'tw-fill-primary-dark';
      case SuspectC2MatcherTypeEnum.Name:
        return 'tw-fill-data-viz-qualitative-skyblue';
      case SuspectC2MatcherTypeEnum.Email:
        return 'tw-fill-data-viz-qualitative-violet';
      case SuspectC2MatcherTypeEnum.Phone:
        return 'tw-fill-data-viz-semantic-yellow-graph';
      case SuspectC2MatcherTypeEnum.Mobile:
        return 'tw-text-dark-shade-4';
      case SuspectC2MatcherTypeEnum.Location:
        return 'tw-fill-data-viz-semantic-red-graph';
      case SuspectC2MatcherTypeEnum.Domain:
        return 'tw-fill-data-viz-semantic-green-content';
      default:
        return 'tw-fill-primary-dark';
    }
  };

  return {
    getColorCodeAgainstString,
    getCaretType,
    getCaretStyle,
    setVisibility,
    getPriorityColorCode,
    getStatusColorCode,
    getMessagePriorityColorCode,
    getSuspectC2MatcherColor,
  };
};
