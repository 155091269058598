import { ChartData, ScriptableContext } from 'chart.js';
import { C1HealthScore, C2HealthScore } from '@/api/client';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';

export const useHealthMovementChart = () => {
  const { t } = useI18n<{ message: MessageSchema }>({
    useScope: 'global',
  });
  const filterOptions = [
    {
      label: 'Customers',
      value: 'customers',
    },
    {
      label: 'Users',
      value: 'users',
    },
  ];

  const colors: Record<string, string> = {
    upgrades_dec: '#4AC18D', //green
    upgrades_jan: '#4AC18D', //green
    steady_dec: '#FEBB2A', //yellow
    steady_jan: '#FEBB2A', //yellow
    downgrades_dec: '#FF625E', //red
    downgrades_jan: '#FF625E', //red
    churn_dec: '#444444', //black
    churn_jan: '#444444', //black
    inactive_dec: '#D0D0D0', //grey
    inactive_jan: '#D0D0D0', //grey
  };

  const getColor = (name: string) => {
    return colors[name] || 'green';
  };

  const chartData: ChartData<'sankey'> = {
    datasets: [
      {
        data: [],
        colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
        colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
        colorMode: 'to',
        labels: {},
        priority: {},
        // nodeWidth: 50,
        borderWidth: 0,
      },
    ],
  };

  function populateDataAgainstC2(c1healthScore: C1HealthScore) {
    const data = [];
    if (c1healthScore.no_of_c2_grey_to_black > 0) {
      data.push({ from: 'inactive_dec', to: 'churn_jan', flow: c1healthScore.no_of_c2_grey_to_black });
    }
    if (c1healthScore.no_of_c2_grey_to_grey > 0) {
      data.push({ from: 'inactive_dec', to: 'inactive_jan', flow: c1healthScore.no_of_c2_grey_to_grey });
    }
    if (c1healthScore.no_of_c2_grey_to_red > 0) {
      data.push({ from: 'inactive_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_c2_grey_to_red });
    }
    if (c1healthScore.no_of_c2_grey_to_yellow > 0) {
      data.push({ from: 'inactive_dec', to: 'steady_jan', flow: c1healthScore.no_of_c2_grey_to_yellow });
    }
    if (c1healthScore.no_of_c2_grey_to_green > 0) {
      data.push({ from: 'inactive_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_c2_grey_to_green });
    }

    if (c1healthScore.no_of_c2_red_to_black > 0) {
      data.push({ from: 'downgrades_dec', to: 'churn_jan', flow: c1healthScore.no_of_c2_red_to_black });
    }
    if (c1healthScore.no_of_c2_red_to_grey > 0) {
      data.push({ from: 'downgrades_dec', to: 'inactive_jan', flow: c1healthScore.no_of_c2_red_to_grey });
    }
    if (c1healthScore.no_of_c2_red_to_red > 0) {
      data.push({ from: 'downgrades_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_c2_red_to_red });
    }
    if (c1healthScore.no_of_c2_red_to_yellow > 0) {
      data.push({ from: 'downgrades_dec', to: 'steady_jan', flow: c1healthScore.no_of_c2_red_to_yellow });
    }
    if (c1healthScore.no_of_c2_red_to_green > 0) {
      data.push({ from: 'downgrades_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_c2_red_to_green });
    }

    if (c1healthScore.no_of_c2_green_to_black > 0) {
      data.push({ from: 'upgrades_dec', to: 'churn_jan', flow: c1healthScore.no_of_c2_green_to_black });
    }
    if (c1healthScore.no_of_c2_green_to_grey > 0) {
      data.push({ from: 'upgrades_dec', to: 'inactive_jan', flow: c1healthScore.no_of_c2_green_to_grey });
    }
    if (c1healthScore.no_of_c2_green_to_red > 0) {
      data.push({ from: 'upgrades_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_c2_green_to_red });
    }
    if (c1healthScore.no_of_c2_green_to_yellow > 0) {
      data.push({ from: 'upgrades_dec', to: 'steady_jan', flow: c1healthScore.no_of_c2_green_to_yellow });
    }
    if (c1healthScore.no_of_c2_green_to_green > 0) {
      data.push({ from: 'upgrades_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_c2_green_to_green });
    }

    if (c1healthScore.no_of_c2_yellow_to_black > 0) {
      data.push({ from: 'steady_dec', to: 'churn_jan', flow: c1healthScore.no_of_c2_yellow_to_black });
    }
    if (c1healthScore.no_of_c2_yellow_to_grey > 0) {
      data.push({ from: 'steady_dec', to: 'inactive_jan', flow: c1healthScore.no_of_c2_yellow_to_grey });
    }
    if (c1healthScore.no_of_c2_yellow_to_red > 0) {
      data.push({ from: 'steady_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_c2_yellow_to_red });
    }
    if (c1healthScore.no_of_c2_yellow_to_yellow > 0) {
      data.push({ from: 'steady_dec', to: 'steady_jan', flow: c1healthScore.no_of_c2_yellow_to_yellow });
    }
    if (c1healthScore.no_of_c2_yellow_to_green > 0) {
      data.push({ from: 'steady_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_c2_yellow_to_green });
    }
    return data;
  }

  function populateDataAgainstU2(c1healthScore: C1HealthScore) {
    const data = [];
    if (c1healthScore.no_of_u2_grey_to_black > 0) {
      data.push({ from: 'inactive_dec', to: 'churn_jan', flow: c1healthScore.no_of_u2_grey_to_black });
    }
    if (c1healthScore.no_of_u2_grey_to_grey > 0) {
      data.push({ from: 'inactive_dec', to: 'inactive_jan', flow: c1healthScore.no_of_u2_grey_to_grey });
    }
    if (c1healthScore.no_of_u2_grey_to_red > 0) {
      data.push({ from: 'inactive_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_u2_grey_to_red });
    }
    if (c1healthScore.no_of_u2_grey_to_yellow > 0) {
      data.push({ from: 'inactive_dec', to: 'steady_jan', flow: c1healthScore.no_of_u2_grey_to_yellow });
    }
    if (c1healthScore.no_of_u2_grey_to_green > 0) {
      data.push({ from: 'inactive_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_u2_grey_to_green });
    }

    if (c1healthScore.no_of_u2_red_to_black > 0) {
      data.push({ from: 'downgrades_dec', to: 'churn_jan', flow: c1healthScore.no_of_u2_red_to_black });
    }
    if (c1healthScore.no_of_u2_red_to_grey > 0) {
      data.push({ from: 'downgrades_dec', to: 'inactive_jan', flow: c1healthScore.no_of_u2_red_to_grey });
    }
    if (c1healthScore.no_of_u2_red_to_red > 0) {
      data.push({ from: 'downgrades_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_u2_red_to_red });
    }
    if (c1healthScore.no_of_u2_red_to_yellow > 0) {
      data.push({ from: 'downgrades_dec', to: 'steady_jan', flow: c1healthScore.no_of_u2_red_to_yellow });
    }
    if (c1healthScore.no_of_u2_red_to_green > 0) {
      data.push({ from: 'downgrades_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_u2_red_to_green });
    }

    if (c1healthScore.no_of_u2_green_to_black > 0) {
      data.push({ from: 'upgrades_dec', to: 'churn_jan', flow: c1healthScore.no_of_u2_green_to_black });
    }
    if (c1healthScore.no_of_u2_green_to_grey > 0) {
      data.push({ from: 'upgrades_dec', to: 'inactive_jan', flow: c1healthScore.no_of_u2_green_to_grey });
    }
    if (c1healthScore.no_of_u2_green_to_red > 0) {
      data.push({ from: 'upgrades_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_u2_green_to_red });
    }
    if (c1healthScore.no_of_u2_green_to_yellow > 0) {
      data.push({ from: 'upgrades_dec', to: 'steady_jan', flow: c1healthScore.no_of_u2_green_to_yellow });
    }
    if (c1healthScore.no_of_u2_green_to_green > 0) {
      data.push({ from: 'upgrades_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_u2_green_to_green });
    }

    if (c1healthScore.no_of_u2_yellow_to_black > 0) {
      data.push({ from: 'steady_dec', to: 'churn_jan', flow: c1healthScore.no_of_u2_yellow_to_black });
    }
    if (c1healthScore.no_of_u2_yellow_to_grey > 0) {
      data.push({ from: 'steady_dec', to: 'inactive_jan', flow: c1healthScore.no_of_u2_yellow_to_grey });
    }
    if (c1healthScore.no_of_u2_yellow_to_red > 0) {
      data.push({ from: 'steady_dec', to: 'downgrades_jan', flow: c1healthScore.no_of_u2_yellow_to_red });
    }
    if (c1healthScore.no_of_u2_yellow_to_yellow > 0) {
      data.push({ from: 'steady_dec', to: 'steady_jan', flow: c1healthScore.no_of_u2_yellow_to_yellow });
    }
    if (c1healthScore.no_of_u2_yellow_to_green > 0) {
      data.push({ from: 'steady_dec', to: 'upgrades_jan', flow: c1healthScore.no_of_u2_yellow_to_green });
    }
    return data;
  }

  function getC1HealthScoreMovementChartAgainstFilter(c1healthScore: C1HealthScore, filter: string) {
    if (!c1healthScore) {
      return {
        labels: [],
        datasets: [],
      } as ChartData<'sankey'>;
    }
    let data = [];
    if (filter === filterOptions[0].value) {
      data = [...populateDataAgainstC2(c1healthScore)];
    } else {
      data = [...populateDataAgainstU2(c1healthScore)];
    }

    const label: { [key: string]: string } = {};
    const priority: { [key: string]: number } = {};
    const isInActiveFrom = data.find((x) => x?.from === 'inactive_dec');
    if (isInActiveFrom) {
      label['inactive_dec'] = t('grey');
      priority['inactive_dec'] = 1;
    }
    const isDowngradeFrom = data.find((x) => x?.from === 'downgrades_dec');
    if (isDowngradeFrom) {
      label['downgrades_dec'] = t('red');
      priority['downgrades_dec'] = 2;
    }
    const isUpgradeFrom = data.find((x) => x?.from === 'upgrades_dec');
    if (isUpgradeFrom) {
      label['upgrades_dec'] = t('green');
      priority['upgrades_dec'] = 4;
    }
    const isSteadyFrom = data.find((x) => x?.from === 'steady_dec');
    if (isSteadyFrom) {
      label['steady_dec'] = t('yellow');
      priority['steady_dec'] = 3;
    }
    const isChurnTo = data.find((x) => x?.to === 'churn_jan');
    if (isChurnTo) {
      label['churn_jan'] = t('black');
      priority['churn_jan'] = 0;
    }
    const isInActiveTo = data.find((x) => x?.to === 'inactive_jan');
    if (isInActiveTo) {
      label['inactive_jan'] = t('grey');
      priority['inactive_jan'] = 1;
    }
    const isDowngradesTo = data.find((x) => x?.to === 'downgrades_jan');
    if (isDowngradesTo) {
      label['downgrades_jan'] = t('red');
      priority['downgrades_jan'] = 2;
    }
    const isUpgradeTo = data.find((x) => x?.to === 'upgrades_jan');
    if (isUpgradeTo) {
      label['upgrades_jan'] = t('green');
      priority['upgrades_jan'] = 4;
    }
    const isSteadyTo = data.find((x) => x?.to === 'steady_jan');
    if (isSteadyTo) {
      label['steady_jan'] = t('yellow');
      priority['steady_jan'] = 3;
    }

    const chartDataInstance = {
      data,
      colorFrom: (c: ScriptableContext<'sankey'>) => getColor(c.dataset.data[c.dataIndex]?.from),
      colorTo: (c: ScriptableContext<'sankey'>) => getColor(c.dataset.data[c.dataIndex]?.to),
      colorMode: 'to',
      labels: label,
      priority,
      borderWidth: 0,
    };
    return {
      datasets: [chartDataInstance],
    } as ChartData<'sankey'>;
  }

  function getC2HealthScoreSankeyAgainstUser(c2HealthScore: C2HealthScore) {
    const data = [];
    if (c2HealthScore.no_of_u2_grey_to_black > 0) {
      data.push({ from: 'inactive_dec', to: 'churn_jan', flow: c2HealthScore.no_of_u2_grey_to_black });
    }
    if (c2HealthScore.no_of_u2_grey_to_grey > 0) {
      data.push({ from: 'inactive_dec', to: 'inactive_jan', flow: c2HealthScore.no_of_u2_grey_to_grey });
    }
    if (c2HealthScore.no_of_u2_grey_to_red > 0) {
      data.push({ from: 'inactive_dec', to: 'downgrades_jan', flow: c2HealthScore.no_of_u2_grey_to_red });
    }
    if (c2HealthScore.no_of_u2_grey_to_yellow > 0) {
      data.push({ from: 'inactive_dec', to: 'steady_jan', flow: c2HealthScore.no_of_u2_grey_to_yellow });
    }
    if (c2HealthScore.no_of_u2_grey_to_green > 0) {
      data.push({ from: 'inactive_dec', to: 'upgrades_jan', flow: c2HealthScore.no_of_u2_grey_to_green });
    }

    if (c2HealthScore.no_of_u2_red_to_black > 0) {
      data.push({ from: 'downgrades_dec', to: 'churn_jan', flow: c2HealthScore.no_of_u2_red_to_black });
    }
    if (c2HealthScore.no_of_u2_red_to_grey > 0) {
      data.push({ from: 'downgrades_dec', to: 'inactive_jan', flow: c2HealthScore.no_of_u2_red_to_grey });
    }
    if (c2HealthScore.no_of_u2_red_to_red > 0) {
      data.push({ from: 'downgrades_dec', to: 'downgrades_jan', flow: c2HealthScore.no_of_u2_red_to_red });
    }
    if (c2HealthScore.no_of_u2_red_to_yellow > 0) {
      data.push({ from: 'downgrades_dec', to: 'steady_jan', flow: c2HealthScore.no_of_u2_red_to_yellow });
    }
    if (c2HealthScore.no_of_u2_red_to_green > 0) {
      data.push({ from: 'downgrades_dec', to: 'upgrades_jan', flow: c2HealthScore.no_of_u2_red_to_green });
    }

    if (c2HealthScore.no_of_u2_green_to_black > 0) {
      data.push({ from: 'upgrades_dec', to: 'churn_jan', flow: c2HealthScore.no_of_u2_green_to_black });
    }
    if (c2HealthScore.no_of_u2_green_to_grey > 0) {
      data.push({ from: 'upgrades_dec', to: 'inactive_jan', flow: c2HealthScore.no_of_u2_green_to_grey });
    }
    if (c2HealthScore.no_of_u2_green_to_red > 0) {
      data.push({ from: 'upgrades_dec', to: 'downgrades_jan', flow: c2HealthScore.no_of_u2_green_to_red });
    }
    if (c2HealthScore.no_of_u2_green_to_yellow > 0) {
      data.push({ from: 'upgrades_dec', to: 'steady_jan', flow: c2HealthScore.no_of_u2_green_to_yellow });
    }
    if (c2HealthScore.no_of_u2_green_to_green > 0) {
      data.push({ from: 'upgrades_dec', to: 'upgrades_jan', flow: c2HealthScore.no_of_u2_green_to_green });
    }

    if (c2HealthScore.no_of_u2_yellow_to_black > 0) {
      data.push({ from: 'steady_dec', to: 'churn_jan', flow: c2HealthScore.no_of_u2_yellow_to_black });
    }
    if (c2HealthScore.no_of_u2_yellow_to_grey > 0) {
      data.push({ from: 'steady_dec', to: 'inactive_jan', flow: c2HealthScore.no_of_u2_yellow_to_grey });
    }
    if (c2HealthScore.no_of_u2_yellow_to_red > 0) {
      data.push({ from: 'steady_dec', to: 'downgrades_jan', flow: c2HealthScore.no_of_u2_yellow_to_red });
    }
    if (c2HealthScore.no_of_u2_yellow_to_yellow > 0) {
      data.push({ from: 'steady_dec', to: 'steady_jan', flow: c2HealthScore.no_of_u2_yellow_to_yellow });
    }
    if (c2HealthScore.no_of_u2_yellow_to_green > 0) {
      data.push({ from: 'steady_dec', to: 'upgrades_jan', flow: c2HealthScore.no_of_u2_yellow_to_green });
    }
    if (data.length <= 0) {
      return {
        labels: [],
        datasets: [],
      } as ChartData<'sankey'>;
    }
    const label: { [key: string]: string } = {};
    const priority: { [key: string]: number } = {};
    const isInActiveFrom = data.find((x) => x?.from === 'inactive_dec');
    if (isInActiveFrom) {
      label['inactive_dec'] = t('grey');
      priority['inactive_dec'] = 1;
    }
    const isDowngradeFrom = data.find((x) => x?.from === 'downgrades_dec');
    if (isDowngradeFrom) {
      label['downgrades_dec'] = t('red');
      priority['downgrades_dec'] = 2;
    }
    const isUpgradeFrom = data.find((x) => x?.from === 'upgrades_dec');
    if (isUpgradeFrom) {
      label['upgrades_dec'] = t('green');
      priority['upgrades_dec'] = 4;
    }
    const isSteadyFrom = data.find((x) => x?.from === 'steady_dec');
    if (isSteadyFrom) {
      label['steady_dec'] = t('yellow');
      priority['steady_dec'] = 3;
    }
    const isChurnTo = data.find((x) => x?.to === 'churn_jan');
    if (isChurnTo) {
      label['churn_jan'] = t('black');
      priority['churn_jan'] = 0;
    }
    const isInActiveTo = data.find((x) => x?.to === 'inactive_jan');
    if (isInActiveTo) {
      label['inactive_jan'] = t('grey');
      priority['inactive_jan'] = 1;
    }
    const isDowngradesTo = data.find((x) => x?.to === 'downgrades_jan');
    if (isDowngradesTo) {
      label['downgrades_jan'] = t('red');
      priority['downgrades_jan'] = 2;
    }
    const isUpgradeTo = data.find((x) => x?.to === 'upgrades_jan');
    if (isUpgradeTo) {
      label['upgrades_jan'] = t('green');
      priority['upgrades_jan'] = 4;
    }
    const isSteadyTo = data.find((x) => x?.to === 'steady_jan');
    if (isSteadyTo) {
      label['steady_jan'] = t('yellow');
      priority['steady_jan'] = 3;
    }
    // const chartDataInstance = chartData.datasets[0];
    const chartDataInstance = {
      data,
      colorFrom: (c: ScriptableContext<'sankey'>) => getColor(c.dataset.data[c.dataIndex].from),
      colorTo: (c: ScriptableContext<'sankey'>) => getColor(c.dataset.data[c.dataIndex].to),
      colorMode: 'to',
      labels: label,
      priority,
      borderWidth: 0,
    };
    return {
      datasets: [chartDataInstance],
    } as ChartData<'sankey'>;
  }

  return {
    filterOptions,
    chartData,
    getC2HealthScoreSankeyAgainstUser,
    getC1HealthScoreMovementChartAgainstFilter,
    getColor,
  };
};
