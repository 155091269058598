/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CrmUpsellDealOwnerDto } from '../model/';
// @ts-ignore
import { CrmUpsellDealsFilterDto } from '../model/';
// @ts-ignore
import { RoleBasedPaginatedUpsellDealsDto } from '../model/';
// @ts-ignore
import { RoleBasedUpsellAggregationDto } from '../model/';
/**
 * CrmUpsellLogsApi - axios parameter creator
 * @export
 */
export const CrmUpsellLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUniqueDealOwners: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crm-upsell-logs/deal-owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique deal statuses
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUniqueDealStatuses: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crm-upsell-logs/deal-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique pipeline names
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUniquePipelineNames: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crm-upsell-logs/pipeline-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated Upsell Deals
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUpsellDeals: async (crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUpsellDealsFilterDto' is not null or undefined
            assertParamExists('crmUpsellLogsControllerFindAllUpsellDeals', 'crmUpsellDealsFilterDto', crmUpsellDealsFilterDto)
            const localVarPath = `/api/crm-upsell-logs/paginated-upsell-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmUpsellDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated Upsell Deals For C2
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUpsellDealsForC2: async (crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUpsellDealsFilterDto' is not null or undefined
            assertParamExists('crmUpsellLogsControllerFindAllUpsellDealsForC2', 'crmUpsellDealsFilterDto', crmUpsellDealsFilterDto)
            const localVarPath = `/api/crm-upsell-logs/paginated-upsell-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmUpsellDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsell Aggregation Count
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindUpsellDealAggregationCount: async (crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUpsellDealsFilterDto' is not null or undefined
            assertParamExists('crmUpsellLogsControllerFindUpsellDealAggregationCount', 'crmUpsellDealsFilterDto', crmUpsellDealsFilterDto)
            const localVarPath = `/api/crm-upsell-logs/upsell-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmUpsellDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsell Aggregation Count For C2
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindUpsellDealAggregationCountForC2: async (crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUpsellDealsFilterDto' is not null or undefined
            assertParamExists('crmUpsellLogsControllerFindUpsellDealAggregationCountForC2', 'crmUpsellDealsFilterDto', crmUpsellDealsFilterDto)
            const localVarPath = `/api/crm-upsell-logs/upsell-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmUpsellDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Date wise Upsell Deal History Logs
         * @param {string} id 
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerGetDateWiseUpsellDealHistory: async (id: string, crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('crmUpsellLogsControllerGetDateWiseUpsellDealHistory', 'id', id)
            // verify required parameter 'crmUpsellDealsFilterDto' is not null or undefined
            assertParamExists('crmUpsellLogsControllerGetDateWiseUpsellDealHistory', 'crmUpsellDealsFilterDto', crmUpsellDealsFilterDto)
            const localVarPath = `/api/crm-upsell-logs/{id}/date-wise-upsell-deal-history-logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmUpsellDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsell Deal History Logs
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerGetUpsellDealHistory: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('crmUpsellLogsControllerGetUpsellDealHistory', 'id', id)
            const localVarPath = `/api/crm-upsell-logs/{id}/upsell-deal-history-logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrmUpsellLogsApi - functional programming interface
 * @export
 */
export const CrmUpsellLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrmUpsellLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerFindAllUniqueDealOwners(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrmUpsellDealOwnerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerFindAllUniqueDealOwners(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique deal statuses
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerFindAllUniqueDealStatuses(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerFindAllUniqueDealStatuses(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique pipeline names
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerFindAllUniquePipelineNames(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerFindAllUniquePipelineNames(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated Upsell Deals
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerFindAllUpsellDeals(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedUpsellDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerFindAllUpsellDeals(crmUpsellDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated Upsell Deals For C2
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerFindAllUpsellDealsForC2(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedUpsellDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerFindAllUpsellDealsForC2(crmUpsellDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsell Aggregation Count
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerFindUpsellDealAggregationCount(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerFindUpsellDealAggregationCount(crmUpsellDealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsell Aggregation Count For C2
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerFindUpsellDealAggregationCountForC2(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerFindUpsellDealAggregationCountForC2(crmUpsellDealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Date wise Upsell Deal History Logs
         * @param {string} id 
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerGetDateWiseUpsellDealHistory(id: string, crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedUpsellDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerGetDateWiseUpsellDealHistory(id, crmUpsellDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsell Deal History Logs
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUpsellLogsControllerGetUpsellDealHistory(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedUpsellDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUpsellLogsControllerGetUpsellDealHistory(id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrmUpsellLogsApi - factory interface
 * @export
 */
export const CrmUpsellLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrmUpsellLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUniqueDealOwners(xTenantId?: string, options?: any): AxiosPromise<Array<CrmUpsellDealOwnerDto>> {
            return localVarFp.crmUpsellLogsControllerFindAllUniqueDealOwners(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique deal statuses
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUniqueDealStatuses(xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.crmUpsellLogsControllerFindAllUniqueDealStatuses(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique pipeline names
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUniquePipelineNames(xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.crmUpsellLogsControllerFindAllUniquePipelineNames(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated Upsell Deals
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUpsellDeals(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedUpsellDealsDto> {
            return localVarFp.crmUpsellLogsControllerFindAllUpsellDeals(crmUpsellDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated Upsell Deals For C2
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindAllUpsellDealsForC2(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedUpsellDealsDto> {
            return localVarFp.crmUpsellLogsControllerFindAllUpsellDealsForC2(crmUpsellDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsell Aggregation Count
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindUpsellDealAggregationCount(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedUpsellAggregationDto> {
            return localVarFp.crmUpsellLogsControllerFindUpsellDealAggregationCount(crmUpsellDealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsell Aggregation Count For C2
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerFindUpsellDealAggregationCountForC2(crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedUpsellAggregationDto> {
            return localVarFp.crmUpsellLogsControllerFindUpsellDealAggregationCountForC2(crmUpsellDealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Date wise Upsell Deal History Logs
         * @param {string} id 
         * @param {CrmUpsellDealsFilterDto} crmUpsellDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerGetDateWiseUpsellDealHistory(id: string, crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedUpsellDealsDto> {
            return localVarFp.crmUpsellLogsControllerGetDateWiseUpsellDealHistory(id, crmUpsellDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsell Deal History Logs
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUpsellLogsControllerGetUpsellDealHistory(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedUpsellDealsDto> {
            return localVarFp.crmUpsellLogsControllerGetUpsellDealHistory(id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for crmUpsellLogsControllerFindAllUniqueDealOwners operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealOwnersRequest
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealOwnersRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealOwners
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmUpsellLogsControllerFindAllUniqueDealStatuses operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealStatusesRequest
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealStatusesRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealStatuses
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmUpsellLogsControllerFindAllUniquePipelineNames operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniquePipelineNamesRequest
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniquePipelineNamesRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniquePipelineNames
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmUpsellLogsControllerFindAllUpsellDeals operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsRequest
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsRequest {
    /**
     * 
     * @type {CrmUpsellDealsFilterDto}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDeals
     */
    readonly after?: string
}

/**
 * Request parameters for crmUpsellLogsControllerFindAllUpsellDealsForC2 operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2Request
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2Request {
    /**
     * 
     * @type {CrmUpsellDealsFilterDto}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for crmUpsellLogsControllerFindUpsellDealAggregationCount operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountRequest
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountRequest {
    /**
     * 
     * @type {CrmUpsellDealsFilterDto}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCount
     */
    readonly crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmUpsellLogsControllerFindUpsellDealAggregationCountForC2 operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountForC2Request
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountForC2Request {
    /**
     * 
     * @type {CrmUpsellDealsFilterDto}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountForC2
     */
    readonly crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmUpsellLogsControllerGetDateWiseUpsellDealHistory operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistoryRequest
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly id: string

    /**
     * 
     * @type {CrmUpsellDealsFilterDto}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly crmUpsellDealsFilterDto: CrmUpsellDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistory
     */
    readonly after?: string
}

/**
 * Request parameters for crmUpsellLogsControllerGetUpsellDealHistory operation in CrmUpsellLogsApi.
 * @export
 * @interface CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistoryRequest
 */
export interface CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistory
     */
    readonly after?: string
}

/**
 * CrmUpsellLogsApi - object-oriented interface
 * @export
 * @class CrmUpsellLogsApi
 * @extends {BaseAPI}
 */
export class CrmUpsellLogsApi extends BaseAPI {
    /**
     * 
     * @summary Find all unique deal owners
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerFindAllUniqueDealOwners(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealOwnersRequest = {}, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerFindAllUniqueDealOwners(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique deal statuses
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerFindAllUniqueDealStatuses(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniqueDealStatusesRequest = {}, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerFindAllUniqueDealStatuses(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique pipeline names
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniquePipelineNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerFindAllUniquePipelineNames(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUniquePipelineNamesRequest = {}, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerFindAllUniquePipelineNames(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated Upsell Deals
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerFindAllUpsellDeals(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsRequest, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerFindAllUpsellDeals(requestParameters.crmUpsellDealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated Upsell Deals For C2
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerFindAllUpsellDealsForC2(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerFindAllUpsellDealsForC2Request, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerFindAllUpsellDealsForC2(requestParameters.crmUpsellDealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsell Aggregation Count
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerFindUpsellDealAggregationCount(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountRequest, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerFindUpsellDealAggregationCount(requestParameters.crmUpsellDealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsell Aggregation Count For C2
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerFindUpsellDealAggregationCountForC2(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerFindUpsellDealAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerFindUpsellDealAggregationCountForC2(requestParameters.crmUpsellDealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Date wise Upsell Deal History Logs
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerGetDateWiseUpsellDealHistory(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerGetDateWiseUpsellDealHistoryRequest, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerGetDateWiseUpsellDealHistory(requestParameters.id, requestParameters.crmUpsellDealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsell Deal History Logs
     * @param {CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUpsellLogsApi
     */
    public crmUpsellLogsControllerGetUpsellDealHistory(requestParameters: CrmUpsellLogsApiCrmUpsellLogsControllerGetUpsellDealHistoryRequest, options?: AxiosRequestConfig) {
        return CrmUpsellLogsApiFp(this.configuration).crmUpsellLogsControllerGetUpsellDealHistory(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }
}
