/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ZapscaleEvent } from '../model/';
/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [c1MasterId] 
         * @param {string} [synthetic] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleEventsControllerFindAll: async (c1MasterId?: string, synthetic?: string, skip?: number, limit?: number, sort?: string, direction?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/zapscale-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c1MasterId !== undefined) {
                localVarQueryParameter['c1MasterId'] = c1MasterId;
            }

            if (synthetic !== undefined) {
                localVarQueryParameter['synthetic'] = synthetic;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} c1MasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleEventsControllerFindLatest: async (c1MasterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c1MasterId' is not null or undefined
            assertParamExists('zapscaleEventsControllerFindLatest', 'c1MasterId', c1MasterId)
            const localVarPath = `/api/zapscale-events/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c1MasterId !== undefined) {
                localVarQueryParameter['c1MasterId'] = c1MasterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [c1MasterId] 
         * @param {string} [synthetic] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleEventsControllerFindAll(c1MasterId?: string, synthetic?: string, skip?: number, limit?: number, sort?: string, direction?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleEventsControllerFindAll(c1MasterId, synthetic, skip, limit, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} c1MasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleEventsControllerFindLatest(c1MasterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleEventsControllerFindLatest(c1MasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [c1MasterId] 
         * @param {string} [synthetic] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleEventsControllerFindAll(c1MasterId?: string, synthetic?: string, skip?: number, limit?: number, sort?: string, direction?: number, options?: any): AxiosPromise<Array<ZapscaleEvent>> {
            return localVarFp.zapscaleEventsControllerFindAll(c1MasterId, synthetic, skip, limit, sort, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} c1MasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleEventsControllerFindLatest(c1MasterId: string, options?: any): AxiosPromise<ZapscaleEvent> {
            return localVarFp.zapscaleEventsControllerFindLatest(c1MasterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for zapscaleEventsControllerFindAll operation in EventsApi.
 * @export
 * @interface EventsApiZapscaleEventsControllerFindAllRequest
 */
export interface EventsApiZapscaleEventsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiZapscaleEventsControllerFindAll
     */
    readonly c1MasterId?: string

    /**
     * 
     * @type {string}
     * @memberof EventsApiZapscaleEventsControllerFindAll
     */
    readonly synthetic?: string

    /**
     * 
     * @type {number}
     * @memberof EventsApiZapscaleEventsControllerFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof EventsApiZapscaleEventsControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EventsApiZapscaleEventsControllerFindAll
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof EventsApiZapscaleEventsControllerFindAll
     */
    readonly direction?: number
}

/**
 * Request parameters for zapscaleEventsControllerFindLatest operation in EventsApi.
 * @export
 * @interface EventsApiZapscaleEventsControllerFindLatestRequest
 */
export interface EventsApiZapscaleEventsControllerFindLatestRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiZapscaleEventsControllerFindLatest
     */
    readonly c1MasterId: string
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @param {EventsApiZapscaleEventsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public zapscaleEventsControllerFindAll(requestParameters: EventsApiZapscaleEventsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).zapscaleEventsControllerFindAll(requestParameters.c1MasterId, requestParameters.synthetic, requestParameters.skip, requestParameters.limit, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventsApiZapscaleEventsControllerFindLatestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public zapscaleEventsControllerFindLatest(requestParameters: EventsApiZapscaleEventsControllerFindLatestRequest, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).zapscaleEventsControllerFindLatest(requestParameters.c1MasterId, options).then((request) => request(this.axios, this.basePath));
    }
}
