<script setup lang="ts">
import { DashboardSelectionTypeEnum } from '@/api/client';
import { MessageSchema } from '@/lib/i18n';
import { phCaretDown, phCaretUp } from '@/phosphor-icons';
import { phMagnifyingGlass, phXLight } from '@/phosphor-icons';
import { useAccountStore } from '@/stores/account';
import { useDashboardV2Store, IDashFilterOption } from '@/stores/dashboardv2';
import { storeToRefs } from 'pinia';
import { QMenu } from 'quasar';
import { ComponentPublicInstance, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const emits = defineEmits(['onFilterApplied']);

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const selectedSegmentName = ref<string>('');

// const c2Segment = useCustomerSegmentStore();
// const { c2SegmentList } = storeToRefs(c2Segment);
const { dashboardFor, dashboardC2Types, dashboardC2Segments } = storeToRefs(useDashboardV2Store());
const { isRoleBaseAccessEnabled, isAdmin } = storeToRefs(useAccountStore());

const icon = ref(phCaretDown);

const c2Types = computed(() => {
  return [...dashboardC2Types.value].sort((x, y) => (x.name > y.name ? 1 : -1));
});

const zapscaleSegment = computed(() => {
  return dashboardC2Segments.value
    ?.filter((x) => x.is_system_defined)
    ?.filter((segment) => segment.name.toLowerCase().includes(selectedSegmentName.value.toLowerCase() || ''))
    ?.sort((x, y) => x.name.localeCompare(y.name, undefined, { sensitivity: 'base' }));
});

const customerSegment = computed(() => {
  return dashboardC2Segments.value
    ?.filter((x) => !x.is_system_defined)
    ?.filter((segment) => segment.name.toLowerCase().includes(selectedSegmentName.value.toLowerCase() || ''))
    ?.sort((x, y) => x.name.localeCompare(y.name, undefined, { sensitivity: 'base' }));
});

const showCustomerTypeSelection = computed(() => {
  return !isRoleBaseAccessEnabled.value || isAdmin.value;
});

const filterContextMenu = ref<ComponentPublicInstance<QMenu>>();

onMounted(async () => {
  // c2Segment.fetchSegmentList();
});

// const allC2Types = computed(() => {
// return [{ display_name: t('dashboard_filter.all'), id: '-' } as C2Type, ...c2Types.value];
// });

function onCustomerTypeSelection(c2TypeId: string) {
  const c2Type = dashboardC2Types.value.find((x) => x.id === c2TypeId);
  if (!c2Type) {
    return;
  }
  dashboardFor.value = {
    id: c2Type.id,
    name: c2Type.name,
    type: DashboardSelectionTypeEnum[c2Type.type],
  } as IDashFilterOption;
  emits('onFilterApplied');
  filterContextMenu.value?.toggle();
}

function onCustomerSegmentSelection(c2SegmentId: string) {
  const c2Segment = dashboardC2Segments.value.find((x) => x.id === c2SegmentId);
  if (!c2Segment) {
    return;
  }
  dashboardFor.value = { id: c2Segment.id, name: c2Segment.name, type: DashboardSelectionTypeEnum.C2segmenthealth };
  emits('onFilterApplied');
  filterContextMenu.value?.toggle();
}
</script>

<template>
  <div v-if="dashboardFor">
    <q-input
      readonly
      standout="tw-border-solid"
      outlined
      v-model="dashboardFor.name"
      dense
      class="custom-icon tw-mx-1 tw-w-[17rem] tw-cursor-pointer"
    >
      <q-menu
        ref="filterContextMenu"
        @before-show="icon = phCaretUp"
        @before-hide="icon = phCaretDown"
        class="tw-overflow-x-hidden"
      >
        <q-list>
          <q-item-label header v-if="showCustomerTypeSelection" class="tw-text-primary">{{
            t('dashboard_filter.customer_type')
          }}</q-item-label>
          <q-item v-for="item in c2Types" :key="item.id" clickable v-show="showCustomerTypeSelection">
            <q-item-section>
              <q-radio
                v-model="dashboardFor.id"
                dense
                size="sm"
                :val="item.id"
                :label="item.name"
                class="tw-text-sm tw-font-semibold tw-text-dark-shade-1"
                :class="item.id === dashboardFor.id ? 'tw-text-primary-dark' : ''"
                @update:model-value="onCustomerTypeSelection"
              ></q-radio>
            </q-item-section>
          </q-item>
          <q-separator v-if="zapscaleSegment?.length > 0" />

          <!-- search section -->
          <div class="tw-mt-3 tw-w-auto tw-px-4">
            <q-input
              for="task_name"
              v-model="selectedSegmentName"
              dense
              outlined
              placeholder="Search here...."
              class="tw-rounded-md tw-border tw-border-primary-dark"
            >
              <template v-slot:prepend>
                <q-icon :size="'xs'" :name="phMagnifyingGlass" @click="selectedSegmentName = ''" />
              </template>
              <template v-slot:append>
                <q-icon :size="'xs'" :name="phXLight" @click="selectedSegmentName = ''" class="cursor-pointer" />
              </template>
            </q-input>
          </div>
          <!-- ...................... -->

          <q-item-label header v-if="zapscaleSegment?.length > 0" class="tw-text-primary">{{
            t('dashboard_filter.zapscale_segment')
          }}</q-item-label>
          <q-item v-for="item in zapscaleSegment" :key="item.id" clickable>
            <q-item-section>
              <q-radio
                v-model="dashboardFor.id"
                dense
                size="sm"
                :val="item.id"
                :label="item.name"
                class="tw-text-sm tw-font-semibold tw-text-dark-shade-1"
                :class="item.id === dashboardFor.id ? 'tw-text-primary-dark' : ''"
                @update:model-value="onCustomerSegmentSelection"
              ></q-radio>
            </q-item-section>
          </q-item>
          <q-separator v-if="customerSegment?.length > 0" />
          <q-item-label header v-if="customerSegment?.length > 0" class="tw-text-primary">{{
            t('dashboard_filter.customer_segment')
          }}</q-item-label>
          <q-item v-for="item in customerSegment" :key="item.id" clickable>
            <q-item-section>
              <q-radio
                v-model="dashboardFor.id"
                dense
                size="sm"
                :val="item.id"
                :label="item.name"
                class="tw-text-sm tw-font-semibold tw-text-dark-shade-1"
                :class="item.id === dashboardFor.id ? 'tw-text-primary-dark' : ''"
                @update:model-value="onCustomerSegmentSelection"
              ></q-radio>
            </q-item-section>
          </q-item>
          <q-item-label
            header
            v-if="!customerSegment?.length && !zapscaleSegment?.length"
            class="tw-text-dark-shade-1"
            >{{ t('no_record_found') }}</q-item-label
          >
        </q-list>
      </q-menu>

      <template v-slot:append>
        <q-icon :name="icon" :size="'12px'" />
      </template>
    </q-input>
  </div>
</template>
