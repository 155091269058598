/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SuspectC2Matcher } from '../model/';
// @ts-ignore
import { UpdateSuspectC2MatcherListDto } from '../model/';
/**
 * SuspectC2MatcherApi - axios parameter creator
 * @export
 */
export const SuspectC2MatcherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Suspect C2 Matchers
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspectC2MatcherControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/suspect-c2-matcher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Suspect C2 Matcher
         * @param {UpdateSuspectC2MatcherListDto} updateSuspectC2MatcherListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspectC2MatcherControllerUpdateAll: async (updateSuspectC2MatcherListDto: UpdateSuspectC2MatcherListDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSuspectC2MatcherListDto' is not null or undefined
            assertParamExists('suspectC2MatcherControllerUpdateAll', 'updateSuspectC2MatcherListDto', updateSuspectC2MatcherListDto)
            const localVarPath = `/api/suspect-c2-matcher/update-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSuspectC2MatcherListDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuspectC2MatcherApi - functional programming interface
 * @export
 */
export const SuspectC2MatcherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuspectC2MatcherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Suspect C2 Matchers
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspectC2MatcherControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuspectC2Matcher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspectC2MatcherControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Suspect C2 Matcher
         * @param {UpdateSuspectC2MatcherListDto} updateSuspectC2MatcherListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspectC2MatcherControllerUpdateAll(updateSuspectC2MatcherListDto: UpdateSuspectC2MatcherListDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuspectC2Matcher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspectC2MatcherControllerUpdateAll(updateSuspectC2MatcherListDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuspectC2MatcherApi - factory interface
 * @export
 */
export const SuspectC2MatcherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuspectC2MatcherApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Suspect C2 Matchers
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspectC2MatcherControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<SuspectC2Matcher>> {
            return localVarFp.suspectC2MatcherControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Suspect C2 Matcher
         * @param {UpdateSuspectC2MatcherListDto} updateSuspectC2MatcherListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspectC2MatcherControllerUpdateAll(updateSuspectC2MatcherListDto: UpdateSuspectC2MatcherListDto, xTenantId?: string, options?: any): AxiosPromise<Array<SuspectC2Matcher>> {
            return localVarFp.suspectC2MatcherControllerUpdateAll(updateSuspectC2MatcherListDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for suspectC2MatcherControllerFindAll operation in SuspectC2MatcherApi.
 * @export
 * @interface SuspectC2MatcherApiSuspectC2MatcherControllerFindAllRequest
 */
export interface SuspectC2MatcherApiSuspectC2MatcherControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof SuspectC2MatcherApiSuspectC2MatcherControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for suspectC2MatcherControllerUpdateAll operation in SuspectC2MatcherApi.
 * @export
 * @interface SuspectC2MatcherApiSuspectC2MatcherControllerUpdateAllRequest
 */
export interface SuspectC2MatcherApiSuspectC2MatcherControllerUpdateAllRequest {
    /**
     * 
     * @type {UpdateSuspectC2MatcherListDto}
     * @memberof SuspectC2MatcherApiSuspectC2MatcherControllerUpdateAll
     */
    readonly updateSuspectC2MatcherListDto: UpdateSuspectC2MatcherListDto

    /**
     * 
     * @type {string}
     * @memberof SuspectC2MatcherApiSuspectC2MatcherControllerUpdateAll
     */
    readonly xTenantId?: string
}

/**
 * SuspectC2MatcherApi - object-oriented interface
 * @export
 * @class SuspectC2MatcherApi
 * @extends {BaseAPI}
 */
export class SuspectC2MatcherApi extends BaseAPI {
    /**
     * 
     * @summary List of Suspect C2 Matchers
     * @param {SuspectC2MatcherApiSuspectC2MatcherControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspectC2MatcherApi
     */
    public suspectC2MatcherControllerFindAll(requestParameters: SuspectC2MatcherApiSuspectC2MatcherControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return SuspectC2MatcherApiFp(this.configuration).suspectC2MatcherControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Suspect C2 Matcher
     * @param {SuspectC2MatcherApiSuspectC2MatcherControllerUpdateAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspectC2MatcherApi
     */
    public suspectC2MatcherControllerUpdateAll(requestParameters: SuspectC2MatcherApiSuspectC2MatcherControllerUpdateAllRequest, options?: AxiosRequestConfig) {
        return SuspectC2MatcherApiFp(this.configuration).suspectC2MatcherControllerUpdateAll(requestParameters.updateSuspectC2MatcherListDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
