/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MilestoneAutoAction } from '../model/';
/**
 * MilestoneAutoActionApi - axios parameter creator
 * @export
 */
export const MilestoneAutoActionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all Milestone Auto actions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        milestoneAutoActionControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/milestone-auto-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MilestoneAutoActionApi - functional programming interface
 * @export
 */
export const MilestoneAutoActionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MilestoneAutoActionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of all Milestone Auto actions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async milestoneAutoActionControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MilestoneAutoAction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.milestoneAutoActionControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MilestoneAutoActionApi - factory interface
 * @export
 */
export const MilestoneAutoActionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MilestoneAutoActionApiFp(configuration)
    return {
        /**
         * 
         * @summary List of all Milestone Auto actions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        milestoneAutoActionControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<MilestoneAutoAction>> {
            return localVarFp.milestoneAutoActionControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for milestoneAutoActionControllerFindAll operation in MilestoneAutoActionApi.
 * @export
 * @interface MilestoneAutoActionApiMilestoneAutoActionControllerFindAllRequest
 */
export interface MilestoneAutoActionApiMilestoneAutoActionControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof MilestoneAutoActionApiMilestoneAutoActionControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * MilestoneAutoActionApi - object-oriented interface
 * @export
 * @class MilestoneAutoActionApi
 * @extends {BaseAPI}
 */
export class MilestoneAutoActionApi extends BaseAPI {
    /**
     * 
     * @summary List of all Milestone Auto actions
     * @param {MilestoneAutoActionApiMilestoneAutoActionControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneAutoActionApi
     */
    public milestoneAutoActionControllerFindAll(requestParameters: MilestoneAutoActionApiMilestoneAutoActionControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return MilestoneAutoActionApiFp(this.configuration).milestoneAutoActionControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
