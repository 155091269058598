<script setup lang="ts">
import {
  phPulse,
  phBug,
  phCheckSquareOffset,
  phClock,
  phFile,
  phLayout,
  phMessengerLogo,
  phXBold,
  phChartLineUp,
  phTrendDown,
  phChartLine,
} from '@/phosphor-icons';
import { useDialogPluginComponent } from 'quasar';
import { computed, ref } from 'vue';
import { useDateStore } from '@/stores/date';
import { useC1HealthScoreStore } from '@/stores/c1HealthScore';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { DateTypeEnum } from '@/api/client';
import { IMoreDetailsElement, MoreDetailsElementType } from '@/types/dashboard';
import { storeToRefs } from 'pinia';
import CustomerMoreDetailsItem from '../CustomerDetails/CustomerMoreDetailsItem.vue';
import { useDashboardStore } from '@/stores/dashboard';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});
const { formatNumberRoundOfDecimal, formatSecondsToMins, formatSecondsToHour } = useAmountFormatter();
const { iDate, dateText } = storeToRefs(useDateStore());
const { c1HealthScore } = storeToRefs(useC1HealthScoreStore());
const { c2Count } = storeToRefs(useDashboardStore());

const tabs = [
  { name: 'customer_user_stats', label: t('customer_user_stats') },
  { name: 'team_stats', label: t('team_stats') },
];

const selectedTab = ref(tabs[0].name);

const { dialogRef, onDialogCancel } = useDialogPluginComponent();

const time = computed(() => {
  switch (iDate.value?.type) {
    case DateTypeEnum.Day:
      return t('day');
    case DateTypeEnum.Week:
      return t('week');
    case DateTypeEnum.Month:
      return t('month');
    case DateTypeEnum.Quarter:
      return t('quarter');
    case DateTypeEnum.Year:
      return t('year');
  }
  return '';
});

const retentionInfoYoY = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      headerIcon: phChartLineUp,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.yoy_churn_percentage,
      percentage: c1HealthScore.value?.yoy_churn_percentage_percent_change_from_last,
      footer: t('overview_more_details.yoy_customer_churn'),
    } as IMoreDetailsElement,
    {
      headerIcon: phChartLine,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.yoy_gross_revenue_retention,
      percentage: c1HealthScore.value?.yoy_gross_revenue_retention_percent_change_from_last,
      footer: t('overview_more_details.yoy_grr'),
    } as IMoreDetailsElement,
    {
      headerIcon: phTrendDown,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.yoy_net_revenue_retention,
      percentage: c1HealthScore.value?.yoy_net_revenue_retention_percent_change_from_last,
      footer: t('overview_more_details.yoy_nrr'),
    } as IMoreDetailsElement,
  ];
});

const retentionInfoQoQ = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      headerIcon: phChartLineUp,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.qoq_churn_percentage,
      percentage: c1HealthScore.value?.qoq_churn_percentage_percent_change_from_last,
      footer: t('overview_more_details.qoq_customer_churn'),
    } as IMoreDetailsElement,
    {
      headerIcon: phChartLine,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.qoq_gross_revenue_retention,
      percentage: c1HealthScore.value?.qoq_gross_revenue_retention_percent_change_from_last,
      footer: t('overview_more_details.qoq_grr'),
    } as IMoreDetailsElement,
    {
      headerIcon: phTrendDown,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.qoq_net_revenue_retention,
      percentage: c1HealthScore.value?.qoq_net_revenue_retention_percent_change_from_last,
      footer: t('overview_more_details.qoq_nrr'),
    } as IMoreDetailsElement,
  ];
});

const retentionInfoMoM = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      headerIcon: phChartLineUp,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.mom_churn_percentage,
      percentage: c1HealthScore.value?.mom_churn_percentage_percent_change_from_last,
      footer: t('overview_more_details.mom_customer_churn'),
    } as IMoreDetailsElement,
    {
      headerIcon: phChartLine,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.mom_gross_revenue_retention,
      percentage: c1HealthScore.value?.mom_gross_revenue_retention_percent_change_from_last,
      footer: t('overview_more_details.mom_grr'),
    } as IMoreDetailsElement,
    {
      headerIcon: phTrendDown,
      type: MoreDetailsElementType.PERCENTAGE,
      value: c1HealthScore.value?.mom_net_revenue_retention,
      percentage: c1HealthScore.value?.mom_net_revenue_retention_percent_change_from_last,
      footer: t('overview_more_details.mom_nrr'),
    } as IMoreDetailsElement,
  ];
});

const softwareInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      header: t('software_usage'),
      headerIcon: phClock,
      type: MoreDetailsElementType.TIME,
      value: c1HealthScore.value?.stvcs_total_sessions_time,
      percentage: c1HealthScore.value?.stvcs_total_sessions_time_percent_change_from_last,
      footer: t('total_time_of_usage'),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: perCustomerPerDayUsageTimeInMinutes(formatSecondsToMins(c1HealthScore.value?.stvcs_total_sessions_time)),
      percentage: c1HealthScore.value?.stvcs_total_sessions_time_percent_change_from_last,
      footer: t('mins_customer_time', { time: time.value }),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: formatSecondsToMins(c1HealthScore.value?.session_time_per_day_per_u2_avg),
      percentage: c1HealthScore.value?.session_time_per_day_per_u2_avg_percent_change_from_last,
      footer: t('mins_user_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});

const pageInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      header: t('pages_visited'),
      headerIcon: phFile,
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.pvvcs_total_no_of_page_visits),
      percentage: c1HealthScore.value?.pvvcs_total_no_of_page_visits_percent_change_from_last,
      footer: t('total_pages_visited'),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: perCustomerPerDayScore(+formatNumberRoundOfDecimal(c1HealthScore.value?.pvvcs_total_no_of_page_visits)),
      percentage: c1HealthScore.value?.pvvcs_total_no_of_page_visits_percent_change_from_last,
      footer: t('pages_customer_time', { time: time.value }),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.page_visit_per_day_per_u2_avg),
      percentage: c1HealthScore.value?.page_visit_per_day_per_u2_avg_percent_change_from_last,
      footer: t('pages_user_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});

const featureInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      header: t('feature_used'),
      headerIcon: phLayout,
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.fups_total_no_of_unique_features_used),
      percentage: c1HealthScore.value?.fups_total_no_of_unique_features_used_percent_change_from_last,
      footer: t('total_features_used'),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: perCustomerPerDayScore(
        +formatNumberRoundOfDecimal(c1HealthScore.value?.fups_total_no_of_unique_features_used),
      ),
      percentage: c1HealthScore.value?.fups_total_no_of_unique_features_used_percent_change_from_last,
      footer: t('features_customer_time', { time: time.value }),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.features_used_per_day_per_u2_average),
      percentage: c1HealthScore.value?.features_used_per_day_per_u2_average_percent_change_from_last,
      footer: t('user_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});

const bugInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      header: t('bugs_logged'),
      headerIcon: phBug,
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.no_of_bugs_logged),
      percentage: c1HealthScore.value?.no_of_bugs_logged_percent_change_from_last,
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: perCustomerPerDayScore(+formatNumberRoundOfDecimal(c1HealthScore.value?.no_of_bugs_logged)),
      percentage: c1HealthScore.value?.no_of_bugs_logged_percent_change_from_last,
      footer: t('bugs_customer_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});

const taskInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      header: t('tasks'),
      headerIcon: phCheckSquareOffset,
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.tasks_completed),
      percentage: c1HealthScore.value?.tasks_completed_percent_change_from_last,
      footer: t('total_task_done'),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: perCustomerPerDayScore(+formatNumberRoundOfDecimal(c1HealthScore.value?.tasks_completed_per_u1)),
      percentage: c1HealthScore.value?.tasks_completed_per_u1_percent_change_from_last,
      footer: t('tasks_user_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});

const messageInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      header: t('messages'),
      headerIcon: phMessengerLogo,
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.messages_handled),
      percentage: c1HealthScore.value?.messages_handled_percent_change_from_last,
      footer: t('messages_handled'),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.messages_per_u1),
      percentage: c1HealthScore.value?.messages_per_u1_spent_percent_change_from_last,
      footer: t('messages_user_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});

const actionInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  return [
    {
      header: t('actions'),
      headerIcon: phPulse,
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.actions_completed),
      percentage: c1HealthScore.value?.actions_completed_percent_change_from_last,
      footer: t('actions_done'),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: +formatNumberRoundOfDecimal(c1HealthScore.value?.actions_completed_per_u1),
      percentage: c1HealthScore.value?.actions_completed_per_u1_percent_change_from_last,
      footer: t('actions_user_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});
const actionTimeInfo = computed(() => {
  if (!c1HealthScore.value) {
    return [] as IMoreDetailsElement[];
  }
  const actionTime = c1HealthScore.value?.actions_time_spent ? c1HealthScore.value?.actions_time_spent : 0;
  const actionTimePerU1 = c1HealthScore.value?.actions_time_spent_per_u1
    ? c1HealthScore.value?.actions_time_spent_per_u1
    : 0;
  return [
    {
      header: t('actions_time'),
      headerIcon: phClock,
      type: MoreDetailsElementType.SCORE,
      value: formatSecondsToHour(actionTime),
      percentage: c1HealthScore.value?.actions_time_spent_percent_change_from_last,
      footer: t('hours_spent'),
    } as IMoreDetailsElement,
    {
      type: MoreDetailsElementType.SCORE,
      value: formatSecondsToMins(actionTimePerU1),
      percentage: c1HealthScore.value?.actions_time_spent_per_u1_percent_change_from_last,
      footer: t('mins_user_time', { time: time.value }),
    } as IMoreDetailsElement,
  ];
});

const perCustomerPerDayUsageTimeInMinutes = (usageTimeInMinutes?: number) => {
  let result = 0;
  if (!usageTimeInMinutes) return result;
  const usageInMinutes = +formatNumberRoundOfDecimal(usageTimeInMinutes);
  return +formatNumberRoundOfDecimal(usageInMinutes / c2Count.value, 2);
};

const perCustomerPerDayScore = (customerScore?: number) => {
  return +formatNumberRoundOfDecimal((customerScore || 0) / c2Count.value, 2);
};

function onMoreDetailsClose() {
  onDialogCancel();
}
</script>

<template>
  <q-dialog ref="dialogRef" full-height position="right">
    <div class="tw-min-w-40 tw-bg-light-shade-2">
      <div class="tw-flex tw-items-center tw-justify-between tw-bg-light-shade-1 tw-px-4 tw-py-4">
        <div class="tw-flex tw-items-baseline tw-text-2xl tw-font-bold tw-text-dark-shade-1">
          {{ t('more_details') }}
          <span class="tw-px-2 tw-text-lg tw-font-semibold tw-text-dark-shade-2"> {{ dateText }}</span>
        </div>
        <q-icon
          :name="phXBold"
          :size="'1.0rem'"
          class="tw-mr-4 tw-cursor-pointer tw-px-8"
          @click="onMoreDetailsClose"
        />
      </div>
      <q-tabs
        v-model="selectedTab"
        align="justify"
        active-color="primary"
        active-bg-color="#E6F1F6"
        class="tw-border-b tw-bg-light-shade-1"
        :breakpoint="0"
        no-caps
        inline-label
        indicator-color="primary"
      >
        <q-tab v-for="tab in tabs" :key="tab.name" v-bind="tab" :ripple="false" exact />
      </q-tabs>
      <div class="tw-bg-light-shade-2 tw-px-3">
        <!-- #region Your -->
        <div class="tw-my-6 tw-grid tw-grid-rows-4 tw-gap-3" v-if="selectedTab === tabs[0].name">
          <CustomerMoreDetailsItem :items="retentionInfoYoY" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="retentionInfoQoQ" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="retentionInfoMoM" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="softwareInfo" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="pageInfo" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="featureInfo" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="bugInfo" class="tw-py-6" />
        </div>
        <div class="tw-grid-rows-8 tw-my-6 tw-grid tw-gap-3" v-else>
          <CustomerMoreDetailsItem :items="taskInfo" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="messageInfo" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="actionInfo" class="tw-py-6" />
          <CustomerMoreDetailsItem :items="actionTimeInfo" class="tw-py-6" />
        </div>
      </div>
    </div>
  </q-dialog>
</template>
<style scoped lang="postcss">
:deep(.q-dialog__inner--minimized) {
  @apply tw-py-0 tw-pr-0;
}

:deep(.q-tab--inactive) {
  @apply tw-text-dark-shade-1;
}

:deep(.q-tab__label) {
  @apply tw-text-lg tw-font-semibold tw-leading-6;
}
</style>
