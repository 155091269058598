<script setup lang="ts">
import { ServerError, personalAccessTokenApi } from '@/api';
import { PersonalAccessToken } from '@/api/client';
import { MessageSchema } from '@/lib/i18n';
import { useAccountStore } from '@/stores/account';
import { IPagination } from '@/types/pagination.type';
import { QTableProps, useQuasar } from 'quasar';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { phTrash } from '@/phosphor-icons';
import { format as formatDate } from 'date-fns';
import axios, { AxiosError } from 'axios';
import DeleteConfirmationAlert from '@/components/DeleteConfirmationAlert.vue';
import { computed } from 'vue';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const accountStore = useAccountStore();

const $q = useQuasar();

const columns: QTableProps['columns'] = [
  {
    name: 'name',
    field: 'name',
    label: t('developer_setting.personal_access_token.list.table_columns.name'),
    align: 'left' as const,
    headerStyle: 'min-width: 150px',
  },
  {
    name: 'expires_at',
    field: 'expires_at',
    label: t('developer_setting.personal_access_token.list.table_columns.expires_at'),
    align: 'left' as const,
    headerStyle: 'min-width: 150px',
  },
  {
    name: 'action',
    field: 'action',
    label: '',
    align: 'left' as const,
    headerStyle: 'min-width: 100px',
  },
];

const pagination = ref({
  sortBy: 'created_at',
  descending: false,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 25,
} as IPagination);

const totalPagesCount = ref<number>(0);

const isLoadingList = ref<boolean>(false);

const isDeletingToken = ref<boolean>(false);

const showLoader = computed(() => {
  return isLoadingList.value || isDeletingToken.value;
});

const personalAccessTokenList = ref<PersonalAccessToken[]>([]);

const onRequestPages = async (props: any) => {
  if (!accountStore.activeC1Master || !accountStore.activeC1Master.id) return;
  const { page, rowsPerPage, sortBy, descending } = props.pagination;
  isLoadingList.value = true;
  const fetchCount = rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;
  const startRow = (page - 1) * rowsPerPage;
  try {
    const { data } = await personalAccessTokenApi.personalAccessTokensControllerFindAll({
      skip: startRow,
      limit: fetchCount,
      sortBy,
      descending: descending ? -1 : 1,
    });
    personalAccessTokenList.value = [...data.data];
    totalPagesCount.value = data.totalCount;
  } catch (e) {
    notifyUser(false, t('developer_setting.personal_access_token.list.list_fetch_failed'));
    personalAccessTokenList.value = [];
    totalPagesCount.value = 0;
  } finally {
    isLoadingList.value = false;
  }
  pagination.value = {
    page,
    rowsPerPage,
    sortBy,
    descending,
    rowsNumber: totalPagesCount.value,
  } as IPagination;
};

const showDeleteAlert = (personalAccessToken: PersonalAccessToken) => {
  $q.dialog({
    component: DeleteConfirmationAlert,
    componentProps: {
      alertTitle: t('developer_setting.personal_access_token.delete_alert.title'),
      alertBody: t('developer_setting.personal_access_token.delete_alert.body', {
        token_name: personalAccessToken.name || '',
      }),
      buttonConfirmText: t('developer_setting.personal_access_token.delete_alert.confirm_btn'),
      buttonCancelText: t('developer_setting.personal_access_token.delete_alert.cancel_btn'),
      isShowCheckbox: false,
    },
  })
    .onOk(async () => {
      await deleteAccessToken(personalAccessToken.id);
    })
    .onCancel(() => {
      console.log('delete alert cancelled');
    });
};

const deleteAccessToken = async (id: string) => {
  isDeletingToken.value = true;
  try {
    const { data } = await personalAccessTokenApi.personalAccessTokensControllerRemove({ id: id });
    if (data) {
      notifyUser(true, t('developer_setting.personal_access_token.list.token_deleted_successfully'));
      onRequestPages({ pagination: pagination.value });
    } else {
      notifyUser(false, t('developer_setting.personal_access_token.list.failed_to_delete_token'));
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const err = e as AxiosError<ServerError>;
      notifyUser(
        false,
        err.response?.data.message || t('developer_setting.personal_access_token.list.failed_to_delete_token'),
      );
    } else {
      notifyUser(false, t('developer_setting.personal_access_token.list.failed_to_delete_token'));
    }
  } finally {
    isDeletingToken.value = false;
  }
};

const notifyUser = (isSuccess: boolean, message: string) => {
  const notificationType = isSuccess ? 'positive' : 'negative';
  $q.notify({
    type: notificationType,
    message: message,
    color: notificationType,
    timeout: 2500,
  });
};

const getFormattedDate = (expiryDate?: string) => {
  if (expiryDate) {
    return formatDate(new Date(expiryDate), 'd MMM, yyyy');
  } else {
    return '-';
  }
};

onMounted(async () => {
  onRequestPages({ pagination: pagination.value });
});
</script>

<template>
  <q-table
    flat
    bordered
    :rows="personalAccessTokenList"
    :columns="columns"
    :row-key="(row) => row.id"
    :rows-per-page-options="[5, 10, 25, 50, 100]"
    v-model:pagination="pagination"
    :loading="showLoader"
    :no-data-label="t('developer_setting.personal_access_token.list.no_data')"
    class="sticky-header-column"
    @request="onRequestPages"
  >
    <template v-slot:loading>
      <q-inner-loading showing color="primary" class="z-max" size="md" />
    </template>

    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          <span class="tw-text-sm tw-font-semibold tw-leading-5 tw-text-dark-shade-4">{{ col.label }}</span>
        </q-th>
      </q-tr>
    </template>

    <template v-slot:body="props">
      <q-tr :props="props" :key="props.row.id">
        <q-td>
          <div class="tw-pt-2">
            {{ props.row.name }}
          </div>
        </q-td>

        <q-td>
          <div class="tw-pt-2">{{ getFormattedDate(props.row.expires_at) }}</div>
        </q-td>

        <q-td>
          <div class="tw-flex tw-cursor-pointer tw-justify-center tw-pt-2">
            <q-icon
              :name="phTrash"
              size="1.2rem"
              class="tw-text-data-viz-semantic-red-content"
              @click="showDeleteAlert(props.row)"
            />
          </div>
        </q-td>
      </q-tr>
    </template>

    <template v-slot:no-data="{ message }">
      <div class="full-width row flex-center q-gutter-sm tw-text-dark-shade-4">
        <span class="tw-text-sm tw-font-semibold tw-text-dark-shade-3"> {{ message }}</span>
      </div>
    </template>
  </q-table>
</template>

<style scoped lang="postcss">
:deep(.q-table__container.sticky-header-column) {
  max-height: calc(100vh - 200px);
}
</style>
