/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2DocumentSchema } from '../model/';
// @ts-ignore
import { PaginatedDocumentDto } from '../model/';
/**
 * C2DocumentsApi - axios parameter creator
 * @export
 */
export const C2DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentControllerDeleteOneDocument: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentControllerDeleteOneDocument', 'id', id)
            const localVarPath = `/api/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} c2Id C2 Id
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentControllerFetchAllDocuments: async (c2Id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2Id' is not null or undefined
            assertParamExists('documentControllerFetchAllDocuments', 'c2Id', c2Id)
            const localVarPath = `/api/documents/c2-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (c2Id !== undefined) {
                localVarQueryParameter['c2_id'] = c2Id;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2DocumentsApi - functional programming interface
 * @export
 */
export const C2DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentControllerDeleteOneDocument(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2DocumentSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerDeleteOneDocument(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} c2Id C2 Id
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentControllerFetchAllDocuments(c2Id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerFetchAllDocuments(c2Id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2DocumentsApi - factory interface
 * @export
 */
export const C2DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentControllerDeleteOneDocument(id: string, xTenantId?: string, options?: any): AxiosPromise<C2DocumentSchema> {
            return localVarFp.documentControllerDeleteOneDocument(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} c2Id C2 Id
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentControllerFetchAllDocuments(c2Id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedDocumentDto> {
            return localVarFp.documentControllerFetchAllDocuments(c2Id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for documentControllerDeleteOneDocument operation in C2DocumentsApi.
 * @export
 * @interface C2DocumentsApiDocumentControllerDeleteOneDocumentRequest
 */
export interface C2DocumentsApiDocumentControllerDeleteOneDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof C2DocumentsApiDocumentControllerDeleteOneDocument
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2DocumentsApiDocumentControllerDeleteOneDocument
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for documentControllerFetchAllDocuments operation in C2DocumentsApi.
 * @export
 * @interface C2DocumentsApiDocumentControllerFetchAllDocumentsRequest
 */
export interface C2DocumentsApiDocumentControllerFetchAllDocumentsRequest {
    /**
     * C2 Id
     * @type {string}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly c2Id: string

    /**
     * 
     * @type {string}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2DocumentsApiDocumentControllerFetchAllDocuments
     */
    readonly after?: string
}

/**
 * C2DocumentsApi - object-oriented interface
 * @export
 * @class C2DocumentsApi
 * @extends {BaseAPI}
 */
export class C2DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {C2DocumentsApiDocumentControllerDeleteOneDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2DocumentsApi
     */
    public documentControllerDeleteOneDocument(requestParameters: C2DocumentsApiDocumentControllerDeleteOneDocumentRequest, options?: AxiosRequestConfig) {
        return C2DocumentsApiFp(this.configuration).documentControllerDeleteOneDocument(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {C2DocumentsApiDocumentControllerFetchAllDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2DocumentsApi
     */
    public documentControllerFetchAllDocuments(requestParameters: C2DocumentsApiDocumentControllerFetchAllDocumentsRequest, options?: AxiosRequestConfig) {
        return C2DocumentsApiFp(this.configuration).documentControllerFetchAllDocuments(requestParameters.c2Id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }
}
