<script setup lang="ts">
import { addWeeks, endOfYear, getYear } from 'date-fns';
import { ref, computed, watch, onMounted } from 'vue';
import { phCheck } from '@/phosphor-icons';
import { IDate } from '@/types/date.type';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { QScrollArea } from 'quasar';
import { DateTypeEnum } from '@/api/client';
import { useAccountStore } from '@/stores/account';
import { storeToRefs } from 'pinia';

const props = defineProps<{ year: number; selectedDate: IDate }>();
const emits = defineEmits(['onDateSelection']);

const scrollAreaRef = ref<QScrollArea | undefined>();

const { getIDateWeekObj, iso8601_week_no } = useDateFormatter();

const selection = ref<IDate>(props.selectedDate);

const currentDate = new Date();
const currentYear = getYear(currentDate);
const currentWeek = iso8601_week_no(currentDate);

const itemHeight = 48;

const { onboardingCompletedAt } = storeToRefs(useAccountStore());

const iWeeks = computed(() => {
  const items = [] as IDate[];
  let startDate = new Date(props.year, 0, 1);
  if (onboardingCompletedAt.value) {
    if (props.year === onboardingCompletedAt.value.getFullYear()) {
      startDate = onboardingCompletedAt.value;
    }
  }
  const endDate = endOfYear(startDate);
  while (startDate < endDate) {
    const weekInfo = getIDateWeekObj(startDate);
    weekInfo.isDisabled =
      currentYear >= props.year && currentWeek <= (weekInfo.itemNo || 0) && currentDate < weekInfo.endDate
        ? true
        : false;
    startDate = addWeeks(startDate, 1);
    items.push(weekInfo);
  }
  return items;
});

watch(
  () => props.year,
  () => {
    scrollToPosition();
  },
  { immediate: true },
);

onMounted(() => {
  scrollToPosition();
});

function scrollToPosition() {
  const selected = selection.value;
  if (selected && selected.type === DateTypeEnum.Week) {
    const selectedWeekOffset = iWeeks.value.findIndex((x) => x.endDate.toString() === selected.endDate.toString());
    if (selectedWeekOffset) {
      scrollAreaRef.value?.setScrollPosition('vertical', (selectedWeekOffset - 1) * itemHeight);
    }
  } else {
    const firstDisableWeekOffset = iWeeks.value.findIndex((x) => x.isDisabled);
    if (firstDisableWeekOffset > 0 && currentYear === props.year) {
      scrollAreaRef.value?.setScrollPosition('vertical', (firstDisableWeekOffset - 1) * itemHeight);
    } else {
      scrollAreaRef.value?.setScrollPosition('vertical', 0);
    }
  }
}

function isSelected(week: IDate) {
  const selected = selection.value;
  return selected.endDate?.toString() === week.endDate?.toString() && week.type === selected.type;
}

function onItemClick(week: IDate) {
  selection.value = week;
  emits('onDateSelection', week);
}
</script>

<template>
  <div>
    <q-scroll-area ref="scrollAreaRef" style="height: 14rem" class="tw-h-full tw-w-full">
      <q-list>
        <q-item
          v-for="week in iWeeks"
          :key="week.text"
          clickable
          :active="isSelected(week)"
          @click="onItemClick(week)"
          active-class="my-menu-link"
          :disable="week.isDisabled"
        >
          <q-item-section>{{ week.text }}</q-item-section>
          <q-item-section side v-if="isSelected(week)">
            <q-icon :name="phCheck" class="tw-text-light-shade-1" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
  </div>
</template>
<style scoped lang="postcss">
.my-menu-link {
  @apply tw-bg-primary-dark tw-text-light-shade-1;
}
q-item__section--side {
  @apply tw-text-light-shade-1;
}
:deep(.disable) {
  @apply !tw-text-dark-shade-4;
}
</style>
