/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateSitemapOnboardingStep } from '../model/';
// @ts-ignore
import { OnboardingStep } from '../model/';
// @ts-ignore
import { UserObjectTestResultDto } from '../model/';
/**
 * OnboardingStepsApi - axios parameter creator
 * @export
 */
export const OnboardingStepsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Onboarding Steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerFindOne: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onboarding-steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Onboarding Step
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerGetOnboardingStep: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onboarding-steps/get-onboarding-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sitemap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerGetSitemap: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onboarding-steps/sitemap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Sitemap
         * @param {CreateSitemapOnboardingStep} createSitemapOnboardingStep 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerSetSitemap: async (createSitemapOnboardingStep: CreateSitemapOnboardingStep, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSitemapOnboardingStep' is not null or undefined
            assertParamExists('onboardingStepsControllerSetSitemap', 'createSitemapOnboardingStep', createSitemapOnboardingStep)
            const localVarPath = `/api/onboarding-steps/sitemap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSitemapOnboardingStep, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test User Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerTestUserObject: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onboarding-steps/test-user-object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingStepsApi - functional programming interface
 * @export
 */
export const OnboardingStepsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnboardingStepsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Onboarding Steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingStepsControllerFindOne(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingStepsControllerFindOne(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Onboarding Step
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingStepsControllerGetOnboardingStep(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingStepsControllerGetOnboardingStep(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Sitemap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingStepsControllerGetSitemap(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingStepsControllerGetSitemap(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Sitemap
         * @param {CreateSitemapOnboardingStep} createSitemapOnboardingStep 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingStepsControllerSetSitemap(createSitemapOnboardingStep: CreateSitemapOnboardingStep, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingStepsControllerSetSitemap(createSitemapOnboardingStep, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test User Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingStepsControllerTestUserObject(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserObjectTestResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingStepsControllerTestUserObject(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnboardingStepsApi - factory interface
 * @export
 */
export const OnboardingStepsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnboardingStepsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Onboarding Steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerFindOne(options?: any): AxiosPromise<OnboardingStep> {
            return localVarFp.onboardingStepsControllerFindOne(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Onboarding Step
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerGetOnboardingStep(options?: any): AxiosPromise<object> {
            return localVarFp.onboardingStepsControllerGetOnboardingStep(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sitemap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerGetSitemap(options?: any): AxiosPromise<object> {
            return localVarFp.onboardingStepsControllerGetSitemap(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Sitemap
         * @param {CreateSitemapOnboardingStep} createSitemapOnboardingStep 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerSetSitemap(createSitemapOnboardingStep: CreateSitemapOnboardingStep, options?: any): AxiosPromise<void> {
            return localVarFp.onboardingStepsControllerSetSitemap(createSitemapOnboardingStep, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test User Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingStepsControllerTestUserObject(options?: any): AxiosPromise<UserObjectTestResultDto> {
            return localVarFp.onboardingStepsControllerTestUserObject(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for onboardingStepsControllerSetSitemap operation in OnboardingStepsApi.
 * @export
 * @interface OnboardingStepsApiOnboardingStepsControllerSetSitemapRequest
 */
export interface OnboardingStepsApiOnboardingStepsControllerSetSitemapRequest {
    /**
     * 
     * @type {CreateSitemapOnboardingStep}
     * @memberof OnboardingStepsApiOnboardingStepsControllerSetSitemap
     */
    readonly createSitemapOnboardingStep: CreateSitemapOnboardingStep
}

/**
 * OnboardingStepsApi - object-oriented interface
 * @export
 * @class OnboardingStepsApi
 * @extends {BaseAPI}
 */
export class OnboardingStepsApi extends BaseAPI {
    /**
     * 
     * @summary Get Onboarding Steps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingStepsApi
     */
    public onboardingStepsControllerFindOne(options?: AxiosRequestConfig) {
        return OnboardingStepsApiFp(this.configuration).onboardingStepsControllerFindOne(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Onboarding Step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingStepsApi
     */
    public onboardingStepsControllerGetOnboardingStep(options?: AxiosRequestConfig) {
        return OnboardingStepsApiFp(this.configuration).onboardingStepsControllerGetOnboardingStep(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sitemap
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingStepsApi
     */
    public onboardingStepsControllerGetSitemap(options?: AxiosRequestConfig) {
        return OnboardingStepsApiFp(this.configuration).onboardingStepsControllerGetSitemap(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Sitemap
     * @param {OnboardingStepsApiOnboardingStepsControllerSetSitemapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingStepsApi
     */
    public onboardingStepsControllerSetSitemap(requestParameters: OnboardingStepsApiOnboardingStepsControllerSetSitemapRequest, options?: AxiosRequestConfig) {
        return OnboardingStepsApiFp(this.configuration).onboardingStepsControllerSetSitemap(requestParameters.createSitemapOnboardingStep, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test User Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingStepsApi
     */
    public onboardingStepsControllerTestUserObject(options?: AxiosRequestConfig) {
        return OnboardingStepsApiFp(this.configuration).onboardingStepsControllerTestUserObject(options).then((request) => request(this.axios, this.basePath));
    }
}
