import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useC2HealthScoreStore } from '@/stores/c2HealthScore';
import { useC2TypeHealthScore } from '@/stores/c2TypeHealthScore';
import { IKpiRow } from '@/types/customer.type';
import { useDateStore } from '@/stores/date';
import { C2TypeHealthScore, Kpi, KpiNameEnum } from '@/api/client';
import { useHealthTrendChart } from '@/composables/useHealthTrendChart';

export const useCustomerStore = defineStore('customer', () => {
  const c2HealthScoreStore = useC2HealthScoreStore();
  const c2TypeHealthScoreStore = useC2TypeHealthScore();
  const dateStore = useDateStore();

  const { filterOptionsCustomer } = useHealthTrendChart();

  // const selectedKpiTrendFilter = ref<Kpi>({ name: KpiNameEnum.Zs } as Kpi);

  const currentC2TypeId = computed(() => {
    return c2HealthScoreStore.c2HealthScore?.c2_type_id || '';
  });

  const healthTrendSelectedFilter = ref<{
    label: string;
    value: string;
  }>(filterOptionsCustomer[0]);

  const overviewSelectedFilter = ref<Kpi>({ name: KpiNameEnum.Zs } as Kpi);

  const usageSuccessSelectedFilter = ref<Kpi>({ name: KpiNameEnum.Uss } as Kpi);

  const peripheralSuccessSelectedFilter = ref<Kpi>({ name: KpiNameEnum.Pss } as Kpi);

  const upsellSuccessSelectedFilter = ref<Kpi>({ name: KpiNameEnum.Upss } as Kpi);

  function updateHealthTrendFilterSelection(selection: { label: string; value: string }) {
    healthTrendSelectedFilter.value = selection;
  }

  const csHandfeelTableRows = computed(() => {
    const rows = [] as IKpiRow[];
    const c2HealthScoreList = c2HealthScoreStore.c2HealthScoreList.sort((a, b) => (a.date < b.date ? 1 : -1));
    const c2TypeHealthScoreList = c2TypeHealthScoreStore.c2TypeHealthScoreList.filter(
      (x) => x.c2_type_id == currentC2TypeId.value,
    );
    if (!c2HealthScoreList || !c2TypeHealthScoreList) {
      return rows;
    }
    let c2TypeHealthScoreObj: C2TypeHealthScore | null;
    let dateStr = '';

    c2HealthScoreList.forEach((item) => {
      dateStr = dateStore.getDateLabel(item.date);
      c2TypeHealthScoreObj =
        c2TypeHealthScoreList.find(
          (x) =>
            x.day_id === item.day_id &&
            x.week_id === item.week_id &&
            x.month_id === item.month_id &&
            x.quarter_id === item.quarter_id &&
            x.year_id === x.year_id,
        ) ?? null;

      rows.push({
        kpiId: item?.hfs_kpi_id,
        kpiName: dateStr,
        kpiScore: item?.hfs_percentile || 0,
        kpiPercentageChange: item?.hfs_absolute_percentile_change,
        kpiColorCode: item?.hfs_color_code || 'black',
        cohortScore: c2TypeHealthScoreObj?.hfs_percentile || 0,
        cohortPercentageChange: c2TypeHealthScoreObj?.hfs_absolute_percentile_change,
        cohortColorCode: c2TypeHealthScoreObj?.hfs_color_code || 'black',
        showHealthInfo: false,
      } as IKpiRow);
    });
    return rows;
  });

  return {
    overviewSelectedFilter,
    healthTrendSelectedFilter,
    usageSuccessSelectedFilter,
    updateHealthTrendFilterSelection,
    csHandfeelTableRows,
    peripheralSuccessSelectedFilter,
    upsellSuccessSelectedFilter,
    // selectedKpiTrendFilter,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCustomerStore, import.meta.hot));
}
