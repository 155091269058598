/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateU2ContactDto } from '../model/';
// @ts-ignore
import { PaginatedU2ContactsDto } from '../model/';
// @ts-ignore
import { U2Contacts } from '../model/';
// @ts-ignore
import { U2ContactsFilterDto } from '../model/';
/**
 * U2ContactsApi - axios parameter creator
 * @export
 */
export const U2ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Contact
         * @param {CreateU2ContactDto} createU2ContactDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerCreateContact: async (createU2ContactDto: CreateU2ContactDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createU2ContactDto' is not null or undefined
            assertParamExists('u2ContactsControllerCreateContact', 'createU2ContactDto', createU2ContactDto)
            const localVarPath = `/api/u2-contacts/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createU2ContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download user contact list
         * @param {U2ContactsFilterDto} u2ContactsFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerDownloadUserInfoCsv: async (u2ContactsFilterDto: U2ContactsFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2ContactsFilterDto' is not null or undefined
            assertParamExists('u2ContactsControllerDownloadUserInfoCsv', 'u2ContactsFilterDto', u2ContactsFilterDto)
            const localVarPath = `/api/u2-contacts/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(u2ContactsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Emails For Contacts
         * @param {string} email 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerFindAllContactEmails: async (email: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('u2ContactsControllerFindAllContactEmails', 'email', email)
            const localVarPath = `/api/u2-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Contacts
         * @param {U2ContactsFilterDto} u2ContactsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerFindAllPaginatedContacts: async (u2ContactsFilterDto: U2ContactsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2ContactsFilterDto' is not null or undefined
            assertParamExists('u2ContactsControllerFindAllPaginatedContacts', 'u2ContactsFilterDto', u2ContactsFilterDto)
            const localVarPath = `/api/u2-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(u2ContactsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2ContactsApi - functional programming interface
 * @export
 */
export const U2ContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2ContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Contact
         * @param {CreateU2ContactDto} createU2ContactDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ContactsControllerCreateContact(createU2ContactDto: CreateU2ContactDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2Contacts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ContactsControllerCreateContact(createU2ContactDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download user contact list
         * @param {U2ContactsFilterDto} u2ContactsFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ContactsControllerDownloadUserInfoCsv(u2ContactsFilterDto: U2ContactsFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ContactsControllerDownloadUserInfoCsv(u2ContactsFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch Emails For Contacts
         * @param {string} email 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ContactsControllerFindAllContactEmails(email: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ContactsControllerFindAllContactEmails(email, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Contacts
         * @param {U2ContactsFilterDto} u2ContactsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2ContactsControllerFindAllPaginatedContacts(u2ContactsFilterDto: U2ContactsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedU2ContactsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2ContactsControllerFindAllPaginatedContacts(u2ContactsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2ContactsApi - factory interface
 * @export
 */
export const U2ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2ContactsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Contact
         * @param {CreateU2ContactDto} createU2ContactDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerCreateContact(createU2ContactDto: CreateU2ContactDto, xTenantId?: string, options?: any): AxiosPromise<U2Contacts> {
            return localVarFp.u2ContactsControllerCreateContact(createU2ContactDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download user contact list
         * @param {U2ContactsFilterDto} u2ContactsFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerDownloadUserInfoCsv(u2ContactsFilterDto: U2ContactsFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.u2ContactsControllerDownloadUserInfoCsv(u2ContactsFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Emails For Contacts
         * @param {string} email 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerFindAllContactEmails(email: string, xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.u2ContactsControllerFindAllContactEmails(email, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Contacts
         * @param {U2ContactsFilterDto} u2ContactsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2ContactsControllerFindAllPaginatedContacts(u2ContactsFilterDto: U2ContactsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedU2ContactsDto> {
            return localVarFp.u2ContactsControllerFindAllPaginatedContacts(u2ContactsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2ContactsControllerCreateContact operation in U2ContactsApi.
 * @export
 * @interface U2ContactsApiU2ContactsControllerCreateContactRequest
 */
export interface U2ContactsApiU2ContactsControllerCreateContactRequest {
    /**
     * 
     * @type {CreateU2ContactDto}
     * @memberof U2ContactsApiU2ContactsControllerCreateContact
     */
    readonly createU2ContactDto: CreateU2ContactDto

    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerCreateContact
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ContactsControllerDownloadUserInfoCsv operation in U2ContactsApi.
 * @export
 * @interface U2ContactsApiU2ContactsControllerDownloadUserInfoCsvRequest
 */
export interface U2ContactsApiU2ContactsControllerDownloadUserInfoCsvRequest {
    /**
     * 
     * @type {U2ContactsFilterDto}
     * @memberof U2ContactsApiU2ContactsControllerDownloadUserInfoCsv
     */
    readonly u2ContactsFilterDto: U2ContactsFilterDto

    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerDownloadUserInfoCsv
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerDownloadUserInfoCsv
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof U2ContactsApiU2ContactsControllerDownloadUserInfoCsv
     */
    readonly descending?: boolean
}

/**
 * Request parameters for u2ContactsControllerFindAllContactEmails operation in U2ContactsApi.
 * @export
 * @interface U2ContactsApiU2ContactsControllerFindAllContactEmailsRequest
 */
export interface U2ContactsApiU2ContactsControllerFindAllContactEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerFindAllContactEmails
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerFindAllContactEmails
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2ContactsControllerFindAllPaginatedContacts operation in U2ContactsApi.
 * @export
 * @interface U2ContactsApiU2ContactsControllerFindAllPaginatedContactsRequest
 */
export interface U2ContactsApiU2ContactsControllerFindAllPaginatedContactsRequest {
    /**
     * 
     * @type {U2ContactsFilterDto}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly u2ContactsFilterDto: U2ContactsFilterDto

    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof U2ContactsApiU2ContactsControllerFindAllPaginatedContacts
     */
    readonly after?: string
}

/**
 * U2ContactsApi - object-oriented interface
 * @export
 * @class U2ContactsApi
 * @extends {BaseAPI}
 */
export class U2ContactsApi extends BaseAPI {
    /**
     * 
     * @summary Create Contact
     * @param {U2ContactsApiU2ContactsControllerCreateContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2ContactsApi
     */
    public u2ContactsControllerCreateContact(requestParameters: U2ContactsApiU2ContactsControllerCreateContactRequest, options?: AxiosRequestConfig) {
        return U2ContactsApiFp(this.configuration).u2ContactsControllerCreateContact(requestParameters.createU2ContactDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download user contact list
     * @param {U2ContactsApiU2ContactsControllerDownloadUserInfoCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2ContactsApi
     */
    public u2ContactsControllerDownloadUserInfoCsv(requestParameters: U2ContactsApiU2ContactsControllerDownloadUserInfoCsvRequest, options?: AxiosRequestConfig) {
        return U2ContactsApiFp(this.configuration).u2ContactsControllerDownloadUserInfoCsv(requestParameters.u2ContactsFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Emails For Contacts
     * @param {U2ContactsApiU2ContactsControllerFindAllContactEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2ContactsApi
     */
    public u2ContactsControllerFindAllContactEmails(requestParameters: U2ContactsApiU2ContactsControllerFindAllContactEmailsRequest, options?: AxiosRequestConfig) {
        return U2ContactsApiFp(this.configuration).u2ContactsControllerFindAllContactEmails(requestParameters.email, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Contacts
     * @param {U2ContactsApiU2ContactsControllerFindAllPaginatedContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2ContactsApi
     */
    public u2ContactsControllerFindAllPaginatedContacts(requestParameters: U2ContactsApiU2ContactsControllerFindAllPaginatedContactsRequest, options?: AxiosRequestConfig) {
        return U2ContactsApiFp(this.configuration).u2ContactsControllerFindAllPaginatedContacts(requestParameters.u2ContactsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }
}
