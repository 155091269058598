/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppCustomDataLogDto } from '../model/';
// @ts-ignore
import { AppCustomDataLogs } from '../model/';
// @ts-ignore
import { AppCustomDataLogsFilterDto } from '../model/';
// @ts-ignore
import { PaginatedAppCustomDataLog } from '../model/';
// @ts-ignore
import { PopulateCustomFieldsDto } from '../model/';
// @ts-ignore
import { UniqueCustomFieldValuesDto } from '../model/';
// @ts-ignore
import { UniqueCustomFieldsDto } from '../model/';
/**
 * AppCustomDataLogsApi - axios parameter creator
 * @export
 */
export const AppCustomDataLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create custom field entry from web
         * @param {PopulateCustomFieldsDto} populateCustomFieldsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerCreateCustomField: async (populateCustomFieldsDto: PopulateCustomFieldsDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'populateCustomFieldsDto' is not null or undefined
            assertParamExists('appCustomDataLogsControllerCreateCustomField', 'populateCustomFieldsDto', populateCustomFieldsDto)
            const localVarPath = `/api/app-custom-data-logs/create-custom-field-from-web`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(populateCustomFieldsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all custom fields
         * @param {boolean} isUnique Get only unique field name or all
         * @param {string} [xTenantId] 
         * @param {string} [source] 
         * @param {string} [apiId] Optional field if provided list will be displayed on for that api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerFindAll: async (isUnique: boolean, xTenantId?: string, source?: string, apiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isUnique' is not null or undefined
            assertParamExists('appCustomDataLogsControllerFindAll', 'isUnique', isUnique)
            const localVarPath = `/api/app-custom-data-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isUnique !== undefined) {
                localVarQueryParameter['is_unique'] = isUnique;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (apiId !== undefined) {
                localVarQueryParameter['api_id'] = apiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all api data logs with pagination
         * @param {AppCustomDataLogsFilterDto} appCustomDataLogsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerFindAllApiLogs: async (appCustomDataLogsFilterDto: AppCustomDataLogsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appCustomDataLogsFilterDto' is not null or undefined
            assertParamExists('appCustomDataLogsControllerFindAllApiLogs', 'appCustomDataLogsFilterDto', appCustomDataLogsFilterDto)
            const localVarPath = `/api/app-custom-data-logs/api-history-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appCustomDataLogsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique custom field names with source api, web, csv
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerGetAllUniqueCustomFieldNames: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-custom-data-logs/custom-field-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique custom field values against source and custom field name
         * @param {string} source 
         * @param {string} customFieldName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerGetAllUniqueCustomFieldValues: async (source: string, customFieldName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('appCustomDataLogsControllerGetAllUniqueCustomFieldValues', 'source', source)
            // verify required parameter 'customFieldName' is not null or undefined
            assertParamExists('appCustomDataLogsControllerGetAllUniqueCustomFieldValues', 'customFieldName', customFieldName)
            const localVarPath = `/api/app-custom-data-logs/custom-field-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (customFieldName !== undefined) {
                localVarQueryParameter['custom_field_name'] = customFieldName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Custom field names from web and csv
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerGetCustomFieldNames: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-custom-data-logs/custom-field-definition-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppCustomDataLogsApi - functional programming interface
 * @export
 */
export const AppCustomDataLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppCustomDataLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create custom field entry from web
         * @param {PopulateCustomFieldsDto} populateCustomFieldsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCustomDataLogsControllerCreateCustomField(populateCustomFieldsDto: PopulateCustomFieldsDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppCustomDataLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCustomDataLogsControllerCreateCustomField(populateCustomFieldsDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all custom fields
         * @param {boolean} isUnique Get only unique field name or all
         * @param {string} [xTenantId] 
         * @param {string} [source] 
         * @param {string} [apiId] Optional field if provided list will be displayed on for that api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCustomDataLogsControllerFindAll(isUnique: boolean, xTenantId?: string, source?: string, apiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppCustomDataLogDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCustomDataLogsControllerFindAll(isUnique, xTenantId, source, apiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all api data logs with pagination
         * @param {AppCustomDataLogsFilterDto} appCustomDataLogsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCustomDataLogsControllerFindAllApiLogs(appCustomDataLogsFilterDto: AppCustomDataLogsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAppCustomDataLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCustomDataLogsControllerFindAllApiLogs(appCustomDataLogsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique custom field names with source api, web, csv
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCustomDataLogsControllerGetAllUniqueCustomFieldNames(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UniqueCustomFieldsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCustomDataLogsControllerGetAllUniqueCustomFieldNames(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique custom field values against source and custom field name
         * @param {string} source 
         * @param {string} customFieldName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCustomDataLogsControllerGetAllUniqueCustomFieldValues(source: string, customFieldName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniqueCustomFieldValuesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCustomDataLogsControllerGetAllUniqueCustomFieldValues(source, customFieldName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Custom field names from web and csv
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCustomDataLogsControllerGetCustomFieldNames(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCustomDataLogsControllerGetCustomFieldNames(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppCustomDataLogsApi - factory interface
 * @export
 */
export const AppCustomDataLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppCustomDataLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create custom field entry from web
         * @param {PopulateCustomFieldsDto} populateCustomFieldsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerCreateCustomField(populateCustomFieldsDto: PopulateCustomFieldsDto, xTenantId?: string, options?: any): AxiosPromise<AppCustomDataLogs> {
            return localVarFp.appCustomDataLogsControllerCreateCustomField(populateCustomFieldsDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all custom fields
         * @param {boolean} isUnique Get only unique field name or all
         * @param {string} [xTenantId] 
         * @param {string} [source] 
         * @param {string} [apiId] Optional field if provided list will be displayed on for that api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerFindAll(isUnique: boolean, xTenantId?: string, source?: string, apiId?: string, options?: any): AxiosPromise<Array<AppCustomDataLogDto>> {
            return localVarFp.appCustomDataLogsControllerFindAll(isUnique, xTenantId, source, apiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all api data logs with pagination
         * @param {AppCustomDataLogsFilterDto} appCustomDataLogsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerFindAllApiLogs(appCustomDataLogsFilterDto: AppCustomDataLogsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedAppCustomDataLog> {
            return localVarFp.appCustomDataLogsControllerFindAllApiLogs(appCustomDataLogsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique custom field names with source api, web, csv
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerGetAllUniqueCustomFieldNames(xTenantId?: string, options?: any): AxiosPromise<UniqueCustomFieldsDto> {
            return localVarFp.appCustomDataLogsControllerGetAllUniqueCustomFieldNames(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique custom field values against source and custom field name
         * @param {string} source 
         * @param {string} customFieldName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerGetAllUniqueCustomFieldValues(source: string, customFieldName: string, xTenantId?: string, options?: any): AxiosPromise<Array<UniqueCustomFieldValuesDto>> {
            return localVarFp.appCustomDataLogsControllerGetAllUniqueCustomFieldValues(source, customFieldName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Custom field names from web and csv
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCustomDataLogsControllerGetCustomFieldNames(xTenantId?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.appCustomDataLogsControllerGetCustomFieldNames(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appCustomDataLogsControllerCreateCustomField operation in AppCustomDataLogsApi.
 * @export
 * @interface AppCustomDataLogsApiAppCustomDataLogsControllerCreateCustomFieldRequest
 */
export interface AppCustomDataLogsApiAppCustomDataLogsControllerCreateCustomFieldRequest {
    /**
     * 
     * @type {PopulateCustomFieldsDto}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerCreateCustomField
     */
    readonly populateCustomFieldsDto: PopulateCustomFieldsDto

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerCreateCustomField
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appCustomDataLogsControllerFindAll operation in AppCustomDataLogsApi.
 * @export
 * @interface AppCustomDataLogsApiAppCustomDataLogsControllerFindAllRequest
 */
export interface AppCustomDataLogsApiAppCustomDataLogsControllerFindAllRequest {
    /**
     * Get only unique field name or all
     * @type {boolean}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAll
     */
    readonly isUnique: boolean

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAll
     */
    readonly source?: string

    /**
     * Optional field if provided list will be displayed on for that api
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAll
     */
    readonly apiId?: string
}

/**
 * Request parameters for appCustomDataLogsControllerFindAllApiLogs operation in AppCustomDataLogsApi.
 * @export
 * @interface AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogsRequest
 */
export interface AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogsRequest {
    /**
     * 
     * @type {AppCustomDataLogsFilterDto}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly appCustomDataLogsFilterDto: AppCustomDataLogsFilterDto

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogs
     */
    readonly after?: string
}

/**
 * Request parameters for appCustomDataLogsControllerGetAllUniqueCustomFieldNames operation in AppCustomDataLogsApi.
 * @export
 * @interface AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldNamesRequest
 */
export interface AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldNamesRequest {
    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldNames
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appCustomDataLogsControllerGetAllUniqueCustomFieldValues operation in AppCustomDataLogsApi.
 * @export
 * @interface AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldValuesRequest
 */
export interface AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldValuesRequest {
    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldValues
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldValues
     */
    readonly customFieldName: string

    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldValues
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appCustomDataLogsControllerGetCustomFieldNames operation in AppCustomDataLogsApi.
 * @export
 * @interface AppCustomDataLogsApiAppCustomDataLogsControllerGetCustomFieldNamesRequest
 */
export interface AppCustomDataLogsApiAppCustomDataLogsControllerGetCustomFieldNamesRequest {
    /**
     * 
     * @type {string}
     * @memberof AppCustomDataLogsApiAppCustomDataLogsControllerGetCustomFieldNames
     */
    readonly xTenantId?: string
}

/**
 * AppCustomDataLogsApi - object-oriented interface
 * @export
 * @class AppCustomDataLogsApi
 * @extends {BaseAPI}
 */
export class AppCustomDataLogsApi extends BaseAPI {
    /**
     * 
     * @summary Create custom field entry from web
     * @param {AppCustomDataLogsApiAppCustomDataLogsControllerCreateCustomFieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppCustomDataLogsApi
     */
    public appCustomDataLogsControllerCreateCustomField(requestParameters: AppCustomDataLogsApiAppCustomDataLogsControllerCreateCustomFieldRequest, options?: AxiosRequestConfig) {
        return AppCustomDataLogsApiFp(this.configuration).appCustomDataLogsControllerCreateCustomField(requestParameters.populateCustomFieldsDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all custom fields
     * @param {AppCustomDataLogsApiAppCustomDataLogsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppCustomDataLogsApi
     */
    public appCustomDataLogsControllerFindAll(requestParameters: AppCustomDataLogsApiAppCustomDataLogsControllerFindAllRequest, options?: AxiosRequestConfig) {
        return AppCustomDataLogsApiFp(this.configuration).appCustomDataLogsControllerFindAll(requestParameters.isUnique, requestParameters.xTenantId, requestParameters.source, requestParameters.apiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all api data logs with pagination
     * @param {AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppCustomDataLogsApi
     */
    public appCustomDataLogsControllerFindAllApiLogs(requestParameters: AppCustomDataLogsApiAppCustomDataLogsControllerFindAllApiLogsRequest, options?: AxiosRequestConfig) {
        return AppCustomDataLogsApiFp(this.configuration).appCustomDataLogsControllerFindAllApiLogs(requestParameters.appCustomDataLogsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique custom field names with source api, web, csv
     * @param {AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppCustomDataLogsApi
     */
    public appCustomDataLogsControllerGetAllUniqueCustomFieldNames(requestParameters: AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldNamesRequest = {}, options?: AxiosRequestConfig) {
        return AppCustomDataLogsApiFp(this.configuration).appCustomDataLogsControllerGetAllUniqueCustomFieldNames(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique custom field values against source and custom field name
     * @param {AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppCustomDataLogsApi
     */
    public appCustomDataLogsControllerGetAllUniqueCustomFieldValues(requestParameters: AppCustomDataLogsApiAppCustomDataLogsControllerGetAllUniqueCustomFieldValuesRequest, options?: AxiosRequestConfig) {
        return AppCustomDataLogsApiFp(this.configuration).appCustomDataLogsControllerGetAllUniqueCustomFieldValues(requestParameters.source, requestParameters.customFieldName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Custom field names from web and csv
     * @param {AppCustomDataLogsApiAppCustomDataLogsControllerGetCustomFieldNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppCustomDataLogsApi
     */
    public appCustomDataLogsControllerGetCustomFieldNames(requestParameters: AppCustomDataLogsApiAppCustomDataLogsControllerGetCustomFieldNamesRequest = {}, options?: AxiosRequestConfig) {
        return AppCustomDataLogsApiFp(this.configuration).appCustomDataLogsControllerGetCustomFieldNames(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
