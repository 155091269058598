import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';

export const PlaybookRoutes: RouteRecordRaw = {
  path: '/playbooks',
  name: RouteName.Playbooks,
  redirect: { name: RouteName.PlaybookDisplayGroups },
  component: () => import('../views/Playbooks.vue'),
  meta: {
    title: 'Playbooks',
    group: RouteName.Playbooks,
    layout: Layout.Authenticated,
  },
  children: [
    {
      path: 'display-groups',
      name: RouteName.PlaybookDisplayGroups,
      component: () => import('@/views/Playbook/PlaybookDisplayGroups.vue'),
      meta: {
        title: 'Playbook Display Groups',
      },
    },
  ],
};
