import { useQuery } from '@tanstack/vue-query';
import { featureFlagQueryOptions } from './queries/featureFlagQueryOptions';
import { computed } from 'vue';

export const useFeatureFlag = (key: string) => {
  const { data: featureFlagKeys } = useQuery(featureFlagQueryOptions.findAllEnabledKeysForC1());

  const isEnabled = computed(() => {
    return featureFlagKeys.value?.includes(key) ?? false;
  });

  return {
    isEnabled,
  };
};
