/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CrmRenewalDealOwnerDto } from '../model/';
// @ts-ignore
import { CrmRenewalDealsFilterDto } from '../model/';
// @ts-ignore
import { RoleBasedPaginatedRenewalDealsDto } from '../model/';
// @ts-ignore
import { RoleBasedRenewalAggregationDto } from '../model/';
/**
 * CrmRenewalLogsApi - axios parameter creator
 * @export
 */
export const CrmRenewalLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Paginated Renewal Deals
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllRenewalDeals: async (crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmRenewalDealsFilterDto' is not null or undefined
            assertParamExists('crmRenewalLogsControllerFindAllRenewalDeals', 'crmRenewalDealsFilterDto', crmRenewalDealsFilterDto)
            const localVarPath = `/api/crm-renewal-logs/paginated-renewal-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmRenewalDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated Renewal Deals For C2
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllRenewalDealsForC2: async (crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmRenewalDealsFilterDto' is not null or undefined
            assertParamExists('crmRenewalLogsControllerFindAllRenewalDealsForC2', 'crmRenewalDealsFilterDto', crmRenewalDealsFilterDto)
            const localVarPath = `/api/crm-renewal-logs/paginated-renewal-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmRenewalDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllUniqueDealOwners: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crm-renewal-logs/deal-owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique deal statuses
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllUniqueDealStatuses: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crm-renewal-logs/deal-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique pipeline names
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllUniquePipelineNames: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crm-renewal-logs/pipeline-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renewal Aggregation Count
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindRenewalDealAggregationCount: async (crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmRenewalDealsFilterDto' is not null or undefined
            assertParamExists('crmRenewalLogsControllerFindRenewalDealAggregationCount', 'crmRenewalDealsFilterDto', crmRenewalDealsFilterDto)
            const localVarPath = `/api/crm-renewal-logs/renewal-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmRenewalDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renewal Aggregation Count For C2
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindRenewalDealAggregationCountForC2: async (crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmRenewalDealsFilterDto' is not null or undefined
            assertParamExists('crmRenewalLogsControllerFindRenewalDealAggregationCountForC2', 'crmRenewalDealsFilterDto', crmRenewalDealsFilterDto)
            const localVarPath = `/api/crm-renewal-logs/renewal-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmRenewalDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Date wise Renewal Deal History Logs
         * @param {string} id 
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerGetDateWiseRenewalDealHistory: async (id: string, crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('crmRenewalLogsControllerGetDateWiseRenewalDealHistory', 'id', id)
            // verify required parameter 'crmRenewalDealsFilterDto' is not null or undefined
            assertParamExists('crmRenewalLogsControllerGetDateWiseRenewalDealHistory', 'crmRenewalDealsFilterDto', crmRenewalDealsFilterDto)
            const localVarPath = `/api/crm-renewal-logs/{id}/date-wise-renewal-deal-history-logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crmRenewalDealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renewal Deal History Logs
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerGetRenewalDealHistory: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('crmRenewalLogsControllerGetRenewalDealHistory', 'id', id)
            const localVarPath = `/api/crm-renewal-logs/{id}/renewal-deal-history-logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrmRenewalLogsApi - functional programming interface
 * @export
 */
export const CrmRenewalLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrmRenewalLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Paginated Renewal Deals
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerFindAllRenewalDeals(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedRenewalDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerFindAllRenewalDeals(crmRenewalDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated Renewal Deals For C2
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerFindAllRenewalDealsForC2(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedRenewalDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerFindAllRenewalDealsForC2(crmRenewalDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerFindAllUniqueDealOwners(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrmRenewalDealOwnerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerFindAllUniqueDealOwners(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique deal statuses
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerFindAllUniqueDealStatuses(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerFindAllUniqueDealStatuses(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique pipeline names
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerFindAllUniquePipelineNames(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerFindAllUniquePipelineNames(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renewal Aggregation Count
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerFindRenewalDealAggregationCount(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerFindRenewalDealAggregationCount(crmRenewalDealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renewal Aggregation Count For C2
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerFindRenewalDealAggregationCountForC2(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerFindRenewalDealAggregationCountForC2(crmRenewalDealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Date wise Renewal Deal History Logs
         * @param {string} id 
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerGetDateWiseRenewalDealHistory(id: string, crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedRenewalDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerGetDateWiseRenewalDealHistory(id, crmRenewalDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renewal Deal History Logs
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmRenewalLogsControllerGetRenewalDealHistory(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedPaginatedRenewalDealsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmRenewalLogsControllerGetRenewalDealHistory(id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrmRenewalLogsApi - factory interface
 * @export
 */
export const CrmRenewalLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrmRenewalLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Paginated Renewal Deals
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllRenewalDeals(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedRenewalDealsDto> {
            return localVarFp.crmRenewalLogsControllerFindAllRenewalDeals(crmRenewalDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated Renewal Deals For C2
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllRenewalDealsForC2(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedRenewalDealsDto> {
            return localVarFp.crmRenewalLogsControllerFindAllRenewalDealsForC2(crmRenewalDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllUniqueDealOwners(xTenantId?: string, options?: any): AxiosPromise<Array<CrmRenewalDealOwnerDto>> {
            return localVarFp.crmRenewalLogsControllerFindAllUniqueDealOwners(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique deal statuses
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllUniqueDealStatuses(xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.crmRenewalLogsControllerFindAllUniqueDealStatuses(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique pipeline names
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindAllUniquePipelineNames(xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.crmRenewalLogsControllerFindAllUniquePipelineNames(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renewal Aggregation Count
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindRenewalDealAggregationCount(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedRenewalAggregationDto> {
            return localVarFp.crmRenewalLogsControllerFindRenewalDealAggregationCount(crmRenewalDealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renewal Aggregation Count For C2
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerFindRenewalDealAggregationCountForC2(crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedRenewalAggregationDto> {
            return localVarFp.crmRenewalLogsControllerFindRenewalDealAggregationCountForC2(crmRenewalDealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Date wise Renewal Deal History Logs
         * @param {string} id 
         * @param {CrmRenewalDealsFilterDto} crmRenewalDealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerGetDateWiseRenewalDealHistory(id: string, crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedRenewalDealsDto> {
            return localVarFp.crmRenewalLogsControllerGetDateWiseRenewalDealHistory(id, crmRenewalDealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renewal Deal History Logs
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmRenewalLogsControllerGetRenewalDealHistory(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedPaginatedRenewalDealsDto> {
            return localVarFp.crmRenewalLogsControllerGetRenewalDealHistory(id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for crmRenewalLogsControllerFindAllRenewalDeals operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsRequest
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsRequest {
    /**
     * 
     * @type {CrmRenewalDealsFilterDto}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDeals
     */
    readonly after?: string
}

/**
 * Request parameters for crmRenewalLogsControllerFindAllRenewalDealsForC2 operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2Request
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2Request {
    /**
     * 
     * @type {CrmRenewalDealsFilterDto}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for crmRenewalLogsControllerFindAllUniqueDealOwners operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealOwnersRequest
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealOwnersRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealOwners
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmRenewalLogsControllerFindAllUniqueDealStatuses operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealStatusesRequest
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealStatusesRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealStatuses
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmRenewalLogsControllerFindAllUniquePipelineNames operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniquePipelineNamesRequest
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniquePipelineNamesRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniquePipelineNames
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmRenewalLogsControllerFindRenewalDealAggregationCount operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountRequest
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountRequest {
    /**
     * 
     * @type {CrmRenewalDealsFilterDto}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCount
     */
    readonly crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmRenewalLogsControllerFindRenewalDealAggregationCountForC2 operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountForC2Request
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountForC2Request {
    /**
     * 
     * @type {CrmRenewalDealsFilterDto}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountForC2
     */
    readonly crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for crmRenewalLogsControllerGetDateWiseRenewalDealHistory operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistoryRequest
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly id: string

    /**
     * 
     * @type {CrmRenewalDealsFilterDto}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly crmRenewalDealsFilterDto: CrmRenewalDealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistory
     */
    readonly after?: string
}

/**
 * Request parameters for crmRenewalLogsControllerGetRenewalDealHistory operation in CrmRenewalLogsApi.
 * @export
 * @interface CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistoryRequest
 */
export interface CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistory
     */
    readonly after?: string
}

/**
 * CrmRenewalLogsApi - object-oriented interface
 * @export
 * @class CrmRenewalLogsApi
 * @extends {BaseAPI}
 */
export class CrmRenewalLogsApi extends BaseAPI {
    /**
     * 
     * @summary Paginated Renewal Deals
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerFindAllRenewalDeals(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsRequest, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerFindAllRenewalDeals(requestParameters.crmRenewalDealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated Renewal Deals For C2
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerFindAllRenewalDealsForC2(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerFindAllRenewalDealsForC2Request, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerFindAllRenewalDealsForC2(requestParameters.crmRenewalDealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique deal owners
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerFindAllUniqueDealOwners(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealOwnersRequest = {}, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerFindAllUniqueDealOwners(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique deal statuses
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerFindAllUniqueDealStatuses(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniqueDealStatusesRequest = {}, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerFindAllUniqueDealStatuses(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique pipeline names
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniquePipelineNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerFindAllUniquePipelineNames(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerFindAllUniquePipelineNamesRequest = {}, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerFindAllUniquePipelineNames(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renewal Aggregation Count
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerFindRenewalDealAggregationCount(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountRequest, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerFindRenewalDealAggregationCount(requestParameters.crmRenewalDealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renewal Aggregation Count For C2
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerFindRenewalDealAggregationCountForC2(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerFindRenewalDealAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerFindRenewalDealAggregationCountForC2(requestParameters.crmRenewalDealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Date wise Renewal Deal History Logs
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerGetDateWiseRenewalDealHistory(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerGetDateWiseRenewalDealHistoryRequest, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerGetDateWiseRenewalDealHistory(requestParameters.id, requestParameters.crmRenewalDealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renewal Deal History Logs
     * @param {CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmRenewalLogsApi
     */
    public crmRenewalLogsControllerGetRenewalDealHistory(requestParameters: CrmRenewalLogsApiCrmRenewalLogsControllerGetRenewalDealHistoryRequest, options?: AxiosRequestConfig) {
        return CrmRenewalLogsApiFp(this.configuration).crmRenewalLogsControllerGetRenewalDealHistory(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }
}
