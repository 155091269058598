/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Kpi } from '../model/';
// @ts-ignore
import { ToggleKpiDto } from '../model/';
// @ts-ignore
import { UpdateKpiDto } from '../model/';
/**
 * KPIApi - axios parameter creator
 * @export
 */
export const KPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List KPIs
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kpi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a KPI by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('kpiControllerFindOne', 'id', id)
            const localVarPath = `/api/kpi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft delete custom kpi
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerSoftDeleteCustomKpi: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('kpiControllerSoftDeleteCustomKpi', 'id', id)
            const localVarPath = `/api/kpi/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle on/off flag of a KPI and it\'s children
         * @param {string} id 
         * @param {ToggleKpiDto} toggleKpiDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerToggle: async (id: string, toggleKpiDto: ToggleKpiDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('kpiControllerToggle', 'id', id)
            // verify required parameter 'toggleKpiDto' is not null or undefined
            assertParamExists('kpiControllerToggle', 'toggleKpiDto', toggleKpiDto)
            const localVarPath = `/api/kpi/{id}/toggle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toggleKpiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a KPI
         * @param {string} id 
         * @param {UpdateKpiDto} updateKpiDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerUpdate: async (id: string, updateKpiDto: UpdateKpiDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('kpiControllerUpdate', 'id', id)
            // verify required parameter 'updateKpiDto' is not null or undefined
            assertParamExists('kpiControllerUpdate', 'updateKpiDto', updateKpiDto)
            const localVarPath = `/api/kpi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKpiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KPIApi - functional programming interface
 * @export
 */
export const KPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List KPIs
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Kpi>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a KPI by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Kpi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft delete custom kpi
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiControllerSoftDeleteCustomKpi(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiControllerSoftDeleteCustomKpi(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle on/off flag of a KPI and it\'s children
         * @param {string} id 
         * @param {ToggleKpiDto} toggleKpiDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiControllerToggle(id: string, toggleKpiDto: ToggleKpiDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Kpi>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiControllerToggle(id, toggleKpiDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a KPI
         * @param {string} id 
         * @param {UpdateKpiDto} updateKpiDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiControllerUpdate(id: string, updateKpiDto: UpdateKpiDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Kpi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiControllerUpdate(id, updateKpiDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KPIApi - factory interface
 * @export
 */
export const KPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KPIApiFp(configuration)
    return {
        /**
         * 
         * @summary List KPIs
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Kpi>> {
            return localVarFp.kpiControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a KPI by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Kpi> {
            return localVarFp.kpiControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft delete custom kpi
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerSoftDeleteCustomKpi(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.kpiControllerSoftDeleteCustomKpi(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle on/off flag of a KPI and it\'s children
         * @param {string} id 
         * @param {ToggleKpiDto} toggleKpiDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerToggle(id: string, toggleKpiDto: ToggleKpiDto, xTenantId?: string, options?: any): AxiosPromise<Array<Kpi>> {
            return localVarFp.kpiControllerToggle(id, toggleKpiDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a KPI
         * @param {string} id 
         * @param {UpdateKpiDto} updateKpiDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiControllerUpdate(id: string, updateKpiDto: UpdateKpiDto, xTenantId?: string, options?: any): AxiosPromise<Kpi> {
            return localVarFp.kpiControllerUpdate(id, updateKpiDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for kpiControllerFindAll operation in KPIApi.
 * @export
 * @interface KPIApiKpiControllerFindAllRequest
 */
export interface KPIApiKpiControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiControllerFindOne operation in KPIApi.
 * @export
 * @interface KPIApiKpiControllerFindOneRequest
 */
export interface KPIApiKpiControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiControllerSoftDeleteCustomKpi operation in KPIApi.
 * @export
 * @interface KPIApiKpiControllerSoftDeleteCustomKpiRequest
 */
export interface KPIApiKpiControllerSoftDeleteCustomKpiRequest {
    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerSoftDeleteCustomKpi
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerSoftDeleteCustomKpi
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiControllerToggle operation in KPIApi.
 * @export
 * @interface KPIApiKpiControllerToggleRequest
 */
export interface KPIApiKpiControllerToggleRequest {
    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerToggle
     */
    readonly id: string

    /**
     * 
     * @type {ToggleKpiDto}
     * @memberof KPIApiKpiControllerToggle
     */
    readonly toggleKpiDto: ToggleKpiDto

    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerToggle
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiControllerUpdate operation in KPIApi.
 * @export
 * @interface KPIApiKpiControllerUpdateRequest
 */
export interface KPIApiKpiControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateKpiDto}
     * @memberof KPIApiKpiControllerUpdate
     */
    readonly updateKpiDto: UpdateKpiDto

    /**
     * 
     * @type {string}
     * @memberof KPIApiKpiControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * KPIApi - object-oriented interface
 * @export
 * @class KPIApi
 * @extends {BaseAPI}
 */
export class KPIApi extends BaseAPI {
    /**
     * 
     * @summary List KPIs
     * @param {KPIApiKpiControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kpiControllerFindAll(requestParameters: KPIApiKpiControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return KPIApiFp(this.configuration).kpiControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a KPI by Id
     * @param {KPIApiKpiControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kpiControllerFindOne(requestParameters: KPIApiKpiControllerFindOneRequest, options?: AxiosRequestConfig) {
        return KPIApiFp(this.configuration).kpiControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft delete custom kpi
     * @param {KPIApiKpiControllerSoftDeleteCustomKpiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kpiControllerSoftDeleteCustomKpi(requestParameters: KPIApiKpiControllerSoftDeleteCustomKpiRequest, options?: AxiosRequestConfig) {
        return KPIApiFp(this.configuration).kpiControllerSoftDeleteCustomKpi(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle on/off flag of a KPI and it\'s children
     * @param {KPIApiKpiControllerToggleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kpiControllerToggle(requestParameters: KPIApiKpiControllerToggleRequest, options?: AxiosRequestConfig) {
        return KPIApiFp(this.configuration).kpiControllerToggle(requestParameters.id, requestParameters.toggleKpiDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a KPI
     * @param {KPIApiKpiControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kpiControllerUpdate(requestParameters: KPIApiKpiControllerUpdateRequest, options?: AxiosRequestConfig) {
        return KPIApiFp(this.configuration).kpiControllerUpdate(requestParameters.id, requestParameters.updateKpiDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
