/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedPeripheralTaskDto } from '../model/';
// @ts-ignore
import { PeripheralTask } from '../model/';
// @ts-ignore
import { PeripheralTaskFilterDto } from '../model/';
// @ts-ignore
import { UniquePeripheralTaskAppNameDto } from '../model/';
// @ts-ignore
import { UpdatePeripheralTaskDto } from '../model/';
/**
 * PeripheralTasksApi - axios parameter creator
 * @export
 */
export const PeripheralTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Filter peripheral tasks
         * @param {PeripheralTaskFilterDto} peripheralTaskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerFilter: async (peripheralTaskFilterDto: PeripheralTaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'peripheralTaskFilterDto' is not null or undefined
            assertParamExists('peripheralTasksControllerFilter', 'peripheralTaskFilterDto', peripheralTaskFilterDto)
            const localVarPath = `/api/peripheral-tasks/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peripheralTaskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Peripheral Task by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralTasksControllerFindOne', 'id', id)
            const localVarPath = `/api/peripheral-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all unique peripheral apps in peripheral tasks
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerGetAllUniquePeripheralAppNames: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/peripheral-tasks/all-unique-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Peripheral Task Info
         * @param {string} id 
         * @param {UpdatePeripheralTaskDto} updatePeripheralTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerUpdatePeripheralTaskInfo: async (id: string, updatePeripheralTaskDto: UpdatePeripheralTaskDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralTasksControllerUpdatePeripheralTaskInfo', 'id', id)
            // verify required parameter 'updatePeripheralTaskDto' is not null or undefined
            assertParamExists('peripheralTasksControllerUpdatePeripheralTaskInfo', 'updatePeripheralTaskDto', updatePeripheralTaskDto)
            const localVarPath = `/api/peripheral-tasks/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePeripheralTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeripheralTasksApi - functional programming interface
 * @export
 */
export const PeripheralTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeripheralTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Filter peripheral tasks
         * @param {PeripheralTaskFilterDto} peripheralTaskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralTasksControllerFilter(peripheralTaskFilterDto: PeripheralTaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPeripheralTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralTasksControllerFilter(peripheralTaskFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Peripheral Task by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralTasksControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralTasksControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all unique peripheral apps in peripheral tasks
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralTasksControllerGetAllUniquePeripheralAppNames(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniquePeripheralTaskAppNameDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralTasksControllerGetAllUniquePeripheralAppNames(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Peripheral Task Info
         * @param {string} id 
         * @param {UpdatePeripheralTaskDto} updatePeripheralTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralTasksControllerUpdatePeripheralTaskInfo(id: string, updatePeripheralTaskDto: UpdatePeripheralTaskDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralTasksControllerUpdatePeripheralTaskInfo(id, updatePeripheralTaskDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeripheralTasksApi - factory interface
 * @export
 */
export const PeripheralTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeripheralTasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Filter peripheral tasks
         * @param {PeripheralTaskFilterDto} peripheralTaskFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerFilter(peripheralTaskFilterDto: PeripheralTaskFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedPeripheralTaskDto> {
            return localVarFp.peripheralTasksControllerFilter(peripheralTaskFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Peripheral Task by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<PeripheralTask> {
            return localVarFp.peripheralTasksControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all unique peripheral apps in peripheral tasks
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerGetAllUniquePeripheralAppNames(xTenantId?: string, options?: any): AxiosPromise<Array<UniquePeripheralTaskAppNameDto>> {
            return localVarFp.peripheralTasksControllerGetAllUniquePeripheralAppNames(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Peripheral Task Info
         * @param {string} id 
         * @param {UpdatePeripheralTaskDto} updatePeripheralTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralTasksControllerUpdatePeripheralTaskInfo(id: string, updatePeripheralTaskDto: UpdatePeripheralTaskDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralTask> {
            return localVarFp.peripheralTasksControllerUpdatePeripheralTaskInfo(id, updatePeripheralTaskDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for peripheralTasksControllerFilter operation in PeripheralTasksApi.
 * @export
 * @interface PeripheralTasksApiPeripheralTasksControllerFilterRequest
 */
export interface PeripheralTasksApiPeripheralTasksControllerFilterRequest {
    /**
     * 
     * @type {PeripheralTaskFilterDto}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly peripheralTaskFilterDto: PeripheralTaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFilter
     */
    readonly after?: string
}

/**
 * Request parameters for peripheralTasksControllerFindOne operation in PeripheralTasksApi.
 * @export
 * @interface PeripheralTasksApiPeripheralTasksControllerFindOneRequest
 */
export interface PeripheralTasksApiPeripheralTasksControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralTasksControllerGetAllUniquePeripheralAppNames operation in PeripheralTasksApi.
 * @export
 * @interface PeripheralTasksApiPeripheralTasksControllerGetAllUniquePeripheralAppNamesRequest
 */
export interface PeripheralTasksApiPeripheralTasksControllerGetAllUniquePeripheralAppNamesRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerGetAllUniquePeripheralAppNames
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralTasksControllerUpdatePeripheralTaskInfo operation in PeripheralTasksApi.
 * @export
 * @interface PeripheralTasksApiPeripheralTasksControllerUpdatePeripheralTaskInfoRequest
 */
export interface PeripheralTasksApiPeripheralTasksControllerUpdatePeripheralTaskInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerUpdatePeripheralTaskInfo
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePeripheralTaskDto}
     * @memberof PeripheralTasksApiPeripheralTasksControllerUpdatePeripheralTaskInfo
     */
    readonly updatePeripheralTaskDto: UpdatePeripheralTaskDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralTasksApiPeripheralTasksControllerUpdatePeripheralTaskInfo
     */
    readonly xTenantId?: string
}

/**
 * PeripheralTasksApi - object-oriented interface
 * @export
 * @class PeripheralTasksApi
 * @extends {BaseAPI}
 */
export class PeripheralTasksApi extends BaseAPI {
    /**
     * 
     * @summary Filter peripheral tasks
     * @param {PeripheralTasksApiPeripheralTasksControllerFilterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralTasksApi
     */
    public peripheralTasksControllerFilter(requestParameters: PeripheralTasksApiPeripheralTasksControllerFilterRequest, options?: AxiosRequestConfig) {
        return PeripheralTasksApiFp(this.configuration).peripheralTasksControllerFilter(requestParameters.peripheralTaskFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Peripheral Task by ID
     * @param {PeripheralTasksApiPeripheralTasksControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralTasksApi
     */
    public peripheralTasksControllerFindOne(requestParameters: PeripheralTasksApiPeripheralTasksControllerFindOneRequest, options?: AxiosRequestConfig) {
        return PeripheralTasksApiFp(this.configuration).peripheralTasksControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all unique peripheral apps in peripheral tasks
     * @param {PeripheralTasksApiPeripheralTasksControllerGetAllUniquePeripheralAppNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralTasksApi
     */
    public peripheralTasksControllerGetAllUniquePeripheralAppNames(requestParameters: PeripheralTasksApiPeripheralTasksControllerGetAllUniquePeripheralAppNamesRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralTasksApiFp(this.configuration).peripheralTasksControllerGetAllUniquePeripheralAppNames(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Peripheral Task Info
     * @param {PeripheralTasksApiPeripheralTasksControllerUpdatePeripheralTaskInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralTasksApi
     */
    public peripheralTasksControllerUpdatePeripheralTaskInfo(requestParameters: PeripheralTasksApiPeripheralTasksControllerUpdatePeripheralTaskInfoRequest, options?: AxiosRequestConfig) {
        return PeripheralTasksApiFp(this.configuration).peripheralTasksControllerUpdatePeripheralTaskInfo(requestParameters.id, requestParameters.updatePeripheralTaskDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
