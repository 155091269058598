import { defineStore } from 'pinia';
import { ref } from 'vue';
import { U2Type } from '@/api/client';
import { u2TypeApi } from '@/api';

export const useU2TypeStore = defineStore('u2-type', () => {
  const isLoading = ref<boolean>(false);
  const u2Types = ref<U2Type[]>([]);

  async function fetchU2Types() {
    if (u2Types.value.length > 0) {
      return u2Types;
    }
    isLoading.value = true;
    try {
      const { data } = await u2TypeApi.u2TypesControllerFindAll();
      u2Types.value = data;
    } catch (e) {
      console.error('u2 Types', e);
    } finally {
      isLoading.value = false;
    }
  }

  function getU2TypeDisplayName(u2TypeId?: string) {
    if (!u2TypeId) {
      return '';
    }
    return u2Types.value.find((x) => x.id === u2TypeId)?.display_name || '';
  }

  return {
    isLoading,
    u2Types,
    fetchU2Types,
    getU2TypeDisplayName,
  };
});
