/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ZapscaleAppObject } from '../model/';
/**
 * ZapscaleAppObjectsApi - axios parameter creator
 * @export
 */
export const ZapscaleAppObjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Zapscale App Objects
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleAppObjectsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/zapscale-app-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZapscaleAppObjectsApi - functional programming interface
 * @export
 */
export const ZapscaleAppObjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZapscaleAppObjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Zapscale App Objects
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleAppObjectsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleAppObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleAppObjectsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZapscaleAppObjectsApi - factory interface
 * @export
 */
export const ZapscaleAppObjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZapscaleAppObjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Zapscale App Objects
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleAppObjectsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<ZapscaleAppObject>> {
            return localVarFp.zapscaleAppObjectsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for zapscaleAppObjectsControllerFindAll operation in ZapscaleAppObjectsApi.
 * @export
 * @interface ZapscaleAppObjectsApiZapscaleAppObjectsControllerFindAllRequest
 */
export interface ZapscaleAppObjectsApiZapscaleAppObjectsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleAppObjectsApiZapscaleAppObjectsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * ZapscaleAppObjectsApi - object-oriented interface
 * @export
 * @class ZapscaleAppObjectsApi
 * @extends {BaseAPI}
 */
export class ZapscaleAppObjectsApi extends BaseAPI {
    /**
     * 
     * @summary List of Zapscale App Objects
     * @param {ZapscaleAppObjectsApiZapscaleAppObjectsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleAppObjectsApi
     */
    public zapscaleAppObjectsControllerFindAll(requestParameters: ZapscaleAppObjectsApiZapscaleAppObjectsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return ZapscaleAppObjectsApiFp(this.configuration).zapscaleAppObjectsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
