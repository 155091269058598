import { createApp } from 'vue';
import { Quasar, Notify, Loading, Dialog } from 'quasar';
import iconSet from '@/icon-set/svg-phosphor-icons';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import App from './App.vue';
// import WebView from './components/Inbox/WebView.ce.vue';

import router from './router';
import '@/lib/chartjs-registrar';

import 'quasar/dist/quasar.css';
import 'prismjs/themes/prism.css';
import '@/css/app.css';

import { i18n } from './lib/i18n';
import { registerLayouts } from './layouts/register';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';

const pinia = createPinia();

const app = createApp(App);

const sentryDsn = import.meta.env.VITE_SENTRY_DSN as string;
const sentryTracesSampleRate = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE as string;
const sentryEnvironment = import.meta.env.VITE_SENTRY_ENVIRONMENT as string;

if (sentryDsn) {
  Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['dev.zapscale.com', 'stage.zapscale.com', 'app.zapscale.com', /^\//],
      }),
    ],
    tracesSampleRate: +sentryTracesSampleRate,
    logErrors: true,
    environment: sentryEnvironment,
  });
}

app
  .use(i18n)
  .use(pinia)
  .use(router)
  .use(VueQueryPlugin, {
    queryClient: new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
        },
      },
    }),
  })
  .use(Quasar, {
    iconSet: iconSet,
    plugins: { Dialog, Loading, Notify },
    config: {
      brand: {
        primary: '#006da3',
        'primary-lighter': '#E6F1F6',
        secondary: '#FF7F00',
        positive: '#47BE8A',
        negative: '#FB4E4E',
        warning: '#FFAA00',
      },
      notify: {
        position: 'top',
      }, // default set of options for Notify Quasar plugin
      loading: {
        boxClass:
          'tw-bg-light-shade-1 tw-text-dark-shade-1 tw-font-medium tw-max-w-none tw-w-screen tw-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-none',
        customClass: 'tw-pt-12',
        message: 'Loading ZapScale...',
        spinnerColor: 'primary',
        spinnerSize: 32,
      }, // default set of options for Loading Quasar plugin
    },
  });

registerLayouts(app);

// const WebViewElement = defineCustomElement(WebView);
// customElements.define('web-view', WebViewElement);

// declare module 'vue' {
//   export interface GlobalComponents {
//     WebViewElement: typeof WebViewElement;
//   }
// }

app.mount('#app');
