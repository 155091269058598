/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DynamicFieldConfigDto } from '../model/';
/**
 * DynamicFieldConfigApi - axios parameter creator
 * @export
 */
export const DynamicFieldConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] Optional field for tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicFieldConfigControllerFindAll: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dynamic-field-config/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name Mandatory field
         * @param {string} [id] Optional field for tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicFieldConfigControllerFindAllByName: async (name: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('dynamicFieldConfigControllerFindAllByName', 'name', name)
            const localVarPath = `/api/dynamic-field-config/by-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicFieldConfigApi - functional programming interface
 * @export
 */
export const DynamicFieldConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DynamicFieldConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] Optional field for tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicFieldConfigControllerFindAll(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DynamicFieldConfigDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicFieldConfigControllerFindAll(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name Mandatory field
         * @param {string} [id] Optional field for tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicFieldConfigControllerFindAllByName(name: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DynamicFieldConfigDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicFieldConfigControllerFindAllByName(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DynamicFieldConfigApi - factory interface
 * @export
 */
export const DynamicFieldConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DynamicFieldConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] Optional field for tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicFieldConfigControllerFindAll(id?: string, options?: any): AxiosPromise<Array<DynamicFieldConfigDto>> {
            return localVarFp.dynamicFieldConfigControllerFindAll(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name Mandatory field
         * @param {string} [id] Optional field for tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicFieldConfigControllerFindAllByName(name: string, id?: string, options?: any): AxiosPromise<Array<DynamicFieldConfigDto>> {
            return localVarFp.dynamicFieldConfigControllerFindAllByName(name, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dynamicFieldConfigControllerFindAll operation in DynamicFieldConfigApi.
 * @export
 * @interface DynamicFieldConfigApiDynamicFieldConfigControllerFindAllRequest
 */
export interface DynamicFieldConfigApiDynamicFieldConfigControllerFindAllRequest {
    /**
     * Optional field for tenant id
     * @type {string}
     * @memberof DynamicFieldConfigApiDynamicFieldConfigControllerFindAll
     */
    readonly id?: string
}

/**
 * Request parameters for dynamicFieldConfigControllerFindAllByName operation in DynamicFieldConfigApi.
 * @export
 * @interface DynamicFieldConfigApiDynamicFieldConfigControllerFindAllByNameRequest
 */
export interface DynamicFieldConfigApiDynamicFieldConfigControllerFindAllByNameRequest {
    /**
     * Mandatory field
     * @type {string}
     * @memberof DynamicFieldConfigApiDynamicFieldConfigControllerFindAllByName
     */
    readonly name: string

    /**
     * Optional field for tenant id
     * @type {string}
     * @memberof DynamicFieldConfigApiDynamicFieldConfigControllerFindAllByName
     */
    readonly id?: string
}

/**
 * DynamicFieldConfigApi - object-oriented interface
 * @export
 * @class DynamicFieldConfigApi
 * @extends {BaseAPI}
 */
export class DynamicFieldConfigApi extends BaseAPI {
    /**
     * 
     * @param {DynamicFieldConfigApiDynamicFieldConfigControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicFieldConfigApi
     */
    public dynamicFieldConfigControllerFindAll(requestParameters: DynamicFieldConfigApiDynamicFieldConfigControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return DynamicFieldConfigApiFp(this.configuration).dynamicFieldConfigControllerFindAll(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicFieldConfigApiDynamicFieldConfigControllerFindAllByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicFieldConfigApi
     */
    public dynamicFieldConfigControllerFindAllByName(requestParameters: DynamicFieldConfigApiDynamicFieldConfigControllerFindAllByNameRequest, options?: AxiosRequestConfig) {
        return DynamicFieldConfigApiFp(this.configuration).dynamicFieldConfigControllerFindAllByName(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
