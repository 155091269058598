/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CopyKpiConditionDto } from '../model/';
// @ts-ignore
import { CreateKpiConditionDto } from '../model/';
// @ts-ignore
import { KpiCondition } from '../model/';
// @ts-ignore
import { UpdateKpiConditionDto } from '../model/';
// @ts-ignore
import { UpdateKpiConditionFlagDto } from '../model/';
/**
 * KpiConditionApi - axios parameter creator
 * @export
 */
export const KpiConditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy one kpi condition to another using parent kpi id
         * @param {string} parentKpiId 
         * @param {CopyKpiConditionDto} copyKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerCopyKpiCondition: async (parentKpiId: string, copyKpiConditionDto: CopyKpiConditionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentKpiId' is not null or undefined
            assertParamExists('kpiConditionControllerCopyKpiCondition', 'parentKpiId', parentKpiId)
            // verify required parameter 'copyKpiConditionDto' is not null or undefined
            assertParamExists('kpiConditionControllerCopyKpiCondition', 'copyKpiConditionDto', copyKpiConditionDto)
            const localVarPath = `/api/kpi-condition/{parentKpiId}/copy`
                .replace(`{${"parentKpiId"}}`, encodeURIComponent(String(parentKpiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyKpiConditionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create kpi condition
         * @param {CreateKpiConditionDto} createKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerCreateKpiCondition: async (createKpiConditionDto: CreateKpiConditionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createKpiConditionDto' is not null or undefined
            assertParamExists('kpiConditionControllerCreateKpiCondition', 'createKpiConditionDto', createKpiConditionDto)
            const localVarPath = `/api/kpi-condition/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createKpiConditionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all kpi conditions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kpi-condition/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find child kpi conditions using parent condition id
         * @param {string} parentConditionId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerFindAllChildConditions: async (parentConditionId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentConditionId' is not null or undefined
            assertParamExists('kpiConditionControllerFindAllChildConditions', 'parentConditionId', parentConditionId)
            const localVarPath = `/api/kpi-condition/{parentConditionId}/by-parent-condition-id`
                .replace(`{${"parentConditionId"}}`, encodeURIComponent(String(parentConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find kpi condition using parent kpi id
         * @param {string} parentKpiId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerFindOne: async (parentKpiId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentKpiId' is not null or undefined
            assertParamExists('kpiConditionControllerFindOne', 'parentKpiId', parentKpiId)
            const localVarPath = `/api/kpi-condition/{parentKpiId}/by-parent-kpi-id`
                .replace(`{${"parentKpiId"}}`, encodeURIComponent(String(parentKpiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update child kpi condition using id
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateKpiCondition: async (id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateKpiCondition', 'id', id)
            // verify required parameter 'updateKpiConditionDto' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateKpiCondition', 'updateKpiConditionDto', updateKpiConditionDto)
            const localVarPath = `/api/kpi-condition/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKpiConditionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update kpi condition on_off_flag
         * @param {string} parentKpiId 
         * @param {UpdateKpiConditionFlagDto} updateKpiConditionFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateOnOffFlag: async (parentKpiId: string, updateKpiConditionFlagDto: UpdateKpiConditionFlagDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentKpiId' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateOnOffFlag', 'parentKpiId', parentKpiId)
            // verify required parameter 'updateKpiConditionFlagDto' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateOnOffFlag', 'updateKpiConditionFlagDto', updateKpiConditionFlagDto)
            const localVarPath = `/api/kpi-condition/update-on-off/{parentKpiId}`
                .replace(`{${"parentKpiId"}}`, encodeURIComponent(String(parentKpiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKpiConditionFlagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update kpi condition using id, along with child conditions using parent condition id
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateWithChildren: async (id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateWithChildren', 'id', id)
            // verify required parameter 'updateKpiConditionDto' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateWithChildren', 'updateKpiConditionDto', updateKpiConditionDto)
            const localVarPath = `/api/kpi-condition/update-with-children/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKpiConditionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update kpi condition using id, and remove all child conditions
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateWithoutChildren: async (id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateWithoutChildren', 'id', id)
            // verify required parameter 'updateKpiConditionDto' is not null or undefined
            assertParamExists('kpiConditionControllerUpdateWithoutChildren', 'updateKpiConditionDto', updateKpiConditionDto)
            const localVarPath = `/api/kpi-condition/update-without-children/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKpiConditionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KpiConditionApi - functional programming interface
 * @export
 */
export const KpiConditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KpiConditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy one kpi condition to another using parent kpi id
         * @param {string} parentKpiId 
         * @param {CopyKpiConditionDto} copyKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerCopyKpiCondition(parentKpiId: string, copyKpiConditionDto: CopyKpiConditionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerCopyKpiCondition(parentKpiId, copyKpiConditionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create kpi condition
         * @param {CreateKpiConditionDto} createKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerCreateKpiCondition(createKpiConditionDto: CreateKpiConditionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerCreateKpiCondition(createKpiConditionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all kpi conditions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find child kpi conditions using parent condition id
         * @param {string} parentConditionId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerFindAllChildConditions(parentConditionId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerFindAllChildConditions(parentConditionId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find kpi condition using parent kpi id
         * @param {string} parentKpiId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerFindOne(parentKpiId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerFindOne(parentKpiId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update child kpi condition using id
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerUpdateKpiCondition(id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerUpdateKpiCondition(id, updateKpiConditionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update kpi condition on_off_flag
         * @param {string} parentKpiId 
         * @param {UpdateKpiConditionFlagDto} updateKpiConditionFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerUpdateOnOffFlag(parentKpiId: string, updateKpiConditionFlagDto: UpdateKpiConditionFlagDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerUpdateOnOffFlag(parentKpiId, updateKpiConditionFlagDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update kpi condition using id, along with child conditions using parent condition id
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerUpdateWithChildren(id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerUpdateWithChildren(id, updateKpiConditionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update kpi condition using id, and remove all child conditions
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiConditionControllerUpdateWithoutChildren(id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiConditionControllerUpdateWithoutChildren(id, updateKpiConditionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KpiConditionApi - factory interface
 * @export
 */
export const KpiConditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KpiConditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy one kpi condition to another using parent kpi id
         * @param {string} parentKpiId 
         * @param {CopyKpiConditionDto} copyKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerCopyKpiCondition(parentKpiId: string, copyKpiConditionDto: CopyKpiConditionDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.kpiConditionControllerCopyKpiCondition(parentKpiId, copyKpiConditionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create kpi condition
         * @param {CreateKpiConditionDto} createKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerCreateKpiCondition(createKpiConditionDto: CreateKpiConditionDto, xTenantId?: string, options?: any): AxiosPromise<KpiCondition> {
            return localVarFp.kpiConditionControllerCreateKpiCondition(createKpiConditionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all kpi conditions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<KpiCondition>> {
            return localVarFp.kpiConditionControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find child kpi conditions using parent condition id
         * @param {string} parentConditionId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerFindAllChildConditions(parentConditionId: string, xTenantId?: string, options?: any): AxiosPromise<Array<KpiCondition>> {
            return localVarFp.kpiConditionControllerFindAllChildConditions(parentConditionId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find kpi condition using parent kpi id
         * @param {string} parentKpiId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerFindOne(parentKpiId: string, xTenantId?: string, options?: any): AxiosPromise<KpiCondition> {
            return localVarFp.kpiConditionControllerFindOne(parentKpiId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update child kpi condition using id
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateKpiCondition(id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options?: any): AxiosPromise<KpiCondition> {
            return localVarFp.kpiConditionControllerUpdateKpiCondition(id, updateKpiConditionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update kpi condition on_off_flag
         * @param {string} parentKpiId 
         * @param {UpdateKpiConditionFlagDto} updateKpiConditionFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateOnOffFlag(parentKpiId: string, updateKpiConditionFlagDto: UpdateKpiConditionFlagDto, xTenantId?: string, options?: any): AxiosPromise<KpiCondition> {
            return localVarFp.kpiConditionControllerUpdateOnOffFlag(parentKpiId, updateKpiConditionFlagDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update kpi condition using id, along with child conditions using parent condition id
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateWithChildren(id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options?: any): AxiosPromise<KpiCondition> {
            return localVarFp.kpiConditionControllerUpdateWithChildren(id, updateKpiConditionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update kpi condition using id, and remove all child conditions
         * @param {string} id 
         * @param {UpdateKpiConditionDto} updateKpiConditionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiConditionControllerUpdateWithoutChildren(id: string, updateKpiConditionDto: UpdateKpiConditionDto, xTenantId?: string, options?: any): AxiosPromise<KpiCondition> {
            return localVarFp.kpiConditionControllerUpdateWithoutChildren(id, updateKpiConditionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for kpiConditionControllerCopyKpiCondition operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerCopyKpiConditionRequest
 */
export interface KpiConditionApiKpiConditionControllerCopyKpiConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerCopyKpiCondition
     */
    readonly parentKpiId: string

    /**
     * 
     * @type {CopyKpiConditionDto}
     * @memberof KpiConditionApiKpiConditionControllerCopyKpiCondition
     */
    readonly copyKpiConditionDto: CopyKpiConditionDto

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerCopyKpiCondition
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerCreateKpiCondition operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerCreateKpiConditionRequest
 */
export interface KpiConditionApiKpiConditionControllerCreateKpiConditionRequest {
    /**
     * 
     * @type {CreateKpiConditionDto}
     * @memberof KpiConditionApiKpiConditionControllerCreateKpiCondition
     */
    readonly createKpiConditionDto: CreateKpiConditionDto

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerCreateKpiCondition
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerFindAll operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerFindAllRequest
 */
export interface KpiConditionApiKpiConditionControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerFindAllChildConditions operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerFindAllChildConditionsRequest
 */
export interface KpiConditionApiKpiConditionControllerFindAllChildConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerFindAllChildConditions
     */
    readonly parentConditionId: string

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerFindAllChildConditions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerFindOne operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerFindOneRequest
 */
export interface KpiConditionApiKpiConditionControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerFindOne
     */
    readonly parentKpiId: string

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerUpdateKpiCondition operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerUpdateKpiConditionRequest
 */
export interface KpiConditionApiKpiConditionControllerUpdateKpiConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateKpiCondition
     */
    readonly id: string

    /**
     * 
     * @type {UpdateKpiConditionDto}
     * @memberof KpiConditionApiKpiConditionControllerUpdateKpiCondition
     */
    readonly updateKpiConditionDto: UpdateKpiConditionDto

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateKpiCondition
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerUpdateOnOffFlag operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerUpdateOnOffFlagRequest
 */
export interface KpiConditionApiKpiConditionControllerUpdateOnOffFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateOnOffFlag
     */
    readonly parentKpiId: string

    /**
     * 
     * @type {UpdateKpiConditionFlagDto}
     * @memberof KpiConditionApiKpiConditionControllerUpdateOnOffFlag
     */
    readonly updateKpiConditionFlagDto: UpdateKpiConditionFlagDto

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateOnOffFlag
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerUpdateWithChildren operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerUpdateWithChildrenRequest
 */
export interface KpiConditionApiKpiConditionControllerUpdateWithChildrenRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateWithChildren
     */
    readonly id: string

    /**
     * 
     * @type {UpdateKpiConditionDto}
     * @memberof KpiConditionApiKpiConditionControllerUpdateWithChildren
     */
    readonly updateKpiConditionDto: UpdateKpiConditionDto

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateWithChildren
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for kpiConditionControllerUpdateWithoutChildren operation in KpiConditionApi.
 * @export
 * @interface KpiConditionApiKpiConditionControllerUpdateWithoutChildrenRequest
 */
export interface KpiConditionApiKpiConditionControllerUpdateWithoutChildrenRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateWithoutChildren
     */
    readonly id: string

    /**
     * 
     * @type {UpdateKpiConditionDto}
     * @memberof KpiConditionApiKpiConditionControllerUpdateWithoutChildren
     */
    readonly updateKpiConditionDto: UpdateKpiConditionDto

    /**
     * 
     * @type {string}
     * @memberof KpiConditionApiKpiConditionControllerUpdateWithoutChildren
     */
    readonly xTenantId?: string
}

/**
 * KpiConditionApi - object-oriented interface
 * @export
 * @class KpiConditionApi
 * @extends {BaseAPI}
 */
export class KpiConditionApi extends BaseAPI {
    /**
     * 
     * @summary Copy one kpi condition to another using parent kpi id
     * @param {KpiConditionApiKpiConditionControllerCopyKpiConditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerCopyKpiCondition(requestParameters: KpiConditionApiKpiConditionControllerCopyKpiConditionRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerCopyKpiCondition(requestParameters.parentKpiId, requestParameters.copyKpiConditionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create kpi condition
     * @param {KpiConditionApiKpiConditionControllerCreateKpiConditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerCreateKpiCondition(requestParameters: KpiConditionApiKpiConditionControllerCreateKpiConditionRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerCreateKpiCondition(requestParameters.createKpiConditionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all kpi conditions
     * @param {KpiConditionApiKpiConditionControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerFindAll(requestParameters: KpiConditionApiKpiConditionControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find child kpi conditions using parent condition id
     * @param {KpiConditionApiKpiConditionControllerFindAllChildConditionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerFindAllChildConditions(requestParameters: KpiConditionApiKpiConditionControllerFindAllChildConditionsRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerFindAllChildConditions(requestParameters.parentConditionId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find kpi condition using parent kpi id
     * @param {KpiConditionApiKpiConditionControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerFindOne(requestParameters: KpiConditionApiKpiConditionControllerFindOneRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerFindOne(requestParameters.parentKpiId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update child kpi condition using id
     * @param {KpiConditionApiKpiConditionControllerUpdateKpiConditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerUpdateKpiCondition(requestParameters: KpiConditionApiKpiConditionControllerUpdateKpiConditionRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerUpdateKpiCondition(requestParameters.id, requestParameters.updateKpiConditionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update kpi condition on_off_flag
     * @param {KpiConditionApiKpiConditionControllerUpdateOnOffFlagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerUpdateOnOffFlag(requestParameters: KpiConditionApiKpiConditionControllerUpdateOnOffFlagRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerUpdateOnOffFlag(requestParameters.parentKpiId, requestParameters.updateKpiConditionFlagDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update kpi condition using id, along with child conditions using parent condition id
     * @param {KpiConditionApiKpiConditionControllerUpdateWithChildrenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerUpdateWithChildren(requestParameters: KpiConditionApiKpiConditionControllerUpdateWithChildrenRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerUpdateWithChildren(requestParameters.id, requestParameters.updateKpiConditionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update kpi condition using id, and remove all child conditions
     * @param {KpiConditionApiKpiConditionControllerUpdateWithoutChildrenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiConditionApi
     */
    public kpiConditionControllerUpdateWithoutChildren(requestParameters: KpiConditionApiKpiConditionControllerUpdateWithoutChildrenRequest, options?: AxiosRequestConfig) {
        return KpiConditionApiFp(this.configuration).kpiConditionControllerUpdateWithoutChildren(requestParameters.id, requestParameters.updateKpiConditionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
