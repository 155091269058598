/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePasswordDto } from '../model/';
// @ts-ignore
import { ResetPasswordDto } from '../model/';
/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePasswordDto} createPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordControllerCreate: async (createPasswordDto: CreatePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPasswordDto' is not null or undefined
            assertParamExists('passwordControllerCreate', 'createPasswordDto', createPasswordDto)
            const localVarPath = `/api/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordControllerUpdate: async (token: string, resetPasswordDto: ResetPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('passwordControllerUpdate', 'token', token)
            // verify required parameter 'resetPasswordDto' is not null or undefined
            assertParamExists('passwordControllerUpdate', 'resetPasswordDto', resetPasswordDto)
            const localVarPath = `/api/password/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePasswordDto} createPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordControllerCreate(createPasswordDto: CreatePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordControllerCreate(createPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordControllerUpdate(token: string, resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordControllerUpdate(token, resetPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePasswordDto} createPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordControllerCreate(createPasswordDto: CreatePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.passwordControllerCreate(createPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordControllerUpdate(token: string, resetPasswordDto: ResetPasswordDto, options?: any): AxiosPromise<object> {
            return localVarFp.passwordControllerUpdate(token, resetPasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for passwordControllerCreate operation in PasswordApi.
 * @export
 * @interface PasswordApiPasswordControllerCreateRequest
 */
export interface PasswordApiPasswordControllerCreateRequest {
    /**
     * 
     * @type {CreatePasswordDto}
     * @memberof PasswordApiPasswordControllerCreate
     */
    readonly createPasswordDto: CreatePasswordDto
}

/**
 * Request parameters for passwordControllerUpdate operation in PasswordApi.
 * @export
 * @interface PasswordApiPasswordControllerUpdateRequest
 */
export interface PasswordApiPasswordControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordApiPasswordControllerUpdate
     */
    readonly token: string

    /**
     * 
     * @type {ResetPasswordDto}
     * @memberof PasswordApiPasswordControllerUpdate
     */
    readonly resetPasswordDto: ResetPasswordDto
}

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * 
     * @param {PasswordApiPasswordControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public passwordControllerCreate(requestParameters: PasswordApiPasswordControllerCreateRequest, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).passwordControllerCreate(requestParameters.createPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordApiPasswordControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public passwordControllerUpdate(requestParameters: PasswordApiPasswordControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).passwordControllerUpdate(requestParameters.token, requestParameters.resetPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}
