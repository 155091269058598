/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CampaignDto } from '../model/';
// @ts-ignore
import { CampaignSchema } from '../model/';
// @ts-ignore
import { CopyCampaignDto } from '../model/';
// @ts-ignore
import { StatusCampaignDto } from '../model/';
/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy current campaign 
         * @param {CopyCampaignDto} copyCampaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerCopy: async (copyCampaignDto: CopyCampaignDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyCampaignDto' is not null or undefined
            assertParamExists('campaignControllerCopy', 'copyCampaignDto', copyCampaignDto)
            const localVarPath = `/api/campaign/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyCampaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Campaign
         * @param {string} name 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerCreate: async (name: string, campaignDto: CampaignDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('campaignControllerCreate', 'name', name)
            // verify required parameter 'campaignDto' is not null or undefined
            assertParamExists('campaignControllerCreate', 'campaignDto', campaignDto)
            const localVarPath = `/api/campaign/create/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find campaign by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignControllerFindOne', 'id', id)
            const localVarPath = `/api/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Campaign by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignControllerRemove', 'id', id)
            const localVarPath = `/api/campaign/delete-campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Campaign by ID
         * @param {string} id 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerUpdate: async (id: string, campaignDto: CampaignDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignControllerUpdate', 'id', id)
            // verify required parameter 'campaignDto' is not null or undefined
            assertParamExists('campaignControllerUpdate', 'campaignDto', campaignDto)
            const localVarPath = `/api/campaign/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Campaign status by id
         * @param {string} id 
         * @param {StatusCampaignDto} statusCampaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerUpdateStatus: async (id: string, statusCampaignDto: StatusCampaignDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignControllerUpdateStatus', 'id', id)
            // verify required parameter 'statusCampaignDto' is not null or undefined
            assertParamExists('campaignControllerUpdateStatus', 'statusCampaignDto', statusCampaignDto)
            const localVarPath = `/api/campaign/campaign-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusCampaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Campaign by ID version 2
         * @param {string} id 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerUpdateV2: async (id: string, campaignDto: CampaignDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignControllerUpdateV2', 'id', id)
            // verify required parameter 'campaignDto' is not null or undefined
            assertParamExists('campaignControllerUpdateV2', 'campaignDto', campaignDto)
            const localVarPath = `/api/v2/campaign/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy current campaign 
         * @param {CopyCampaignDto} copyCampaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerCopy(copyCampaignDto: CopyCampaignDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerCopy(copyCampaignDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Campaign
         * @param {string} name 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerCreate(name: string, campaignDto: CampaignDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerCreate(name, campaignDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find campaign by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Campaign by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Campaign by ID
         * @param {string} id 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerUpdate(id: string, campaignDto: CampaignDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerUpdate(id, campaignDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Campaign status by id
         * @param {string} id 
         * @param {StatusCampaignDto} statusCampaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerUpdateStatus(id: string, statusCampaignDto: StatusCampaignDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerUpdateStatus(id, statusCampaignDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Campaign by ID version 2
         * @param {string} id 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignControllerUpdateV2(id: string, campaignDto: CampaignDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignControllerUpdateV2(id, campaignDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy current campaign 
         * @param {CopyCampaignDto} copyCampaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerCopy(copyCampaignDto: CopyCampaignDto, xTenantId?: string, options?: any): AxiosPromise<Array<CampaignSchema>> {
            return localVarFp.campaignControllerCopy(copyCampaignDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Campaign
         * @param {string} name 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerCreate(name: string, campaignDto: CampaignDto, xTenantId?: string, options?: any): AxiosPromise<Array<CampaignSchema>> {
            return localVarFp.campaignControllerCreate(name, campaignDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of campaigns
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<CampaignSchema>> {
            return localVarFp.campaignControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find campaign by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<CampaignSchema> {
            return localVarFp.campaignControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Campaign by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<CampaignSchema> {
            return localVarFp.campaignControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Campaign by ID
         * @param {string} id 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerUpdate(id: string, campaignDto: CampaignDto, xTenantId?: string, options?: any): AxiosPromise<CampaignSchema> {
            return localVarFp.campaignControllerUpdate(id, campaignDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Campaign status by id
         * @param {string} id 
         * @param {StatusCampaignDto} statusCampaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerUpdateStatus(id: string, statusCampaignDto: StatusCampaignDto, xTenantId?: string, options?: any): AxiosPromise<CampaignSchema> {
            return localVarFp.campaignControllerUpdateStatus(id, statusCampaignDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Campaign by ID version 2
         * @param {string} id 
         * @param {CampaignDto} campaignDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignControllerUpdateV2(id: string, campaignDto: CampaignDto, xTenantId?: string, options?: any): AxiosPromise<CampaignSchema> {
            return localVarFp.campaignControllerUpdateV2(id, campaignDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for campaignControllerCopy operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerCopyRequest
 */
export interface CampaignApiCampaignControllerCopyRequest {
    /**
     * 
     * @type {CopyCampaignDto}
     * @memberof CampaignApiCampaignControllerCopy
     */
    readonly copyCampaignDto: CopyCampaignDto

    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerCopy
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignControllerCreate operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerCreateRequest
 */
export interface CampaignApiCampaignControllerCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerCreate
     */
    readonly name: string

    /**
     * 
     * @type {CampaignDto}
     * @memberof CampaignApiCampaignControllerCreate
     */
    readonly campaignDto: CampaignDto

    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignControllerFindAll operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerFindAllRequest
 */
export interface CampaignApiCampaignControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignControllerFindOne operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerFindOneRequest
 */
export interface CampaignApiCampaignControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignControllerRemove operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerRemoveRequest
 */
export interface CampaignApiCampaignControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignControllerUpdate operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerUpdateRequest
 */
export interface CampaignApiCampaignControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {CampaignDto}
     * @memberof CampaignApiCampaignControllerUpdate
     */
    readonly campaignDto: CampaignDto

    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignControllerUpdateStatus operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerUpdateStatusRequest
 */
export interface CampaignApiCampaignControllerUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerUpdateStatus
     */
    readonly id: string

    /**
     * 
     * @type {StatusCampaignDto}
     * @memberof CampaignApiCampaignControllerUpdateStatus
     */
    readonly statusCampaignDto: StatusCampaignDto

    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerUpdateStatus
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignControllerUpdateV2 operation in CampaignApi.
 * @export
 * @interface CampaignApiCampaignControllerUpdateV2Request
 */
export interface CampaignApiCampaignControllerUpdateV2Request {
    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerUpdateV2
     */
    readonly id: string

    /**
     * 
     * @type {CampaignDto}
     * @memberof CampaignApiCampaignControllerUpdateV2
     */
    readonly campaignDto: CampaignDto

    /**
     * 
     * @type {string}
     * @memberof CampaignApiCampaignControllerUpdateV2
     */
    readonly xTenantId?: string
}

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Copy current campaign 
     * @param {CampaignApiCampaignControllerCopyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerCopy(requestParameters: CampaignApiCampaignControllerCopyRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerCopy(requestParameters.copyCampaignDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Campaign
     * @param {CampaignApiCampaignControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerCreate(requestParameters: CampaignApiCampaignControllerCreateRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerCreate(requestParameters.name, requestParameters.campaignDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of campaigns
     * @param {CampaignApiCampaignControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerFindAll(requestParameters: CampaignApiCampaignControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find campaign by id
     * @param {CampaignApiCampaignControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerFindOne(requestParameters: CampaignApiCampaignControllerFindOneRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Campaign by Id
     * @param {CampaignApiCampaignControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerRemove(requestParameters: CampaignApiCampaignControllerRemoveRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Campaign by ID
     * @param {CampaignApiCampaignControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerUpdate(requestParameters: CampaignApiCampaignControllerUpdateRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerUpdate(requestParameters.id, requestParameters.campaignDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Campaign status by id
     * @param {CampaignApiCampaignControllerUpdateStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerUpdateStatus(requestParameters: CampaignApiCampaignControllerUpdateStatusRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerUpdateStatus(requestParameters.id, requestParameters.statusCampaignDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Campaign by ID version 2
     * @param {CampaignApiCampaignControllerUpdateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignControllerUpdateV2(requestParameters: CampaignApiCampaignControllerUpdateV2Request, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignControllerUpdateV2(requestParameters.id, requestParameters.campaignDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
