import { C1HealthScore, DashboardOverviewDto } from '@/api/client';
import { D3ChartOptions, ICustomerUserHealthData } from '@/types/dashboard';
import { useAmountFormatter } from './useAmountFormatter';

export const useCustomerUserHealthDataSet = () => {
  const { formatNumberRoundOfDecimal, formatAmount } = useAmountFormatter();
  const filterOptions = [
    {
      label: 'All User',
      value: 'all_user',
    },
    {
      label: 'Decision Makers',
      value: 'decision_maker',
    },
    {
      label: 'Influencers',
      value: 'influencer',
    },
    {
      label: 'Normal Users',
      value: 'normal_user',
    },
  ];

  const defaultDataSet = [
    {
      group: 'good',
    },
    {
      group: 'fair',
    },
    {
      group: 'bad',
    },
    {
      group: 'inactive',
    },
    {
      group: 'churn',
    },
  ] as ICustomerUserHealthData[];

  function prepareChartV2(dashboardDto: DashboardOverviewDto, filter: D3ChartOptions) {
    defaultDataSet.forEach((x) => {
      if (!dashboardDto.total_acv) {
        let goodPercentage = dashboardDto?.percent_c2s_in_green || 0;
        let fairPercentage = dashboardDto?.percent_c2s_in_yellow || 0;
        let badPercentage = dashboardDto?.percent_c2s_in_red || 0;
        let inactivePercentage = dashboardDto?.percent_c2s_in_grey || 0;
        let churnPercentage = dashboardDto?.percent_c2s_in_black || 0;
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        if (x.group === 'good') {
          x.percentage = goodPercentage;
          x.value = `(${dashboardDto.total_c2s_in_green?.toString()})`;
          x.users = getGoodUsersV2(dashboardDto, filter);
        } else if (x.group === 'fair') {
          x.percentage = fairPercentage;
          x.value = `(${dashboardDto.total_c2s_in_yellow?.toString()})`;
          x.users = getFairUsersV2(dashboardDto, filter);
        } else if (x.group === 'bad') {
          x.percentage = badPercentage;
          x.value = `(${dashboardDto.total_c2s_in_red?.toString()})`;
          x.users = getBadUsersV2(dashboardDto, filter);
        } else if (x.group === 'inactive') {
          x.percentage = inactivePercentage;
          x.value = `(${dashboardDto.total_c2s_in_grey?.toString()})`;
          x.users = getInactiveUsersV2(dashboardDto, filter);
        } else {
          x.percentage = churnPercentage;
          x.value = `(${dashboardDto.total_c2s_in_black?.toString()})`;
          x.users = getChurnUsersV2(dashboardDto, filter);
        }
      } else {
        if (x.group === 'good') {
          x.percentage = +formatNumberRoundOfDecimal(dashboardDto.percent_of_green_c2_acv);
          x.value = formatAmount(dashboardDto.green_c2_acv);
          x.users = getGoodUsersV2(dashboardDto, filter);
        } else if (x.group === 'fair') {
          x.percentage = +formatNumberRoundOfDecimal(dashboardDto.percent_of_yellow_c2_acv);
          x.value = formatAmount(dashboardDto.yellow_c2_acv);
          x.users = getFairUsersV2(dashboardDto, filter);
        } else if (x.group === 'bad') {
          x.percentage = +formatNumberRoundOfDecimal(dashboardDto.percent_of_red_c2_acv);
          x.value = formatAmount(dashboardDto.red_c2_acv);
          x.users = getBadUsersV2(dashboardDto, filter);
        } else if (x.group === 'inactive') {
          x.percentage = +formatNumberRoundOfDecimal(dashboardDto.percent_of_grey_c2_acv);
          x.value = formatAmount(dashboardDto.grey_c2_acv);
          x.users = getInactiveUsersV2(dashboardDto, filter);
        } else {
          x.percentage = +formatNumberRoundOfDecimal(dashboardDto.percent_of_black_c2_acv);
          x.value = formatAmount(dashboardDto.black_c2_acv);
          x.users = getChurnUsersV2(dashboardDto, filter);
        }
      }
    });
    return defaultDataSet;
  }

  function getGoodUsersV2(healthScore: DashboardOverviewDto, filter: D3ChartOptions) {
    switch (filter) {
      case D3ChartOptions.all:
        return [
          {
            group: 'good',
            percentage: healthScore?.green_c2_percent_green_u2s || 0,
            value: healthScore?.green_c2_no_of_green_u2s?.toString() || '',
            count: healthScore?.green_c2_no_of_green_u2s || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.green_c2_percent_yellow_u2s || 0,
            value: healthScore?.green_c2_no_of_yellow_u2s?.toString() || '',
            count: healthScore?.green_c2_no_of_yellow_u2s || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.green_c2_percent_red_u2s || 0,
            value: healthScore?.green_c2_no_of_red_u2s?.toString() || '',
            count: healthScore?.green_c2_no_of_red_u2s || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.green_c2_percent_grey_u2s || 0,
            value: healthScore?.green_c2_no_of_grey_u2s?.toString() || '',
            count: healthScore?.green_c2_no_of_grey_u2s || 0,
          },
        ];
      case D3ChartOptions.decision_maker:
        return [
          {
            group: 'good',
            percentage: healthScore?.green_c2_percent_green_decision_makers || 0,
            value: healthScore?.green_c2_no_of_green_decision_makers?.toString() || '',
            count: healthScore?.green_c2_no_of_green_decision_makers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.green_c2_percent_yellow_decision_makers || 0,
            value: healthScore?.green_c2_no_of_yellow_decision_makers?.toString() || '',
            count: healthScore?.green_c2_no_of_yellow_decision_makers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.green_c2_percent_red_decision_makers || 0,
            value: healthScore?.green_c2_no_of_red_decision_makers?.toString() || '',
            count: healthScore?.green_c2_no_of_red_decision_makers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.green_c2_percent_grey_decision_makers || 0,
            value: healthScore?.green_c2_no_of_grey_decision_makers?.toString() || '',
            count: healthScore?.green_c2_no_of_grey_decision_makers || 0,
          },
        ];
      case D3ChartOptions.influencer:
        return [
          {
            group: 'good',
            percentage: healthScore?.green_c2_percent_green_influencers || 0,
            value: healthScore?.green_c2_no_of_green_influencers?.toString() || '',
            count: healthScore?.green_c2_no_of_green_influencers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.green_c2_percent_yellow_influencers || 0,
            value: healthScore?.green_c2_no_of_yellow_influencers?.toString() || '',
            count: healthScore?.green_c2_no_of_yellow_influencers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.green_c2_percent_red_influencers || 0,
            value: healthScore?.green_c2_no_of_red_influencers?.toString() || '',
            count: healthScore?.green_c2_no_of_red_influencers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.green_c2_percent_grey_influencers || 0,
            value: healthScore?.green_c2_no_of_grey_influencers?.toString() || '',
            count: healthScore?.green_c2_no_of_grey_influencers || 0,
          },
        ];
      case D3ChartOptions.normal_user:
        return [
          {
            group: 'good',
            percentage: healthScore?.green_c2_percent_green_normal_users || 0,
            value: healthScore?.green_c2_no_of_green_normal_users?.toString() || '',
            count: healthScore?.green_c2_no_of_green_normal_users || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.green_c2_percent_yellow_normal_users || 0,
            value: healthScore?.green_c2_no_of_yellow_normal_users?.toString() || '',
            count: healthScore?.green_c2_no_of_yellow_normal_users || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.green_c2_percent_red_normal_users || 0,
            value: healthScore?.green_c2_no_of_red_normal_users?.toString() || '',
            count: healthScore?.green_c2_no_of_red_normal_users || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.green_c2_percent_grey_normal_users || 0,
            value: healthScore?.green_c2_no_of_grey_normal_users?.toString() || '',
            count: healthScore?.green_c2_no_of_grey_normal_users || 0,
          },
        ];
    }
  }

  function getFairUsersV2(healthScore: DashboardOverviewDto, filter: D3ChartOptions) {
    switch (filter) {
      case D3ChartOptions.all:
        return [
          {
            group: 'good',
            percentage: healthScore?.yellow_c2_percent_green_u2s || 0,
            value: healthScore?.yellow_c2_no_of_green_u2s?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_green_u2s || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.yellow_c2_percent_yellow_u2s || 0,
            value: healthScore?.yellow_c2_no_of_yellow_u2s?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_yellow_u2s || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.yellow_c2_percent_red_u2s || 0,
            value: healthScore?.yellow_c2_no_of_red_u2s?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_red_u2s || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.yellow_c2_percent_grey_u2s || 0,
            value: healthScore?.yellow_c2_no_of_grey_u2s?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_grey_u2s || 0,
          },
        ];
      case D3ChartOptions.decision_maker:
        return [
          {
            group: 'good',
            percentage: healthScore?.yellow_c2_percent_green_decision_makers || 0,
            value: healthScore?.yellow_c2_no_of_green_decision_makers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_green_decision_makers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.yellow_c2_percent_yellow_decision_makers || 0,
            value: healthScore?.yellow_c2_no_of_yellow_decision_makers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_yellow_decision_makers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.yellow_c2_percent_red_decision_makers || 0,
            value: healthScore?.yellow_c2_no_of_red_decision_makers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_red_decision_makers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.yellow_c2_percent_grey_decision_makers || 0,
            value: healthScore?.yellow_c2_no_of_grey_decision_makers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_grey_decision_makers || 0,
          },
        ];
      case D3ChartOptions.influencer:
        return [
          {
            group: 'good',
            percentage: healthScore?.yellow_c2_percent_green_influencers || 0,
            value: healthScore?.yellow_c2_no_of_green_influencers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_green_influencers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.yellow_c2_percent_yellow_influencers || 0,
            value: healthScore?.yellow_c2_no_of_yellow_influencers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_yellow_influencers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.yellow_c2_percent_red_influencers || 0,
            value: healthScore?.yellow_c2_no_of_red_influencers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_red_influencers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.yellow_c2_percent_grey_influencers || 0,
            value: healthScore?.yellow_c2_no_of_grey_influencers?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_grey_influencers || 0,
          },
        ];
      case D3ChartOptions.normal_user:
        return [
          {
            group: 'good',
            percentage: healthScore?.yellow_c2_percent_green_normal_users || 0,
            value: healthScore?.yellow_c2_no_of_green_normal_users?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_green_normal_users || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.yellow_c2_percent_yellow_normal_users || 0,
            value: healthScore?.yellow_c2_no_of_yellow_normal_users?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_yellow_normal_users || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.yellow_c2_percent_red_normal_users || 0,
            value: healthScore?.yellow_c2_no_of_red_normal_users?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_red_normal_users || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.yellow_c2_percent_grey_normal_users || 0,
            value: healthScore?.yellow_c2_no_of_grey_normal_users?.toString() || '0',
            count: healthScore?.yellow_c2_no_of_grey_normal_users || 0,
          },
        ];
    }
  }

  function getBadUsersV2(healthScore: DashboardOverviewDto, filter: D3ChartOptions) {
    switch (filter) {
      case D3ChartOptions.all:
        return [
          {
            group: 'good',
            percentage: healthScore?.red_c2_percent_green_u2s || 0,
            value: healthScore?.red_c2_no_of_green_u2s?.toString() || '0',
            count: healthScore?.red_c2_no_of_green_u2s || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.red_c2_percent_yellow_u2s || 0,
            value: healthScore?.red_c2_no_of_yellow_u2s?.toString() || '0',
            count: healthScore?.red_c2_no_of_yellow_u2s || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.red_c2_percent_red_u2s || 0,
            value: healthScore?.red_c2_no_of_red_u2s?.toString() || '0',
            count: healthScore?.red_c2_no_of_red_u2s || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.red_c2_percent_grey_u2s || 0,
            value: healthScore?.red_c2_no_of_grey_u2s?.toString() || '0',
            count: healthScore?.red_c2_no_of_grey_u2s || 0,
          },
        ];
      case D3ChartOptions.decision_maker:
        return [
          {
            group: 'good',
            percentage: healthScore?.red_c2_percent_green_decision_makers || 0,
            value: healthScore?.red_c2_no_of_green_decision_makers?.toString() || '0',
            count: healthScore?.red_c2_no_of_green_decision_makers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.red_c2_percent_yellow_decision_makers || 0,
            value: healthScore?.red_c2_no_of_yellow_decision_makers?.toString() || '0',
            count: healthScore?.red_c2_no_of_yellow_decision_makers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.red_c2_percent_red_decision_makers || 0,
            value: healthScore?.red_c2_no_of_red_decision_makers?.toString() || '0',
            count: healthScore?.red_c2_no_of_red_decision_makers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.red_c2_percent_grey_decision_makers || 0,
            value: healthScore?.red_c2_no_of_grey_decision_makers?.toString() || '0',
            count: healthScore?.red_c2_no_of_grey_decision_makers || 0,
          },
        ];
      case D3ChartOptions.influencer:
        return [
          {
            group: 'good',
            percentage: healthScore?.red_c2_percent_green_influencers || 0,
            value: healthScore?.red_c2_no_of_green_influencers?.toString() || '0',
            count: healthScore?.red_c2_no_of_green_influencers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.red_c2_percent_yellow_influencers || 0,
            value: healthScore?.red_c2_no_of_yellow_influencers?.toString() || '0',
            count: healthScore?.red_c2_no_of_yellow_influencers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.red_c2_percent_red_influencers || 0,
            value: healthScore?.red_c2_no_of_red_influencers?.toString() || '0',
            count: healthScore?.red_c2_no_of_red_influencers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.red_c2_percent_grey_influencers || 0,
            value: healthScore?.red_c2_no_of_grey_influencers?.toString() || '0',
            count: healthScore?.red_c2_no_of_grey_influencers || 0,
          },
        ];
      case D3ChartOptions.normal_user:
        return [
          {
            group: 'good',
            percentage: healthScore?.red_c2_percent_green_normal_users || 0,
            value: healthScore?.red_c2_no_of_green_normal_users?.toString() || '0',
            count: healthScore?.red_c2_no_of_green_normal_users || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.red_c2_percent_yellow_normal_users || 0,
            value: healthScore?.red_c2_no_of_yellow_normal_users?.toString() || '0',
            count: healthScore?.red_c2_no_of_yellow_normal_users || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.red_c2_percent_red_normal_users || 0,
            value: healthScore?.red_c2_no_of_red_normal_users?.toString() || '0',
            count: healthScore?.red_c2_no_of_red_normal_users || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.red_c2_percent_grey_normal_users || 0,
            value: healthScore?.red_c2_no_of_grey_normal_users?.toString() || '0',
            count: healthScore?.red_c2_no_of_grey_normal_users || 0,
          },
        ];
    }
  }

  function getInactiveUsersV2(healthScore: DashboardOverviewDto, filter: D3ChartOptions) {
    switch (filter) {
      case D3ChartOptions.all:
        return [
          {
            group: 'good',
            percentage: healthScore?.grey_c2_percent_green_u2s || 0,
            value: healthScore?.grey_c2_no_of_green_u2s?.toString() || '0',
            count: healthScore?.grey_c2_no_of_green_u2s || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.grey_c2_percent_yellow_u2s || 0,
            value: healthScore?.grey_c2_no_of_yellow_u2s?.toString() || '0',
            count: healthScore?.grey_c2_no_of_yellow_u2s || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.grey_c2_percent_red_u2s || 0,
            value: healthScore?.grey_c2_no_of_red_u2s?.toString() || '0',
            count: healthScore?.grey_c2_no_of_red_u2s || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.grey_c2_percent_grey_u2s || 0,
            value: healthScore?.grey_c2_no_of_grey_u2s?.toString() || '0',
            count: healthScore?.grey_c2_no_of_grey_u2s || 0,
          },
        ];
      case D3ChartOptions.decision_maker:
        return [
          {
            group: 'good',
            percentage: healthScore?.grey_c2_percent_green_decision_makers || 0,
            value: healthScore?.grey_c2_no_of_green_decision_makers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_green_decision_makers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.grey_c2_percent_yellow_decision_makers || 0,
            value: healthScore?.grey_c2_no_of_yellow_decision_makers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_yellow_decision_makers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.grey_c2_percent_red_decision_makers || 0,
            value: healthScore?.grey_c2_no_of_red_decision_makers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_red_decision_makers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.grey_c2_percent_grey_decision_makers || 0,
            value: healthScore?.grey_c2_no_of_grey_decision_makers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_grey_decision_makers || 0,
          },
        ];
      case D3ChartOptions.influencer:
        return [
          {
            group: 'good',
            percentage: healthScore?.grey_c2_percent_green_influencers || 0,
            value: healthScore?.grey_c2_no_of_green_influencers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_green_influencers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.grey_c2_percent_yellow_influencers || 0,
            value: healthScore?.grey_c2_no_of_yellow_influencers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_yellow_influencers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.grey_c2_percent_red_influencers || 0,
            value: healthScore?.grey_c2_no_of_red_influencers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_red_influencers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.grey_c2_percent_grey_influencers || 0,
            value: healthScore?.grey_c2_no_of_grey_influencers?.toString() || '0',
            count: healthScore?.grey_c2_no_of_grey_influencers || 0,
          },
        ];
      case D3ChartOptions.normal_user:
        return [
          {
            group: 'good',
            percentage: healthScore?.grey_c2_percent_green_normal_users || 0,
            value: healthScore?.grey_c2_no_of_green_normal_users?.toString() || '0',
            count: healthScore?.grey_c2_no_of_green_normal_users || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.grey_c2_percent_yellow_normal_users || 0,
            value: healthScore?.grey_c2_no_of_yellow_normal_users?.toString() || '0',
            count: healthScore?.grey_c2_no_of_yellow_normal_users || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.grey_c2_percent_red_normal_users || 0,
            value: healthScore?.grey_c2_no_of_red_normal_users?.toString() || '0',
            count: healthScore?.grey_c2_no_of_red_normal_users || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.grey_c2_percent_grey_normal_users || 0,
            value: healthScore?.grey_c2_no_of_grey_normal_users?.toString() || '0',
            count: healthScore?.grey_c2_no_of_grey_normal_users || 0,
          },
        ];
    }
  }

  function getChurnUsersV2(healthScore: DashboardOverviewDto, filter: D3ChartOptions) {
    switch (filter) {
      case D3ChartOptions.all:
        return [
          {
            group: 'good',
            percentage: healthScore?.black_c2_percent_green_u2s || 0,
            value: healthScore?.black_c2_no_of_green_u2s?.toString() || '0',
            count: healthScore?.black_c2_no_of_green_u2s || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.black_c2_percent_yellow_u2s || 0,
            value: healthScore?.black_c2_no_of_yellow_u2s?.toString() || '0',
            count: healthScore?.black_c2_no_of_yellow_u2s || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.black_c2_percent_red_u2s || 0,
            value: healthScore?.black_c2_no_of_red_u2s?.toString() || '0',
            count: healthScore?.black_c2_no_of_red_u2s || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.black_c2_percent_grey_u2s || 0,
            value: healthScore?.black_c2_no_of_grey_u2s?.toString() || '0',
            count: healthScore?.black_c2_no_of_grey_u2s || 0,
          },
        ];
      case D3ChartOptions.decision_maker:
        return [
          {
            group: 'good',
            percentage: healthScore?.black_c2_percent_green_decision_makers || 0,
            value: healthScore?.black_c2_no_of_green_decision_makers?.toString() || '0',
            count: healthScore?.black_c2_no_of_green_decision_makers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.black_c2_percent_yellow_decision_makers || 0,
            value: healthScore?.black_c2_no_of_yellow_decision_makers?.toString() || '0',
            count: healthScore?.black_c2_no_of_yellow_decision_makers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.black_c2_percent_red_decision_makers || 0,
            value: healthScore?.black_c2_no_of_red_decision_makers?.toString() || '0',
            count: healthScore?.black_c2_no_of_red_decision_makers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.black_c2_percent_grey_decision_makers || 0,
            value: healthScore?.black_c2_no_of_grey_decision_makers?.toString() || '0',
            count: healthScore?.black_c2_no_of_grey_decision_makers || 0,
          },
        ];
      case D3ChartOptions.influencer:
        return [
          {
            group: 'good',
            percentage: healthScore?.black_c2_percent_green_influencers || 0,
            value: healthScore?.black_c2_no_of_green_influencers?.toString() || '0',
            count: healthScore?.black_c2_no_of_green_influencers || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.black_c2_percent_yellow_influencers || 0,
            value: healthScore?.black_c2_no_of_yellow_influencers?.toString() || '0',
            count: healthScore?.black_c2_no_of_yellow_influencers || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.black_c2_percent_red_influencers || 0,
            value: healthScore?.black_c2_no_of_red_influencers?.toString() || '0',
            count: healthScore?.black_c2_no_of_red_influencers || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.black_c2_percent_grey_influencers || 0,
            value: healthScore?.black_c2_no_of_grey_influencers?.toString() || '0',
            count: healthScore?.black_c2_no_of_grey_influencers || 0,
          },
        ];
      case D3ChartOptions.normal_user:
        return [
          {
            group: 'good',
            percentage: healthScore?.black_c2_percent_green_normal_users || 0,
            value: healthScore?.black_c2_no_of_green_normal_users?.toString() || '0',
            count: healthScore?.black_c2_no_of_green_normal_users || 0,
          },
          {
            group: 'fair',
            percentage: healthScore?.black_c2_percent_yellow_normal_users || 0,
            value: healthScore?.black_c2_no_of_yellow_normal_users?.toString() || '0',
            count: healthScore?.black_c2_no_of_yellow_normal_users || 0,
          },
          {
            group: 'bad',
            percentage: healthScore?.black_c2_percent_red_normal_users || 0,
            value: healthScore?.black_c2_no_of_red_normal_users?.toString() || '0',
            count: healthScore?.black_c2_no_of_red_normal_users || 0,
          },
          {
            group: 'inactive',
            percentage: healthScore?.black_c2_percent_grey_normal_users || 0,
            value: healthScore?.black_c2_no_of_grey_normal_users?.toString() || '0',
            count: healthScore?.black_c2_no_of_grey_normal_users || 0,
          },
        ];
    }
  }

  function prepareChart(c1HealthScore: C1HealthScore, filter: string) {
    defaultDataSet.forEach((x) => {
      if (!c1HealthScore.total_acv_of_all_c2s) {
        let goodPercentage = c1HealthScore.percent_c2s_in_green;
        let fairPercentage = c1HealthScore.percent_c2s_in_yellow;
        let badPercentage = c1HealthScore.percent_c2s_in_red;
        let inactivePercentage = c1HealthScore.percent_c2s_in_grey;
        let churnPercentage = c1HealthScore.percent_c2s_in_black;
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        if (x.group === 'good') {
          x.percentage = goodPercentage;
          x.value = `(${c1HealthScore.total_c2s_in_green?.toString()})`;
          x.users = getGoodUsers(c1HealthScore, filter);
        } else if (x.group === 'fair') {
          x.percentage = fairPercentage;
          x.value = `(${c1HealthScore.total_c2s_in_yellow?.toString()})`;
          x.users = getFairUsers(c1HealthScore, filter);
        } else if (x.group === 'bad') {
          x.percentage = badPercentage;
          x.value = `(${c1HealthScore.total_c2s_in_red?.toString()})`;
          x.users = getBadUsers(c1HealthScore, filter);
        } else if (x.group === 'inactive') {
          x.percentage = inactivePercentage;
          x.value = `(${c1HealthScore.total_c2s_in_grey?.toString()})`;
          x.users = getInactiveUsers(c1HealthScore, filter);
        } else {
          x.percentage = churnPercentage;
          x.value = `(${c1HealthScore.total_c2s_in_black?.toString()})`;
          x.users = getChurnUsers(c1HealthScore, filter);
        }
      } else {
        if (x.group === 'good') {
          x.percentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_of_green_c2_acv);
          x.value = formatAmount(c1HealthScore.green_c2_acv);
          x.users = getGoodUsers(c1HealthScore, filter);
        } else if (x.group === 'fair') {
          x.percentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_of_yellow_c2_acv);
          x.value = formatAmount(c1HealthScore.yellow_c2_acv);
          x.users = getFairUsers(c1HealthScore, filter);
        } else if (x.group === 'bad') {
          x.percentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_of_red_c2_acv);
          x.value = formatAmount(c1HealthScore.red_c2_acv);
          x.users = getBadUsers(c1HealthScore, filter);
        } else if (x.group === 'inactive') {
          x.percentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_of_grey_c2_acv);
          x.value = formatAmount(c1HealthScore.grey_c2_acv);
          x.users = getInactiveUsers(c1HealthScore, filter);
        } else {
          x.percentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_of_black_c2_acv);
          x.value = formatAmount(c1HealthScore.black_c2_acv);
          x.users = getChurnUsers(c1HealthScore, filter);
        }
      }
    });
    return defaultDataSet;
  }

  function getChurnUsers(c1HealthScore: C1HealthScore, filter: string) {
    if (filter === filterOptions[0].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.black_c2_percent_green_u2s,
          value: c1HealthScore?.black_c2_no_of_green_u2s?.toString(),
          count: c1HealthScore?.black_c2_no_of_green_u2s || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.black_c2_percent_yellow_u2s,
          value: c1HealthScore?.black_c2_no_of_yellow_u2s?.toString(),
          count: c1HealthScore?.black_c2_no_of_yellow_u2s || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.black_c2_percent_red_u2s,
          value: c1HealthScore?.black_c2_no_of_red_u2s?.toString(),
          count: c1HealthScore?.black_c2_no_of_red_u2s || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.black_c2_percent_grey_u2s,
          value: c1HealthScore?.black_c2_no_of_grey_u2s?.toString(),
          count: c1HealthScore?.black_c2_no_of_grey_u2s || 0,
        },
      ];
    } else if (filter == filterOptions[1].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.black_c2_percent_green_decision_makers || 0,
          value: c1HealthScore?.black_c2_no_of_green_decision_makers?.toString(),
          count: c1HealthScore?.black_c2_no_of_green_decision_makers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.black_c2_percent_yellow_decision_makers || 0,
          value: c1HealthScore?.black_c2_no_of_yellow_decision_makers?.toString(),
          count: c1HealthScore?.black_c2_no_of_yellow_decision_makers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.black_c2_percent_red_decision_makers,
          value: c1HealthScore?.black_c2_no_of_red_decision_makers?.toString(),
          count: c1HealthScore?.black_c2_no_of_red_decision_makers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.black_c2_percent_grey_decision_makers || 0,
          value: c1HealthScore?.black_c2_no_of_grey_decision_makers?.toString(),
          count: c1HealthScore?.black_c2_no_of_grey_decision_makers || 0,
        },
      ];
    } else if (filter == filterOptions[2].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.black_c2_percent_green_influencers || 0,
          value: c1HealthScore?.black_c2_no_of_green_influencers?.toString(),
          count: c1HealthScore?.black_c2_no_of_green_influencers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.black_c2_percent_yellow_influencers || 0,
          value: c1HealthScore?.black_c2_no_of_yellow_influencers?.toString(),
          count: c1HealthScore?.black_c2_no_of_yellow_influencers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.black_c2_percent_red_influencers || 0,
          value: c1HealthScore?.black_c2_no_of_red_influencers?.toString(),
          count: c1HealthScore?.black_c2_no_of_red_influencers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.black_c2_percent_grey_influencers || 0,
          value: c1HealthScore?.black_c2_no_of_grey_influencers?.toString(),
          count: c1HealthScore?.black_c2_no_of_grey_influencers || 0,
        },
      ];
    } else {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.black_c2_percent_green_normal_users || 0,
          value: c1HealthScore?.black_c2_no_of_green_normal_users?.toString(),
          count: c1HealthScore?.black_c2_no_of_green_normal_users || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.black_c2_percent_yellow_normal_users || 0,
          value: c1HealthScore?.black_c2_no_of_yellow_normal_users?.toString(),
          count: c1HealthScore?.black_c2_no_of_yellow_normal_users || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.black_c2_percent_red_normal_users || 0,
          value: c1HealthScore?.black_c2_no_of_red_normal_users?.toString(),
          count: c1HealthScore?.black_c2_no_of_red_normal_users || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.black_c2_percent_grey_normal_users || 0,
          value: c1HealthScore?.black_c2_no_of_grey_normal_users?.toString(),
          count: c1HealthScore?.black_c2_no_of_grey_normal_users || 0,
        },
      ];
    }
  }

  function getInactiveUsers(c1HealthScore: C1HealthScore, filter: string) {
    if (filter === filterOptions[0].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.grey_c2_percent_green_u2s || 0,
          value: c1HealthScore?.grey_c2_no_of_green_u2s?.toString(),
          count: c1HealthScore?.grey_c2_no_of_green_u2s || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.grey_c2_percent_yellow_u2s || 0,
          value: c1HealthScore?.grey_c2_no_of_yellow_u2s?.toString(),
          count: c1HealthScore?.grey_c2_no_of_yellow_u2s || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.grey_c2_percent_red_u2s || 0,
          value: c1HealthScore?.grey_c2_no_of_red_u2s?.toString(),
          count: c1HealthScore?.grey_c2_no_of_red_u2s || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.grey_c2_percent_grey_u2s || 0,
          value: c1HealthScore?.grey_c2_no_of_grey_u2s?.toString(),
          count: c1HealthScore?.grey_c2_no_of_grey_u2s || 0,
        },
      ];
    } else if (filter == filterOptions[1].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.grey_c2_percent_green_decision_makers || 0,
          value: c1HealthScore?.grey_c2_no_of_green_decision_makers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_green_decision_makers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.grey_c2_percent_yellow_decision_makers || 0,
          value: c1HealthScore?.grey_c2_no_of_yellow_decision_makers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_yellow_decision_makers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.grey_c2_percent_red_decision_makers || 0,
          value: c1HealthScore?.grey_c2_no_of_red_decision_makers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_red_decision_makers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.grey_c2_percent_grey_decision_makers || 0,
          value: c1HealthScore?.grey_c2_no_of_grey_decision_makers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_grey_decision_makers || 0,
        },
      ];
    } else if (filter == filterOptions[2].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.grey_c2_percent_green_influencers || 0,
          value: c1HealthScore?.grey_c2_no_of_green_influencers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_green_influencers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.grey_c2_percent_yellow_influencers || 0,
          value: c1HealthScore?.grey_c2_no_of_yellow_influencers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_yellow_influencers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.grey_c2_percent_red_influencers || 0,
          value: c1HealthScore?.grey_c2_no_of_red_influencers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_red_influencers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.grey_c2_percent_grey_influencers || 0,
          value: c1HealthScore?.grey_c2_no_of_grey_influencers?.toString(),
          count: c1HealthScore?.grey_c2_no_of_grey_influencers || 0,
        },
      ];
    } else {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.grey_c2_percent_green_normal_users || 0,
          value: c1HealthScore?.grey_c2_no_of_green_normal_users?.toString(),
          count: c1HealthScore?.grey_c2_no_of_green_normal_users || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.grey_c2_percent_yellow_normal_users || 0,
          value: c1HealthScore?.grey_c2_no_of_yellow_normal_users?.toString(),
          count: c1HealthScore?.grey_c2_no_of_yellow_normal_users || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.grey_c2_percent_red_normal_users || 0,
          value: c1HealthScore?.grey_c2_no_of_red_normal_users?.toString(),
          count: c1HealthScore?.grey_c2_no_of_red_normal_users || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.grey_c2_percent_grey_normal_users || 0,
          value: c1HealthScore?.grey_c2_no_of_grey_normal_users?.toString(),
          count: c1HealthScore?.grey_c2_no_of_grey_normal_users || 0,
        },
      ];
    }
  }

  function getBadUsers(c1HealthScore: C1HealthScore, filter: string) {
    if (filter === filterOptions[0].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.red_c2_percent_green_u2s || 0,
          value: c1HealthScore?.red_c2_no_of_green_u2s?.toString(),
          count: c1HealthScore?.red_c2_no_of_green_u2s || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.red_c2_percent_yellow_u2s || 0,
          value: c1HealthScore?.red_c2_no_of_yellow_u2s?.toString(),
          count: c1HealthScore?.red_c2_no_of_yellow_u2s || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.red_c2_percent_red_u2s || 0,
          value: c1HealthScore?.red_c2_no_of_red_u2s?.toString(),
          count: c1HealthScore?.red_c2_no_of_red_u2s || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.red_c2_percent_grey_u2s || 0,
          value: c1HealthScore?.red_c2_no_of_grey_u2s?.toString(),
          count: c1HealthScore?.red_c2_no_of_grey_u2s || 0,
        },
      ];
    } else if (filter == filterOptions[1].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.red_c2_percent_green_decision_makers || 0,
          value: c1HealthScore?.red_c2_no_of_green_decision_makers?.toString(),
          count: c1HealthScore?.red_c2_no_of_green_decision_makers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.red_c2_percent_yellow_decision_makers || 0,
          value: c1HealthScore?.red_c2_no_of_yellow_decision_makers?.toString(),
          count: c1HealthScore?.red_c2_no_of_yellow_decision_makers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.red_c2_percent_red_decision_makers || 0,
          value: c1HealthScore?.red_c2_no_of_red_decision_makers?.toString(),
          count: c1HealthScore?.red_c2_no_of_red_decision_makers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.red_c2_percent_grey_decision_makers || 0,
          value: c1HealthScore?.red_c2_no_of_grey_decision_makers?.toString(),
          count: c1HealthScore?.red_c2_no_of_grey_decision_makers || 0,
        },
      ];
    } else if (filter == filterOptions[2].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.red_c2_percent_green_influencers || 0,
          value: c1HealthScore?.red_c2_no_of_green_influencers?.toString(),
          count: c1HealthScore?.red_c2_no_of_green_influencers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.red_c2_percent_yellow_influencers || 0,
          value: c1HealthScore?.red_c2_no_of_yellow_influencers?.toString(),
          count: c1HealthScore?.red_c2_no_of_yellow_influencers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.red_c2_percent_red_influencers || 0,
          value: c1HealthScore?.red_c2_no_of_red_influencers?.toString(),
          count: c1HealthScore?.red_c2_no_of_red_influencers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.red_c2_percent_grey_influencers || 0,
          value: c1HealthScore?.red_c2_no_of_grey_influencers?.toString(),
          count: c1HealthScore?.red_c2_no_of_grey_influencers || 0,
        },
      ];
    } else {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.red_c2_percent_green_normal_users || 0,
          value: c1HealthScore?.red_c2_no_of_green_normal_users?.toString(),
          count: c1HealthScore?.red_c2_no_of_green_normal_users || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.red_c2_percent_yellow_normal_users || 0,
          value: c1HealthScore?.red_c2_no_of_yellow_normal_users?.toString(),
          count: c1HealthScore?.red_c2_no_of_yellow_normal_users || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.red_c2_percent_red_normal_users || 0,
          value: c1HealthScore?.red_c2_no_of_red_normal_users?.toString(),
          count: c1HealthScore?.red_c2_no_of_red_normal_users || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.red_c2_percent_grey_normal_users || 0,
          value: c1HealthScore?.red_c2_no_of_grey_normal_users?.toString(),
          count: c1HealthScore?.red_c2_no_of_grey_normal_users || 0,
        },
      ];
    }
  }

  function getFairUsers(c1HealthScore: C1HealthScore, filter: string) {
    if (filter === filterOptions[0].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.yellow_c2_percent_green_u2s || 0,
          value: c1HealthScore?.yellow_c2_no_of_green_u2s?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_green_u2s || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.yellow_c2_percent_yellow_u2s || 0,
          value: c1HealthScore?.yellow_c2_no_of_yellow_u2s?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_yellow_u2s || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.yellow_c2_percent_red_u2s || 0,
          value: c1HealthScore?.yellow_c2_no_of_red_u2s?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_red_u2s || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.yellow_c2_percent_grey_u2s || 0,
          value: c1HealthScore?.yellow_c2_no_of_grey_u2s?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_grey_u2s || 0,
        },
      ];
    } else if (filter == filterOptions[1].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.yellow_c2_percent_green_decision_makers || 0,
          value: c1HealthScore?.yellow_c2_no_of_green_decision_makers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_green_decision_makers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.yellow_c2_percent_yellow_decision_makers || 0,
          value: c1HealthScore?.yellow_c2_no_of_yellow_decision_makers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_yellow_decision_makers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.yellow_c2_percent_red_decision_makers || 0,
          value: c1HealthScore?.yellow_c2_no_of_red_decision_makers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_red_decision_makers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.yellow_c2_percent_grey_decision_makers || 0,
          value: c1HealthScore?.yellow_c2_no_of_grey_decision_makers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_grey_decision_makers || 0,
        },
      ];
    } else if (filter == filterOptions[2].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.yellow_c2_percent_green_influencers || 0,
          value: c1HealthScore?.yellow_c2_no_of_green_influencers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_green_influencers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.yellow_c2_percent_yellow_influencers || 0,
          value: c1HealthScore?.yellow_c2_no_of_yellow_influencers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_yellow_influencers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.yellow_c2_percent_red_influencers || 0,
          value: c1HealthScore?.yellow_c2_no_of_red_influencers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_red_influencers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.yellow_c2_percent_grey_influencers || 0,
          value: c1HealthScore?.yellow_c2_no_of_grey_influencers?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_grey_influencers || 0,
        },
      ];
    } else {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.yellow_c2_percent_green_normal_users || 0,
          value: c1HealthScore?.yellow_c2_no_of_green_normal_users?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_green_normal_users || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.yellow_c2_percent_yellow_normal_users || 0,
          value: c1HealthScore?.yellow_c2_no_of_yellow_normal_users?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_yellow_normal_users || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.yellow_c2_percent_red_normal_users || 0,
          value: c1HealthScore?.yellow_c2_no_of_red_normal_users?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_red_normal_users || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.yellow_c2_percent_grey_normal_users || 0,
          value: c1HealthScore?.yellow_c2_no_of_grey_normal_users?.toString(),
          count: c1HealthScore?.yellow_c2_no_of_grey_normal_users || 0,
        },
      ];
    }
  }

  function getGoodUsers(c1HealthScore: C1HealthScore, filter: string) {
    if (filter === filterOptions[0].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.green_c2_percent_green_u2s || 0,
          value: c1HealthScore?.green_c2_no_of_green_u2s?.toString(),
          count: c1HealthScore?.green_c2_no_of_green_u2s || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.green_c2_percent_yellow_u2s || 0,
          value: c1HealthScore?.green_c2_no_of_yellow_u2s?.toString(),
          count: c1HealthScore?.green_c2_no_of_yellow_u2s || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.green_c2_percent_red_u2s || 0,
          value: c1HealthScore?.green_c2_no_of_red_u2s?.toString(),
          count: c1HealthScore?.green_c2_no_of_red_u2s || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.green_c2_percent_grey_u2s || 0,
          value: c1HealthScore?.green_c2_no_of_grey_u2s?.toString(),
          count: c1HealthScore?.green_c2_no_of_grey_u2s || 0,
        },
      ];
    } else if (filter == filterOptions[1].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.green_c2_percent_green_decision_makers || 0,
          value: c1HealthScore?.green_c2_no_of_green_decision_makers?.toString(),
          count: c1HealthScore?.green_c2_no_of_green_decision_makers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.green_c2_percent_yellow_decision_makers || 0,
          value: c1HealthScore?.green_c2_no_of_yellow_decision_makers?.toString(),
          count: c1HealthScore?.green_c2_no_of_yellow_decision_makers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.green_c2_percent_red_decision_makers || 0,
          value: c1HealthScore?.green_c2_no_of_red_decision_makers?.toString(),
          count: c1HealthScore?.green_c2_no_of_red_decision_makers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.green_c2_percent_grey_decision_makers || 0,
          value: c1HealthScore?.green_c2_no_of_grey_decision_makers?.toString(),
          count: c1HealthScore?.green_c2_no_of_grey_decision_makers || 0,
        },
      ];
    } else if (filter == filterOptions[2].value) {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.green_c2_percent_green_influencers || 0,
          value: c1HealthScore?.green_c2_no_of_green_influencers?.toString(),
          count: c1HealthScore?.green_c2_no_of_green_influencers || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.green_c2_percent_yellow_influencers || 0,
          value: c1HealthScore?.green_c2_no_of_yellow_influencers?.toString(),
          count: c1HealthScore?.green_c2_no_of_yellow_influencers || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.green_c2_percent_red_influencers || 0,
          value: c1HealthScore?.green_c2_no_of_red_influencers?.toString(),
          count: c1HealthScore?.green_c2_no_of_red_influencers || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.green_c2_percent_grey_influencers || 0,
          value: c1HealthScore?.green_c2_no_of_grey_influencers?.toString(),
          count: c1HealthScore?.green_c2_no_of_grey_influencers || 0,
        },
      ];
    } else {
      return [
        {
          group: 'good',
          percentage: c1HealthScore?.green_c2_percent_green_normal_users || 0,
          value: c1HealthScore?.green_c2_no_of_green_normal_users?.toString(),
          count: c1HealthScore?.green_c2_no_of_green_normal_users || 0,
        },
        {
          group: 'fair',
          percentage: c1HealthScore?.green_c2_percent_yellow_normal_users || 0,
          value: c1HealthScore?.green_c2_no_of_yellow_normal_users?.toString(),
          count: c1HealthScore?.green_c2_no_of_yellow_normal_users || 0,
        },
        {
          group: 'bad',
          percentage: c1HealthScore?.green_c2_percent_red_normal_users || 0,
          value: c1HealthScore?.green_c2_no_of_red_normal_users?.toString(),
          count: c1HealthScore?.green_c2_no_of_red_normal_users || 0,
        },
        {
          group: 'inactive',
          percentage: c1HealthScore?.green_c2_percent_grey_normal_users || 0,
          value: c1HealthScore?.green_c2_no_of_grey_normal_users?.toString(),
          count: c1HealthScore?.green_c2_no_of_grey_normal_users || 0,
        },
      ];
    }
  }

  return {
    prepareChart,
    prepareChartV2,
    filterOptions,
  };
};
