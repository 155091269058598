/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MultiMapProductSuspectListDto } from '../model/';
// @ts-ignore
import { ProductSuspect } from '../model/';
// @ts-ignore
import { UpdateProductSuspectDto } from '../model/';
/**
 * ProductSuspectsApi - axios parameter creator
 * @export
 */
export const ProductSuspectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Product Suspects
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSuspectsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product-suspects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Multi Map Suspects
         * @param {MultiMapProductSuspectListDto} multiMapProductSuspectListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSuspectsControllerManuallyMultiMapSuspects: async (multiMapProductSuspectListDto: MultiMapProductSuspectListDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'multiMapProductSuspectListDto' is not null or undefined
            assertParamExists('productSuspectsControllerManuallyMultiMapSuspects', 'multiMapProductSuspectListDto', multiMapProductSuspectListDto)
            const localVarPath = `/api/product-suspects/multi-map-suspects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multiMapProductSuspectListDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Suspect Product
         * @param {string} id 
         * @param {UpdateProductSuspectDto} updateProductSuspectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSuspectsControllerUpdate: async (id: string, updateProductSuspectDto: UpdateProductSuspectDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productSuspectsControllerUpdate', 'id', id)
            // verify required parameter 'updateProductSuspectDto' is not null or undefined
            assertParamExists('productSuspectsControllerUpdate', 'updateProductSuspectDto', updateProductSuspectDto)
            const localVarPath = `/api/product-suspects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductSuspectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductSuspectsApi - functional programming interface
 * @export
 */
export const ProductSuspectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductSuspectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Product Suspects
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productSuspectsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductSuspect>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productSuspectsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Multi Map Suspects
         * @param {MultiMapProductSuspectListDto} multiMapProductSuspectListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productSuspectsControllerManuallyMultiMapSuspects(multiMapProductSuspectListDto: MultiMapProductSuspectListDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductSuspect>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productSuspectsControllerManuallyMultiMapSuspects(multiMapProductSuspectListDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Suspect Product
         * @param {string} id 
         * @param {UpdateProductSuspectDto} updateProductSuspectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productSuspectsControllerUpdate(id: string, updateProductSuspectDto: UpdateProductSuspectDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productSuspectsControllerUpdate(id, updateProductSuspectDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductSuspectsApi - factory interface
 * @export
 */
export const ProductSuspectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductSuspectsApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Product Suspects
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSuspectsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<ProductSuspect>> {
            return localVarFp.productSuspectsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Multi Map Suspects
         * @param {MultiMapProductSuspectListDto} multiMapProductSuspectListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSuspectsControllerManuallyMultiMapSuspects(multiMapProductSuspectListDto: MultiMapProductSuspectListDto, xTenantId?: string, options?: any): AxiosPromise<Array<ProductSuspect>> {
            return localVarFp.productSuspectsControllerManuallyMultiMapSuspects(multiMapProductSuspectListDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Suspect Product
         * @param {string} id 
         * @param {UpdateProductSuspectDto} updateProductSuspectDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSuspectsControllerUpdate(id: string, updateProductSuspectDto: UpdateProductSuspectDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productSuspectsControllerUpdate(id, updateProductSuspectDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productSuspectsControllerFindAll operation in ProductSuspectsApi.
 * @export
 * @interface ProductSuspectsApiProductSuspectsControllerFindAllRequest
 */
export interface ProductSuspectsApiProductSuspectsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductSuspectsApiProductSuspectsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for productSuspectsControllerManuallyMultiMapSuspects operation in ProductSuspectsApi.
 * @export
 * @interface ProductSuspectsApiProductSuspectsControllerManuallyMultiMapSuspectsRequest
 */
export interface ProductSuspectsApiProductSuspectsControllerManuallyMultiMapSuspectsRequest {
    /**
     * 
     * @type {MultiMapProductSuspectListDto}
     * @memberof ProductSuspectsApiProductSuspectsControllerManuallyMultiMapSuspects
     */
    readonly multiMapProductSuspectListDto: MultiMapProductSuspectListDto

    /**
     * 
     * @type {string}
     * @memberof ProductSuspectsApiProductSuspectsControllerManuallyMultiMapSuspects
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for productSuspectsControllerUpdate operation in ProductSuspectsApi.
 * @export
 * @interface ProductSuspectsApiProductSuspectsControllerUpdateRequest
 */
export interface ProductSuspectsApiProductSuspectsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductSuspectsApiProductSuspectsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateProductSuspectDto}
     * @memberof ProductSuspectsApiProductSuspectsControllerUpdate
     */
    readonly updateProductSuspectDto: UpdateProductSuspectDto

    /**
     * 
     * @type {string}
     * @memberof ProductSuspectsApiProductSuspectsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * ProductSuspectsApi - object-oriented interface
 * @export
 * @class ProductSuspectsApi
 * @extends {BaseAPI}
 */
export class ProductSuspectsApi extends BaseAPI {
    /**
     * 
     * @summary List of Product Suspects
     * @param {ProductSuspectsApiProductSuspectsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSuspectsApi
     */
    public productSuspectsControllerFindAll(requestParameters: ProductSuspectsApiProductSuspectsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return ProductSuspectsApiFp(this.configuration).productSuspectsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Multi Map Suspects
     * @param {ProductSuspectsApiProductSuspectsControllerManuallyMultiMapSuspectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSuspectsApi
     */
    public productSuspectsControllerManuallyMultiMapSuspects(requestParameters: ProductSuspectsApiProductSuspectsControllerManuallyMultiMapSuspectsRequest, options?: AxiosRequestConfig) {
        return ProductSuspectsApiFp(this.configuration).productSuspectsControllerManuallyMultiMapSuspects(requestParameters.multiMapProductSuspectListDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Suspect Product
     * @param {ProductSuspectsApiProductSuspectsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSuspectsApi
     */
    public productSuspectsControllerUpdate(requestParameters: ProductSuspectsApiProductSuspectsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return ProductSuspectsApiFp(this.configuration).productSuspectsControllerUpdate(requestParameters.id, requestParameters.updateProductSuspectDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
