/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrganizationTreeNode } from '../model/';
// @ts-ignore
import { PaginatedU1Dto } from '../model/';
// @ts-ignore
import { U1 } from '../model/';
// @ts-ignore
import { U1ProfileDto } from '../model/';
// @ts-ignore
import { U1ProjectionTypeEnum } from '../model/';
// @ts-ignore
import { UpdateCustomerConfigDtoV2 } from '../model/';
// @ts-ignore
import { UpdateInvitationDto } from '../model/';
// @ts-ignore
import { UpdateU1ConfigDto } from '../model/';
// @ts-ignore
import { UpdateU1Dto } from '../model/';
/**
 * U1Api - axios parameter creator
 * @export
 */
export const U1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Build Organization Tree
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerBuildOrganizationTree: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1/organization-tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete zapscale user using id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerDeleteZapscaleUser: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1ControllerDeleteZapscaleUser', 'id', id)
            const localVarPath = `/api/u1/delete-zapscale-user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch u1 with reportees
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFetchU1WithReportees: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1ControllerFetchU1WithReportees', 'id', id)
            const localVarPath = `/api/u1/{id}/u1-with-reportees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U1
         * @param {string} [xTenantId] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAll: async (xTenantId?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['department_id'] = departmentId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (accessRight !== undefined) {
                localVarQueryParameter['access_right'] = accessRight;
            }

            if (nameEmail !== undefined) {
                localVarQueryParameter['name_email'] = nameEmail;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U1 including deleted
         * @param {string} [xTenantId] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAllAlongWithDeleted: async (xTenantId?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1/all-u1s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['department_id'] = departmentId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (accessRight !== undefined) {
                localVarQueryParameter['access_right'] = accessRight;
            }

            if (nameEmail !== undefined) {
                localVarQueryParameter['name_email'] = nameEmail;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2 Ids for U1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAllC2Ids: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1/get-c2-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated U1 List
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAllWithPagination: async (xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1/paginated-u1s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['department_id'] = departmentId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (accessRight !== undefined) {
                localVarQueryParameter['access_right'] = accessRight;
            }

            if (nameEmail !== undefined) {
                localVarQueryParameter['name_email'] = nameEmail;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Reporting Manager Options
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindReportingManagerOptions: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1ControllerFindReportingManagerOptions', 'id', id)
            const localVarPath = `/api/u1/{id}/reporting-manager-options`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile of the authenticated user
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerGetProfile: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Invited U1
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerRemoveInvitedU1: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1ControllerRemoveInvitedU1', 'id', id)
            const localVarPath = `/api/u1/{id}/remove-invited-u1`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update U1
         * @param {string} id 
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdate: async (id: string, updateU1Dto: UpdateU1Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1ControllerUpdate', 'id', id)
            // verify required parameter 'updateU1Dto' is not null or undefined
            assertParamExists('u1ControllerUpdate', 'updateU1Dto', updateU1Dto)
            const localVarPath = `/api/u1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateU1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer config
         * @param {UpdateCustomerConfigDtoV2} updateCustomerConfigDtoV2 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateCustomerConfig: async (updateCustomerConfigDtoV2: UpdateCustomerConfigDtoV2, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomerConfigDtoV2' is not null or undefined
            assertParamExists('u1ControllerUpdateCustomerConfig', 'updateCustomerConfigDtoV2', updateCustomerConfigDtoV2)
            const localVarPath = `/api/u1/customer-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerConfigDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user profile
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateProfile: async (updateU1Dto: UpdateU1Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateU1Dto' is not null or undefined
            assertParamExists('u1ControllerUpdateProfile', 'updateU1Dto', updateU1Dto)
            const localVarPath = `/api/u1/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateU1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user profile V2
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateProfileV2: async (updateU1Dto: UpdateU1Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateU1Dto' is not null or undefined
            assertParamExists('u1ControllerUpdateProfileV2', 'updateU1Dto', updateU1Dto)
            const localVarPath = `/api/v2/u1/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateU1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update U1 config
         * @param {UpdateU1ConfigDto} updateU1ConfigDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateU1Config: async (updateU1ConfigDto: UpdateU1ConfigDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateU1ConfigDto' is not null or undefined
            assertParamExists('u1ControllerUpdateU1Config', 'updateU1ConfigDto', updateU1ConfigDto)
            const localVarPath = `/api/u1/update-u1-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateU1ConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update U1 V2
         * @param {string} id 
         * @param {UpdateInvitationDto} updateInvitationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateV2: async (id: string, updateInvitationDto: UpdateInvitationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1ControllerUpdateV2', 'id', id)
            // verify required parameter 'updateInvitationDto' is not null or undefined
            assertParamExists('u1ControllerUpdateV2', 'updateInvitationDto', updateInvitationDto)
            const localVarPath = `/api/v2/u1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U1Api - functional programming interface
 * @export
 */
export const U1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Build Organization Tree
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerBuildOrganizationTree(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationTreeNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerBuildOrganizationTree(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete zapscale user using id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerDeleteZapscaleUser(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerDeleteZapscaleUser(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch u1 with reportees
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerFetchU1WithReportees(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerFetchU1WithReportees(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U1
         * @param {string} [xTenantId] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerFindAll(xTenantId?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerFindAll(xTenantId, include, departmentId, roleId, accessRight, nameEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U1 including deleted
         * @param {string} [xTenantId] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerFindAllAlongWithDeleted(xTenantId?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerFindAllAlongWithDeleted(xTenantId, include, departmentId, roleId, accessRight, nameEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2 Ids for U1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerFindAllC2Ids(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerFindAllC2Ids(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated U1 List
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedU1Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, include, departmentId, roleId, accessRight, nameEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Reporting Manager Options
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerFindReportingManagerOptions(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerFindReportingManagerOptions(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile of the authenticated user
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerGetProfile(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerGetProfile(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Invited U1
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerRemoveInvitedU1(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerRemoveInvitedU1(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update U1
         * @param {string} id 
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerUpdate(id: string, updateU1Dto: UpdateU1Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerUpdate(id, updateU1Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer config
         * @param {UpdateCustomerConfigDtoV2} updateCustomerConfigDtoV2 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerUpdateCustomerConfig(updateCustomerConfigDtoV2: UpdateCustomerConfigDtoV2, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerUpdateCustomerConfig(updateCustomerConfigDtoV2, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user profile
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerUpdateProfile(updateU1Dto: UpdateU1Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerUpdateProfile(updateU1Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user profile V2
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerUpdateProfileV2(updateU1Dto: UpdateU1Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerUpdateProfileV2(updateU1Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update U1 config
         * @param {UpdateU1ConfigDto} updateU1ConfigDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerUpdateU1Config(updateU1ConfigDto: UpdateU1ConfigDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerUpdateU1Config(updateU1ConfigDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update U1 V2
         * @param {string} id 
         * @param {UpdateInvitationDto} updateInvitationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1ControllerUpdateV2(id: string, updateInvitationDto: UpdateInvitationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1ControllerUpdateV2(id, updateInvitationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U1Api - factory interface
 * @export
 */
export const U1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Build Organization Tree
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerBuildOrganizationTree(xTenantId?: string, options?: any): AxiosPromise<Array<OrganizationTreeNode>> {
            return localVarFp.u1ControllerBuildOrganizationTree(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete zapscale user using id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerDeleteZapscaleUser(id: string, xTenantId?: string, options?: any): AxiosPromise<U1> {
            return localVarFp.u1ControllerDeleteZapscaleUser(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch u1 with reportees
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFetchU1WithReportees(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<U1>> {
            return localVarFp.u1ControllerFetchU1WithReportees(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U1
         * @param {string} [xTenantId] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAll(xTenantId?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options?: any): AxiosPromise<Array<U1>> {
            return localVarFp.u1ControllerFindAll(xTenantId, include, departmentId, roleId, accessRight, nameEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U1 including deleted
         * @param {string} [xTenantId] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAllAlongWithDeleted(xTenantId?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options?: any): AxiosPromise<Array<U1>> {
            return localVarFp.u1ControllerFindAllAlongWithDeleted(xTenantId, include, departmentId, roleId, accessRight, nameEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2 Ids for U1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAllC2Ids(xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.u1ControllerFindAllC2Ids(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated U1 List
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {Array<U1ProjectionTypeEnum>} [include] 
         * @param {string} [departmentId] 
         * @param {string} [roleId] 
         * @param {string} [accessRight] 
         * @param {string} [nameEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, include?: Array<U1ProjectionTypeEnum>, departmentId?: string, roleId?: string, accessRight?: string, nameEmail?: string, options?: any): AxiosPromise<PaginatedU1Dto> {
            return localVarFp.u1ControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, include, departmentId, roleId, accessRight, nameEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Reporting Manager Options
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerFindReportingManagerOptions(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<U1>> {
            return localVarFp.u1ControllerFindReportingManagerOptions(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile of the authenticated user
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerGetProfile(xTenantId?: string, options?: any): AxiosPromise<U1ProfileDto> {
            return localVarFp.u1ControllerGetProfile(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Invited U1
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerRemoveInvitedU1(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.u1ControllerRemoveInvitedU1(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update U1
         * @param {string} id 
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdate(id: string, updateU1Dto: UpdateU1Dto, xTenantId?: string, options?: any): AxiosPromise<U1> {
            return localVarFp.u1ControllerUpdate(id, updateU1Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer config
         * @param {UpdateCustomerConfigDtoV2} updateCustomerConfigDtoV2 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateCustomerConfig(updateCustomerConfigDtoV2: UpdateCustomerConfigDtoV2, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.u1ControllerUpdateCustomerConfig(updateCustomerConfigDtoV2, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user profile
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateProfile(updateU1Dto: UpdateU1Dto, xTenantId?: string, options?: any): AxiosPromise<U1> {
            return localVarFp.u1ControllerUpdateProfile(updateU1Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user profile V2
         * @param {UpdateU1Dto} updateU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateProfileV2(updateU1Dto: UpdateU1Dto, xTenantId?: string, options?: any): AxiosPromise<U1> {
            return localVarFp.u1ControllerUpdateProfileV2(updateU1Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update U1 config
         * @param {UpdateU1ConfigDto} updateU1ConfigDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateU1Config(updateU1ConfigDto: UpdateU1ConfigDto, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.u1ControllerUpdateU1Config(updateU1ConfigDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update U1 V2
         * @param {string} id 
         * @param {UpdateInvitationDto} updateInvitationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1ControllerUpdateV2(id: string, updateInvitationDto: UpdateInvitationDto, xTenantId?: string, options?: any): AxiosPromise<U1> {
            return localVarFp.u1ControllerUpdateV2(id, updateInvitationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u1ControllerBuildOrganizationTree operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerBuildOrganizationTreeRequest
 */
export interface U1ApiU1ControllerBuildOrganizationTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerBuildOrganizationTree
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerDeleteZapscaleUser operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerDeleteZapscaleUserRequest
 */
export interface U1ApiU1ControllerDeleteZapscaleUserRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerDeleteZapscaleUser
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerDeleteZapscaleUser
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerFetchU1WithReportees operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerFetchU1WithReporteesRequest
 */
export interface U1ApiU1ControllerFetchU1WithReporteesRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFetchU1WithReportees
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFetchU1WithReportees
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerFindAll operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerFindAllRequest
 */
export interface U1ApiU1ControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {Array<U1ProjectionTypeEnum>}
     * @memberof U1ApiU1ControllerFindAll
     */
    readonly include?: Array<U1ProjectionTypeEnum>

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAll
     */
    readonly departmentId?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAll
     */
    readonly roleId?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAll
     */
    readonly accessRight?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAll
     */
    readonly nameEmail?: string
}

/**
 * Request parameters for u1ControllerFindAllAlongWithDeleted operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerFindAllAlongWithDeletedRequest
 */
export interface U1ApiU1ControllerFindAllAlongWithDeletedRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllAlongWithDeleted
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {Array<U1ProjectionTypeEnum>}
     * @memberof U1ApiU1ControllerFindAllAlongWithDeleted
     */
    readonly include?: Array<U1ProjectionTypeEnum>

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllAlongWithDeleted
     */
    readonly departmentId?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllAlongWithDeleted
     */
    readonly roleId?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllAlongWithDeleted
     */
    readonly accessRight?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllAlongWithDeleted
     */
    readonly nameEmail?: string
}

/**
 * Request parameters for u1ControllerFindAllC2Ids operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerFindAllC2IdsRequest
 */
export interface U1ApiU1ControllerFindAllC2IdsRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllC2Ids
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerFindAllWithPagination operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerFindAllWithPaginationRequest
 */
export interface U1ApiU1ControllerFindAllWithPaginationRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly after?: string

    /**
     * 
     * @type {Array<U1ProjectionTypeEnum>}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly include?: Array<U1ProjectionTypeEnum>

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly departmentId?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly roleId?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly accessRight?: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindAllWithPagination
     */
    readonly nameEmail?: string
}

/**
 * Request parameters for u1ControllerFindReportingManagerOptions operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerFindReportingManagerOptionsRequest
 */
export interface U1ApiU1ControllerFindReportingManagerOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindReportingManagerOptions
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerFindReportingManagerOptions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerGetProfile operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerGetProfileRequest
 */
export interface U1ApiU1ControllerGetProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerGetProfile
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerRemoveInvitedU1 operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerRemoveInvitedU1Request
 */
export interface U1ApiU1ControllerRemoveInvitedU1Request {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerRemoveInvitedU1
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerRemoveInvitedU1
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerUpdate operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerUpdateRequest
 */
export interface U1ApiU1ControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateU1Dto}
     * @memberof U1ApiU1ControllerUpdate
     */
    readonly updateU1Dto: UpdateU1Dto

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerUpdateCustomerConfig operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerUpdateCustomerConfigRequest
 */
export interface U1ApiU1ControllerUpdateCustomerConfigRequest {
    /**
     * 
     * @type {UpdateCustomerConfigDtoV2}
     * @memberof U1ApiU1ControllerUpdateCustomerConfig
     */
    readonly updateCustomerConfigDtoV2: UpdateCustomerConfigDtoV2

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdateCustomerConfig
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerUpdateProfile operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerUpdateProfileRequest
 */
export interface U1ApiU1ControllerUpdateProfileRequest {
    /**
     * 
     * @type {UpdateU1Dto}
     * @memberof U1ApiU1ControllerUpdateProfile
     */
    readonly updateU1Dto: UpdateU1Dto

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdateProfile
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerUpdateProfileV2 operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerUpdateProfileV2Request
 */
export interface U1ApiU1ControllerUpdateProfileV2Request {
    /**
     * 
     * @type {UpdateU1Dto}
     * @memberof U1ApiU1ControllerUpdateProfileV2
     */
    readonly updateU1Dto: UpdateU1Dto

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdateProfileV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerUpdateU1Config operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerUpdateU1ConfigRequest
 */
export interface U1ApiU1ControllerUpdateU1ConfigRequest {
    /**
     * 
     * @type {UpdateU1ConfigDto}
     * @memberof U1ApiU1ControllerUpdateU1Config
     */
    readonly updateU1ConfigDto: UpdateU1ConfigDto

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdateU1Config
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1ControllerUpdateV2 operation in U1Api.
 * @export
 * @interface U1ApiU1ControllerUpdateV2Request
 */
export interface U1ApiU1ControllerUpdateV2Request {
    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdateV2
     */
    readonly id: string

    /**
     * 
     * @type {UpdateInvitationDto}
     * @memberof U1ApiU1ControllerUpdateV2
     */
    readonly updateInvitationDto: UpdateInvitationDto

    /**
     * 
     * @type {string}
     * @memberof U1ApiU1ControllerUpdateV2
     */
    readonly xTenantId?: string
}

/**
 * U1Api - object-oriented interface
 * @export
 * @class U1Api
 * @extends {BaseAPI}
 */
export class U1Api extends BaseAPI {
    /**
     * 
     * @summary Build Organization Tree
     * @param {U1ApiU1ControllerBuildOrganizationTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerBuildOrganizationTree(requestParameters: U1ApiU1ControllerBuildOrganizationTreeRequest = {}, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerBuildOrganizationTree(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete zapscale user using id
     * @param {U1ApiU1ControllerDeleteZapscaleUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerDeleteZapscaleUser(requestParameters: U1ApiU1ControllerDeleteZapscaleUserRequest, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerDeleteZapscaleUser(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch u1 with reportees
     * @param {U1ApiU1ControllerFetchU1WithReporteesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerFetchU1WithReportees(requestParameters: U1ApiU1ControllerFetchU1WithReporteesRequest, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerFetchU1WithReportees(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U1
     * @param {U1ApiU1ControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerFindAll(requestParameters: U1ApiU1ControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerFindAll(requestParameters.xTenantId, requestParameters.include, requestParameters.departmentId, requestParameters.roleId, requestParameters.accessRight, requestParameters.nameEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U1 including deleted
     * @param {U1ApiU1ControllerFindAllAlongWithDeletedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerFindAllAlongWithDeleted(requestParameters: U1ApiU1ControllerFindAllAlongWithDeletedRequest = {}, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerFindAllAlongWithDeleted(requestParameters.xTenantId, requestParameters.include, requestParameters.departmentId, requestParameters.roleId, requestParameters.accessRight, requestParameters.nameEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2 Ids for U1
     * @param {U1ApiU1ControllerFindAllC2IdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerFindAllC2Ids(requestParameters: U1ApiU1ControllerFindAllC2IdsRequest = {}, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerFindAllC2Ids(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated U1 List
     * @param {U1ApiU1ControllerFindAllWithPaginationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerFindAllWithPagination(requestParameters: U1ApiU1ControllerFindAllWithPaginationRequest = {}, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerFindAllWithPagination(requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.include, requestParameters.departmentId, requestParameters.roleId, requestParameters.accessRight, requestParameters.nameEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Reporting Manager Options
     * @param {U1ApiU1ControllerFindReportingManagerOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerFindReportingManagerOptions(requestParameters: U1ApiU1ControllerFindReportingManagerOptionsRequest, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerFindReportingManagerOptions(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile of the authenticated user
     * @param {U1ApiU1ControllerGetProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerGetProfile(requestParameters: U1ApiU1ControllerGetProfileRequest = {}, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerGetProfile(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Invited U1
     * @param {U1ApiU1ControllerRemoveInvitedU1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerRemoveInvitedU1(requestParameters: U1ApiU1ControllerRemoveInvitedU1Request, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerRemoveInvitedU1(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update U1
     * @param {U1ApiU1ControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerUpdate(requestParameters: U1ApiU1ControllerUpdateRequest, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerUpdate(requestParameters.id, requestParameters.updateU1Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer config
     * @param {U1ApiU1ControllerUpdateCustomerConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerUpdateCustomerConfig(requestParameters: U1ApiU1ControllerUpdateCustomerConfigRequest, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerUpdateCustomerConfig(requestParameters.updateCustomerConfigDtoV2, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user profile
     * @param {U1ApiU1ControllerUpdateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerUpdateProfile(requestParameters: U1ApiU1ControllerUpdateProfileRequest, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerUpdateProfile(requestParameters.updateU1Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user profile V2
     * @param {U1ApiU1ControllerUpdateProfileV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerUpdateProfileV2(requestParameters: U1ApiU1ControllerUpdateProfileV2Request, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerUpdateProfileV2(requestParameters.updateU1Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update U1 config
     * @param {U1ApiU1ControllerUpdateU1ConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerUpdateU1Config(requestParameters: U1ApiU1ControllerUpdateU1ConfigRequest, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerUpdateU1Config(requestParameters.updateU1ConfigDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update U1 V2
     * @param {U1ApiU1ControllerUpdateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1Api
     */
    public u1ControllerUpdateV2(requestParameters: U1ApiU1ControllerUpdateV2Request, options?: AxiosRequestConfig) {
        return U1ApiFp(this.configuration).u1ControllerUpdateV2(requestParameters.id, requestParameters.updateInvitationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
