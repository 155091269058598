/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCustomerCustomViewDto } from '../model/';
// @ts-ignore
import { CustomViewDto } from '../model/';
// @ts-ignore
import { DeletedCustomCustomViewDto } from '../model/';
// @ts-ignore
import { UpdateCustomerCustomViewDto } from '../model/';
/**
 * CustomerCustomViewApi - axios parameter creator
 * @export
 */
export const CustomerCustomViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new customer column config
         * @param {CreateCustomerCustomViewDto} createCustomerCustomViewDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCustomViewControllerCreate: async (createCustomerCustomViewDto: CreateCustomerCustomViewDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomerCustomViewDto' is not null or undefined
            assertParamExists('customerCustomViewControllerCreate', 'createCustomerCustomViewDto', createCustomerCustomViewDto)
            const localVarPath = `/api/customer-custom-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerCustomViewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Customer Custom View
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCustomViewControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerCustomViewControllerRemove', 'id', id)
            const localVarPath = `/api/customer-custom-view/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer column config
         * @param {string} id 
         * @param {UpdateCustomerCustomViewDto} updateCustomerCustomViewDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCustomViewControllerUpdate: async (id: string, updateCustomerCustomViewDto: UpdateCustomerCustomViewDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerCustomViewControllerUpdate', 'id', id)
            // verify required parameter 'updateCustomerCustomViewDto' is not null or undefined
            assertParamExists('customerCustomViewControllerUpdate', 'updateCustomerCustomViewDto', updateCustomerCustomViewDto)
            const localVarPath = `/api/customer-custom-view/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerCustomViewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerCustomViewApi - functional programming interface
 * @export
 */
export const CustomerCustomViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerCustomViewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new customer column config
         * @param {CreateCustomerCustomViewDto} createCustomerCustomViewDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCustomViewControllerCreate(createCustomerCustomViewDto: CreateCustomerCustomViewDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomViewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCustomViewControllerCreate(createCustomerCustomViewDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Customer Custom View
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCustomViewControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletedCustomCustomViewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCustomViewControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer column config
         * @param {string} id 
         * @param {UpdateCustomerCustomViewDto} updateCustomerCustomViewDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCustomViewControllerUpdate(id: string, updateCustomerCustomViewDto: UpdateCustomerCustomViewDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomViewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCustomViewControllerUpdate(id, updateCustomerCustomViewDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerCustomViewApi - factory interface
 * @export
 */
export const CustomerCustomViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerCustomViewApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new customer column config
         * @param {CreateCustomerCustomViewDto} createCustomerCustomViewDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCustomViewControllerCreate(createCustomerCustomViewDto: CreateCustomerCustomViewDto, xTenantId?: string, options?: any): AxiosPromise<CustomViewDto> {
            return localVarFp.customerCustomViewControllerCreate(createCustomerCustomViewDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Customer Custom View
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCustomViewControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<DeletedCustomCustomViewDto> {
            return localVarFp.customerCustomViewControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer column config
         * @param {string} id 
         * @param {UpdateCustomerCustomViewDto} updateCustomerCustomViewDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCustomViewControllerUpdate(id: string, updateCustomerCustomViewDto: UpdateCustomerCustomViewDto, xTenantId?: string, options?: any): AxiosPromise<CustomViewDto> {
            return localVarFp.customerCustomViewControllerUpdate(id, updateCustomerCustomViewDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for customerCustomViewControllerCreate operation in CustomerCustomViewApi.
 * @export
 * @interface CustomerCustomViewApiCustomerCustomViewControllerCreateRequest
 */
export interface CustomerCustomViewApiCustomerCustomViewControllerCreateRequest {
    /**
     * 
     * @type {CreateCustomerCustomViewDto}
     * @memberof CustomerCustomViewApiCustomerCustomViewControllerCreate
     */
    readonly createCustomerCustomViewDto: CreateCustomerCustomViewDto

    /**
     * 
     * @type {string}
     * @memberof CustomerCustomViewApiCustomerCustomViewControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customerCustomViewControllerRemove operation in CustomerCustomViewApi.
 * @export
 * @interface CustomerCustomViewApiCustomerCustomViewControllerRemoveRequest
 */
export interface CustomerCustomViewApiCustomerCustomViewControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomViewApiCustomerCustomViewControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerCustomViewApiCustomerCustomViewControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for customerCustomViewControllerUpdate operation in CustomerCustomViewApi.
 * @export
 * @interface CustomerCustomViewApiCustomerCustomViewControllerUpdateRequest
 */
export interface CustomerCustomViewApiCustomerCustomViewControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomViewApiCustomerCustomViewControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCustomerCustomViewDto}
     * @memberof CustomerCustomViewApiCustomerCustomViewControllerUpdate
     */
    readonly updateCustomerCustomViewDto: UpdateCustomerCustomViewDto

    /**
     * 
     * @type {string}
     * @memberof CustomerCustomViewApiCustomerCustomViewControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * CustomerCustomViewApi - object-oriented interface
 * @export
 * @class CustomerCustomViewApi
 * @extends {BaseAPI}
 */
export class CustomerCustomViewApi extends BaseAPI {
    /**
     * 
     * @summary Create new customer column config
     * @param {CustomerCustomViewApiCustomerCustomViewControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCustomViewApi
     */
    public customerCustomViewControllerCreate(requestParameters: CustomerCustomViewApiCustomerCustomViewControllerCreateRequest, options?: AxiosRequestConfig) {
        return CustomerCustomViewApiFp(this.configuration).customerCustomViewControllerCreate(requestParameters.createCustomerCustomViewDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Customer Custom View
     * @param {CustomerCustomViewApiCustomerCustomViewControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCustomViewApi
     */
    public customerCustomViewControllerRemove(requestParameters: CustomerCustomViewApiCustomerCustomViewControllerRemoveRequest, options?: AxiosRequestConfig) {
        return CustomerCustomViewApiFp(this.configuration).customerCustomViewControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer column config
     * @param {CustomerCustomViewApiCustomerCustomViewControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCustomViewApi
     */
    public customerCustomViewControllerUpdate(requestParameters: CustomerCustomViewApiCustomerCustomViewControllerUpdateRequest, options?: AxiosRequestConfig) {
        return CustomerCustomViewApiFp(this.configuration).customerCustomViewControllerUpdate(requestParameters.id, requestParameters.updateCustomerCustomViewDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
