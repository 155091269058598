<script setup lang="ts">
import { computed } from 'vue';
import Dashboard from './Dashboard.vue';
import DashboardV2 from './DashboardV2.vue';

const showDashV2 = computed(() => {
  return import.meta.env.VITE_FF_DASHBOARD_V2_ENABLED === '1';
});
</script>

<template>
  <DashboardV2 v-if="showDashV2"></DashboardV2>
  <Dashboard v-else></Dashboard>
</template>
