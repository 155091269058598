<script setup lang="ts">
import { phXBold } from '@/phosphor-icons';
import { computed } from 'vue';

const props = withDefaults(defineProps<{ title: string; isCrossVisible?: boolean }>(), {
  title: '',
  isCrossVisible: true,
});
const emits = defineEmits(['onCloseButtonClick']);

const text = computed(() => {
  return props.title;
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-border-b tw-border-light-shade-4 tw-py-4 tw-pl-4">
    <div class="tw-flex tw-items-center tw-justify-between tw-text-base">
      <div class="tw-flex tw-items-center">
        <span class="tw-text-xl tw-font-bold tw-text-dark-shade-1">{{ text }}</span>
        <div class="tw-px-2">
          <slot name="title_append"></slot>
        </div>
        <slot name="thread_title"></slot>
        <slot name="title_icon"></slot>
      </div>

      <div class="tw-flex tw-items-center">
        <slot name="right"></slot>
        <q-icon
          v-if="props.isCrossVisible"
          :name="phXBold"
          :size="'1.0rem'"
          class="tw-ml-4 tw-mr-4 tw-cursor-pointer"
          @click="emits('onCloseButtonClick')"
        />
      </div>
    </div>
    <slot name="caption"></slot>
  </div>
</template>
