import { defineStore } from 'pinia';
import { C1HealthScore, C2HealthScoreZapColorAggregation, DateInfo, DateTypeEnum } from '@/api/client';
import { ref } from 'vue';
import { c1HealthScoreApi, c2HealthScoreApi } from '@/api';
import { endOfDay, formatISO, isLastDayOfMonth, lastDayOfMonth, subMonths } from 'date-fns';

export const useC1HealthScoreStore = defineStore('c1HealthScore', () => {
  const isLoading = ref(false);
  const c1HealthScore = ref<C1HealthScore | null>();

  const isLoadingList = ref(false);
  const isLoadingCustomerMovementTrendList = ref(false);
  const c1HealthScoreList = ref<C1HealthScore[]>([]);
  const c1HealthScoreCustomerMovementTrendList = ref<C1HealthScore[]>([]);

  const c2ZapscoreColorAggregationList = ref<C2HealthScoreZapColorAggregation[]>([]);
  const c2ZapscoreColorAggregationListLoading = ref<boolean>(false);

  async function fetchC1HealthScore(dateInfo?: DateInfo) {
    c1HealthScore.value = null;
    if (!dateInfo) {
      return;
    }
    isLoading.value = true;
    try {
      const { data } = await c1HealthScoreApi.c1HealthScoresControllerFindByDateInfo({ dateInfo });
      c1HealthScore.value = data;
    } catch (e) {
      console.error('C1HealthScore', e);
    } finally {
      isLoading.value = false;
    }
  }

  async function fetchC2HealthScoreColorAggregation(dateInfo: DateInfo) {
    try {
      c2ZapscoreColorAggregationListLoading.value = true;
      const { data } = await c2HealthScoreApi.c2HealthScoreControllerFindAllZapscoreColorAggregationForDate({
        type: dateInfo.type,
        dateUtc: dateInfo.dateUtc,
        date: dateInfo.date,
      });
      c2ZapscoreColorAggregationList.value = data;
    } catch (e) {
      console.error('fetch c2 health score', e);
    } finally {
      c2ZapscoreColorAggregationListLoading.value = false;
    }
  }
  /*Used in Dashboard section*/
  async function fetchC1HealthScoreList(date?: string, dateType?: DateTypeEnum) {
    c1HealthScoreList.value = [];
    if (!date || !dateType) {
      return;
    }
    isLoadingList.value = true;
    try {
      const { data } = await c1HealthScoreApi.c1HealthScoresControllerFindAllByDateRange({ date, dateType });
      c1HealthScoreList.value = data;
    } catch (e) {
      console.error('c1 health score list', e);
    } finally {
      isLoadingList.value = false;
    }
  }

  async function fetchC1HealthScoreCustomerMovementTrendList(date?: string, dateType?: DateTypeEnum) {
    c1HealthScoreCustomerMovementTrendList.value = [];
    if (!date || !dateType) {
      return;
    }
    if (dateType === DateTypeEnum.Day || DateTypeEnum.Week) {
      dateType = DateTypeEnum.Month;
      const isLastDayOfMonths = isLastDayOfMonth(new Date(date));
      if (!isLastDayOfMonths) {
        const prevMonth = subMonths(new Date(date), 1);
        let prevMonthLastDayOfMonth = lastDayOfMonth(prevMonth);
        prevMonthLastDayOfMonth = endOfDay(prevMonthLastDayOfMonth);
        date = formatISO(prevMonthLastDayOfMonth);
      }
    }
    isLoadingCustomerMovementTrendList.value = true;
    try {
      const { data } = await c1HealthScoreApi.c1HealthScoresControllerFindAllByDateRange({ date, dateType });
      c1HealthScoreCustomerMovementTrendList.value = data;
    } catch (e) {
      console.error('customer movement trend c1 health score list', e);
    } finally {
      isLoadingCustomerMovementTrendList.value = false;
    }
  }

  return {
    isLoading,
    isLoadingList,
    c1HealthScore,
    c1HealthScoreList,
    c2ZapscoreColorAggregationList,
    c2ZapscoreColorAggregationListLoading,
    fetchC1HealthScore,
    fetchC1HealthScoreList,
    fetchC2HealthScoreColorAggregation,
    isLoadingCustomerMovementTrendList,
    c1HealthScoreCustomerMovementTrendList,
    fetchC1HealthScoreCustomerMovementTrendList,
  };
});
