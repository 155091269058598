import { defineStore, storeToRefs } from 'pinia';
import {
  AppSuspectC2,
  AppTypeNameEnum,
  C2HealthScore,
  DateInfo,
  DateTypeEnum,
  KpiNameEnum,
  Subscription,
} from '@/api/client';
import { computed, ref } from 'vue';
import { ServerError, appSuspectC2Api, c2HealthScoreApi, peripheralAppApi, subscriptionApi } from '@/api';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { IC2HealthScore } from '@/types/c2HealthScoreDynamic.type';
import { usePeripheralAppsStore } from './peripheralApps';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { useKpiStore } from './kpi';
import { CustomFieldSourceEnum } from '@zapscale/types';
import axios, { AxiosError } from 'axios';
import { useCustomFieldsDefinitionStore } from './customFieldsDefinitionStore';
import { ICustomFieldsDefinitionOptions } from '@/types/customFieldsDefinition.type';

export const useC2HealthScoreStore = defineStore('c2healthscore', () => {
  const { t } = useI18n<{ message: MessageSchema }>({
    useScope: 'global',
  });

  const _kpiStore = useKpiStore();
  const { activeKpiList } = storeToRefs(_kpiStore);
  const appTypeStore = usePeripheralAppsStore();
  const { appTypes } = storeToRefs(appTypeStore);
  const { formatNumberRoundOfDecimal, formatSecondsToMins } = useAmountFormatter();

  const customFieldsDefinitionStore = useCustomFieldsDefinitionStore();

  const { apiCustomFieldsDefinition, webCsvCustomFieldsDefinition, peripheralCustomFieldsDefinition } =
    storeToRefs(customFieldsDefinitionStore);

  const planName = ref<string>('');
  const planCadence = ref<string>('');
  const limit = ref('');

  const noAggregationRecordFound = ref<boolean>(false);
  const isLoading = ref(false);
  const showFinancialData = ref<boolean>(false);
  const c2HealthScore = ref<C2HealthScore | null>();
  const dynamicC2HealthScore = ref<IC2HealthScore | null>();

  const isLoadingList = ref(false);
  const c2HealthScoreList = ref<C2HealthScore[]>([]);

  const syncC2HealthScoreId = ref<string | undefined>(undefined);

  const syncDeletedCustomerTaskId = ref<string | undefined>(undefined);
  const syncDeletedCustomerActionId = ref<string | undefined>(undefined);

  const subscriptionData = ref<Subscription[]>([]);

  const crmRef = ref<ICustomFieldsDefinitionOptions[]>([]);
  const productRef = ref<ICustomFieldsDefinitionOptions[]>([]);
  const ticketsRef = ref<ICustomFieldsDefinitionOptions[]>([]);
  const featuresRef = ref<ICustomFieldsDefinitionOptions[]>([]);
  const subsAndBillRef = ref<ICustomFieldsDefinitionOptions[]>([]);
  const commsRef = ref<ICustomFieldsDefinitionOptions[]>([]);
  const zapscaleDataRef = ref<ICustomFieldsDefinitionOptions[]>([]);

  async function fetchAppTypesFromStore() {
    await appTypeStore.fetchAppTypes();
  }

  const crmAppId = computed(() => {
    // if (!appTypes.value) {
    //   return '';
    // } else return appTypes.value.find((type) => type.name === AppTypeNameEnum.Crm)?.id || '';
    if (!appTypes.value || appTypes.value.length === 0) {
      return '';
    }
    const id = appTypes.value.find((x) => x.name === AppTypeNameEnum.Crm)?.id || '';

    return id;
  });

  const saasProductAppId = computed(() => {
    if (!appTypes.value) {
      return '';
    } else return appTypes.value.find((type) => type.name === AppTypeNameEnum.SaasProduct)?.id || '';
  });

  const ticketDataAppId = computed(() => {
    if (!appTypes.value) {
      return '';
    } else return appTypes.value.find((type) => type.name === AppTypeNameEnum.BugTrackingApp)?.id || '';
  });

  const subscriptionAndBillingAppId = computed(() => {
    if (!appTypes.value) {
      return '';
    } else return appTypes.value.find((type) => type.name === AppTypeNameEnum.SubscriptionAndPaymentApp)?.id || '';
  });

  const featureRequestAppId = computed(() => {
    if (!appTypes.value) {
      return '';
    } else return appTypes.value.find((type) => type.name === AppTypeNameEnum.FeatureRequestApp)?.id || '';
  });

  const CommunicationAppId = computed(() => {
    if (!appTypes.value) {
      return '';
    } else return appTypes.value.find((type) => type.name === AppTypeNameEnum.EmailAndCommunication)?.id || '';
  });

  const dau = computed(() => {
    const healthScore = c2HealthScore.value;
    if (healthScore == null) {
      return;
    }

    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const dau = 'dau' as C2HealthScoreObjectKey;
    const value = healthScore[dau];
    return value;
  });

  const mau = computed(() => {
    const healthScore = c2HealthScore.value;
    if (healthScore == null) {
      return;
    }

    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const mau = 'mau' as C2HealthScoreObjectKey;
    const value = healthScore[mau];
    return value;
  });

  const wau = computed(() => {
    const healthScore = c2HealthScore.value;
    if (healthScore == null) {
      return;
    }

    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const wau = 'wau' as C2HealthScoreObjectKey;
    const value = healthScore[wau];
    return value;
  });

  const totalNoOfBugs = computed(() => {
    if (!c2HealthScore.value?.total_no_of_bugs) {
      return 0;
    }

    return c2HealthScore.value.total_no_of_bugs;
  });

  const totalNoOfBugsResolved = computed(() => {
    if (!c2HealthScore.value?.no_of_bugs_resolved) {
      return 0;
    }

    return c2HealthScore.value.no_of_bugs_resolved;
  });

  const totalNoOffeatures = computed(() => {
    if (!c2HealthScore.value?.no_of_feature_requests) {
      return 0;
    }

    return c2HealthScore.value.no_of_feature_requests;
  });

  const totalNoOffeaturesResolved = computed(() => {
    if (!c2HealthScore.value?.no_of_features_resolved) {
      return 0;
    }

    return c2HealthScore.value.no_of_features_resolved;
  });

  const isCrmRef = ref<Boolean>();
  async function populateCrmData(healthScore: C2HealthScore, appSuspectC2List: AppSuspectC2[]) {
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAllIntegratedApps({
        id: crmAppId.value,
      });
      if (data.length > 0) {
        isCrmRef.value = true;
      } else {
        isCrmRef.value = false;
      }
    } catch (e) {
      console.error('Error in fetching all integrated apps', e);
    }

    crmRef.value = [];
    if (!healthScore && appSuspectC2List.length === 0) {
      crmRef.value = [];
      return;
    }

    const crmSuspectNames = appSuspectC2List
      .reduce((filtered, x) => {
        if (x.app_C2_name) {
          filtered.push(x.app_C2_name);
        }
        return filtered;
      }, [] as string[])
      .join(', ');
    const crmSuspectPhones = appSuspectC2List
      .reduce((filtered, x) => {
        if (x.app_C2_phones) {
          filtered.push(x.app_C2_phones);
        }
        return filtered;
      }, [] as string[])
      .join(', ');

    const crmSuspectEmails = appSuspectC2List
      .reduce((filtered, x) => {
        if (x.app_C2_emails) {
          filtered.push(x.app_C2_emails);
        }
        return filtered;
      }, [] as string[])
      .join(', ');
    const crmSuspectAddresses = appSuspectC2List
      .reduce((filtered, x) => {
        if (x.app_C2_address) {
          filtered.push(x.app_C2_address);
        }
        return filtered;
      }, [] as string[])
      .join(', ');

    crmRef.value.push({
      label: t('customer_detail_overview.fields.crm.label.name'),
      name: t('customer_detail_overview.fields.crm.name.app_C2_name'),
      value: crmSuspectNames,
      source: '',
      isVisible: true,
    });
    crmRef.value.push({
      label: t('customer_detail_overview.fields.crm.label.phone'),
      name: t('customer_detail_overview.fields.crm.name.app_C2_phone'),
      value: crmSuspectPhones,
      source: '',
      isVisible: true,
    });
    crmRef.value.push({
      label: t('customer_detail_overview.fields.crm.label.email'),
      name: t('customer_detail_overview.fields.crm.name.app_C2_email'),
      value: crmSuspectEmails,
      source: '',
      isVisible: true,
    });
    crmRef.value.push({
      label: t('customer_detail_overview.fields.crm.label.address'),
      name: t('customer_detail_overview.fields.crm.name.app_C2_address'),
      value: crmSuspectAddresses,
      source: '',
      isVisible: true,
    });
    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const customFields = 'custom_fields' as C2HealthScoreObjectKey;
    const value = healthScore[customFields] as unknown as Array<{
      name: string;
      value: string;
      source: string;
      isVisible: boolean;
      isCustom: boolean;
      label: string;
      app_type_id: string;
    }>;
    if (value === undefined) {
      return crmRef.value;
    } else {
      const crmApp = appTypes.value.find((type) => type.name === AppTypeNameEnum.Crm);
      const result = [
        ...value.map((x) => {
          return {
            ...x,
            label: x.name,
            isVisible: true,
            isCustom: true,
          };
        }),
      ].filter((x) => x.app_type_id === crmApp?.id);
      crmRef.value = [...crmRef.value, ...result];
    }
  }

  const isTicketsRef = ref<Boolean>();
  async function populateTicketData(healthScore: C2HealthScore) {
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAllIntegratedApps({
        id: ticketDataAppId.value,
      });
      if (data.length > 0) {
        isTicketsRef.value = true;
      } else {
        isTicketsRef.value = false;
      }
    } catch (e) {
      console.error('Error in fetching all integrated apps', e);
    }

    ticketsRef.value = [];

    if (healthScore == null) {
      ticketsRef.value = [];
      return;
    }
    ticketsRef.value.push({
      label: t('customer_detail_overview.fields.ticket.label.30_days_of_logged_tickets'),
      name: t('customer_detail_overview.fields.ticket.name.total_no_of_bugs'),
      value: c2HealthScore.value?.total_no_of_bugs as number,
      value_change_from_last: c2HealthScore.value?.no_of_bugs_logged_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    ticketsRef.value.push({
      label: t('customer_detail_overview.fields.ticket.label.Resolved_tickets'),
      name: t('customer_detail_overview.fields.ticket.name.no_of_bugs_resolved'),
      value: c2HealthScore.value?.no_of_bugs_resolved as number,
      value_change_from_last: c2HealthScore.value?.no_of_bugs_resolved_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    ticketsRef.value.push({
      label: t('customer_detail_overview.fields.ticket.label.Unresolved_tickets'),
      name: t('customer_detail_overview.fields.ticket.name.Unresolved_tickets'),
      value: totalNoOfBugs.value - totalNoOfBugsResolved.value,
      source: '',
      isVisible: true,
    });
    ticketsRef.value.push({
      label: t('customer_detail_overview.fields.ticket.label.Users_logging_tickets'),
      name: t('customer_detail_overview.fields.ticket.name.no_of_u2_logging_bugs'),
      value: c2HealthScore.value?.no_of_u2_logging_bugs as number,
      value_change_from_last: c2HealthScore.value?.no_of_u2s_logging_bugs_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const customFields = 'custom_fields' as C2HealthScoreObjectKey;
    const value = healthScore[customFields] as unknown as Array<{
      name: string;
      value: string;
      source: string;
      isVisible: boolean;
      isCustom: boolean;
      label: string;
      app_type_id: string;
    }>;
    if (value === undefined) {
      return ticketsRef.value;
    } else {
      const ticketApp = appTypes.value.find((type) => type.name === AppTypeNameEnum.BugTrackingApp);
      const result = [
        ...value.map((x) => {
          return {
            ...x,
            label: x.name,
            isVisible: true,
            isCustom: true,
          };
        }),
      ];
      const customTicketData = result.filter((x) => x.app_type_id === ticketApp?.id);
      ticketsRef.value = [...ticketsRef.value, ...customTicketData];
    }
    return;
  }

  const isFeatureRequestAppRef = ref<Boolean>();
  async function populateFeatureData(healthScore: C2HealthScore) {
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAllIntegratedApps({
        id: featureRequestAppId.value,
      });
      if (data.length > 0) {
        isFeatureRequestAppRef.value = true;
      } else {
        isFeatureRequestAppRef.value = false;
      }
    } catch (e) {
      console.error('Error in fetching all integrated apps', e);
    }

    featuresRef.value = [];
    if (healthScore == null) {
      featuresRef.value = [];
      return;
    }
    featuresRef.value.push({
      label: t('customer_detail_overview.fields.feature.label.90_days_of_logged_features'),
      name: t('customer_detail_overview.fields.feature.name.no_of_feature_requests'),
      value: c2HealthScore.value?.no_of_feature_requests as number,
      value_change_from_last: c2HealthScore.value?.no_of_feature_requests_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    featuresRef.value.push({
      label: t('customer_detail_overview.fields.feature.label.Resolved_tickets'),
      name: t('customer_detail_overview.fields.feature.name.no_of_features_resolved'),
      value: c2HealthScore.value?.no_of_features_resolved as number,
      value_change_from_last: c2HealthScore.value?.no_of_features_resolved_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    featuresRef.value.push({
      label: t('customer_detail_overview.fields.feature.label.Unresolved_tickets'),
      name: t('customer_detail_overview.fields.feature.name.Unresolved_features'),
      value: totalNoOffeatures.value - totalNoOffeaturesResolved.value,
      source: '',
      isVisible: true,
    });
    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const customFields = 'custom_fields' as C2HealthScoreObjectKey;
    const value = healthScore[customFields] as unknown as Array<{
      name: string;
      value: string;
      source: string;
      isVisible: boolean;
      isCustom: boolean;
      label: string;
      app_type_id: string;
    }>;
    if (value === undefined) {
      return featuresRef.value;
    } else {
      const featureApp = appTypes.value.find((type) => type.name === AppTypeNameEnum.FeatureRequestApp);
      const result = [
        ...value.map((x) => {
          return {
            ...x,
            label: x.name,
            isVisible: true,
            isCustom: true,
          };
        }),
      ];
      const customFeatureData = result.filter((x) => x.app_type_id === featureApp?.id);
      featuresRef.value = [...featuresRef.value, ...customFeatureData];
    }
    return;
  }

  const isSubsAndBillRef = ref<Boolean>();
  async function populateSubsAndBillData(healthScore: C2HealthScore) {
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAllIntegratedApps({
        id: subscriptionAndBillingAppId.value,
      });
      if (data.length > 0) {
        isSubsAndBillRef.value = true;
      } else {
        isSubsAndBillRef.value = false;
      }
    } catch (e) {
      console.error('Error in fetching all integrated apps', e);
    }

    subsAndBillRef.value = [];
    if (healthScore == null) {
      return;
    }
    subsAndBillRef.value.push({
      label: t('customer_detail_overview.fields.subs.label.No_of_invoices_in_90_days '),
      name: t('customer_detail_overview.fields.subs.name.no_of_invoices_overdue_90_days_plus'),
      value: c2HealthScore.value?.rolling_no_of_invoices as number,
      value_change_from_last: c2HealthScore.value?.total_no_of_overdue_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    subsAndBillRef.value.push({
      label: t('customer_detail_overview.fields.subs.label.Amount_received'),
      name: t('customer_detail_overview.fields.subs.name.rolling_invoice_value_paid'),
      value: (c2HealthScore.value?.rolling_invoice_value_paid as number) / 100,
      source: '',
      isVisible: true,
    });
    subsAndBillRef.value.push({
      label: t('customer_detail_overview.fields.subs.label.Amount_passed_due_date'),
      name: t('customer_detail_overview.fields.subs.name.total_overdue_amount'),
      value: (c2HealthScore.value?.total_overdue_amount as number) / 100,
      source: '',
      isVisible: true,
    });
    subsAndBillRef.value = [
      {
        label: t('customer_detail_overview.fields.subs.label.Plan_Name'),
        name: t('customer_detail_overview.fields.subs.name.Plan_Name'),
        value: planName.value,
        source: '',
        isVisible: true,
      },
      {
        label: t('customer_detail_overview.fields.subs.label.Plan_Cadence'),
        name: t('customer_detail_overview.fields.subs.name.Plan_Cadence'),
        value: planCadence.value,
        source: '',
        isVisible: true,
      },
      {
        label: t('customer_detail_overview.fields.subs.label.Subscription_Limit'),
        name: t('customer_detail_overview.fields.subs.name.Subscription_Limit'),
        value: limit.value,
        source: '',
        isVisible: true,
      },
      // ...subsAndBillRef.value,
    ];
    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const customFields = 'custom_fields' as C2HealthScoreObjectKey;
    const value = healthScore[customFields] as unknown as Array<{
      name: string;
      value: string;
      source: string;
      isVisible: boolean;
      isCustom: boolean;
      label: string;
      app_type_id: string;
    }>;
    if (value === undefined) {
      return subsAndBillRef.value;
    } else {
      const subAndBillApp = appTypes.value.find((type) => type.name === AppTypeNameEnum.SubscriptionAndPaymentApp);
      const result = [
        ...value.map((x) => {
          return {
            ...x,
            label: x.name,
            isVisible: true,
            isCustom: true,
          };
        }),
      ];
      const customSubsAndBillingData = result.filter((x) => x.app_type_id === subAndBillApp?.id);
      subsAndBillRef.value = [...subsAndBillRef.value, ...customSubsAndBillingData];
    }

    return;
  }

  const isCommsRef = ref<Boolean>();
  async function populateCommsData(healthScore: C2HealthScore) {
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAllIntegratedApps({
        id: CommunicationAppId.value,
      });
      if (data.length > 0) {
        isCommsRef.value = true;
      } else {
        isCommsRef.value = false;
      }
    } catch (e) {
      console.error('Error in fetching all integrated apps', e);
    }

    commsRef.value = [];
    if (healthScore == null) {
      commsRef.value = [];
      return;
    }
    commsRef.value.push({
      label: t('customer_detail_overview.fields.comms.label.30_days_of_comm_received'),
      name: t('customer_detail_overview.fields.comms.name.no_of_emails_or_communication'),
      value: c2HealthScore.value?.no_of_emails_or_communication as number,
      value_change_from_last: c2HealthScore.value?.no_of_emails_or_communication_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    commsRef.value.push({
      label: t('customer_detail_overview.fields.comms.label.No_comms_since'),
      name: t('customer_detail_overview.fields.comms.name.days_since_last_communication'),
      value: c2HealthScore.value?.days_since_last_communication as number,
      value_change_from_last: c2HealthScore.value?.days_since_last_communication_percent_change_from_last as number,
      source: '',
      isVisible: true,
    });
    // commsRef.value.push({
    //   label: 'Comms from other channels',
    //   name: 'placeholder',
    //   value: '-',
    //   source: '',
    //   isVisible: true,
    // });
    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const customFields = 'custom_fields' as C2HealthScoreObjectKey;
    const value = healthScore[customFields] as unknown as Array<{
      name: string;
      value: string;
      source: string;
      isVisible: boolean;
      isCustom: boolean;
      label: string;
      app_type_id: string;
    }>;
    if (value === undefined) {
      return commsRef.value;
    } else {
      const commApp = appTypes.value.find((type) => type.name === AppTypeNameEnum.EmailAndCommunication);
      const result = [
        ...value.map((x) => {
          return {
            ...x,
            label: x.name,
            isVisible: true,
            isCustom: true,
          };
        }),
      ];
      const customCommsData = result.filter((x) => x.app_type_id === commApp?.id);
      commsRef.value = [...commsRef.value, ...customCommsData];
    }
    return;
  }

  const isProductRef = ref<Boolean>();
  async function populateProductData(healthScore: C2HealthScore) {
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAllIntegratedApps({
        id: saasProductAppId.value,
      });
      if (data.length > 0) {
        isProductRef.value = true;
      } else {
        isProductRef.value = false;
      }
    } catch (e) {
      console.error('Error in fetching all integrated apps', e);
    }

    productRef.value = [];

    if (healthScore == null) {
      productRef.value = [];
      return;
    }
    productRef.value.push({
      label: t('customer_detail_overview.fields.product.label.Total_Time_on_Product'),
      name: t('customer_detail_overview.fields.product.name.stvcs_total_sessions_time'),
      value: formatNumberRoundOfDecimal(
        formatSecondsToMins(c2HealthScore.value?.stvcs_total_sessions_time),
      ) as unknown as number,
      value_change_from_last: c2HealthScore.value?.stvcs_absolute_percentile_change as number,
      source: '',
      isVisible: true,
    });
    productRef.value.push({
      label: t('customer_detail_overview.fields.product.label.Total_Pages_Visited'),
      name: 'pvvcs_total_no_of_page_visits',
      value: c2HealthScore.value?.pvvcs_total_no_of_page_visits as number,
      value_change_from_last: c2HealthScore.value?.pvps_absolute_percentile_change as number,
      source: '',
      isVisible: true,
    });
    productRef.value.push({
      label: t('customer_detail_overview.fields.product.label.Totals_Features_Used'),
      name: t('customer_detail_overview.fields.product.name.fups_total_no_of_unique_features_used'),
      value: c2HealthScore.value?.fups_total_no_of_unique_features_used as number,
      value_change_from_last: c2HealthScore.value?.fups_absolute_percentile_change as number,
      source: '',
      isVisible: true,
    });
    productRef.value.push({
      label: t('customer_detail_overview.fields.product.label.DAU'),
      name: t('customer_detail_overview.fields.product.name.dau'),
      value: dau.value as number,
      source: '',
      isVisible: true,
    });
    productRef.value.push({
      label: t('customer_detail_overview.fields.product.label.WAU'),
      name: t('customer_detail_overview.fields.product.name.wau'),
      value: wau.value as number,
      source: '',
      isVisible: true,
    });
    productRef.value.push({
      label: t('customer_detail_overview.fields.product.label.MAU'),
      name: t('customer_detail_overview.fields.product.name.mau'),
      value: mau.value as number,
      source: '',
      isVisible: true,
    });

    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const customFields = 'custom_fields' as C2HealthScoreObjectKey;
    const value = healthScore[customFields] as unknown as Array<{
      name: string;
      value: string;
      source: string;
      isVisible: boolean;
      isCustom: boolean;
      label: string;
      app_type_id: string;
    }>;
    if (value === undefined) {
      // productRef.value = result;
      return productRef.value;
    } else {
      if (
        value.filter((x) => x.source === CustomFieldSourceEnum.api).length > 0 ||
        activeKpiList.value.find((x) => x.name === KpiNameEnum.Uss)
      ) {
        isProductRef.value = true;
      }

      const result = [
        ...productRef.value,
        ...value
          .map((x) => {
            return {
              ...x,
              label: x.name,
              isVisible: true,
              isCustom: true,
            };
          })
          .filter((x) => x.source === CustomFieldSourceEnum.api),
      ];
      const customProductData = value.filter(
        (x) => x.app_type_id === appTypes.value.find((type) => type.name === AppTypeNameEnum.SaasProduct)?.id,
      );
      if (customProductData) {
        productRef.value = [...result, ...customProductData];
      }
    }
  }

  function populateZapscaleData(healthScore: C2HealthScore) {
    zapscaleDataRef.value = [];
    if (healthScore == null) {
      zapscaleDataRef.value = [];
      return;
    }

    type C2HealthScoreObjectKey = keyof typeof healthScore;
    const customFields = 'custom_fields' as C2HealthScoreObjectKey;
    const value = healthScore[customFields] as unknown as Array<{
      name: string;
      value: string;
      source: string;
      isVisible: boolean;
      isCustom: boolean;
      label: string;
      app_type_id: string;
      type: string;
    }>;

    if (value === undefined) {
      return zapscaleDataRef.value;
    } else {
      const result = value
        .map((x) => {
          return {
            ...x,
            label: x.name,
            isVisible: true,
            isCustom: true,
          };
        })
        .filter((x) => x.source === CustomFieldSourceEnum.web_csv);
      // .filter((x) => x.source === 'web' || x.source === 'csv');

      zapscaleDataRef.value = [...result];
    }
  }

  async function fetchC2HealthScore(c2Id?: string, dateInfo?: DateInfo) {
    c2HealthScore.value = null;
    if (!c2Id || !dateInfo) {
      return;
    }
    isLoading.value = true;
    noAggregationRecordFound.value = false;
    try {
      await fetchAppTypesFromStore();
      // await appTypeStore.fetchAppTypes();

      const { data } = await c2HealthScoreApi.c2HealthScoreControllerFindOneByC2IdAndDayId({
        c2IdDateInfo: { c2_id: c2Id, date_info: dateInfo },
      });
      showFinancialData.value = data.show_financial_data;
      c2HealthScore.value = data.data;
      dynamicC2HealthScore.value = { ...data.data } as IC2HealthScore;

      if (crmAppId.value === '') {
        return;
      }

      const appSuspectsData = await appSuspectC2Api.appSuspectC2ControllerFindMatchedC2({
        appTypeId: crmAppId.value,
        c2Id: c2Id,
      });

      populateProductData(c2HealthScore.value);
      populateTicketData(c2HealthScore.value);
      populateFeatureData(c2HealthScore.value);
      populateCommsData(c2HealthScore.value);
      populateCrmData(c2HealthScore.value, appSuspectsData.data);
      await fetchSubsAndBillingData(c2Id);
      populateSubsAndBillData(c2HealthScore.value);
      populateZapscaleData(c2HealthScore.value);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const err = e as AxiosError<ServerError>;
        if (err.response?.status === 404) {
          noAggregationRecordFound.value = true;
        } else {
          noAggregationRecordFound.value = false;
        }
      }
      console.error('c2Healthscore', e);
    } finally {
      isLoading.value = false;
    }
  }

  async function fetchSubsAndBillingData(c2Id: string) {
    const { data } = await subscriptionApi.subscriptionsControllerFindSubscriptionsAndBillingDataWithC2Id({
      c2Id: c2Id,
    });
    subscriptionData.value = data;
    planName.value = subscriptionData.value?.map((x) => x.plans?.map((p) => p?.name))?.join(',');
    planCadence.value = subscriptionData.value?.map((x) => x.plan_options?.map((p) => p.interval))?.join(',');
    limit.value = subscriptionData.value
      ?.map((x) => x.plan_options?.map((p) => p.entitlement_object_high_limit))
      ?.join(',');
  }

  async function silentFetchC2HealthScore(c2Id?: string, dateInfo?: DateInfo) {
    if (!c2Id || !dateInfo) {
      return;
    }
    try {
      const { data } = await c2HealthScoreApi.c2HealthScoreControllerFindOneByC2IdAndDayId({
        c2IdDateInfo: { c2_id: c2Id, date_info: dateInfo },
      });
      showFinancialData.value = data.show_financial_data;
      c2HealthScore.value = data.data;
    } catch (e) {
      console.error('c2Healthscore', e);
    }
  }

  async function fetchC2HealthScoreList(c2Id: string, date?: string, dateType?: DateTypeEnum) {
    c2HealthScoreList.value = [];
    if (!c2Id || !date || !dateType) {
      return;
    }
    isLoadingList.value = true;
    try {
      const { data } = await c2HealthScoreApi.c2HealthScoreControllerFindAllByDateRange({ c2Id, date, dateType });
      c2HealthScoreList.value = data.sort((a, b) => (a.date < b.date ? 1 : -1));
    } catch (e) {
      console.error('c2HealthscoreList', e);
    } finally {
      isLoadingList.value = false;
    }
  }

  async function fetchC2HealthScoreById(c2HealthScoreId: string) {
    if (!c2HealthScoreId) {
      return;
    }
    try {
      const { data } = await c2HealthScoreApi.c2HealthScoreControllerFindOneV2({
        id: c2HealthScoreId,
      });
      c2HealthScore.value = data.data;
    } catch (e) {
      console.error('c2Healthscore', e);
    }
  }

  const currentHealthScoreUpsellProbabilityColor = computed(() => {
    if (!c2HealthScore.value?.upsell_probability) {
      return '';
    }
    if (c2HealthScore.value?.upsell_probability === t('upsell.high').toLowerCase()) {
      return ' tw-text-data-viz-semantic-green-content';
    }
    if (c2HealthScore.value?.upsell_probability === t('upsell.mid').toLowerCase()) {
      return ' tw-text-data-viz-semantic-yellow-content';
    }
    if (c2HealthScore.value?.upsell_probability === t('upsell.low').toLowerCase()) {
      return ' tw-text-data-viz-semantic-red-content';
    }
    return '';
  });

  const currentHealthScoreUpsellProbability = computed(() => {
    if (!c2HealthScore.value?.upsell_probability) {
      return '';
    }
    return t(`upsell.${c2HealthScore.value?.upsell_probability}`);
  });

  const activeUsers = computed(() => {
    const totalU2s = c2HealthScore.value?.total_u2s;
    if (totalU2s == null) {
      return '';
    }
    return `(${Math.round(totalU2s)} Users)`;
  });

  const orderedZsData = computed(() => {
    const data = [...zapscaleDataRef.value]
      .map((x) => {
        const item = webCsvCustomFieldsDefinition.value?.find((obj) => x.name === obj.field_name);
        return {
          ...x,
          isVisible: item?.visibility ?? true,
          order: item?.order ?? '',
        };
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    return data as ICustomFieldsDefinitionOptions[];
  });

  const orderedProductData = computed(() => {
    const data = [...productRef.value]
      .map((x) => {
        const item = apiCustomFieldsDefinition.value?.find((obj) => x.name === obj.field_name);
        return {
          ...x,
          isVisible: x?.isCustom ? item?.visibility ?? true : true,
          order: x?.isCustom ? item?.order ?? '' : '',
        };
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    return data as ICustomFieldsDefinitionOptions[];
  });

  const orderedCrmData = computed(() => {
    const data = [...crmRef.value]
      .map((x) => {
        const item = peripheralCustomFieldsDefinition.value?.find((obj) => x.name === obj.field_name);
        return {
          ...x,
          isVisible: x?.isCustom ? item?.visibility ?? true : true,
          order: x?.isCustom ? item?.order ?? '' : '',
        };
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    return data as ICustomFieldsDefinitionOptions[];
  });

  const orderedCommsData = computed(() => {
    const data = [...commsRef.value]
      .map((x) => {
        const item = peripheralCustomFieldsDefinition.value?.find((obj) => x.name === obj.field_name);
        return {
          ...x,
          isVisible: x?.isCustom ? item?.visibility ?? true : true,
          order: x?.isCustom ? item?.order ?? '' : '',
        };
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    return data as ICustomFieldsDefinitionOptions[];
  });

  const orderedFeaturesData = computed(() => {
    const data = [...featuresRef.value]
      .map((x) => {
        const item = peripheralCustomFieldsDefinition.value?.find((obj) => x.name === obj.field_name);
        return {
          ...x,
          isVisible: x?.isCustom ? item?.visibility ?? true : true,
          order: x?.isCustom ? item?.order ?? '' : '',
        };
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    return data as ICustomFieldsDefinitionOptions[];
  });

  const orderedTicketsData = computed(() => {
    const data = [...ticketsRef.value]
      .map((x) => {
        const item = peripheralCustomFieldsDefinition.value?.find((obj) => x.name === obj.field_name);
        return {
          ...x,
          isVisible: x?.isCustom ? item?.visibility ?? true : true,
          order: x?.isCustom ? item?.order ?? '' : '',
        };
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    return data as ICustomFieldsDefinitionOptions[];
  });

  const orderedSubsAndBillData = computed(() => {
    const data = [...subsAndBillRef.value]
      .map((x) => {
        const item = peripheralCustomFieldsDefinition.value?.find((obj) => x.name === obj.field_name);
        return {
          ...x,
          isVisible: x?.isCustom ? item?.visibility ?? true : true,
          order: x?.isCustom ? item?.order ?? '' : '',
        };
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    return data as ICustomFieldsDefinitionOptions[];
  });

  return {
    orderedZsData,
    orderedProductData,
    orderedCommsData,
    orderedFeaturesData,
    orderedTicketsData,
    orderedSubsAndBillData,
    orderedCrmData,
    isLoading,
    noAggregationRecordFound,
    c2HealthScore,
    dynamicC2HealthScore,
    isLoadingList,
    c2HealthScoreList,
    syncC2HealthScoreId,
    syncDeletedCustomerTaskId,
    syncDeletedCustomerActionId,
    fetchC2HealthScore,
    silentFetchC2HealthScore,
    fetchC2HealthScoreList,
    fetchC2HealthScoreById,
    activeUsers,
    currentHealthScoreUpsellProbability,
    currentHealthScoreUpsellProbabilityColor,
    productRef,
    ticketsRef,
    featuresRef,
    subsAndBillRef,
    commsRef,
    crmRef,
    zapscaleDataRef,
    // fetchSubsAndBillingData,
    subscriptionData,
    // planName,
    // planCadence,
    // subsData,
    // commsData,
    // productData,
    isCrmRef,
    isProductRef,
    isTicketsRef,
    isSubsAndBillRef,
    isFeatureRequestAppRef,
    isCommsRef,
    showFinancialData,
  };
});
