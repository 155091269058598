<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import PillToggle from '../PillToggle.vue';
import { Chart, ChartData, ChartTypeRegistry } from 'chart.js';
import { VerticalChartOption } from '@/types/dashboard';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useI18n } from 'vue-i18n';
import { MessageSchema } from '@/lib/i18n';
import LoadingIndicator from '../LoadingIndicator.vue';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { storeToRefs } from 'pinia';
import { useDateStore } from '@/stores/date';
import { C1HealthScore, C2TypeHealthScore } from '@/api/client';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const amountFormatter = useAmountFormatter();
const { movementTrendList, isMovementTrendListLoading, verticalChartFor, showFinancialData } = storeToRefs(
  useDashboardV2Store(),
);
const _dateStore = useDateStore();

const chartDataSet = computed(() => {
  return [
    {
      label: 'Total Customers',
      data: [] as number[][],
      datalabels: {
        align: 'center' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number[]) => {
          if (verticalChartFor.value === VerticalChartOption.arr) {
            return amountFormatter.formatAmount(value[1]);
          } else {
            return `${value[1]}`;
          }
        },
      },
      backgroundColor: '#00A7D2',
    },
    {
      label: 'New Customers',
      data: [] as number[][],
      datalabels: {
        align: 'center' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number[]) => {
          if (verticalChartFor.value === VerticalChartOption.arr) {
            return amountFormatter.formatAmount(value[1] - value[0]);
          } else {
            return `+${value[1] - value[0]}`;
          }
        },
      },
      backgroundColor: '#006DA3',
    },
    {
      label: 'Churned Customers',
      data: [] as number[][],
      datalabels: {
        align: 'center' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number[]) => {
          if (verticalChartFor.value === VerticalChartOption.arr) {
            return amountFormatter.formatAmount(value[1] - value[0]);
          } else {
            return `-${value[1] - value[0]}`;
          }
        },
      },
      backgroundColor: '#444444',
    },
  ];
});

const filterOption = computed(() => {
  if (showFinancialData.value) {
    return [
      {
        label: 'Customers',
        value: VerticalChartOption.customer,
      },
      {
        label: 'ARR',
        value: VerticalChartOption.arr,
      },
    ];
  } else {
    return [
      {
        label: 'Customers',
        value: VerticalChartOption.customer,
      },
    ];
  }
});

const canvas = ref<HTMLCanvasElement>();

let chartInstance: Chart<keyof ChartTypeRegistry, number[], unknown>;
const dataSets = {} as ChartData<'bar'>;
const chartOpacityLevel = ref<string>('tw-opacity-40');

const dataSet = computed(() => {
  let showAggregation = true;

  const barChartDataSet = [...chartDataSet.value];
  barChartDataSet.forEach((x) => (x.data = [] as number[][]));

  let listOfLabel: string[] = [];
  const dateList = [...new Set(movementTrendList.value.map((x) => x.date))].sort();
  const selectedFilter = verticalChartFor.value;
  const healthList = [...movementTrendList.value];
  for (let i = 0; i < dateList.length; i++) {
    const date = dateList[i];
    listOfLabel.push(_dateStore.getCustomerMovementTrendDateLabel(date, showAggregation));
    const healthScore = healthList?.find((x: C1HealthScore | C2TypeHealthScore) => x.date === date);
    if (!healthScore) {
      continue;
    }
    let newCustomerYAxisUpperLimit = 0;
    const totalChart = barChartDataSet.find((x) => x.label === 'Total Customers');
    if (selectedFilter === VerticalChartOption.customer) {
      if (healthScore.total_c2s !== undefined && healthScore.total_c2s > 0) {
        totalChart?.data.push([0, healthScore.total_c2s]);
        newCustomerYAxisUpperLimit = healthScore.total_c2s;
      } else {
        totalChart?.data.push([0, 0]);
      }
    } else {
      if (healthScore.total_acv_of_all_c2s !== undefined && healthScore.total_acv_of_all_c2s > 0) {
        totalChart?.data.push([0, healthScore.total_acv_of_all_c2s]);
        newCustomerYAxisUpperLimit = healthScore.total_acv_of_all_c2s;
      } else {
        totalChart?.data.push([0, 0]);
      }
    }

    const newChart = barChartDataSet.find((x) => x.label === 'New Customers');
    if (selectedFilter === VerticalChartOption.customer) {
      if (healthScore.no_of_c2_new_addition !== undefined && healthScore.no_of_c2_new_addition > 0) {
        newChart?.data.push([healthScore.total_c2s, healthScore.total_c2s + healthScore.no_of_c2_new_addition]);
        newCustomerYAxisUpperLimit = healthScore.total_c2s + healthScore.no_of_c2_new_addition;
      } else {
        newChart?.data.push([0, 0]);
      }
    } else {
      if (healthScore.new_addition_c2_acv !== undefined && healthScore.new_addition_c2_acv > 0) {
        newChart?.data.push([
          healthScore.total_c2s,
          healthScore.total_acv_of_all_c2s + healthScore.new_addition_c2_acv,
        ]);
        newCustomerYAxisUpperLimit = healthScore.total_c2s + healthScore.new_addition_c2_acv;
      } else {
        newChart?.data.push([0, 0]);
      }
    }

    const churnedChart = barChartDataSet.find((x) => x.label === 'Churned Customers');
    if (selectedFilter === VerticalChartOption.customer) {
      if (healthScore.no_of_c2_churns !== undefined && healthScore.no_of_c2_churns > 0) {
        churnedChart?.data.push([newCustomerYAxisUpperLimit - healthScore.no_of_c2_churns, newCustomerYAxisUpperLimit]);
      } else {
        churnedChart?.data.push([0, 0]);
      }
    } else {
      if (healthScore.black_churn_c2_acv !== undefined && healthScore.black_churn_c2_acv > 0) {
        churnedChart?.data.push([
          newCustomerYAxisUpperLimit - healthScore.black_churn_c2_acv,
          newCustomerYAxisUpperLimit,
        ]);
      } else {
        churnedChart?.data.push([0, 0]);
      }
    }
  }
  return {
    labels: listOfLabel,
    datasets: [...barChartDataSet],
  } as unknown as ChartData<'bar'>;
});

watch(
  () => isMovementTrendListLoading.value,
  (isLoading) => {
    if (isLoading) {
      chartOpacityLevel.value = 'tw-opacity-40';
    } else {
      chartOpacityLevel.value = 'tw-opacity-100';
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => dataSet.value,
  (newVal) => {
    updateChartData(newVal);
  },
  {
    immediate: true,
  },
);

function updateChartData(newVal: ChartData<'bar'>) {
  if (chartInstance) {
    chartInstance.data.labels = newVal.labels;
    chartInstance.data.datasets = newVal.datasets;
    chartInstance.update();
  }
}

const drawChart = () => {
  const ctx = canvas.value?.getContext('2d');
  if (!ctx) return;

  chartInstance = new Chart(ctx, {
    type: 'bar',
    plugins: [ChartDataLabels],
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          align: 'end',
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 3.5,
            color: '#666666',
            font: {
              family: 'Hanken Grotesk',
              size: 10,
              weight: '700',
            },
          },
        },
        tooltip: {
          caretSize: 0,
          enabled: true,
          usePointStyle: true,
          boxPadding: 5,
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: function (ctx: any) {
              const element = chartInstance.getActiveElements()[0];
              const dataset = ctx.dataset;
              let label = dataset.label;
              let value = '';
              switch (label) {
                case 'New Customers':
                  if (verticalChartFor.value === VerticalChartOption.arr) {
                    value = t('churn_and_revenue_trend_graph_tooltip.new_acv', {
                      value: amountFormatter.formatAmount(
                        ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                      ),
                    });
                  } else {
                    value = t('churn_and_revenue_trend_graph_tooltip.new_customers', {
                      value: ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                    });
                  }
                  break;
                case 'Churned Customers':
                  if (verticalChartFor.value === VerticalChartOption.arr) {
                    value = t('churn_and_revenue_trend_graph_tooltip.churned_acv', {
                      value: amountFormatter.formatAmount(
                        ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                      ),
                    });
                  } else {
                    value = t('churn_and_revenue_trend_graph_tooltip.churned_customers', {
                      value: ctx.dataset.data[element.index][1] - ctx.dataset.data[element.index][0],
                    });
                  }
                  break;
                default:
                  if (verticalChartFor.value === VerticalChartOption.arr) {
                    value = t('churn_and_revenue_trend_graph_tooltip.total_acv', {
                      value: amountFormatter.formatAmount(ctx.dataset.data[element.index][1]),
                    });
                  } else {
                    value = t('churn_and_revenue_trend_graph_tooltip.total_customers', {
                      value: ctx.dataset.data[element.index][1],
                    });
                  }
                  break;
              }
              return value;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Hanken Grotesk',
              size: 14,
              weight: '600',
            },
          },
        },
        y: {
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
    data: dataSets,
  });
};

onMounted(() => {
  drawChart();
  if (dataSet.value) {
    updateChartData(dataSet.value);
  }
});
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-shadow">
    <LoadingIndicator class="tw-bg-light-shade-1" v-if="isMovementTrendListLoading" />
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-px-6 tw-pt-4">
      <div class="tw-flex tw-flex-col">
        <span class="tw-text-base tw-text-dark-shade-3">{{ t('churn_and_revenue_trends') }}</span>
      </div>

      <PillToggle v-if="!isMovementTrendListLoading" :options="filterOption" v-model="verticalChartFor" />
    </div>

    <div class="tw-relative tw-mt-9 tw-h-60">
      <canvas ref="canvas" />
    </div>
  </div>
</template>
