import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';

export const ManageRoutes: RouteRecordRaw = {
  path: '/manage',
  component: () => import('../views/Manage.vue'),
  name: RouteName.Manage,
  redirect: { name: RouteName.ManageUserProfile },
  meta: {
    layout: Layout.Manage,
  },
  children: [
    {
      path: 'user-profile',
      name: RouteName.ManageUserProfile,
      component: () => import('@/views/Manage/UserProfile.vue'),
      meta: {
        title: 'User Profile',
      },
    },
    {
      path: 'developer-settings',
      component: () => import('@/views/Manage/DeveloperSettings.vue'),
      children: [
        {
          path: 'personal-access-token',
          name: RouteName.ManageDeveloperSettings,
          component: () => import('@/views/Manage/DeveloperSettings/PersonalAccessToken.vue'),
          meta: {
            title: 'Developer Settings - Personal Access Token',
          },
        },
      ],
    },
  ],
};
