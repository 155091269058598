/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OutcomeSuccess } from '../model/';
/**
 * OutcomeSuccessApi - axios parameter creator
 * @export
 */
export const OutcomeSuccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Outcome Success By Kpi Id
         * @param {string} kpiId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outcomeSuccessControllerFindByKpiId: async (kpiId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'kpiId' is not null or undefined
            assertParamExists('outcomeSuccessControllerFindByKpiId', 'kpiId', kpiId)
            const localVarPath = `/api/outcome-success/kpi_id/{kpi_id}`
                .replace(`{${"kpi_id"}}`, encodeURIComponent(String(kpiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutcomeSuccessApi - functional programming interface
 * @export
 */
export const OutcomeSuccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutcomeSuccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Outcome Success By Kpi Id
         * @param {string} kpiId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outcomeSuccessControllerFindByKpiId(kpiId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutcomeSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outcomeSuccessControllerFindByKpiId(kpiId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OutcomeSuccessApi - factory interface
 * @export
 */
export const OutcomeSuccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutcomeSuccessApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Outcome Success By Kpi Id
         * @param {string} kpiId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outcomeSuccessControllerFindByKpiId(kpiId: string, xTenantId?: string, options?: any): AxiosPromise<OutcomeSuccess> {
            return localVarFp.outcomeSuccessControllerFindByKpiId(kpiId, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for outcomeSuccessControllerFindByKpiId operation in OutcomeSuccessApi.
 * @export
 * @interface OutcomeSuccessApiOutcomeSuccessControllerFindByKpiIdRequest
 */
export interface OutcomeSuccessApiOutcomeSuccessControllerFindByKpiIdRequest {
    /**
     * 
     * @type {string}
     * @memberof OutcomeSuccessApiOutcomeSuccessControllerFindByKpiId
     */
    readonly kpiId: string

    /**
     * 
     * @type {string}
     * @memberof OutcomeSuccessApiOutcomeSuccessControllerFindByKpiId
     */
    readonly xTenantId?: string
}

/**
 * OutcomeSuccessApi - object-oriented interface
 * @export
 * @class OutcomeSuccessApi
 * @extends {BaseAPI}
 */
export class OutcomeSuccessApi extends BaseAPI {
    /**
     * 
     * @summary Get Outcome Success By Kpi Id
     * @param {OutcomeSuccessApiOutcomeSuccessControllerFindByKpiIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutcomeSuccessApi
     */
    public outcomeSuccessControllerFindByKpiId(requestParameters: OutcomeSuccessApiOutcomeSuccessControllerFindByKpiIdRequest, options?: AxiosRequestConfig) {
        return OutcomeSuccessApiFp(this.configuration).outcomeSuccessControllerFindByKpiId(requestParameters.kpiId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
