/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateSitemapDto } from '../model/';
// @ts-ignore
import { Sitemap } from '../model/';
// @ts-ignore
import { UpdateSitemapDto } from '../model/';
/**
 * SitemapApi - axios parameter creator
 * @export
 */
export const SitemapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Sitemap
         * @param {CreateSitemapDto} createSitemapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapsControllerCreate: async (createSitemapDto: CreateSitemapDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSitemapDto' is not null or undefined
            assertParamExists('sitemapsControllerCreate', 'createSitemapDto', createSitemapDto)
            const localVarPath = `/api/sitemaps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSitemapDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Sitemaps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sitemaps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Sitemap
         * @param {string} id 
         * @param {UpdateSitemapDto} updateSitemapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapsControllerUpdate: async (id: string, updateSitemapDto: UpdateSitemapDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sitemapsControllerUpdate', 'id', id)
            // verify required parameter 'updateSitemapDto' is not null or undefined
            assertParamExists('sitemapsControllerUpdate', 'updateSitemapDto', updateSitemapDto)
            const localVarPath = `/api/sitemaps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSitemapDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitemapApi - functional programming interface
 * @export
 */
export const SitemapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitemapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Sitemap
         * @param {CreateSitemapDto} createSitemapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sitemapsControllerCreate(createSitemapDto: CreateSitemapDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sitemap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sitemapsControllerCreate(createSitemapDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Sitemaps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sitemapsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sitemap>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sitemapsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Sitemap
         * @param {string} id 
         * @param {UpdateSitemapDto} updateSitemapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sitemapsControllerUpdate(id: string, updateSitemapDto: UpdateSitemapDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sitemap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sitemapsControllerUpdate(id, updateSitemapDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SitemapApi - factory interface
 * @export
 */
export const SitemapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitemapApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Sitemap
         * @param {CreateSitemapDto} createSitemapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapsControllerCreate(createSitemapDto: CreateSitemapDto, xTenantId?: string, options?: any): AxiosPromise<Sitemap> {
            return localVarFp.sitemapsControllerCreate(createSitemapDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Sitemaps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Sitemap>> {
            return localVarFp.sitemapsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Sitemap
         * @param {string} id 
         * @param {UpdateSitemapDto} updateSitemapDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapsControllerUpdate(id: string, updateSitemapDto: UpdateSitemapDto, xTenantId?: string, options?: any): AxiosPromise<Sitemap> {
            return localVarFp.sitemapsControllerUpdate(id, updateSitemapDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sitemapsControllerCreate operation in SitemapApi.
 * @export
 * @interface SitemapApiSitemapsControllerCreateRequest
 */
export interface SitemapApiSitemapsControllerCreateRequest {
    /**
     * 
     * @type {CreateSitemapDto}
     * @memberof SitemapApiSitemapsControllerCreate
     */
    readonly createSitemapDto: CreateSitemapDto

    /**
     * 
     * @type {string}
     * @memberof SitemapApiSitemapsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for sitemapsControllerFindAll operation in SitemapApi.
 * @export
 * @interface SitemapApiSitemapsControllerFindAllRequest
 */
export interface SitemapApiSitemapsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof SitemapApiSitemapsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for sitemapsControllerUpdate operation in SitemapApi.
 * @export
 * @interface SitemapApiSitemapsControllerUpdateRequest
 */
export interface SitemapApiSitemapsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SitemapApiSitemapsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSitemapDto}
     * @memberof SitemapApiSitemapsControllerUpdate
     */
    readonly updateSitemapDto: UpdateSitemapDto

    /**
     * 
     * @type {string}
     * @memberof SitemapApiSitemapsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * SitemapApi - object-oriented interface
 * @export
 * @class SitemapApi
 * @extends {BaseAPI}
 */
export class SitemapApi extends BaseAPI {
    /**
     * 
     * @summary Create Sitemap
     * @param {SitemapApiSitemapsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapApi
     */
    public sitemapsControllerCreate(requestParameters: SitemapApiSitemapsControllerCreateRequest, options?: AxiosRequestConfig) {
        return SitemapApiFp(this.configuration).sitemapsControllerCreate(requestParameters.createSitemapDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Sitemaps
     * @param {SitemapApiSitemapsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapApi
     */
    public sitemapsControllerFindAll(requestParameters: SitemapApiSitemapsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return SitemapApiFp(this.configuration).sitemapsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Sitemap
     * @param {SitemapApiSitemapsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapApi
     */
    public sitemapsControllerUpdate(requestParameters: SitemapApiSitemapsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return SitemapApiFp(this.configuration).sitemapsControllerUpdate(requestParameters.id, requestParameters.updateSitemapDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
