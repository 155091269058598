<script setup lang="ts">
import { computed } from 'vue';
import { useColorFormatter } from '@/composables/useColorFormatter';

const props = defineProps<{
  value?: string;
  percentageChange?: number;
  colorCode?: string;
  iconName?: string;
  isPermissible: boolean;
}>();

const colorFormatter = useColorFormatter();

const valueClass = computed(() => {
  let style = '';
  if (props.iconName) {
    style = 'tw-text-[2.0rem] tw-leading-[3.25rem] tw-font-semibold tw-text-dark-shade-2 tw-mb-1';
  } else {
    style = 'tw-text-[3.0rem] tw-leading-[4.5rem] tw-font-semibold tw-mt-6 tw-mb-2';
    // style = 'tw-text-[3.5rem] tw-leading-[4.5rem] tw-font-semibold tw-mt-2 tw-mb-2';
  }
  if (props.colorCode) {
    style = `${style} ${colorFormatter.getColorCodeAgainstString(props.colorCode)}`;
  }
  return style;
});
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <q-icon v-if="props.iconName" :name="props.iconName" class="tw-mb-2 tw-text-2xl" />
    <div v-if="props.isPermissible" :class="valueClass">
      <!-- <slot name="data" /> -->
      <span> {{ props.value }} </span>
      <!-- <div class="tw-flex tw-rounded tw-p-4 tw-grow tw-backdrop-blur-sm tw-items-center tw-justify-center"> -->
      <!-- </div> -->
      <!-- <span v-if="props.value">
        <q-tooltip class="tw-text-xl" v-if="props.value?.toString().length > 6">{{ props.value }}</q-tooltip>
        {{ showTooltip(props.value) }}
      </span> -->
    </div>
    <div
      v-if="props.isPermissible"
      class="xtw-ml-1 xtw-mt-1 tw-flex tw-flex-row tw-items-center"
      :class="props.percentageChange == null ? 'tw-invisible' : ''"
    >
      <q-icon
        :name="colorFormatter.getCaretType(props.percentageChange)"
        class="tw-text-[0.625rem]"
        :class="colorFormatter.getCaretStyle(props.percentageChange)"
      />
      <span class="tw-ml-0.5 tw-text-sm tw-font-semibold tw-text-dark-shade-1"> {{ props.percentageChange }}% </span>
    </div>

    <slot name="permission" />
    <slot name="footer"></slot>
  </div>
</template>
