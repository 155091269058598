import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';

export const SuccessPlanRoutes: RouteRecordRaw = {
  path: '/success-plans',
  name: RouteName.SuccessPlan,
  redirect: { name: RouteName.SuccessPlanTab },
  component: () => import('../views/SuccessPlan.vue'),
  meta: {
    title: 'Success-Plan',
    group: RouteName.SuccessPlan,
    layout: Layout.Authenticated,
  },
  children: [
    {
      path: 'plans',
      name: RouteName.SuccessPlanTab,
      component: () => import('../views/SuccessPlanTab.vue'),
      meta: {
        title: 'Success-Plan',
      },
    },
  ],
};
