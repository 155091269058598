import { productApi } from '@/api';
import { Product } from '@/api/client';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useProductStore = defineStore('product', () => {
  const isLoading = ref(false);
  const products = ref<Product[]>([]);

  async function fetchProducts() {
    isLoading.value = true;

    try {
      const { data } = await productApi.productsControllerFindAll();
      products.value = data;
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  }

  async function find(id: string) {
    isLoading.value = true;

    return productApi.productsControllerFindOne({ id });
  }

  return {
    isLoading,
    products,
    fetchProducts,
    find,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductStore, import.meta.hot));
}
