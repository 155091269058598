/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePlaybookMessageStepDto } from '../model/';
// @ts-ignore
import { PlaybookMessageStep } from '../model/';
// @ts-ignore
import { UpdatePlaybookMessageStepDto } from '../model/';
/**
 * PlaybookMessageStepApi - axios parameter creator
 * @export
 */
export const PlaybookMessageStepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  Create a Playbook Message Step
         * @param {CreatePlaybookMessageStepDto} createPlaybookMessageStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerCreate: async (createPlaybookMessageStepDto: CreatePlaybookMessageStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlaybookMessageStepDto' is not null or undefined
            assertParamExists('playbookMessageStepControllerCreate', 'createPlaybookMessageStepDto', createPlaybookMessageStepDto)
            const localVarPath = `/api/playbook-message-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaybookMessageStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Playbook Message Steps
         * @param {string} [xTenantId] 
         * @param {string} [messageId] Optional parameter, if present then all message steps with same message id will be returned else return all message steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerFindAll: async (xTenantId?: string, messageId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-message-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Playbook-Message-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookMessageStepControllerRemove', 'id', id)
            const localVarPath = `/api/playbook-message-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Playbook-Message-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookMessageStepDto} updatePlaybookMessageStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerUpdate: async (id: string, updatePlaybookMessageStepDto: UpdatePlaybookMessageStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookMessageStepControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookMessageStepDto' is not null or undefined
            assertParamExists('playbookMessageStepControllerUpdate', 'updatePlaybookMessageStepDto', updatePlaybookMessageStepDto)
            const localVarPath = `/api/playbook-message-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookMessageStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookMessageStepApi - functional programming interface
 * @export
 */
export const PlaybookMessageStepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookMessageStepApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook Message Step
         * @param {CreatePlaybookMessageStepDto} createPlaybookMessageStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookMessageStepControllerCreate(createPlaybookMessageStepDto: CreatePlaybookMessageStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookMessageStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookMessageStepControllerCreate(createPlaybookMessageStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Playbook Message Steps
         * @param {string} [xTenantId] 
         * @param {string} [messageId] Optional parameter, if present then all message steps with same message id will be returned else return all message steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookMessageStepControllerFindAll(xTenantId?: string, messageId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookMessageStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookMessageStepControllerFindAll(xTenantId, messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Playbook-Message-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookMessageStepControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookMessageStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookMessageStepControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Playbook-Message-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookMessageStepDto} updatePlaybookMessageStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookMessageStepControllerUpdate(id: string, updatePlaybookMessageStepDto: UpdatePlaybookMessageStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookMessageStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookMessageStepControllerUpdate(id, updatePlaybookMessageStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookMessageStepApi - factory interface
 * @export
 */
export const PlaybookMessageStepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookMessageStepApiFp(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook Message Step
         * @param {CreatePlaybookMessageStepDto} createPlaybookMessageStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerCreate(createPlaybookMessageStepDto: CreatePlaybookMessageStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookMessageStep> {
            return localVarFp.playbookMessageStepControllerCreate(createPlaybookMessageStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Playbook Message Steps
         * @param {string} [xTenantId] 
         * @param {string} [messageId] Optional parameter, if present then all message steps with same message id will be returned else return all message steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerFindAll(xTenantId?: string, messageId?: string, options?: any): AxiosPromise<Array<PlaybookMessageStep>> {
            return localVarFp.playbookMessageStepControllerFindAll(xTenantId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Playbook-Message-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<PlaybookMessageStep> {
            return localVarFp.playbookMessageStepControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Playbook-Message-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookMessageStepDto} updatePlaybookMessageStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookMessageStepControllerUpdate(id: string, updatePlaybookMessageStepDto: UpdatePlaybookMessageStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookMessageStep> {
            return localVarFp.playbookMessageStepControllerUpdate(id, updatePlaybookMessageStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookMessageStepControllerCreate operation in PlaybookMessageStepApi.
 * @export
 * @interface PlaybookMessageStepApiPlaybookMessageStepControllerCreateRequest
 */
export interface PlaybookMessageStepApiPlaybookMessageStepControllerCreateRequest {
    /**
     * 
     * @type {CreatePlaybookMessageStepDto}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerCreate
     */
    readonly createPlaybookMessageStepDto: CreatePlaybookMessageStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookMessageStepControllerFindAll operation in PlaybookMessageStepApi.
 * @export
 * @interface PlaybookMessageStepApiPlaybookMessageStepControllerFindAllRequest
 */
export interface PlaybookMessageStepApiPlaybookMessageStepControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional parameter, if present then all message steps with same message id will be returned else return all message steps
     * @type {string}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerFindAll
     */
    readonly messageId?: string
}

/**
 * Request parameters for playbookMessageStepControllerRemove operation in PlaybookMessageStepApi.
 * @export
 * @interface PlaybookMessageStepApiPlaybookMessageStepControllerRemoveRequest
 */
export interface PlaybookMessageStepApiPlaybookMessageStepControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookMessageStepControllerUpdate operation in PlaybookMessageStepApi.
 * @export
 * @interface PlaybookMessageStepApiPlaybookMessageStepControllerUpdateRequest
 */
export interface PlaybookMessageStepApiPlaybookMessageStepControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookMessageStepDto}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerUpdate
     */
    readonly updatePlaybookMessageStepDto: UpdatePlaybookMessageStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookMessageStepApiPlaybookMessageStepControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlaybookMessageStepApi - object-oriented interface
 * @export
 * @class PlaybookMessageStepApi
 * @extends {BaseAPI}
 */
export class PlaybookMessageStepApi extends BaseAPI {
    /**
     * 
     * @summary  Create a Playbook Message Step
     * @param {PlaybookMessageStepApiPlaybookMessageStepControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookMessageStepApi
     */
    public playbookMessageStepControllerCreate(requestParameters: PlaybookMessageStepApiPlaybookMessageStepControllerCreateRequest, options?: AxiosRequestConfig) {
        return PlaybookMessageStepApiFp(this.configuration).playbookMessageStepControllerCreate(requestParameters.createPlaybookMessageStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Playbook Message Steps
     * @param {PlaybookMessageStepApiPlaybookMessageStepControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookMessageStepApi
     */
    public playbookMessageStepControllerFindAll(requestParameters: PlaybookMessageStepApiPlaybookMessageStepControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookMessageStepApiFp(this.configuration).playbookMessageStepControllerFindAll(requestParameters.xTenantId, requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Playbook-Message-Step by id
     * @param {PlaybookMessageStepApiPlaybookMessageStepControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookMessageStepApi
     */
    public playbookMessageStepControllerRemove(requestParameters: PlaybookMessageStepApiPlaybookMessageStepControllerRemoveRequest, options?: AxiosRequestConfig) {
        return PlaybookMessageStepApiFp(this.configuration).playbookMessageStepControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Playbook-Message-Step by id
     * @param {PlaybookMessageStepApiPlaybookMessageStepControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookMessageStepApi
     */
    public playbookMessageStepControllerUpdate(requestParameters: PlaybookMessageStepApiPlaybookMessageStepControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookMessageStepApiFp(this.configuration).playbookMessageStepControllerUpdate(requestParameters.id, requestParameters.updatePlaybookMessageStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
