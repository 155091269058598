/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateChatDto } from '../model/';
// @ts-ignore
import { CreateMessageDto } from '../model/';
// @ts-ignore
import { GenerateTextResponseDto } from '../model/';
// @ts-ignore
import { Message } from '../model/';
// @ts-ignore
import { MessageFilterDto } from '../model/';
// @ts-ignore
import { MessageFilterV2Dto } from '../model/';
// @ts-ignore
import { PaginatedMessageDto } from '../model/';
// @ts-ignore
import { RoleBaseMessagePaginatedDto } from '../model/';
// @ts-ignore
import { SourceEnum } from '../model/';
// @ts-ignore
import { UpdateMessageDto } from '../model/';
/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Message
         * @param {CreateMessageDto} createMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerCreate: async (createMessageDto: CreateMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMessageDto' is not null or undefined
            assertParamExists('messagesControllerCreate', 'createMessageDto', createMessageDto)
            const localVarPath = `/api/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create chat within message
         * @param {string} parentId 
         * @param {CreateChatDto} createChatDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerCreateMessageForThread: async (parentId: string, createChatDto: CreateChatDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('messagesControllerCreateMessageForThread', 'parentId', parentId)
            // verify required parameter 'createChatDto' is not null or undefined
            assertParamExists('messagesControllerCreateMessageForThread', 'createChatDto', createChatDto)
            const localVarPath = `/api/messages/{parent_id}/chat`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zapbook Action Details CSV Download
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerDownloadMessageInfoCsv: async (messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageFilterV2Dto' is not null or undefined
            assertParamExists('messagesControllerDownloadMessageInfoCsv', 'messageFilterV2Dto', messageFilterV2Dto)
            const localVarPath = `/api/messages/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageFilterV2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Messages against filter and sorting
         * @param {MessageFilterDto} messageFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFilterFindAll: async (messageFilterDto: MessageFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageFilterDto' is not null or undefined
            assertParamExists('messagesControllerFilterFindAll', 'messageFilterDto', messageFilterDto)
            const localVarPath = `/api/messages/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of message threads filter v2 with performance enhancement
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFilterV2: async (messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageFilterV2Dto' is not null or undefined
            assertParamExists('messagesControllerFilterV2', 'messageFilterV2Dto', messageFilterV2Dto)
            const localVarPath = `/api/v2/messages/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageFilterV2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all Messages
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAll: async (source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('messagesControllerFindAll', 'source', source)
            const localVarPath = `/api/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (healthScoreId !== undefined) {
                localVarQueryParameter['health_score_id'] = healthScoreId;
            }

            if (kpiId !== undefined) {
                localVarQueryParameter['kpi_id'] = kpiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of chats within message
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAllChats: async (id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesControllerFindAllChats', 'id', id)
            const localVarPath = `/api/messages/{id}/chats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List messages for polling
         * @param {string} parentId 
         * @param {string} id 
         * @param {string} createdAt 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAllLatestMessages: async (parentId: string, id: string, createdAt: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('messagesControllerFindAllLatestMessages', 'parentId', parentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesControllerFindAllLatestMessages', 'id', id)
            // verify required parameter 'createdAt' is not null or undefined
            assertParamExists('messagesControllerFindAllLatestMessages', 'createdAt', createdAt)
            const localVarPath = `/api/messages/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['created_at'] = (createdAt as any instanceof Date) ?
                    (createdAt as any).toISOString() :
                    createdAt;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all Messages V2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAllV2: async (source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('messagesControllerFindAllV2', 'source', source)
            const localVarPath = `/api/v2/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (healthScoreId !== undefined) {
                localVarQueryParameter['health_score_id'] = healthScoreId;
            }

            if (kpiId !== undefined) {
                localVarQueryParameter['kpi_id'] = kpiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Message by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesControllerFindOne', 'id', id)
            const localVarPath = `/api/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch message count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindU1MesssageCount: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/msg-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerGenerateText: async (topic: string, pointers: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('messagesControllerGenerateText', 'topic', topic)
            // verify required parameter 'pointers' is not null or undefined
            assertParamExists('messagesControllerGenerateText', 'pointers', pointers)
            const localVarPath = `/api/messages/generate-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (pointers !== undefined) {
                localVarQueryParameter['pointers'] = pointers;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Message by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesControllerRemove', 'id', id)
            const localVarPath = `/api/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Message by ID
         * @param {string} id 
         * @param {UpdateMessageDto} updateMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerUpdate: async (id: string, updateMessageDto: UpdateMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesControllerUpdate', 'id', id)
            // verify required parameter 'updateMessageDto' is not null or undefined
            assertParamExists('messagesControllerUpdate', 'updateMessageDto', updateMessageDto)
            const localVarPath = `/api/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Message
         * @param {CreateMessageDto} createMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerCreate(createMessageDto: CreateMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerCreate(createMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create chat within message
         * @param {string} parentId 
         * @param {CreateChatDto} createChatDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerCreateMessageForThread(parentId: string, createChatDto: CreateChatDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerCreateMessageForThread(parentId, createChatDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Zapbook Action Details CSV Download
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerDownloadMessageInfoCsv(messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerDownloadMessageInfoCsv(messageFilterV2Dto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Messages against filter and sorting
         * @param {MessageFilterDto} messageFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFilterFindAll(messageFilterDto: MessageFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFilterFindAll(messageFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of message threads filter v2 with performance enhancement
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFilterV2(messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseMessagePaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFilterV2(messageFilterV2Dto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all Messages
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFindAll(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFindAll(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of chats within message
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFindAllChats(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFindAllChats(id, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List messages for polling
         * @param {string} parentId 
         * @param {string} id 
         * @param {string} createdAt 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFindAllLatestMessages(parentId: string, id: string, createdAt: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFindAllLatestMessages(parentId, id, createdAt, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all Messages V2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFindAllV2(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseMessagePaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFindAllV2(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Message by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch message count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerFindU1MesssageCount(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerFindU1MesssageCount(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerGenerateText(topic: string, pointers: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerGenerateText(topic, pointers, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Message by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Message by ID
         * @param {string} id 
         * @param {UpdateMessageDto} updateMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerUpdate(id: string, updateMessageDto: UpdateMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerUpdate(id, updateMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Message
         * @param {CreateMessageDto} createMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerCreate(createMessageDto: CreateMessageDto, xTenantId?: string, options?: any): AxiosPromise<Message> {
            return localVarFp.messagesControllerCreate(createMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create chat within message
         * @param {string} parentId 
         * @param {CreateChatDto} createChatDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerCreateMessageForThread(parentId: string, createChatDto: CreateChatDto, xTenantId?: string, options?: any): AxiosPromise<Message> {
            return localVarFp.messagesControllerCreateMessageForThread(parentId, createChatDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Zapbook Action Details CSV Download
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerDownloadMessageInfoCsv(messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.messagesControllerDownloadMessageInfoCsv(messageFilterV2Dto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Messages against filter and sorting
         * @param {MessageFilterDto} messageFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFilterFindAll(messageFilterDto: MessageFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedMessageDto> {
            return localVarFp.messagesControllerFilterFindAll(messageFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of message threads filter v2 with performance enhancement
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFilterV2(messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBaseMessagePaginatedDto> {
            return localVarFp.messagesControllerFilterV2(messageFilterV2Dto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all Messages
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAll(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: any): AxiosPromise<PaginatedMessageDto> {
            return localVarFp.messagesControllerFindAll(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of chats within message
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAllChats(id: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedMessageDto> {
            return localVarFp.messagesControllerFindAllChats(id, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List messages for polling
         * @param {string} parentId 
         * @param {string} id 
         * @param {string} createdAt 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAllLatestMessages(parentId: string, id: string, createdAt: string, xTenantId?: string, options?: any): AxiosPromise<Array<Message>> {
            return localVarFp.messagesControllerFindAllLatestMessages(parentId, id, createdAt, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all Messages V2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindAllV2(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: any): AxiosPromise<RoleBaseMessagePaginatedDto> {
            return localVarFp.messagesControllerFindAllV2(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Message by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Message> {
            return localVarFp.messagesControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch message count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerFindU1MesssageCount(xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.messagesControllerFindU1MesssageCount(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerGenerateText(topic: string, pointers: string, xTenantId?: string, options?: any): AxiosPromise<GenerateTextResponseDto> {
            return localVarFp.messagesControllerGenerateText(topic, pointers, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Message by ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<Message> {
            return localVarFp.messagesControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Message by ID
         * @param {string} id 
         * @param {UpdateMessageDto} updateMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerUpdate(id: string, updateMessageDto: UpdateMessageDto, xTenantId?: string, options?: any): AxiosPromise<Message> {
            return localVarFp.messagesControllerUpdate(id, updateMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for messagesControllerCreate operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerCreateRequest
 */
export interface MessageApiMessagesControllerCreateRequest {
    /**
     * 
     * @type {CreateMessageDto}
     * @memberof MessageApiMessagesControllerCreate
     */
    readonly createMessageDto: CreateMessageDto

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for messagesControllerCreateMessageForThread operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerCreateMessageForThreadRequest
 */
export interface MessageApiMessagesControllerCreateMessageForThreadRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerCreateMessageForThread
     */
    readonly parentId: string

    /**
     * 
     * @type {CreateChatDto}
     * @memberof MessageApiMessagesControllerCreateMessageForThread
     */
    readonly createChatDto: CreateChatDto

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerCreateMessageForThread
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for messagesControllerDownloadMessageInfoCsv operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerDownloadMessageInfoCsvRequest
 */
export interface MessageApiMessagesControllerDownloadMessageInfoCsvRequest {
    /**
     * 
     * @type {MessageFilterV2Dto}
     * @memberof MessageApiMessagesControllerDownloadMessageInfoCsv
     */
    readonly messageFilterV2Dto: MessageFilterV2Dto

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerDownloadMessageInfoCsv
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof MessageApiMessagesControllerDownloadMessageInfoCsv
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof MessageApiMessagesControllerDownloadMessageInfoCsv
     */
    readonly descending?: boolean
}

/**
 * Request parameters for messagesControllerFilterFindAll operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFilterFindAllRequest
 */
export interface MessageApiMessagesControllerFilterFindAllRequest {
    /**
     * 
     * @type {MessageFilterDto}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly messageFilterDto: MessageFilterDto

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterFindAll
     */
    readonly after?: string
}

/**
 * Request parameters for messagesControllerFilterV2 operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFilterV2Request
 */
export interface MessageApiMessagesControllerFilterV2Request {
    /**
     * 
     * @type {MessageFilterV2Dto}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly messageFilterV2Dto: MessageFilterV2Dto

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFilterV2
     */
    readonly after?: string
}

/**
 * Request parameters for messagesControllerFindAll operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFindAllRequest
 */
export interface MessageApiMessagesControllerFindAllRequest {
    /**
     * 
     * @type {SourceEnum}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly source: SourceEnum

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly after?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly healthScoreId?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAll
     */
    readonly kpiId?: string
}

/**
 * Request parameters for messagesControllerFindAllChats operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFindAllChatsRequest
 */
export interface MessageApiMessagesControllerFindAllChatsRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllChats
     */
    readonly after?: string
}

/**
 * Request parameters for messagesControllerFindAllLatestMessages operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFindAllLatestMessagesRequest
 */
export interface MessageApiMessagesControllerFindAllLatestMessagesRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllLatestMessages
     */
    readonly parentId: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllLatestMessages
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllLatestMessages
     */
    readonly createdAt: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllLatestMessages
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for messagesControllerFindAllV2 operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFindAllV2Request
 */
export interface MessageApiMessagesControllerFindAllV2Request {
    /**
     * 
     * @type {SourceEnum}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly source: SourceEnum

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly after?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly healthScoreId?: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindAllV2
     */
    readonly kpiId?: string
}

/**
 * Request parameters for messagesControllerFindOne operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFindOneRequest
 */
export interface MessageApiMessagesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for messagesControllerFindU1MesssageCount operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerFindU1MesssageCountRequest
 */
export interface MessageApiMessagesControllerFindU1MesssageCountRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerFindU1MesssageCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for messagesControllerGenerateText operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerGenerateTextRequest
 */
export interface MessageApiMessagesControllerGenerateTextRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerGenerateText
     */
    readonly topic: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerGenerateText
     */
    readonly pointers: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerGenerateText
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for messagesControllerRemove operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerRemoveRequest
 */
export interface MessageApiMessagesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for messagesControllerUpdate operation in MessageApi.
 * @export
 * @interface MessageApiMessagesControllerUpdateRequest
 */
export interface MessageApiMessagesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateMessageDto}
     * @memberof MessageApiMessagesControllerUpdate
     */
    readonly updateMessageDto: UpdateMessageDto

    /**
     * 
     * @type {string}
     * @memberof MessageApiMessagesControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary Create Message
     * @param {MessageApiMessagesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerCreate(requestParameters: MessageApiMessagesControllerCreateRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerCreate(requestParameters.createMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create chat within message
     * @param {MessageApiMessagesControllerCreateMessageForThreadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerCreateMessageForThread(requestParameters: MessageApiMessagesControllerCreateMessageForThreadRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerCreateMessageForThread(requestParameters.parentId, requestParameters.createChatDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Zapbook Action Details CSV Download
     * @param {MessageApiMessagesControllerDownloadMessageInfoCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerDownloadMessageInfoCsv(requestParameters: MessageApiMessagesControllerDownloadMessageInfoCsvRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerDownloadMessageInfoCsv(requestParameters.messageFilterV2Dto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Messages against filter and sorting
     * @param {MessageApiMessagesControllerFilterFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFilterFindAll(requestParameters: MessageApiMessagesControllerFilterFindAllRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFilterFindAll(requestParameters.messageFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of message threads filter v2 with performance enhancement
     * @param {MessageApiMessagesControllerFilterV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFilterV2(requestParameters: MessageApiMessagesControllerFilterV2Request, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFilterV2(requestParameters.messageFilterV2Dto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all Messages
     * @param {MessageApiMessagesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFindAll(requestParameters: MessageApiMessagesControllerFindAllRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFindAll(requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.healthScoreId, requestParameters.kpiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of chats within message
     * @param {MessageApiMessagesControllerFindAllChatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFindAllChats(requestParameters: MessageApiMessagesControllerFindAllChatsRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFindAllChats(requestParameters.id, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List messages for polling
     * @param {MessageApiMessagesControllerFindAllLatestMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFindAllLatestMessages(requestParameters: MessageApiMessagesControllerFindAllLatestMessagesRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFindAllLatestMessages(requestParameters.parentId, requestParameters.id, requestParameters.createdAt, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all Messages V2
     * @param {MessageApiMessagesControllerFindAllV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFindAllV2(requestParameters: MessageApiMessagesControllerFindAllV2Request, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFindAllV2(requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.healthScoreId, requestParameters.kpiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Message by ID
     * @param {MessageApiMessagesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFindOne(requestParameters: MessageApiMessagesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch message count
     * @param {MessageApiMessagesControllerFindU1MesssageCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerFindU1MesssageCount(requestParameters: MessageApiMessagesControllerFindU1MesssageCountRequest = {}, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerFindU1MesssageCount(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageApiMessagesControllerGenerateTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerGenerateText(requestParameters: MessageApiMessagesControllerGenerateTextRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerGenerateText(requestParameters.topic, requestParameters.pointers, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Message by ID
     * @param {MessageApiMessagesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerRemove(requestParameters: MessageApiMessagesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Message by ID
     * @param {MessageApiMessagesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messagesControllerUpdate(requestParameters: MessageApiMessagesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).messagesControllerUpdate(requestParameters.id, requestParameters.updateMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
