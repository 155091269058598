/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePlanWithOptionDto } from '../model/';
// @ts-ignore
import { CustomerWithPlansDto } from '../model/';
// @ts-ignore
import { Plan } from '../model/';
// @ts-ignore
import { PlanWithOptionDto } from '../model/';
// @ts-ignore
import { UpdatePlanWithOptionDto } from '../model/';
/**
 * PlanApi - axios parameter creator
 * @export
 */
export const PlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create plan
         * @param {CreatePlanWithOptionDto} createPlanWithOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerCreate: async (createPlanWithOptionDto: CreatePlanWithOptionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlanWithOptionDto' is not null or undefined
            assertParamExists('plansControllerCreate', 'createPlanWithOptionDto', createPlanWithOptionDto)
            const localVarPath = `/api/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlanWithOptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Customer With Plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerGetCustomerWithPlans: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/plans/get-customer-with-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Plan With Options
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerGetPlanWithOptions: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/plans/get-plan-with-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Plan With Options Against ID
         * @param {string} id 
         * @param {UpdatePlanWithOptionDto} updatePlanWithOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerUpdate: async (id: string, updatePlanWithOptionDto: UpdatePlanWithOptionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plansControllerUpdate', 'id', id)
            // verify required parameter 'updatePlanWithOptionDto' is not null or undefined
            assertParamExists('plansControllerUpdate', 'updatePlanWithOptionDto', updatePlanWithOptionDto)
            const localVarPath = `/api/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlanWithOptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanApi - functional programming interface
 * @export
 */
export const PlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create plan
         * @param {CreatePlanWithOptionDto} createPlanWithOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansControllerCreate(createPlanWithOptionDto: CreatePlanWithOptionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansControllerCreate(createPlanWithOptionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Plan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Customer With Plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansControllerGetCustomerWithPlans(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerWithPlansDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansControllerGetCustomerWithPlans(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Plan With Options
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansControllerGetPlanWithOptions(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanWithOptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansControllerGetPlanWithOptions(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Plan With Options Against ID
         * @param {string} id 
         * @param {UpdatePlanWithOptionDto} updatePlanWithOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansControllerUpdate(id: string, updatePlanWithOptionDto: UpdatePlanWithOptionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePlanWithOptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansControllerUpdate(id, updatePlanWithOptionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanApi - factory interface
 * @export
 */
export const PlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanApiFp(configuration)
    return {
        /**
         * 
         * @summary Create plan
         * @param {CreatePlanWithOptionDto} createPlanWithOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerCreate(createPlanWithOptionDto: CreatePlanWithOptionDto, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.plansControllerCreate(createPlanWithOptionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Plan>> {
            return localVarFp.plansControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Customer With Plans
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerGetCustomerWithPlans(xTenantId?: string, options?: any): AxiosPromise<Array<CustomerWithPlansDto>> {
            return localVarFp.plansControllerGetCustomerWithPlans(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Plan With Options
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerGetPlanWithOptions(xTenantId?: string, options?: any): AxiosPromise<Array<PlanWithOptionDto>> {
            return localVarFp.plansControllerGetPlanWithOptions(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Plan With Options Against ID
         * @param {string} id 
         * @param {UpdatePlanWithOptionDto} updatePlanWithOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansControllerUpdate(id: string, updatePlanWithOptionDto: UpdatePlanWithOptionDto, xTenantId?: string, options?: any): AxiosPromise<UpdatePlanWithOptionDto> {
            return localVarFp.plansControllerUpdate(id, updatePlanWithOptionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for plansControllerCreate operation in PlanApi.
 * @export
 * @interface PlanApiPlansControllerCreateRequest
 */
export interface PlanApiPlansControllerCreateRequest {
    /**
     * 
     * @type {CreatePlanWithOptionDto}
     * @memberof PlanApiPlansControllerCreate
     */
    readonly createPlanWithOptionDto: CreatePlanWithOptionDto

    /**
     * 
     * @type {string}
     * @memberof PlanApiPlansControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for plansControllerFindAll operation in PlanApi.
 * @export
 * @interface PlanApiPlansControllerFindAllRequest
 */
export interface PlanApiPlansControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanApiPlansControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for plansControllerGetCustomerWithPlans operation in PlanApi.
 * @export
 * @interface PlanApiPlansControllerGetCustomerWithPlansRequest
 */
export interface PlanApiPlansControllerGetCustomerWithPlansRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanApiPlansControllerGetCustomerWithPlans
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for plansControllerGetPlanWithOptions operation in PlanApi.
 * @export
 * @interface PlanApiPlansControllerGetPlanWithOptionsRequest
 */
export interface PlanApiPlansControllerGetPlanWithOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanApiPlansControllerGetPlanWithOptions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for plansControllerUpdate operation in PlanApi.
 * @export
 * @interface PlanApiPlansControllerUpdateRequest
 */
export interface PlanApiPlansControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanApiPlansControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlanWithOptionDto}
     * @memberof PlanApiPlansControllerUpdate
     */
    readonly updatePlanWithOptionDto: UpdatePlanWithOptionDto

    /**
     * 
     * @type {string}
     * @memberof PlanApiPlansControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlanApi - object-oriented interface
 * @export
 * @class PlanApi
 * @extends {BaseAPI}
 */
export class PlanApi extends BaseAPI {
    /**
     * 
     * @summary Create plan
     * @param {PlanApiPlansControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public plansControllerCreate(requestParameters: PlanApiPlansControllerCreateRequest, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).plansControllerCreate(requestParameters.createPlanWithOptionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Plans
     * @param {PlanApiPlansControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public plansControllerFindAll(requestParameters: PlanApiPlansControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).plansControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Customer With Plans
     * @param {PlanApiPlansControllerGetCustomerWithPlansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public plansControllerGetCustomerWithPlans(requestParameters: PlanApiPlansControllerGetCustomerWithPlansRequest = {}, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).plansControllerGetCustomerWithPlans(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Plan With Options
     * @param {PlanApiPlansControllerGetPlanWithOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public plansControllerGetPlanWithOptions(requestParameters: PlanApiPlansControllerGetPlanWithOptionsRequest = {}, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).plansControllerGetPlanWithOptions(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Plan With Options Against ID
     * @param {PlanApiPlansControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public plansControllerUpdate(requestParameters: PlanApiPlansControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).plansControllerUpdate(requestParameters.id, requestParameters.updatePlanWithOptionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
