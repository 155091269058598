import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';

export const SegmentRoutes: RouteRecordRaw = {
  path: '/segment-health',
  name: RouteName.SegmentHealth,
  component: () => import('../views/SegmentContainer.vue'),
  meta: {
    title: 'Segment Health',
    group: RouteName.SegmentHealth,
    layout: Layout.Authenticated,
  },
};
