/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionType } from '../model/';
// @ts-ignore
import { CreateActionTypeDto } from '../model/';
// @ts-ignore
import { UpdateActionTypeDto } from '../model/';
/**
 * ActionTypeApi - axios parameter creator
 * @export
 */
export const ActionTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create ActionType
         * @param {CreateActionTypeDto} createActionTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerCreate: async (createActionTypeDto: CreateActionTypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createActionTypeDto' is not null or undefined
            assertParamExists('actionTypesControllerCreate', 'createActionTypeDto', createActionTypeDto)
            const localVarPath = `/api/action-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActionTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of ActionType
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/action-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ActionType Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionTypesControllerFindOne', 'id', id)
            const localVarPath = `/api/action-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove ActionType Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionTypesControllerRemove', 'id', id)
            const localVarPath = `/api/action-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ActionType Against ID
         * @param {string} id 
         * @param {UpdateActionTypeDto} updateActionTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerUpdate: async (id: string, updateActionTypeDto: UpdateActionTypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionTypesControllerUpdate', 'id', id)
            // verify required parameter 'updateActionTypeDto' is not null or undefined
            assertParamExists('actionTypesControllerUpdate', 'updateActionTypeDto', updateActionTypeDto)
            const localVarPath = `/api/action-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActionTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionTypeApi - functional programming interface
 * @export
 */
export const ActionTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create ActionType
         * @param {CreateActionTypeDto} createActionTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypesControllerCreate(createActionTypeDto: CreateActionTypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypesControllerCreate(createActionTypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of ActionType
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get ActionType Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypesControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypesControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove ActionType Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypesControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypesControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update ActionType Against ID
         * @param {string} id 
         * @param {UpdateActionTypeDto} updateActionTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypesControllerUpdate(id: string, updateActionTypeDto: UpdateActionTypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypesControllerUpdate(id, updateActionTypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionTypeApi - factory interface
 * @export
 */
export const ActionTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create ActionType
         * @param {CreateActionTypeDto} createActionTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerCreate(createActionTypeDto: CreateActionTypeDto, xTenantId?: string, options?: any): AxiosPromise<ActionType> {
            return localVarFp.actionTypesControllerCreate(createActionTypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of ActionType
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<ActionType>> {
            return localVarFp.actionTypesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ActionType Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<ActionType> {
            return localVarFp.actionTypesControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove ActionType Against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<ActionType> {
            return localVarFp.actionTypesControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ActionType Against ID
         * @param {string} id 
         * @param {UpdateActionTypeDto} updateActionTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypesControllerUpdate(id: string, updateActionTypeDto: UpdateActionTypeDto, xTenantId?: string, options?: any): AxiosPromise<ActionType> {
            return localVarFp.actionTypesControllerUpdate(id, updateActionTypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for actionTypesControllerCreate operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypesControllerCreateRequest
 */
export interface ActionTypeApiActionTypesControllerCreateRequest {
    /**
     * 
     * @type {CreateActionTypeDto}
     * @memberof ActionTypeApiActionTypesControllerCreate
     */
    readonly createActionTypeDto: CreateActionTypeDto

    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionTypesControllerFindAll operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypesControllerFindAllRequest
 */
export interface ActionTypeApiActionTypesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionTypesControllerFindOne operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypesControllerFindOneRequest
 */
export interface ActionTypeApiActionTypesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionTypesControllerRemove operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypesControllerRemoveRequest
 */
export interface ActionTypeApiActionTypesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionTypesControllerUpdate operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypesControllerUpdateRequest
 */
export interface ActionTypeApiActionTypesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateActionTypeDto}
     * @memberof ActionTypeApiActionTypesControllerUpdate
     */
    readonly updateActionTypeDto: UpdateActionTypeDto

    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypesControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * ActionTypeApi - object-oriented interface
 * @export
 * @class ActionTypeApi
 * @extends {BaseAPI}
 */
export class ActionTypeApi extends BaseAPI {
    /**
     * 
     * @summary Create ActionType
     * @param {ActionTypeApiActionTypesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypesControllerCreate(requestParameters: ActionTypeApiActionTypesControllerCreateRequest, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypesControllerCreate(requestParameters.createActionTypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of ActionType
     * @param {ActionTypeApiActionTypesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypesControllerFindAll(requestParameters: ActionTypeApiActionTypesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ActionType Against ID
     * @param {ActionTypeApiActionTypesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypesControllerFindOne(requestParameters: ActionTypeApiActionTypesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypesControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove ActionType Against ID
     * @param {ActionTypeApiActionTypesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypesControllerRemove(requestParameters: ActionTypeApiActionTypesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypesControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ActionType Against ID
     * @param {ActionTypeApiActionTypesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypesControllerUpdate(requestParameters: ActionTypeApiActionTypesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypesControllerUpdate(requestParameters.id, requestParameters.updateActionTypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
