/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2FilterUsageCountDto } from '../model/';
// @ts-ignore
import { C2FilterUsageCreateDto } from '../model/';
/**
 * C2FilterUsageApi - axios parameter creator
 * @export
 */
export const C2FilterUsageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create filter usage log
         * @param {C2FilterUsageCreateDto} c2FilterUsageCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FilterUsageControllerCreate: async (c2FilterUsageCreateDto: C2FilterUsageCreateDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2FilterUsageCreateDto' is not null or undefined
            assertParamExists('c2FilterUsageControllerCreate', 'c2FilterUsageCreateDto', c2FilterUsageCreateDto)
            const localVarPath = `/api/c2-filter-usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2FilterUsageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2 filter usage
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FilterUsageControllerFindFilterUsage: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-filter-usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2FilterUsageApi - functional programming interface
 * @export
 */
export const C2FilterUsageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2FilterUsageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create filter usage log
         * @param {C2FilterUsageCreateDto} c2FilterUsageCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2FilterUsageControllerCreate(c2FilterUsageCreateDto: C2FilterUsageCreateDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2FilterUsageControllerCreate(c2FilterUsageCreateDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2 filter usage
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2FilterUsageControllerFindFilterUsage(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2FilterUsageCountDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2FilterUsageControllerFindFilterUsage(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2FilterUsageApi - factory interface
 * @export
 */
export const C2FilterUsageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2FilterUsageApiFp(configuration)
    return {
        /**
         * 
         * @summary Create filter usage log
         * @param {C2FilterUsageCreateDto} c2FilterUsageCreateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FilterUsageControllerCreate(c2FilterUsageCreateDto: C2FilterUsageCreateDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.c2FilterUsageControllerCreate(c2FilterUsageCreateDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2 filter usage
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2FilterUsageControllerFindFilterUsage(xTenantId?: string, options?: any): AxiosPromise<Array<C2FilterUsageCountDto>> {
            return localVarFp.c2FilterUsageControllerFindFilterUsage(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2FilterUsageControllerCreate operation in C2FilterUsageApi.
 * @export
 * @interface C2FilterUsageApiC2FilterUsageControllerCreateRequest
 */
export interface C2FilterUsageApiC2FilterUsageControllerCreateRequest {
    /**
     * 
     * @type {C2FilterUsageCreateDto}
     * @memberof C2FilterUsageApiC2FilterUsageControllerCreate
     */
    readonly c2FilterUsageCreateDto: C2FilterUsageCreateDto

    /**
     * 
     * @type {string}
     * @memberof C2FilterUsageApiC2FilterUsageControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2FilterUsageControllerFindFilterUsage operation in C2FilterUsageApi.
 * @export
 * @interface C2FilterUsageApiC2FilterUsageControllerFindFilterUsageRequest
 */
export interface C2FilterUsageApiC2FilterUsageControllerFindFilterUsageRequest {
    /**
     * 
     * @type {string}
     * @memberof C2FilterUsageApiC2FilterUsageControllerFindFilterUsage
     */
    readonly xTenantId?: string
}

/**
 * C2FilterUsageApi - object-oriented interface
 * @export
 * @class C2FilterUsageApi
 * @extends {BaseAPI}
 */
export class C2FilterUsageApi extends BaseAPI {
    /**
     * 
     * @summary Create filter usage log
     * @param {C2FilterUsageApiC2FilterUsageControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2FilterUsageApi
     */
    public c2FilterUsageControllerCreate(requestParameters: C2FilterUsageApiC2FilterUsageControllerCreateRequest, options?: AxiosRequestConfig) {
        return C2FilterUsageApiFp(this.configuration).c2FilterUsageControllerCreate(requestParameters.c2FilterUsageCreateDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2 filter usage
     * @param {C2FilterUsageApiC2FilterUsageControllerFindFilterUsageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2FilterUsageApi
     */
    public c2FilterUsageControllerFindFilterUsage(requestParameters: C2FilterUsageApiC2FilterUsageControllerFindFilterUsageRequest = {}, options?: AxiosRequestConfig) {
        return C2FilterUsageApiFp(this.configuration).c2FilterUsageControllerFindFilterUsage(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
