/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PlaybookEmail } from '../model/';
// @ts-ignore
import { UpdatePlaybookEmailDto } from '../model/';
/**
 * PlaybookEmailApi - axios parameter creator
 * @export
 */
export const PlaybookEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Playbook Email
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookEmailControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Playbook Email
         * @param {string} id 
         * @param {UpdatePlaybookEmailDto} updatePlaybookEmailDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookEmailControllerUpdate: async (id: string, updatePlaybookEmailDto: UpdatePlaybookEmailDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookEmailControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookEmailDto' is not null or undefined
            assertParamExists('playbookEmailControllerUpdate', 'updatePlaybookEmailDto', updatePlaybookEmailDto)
            const localVarPath = `/api/playbook-email/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookEmailApi - functional programming interface
 * @export
 */
export const PlaybookEmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookEmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Playbook Email
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookEmailControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookEmail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookEmailControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Playbook Email
         * @param {string} id 
         * @param {UpdatePlaybookEmailDto} updatePlaybookEmailDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookEmailControllerUpdate(id: string, updatePlaybookEmailDto: UpdatePlaybookEmailDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookEmail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookEmailControllerUpdate(id, updatePlaybookEmailDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookEmailApi - factory interface
 * @export
 */
export const PlaybookEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookEmailApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Playbook Email
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookEmailControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<PlaybookEmail>> {
            return localVarFp.playbookEmailControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Playbook Email
         * @param {string} id 
         * @param {UpdatePlaybookEmailDto} updatePlaybookEmailDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookEmailControllerUpdate(id: string, updatePlaybookEmailDto: UpdatePlaybookEmailDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookEmail> {
            return localVarFp.playbookEmailControllerUpdate(id, updatePlaybookEmailDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookEmailControllerFindAll operation in PlaybookEmailApi.
 * @export
 * @interface PlaybookEmailApiPlaybookEmailControllerFindAllRequest
 */
export interface PlaybookEmailApiPlaybookEmailControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookEmailApiPlaybookEmailControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookEmailControllerUpdate operation in PlaybookEmailApi.
 * @export
 * @interface PlaybookEmailApiPlaybookEmailControllerUpdateRequest
 */
export interface PlaybookEmailApiPlaybookEmailControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookEmailApiPlaybookEmailControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookEmailDto}
     * @memberof PlaybookEmailApiPlaybookEmailControllerUpdate
     */
    readonly updatePlaybookEmailDto: UpdatePlaybookEmailDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookEmailApiPlaybookEmailControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlaybookEmailApi - object-oriented interface
 * @export
 * @class PlaybookEmailApi
 * @extends {BaseAPI}
 */
export class PlaybookEmailApi extends BaseAPI {
    /**
     * 
     * @summary List of Playbook Email
     * @param {PlaybookEmailApiPlaybookEmailControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookEmailApi
     */
    public playbookEmailControllerFindAll(requestParameters: PlaybookEmailApiPlaybookEmailControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookEmailApiFp(this.configuration).playbookEmailControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Playbook Email
     * @param {PlaybookEmailApiPlaybookEmailControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookEmailApi
     */
    public playbookEmailControllerUpdate(requestParameters: PlaybookEmailApiPlaybookEmailControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookEmailApiFp(this.configuration).playbookEmailControllerUpdate(requestParameters.id, requestParameters.updatePlaybookEmailDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
