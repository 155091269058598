import { useDashboardStore } from '@/stores/dashboard';
import { useAmountFormatter } from '@/composables/useAmountFormatter';

export const useMovementTrendChart = () => {
  const amountFormatter = useAmountFormatter();
  const dashboardStore = useDashboardStore();

  const filterOptions = [
    {
      label: 'Customers',
      value: 'customers',
    },
    {
      label: 'ARR',
      value: 'acv',
    },
  ];

  const barChartDataSet = [
    {
      label: 'Total Customers',
      data: [] as number[][],
      datalabels: {
        align: 'center' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number[]) => {
          if (dashboardStore.movementTrendSelectedFilter === filterOptions[1].value) {
            return amountFormatter.formatAmount(value[1]);
          } else {
            return `${value[1]}`;
          }
        },
      },
      backgroundColor: '#00A7D2',
    },
    {
      label: 'New Customers',
      data: [] as number[][],
      datalabels: {
        align: 'center' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number[]) => {
          if (dashboardStore.movementTrendSelectedFilter === filterOptions[1].value) {
            return amountFormatter.formatAmount(value[1] - value[0]);
          } else {
            return `+${value[1] - value[0]}`;
          }
        },
      },
      backgroundColor: '#006DA3',
    },
    {
      label: 'Churned Customers',
      data: [] as number[][],
      datalabels: {
        align: 'center' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number[]) => {
          if (dashboardStore.movementTrendSelectedFilter === filterOptions[1].value) {
            return amountFormatter.formatAmount(value[1] - value[0]);
          } else {
            return `-${value[1] - value[0]}`;
          }
        },
      },
      backgroundColor: '#444444',
    },
  ];

  return {
    filterOptions,
    barChartDataSet,
  };
};
