<script setup lang="ts">
import { watch, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { MessageSchema } from '@/lib/i18n';
import { DateInfo, DateTypeEnum } from '@/api/client';
import Greeter from '@/components/Dashboard/Greeter.vue';
import { RouteName } from '@/router/route-name';
import { useDateStore } from '@/stores/date';
import { IQueryParam } from '@/types/query.type';
import { useDashboardV2Store } from '@/stores/dashboardv2';
// import { useC2TypeStore } from '@/stores/c2Type';
import OverviewSection from '@/components/DashboardV2/OverviewSection.vue';
import AreaChartSection from '@/components/DashboardV2/AreaChartSection.vue';
import BarChartSection from '@/components/DashboardV2/BarChartSection.vue';
import D3ChartSection from '@/components/DashboardV2/D3ChartSection.vue';
import SankeyChartSection from '@/components/DashboardV2/SankeyChartSection.vue';
import VerticalBarChartSection from '@/components/DashboardV2/VerticalBarChartSection.vue';
import TableSection from '@/components/DashboardV2/TableSection.vue';
import EmptyState from '@/components/EmptyState.vue';
// import { useCustomerSegmentStore } from '@/stores/customerSegment';
import DashboardFilter from '@/components/DashboardV2/DashboardFilter.vue';
import ProductFeatureUsage from '@/components/DashboardV2/ProductFeatureUsage.vue';
import { useKpiStore } from '@/stores/kpi';
import { useAccountStore } from '@/stores/account';
import NoAggregationData from '@/components/NoAggregationData.vue';

const { dashboardFor } = storeToRefs(useDashboardV2Store());

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const route = useRoute();

const dateStore = useDateStore();
const { dateInfo } = storeToRefs(useDateStore());
const _dashboardV2Store = useDashboardV2Store();
const { noRecordFound, noAggregationRecordFound } = storeToRefs(useDashboardV2Store());
// const c2SegmentStore = useCustomerSegmentStore();

// const c2TypeStore = useC2TypeStore();

const _kpiStore = useKpiStore();
const _accountStore = useAccountStore();

watch(
  route,
  async (routeValue) => {
    const name = routeValue.name;
    if (name !== RouteName.Dashboard) {
      return;
    }
    const dateQuery = routeValue.query.date as string;
    const dateTypeQuery = routeValue.query.date_type as DateTypeEnum;
    if (dateInfo.value && dateInfo.value?.date === dateQuery && dateInfo.value?.type === dateTypeQuery) {
      return;
    }
    if (!dateQuery || !dateTypeQuery) {
      dateStore.initialize();
    } else {
      dateStore.setupDateFromQueryDateString(dateQuery, dateTypeQuery);
    }
    if (!dateInfo.value) {
      return;
    }
    const { date, type } = dateInfo.value;
    if (!date || !type) {
      return;
    }

    await _dashboardV2Store.syncDashboardMetadata(dateInfo.value);
    fetchApisDependsOnDateSelection(dateInfo.value);
  },
  {
    immediate: true,
    deep: true,
  },
);

async function updateRouteOnDateChange(dateString: string, dateType: DateTypeEnum) {
  if (!route.name) {
    return;
  }
  const queryParam = {
    ...route.query,
    date: dateString,
    date_type: dateType,
  } as IQueryParam;
  router.replace({
    name: route.name,
    query: { ...queryParam },
  });
}

async function onDashboardForSelectionChange() {
  const date = dateInfo.value;
  if (!date) {
    return;
  }
  fetchApisDependsOnDateSelection(date);
}

async function fetchApisDependsOnDateSelection(dateInfo: DateInfo) {
  // _dashboardV2Store.fetchDateWiseCustomerType(dateInfo);
  _dashboardV2Store.fetchDashboardInfo(dateInfo);
  _dashboardV2Store.fetchHealthScoreList(dateInfo);
  _dashboardV2Store.fetchC2HealthScoreList(dateInfo);
}

const showHeatMap = computed(() => {
  return import.meta.env.VITE_FF_DASHBOARD_HEATMAP === '1';
});

onMounted(() => {
  _kpiStore.fetchKpiList();
  _accountStore.fetchC1();
});
</script>

<template>
  <Greeter
    :header-name="t('dashboard.title')"
    :check-route="false"
    @on-date-change="updateRouteOnDateChange"
    class="tw-fixed tw-left-0 tw-right-0 tw-z-[55] tw-bg-light-shade-1 tw-font-bold tw-text-dark-shade-2 lg:tw-left-20"
  >
    <template v-slot:right>
      <div class="tw-flex tw-items-center tw-px-2" v-if="dashboardFor">
        <span class="tw-pr-2 tw-text-sm tw-font-semibold tw-text-dark-shade-3">{{ t('dashboard.filter_by') }}</span>

        <DashboardFilter @on-filter-applied="onDashboardForSelectionChange" />
      </div>
    </template>
  </Greeter>

  <q-page class="tw-px-6 tw-py-2 tw-pt-20">
    <NoAggregationData v-if="noAggregationRecordFound" />
    <div v-else class="tw-grid tw-gap-y-2 md:tw-grid-cols-1 lg:tw-mb-3 lg:tw-grid-cols-9">
      <div class="md:tw-col-span-1 lg:tw-col-span-5">
        <OverviewSection class="tw-h-60" v-if="!noRecordFound" />
        <EmptyState
          v-else
          :header="t('dashboard.empty_header')"
          :description="t('dashboard.empty_description')"
          class="tw-h-full"
        />
      </div>
      <AreaChartSection class="tw-mt-3 tw-h-60 md:tw-col-span-1 lg:tw-col-span-4 lg:tw-ml-3 lg:tw-mt-0" />

      <BarChartSection class="md:tw-col-span-1 lg:tw-col-span-5" />
      <div class="tw-ml-3 md:tw-col-span-1 lg:tw-col-span-4">
        <D3ChartSection v-if="!noRecordFound" />
        <EmptyState
          v-else
          :header="t('dashboard.empty_header')"
          :description="t('dashboard.empty_description')"
          class="tw-h-full"
        />
      </div>

      <VerticalBarChartSection class="md:tw-col-span-1 lg:tw-col-span-5" />
      <div class="tw-ml-3 md:tw-col-span-1 lg:tw-col-span-4">
        <SankeyChartSection v-if="!noRecordFound" />
        <EmptyState
          v-else
          :header="t('dashboard.empty_header')"
          :description="t('dashboard.empty_description')"
          class="tw-h-full"
        />
      </div>

      <ProductFeatureUsage
        v-if="showHeatMap && _dashboardV2Store.dashboardFor"
        class="tw-mt-2 tw-flex-grow lg:tw-col-span-9"
        :propC2TypeOrSegmentType="_dashboardV2Store.dashboardFor.type"
        :propC2TypeIdOrSegmentId="_dashboardV2Store.dashboardFor.id"
      />

      <div class="tw-flex-grow lg:tw-col-span-9">
        <TableSection v-if="!noRecordFound" />
        <EmptyState
          v-else
          :header="t('dashboard.empty_table')"
          :description="t('dashboard.empty_description')"
          class="tw-h-full"
        />
      </div>
    </div>
  </q-page>
</template>
