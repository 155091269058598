/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateZapscaleToAppObjectsFieldsMappingDto } from '../model/';
// @ts-ignore
import { UpdateZapscaleToAppObjectsFieldsMappingDto } from '../model/';
// @ts-ignore
import { ZapscaleToAppObjectsFieldsMapping } from '../model/';
/**
 * ZapscaleToAppObjectsFieldsMappingsApi - axios parameter creator
 * @export
 */
export const ZapscaleToAppObjectsFieldsMappingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Zapscale To App Object Field Mapping
         * @param {CreateZapscaleToAppObjectsFieldsMappingDto} createZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerCreate: async (createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createZapscaleToAppObjectsFieldsMappingDto' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerCreate', 'createZapscaleToAppObjectsFieldsMappingDto', createZapscaleToAppObjectsFieldsMappingDto)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createZapscaleToAppObjectsFieldsMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Zapscale To App Object Field Mapping
         * @param {CreateZapscaleToAppObjectsFieldsMappingDto} createZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerCreateV2: async (createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createZapscaleToAppObjectsFieldsMappingDto' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerCreateV2', 'createZapscaleToAppObjectsFieldsMappingDto', createZapscaleToAppObjectsFieldsMappingDto)
            const localVarPath = `/api/v2/zapscale-to-app-objects-fields-mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createZapscaleToAppObjectsFieldsMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Zapscale to App Objects Fields Mapping
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Zapscale to App Objects Fields Mapping by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp: async (appName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp', 'appName', appName)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings/by-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appName !== undefined) {
                localVarQueryParameter['app_name'] = appName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all field mapping by app id and group by app object id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {boolean} [uniqueAppObjectId] Optional field
         * @param {string} [appObjectId] Optional field
         * @param {boolean} [isCustom] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam: async (appId: string, xTenantId?: string, uniqueAppObjectId?: boolean, appObjectId?: string, isCustom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam', 'appId', appId)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings/{app_id}/custom-kpi`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uniqueAppObjectId !== undefined) {
                localVarQueryParameter['unique_app_object_id'] = uniqueAppObjectId;
            }

            if (appObjectId !== undefined) {
                localVarQueryParameter['app_object_id'] = appObjectId;
            }

            if (isCustom !== undefined) {
                localVarQueryParameter['is_custom'] = isCustom;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all mappings with zs object name and app id
         * @param {string} appId Required field
         * @param {string} zsObjectName Required field
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName: async (appId: string, zsObjectName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName', 'appId', appId)
            // verify required parameter 'zsObjectName' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName', 'zsObjectName', zsObjectName)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings/by-object-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appId !== undefined) {
                localVarQueryParameter['app_id'] = appId;
            }

            if (zsObjectName !== undefined) {
                localVarQueryParameter['zs_object_name'] = zsObjectName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Zapscale To App Object Field Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerRemove', 'id', id)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerRestore: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerRestore', 'id', id)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID
         * @param {string} id 
         * @param {UpdateZapscaleToAppObjectsFieldsMappingDto} updateZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerUpdate: async (id: string, updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerUpdate', 'id', id)
            // verify required parameter 'updateZapscaleToAppObjectsFieldsMappingDto' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerUpdate', 'updateZapscaleToAppObjectsFieldsMappingDto', updateZapscaleToAppObjectsFieldsMappingDto)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateZapscaleToAppObjectsFieldsMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update optional app object mapping visibility
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility', 'appId', appId)
            const localVarPath = `/api/zapscale-to-app-objects-fields-mappings/{app_id}/optional-mapping-visibility`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID - V2 with sync settings
         * @param {string} id 
         * @param {UpdateZapscaleToAppObjectsFieldsMappingDto} updateZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerUpdateV2: async (id: string, updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerUpdateV2', 'id', id)
            // verify required parameter 'updateZapscaleToAppObjectsFieldsMappingDto' is not null or undefined
            assertParamExists('zapscaleToAppObjectsFieldsMappingsControllerUpdateV2', 'updateZapscaleToAppObjectsFieldsMappingDto', updateZapscaleToAppObjectsFieldsMappingDto)
            const localVarPath = `/api/v2/zapscale-to-app-objects-fields-mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateZapscaleToAppObjectsFieldsMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZapscaleToAppObjectsFieldsMappingsApi - functional programming interface
 * @export
 */
export const ZapscaleToAppObjectsFieldsMappingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZapscaleToAppObjectsFieldsMappingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Zapscale To App Object Field Mapping
         * @param {CreateZapscaleToAppObjectsFieldsMappingDto} createZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerCreate(createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleToAppObjectsFieldsMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerCreate(createZapscaleToAppObjectsFieldsMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Zapscale To App Object Field Mapping
         * @param {CreateZapscaleToAppObjectsFieldsMappingDto} createZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerCreateV2(createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleToAppObjectsFieldsMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerCreateV2(createZapscaleToAppObjectsFieldsMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Zapscale to App Objects Fields Mapping
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Zapscale to App Objects Fields Mapping by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp(appName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp(appName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all field mapping by app id and group by app object id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {boolean} [uniqueAppObjectId] Optional field
         * @param {string} [appObjectId] Optional field
         * @param {boolean} [isCustom] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam(appId: string, xTenantId?: string, uniqueAppObjectId?: boolean, appObjectId?: string, isCustom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam(appId, xTenantId, uniqueAppObjectId, appObjectId, isCustom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all mappings with zs object name and app id
         * @param {string} appId Required field
         * @param {string} zsObjectName Required field
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName(appId: string, zsObjectName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName(appId, zsObjectName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Zapscale To App Object Field Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleToAppObjectsFieldsMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerRestore(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleToAppObjectsFieldsMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerRestore(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID
         * @param {string} id 
         * @param {UpdateZapscaleToAppObjectsFieldsMappingDto} updateZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerUpdate(id: string, updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleToAppObjectsFieldsMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerUpdate(id, updateZapscaleToAppObjectsFieldsMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update optional app object mapping visibility
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID - V2 with sync settings
         * @param {string} id 
         * @param {UpdateZapscaleToAppObjectsFieldsMappingDto} updateZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleToAppObjectsFieldsMappingsControllerUpdateV2(id: string, updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleToAppObjectsFieldsMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleToAppObjectsFieldsMappingsControllerUpdateV2(id, updateZapscaleToAppObjectsFieldsMappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZapscaleToAppObjectsFieldsMappingsApi - factory interface
 * @export
 */
export const ZapscaleToAppObjectsFieldsMappingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZapscaleToAppObjectsFieldsMappingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Zapscale To App Object Field Mapping
         * @param {CreateZapscaleToAppObjectsFieldsMappingDto} createZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerCreate(createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: any): AxiosPromise<ZapscaleToAppObjectsFieldsMapping> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerCreate(createZapscaleToAppObjectsFieldsMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Zapscale To App Object Field Mapping
         * @param {CreateZapscaleToAppObjectsFieldsMappingDto} createZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerCreateV2(createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: any): AxiosPromise<ZapscaleToAppObjectsFieldsMapping> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerCreateV2(createZapscaleToAppObjectsFieldsMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Zapscale to App Objects Fields Mapping
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Zapscale to App Objects Fields Mapping by App
         * @param {string} appName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp(appName: string, xTenantId?: string, options?: any): AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp(appName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all field mapping by app id and group by app object id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {boolean} [uniqueAppObjectId] Optional field
         * @param {string} [appObjectId] Optional field
         * @param {boolean} [isCustom] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam(appId: string, xTenantId?: string, uniqueAppObjectId?: boolean, appObjectId?: string, isCustom?: boolean, options?: any): AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam(appId, xTenantId, uniqueAppObjectId, appObjectId, isCustom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all mappings with zs object name and app id
         * @param {string} appId Required field
         * @param {string} zsObjectName Required field
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName(appId: string, zsObjectName: string, xTenantId?: string, options?: any): AxiosPromise<Array<ZapscaleToAppObjectsFieldsMapping>> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName(appId, zsObjectName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Zapscale To App Object Field Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<ZapscaleToAppObjectsFieldsMapping> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerRestore(id: string, xTenantId?: string, options?: any): AxiosPromise<ZapscaleToAppObjectsFieldsMapping> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerRestore(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID
         * @param {string} id 
         * @param {UpdateZapscaleToAppObjectsFieldsMappingDto} updateZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerUpdate(id: string, updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: any): AxiosPromise<ZapscaleToAppObjectsFieldsMapping> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerUpdate(id, updateZapscaleToAppObjectsFieldsMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update optional app object mapping visibility
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility(appId: string, xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ZapscaleToAppObjectFieldMapping against ID - V2 with sync settings
         * @param {string} id 
         * @param {UpdateZapscaleToAppObjectsFieldsMappingDto} updateZapscaleToAppObjectsFieldsMappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleToAppObjectsFieldsMappingsControllerUpdateV2(id: string, updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto, xTenantId?: string, options?: any): AxiosPromise<ZapscaleToAppObjectsFieldsMapping> {
            return localVarFp.zapscaleToAppObjectsFieldsMappingsControllerUpdateV2(id, updateZapscaleToAppObjectsFieldsMappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerCreate operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateRequest {
    /**
     * 
     * @type {CreateZapscaleToAppObjectsFieldsMappingDto}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreate
     */
    readonly createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerCreateV2 operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateV2Request
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateV2Request {
    /**
     * 
     * @type {CreateZapscaleToAppObjectsFieldsMappingDto}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateV2
     */
    readonly createZapscaleToAppObjectsFieldsMappingDto: CreateZapscaleToAppObjectsFieldsMappingDto

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerFindAll operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByAppRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByAppRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByApp
     */
    readonly appName: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByApp
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParamRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParamRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam
     */
    readonly uniqueAppObjectId?: boolean

    /**
     * Optional field
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam
     */
    readonly appObjectId?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam
     */
    readonly isCustom?: boolean
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectNameRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectNameRequest {
    /**
     * Required field
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName
     */
    readonly appId: string

    /**
     * Required field
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName
     */
    readonly zsObjectName: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerRemove operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRemoveRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerRestore operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRestoreRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRestoreRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRestore
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRestore
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerUpdate operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateZapscaleToAppObjectsFieldsMappingDto}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdate
     */
    readonly updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibilityRequest
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibilityRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleToAppObjectsFieldsMappingsControllerUpdateV2 operation in ZapscaleToAppObjectsFieldsMappingsApi.
 * @export
 * @interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateV2Request
 */
export interface ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateV2Request {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateV2
     */
    readonly id: string

    /**
     * 
     * @type {UpdateZapscaleToAppObjectsFieldsMappingDto}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateV2
     */
    readonly updateZapscaleToAppObjectsFieldsMappingDto: UpdateZapscaleToAppObjectsFieldsMappingDto

    /**
     * 
     * @type {string}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateV2
     */
    readonly xTenantId?: string
}

/**
 * ZapscaleToAppObjectsFieldsMappingsApi - object-oriented interface
 * @export
 * @class ZapscaleToAppObjectsFieldsMappingsApi
 * @extends {BaseAPI}
 */
export class ZapscaleToAppObjectsFieldsMappingsApi extends BaseAPI {
    /**
     * 
     * @summary Create Zapscale To App Object Field Mapping
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerCreate(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerCreate(requestParameters.createZapscaleToAppObjectsFieldsMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Zapscale To App Object Field Mapping
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerCreateV2(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerCreateV2Request, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerCreateV2(requestParameters.createZapscaleToAppObjectsFieldsMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Zapscale to App Objects Fields Mapping
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerFindAll(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Zapscale to App Objects Fields Mapping by App
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByAppRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerFindAllByApp(requestParameters.appName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all field mapping by app id and group by app object id
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParamRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerFindAllByQueryParam(requestParameters.appId, requestParameters.xTenantId, requestParameters.uniqueAppObjectId, requestParameters.appObjectId, requestParameters.isCustom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all mappings with zs object name and app id
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectNameRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerFindAllByZsObjectName(requestParameters.appId, requestParameters.zsObjectName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Zapscale To App Object Field Mapping
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerRemove(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRemoveRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ZapscaleToAppObjectFieldMapping against ID
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRestoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerRestore(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerRestoreRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerRestore(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ZapscaleToAppObjectFieldMapping against ID
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerUpdate(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerUpdate(requestParameters.id, requestParameters.updateZapscaleToAppObjectsFieldsMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update optional app object mapping visibility
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibilityRequest, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerUpdateOptionalMappingVisibility(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ZapscaleToAppObjectFieldMapping against ID - V2 with sync settings
     * @param {ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleToAppObjectsFieldsMappingsApi
     */
    public zapscaleToAppObjectsFieldsMappingsControllerUpdateV2(requestParameters: ZapscaleToAppObjectsFieldsMappingsApiZapscaleToAppObjectsFieldsMappingsControllerUpdateV2Request, options?: AxiosRequestConfig) {
        return ZapscaleToAppObjectsFieldsMappingsApiFp(this.configuration).zapscaleToAppObjectsFieldsMappingsControllerUpdateV2(requestParameters.id, requestParameters.updateZapscaleToAppObjectsFieldsMappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
