import { defineStore } from 'pinia';
import { ref } from 'vue';
import { C2TypeHealthScore, DateTypeEnum } from '@/api/client';
import { c2TypeHealthScoreApi } from '@/api';

export const useC2TypeHealthScore = defineStore('c2TypeHealthScore', () => {
  /*Used in Customer Details section*/
  const isLoading = ref<boolean>(false);
  const c2TypeHealthScore = ref<C2TypeHealthScore | null>();

  /*Used in Dashboard*/
  const isLoadingListSameDay = ref<boolean>(false);
  const c2TypeHealthScoresSameDay = ref<C2TypeHealthScore[]>([]);

  /*Used in Dashboard and customer details section*/
  const isLoadingList = ref<boolean>(false);
  const c2TypeHealthScoreList = ref<C2TypeHealthScore[]>([]);

  async function fetchC2TypeHealthScore(c2TypeId?: string, date?: string, dateType?: DateTypeEnum) {
    c2TypeHealthScore.value = null;
    if (!dateType || !c2TypeId || !date) {
      return;
    }
    try {
      isLoading.value = true;
      const { data } = await c2TypeHealthScoreApi.c2TypeHealthScoreControllerFindOneByDate({
        c2TypeId,
        date,
        dateType,
      });
      c2TypeHealthScore.value = data;
    } catch (e) {
      console.error('c2TypeHealthscore', e);
    } finally {
      isLoading.value = false;
    }
  }

  //In case of failure or no data from server we need to clear the existing data
  async function fetchC2TypeHealthScoresSameDay(date?: string, dateType?: DateTypeEnum) {
    c2TypeHealthScoresSameDay.value = [];
    if (!date || !dateType) {
      return;
    }
    isLoadingListSameDay.value = true;
    try {
      const { data } = await c2TypeHealthScoreApi.c2TypeHealthScoreControllerFindAll({ date, dateType });
      c2TypeHealthScoresSameDay.value = data;
    } catch (e) {
      console.error('C2Type Health scores same day', e);
    } finally {
      isLoadingListSameDay.value = false;
    }
  }

  //In case of failure or no data from server we need to clear the existing data
  async function fetchC2TypeHealthScoreList(date?: string, dateType?: DateTypeEnum) {
    c2TypeHealthScoreList.value = [];
    if (!date || !dateType) {
      return;
    }
    try {
      isLoadingList.value = true;
      const { data } = await c2TypeHealthScoreApi.c2TypeHealthScoreControllerFindAllByDateRange({ date, dateType });
      c2TypeHealthScoreList.value = data;
    } catch (e) {
      console.error('c2TypeHealthScoreList', e);
    } finally {
      isLoadingList.value = false;
    }
  }

  return {
    isLoading,
    isLoadingList,
    isLoadingListSameDay,
    c2TypeHealthScore,
    c2TypeHealthScoreList,
    c2TypeHealthScoresSameDay,
    fetchC2TypeHealthScore,
    fetchC2TypeHealthScoreList,
    fetchC2TypeHealthScoresSameDay,
  };
});
