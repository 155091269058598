/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeatureTrackingFilterDto } from '../model/';
// @ts-ignore
import { PaginatedTicketTrackingLog } from '../model/';
// @ts-ignore
import { TicketTrackingFilterDto } from '../model/';
// @ts-ignore
import { TicketTrackingLog } from '../model/';
/**
 * TicketTrackingLogApi - axios parameter creator
 * @export
 */
export const TicketTrackingLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Ticketing Information
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerDownloadAllTicketingData: async (ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketTrackingFilterDto' is not null or undefined
            assertParamExists('ticketTrackingLogControllerDownloadAllTicketingData', 'ticketTrackingFilterDto', ticketTrackingFilterDto)
            const localVarPath = `/api/ticket-tracking-log/download-ticketing-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketTrackingFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all Feature withing last 90 days for current c2
         * @param {FeatureTrackingFilterDto} featureTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllFeatures: async (featureTrackingFilterDto: FeatureTrackingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureTrackingFilterDto' is not null or undefined
            assertParamExists('ticketTrackingLogControllerFindAllFeatures', 'featureTrackingFilterDto', featureTrackingFilterDto)
            const localVarPath = `/api/ticket-tracking-log/features-history-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureTrackingFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch feature requests against app bug id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllFeaturesAgainstId: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketTrackingLogControllerFindAllFeaturesAgainstId', 'id', id)
            const localVarPath = `/api/ticket-tracking-log/{id}/features`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all tickets within last 30 days for current c2
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllTickets: async (ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketTrackingFilterDto' is not null or undefined
            assertParamExists('ticketTrackingLogControllerFindAllTickets', 'ticketTrackingFilterDto', ticketTrackingFilterDto)
            const localVarPath = `/api/ticket-tracking-log/tickets-history-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketTrackingFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch tickets against app bug id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllTicketsAgainstId: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketTrackingLogControllerFindAllTicketsAgainstId', 'id', id)
            const localVarPath = `/api/ticket-tracking-log/{id}/tickets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketTrackingLogApi - functional programming interface
 * @export
 */
export const TicketTrackingLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketTrackingLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download Ticketing Information
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketTrackingLogControllerDownloadAllTicketingData(ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketTrackingLogControllerDownloadAllTicketingData(ticketTrackingFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all Feature withing last 90 days for current c2
         * @param {FeatureTrackingFilterDto} featureTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketTrackingLogControllerFindAllFeatures(featureTrackingFilterDto: FeatureTrackingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTicketTrackingLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketTrackingLogControllerFindAllFeatures(featureTrackingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch feature requests against app bug id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketTrackingLogControllerFindAllFeaturesAgainstId(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketTrackingLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketTrackingLogControllerFindAllFeaturesAgainstId(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all tickets within last 30 days for current c2
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketTrackingLogControllerFindAllTickets(ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTicketTrackingLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketTrackingLogControllerFindAllTickets(ticketTrackingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch tickets against app bug id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketTrackingLogControllerFindAllTicketsAgainstId(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketTrackingLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketTrackingLogControllerFindAllTicketsAgainstId(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketTrackingLogApi - factory interface
 * @export
 */
export const TicketTrackingLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketTrackingLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Download Ticketing Information
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerDownloadAllTicketingData(ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.ticketTrackingLogControllerDownloadAllTicketingData(ticketTrackingFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all Feature withing last 90 days for current c2
         * @param {FeatureTrackingFilterDto} featureTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllFeatures(featureTrackingFilterDto: FeatureTrackingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedTicketTrackingLog> {
            return localVarFp.ticketTrackingLogControllerFindAllFeatures(featureTrackingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch feature requests against app bug id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllFeaturesAgainstId(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<TicketTrackingLog>> {
            return localVarFp.ticketTrackingLogControllerFindAllFeaturesAgainstId(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all tickets within last 30 days for current c2
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllTickets(ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedTicketTrackingLog> {
            return localVarFp.ticketTrackingLogControllerFindAllTickets(ticketTrackingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch tickets against app bug id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketTrackingLogControllerFindAllTicketsAgainstId(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<TicketTrackingLog>> {
            return localVarFp.ticketTrackingLogControllerFindAllTicketsAgainstId(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for ticketTrackingLogControllerDownloadAllTicketingData operation in TicketTrackingLogApi.
 * @export
 * @interface TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingDataRequest
 */
export interface TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingDataRequest {
    /**
     * 
     * @type {TicketTrackingFilterDto}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingData
     */
    readonly ticketTrackingFilterDto: TicketTrackingFilterDto

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingData
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingData
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingData
     */
    readonly descending?: boolean
}

/**
 * Request parameters for ticketTrackingLogControllerFindAllFeatures operation in TicketTrackingLogApi.
 * @export
 * @interface TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesRequest
 */
export interface TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesRequest {
    /**
     * 
     * @type {FeatureTrackingFilterDto}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly featureTrackingFilterDto: FeatureTrackingFilterDto

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeatures
     */
    readonly after?: string
}

/**
 * Request parameters for ticketTrackingLogControllerFindAllFeaturesAgainstId operation in TicketTrackingLogApi.
 * @export
 * @interface TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesAgainstIdRequest
 */
export interface TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesAgainstIdRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesAgainstId
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesAgainstId
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for ticketTrackingLogControllerFindAllTickets operation in TicketTrackingLogApi.
 * @export
 * @interface TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsRequest
 */
export interface TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsRequest {
    /**
     * 
     * @type {TicketTrackingFilterDto}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly ticketTrackingFilterDto: TicketTrackingFilterDto

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTickets
     */
    readonly after?: string
}

/**
 * Request parameters for ticketTrackingLogControllerFindAllTicketsAgainstId operation in TicketTrackingLogApi.
 * @export
 * @interface TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsAgainstIdRequest
 */
export interface TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsAgainstIdRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsAgainstId
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsAgainstId
     */
    readonly xTenantId?: string
}

/**
 * TicketTrackingLogApi - object-oriented interface
 * @export
 * @class TicketTrackingLogApi
 * @extends {BaseAPI}
 */
export class TicketTrackingLogApi extends BaseAPI {
    /**
     * 
     * @summary Download Ticketing Information
     * @param {TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketTrackingLogApi
     */
    public ticketTrackingLogControllerDownloadAllTicketingData(requestParameters: TicketTrackingLogApiTicketTrackingLogControllerDownloadAllTicketingDataRequest, options?: AxiosRequestConfig) {
        return TicketTrackingLogApiFp(this.configuration).ticketTrackingLogControllerDownloadAllTicketingData(requestParameters.ticketTrackingFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all Feature withing last 90 days for current c2
     * @param {TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketTrackingLogApi
     */
    public ticketTrackingLogControllerFindAllFeatures(requestParameters: TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesRequest, options?: AxiosRequestConfig) {
        return TicketTrackingLogApiFp(this.configuration).ticketTrackingLogControllerFindAllFeatures(requestParameters.featureTrackingFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch feature requests against app bug id
     * @param {TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesAgainstIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketTrackingLogApi
     */
    public ticketTrackingLogControllerFindAllFeaturesAgainstId(requestParameters: TicketTrackingLogApiTicketTrackingLogControllerFindAllFeaturesAgainstIdRequest, options?: AxiosRequestConfig) {
        return TicketTrackingLogApiFp(this.configuration).ticketTrackingLogControllerFindAllFeaturesAgainstId(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all tickets within last 30 days for current c2
     * @param {TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketTrackingLogApi
     */
    public ticketTrackingLogControllerFindAllTickets(requestParameters: TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsRequest, options?: AxiosRequestConfig) {
        return TicketTrackingLogApiFp(this.configuration).ticketTrackingLogControllerFindAllTickets(requestParameters.ticketTrackingFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch tickets against app bug id
     * @param {TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsAgainstIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketTrackingLogApi
     */
    public ticketTrackingLogControllerFindAllTicketsAgainstId(requestParameters: TicketTrackingLogApiTicketTrackingLogControllerFindAllTicketsAgainstIdRequest, options?: AxiosRequestConfig) {
        return TicketTrackingLogApiFp(this.configuration).ticketTrackingLogControllerFindAllTicketsAgainstId(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
