<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import { MessageSchema } from '@/lib/i18n';
import { RouteName } from '@/router/route-name';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const route = useRoute();

const showFooter = computed(() => {
  const routeName = route?.name;
  switch (routeName) {
    case RouteName.Register:
    case RouteName.Login:
    case RouteName.Logout:
    case RouteName.VerifyEmail:
    case RouteName.ResetPasswordRequest:
    case RouteName.ResetPassword:
    case RouteName.AcceptInvitation:
    case RouteName.AcceptHelpRequest:
      return true;
    default:
      return false;
  }
});
</script>

<template>
  <q-layout view="hHh Lpr fFr">
    <app-header />

    <q-page-container>
      <slot />
    </q-page-container>

    <footer v-if="showFooter" class="tw-mt-10 tw-bg-primary-dark tw-shadow">
      <div
        class="tw-md:flex tw-md:items-center tw-md:justify-between tw-w-full tw-max-w-screen-xl tw-bg-primary-dark tw-px-8 tw-py-4"
      >
        <ul class="tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-text-sm tw-font-medium tw-text-light-shade-1">
          <li>
            <a
              href="https://www.zapscale.com/terms-and-conditions"
              target="_blank"
              class="hover:underline me-4 md:me-6"
              >{{ t('app_footer.terms_condition') }}</a
            >
          </li>
          <li>
            <a href="https://www.zapscale.com/privacy-policy" target="_blank" class="hover:underline me-4 md:me-6">
              {{ t('app_footer.privacy_policy') }}
            </a>
          </li>
          <li>
            <a href="https://www.zapscale.com/cookie-policy" target="_blank" class="hover:underline me-4 md:me-6">
              {{ t('app_footer.cookie_policy') }}
            </a>
          </li>
          <li>
            <a href="https://www.zapscale.com/compliance" target="_blank" class="hover:underline">
              {{ t('app_footer.compliance') }}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </q-layout>
</template>
