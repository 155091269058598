<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { useColorFormatter } from '@/composables/useColorFormatter';
import { phInfoBold, phMoney, phUser, phUsers } from '@/phosphor-icons';
import { useQuasar } from 'quasar';
import OverviewMoreDetails from '@/components/Dashboard/OverviewMoreDetails.vue';
import { useC1HealthScoreStore } from '@/stores/c1HealthScore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import LoadingIndicator from '../LoadingIndicator.vue';
import KpiDefinition from '../KpiDefinition.vue';

const $q = useQuasar();

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const _c1HealthScoreStore = useC1HealthScoreStore();
const { c1HealthScore, isLoading } = storeToRefs(_c1HealthScoreStore);

const { getCaretStyle, getCaretType, getColorCodeAgainstString } = useColorFormatter();
const { formatNumberRoundOfDecimal, formatAmount } = useAmountFormatter();

const zapscorePercentageChangeFromLast = computed(() => {
  const percentage = c1HealthScore.value?.czs_percentage_change_from_last;
  if (percentage === undefined || percentage === null) {
    return '';
  }
  return formatNumberRoundOfDecimal(Math.abs(percentage)) + '%';
});

const revenuePercentageChangeFromLast = computed(() => {
  const percentage = c1HealthScore.value?.total_acv_of_all_c2s_percentage_change_from_last;
  if (percentage === undefined || percentage === null) {
    return;
  }
  return formatNumberRoundOfDecimal(Math.abs(percentage)) + '%';
});

const c2sPercentageChangeFromLast = computed(() => {
  const percentage = c1HealthScore.value?.total_c2s_percent_change_from_last;
  if (percentage === undefined || percentage === null) {
    return '';
  }
  return formatNumberRoundOfDecimal(Math.abs(percentage)) + '%';
});

const u2sPercentageChangeFromLast = computed(() => {
  const percentage = c1HealthScore.value?.total_u2s_percent_change_from_last;
  if (percentage === undefined || percentage === null) {
    return '';
  }
  return formatNumberRoundOfDecimal(Math.abs(percentage)) + '%';
});

function onMoreDetailsClick() {
  $q.dialog({
    component: OverviewMoreDetails,
  }).onOk(() => {
    console.log('From dashboard details more');
  });
}
</script>

<template>
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-px-6 tw-shadow">
    <LoadingIndicator v-if="isLoading" class="tw-bg-light-shade-1" />
    <div class="tw-flex tw-items-center tw-justify-between tw-pt-4">
      <span class="tw-text-base tw-text-dark-shade-3">{{ t('overview') }}</span>
      <q-btn
        color="primary"
        class="tw-pt-0z tw-pr-0 tw-text-base tw-font-bold"
        :ripple="false"
        flat
        no-caps
        @click="onMoreDetailsClick"
        >More Details
      </q-btn>
    </div>

    <!-- #region Stats Grid -->
    <div class="tw-grid tw-grid-cols-4 tw-divide-gray-100 md:tw-divide-x">
      <!-- #region ZapScore -->
      <div class="tw-flex tw-flex-col">
        <div
          class="tw-mb-2 tw-mt-2 tw-text-[3.5rem] tw-font-semibold tw-leading-[4.5rem]"
          :class="getColorCodeAgainstString(c1HealthScore?.czs_color_code)"
        >
          {{ formatNumberRoundOfDecimal(c1HealthScore?.c1_zapscore) }}
        </div>
        <div class="xtw-ml-1 xtw-mt-1 tw-flex tw-flex-row tw-items-center">
          <q-icon
            :name="getCaretType(c1HealthScore?.czs_percentage_change_from_last)"
            class="tw-text-[0.625rem]"
            :class="getCaretStyle(c1HealthScore?.czs_percentage_change_from_last)"
          />
          <span class="tw-ml-0.5 tw-text-sm tw-font-semibold tw-text-dark-shade-1">{{
            zapscorePercentageChangeFromLast
          }}</span>
        </div>
        <div class="xtw-ml-1 xtw-mt-1 tw-flex tw-text-sm tw-font-semibold tw-text-primary-dark">
          ZapScore
          <div class="tw-ml-1 tw-cursor-pointer">
            <q-icon :name="phInfoBold" class="tw-text-primary-dark" />
            <q-menu ref="kpi-range-menu" transition-show="fade" transition-hide="fade" transition-duration="300">
              <KpiDefinition :kpi-id="c1HealthScore?.zs_kpi_id" :kpi-for="'business_kpi'" />
            </q-menu>
          </div>
        </div>
      </div>
      <!-- #endregion ZapScore -->

      <!-- #region Annual Revenue -->
      <div class="tw-flex tw-flex-row tw-justify-center">
        <div class="tw-flex tw-flex-col">
          <q-icon :name="phMoney" class="tw-mb-2 tw-text-2xl" />
          <div class="tw-mb-1 tw-text-[2.5rem] tw-font-semibold tw-leading-[3.25rem] tw-text-dark-shade-2">
            {{ formatAmount(c1HealthScore?.total_acv_of_all_c2s) }}
          </div>
          <div class="xtw-ml-1 xtw-mt-1 tw-flex tw-flex-row tw-items-center">
            <q-icon
              class="tw-mr-0.5 tw-text-[0.625rem]"
              :name="getCaretType(c1HealthScore?.total_acv_of_all_c2s_percentage_change_from_last)"
              :class="getCaretStyle(c1HealthScore?.total_acv_of_all_c2s_percentage_change_from_last)"
            />
            <span class="tw-text-sm tw-font-semibold tw-text-dark-shade-1">{{ revenuePercentageChangeFromLast }}</span>
          </div>
          <div class="xtw-ml-1 xtw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3">Annual Revenue</div>
        </div>
      </div>
      <!-- #endregion Annual Revenue -->

      <!-- #region Customers -->
      <div class="tw-flex tw-flex-row tw-justify-center">
        <div class="tw-flex tw-flex-col">
          <q-icon :name="phUser" class="tw-mb-2 tw-text-2xl" />
          <div class="tw-mb-1 tw-text-[2.5rem] tw-font-semibold tw-leading-[3.25rem] tw-text-dark-shade-2">
            {{ c1HealthScore?.total_c2s }}
          </div>
          <div class="xtw-ml-1 xtw-mt-1 tw-flex tw-flex-row tw-items-center">
            <q-icon
              class="tw-mr-0.5 tw-text-[0.625rem]"
              :name="getCaretType(c1HealthScore?.total_c2s_percent_change_from_last)"
              :class="getCaretStyle(c1HealthScore?.total_c2s_percent_change_from_last)"
            />
            <span class="tw-text-sm tw-font-semibold tw-text-dark-shade-1">{{ c2sPercentageChangeFromLast }}</span>
          </div>
          <div class="xtw-ml-1 xtw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3">Customers</div>
        </div>
      </div>
      <!-- #endregion Customers -->

      <!-- #region Users -->
      <div class="tw-flex tw-flex-row tw-justify-center">
        <div class="tw-flex tw-flex-col">
          <q-icon :name="phUsers" class="tw-mb-2 tw-text-2xl" />
          <div class="tw-mb-1 tw-text-[2.5rem] tw-font-semibold tw-leading-[3.25rem] tw-text-dark-shade-2">
            {{ c1HealthScore?.total_u2s }}
          </div>
          <div class="xtw-ml-1 xtw-mt-1 tw-flex tw-flex-row tw-items-center">
            <q-icon
              class="tw-mr-0.5 tw-text-[0.625rem]"
              :class="getCaretStyle(c1HealthScore?.total_u2s_percent_change_from_last)"
              :name="getCaretType(c1HealthScore?.total_u2s_percent_change_from_last)"
            />
            <span class="tw-text-sm tw-font-semibold tw-text-dark-shade-1">{{ u2sPercentageChangeFromLast }}</span>
          </div>
          <div class="xtw-ml-1 xtw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3">Users</div>
        </div>
      </div>
      <!-- #endregion Users -->
    </div>
    <!-- #endregion Stats Grid -->
  </div>
</template>

<style scoped lang="postcss">
:deep(.q-btn--flat.q-hoverable:hover > .q-focus-helper) {
  background: transparent;
}
</style>
