/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateU2PersonaDto } from '../model/';
// @ts-ignore
import { U2Persona } from '../model/';
/**
 * U2PersonaApi - axios parameter creator
 * @export
 */
export const U2PersonaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create u2 persona
         * @param {CreateU2PersonaDto} createU2PersonaDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2PersonaControllerCreateU2Persona: async (createU2PersonaDto: CreateU2PersonaDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createU2PersonaDto' is not null or undefined
            assertParamExists('u2PersonaControllerCreateU2Persona', 'createU2PersonaDto', createU2PersonaDto)
            const localVarPath = `/api/u2-persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createU2PersonaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all u2 personas
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2PersonaControllerFindAllU2Personas: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u2-persona/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find u2 persona by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2PersonaControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2PersonaControllerFindOne', 'id', id)
            const localVarPath = `/api/u2-persona/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2PersonaApi - functional programming interface
 * @export
 */
export const U2PersonaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2PersonaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create u2 persona
         * @param {CreateU2PersonaDto} createU2PersonaDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2PersonaControllerCreateU2Persona(createU2PersonaDto: CreateU2PersonaDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2Persona>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2PersonaControllerCreateU2Persona(createU2PersonaDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all u2 personas
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2PersonaControllerFindAllU2Personas(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2Persona>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2PersonaControllerFindAllU2Personas(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find u2 persona by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2PersonaControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2Persona>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2PersonaControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2PersonaApi - factory interface
 * @export
 */
export const U2PersonaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2PersonaApiFp(configuration)
    return {
        /**
         * 
         * @summary Create u2 persona
         * @param {CreateU2PersonaDto} createU2PersonaDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2PersonaControllerCreateU2Persona(createU2PersonaDto: CreateU2PersonaDto, xTenantId?: string, options?: any): AxiosPromise<Array<U2Persona>> {
            return localVarFp.u2PersonaControllerCreateU2Persona(createU2PersonaDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all u2 personas
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2PersonaControllerFindAllU2Personas(xTenantId?: string, options?: any): AxiosPromise<Array<U2Persona>> {
            return localVarFp.u2PersonaControllerFindAllU2Personas(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find u2 persona by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2PersonaControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<U2Persona> {
            return localVarFp.u2PersonaControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2PersonaControllerCreateU2Persona operation in U2PersonaApi.
 * @export
 * @interface U2PersonaApiU2PersonaControllerCreateU2PersonaRequest
 */
export interface U2PersonaApiU2PersonaControllerCreateU2PersonaRequest {
    /**
     * 
     * @type {CreateU2PersonaDto}
     * @memberof U2PersonaApiU2PersonaControllerCreateU2Persona
     */
    readonly createU2PersonaDto: CreateU2PersonaDto

    /**
     * 
     * @type {string}
     * @memberof U2PersonaApiU2PersonaControllerCreateU2Persona
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2PersonaControllerFindAllU2Personas operation in U2PersonaApi.
 * @export
 * @interface U2PersonaApiU2PersonaControllerFindAllU2PersonasRequest
 */
export interface U2PersonaApiU2PersonaControllerFindAllU2PersonasRequest {
    /**
     * 
     * @type {string}
     * @memberof U2PersonaApiU2PersonaControllerFindAllU2Personas
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2PersonaControllerFindOne operation in U2PersonaApi.
 * @export
 * @interface U2PersonaApiU2PersonaControllerFindOneRequest
 */
export interface U2PersonaApiU2PersonaControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof U2PersonaApiU2PersonaControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2PersonaApiU2PersonaControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * U2PersonaApi - object-oriented interface
 * @export
 * @class U2PersonaApi
 * @extends {BaseAPI}
 */
export class U2PersonaApi extends BaseAPI {
    /**
     * 
     * @summary Create u2 persona
     * @param {U2PersonaApiU2PersonaControllerCreateU2PersonaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2PersonaApi
     */
    public u2PersonaControllerCreateU2Persona(requestParameters: U2PersonaApiU2PersonaControllerCreateU2PersonaRequest, options?: AxiosRequestConfig) {
        return U2PersonaApiFp(this.configuration).u2PersonaControllerCreateU2Persona(requestParameters.createU2PersonaDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all u2 personas
     * @param {U2PersonaApiU2PersonaControllerFindAllU2PersonasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2PersonaApi
     */
    public u2PersonaControllerFindAllU2Personas(requestParameters: U2PersonaApiU2PersonaControllerFindAllU2PersonasRequest = {}, options?: AxiosRequestConfig) {
        return U2PersonaApiFp(this.configuration).u2PersonaControllerFindAllU2Personas(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find u2 persona by id
     * @param {U2PersonaApiU2PersonaControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2PersonaApi
     */
    public u2PersonaControllerFindOne(requestParameters: U2PersonaApiU2PersonaControllerFindOneRequest, options?: AxiosRequestConfig) {
        return U2PersonaApiFp(this.configuration).u2PersonaControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
