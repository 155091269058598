import { authApi } from '@/api';
import { MessageSchema } from '@/lib/i18n';
import { useAccountStore } from '@/stores/account';
import { useProductStore } from '@/stores/product';
import { useU1RoleStore } from '@/stores/u1Role';
import { useStorage } from '@vueuse/core';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';
import { RouteLocation } from 'vue-router';

enum AuthChannelMessage {
  sign_out = 'sign_out',
}

let authChannel: BroadcastChannel;

export const useAuth = () => {
  const { t } = useI18n<{ message: MessageSchema }>({
    useScope: 'global',
  });

  if (authChannel == null) {
    authChannel = new BroadcastChannel('zs-auth-channel');
    authChannel.addEventListener('message', async (ev) => {
      if (ev.data === AuthChannelMessage.sign_out) {
        alert(t('auth.signed_out_from_another_tab'));
      }
    });
  }

  const $q = useQuasar();
  const returnTo = useStorage<string>('zs.return_to', null, sessionStorage);
  const accountStore = useAccountStore();
  const u1RoleStore = useU1RoleStore();
  const productStore = useProductStore();

  const isAuthenticated = useStorage<boolean>('zs.is.authenticated', null, localStorage);

  const authGuard = async (to: RouteLocation) => {
    returnTo.value = to.fullPath;
    try {
      if (!accountStore.account) {
        $q.loading.show();
        await accountStore.fetchAccount();
      }

      isAuthenticated.value = true;

      if (!accountStore.memberships.length) {
        $q.loading.show();
        await accountStore.fetchMemberships();
        const activeC1Id = accountStore.memberships.find((m) => m.u1_master_id === accountStore.account?.id)
          ?.c1_master_id as string;
        await accountStore.fetchActiveC1Master(activeC1Id);
        await accountStore.fetchActiveU1();
      }

      if (!u1RoleStore.u1Roles.length) {
        await u1RoleStore.fetchU1Roles();
      }

      if (!productStore.products.length) {
        await productStore.fetchProducts();
      }

      // if (import.meta.env.VITE_FF_CAMPAIGNS_ENABLED === '1') {
      //   emailQueueApi.emailQueueControllerFindAndUpdateStatisticsByXMessageId();
      //   emailQueueLogsApi.emailQueueLogsControllerFindAndUpdateDetailedActivityByMessageId();
      // }

      // TODO: land on onboarding if incomplete
      // if (!accountStore.activeC1.onboarding_completed_at && !to.matched.some((r) => r.name === 'Onboarding')) {
      //   router.replace({ name: RouteName.Onboarding });
      // }
      returnTo.value = null;
    } finally {
      $q.loading.hide();
    }
  };

  const logout = async () => {
    isAuthenticated.value = null;
    await authApi.authControllerLogout();
    postMessage(AuthChannelMessage.sign_out);
    window.location.replace(`${window.location.origin}/logout`);
  };

  return {
    authGuard,
    isAuthenticated,
    logout,
    returnTo,
  };
};
