import { C1HealthScore, C2HealthScore, DateTypeEnum, KpiNameEnum } from '@/api/client';
import { ChartData } from 'chart.js';
import { useDateStore } from '@/stores/date';
import { LineChartType } from '@/types/linechart.type';
import { useC2TypeStore } from '@/stores/c2Type';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { storeToRefs } from 'pinia';
import { addDays, addMonths, addQuarters, addWeeks, addYears, isSunday, parseJSON } from 'date-fns';

export const useCustomerTypeTrendChart = () => {
  const filterOptions = [
    {
      label: 'ZapScore',
      value: 'zapscore',
    },
    {
      label: 'Usage Success Score',
      value: 'usage_success_score',
    },
    {
      label: 'Outcome Success Score',
      value: 'outcome_success_score',
    },
    {
      label: 'Peripheral Success Score',
      value: 'peripheral_success_score',
    },
    {
      label: 'Upsell Success Score',
      value: 'upsell_success_score',
    },
    {
      label: 'CS Handfeel Score',
      value: 'cs_handfeel_score',
    },
  ];

  const customerLineChartData = [
    {
      label: 'Overall Score',
      data: [] as number[],
      backgroundColor: '#006DA3',
      borderColor: '#006DA3',
      pointRadius: 0,
    },
    {
      label: 'Trial',
      data: [] as number[],
      backgroundColor: '#00A2FF',
      borderColor: '#00A2FF',
      pointRadius: 0,
    },
    {
      label: 'Onboarding',
      data: [] as number[],
      backgroundColor: '#8670FF',
      borderColor: '#8670FF',
      pointRadius: 0,
    },
    {
      label: 'Steady',
      data: [] as number[],
      backgroundColor: '#B7C634',
      borderColor: '#B7C634',
      pointRadius: 0,
    },
  ];

  const dateStore = useDateStore();
  const { dateInfo } = storeToRefs(dateStore);

  const c2TypeStore = useC2TypeStore();
  const numberFormatter = useAmountFormatter();

  function getTotalScoreSameDay(c2HealthScore: C2HealthScore[], type: string) {
    if (c2HealthScore.length <= 0) {
      return 0;
    }
    type C2HealthScoreObjectKey = keyof (typeof c2HealthScore)[0];
    const kpiPercentile = `${type}_percentile` as C2HealthScoreObjectKey;
    const total = c2HealthScore
      .map((x) => x[kpiPercentile] as number)
      .reduce((total, value) => total + (value || 0), 0);
    return total || 0;
  }

  function populateC1HealthScoreCustomerTrendChart(c1HealthScores: C1HealthScore[], filterType?: string) {
    const listOfLabel: string[] = [];
    if (c1HealthScores.length <= 0 || !filterType) {
      return {
        labels: listOfLabel,
        datasets: [],
      };
    }
    const c1HealthScoreList = [...c1HealthScores];
    // Add virtual first element when the c1HealthScoreList's length is 1
    if (c1HealthScoreList.length === 1) {
      const lastElement = c1HealthScoreList[0];
      const virtualElement = {} as C1HealthScore;
      switch (dateInfo.value?.type) {
        case DateTypeEnum.Week:
          virtualElement.date = addWeeks(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Month:
          virtualElement.date = addMonths(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Quarter:
          virtualElement.date = addQuarters(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Year:
          virtualElement.date = addYears(parseJSON(lastElement.date), -1).toISOString();
          break;
        default:
          {
            // If previous day is Sunday, set Friday as previous day
            let previousDay = addDays(parseJSON(lastElement.date), -1);
            if (isSunday(previousDay)) {
              previousDay = addDays(previousDay, -2);
            }
            virtualElement.date = previousDay.toISOString();
          }

          break;
      }
      c1HealthScoreList.unshift(virtualElement);
    }

    const dateList = [...new Set(c1HealthScoreList.map((x) => x.date))].sort();
    const data: number[] = [];
    for (let i = 0; i < dateList.length; i++) {
      listOfLabel.push(dateStore.getDateLabel(dateList[i]));
      const c1HealthScore = c1HealthScoreList.find((x) => x.date === dateList[i]);
      switch (filterType.toLowerCase()) {
        case KpiNameEnum.Uss:
          data.push(c1HealthScore?.usage_success_score || 0);
          break;
        case KpiNameEnum.Pvps:
          data.push(c1HealthScore?.page_visit_percentage_score || 0);
          break;
        case KpiNameEnum.Fups:
          data.push(c1HealthScore?.feature_use_percentage_score || 0);
          break;
        case KpiNameEnum.Pvvcs:
          data.push(c1HealthScore?.page_visits_volume_consistency_score || 0);
          break;
        case KpiNameEnum.Stvcs:
          data.push(c1HealthScore?.session_time_volume_consistency_score || 0);
          break;
        case KpiNameEnum.Oss:
          data.push(c1HealthScore?.outcome_success_score || 0);
          break;
        case KpiNameEnum.Pss:
          data.push(c1HealthScore?.peripheral_success_score || 0);
          break;
        case KpiNameEnum.Bts:
          data.push(c1HealthScore?.bug_tracking_score || 0);
          break;
        case KpiNameEnum.Bls:
          data.push(c1HealthScore?.bug_log_score || 0);
          break;
        case KpiNameEnum.Ulpb:
          data.push(c1HealthScore?.hundred_minus_u2_logging_percentage_bugs || 0);
          break;
        case KpiNameEnum.P1pb:
          data.push(c1HealthScore?.hundred_minus_p1_percentage_bugs || 0);
          break;
        case KpiNameEnum.Brs:
          data.push(c1HealthScore?.bug_resolution_score || 0);
          break;
        case KpiNameEnum.Frs:
          data.push(c1HealthScore?.feature_request_score || 0);
          break;
        case KpiNameEnum.Frls:
          data.push(c1HealthScore?.feature_requests_log_score || 0);
          break;
        case KpiNameEnum.Pfrds:
          data.push(c1HealthScore?.percentage_feature_requests_delivered_score || 0);
          break;
        case KpiNameEnum.Pas:
          data.push(c1HealthScore?.payment_app_score || 0);
          break;
        case KpiNameEnum.Pfps:
          data.push(c1HealthScore?.percent_full_payment_score || 0);
          break;
        case KpiNameEnum.Pdcs:
          data.push(c1HealthScore?.percent_dues_cleared_score || 0);
          break;
        case KpiNameEnum.Ppos:
          data.push(c1HealthScore?.percent_payments_overdue_score || 0);
          break;
        case KpiNameEnum.Ccs:
          data.push(c1HealthScore?.customer_communication_score || 0);
          break;
        case KpiNameEnum.Cfs:
          data.push(c1HealthScore?.communication_frequency_score || 0);
          break;
        case KpiNameEnum.Dslcwc:
          data.push(c1HealthScore?.days_since_last_communication || 0);
          break;
        case KpiNameEnum.Upss:
          data.push(c1HealthScore?.upsell_success_score || 0);
          break;
        case KpiNameEnum.Uurs:
          data.push(c1HealthScore?.user_upsell_readiness_score || 0);
          break;
        case KpiNameEnum.Puers:
          data.push(c1HealthScore?.percent_user_enttilement_reached_score || 0);
          break;
        case KpiNameEnum.Wmglqp:
          data.push(c1HealthScore?.four_week_percent_user_growth_score || 0);
          break;
        case KpiNameEnum.Uuts:
          data.push(c1HealthScore?.upsell_user_tailwind_score || 0);
          break;
        case KpiNameEnum.Pdmghs:
          data.push(c1HealthScore?.percent_decision_makers_in_good_health_score || 0);
          break;
        case KpiNameEnum.Pighs:
          data.push(c1HealthScore?.percent_influencers_in_good_health_score || 0);
          break;
        case KpiNameEnum.Hfs:
          data.push(c1HealthScore?.handfeel_score || 0);
          break;
        default:
          data.push(c1HealthScore?.c1_zapscore || 0);
          break;
      }
    }
    const chartColor = LineChartType.OVERALL_SCORE.color;
    const c1TypeTrend = [];
    c1TypeTrend.push({
      label: 'Overall Score',
      data: data,
      fill: true,
      backgroundColor: chartColor,
      borderColor: chartColor,
      pointRadius: 0,
      tension: 0.1,
    });
    return {
      labels: [...listOfLabel],
      datasets: [...c1TypeTrend],
    } as ChartData<'line'>;
  }

  function populateLineChartDataForC2HealthScore(
    c2HealthScoreList: C2HealthScore[],
    filterType?: string,
    c2TypeId?: string | undefined,
  ) {
    const listOfLabel: string[] = [];
    if (c2HealthScoreList.length <= 0 || !filterType) {
      return {
        labels: listOfLabel,
        datasets: [],
      };
    }

    const c2HealthScores = [...c2HealthScoreList];

    // Add virtual first element when the c2HealthScoreList's length is 1
    if (c2HealthScores.length === 1) {
      const lastElement = c2HealthScores[0];
      const virtualElement = {} as C2HealthScore;
      switch (dateInfo.value?.type) {
        case DateTypeEnum.Week:
          virtualElement.date = addWeeks(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Month:
          virtualElement.date = addMonths(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Quarter:
          virtualElement.date = addQuarters(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Year:
          virtualElement.date = addYears(parseJSON(lastElement.date), -1).toISOString();
          break;
        default:
          {
            // If previous day is Sunday, set Friday as previous day
            let previousDay = addDays(parseJSON(lastElement.date), -1);
            if (isSunday(previousDay)) {
              previousDay = addDays(previousDay, -2);
            }
            virtualElement.date = previousDay.toISOString();
          }
          break;
      }
      c2HealthScores.unshift(virtualElement);
    }

    const dateList = [...new Set(c2HealthScores.map((x) => x.date))].sort();

    const dataItems: number[] = [];
    for (let i = 0; i < dateList.length; i++) {
      listOfLabel.push(dateStore.getDateLabel(dateList[i]));
      // const scoreData = c2HealthScores.filter((x) => x.date === dateList[i] && x.c2_type_id === c2TypeId);
      const scoreData = c2HealthScores.filter((x) => x.date === dateList[i]);
      const sameDayScore = getTotalScoreSameDay(scoreData, filterType);
      dataItems.push(+numberFormatter.formatNumberRoundOfDecimal(sameDayScore));
    }

    const currentC2Type = c2TypeStore.c2Types.find((x) => x.id === c2TypeId);

    if (!currentC2Type) {
      return {
        labels: [],
        datasets: [],
      } as ChartData<'line'>;
    }
    const chartColor = getChartColor(currentC2Type?.name);

    const customerTypeLineChartData = [];

    customerTypeLineChartData.push({
      label: currentC2Type.display_name,
      data: dataItems,
      fill: true,
      backgroundColor: chartColor,
      borderColor: chartColor,
      pointRadius: 0,
      tension: 0.1,
    });
    return {
      labels: [...listOfLabel],
      datasets: [...customerTypeLineChartData],
    } as ChartData<'line'>;
  }

  function getChartColor(c2TypeName: string): string {
    switch (c2TypeName.toLowerCase()) {
      case LineChartType.TRIAL.toString():
        return LineChartType.TRIAL.color;
      case LineChartType.ONBOARDING.toString():
        return LineChartType.ONBOARDING.color;
      case LineChartType.STEADY.toString():
        return LineChartType.STEADY.color;
      default:
        return LineChartType.CUSTOM_C2_TYPE.color;
    }
  }

  function populateLineChartDataForC2HealthScoreNew(
    c2HealthScoreList: C2HealthScore[],
    fieldName: string,
    isCustom?: boolean | undefined,
    appTypeId?: string | undefined,
    c2TypeId?: string | undefined,
  ) {
    const listOfLabel: string[] = [];
    if (c2HealthScoreList.length === 0) {
      return {
        labels: listOfLabel,
        datasets: [],
      };
    }

    const c2HealthScores = [...c2HealthScoreList];

    // Add virtual first element when the c2HealthScoreList's length is 1
    if (c2HealthScores.length === 1) {
      const lastElement = c2HealthScores[0];
      const virtualElement = {} as C2HealthScore;
      switch (dateInfo.value?.type) {
        case DateTypeEnum.Week:
          virtualElement.date = addWeeks(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Month:
          virtualElement.date = addMonths(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Quarter:
          virtualElement.date = addQuarters(parseJSON(lastElement.date), -1).toISOString();
          break;
        case DateTypeEnum.Year:
          virtualElement.date = addYears(parseJSON(lastElement.date), -1).toISOString();
          break;
        default:
          {
            // If previous day is Sunday, set Friday as previous day
            let previousDay = addDays(parseJSON(lastElement.date), -1);
            if (isSunday(previousDay)) {
              previousDay = addDays(previousDay, -2);
            }
            virtualElement.date = previousDay.toISOString();
          }
          break;
      }
      c2HealthScores.unshift(virtualElement);
    }

    const dateList = [...new Set(c2HealthScores.map((x) => x.date))].sort();

    const dataItems: number[] = [];
    for (let i = 0; i < dateList.length; i++) {
      listOfLabel.push(dateStore.getDateLabel(dateList[i]));
      const c2HealthScore = c2HealthScoreList.find((x) => x.date === dateList[i]) as C2HealthScore;
      if (c2HealthScore == null) {
        dataItems.push(0);
        continue;
      }
      type FieldKey = keyof typeof c2HealthScore;
      const field = fieldName as FieldKey;
      if (fieldName === 'Unresolved tickets') {
        const totalTickets = (c2HealthScore?.total_no_of_bugs || 0) as number;
        const resolvedTickets = (c2HealthScore?.no_of_bugs_resolved || 0) as number;
        const unresolvedTickets = totalTickets - resolvedTickets;
        dataItems.push(unresolvedTickets);
      } else if (fieldName === 'Unresolved features') {
        const totalFeatures = (c2HealthScore?.no_of_feature_requests || 0) as number;
        const resolvedFeatures = (c2HealthScore?.no_of_features_resolved || 0) as number;
        const unresolvedFeatures = totalFeatures - resolvedFeatures;
        dataItems.push(unresolvedFeatures);
      } else if (fieldName === 'stvcs_total_sessions_time') {
        const sessionTimeInMinutes = numberFormatter.formatNumberRoundOfDecimal(
          numberFormatter.formatSecondsToMins(c2HealthScore.stvcs_total_sessions_time),
        );
        dataItems.push((sessionTimeInMinutes as unknown as number) || 0);
      } else if (isCustom) {
        type C2HealthScoreObjectKey = keyof typeof c2HealthScore;
        const customFields = 'custom_fields' as C2HealthScoreObjectKey;
        const result = c2HealthScore[customFields] as unknown as Array<{
          name: string;
          value: string | number;
          source: string;
          isVisible: boolean;
          isCustom: boolean;
          label: string;
          app_type_id: string;
        }>;
        if (result == null) {
          dataItems.push(0);
          continue;
        }
        if (appTypeId === '') {
          const custom = result.find((x) => x.name === fieldName);
          dataItems.push((custom?.value as number) || 0);
        } else {
          const custom = result.find((x) => x.name === fieldName && x.app_type_id === appTypeId);
          dataItems.push((custom?.value as number) || 0);
        }
      } else {
        dataItems.push((c2HealthScore[field] || 0) as number);
      }
    }

    const currentC2Type = c2TypeStore.c2Types.find((x) => x.id === c2TypeId);

    if (!currentC2Type) {
      return {
        labels: [],
        datasets: [],
      } as ChartData<'line'>;
    }
    const chartColor = getChartColor(currentC2Type?.name);

    const customerTypeLineChartData = [];

    customerTypeLineChartData.push({
      label: currentC2Type.display_name,
      data: dataItems,
      fill: true,
      backgroundColor: chartColor,
      borderColor: chartColor,
      pointRadius: 0,
      tension: 0.1,
    });
    return {
      labels: [...listOfLabel],
      datasets: [...customerTypeLineChartData],
    } as ChartData<'line'>;
  }

  return {
    filterOptions,
    customerLineChartData,
    populateLineChartDataForC2HealthScore,
    populateC1HealthScoreCustomerTrendChart,
    populateLineChartDataForC2HealthScoreNew,
  };
};
