/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum KpiNameEnum {
    Zs = 'zs',
    Uss = 'uss',
    Oss = 'oss',
    Pss = 'pss',
    Upss = 'upss',
    Hfs = 'hfs',
    Bts = 'bts',
    Pas = 'pas',
    Frs = 'frs',
    Ccs = 'ccs',
    Uurs = 'uurs',
    Uuts = 'uuts',
    Pvvcs = 'pvvcs',
    Stvcs = 'stvcs',
    Pvps = 'pvps',
    Fups = 'fups',
    Bls = 'bls',
    Brs = 'brs',
    P1pb = 'p1pb',
    Ulpb = 'ulpb',
    Pdcs = 'pdcs',
    Pfps = 'pfps',
    Ppos = 'ppos',
    Cfs = 'cfs',
    Dslcwc = 'dslcwc',
    IntercomCfs = 'intercom_cfs',
    IntercomDslcwc = 'intercom_dslcwc',
    SlackCfs = 'slack_cfs',
    SlackDslcwc = 'slack_dslcwc',
    Frls = 'frls',
    Pfrds = 'pfrds',
    Puers = 'puers',
    Wmglqp = 'wmglqp',
    Pdmghs = 'pdmghs',
    Pighs = 'pighs',
    Nps = 'nps',
    Ss = 'ss',
    Cs = 'cs',
    Nls = 'nls',
    Npvs = 'npvs',
    Ttps = 'ttps',
    F1us = 'f1us',
    F2us = 'f2us',
    F3us = 'f3us',
    F4us = 'f4us',
    F5us = 'f5us',
    Usgurs = 'usgurs',
    Hpr = 'hpr',
    Ppots = 'ppots',
    Usglerp = 'usglerp',
    Wuglqp = 'wuglqp',
    Dmihp = 'dmihp',
    Iihp = 'iihp'
}


