/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2SegmentShare } from '../model/';
// @ts-ignore
import { CreateC2SegmentShareDto } from '../model/';
/**
 * C2SegmentShareApi - axios parameter creator
 * @export
 */
export const C2SegmentShareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create segment share entry
         * @param {CreateC2SegmentShareDto} createC2SegmentShareDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerCreateC2SegmentShare: async (createC2SegmentShareDto: CreateC2SegmentShareDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC2SegmentShareDto' is not null or undefined
            assertParamExists('c2SegmentShareControllerCreateC2SegmentShare', 'createC2SegmentShareDto', createC2SegmentShareDto)
            const localVarPath = `/api/c2-segment-share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2SegmentShareDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all c2 segment share entries according u1 access right
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-segment-share/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all c2 segment share entries shared by current u1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerFindAllSharedByCurrentU1: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-segment-share/shared-by`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find c2 segment share entry using segment id and shared by u1 id
         * @param {string} segmentId 
         * @param {string} sharedByU1Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerFindOne: async (segmentId: string, sharedByU1Id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('c2SegmentShareControllerFindOne', 'segmentId', segmentId)
            // verify required parameter 'sharedByU1Id' is not null or undefined
            assertParamExists('c2SegmentShareControllerFindOne', 'sharedByU1Id', sharedByU1Id)
            const localVarPath = `/api/c2-segment-share/{segmentId}/{sharedByU1Id}/find-one`
                .replace(`{${"segmentId"}}`, encodeURIComponent(String(segmentId)))
                .replace(`{${"sharedByU1Id"}}`, encodeURIComponent(String(sharedByU1Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update segment share entry by id
         * @param {string} c2SegmentShareId 
         * @param {CreateC2SegmentShareDto} createC2SegmentShareDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerUpdateC2SegmentShare: async (c2SegmentShareId: string, createC2SegmentShareDto: CreateC2SegmentShareDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2SegmentShareId' is not null or undefined
            assertParamExists('c2SegmentShareControllerUpdateC2SegmentShare', 'c2SegmentShareId', c2SegmentShareId)
            // verify required parameter 'createC2SegmentShareDto' is not null or undefined
            assertParamExists('c2SegmentShareControllerUpdateC2SegmentShare', 'createC2SegmentShareDto', createC2SegmentShareDto)
            const localVarPath = `/api/c2-segment-share/{c2SegmentShareId}`
                .replace(`{${"c2SegmentShareId"}}`, encodeURIComponent(String(c2SegmentShareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2SegmentShareDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2SegmentShareApi - functional programming interface
 * @export
 */
export const C2SegmentShareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2SegmentShareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create segment share entry
         * @param {CreateC2SegmentShareDto} createC2SegmentShareDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentShareControllerCreateC2SegmentShare(createC2SegmentShareDto: CreateC2SegmentShareDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2SegmentShare>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentShareControllerCreateC2SegmentShare(createC2SegmentShareDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all c2 segment share entries according u1 access right
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentShareControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2SegmentShare>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentShareControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all c2 segment share entries shared by current u1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentShareControllerFindAllSharedByCurrentU1(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2SegmentShare>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentShareControllerFindAllSharedByCurrentU1(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find c2 segment share entry using segment id and shared by u1 id
         * @param {string} segmentId 
         * @param {string} sharedByU1Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentShareControllerFindOne(segmentId: string, sharedByU1Id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2SegmentShare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentShareControllerFindOne(segmentId, sharedByU1Id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update segment share entry by id
         * @param {string} c2SegmentShareId 
         * @param {CreateC2SegmentShareDto} createC2SegmentShareDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentShareControllerUpdateC2SegmentShare(c2SegmentShareId: string, createC2SegmentShareDto: CreateC2SegmentShareDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2SegmentShare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentShareControllerUpdateC2SegmentShare(c2SegmentShareId, createC2SegmentShareDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2SegmentShareApi - factory interface
 * @export
 */
export const C2SegmentShareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2SegmentShareApiFp(configuration)
    return {
        /**
         * 
         * @summary Create segment share entry
         * @param {CreateC2SegmentShareDto} createC2SegmentShareDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerCreateC2SegmentShare(createC2SegmentShareDto: CreateC2SegmentShareDto, xTenantId?: string, options?: any): AxiosPromise<Array<C2SegmentShare>> {
            return localVarFp.c2SegmentShareControllerCreateC2SegmentShare(createC2SegmentShareDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all c2 segment share entries according u1 access right
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<C2SegmentShare>> {
            return localVarFp.c2SegmentShareControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all c2 segment share entries shared by current u1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerFindAllSharedByCurrentU1(xTenantId?: string, options?: any): AxiosPromise<Array<C2SegmentShare>> {
            return localVarFp.c2SegmentShareControllerFindAllSharedByCurrentU1(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find c2 segment share entry using segment id and shared by u1 id
         * @param {string} segmentId 
         * @param {string} sharedByU1Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerFindOne(segmentId: string, sharedByU1Id: string, xTenantId?: string, options?: any): AxiosPromise<C2SegmentShare> {
            return localVarFp.c2SegmentShareControllerFindOne(segmentId, sharedByU1Id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update segment share entry by id
         * @param {string} c2SegmentShareId 
         * @param {CreateC2SegmentShareDto} createC2SegmentShareDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentShareControllerUpdateC2SegmentShare(c2SegmentShareId: string, createC2SegmentShareDto: CreateC2SegmentShareDto, xTenantId?: string, options?: any): AxiosPromise<C2SegmentShare> {
            return localVarFp.c2SegmentShareControllerUpdateC2SegmentShare(c2SegmentShareId, createC2SegmentShareDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2SegmentShareControllerCreateC2SegmentShare operation in C2SegmentShareApi.
 * @export
 * @interface C2SegmentShareApiC2SegmentShareControllerCreateC2SegmentShareRequest
 */
export interface C2SegmentShareApiC2SegmentShareControllerCreateC2SegmentShareRequest {
    /**
     * 
     * @type {CreateC2SegmentShareDto}
     * @memberof C2SegmentShareApiC2SegmentShareControllerCreateC2SegmentShare
     */
    readonly createC2SegmentShareDto: CreateC2SegmentShareDto

    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerCreateC2SegmentShare
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentShareControllerFindAll operation in C2SegmentShareApi.
 * @export
 * @interface C2SegmentShareApiC2SegmentShareControllerFindAllRequest
 */
export interface C2SegmentShareApiC2SegmentShareControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentShareControllerFindAllSharedByCurrentU1 operation in C2SegmentShareApi.
 * @export
 * @interface C2SegmentShareApiC2SegmentShareControllerFindAllSharedByCurrentU1Request
 */
export interface C2SegmentShareApiC2SegmentShareControllerFindAllSharedByCurrentU1Request {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerFindAllSharedByCurrentU1
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentShareControllerFindOne operation in C2SegmentShareApi.
 * @export
 * @interface C2SegmentShareApiC2SegmentShareControllerFindOneRequest
 */
export interface C2SegmentShareApiC2SegmentShareControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerFindOne
     */
    readonly segmentId: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerFindOne
     */
    readonly sharedByU1Id: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentShareControllerUpdateC2SegmentShare operation in C2SegmentShareApi.
 * @export
 * @interface C2SegmentShareApiC2SegmentShareControllerUpdateC2SegmentShareRequest
 */
export interface C2SegmentShareApiC2SegmentShareControllerUpdateC2SegmentShareRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerUpdateC2SegmentShare
     */
    readonly c2SegmentShareId: string

    /**
     * 
     * @type {CreateC2SegmentShareDto}
     * @memberof C2SegmentShareApiC2SegmentShareControllerUpdateC2SegmentShare
     */
    readonly createC2SegmentShareDto: CreateC2SegmentShareDto

    /**
     * 
     * @type {string}
     * @memberof C2SegmentShareApiC2SegmentShareControllerUpdateC2SegmentShare
     */
    readonly xTenantId?: string
}

/**
 * C2SegmentShareApi - object-oriented interface
 * @export
 * @class C2SegmentShareApi
 * @extends {BaseAPI}
 */
export class C2SegmentShareApi extends BaseAPI {
    /**
     * 
     * @summary Create segment share entry
     * @param {C2SegmentShareApiC2SegmentShareControllerCreateC2SegmentShareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentShareApi
     */
    public c2SegmentShareControllerCreateC2SegmentShare(requestParameters: C2SegmentShareApiC2SegmentShareControllerCreateC2SegmentShareRequest, options?: AxiosRequestConfig) {
        return C2SegmentShareApiFp(this.configuration).c2SegmentShareControllerCreateC2SegmentShare(requestParameters.createC2SegmentShareDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all c2 segment share entries according u1 access right
     * @param {C2SegmentShareApiC2SegmentShareControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentShareApi
     */
    public c2SegmentShareControllerFindAll(requestParameters: C2SegmentShareApiC2SegmentShareControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C2SegmentShareApiFp(this.configuration).c2SegmentShareControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all c2 segment share entries shared by current u1
     * @param {C2SegmentShareApiC2SegmentShareControllerFindAllSharedByCurrentU1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentShareApi
     */
    public c2SegmentShareControllerFindAllSharedByCurrentU1(requestParameters: C2SegmentShareApiC2SegmentShareControllerFindAllSharedByCurrentU1Request = {}, options?: AxiosRequestConfig) {
        return C2SegmentShareApiFp(this.configuration).c2SegmentShareControllerFindAllSharedByCurrentU1(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find c2 segment share entry using segment id and shared by u1 id
     * @param {C2SegmentShareApiC2SegmentShareControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentShareApi
     */
    public c2SegmentShareControllerFindOne(requestParameters: C2SegmentShareApiC2SegmentShareControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C2SegmentShareApiFp(this.configuration).c2SegmentShareControllerFindOne(requestParameters.segmentId, requestParameters.sharedByU1Id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update segment share entry by id
     * @param {C2SegmentShareApiC2SegmentShareControllerUpdateC2SegmentShareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentShareApi
     */
    public c2SegmentShareControllerUpdateC2SegmentShare(requestParameters: C2SegmentShareApiC2SegmentShareControllerUpdateC2SegmentShareRequest, options?: AxiosRequestConfig) {
        return C2SegmentShareApiFp(this.configuration).c2SegmentShareControllerUpdateC2SegmentShare(requestParameters.c2SegmentShareId, requestParameters.createC2SegmentShareDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
