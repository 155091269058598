/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum C1DepartmentEnum {
    Management = 'management',
    Sales = 'sales',
    Marketing = 'marketing',
    CustomerSuccess = 'customer_success',
    ProductManagement = 'product_management',
    TechAndDevelopment = 'tech_and_development',
    Devops = 'devops',
    Support = 'support',
    Finance = 'finance',
    TrainingAndDevelopment = 'training_and_development',
    Others = 'others'
}


