import { u1RoleApi } from '@/api';
import { U1Role } from '@/api/client';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useU1RoleStore = defineStore('u1-roles', () => {
  const isLoading = ref(false);

  const u1Roles = ref<U1Role[]>([]);

  const fetchU1Roles = async () => {
    isLoading.value = true;
    try {
      const { data } = await u1RoleApi.u1RolesControllerFindAll();
      u1Roles.value = data;
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    fetchU1Roles,
    u1Roles,
  };
});
