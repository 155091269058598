/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SearchResultDto } from '../model/';
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {string} [query] 
         * @param {string} [category] 
         * @param {number} [page] 
         * @param {string} [type] 
         * @param {string} [dateUtc] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearch: async (xTenantId?: string, query?: string, category?: string, page?: number, type?: string, dateUtc?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dateUtc !== undefined) {
                localVarQueryParameter['dateUtc'] = dateUtc;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {string} [query] 
         * @param {string} [category] 
         * @param {number} [page] 
         * @param {string} [type] 
         * @param {string} [dateUtc] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchControllerSearch(xTenantId?: string, query?: string, category?: string, page?: number, type?: string, dateUtc?: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchControllerSearch(xTenantId, query, category, page, type, dateUtc, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {string} [query] 
         * @param {string} [category] 
         * @param {number} [page] 
         * @param {string} [type] 
         * @param {string} [dateUtc] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearch(xTenantId?: string, query?: string, category?: string, page?: number, type?: string, dateUtc?: string, date?: string, options?: any): AxiosPromise<SearchResultDto> {
            return localVarFp.searchControllerSearch(xTenantId, query, category, page, type, dateUtc, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchControllerSearch operation in SearchApi.
 * @export
 * @interface SearchApiSearchControllerSearchRequest
 */
export interface SearchApiSearchControllerSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchControllerSearch
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchControllerSearch
     */
    readonly query?: string

    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchControllerSearch
     */
    readonly category?: string

    /**
     * 
     * @type {number}
     * @memberof SearchApiSearchControllerSearch
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchControllerSearch
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchControllerSearch
     */
    readonly dateUtc?: string

    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchControllerSearch
     */
    readonly date?: string
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {SearchApiSearchControllerSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchControllerSearch(requestParameters: SearchApiSearchControllerSearchRequest = {}, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchControllerSearch(requestParameters.xTenantId, requestParameters.query, requestParameters.category, requestParameters.page, requestParameters.type, requestParameters.dateUtc, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}
