/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ZapscaleFieldCondition } from '../model/';
// @ts-ignore
import { ZapscaleFieldConditionsDto } from '../model/';
/**
 * ZapscaleFieldConditionsApi - axios parameter creator
 * @export
 */
export const ZapscaleFieldConditionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Zapscale Field Mapping Conditions
         * @param {ZapscaleFieldConditionsDto} zapscaleFieldConditionsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerAddZapscaleFieldConditions: async (zapscaleFieldConditionsDto: ZapscaleFieldConditionsDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zapscaleFieldConditionsDto' is not null or undefined
            assertParamExists('zapscaleFieldConditionsControllerAddZapscaleFieldConditions', 'zapscaleFieldConditionsDto', zapscaleFieldConditionsDto)
            const localVarPath = `/api/zapscale-field-conditions/add-zapscale-field-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zapscaleFieldConditionsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all Field Conditions for Zapscale App Object Fields Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions', 'id', id)
            const localVarPath = `/api/zapscale-field-conditions/{id}/delete-all-conditions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Zapscale Field Conditions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/zapscale-field-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Zapscale Field Conditions for Zapscale to App Object Field Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping', 'id', id)
            const localVarPath = `/api/zapscale-field-conditions/{id}/find-all-for-zapscale-field-mapping`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZapscaleFieldConditionsApi - functional programming interface
 * @export
 */
export const ZapscaleFieldConditionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZapscaleFieldConditionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Zapscale Field Mapping Conditions
         * @param {ZapscaleFieldConditionsDto} zapscaleFieldConditionsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleFieldConditionsControllerAddZapscaleFieldConditions(zapscaleFieldConditionsDto: ZapscaleFieldConditionsDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleFieldCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleFieldConditionsControllerAddZapscaleFieldConditions(zapscaleFieldConditionsDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all Field Conditions for Zapscale App Object Fields Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Zapscale Field Conditions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleFieldConditionsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleFieldCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleFieldConditionsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Zapscale Field Conditions for Zapscale to App Object Field Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZapscaleFieldCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZapscaleFieldConditionsApi - factory interface
 * @export
 */
export const ZapscaleFieldConditionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZapscaleFieldConditionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Zapscale Field Mapping Conditions
         * @param {ZapscaleFieldConditionsDto} zapscaleFieldConditionsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerAddZapscaleFieldConditions(zapscaleFieldConditionsDto: ZapscaleFieldConditionsDto, xTenantId?: string, options?: any): AxiosPromise<Array<ZapscaleFieldCondition>> {
            return localVarFp.zapscaleFieldConditionsControllerAddZapscaleFieldConditions(zapscaleFieldConditionsDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all Field Conditions for Zapscale App Object Fields Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions(id: string, xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Zapscale Field Conditions
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<ZapscaleFieldCondition>> {
            return localVarFp.zapscaleFieldConditionsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Zapscale Field Conditions for Zapscale to App Object Field Mapping
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<ZapscaleFieldCondition>> {
            return localVarFp.zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for zapscaleFieldConditionsControllerAddZapscaleFieldConditions operation in ZapscaleFieldConditionsApi.
 * @export
 * @interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerAddZapscaleFieldConditionsRequest
 */
export interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerAddZapscaleFieldConditionsRequest {
    /**
     * 
     * @type {ZapscaleFieldConditionsDto}
     * @memberof ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerAddZapscaleFieldConditions
     */
    readonly zapscaleFieldConditionsDto: ZapscaleFieldConditionsDto

    /**
     * 
     * @type {string}
     * @memberof ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerAddZapscaleFieldConditions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions operation in ZapscaleFieldConditionsApi.
 * @export
 * @interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditionsRequest
 */
export interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleFieldConditionsControllerFindAll operation in ZapscaleFieldConditionsApi.
 * @export
 * @interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllRequest
 */
export interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping operation in ZapscaleFieldConditionsApi.
 * @export
 * @interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMappingRequest
 */
export interface ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping
     */
    readonly xTenantId?: string
}

/**
 * ZapscaleFieldConditionsApi - object-oriented interface
 * @export
 * @class ZapscaleFieldConditionsApi
 * @extends {BaseAPI}
 */
export class ZapscaleFieldConditionsApi extends BaseAPI {
    /**
     * 
     * @summary Add Zapscale Field Mapping Conditions
     * @param {ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerAddZapscaleFieldConditionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleFieldConditionsApi
     */
    public zapscaleFieldConditionsControllerAddZapscaleFieldConditions(requestParameters: ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerAddZapscaleFieldConditionsRequest, options?: AxiosRequestConfig) {
        return ZapscaleFieldConditionsApiFp(this.configuration).zapscaleFieldConditionsControllerAddZapscaleFieldConditions(requestParameters.zapscaleFieldConditionsDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all Field Conditions for Zapscale App Object Fields Mapping
     * @param {ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleFieldConditionsApi
     */
    public zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions(requestParameters: ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditionsRequest, options?: AxiosRequestConfig) {
        return ZapscaleFieldConditionsApiFp(this.configuration).zapscaleFieldConditionsControllerDeleteAllZapscaleFieldConditions(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Zapscale Field Conditions
     * @param {ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleFieldConditionsApi
     */
    public zapscaleFieldConditionsControllerFindAll(requestParameters: ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return ZapscaleFieldConditionsApiFp(this.configuration).zapscaleFieldConditionsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Zapscale Field Conditions for Zapscale to App Object Field Mapping
     * @param {ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMappingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZapscaleFieldConditionsApi
     */
    public zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping(requestParameters: ZapscaleFieldConditionsApiZapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMappingRequest, options?: AxiosRequestConfig) {
        return ZapscaleFieldConditionsApiFp(this.configuration).zapscaleFieldConditionsControllerFindAllForZapscaleObjectFieldMapping(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
