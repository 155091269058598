<script setup lang="ts">
import { useSlots } from 'vue';

defineProps<{
  header: string;
  description?: string;
}>();
const slots = useSlots();
</script>

<template>
  <div class="empty-state tw-rounded tw-border tw-p-4 tw-text-center">
    <h3 class="tw-font-semibold tw-leading-6 tw-text-dark-shade-1">{{ header }}</h3>
    <p class="tw-mt-1 tw-text-sm tw-font-medium tw-text-dark-shade-4" v-if="description">{{ description }}</p>
    <div class="tw-mt-3 tw-flex tw-items-center tw-justify-center tw-space-x-2" v-if="slots.default">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.empty-state {
  background-color: #fff;
  background-image: linear-gradient(45deg, var(--ds-surface-sunken, #f9f9fa) 25%, transparent 25%),
    linear-gradient(135deg, var(--ds-surface-sunken, #f9f9fa) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--ds-surface-sunken, #f9f9fa) 75%),
    linear-gradient(135deg, transparent 75%, var(--ds-surface-sunken, #f9f9fa) 75%);
  background-size: 20px 20px;
  background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px;
}
</style>
