<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { phCheck } from '@/phosphor-icons';
import { addMonths, endOfYear, getMonth, getYear } from 'date-fns';
import { IDate } from '@/types/date.type';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { QScrollArea } from 'quasar';
import { DateTypeEnum } from '@/api/client';
import { useAccountStore } from '@/stores/account';
import { storeToRefs } from 'pinia';

const props = defineProps<{ selectedYear: number; selectedDate: IDate }>();
const emits = defineEmits(['onDateSelection']);

const scrollAreaRef = ref<QScrollArea | undefined>();
const { getIDateMonthObj } = useDateFormatter();
const { onboardingCompletedAt } = storeToRefs(useAccountStore());

const selectedMonth = ref<IDate>(props.selectedDate);

const currentDate = new Date();
const currentMonth = getMonth(currentDate);
const currentYear = getYear(currentDate);

const itemHeight = 48;

const months = computed(() => {
  const items = [] as IDate[];
  let startDate = new Date(props.selectedYear, 0, 1);
  if (onboardingCompletedAt.value && onboardingCompletedAt.value.getFullYear() === props.selectedYear) {
    startDate = onboardingCompletedAt.value;
  }
  const endDate = endOfYear(startDate);
  while (startDate < endDate) {
    const item = getIDateMonthObj(startDate);
    item.isDisabled = currentYear === props.selectedYear ? (currentMonth <= (item.itemNo || 0) ? true : false) : false;
    items.push(item);
    startDate = addMonths(startDate, 1);
  }
  return items;
});

watch(
  () => props.selectedYear,
  () => {
    scrollToPosition();
  },
  { immediate: true },
);

onMounted(() => {
  scrollToPosition();
});

function scrollToPosition() {
  const selected = selectedMonth.value;
  if (selected && selected.type === DateTypeEnum.Month) {
    const selectedWeekOffset = months.value.findIndex((x) => x.endDate.toString() === selected.endDate.toString());
    if (selectedWeekOffset) {
      scrollAreaRef.value?.setScrollPosition('vertical', (selectedWeekOffset - 1) * itemHeight);
    }
  } else {
    const firstDisableWeekOffset = months.value.findIndex((x) => x.isDisabled);
    if (firstDisableWeekOffset > 0 && currentYear === props.selectedYear) {
      scrollAreaRef.value?.setScrollPosition('vertical', (firstDisableWeekOffset - 1) * itemHeight);
    } else {
      scrollAreaRef.value?.setScrollPosition('vertical', 0);
    }
  }
}

function isSelected(month: IDate) {
  const selection = selectedMonth.value;
  return selection.endDate?.toString() === month.endDate?.toString() && month.type === selection.type;
}

function onItemClick(month: IDate) {
  selectedMonth.value = month;
  emits('onDateSelection', month);
}
</script>

<template>
  <q-scroll-area ref="scrollAreaRef" style="height: 14rem" class="tw-h-full tw-w-full">
    <q-list bordered padding class="rounded-borders text-primary tw-mx-5 tw-my-4 tw-bg-light-shade-1 tw-py-0">
      <q-item
        v-for="month in months"
        :key="month.endDate.toString()"
        clickable
        :active="isSelected(month)"
        @click="onItemClick(month)"
        active-class="my-menu-link"
        :disable="month.isDisabled"
      >
        <q-item-section>{{ month.text }}</q-item-section>
        <q-item-section side v-if="isSelected(month)">
          <q-icon :name="phCheck" class="tw-text-light-shade-1" />
        </q-item-section>
      </q-item>
    </q-list>
  </q-scroll-area>
</template>
<style scoped lang="postcss">
.my-menu-link {
  @apply tw-bg-primary-dark tw-text-light-shade-1;
}
q-item__section--side {
  @apply tw-text-light-shade-1;
}
</style>
