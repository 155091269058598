<script setup lang="ts">
import { phInfoBold, phSmileySad } from '@/phosphor-icons';
import { QIcon, QInnerLoading, QMenu, QTable, QTableProps, QTd, QTh, QTr } from 'quasar';
import { ICustomersTableCaret } from '@/types/customerslist.type';
import KpiNumberCaret from '../CustomerDetails/CustomerDetailsOverview/KpiNumberCaret.vue';
import TdBarGraph from '@/components/Customers/TdBarGraph.vue';
import { IKpiRow } from '@/types/customer.type';
import { IKpiRowDashboard } from '@/types/dashboard';
import { useDashboardStore } from '@/stores/dashboard';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useC2TypeStore } from '@/stores/c2Type';
import { C2TypeNameEnum, SourceEnum } from '@/api/client';
import KpiDefinition from '../KpiDefinition.vue';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const dashboardStore = useDashboardStore();
const c2TypeStore = useC2TypeStore();

const sourceType = computed(() => {
  return SourceEnum.C1HealthScore;
});

const columns = computed(() => {
  const columnData: QTableProps['columns'] = [
    { label: t('dashboard_table_column_names.kpi'), name: 'kpiName', field: 'kpiName', align: 'left' as const },
    { label: t('dashboard_table_column_names.kpi_score'), name: 'kpiScore', field: 'kpiScore', align: 'left' as const },
    {
      label: t('dashboard_table_column_names.customer_health'),
      name: 'customer_health',
      field: 'customer_health',
      align: 'left' as const,
    },
  ];
  if (c2TypeStore.c2Types.find((v) => v.name === C2TypeNameEnum.Steady && v.is_active)) {
    columnData.push({
      label: t('dashboard_table_column_names.steady_customers'),
      name: 'steadyCustScore',
      field: 'steadyCustScore',
      align: 'left' as const,
    });
  }
  if (c2TypeStore.c2Types.find((v) => v.name === C2TypeNameEnum.Onboarding && v.is_active)) {
    columnData.push({
      label: t('dashboard_table_column_names.onboarding_customers'),
      name: 'onboardingCustScore',
      field: 'onboardingCustScore',
      align: 'left' as const,
    });
  }
  if (c2TypeStore.c2Types.find((v) => v.name === C2TypeNameEnum.Trial && v.is_active)) {
    columnData.push({
      label: t('dashboard_table_column_names.trialing_customers'),
      name: 'trialCustScore',
      field: 'trialCustScore',
      align: 'left' as const,
    });
  }
  return columnData;
});

function getKpiScore(kpiRow: IKpiRow) {
  return {
    value: kpiRow.kpiScore,
    percentage: kpiRow.kpiPercentageChange,
    isLarge: false,
    colorCode: kpiRow.kpiColorCode,
    isNavigational: false,
  } as ICustomersTableCaret;
}

function getSteadyCustScore(kpiRow: IKpiRowDashboard) {
  return {
    value: kpiRow.steadyCustScore,
    percentage: kpiRow.steadyCustPercentageChange,
    isLarge: false,
    colorCode: kpiRow.steadyCustColorCode,
    isNavigational: false,
  } as ICustomersTableCaret;
}

function getOnboardingCustScore(kpiRow: IKpiRowDashboard) {
  return {
    value: kpiRow.onboardingCustScore,
    percentage: kpiRow.onboardingCustPercentageChange,
    isLarge: false,
    colorCode: kpiRow.onboardingCustColorCode,
    isNavigational: false,
  } as ICustomersTableCaret;
}

function getTrialCustScore(kpiRow: IKpiRowDashboard) {
  return {
    value: kpiRow.trialCustScore,
    percentage: kpiRow.trialCustPercentageChange,
    isLarge: false,
    colorCode: kpiRow.trialCustColorCode,
    isNavigational: false,
  } as ICustomersTableCaret;
}
</script>

<template>
  <q-table
    :rows="dashboardStore.tableRows"
    :columns="columns"
    :loading="dashboardStore.isTableLoading"
    hide-bottom
    :rows-per-page-options="[0]"
    no-data-label="I didn't find anything for you"
    class="my-sticky-header-table no-shadow tw-my-3 tw-border tw-border-light-shade-4"
  >
    <template v-slot:loading>
      <q-inner-loading showing color="primary" class="z-max" size="md" />
    </template>

    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          <span class="tw-text-sm tw-font-semibold tw-leading-5 tw-text-dark-shade-4">{{ col.label }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <!-- KpiName td -->
        <q-td key="kpiName" :props="props" class="tw-text-sm tw-font-semibold tw-leading-5 tw-text-dark-shade-2">
          <div class="tw-flex">
            <a>
              {{ props.row.kpiDisplayName }}
            </a>
            <div class="tw-cursor-pointer">
              <q-icon :name="phInfoBold" class="tw-ml-2.5 tw-text-primary"> </q-icon>
              <q-menu ref="kpi-range-menu" transition-show="fade" transition-hide="fade" transition-duration="300">
                <KpiDefinition :kpi-id="props.row.kpiId" :kpi-for="'business_kpi'" />
              </q-menu>
            </div>
          </div>
        </q-td>
        <!-- Kpi Score td -->
        <q-td key="kpiScore" :props="props">
          <KpiNumberCaret :data="getKpiScore(props.row as IKpiRow)" />
        </q-td>
        <q-td key="customer_health" :props="props">
          <TdBarGraph
            :kpi-id="props.row.kpiId"
            :source="sourceType"
            :date="props.row.date"
            :green="props.row.percentInGreen || 0"
            :red="props.row.percentInRed || 0"
            :yellow="props.row.percentInYellow || 0"
            :grey="props.row.percentInGrey || 0"
            :green-count="props.row.noInGreen || 0"
            :grey-count="props.row.noInGrey || 0"
            :red-count="props.row.noInRed || 0"
            :yellow-count="props.row.noInYellow || 0"
            :black-count="0"
            :black="0"
            :is-clickable="true"
          />
        </q-td>
        <q-td key="steadyCustScore" :props="props">
          <KpiNumberCaret :data="getSteadyCustScore(props.row as IKpiRowDashboard)" />
        </q-td>
        <q-td key="onboardingCustScore" :props="props">
          <KpiNumberCaret :data="getOnboardingCustScore(props.row as IKpiRowDashboard)" />
        </q-td>
        <q-td key="trialCustScore" :props="props">
          <KpiNumberCaret :data="getTrialCustScore(props.row as IKpiRowDashboard)" />
        </q-td>
      </q-tr>
    </template>
    <template v-slot:no-data="{ message }">
      <div class="full-width row flex-center q-gutter-sm tw-text-dark-shade-4">
        <q-icon size="2em" :name="phSmileySad" />
        <span> {{ message }}</span>
      </div>
    </template>
  </q-table>
</template>
