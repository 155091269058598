/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UpdateWarehouseTransformationDto } from '../model/';
// @ts-ignore
import { WarehouseTransformation } from '../model/';
/**
 * WarehouseTransformationApi - axios parameter creator
 * @export
 */
export const WarehouseTransformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get mapping info against app id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseTransformationControllerFindAllWithAppId: async (appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('warehouseTransformationControllerFindAllWithAppId', 'appId', appId)
            const localVarPath = `/api/warehouse-transformation/find-all-with-app-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update mapping with id
         * @param {UpdateWarehouseTransformationDto} updateWarehouseTransformationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseTransformationControllerUpdateDataWarehouseMappingWithId: async (updateWarehouseTransformationDto: UpdateWarehouseTransformationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWarehouseTransformationDto' is not null or undefined
            assertParamExists('warehouseTransformationControllerUpdateDataWarehouseMappingWithId', 'updateWarehouseTransformationDto', updateWarehouseTransformationDto)
            const localVarPath = `/api/warehouse-transformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWarehouseTransformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseTransformationApi - functional programming interface
 * @export
 */
export const WarehouseTransformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseTransformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get mapping info against app id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseTransformationControllerFindAllWithAppId(appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseTransformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseTransformationControllerFindAllWithAppId(appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update mapping with id
         * @param {UpdateWarehouseTransformationDto} updateWarehouseTransformationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseTransformationControllerUpdateDataWarehouseMappingWithId(updateWarehouseTransformationDto: UpdateWarehouseTransformationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseTransformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseTransformationControllerUpdateDataWarehouseMappingWithId(updateWarehouseTransformationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseTransformationApi - factory interface
 * @export
 */
export const WarehouseTransformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseTransformationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get mapping info against app id
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseTransformationControllerFindAllWithAppId(appId: string, xTenantId?: string, options?: any): AxiosPromise<Array<WarehouseTransformation>> {
            return localVarFp.warehouseTransformationControllerFindAllWithAppId(appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update mapping with id
         * @param {UpdateWarehouseTransformationDto} updateWarehouseTransformationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseTransformationControllerUpdateDataWarehouseMappingWithId(updateWarehouseTransformationDto: UpdateWarehouseTransformationDto, xTenantId?: string, options?: any): AxiosPromise<WarehouseTransformation> {
            return localVarFp.warehouseTransformationControllerUpdateDataWarehouseMappingWithId(updateWarehouseTransformationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for warehouseTransformationControllerFindAllWithAppId operation in WarehouseTransformationApi.
 * @export
 * @interface WarehouseTransformationApiWarehouseTransformationControllerFindAllWithAppIdRequest
 */
export interface WarehouseTransformationApiWarehouseTransformationControllerFindAllWithAppIdRequest {
    /**
     * 
     * @type {string}
     * @memberof WarehouseTransformationApiWarehouseTransformationControllerFindAllWithAppId
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof WarehouseTransformationApiWarehouseTransformationControllerFindAllWithAppId
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for warehouseTransformationControllerUpdateDataWarehouseMappingWithId operation in WarehouseTransformationApi.
 * @export
 * @interface WarehouseTransformationApiWarehouseTransformationControllerUpdateDataWarehouseMappingWithIdRequest
 */
export interface WarehouseTransformationApiWarehouseTransformationControllerUpdateDataWarehouseMappingWithIdRequest {
    /**
     * 
     * @type {UpdateWarehouseTransformationDto}
     * @memberof WarehouseTransformationApiWarehouseTransformationControllerUpdateDataWarehouseMappingWithId
     */
    readonly updateWarehouseTransformationDto: UpdateWarehouseTransformationDto

    /**
     * 
     * @type {string}
     * @memberof WarehouseTransformationApiWarehouseTransformationControllerUpdateDataWarehouseMappingWithId
     */
    readonly xTenantId?: string
}

/**
 * WarehouseTransformationApi - object-oriented interface
 * @export
 * @class WarehouseTransformationApi
 * @extends {BaseAPI}
 */
export class WarehouseTransformationApi extends BaseAPI {
    /**
     * 
     * @summary Get mapping info against app id
     * @param {WarehouseTransformationApiWarehouseTransformationControllerFindAllWithAppIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseTransformationApi
     */
    public warehouseTransformationControllerFindAllWithAppId(requestParameters: WarehouseTransformationApiWarehouseTransformationControllerFindAllWithAppIdRequest, options?: AxiosRequestConfig) {
        return WarehouseTransformationApiFp(this.configuration).warehouseTransformationControllerFindAllWithAppId(requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update mapping with id
     * @param {WarehouseTransformationApiWarehouseTransformationControllerUpdateDataWarehouseMappingWithIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseTransformationApi
     */
    public warehouseTransformationControllerUpdateDataWarehouseMappingWithId(requestParameters: WarehouseTransformationApiWarehouseTransformationControllerUpdateDataWarehouseMappingWithIdRequest, options?: AxiosRequestConfig) {
        return WarehouseTransformationApiFp(this.configuration).warehouseTransformationControllerUpdateDataWarehouseMappingWithId(requestParameters.updateWarehouseTransformationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
