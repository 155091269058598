/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { U2RoleLevelHealthScore } from '../model/';
/**
 * U2RoleLevelHealthScoresApi - axios parameter creator
 * @export
 */
export const U2RoleLevelHealthScoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of U2-Role-Level-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RoleLevelHealthScoresControllerFindAllByDateRange: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('u2RoleLevelHealthScoresControllerFindAllByDateRange', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('u2RoleLevelHealthScoresControllerFindAllByDateRange', 'dateType', dateType)
            const localVarPath = `/api/u2-role-level-health-scores/date-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get U2-Role-Level-Health-Score-against U2RoleId and C2TypeId
         * @param {string} u2RoleId 
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RoleLevelHealthScoresControllerFindOneByDate: async (u2RoleId: string, c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2RoleId' is not null or undefined
            assertParamExists('u2RoleLevelHealthScoresControllerFindOneByDate', 'u2RoleId', u2RoleId)
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('u2RoleLevelHealthScoresControllerFindOneByDate', 'c2TypeId', c2TypeId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('u2RoleLevelHealthScoresControllerFindOneByDate', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('u2RoleLevelHealthScoresControllerFindOneByDate', 'dateType', dateType)
            const localVarPath = `/api/u2-role-level-health-scores/{u2RoleId}/by-role-id`
                .replace(`{${"u2RoleId"}}`, encodeURIComponent(String(u2RoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2_type_id'] = c2TypeId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2RoleLevelHealthScoresApi - functional programming interface
 * @export
 */
export const U2RoleLevelHealthScoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2RoleLevelHealthScoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of U2-Role-Level-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2RoleLevelHealthScoresControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2RoleLevelHealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2RoleLevelHealthScoresControllerFindAllByDateRange(date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get U2-Role-Level-Health-Score-against U2RoleId and C2TypeId
         * @param {string} u2RoleId 
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2RoleLevelHealthScoresControllerFindOneByDate(u2RoleId: string, c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2RoleLevelHealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2RoleLevelHealthScoresControllerFindOneByDate(u2RoleId, c2TypeId, date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2RoleLevelHealthScoresApi - factory interface
 * @export
 */
export const U2RoleLevelHealthScoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2RoleLevelHealthScoresApiFp(configuration)
    return {
        /**
         * 
         * @summary List of U2-Role-Level-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RoleLevelHealthScoresControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<U2RoleLevelHealthScore>> {
            return localVarFp.u2RoleLevelHealthScoresControllerFindAllByDateRange(date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get U2-Role-Level-Health-Score-against U2RoleId and C2TypeId
         * @param {string} u2RoleId 
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2RoleLevelHealthScoresControllerFindOneByDate(u2RoleId: string, c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<U2RoleLevelHealthScore> {
            return localVarFp.u2RoleLevelHealthScoresControllerFindOneByDate(u2RoleId, c2TypeId, date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2RoleLevelHealthScoresControllerFindAllByDateRange operation in U2RoleLevelHealthScoresApi.
 * @export
 * @interface U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindAllByDateRangeRequest
 */
export interface U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindAllByDateRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindAllByDateRange
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindAllByDateRange
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindAllByDateRange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2RoleLevelHealthScoresControllerFindOneByDate operation in U2RoleLevelHealthScoresApi.
 * @export
 * @interface U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDateRequest
 */
export interface U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDateRequest {
    /**
     * 
     * @type {string}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDate
     */
    readonly u2RoleId: string

    /**
     * 
     * @type {string}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDate
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDate
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDate
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDate
     */
    readonly xTenantId?: string
}

/**
 * U2RoleLevelHealthScoresApi - object-oriented interface
 * @export
 * @class U2RoleLevelHealthScoresApi
 * @extends {BaseAPI}
 */
export class U2RoleLevelHealthScoresApi extends BaseAPI {
    /**
     * 
     * @summary List of U2-Role-Level-Health-Score by date range
     * @param {U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindAllByDateRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2RoleLevelHealthScoresApi
     */
    public u2RoleLevelHealthScoresControllerFindAllByDateRange(requestParameters: U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindAllByDateRangeRequest, options?: AxiosRequestConfig) {
        return U2RoleLevelHealthScoresApiFp(this.configuration).u2RoleLevelHealthScoresControllerFindAllByDateRange(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get U2-Role-Level-Health-Score-against U2RoleId and C2TypeId
     * @param {U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2RoleLevelHealthScoresApi
     */
    public u2RoleLevelHealthScoresControllerFindOneByDate(requestParameters: U2RoleLevelHealthScoresApiU2RoleLevelHealthScoresControllerFindOneByDateRequest, options?: AxiosRequestConfig) {
        return U2RoleLevelHealthScoresApiFp(this.configuration).u2RoleLevelHealthScoresControllerFindOneByDate(requestParameters.u2RoleId, requestParameters.c2TypeId, requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
