import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { useC1HealthScoreStore } from '@/stores/c1HealthScore';
import { useC2HealthScoreStore } from '@/stores/c2HealthScore';
import { useCustomerStore } from '@/stores/customer';
import { useDashboardStore } from '@/stores/dashboard';
import { useDateStore } from '@/stores/date';
import { ChartData } from 'chart.js';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export const useHealthTrendChart = () => {
  const { formatNumberRoundOfDecimal } = useAmountFormatter();
  const { getDateLabel } = useDateStore();
  const filterOptions = [
    {
      label: 'Customers',
      value: 'customers',
    },
    {
      label: 'Users',
      value: 'users',
    },
  ];

  const filterOptionsCustomer = [
    {
      label: 'All User',
      value: 'all_user',
    },
    {
      label: 'Decision Makers',
      value: 'decision_makers',
    },
    {
      label: 'Influencers',
      value: 'influencers',
    },
    {
      label: 'Normal Users',
      value: 'normal_users',
    },
  ];

  const barChartDataSet = [
    {
      label: 'Good',
      data: [] as number[],
      backgroundColor: '#4AC18D',
      borderColor: '#4AC18D',
      borderRadius: 4,
      ids: [] as string[],
      counts: [] as number[],
      datalabels: {
        align: 'left' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number) => {
          if (value > 5) {
            return `${value}%`;
          } else {
            return '';
          }
        },
      },
    },
    {
      label: 'Fair',
      data: [] as number[],
      backgroundColor: '#FEBB2A',
      borderColor: '#FEBB2A',
      borderRadius: 4,
      ids: [] as string[],
      counts: [] as number[],
      datalabels: {
        align: 'left' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number) => {
          if (value > 5) {
            return `${value}%`;
          } else {
            return '';
          }
        },
      },
    },
    {
      label: 'Bad',
      data: [] as number[],
      backgroundColor: '#FF625E',
      borderColor: '#FF625E',
      borderRadius: 4,
      ids: [] as string[],
      counts: [] as number[],
      datalabels: {
        align: 'left' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number) => {
          if (value > 5) {
            return `${value}%`;
          } else {
            return '';
          }
        },
      },
    },
    {
      label: 'Inactive',
      data: [] as number[],
      backgroundColor: '#D0D0D0',
      borderColor: '#D0D0D0',
      borderRadius: 4,
      ids: [] as string[],
      counts: [] as number[],
      datalabels: {
        align: 'left' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number) => {
          if (value > 5) {
            return `${value}%`;
          } else {
            return '';
          }
        },
      },
    },
    {
      label: 'Churn',
      data: [] as number[],
      backgroundColor: '#444444',
      borderColor: '#444444',
      borderRadius: 4,
      ids: [] as string[],
      counts: [] as number[],
      datalabels: {
        align: 'left' as const,
        offset: -5,
        color: '#FFFFFE',
        font: {
          family: 'Hanken Grotesk',
          size: 10,
          weight: 700,
        },
        formatter: (value: number) => {
          if (value > 5) {
            return `${value}%`;
          } else {
            return '';
          }
        },
      },
    },
  ];

  const { c1HealthScoreList } = storeToRefs(useC1HealthScoreStore());
  const { healthTrendSelectedFilter } = storeToRefs(useDashboardStore());

  const { c2HealthScoreList } = storeToRefs(useC2HealthScoreStore());
  const { healthTrendSelectedFilter: customerOverviewHealthTrendSelectedFilter } = storeToRefs(useCustomerStore());

  const dashboardBarChartDataSet = computed(() => {
    const listOfLabel: string[] = [];
    const chartDatasets = [...barChartDataSet];
    chartDatasets.forEach((x) => {
      x.data = [] as number[];
      x.ids = [] as string[];
      x.counts = [] as number[];
    });
    if (c1HealthScoreList.value?.length <= 0) {
      return {
        labels: listOfLabel,
        datasets: [...chartDatasets],
      };
    }

    const dateList = [...new Set(c1HealthScoreList.value.map((x) => x.date))].sort();
    const selectedFilter = healthTrendSelectedFilter.value;
    for (let i = 0; i < dateList.length; i++) {
      const date = dateList[i];
      listOfLabel.push(getDateLabel(date));
      const c1HealthScore = c1HealthScoreList.value?.find((x) => x.date === date);
      if (!c1HealthScore) {
        continue;
      }
      const goodChart = barChartDataSet.find((x) => x.label === 'Good');
      goodChart?.ids.push(c1HealthScore.id);

      const fairChart = barChartDataSet.find((x) => x.label === 'Fair');
      fairChart?.ids.push(c1HealthScore.id);

      const badChart = barChartDataSet.find((x) => x.label === 'Bad');
      badChart?.ids.push(c1HealthScore.id);

      const inactiveChart = barChartDataSet.find((x) => x.label === 'Inactive');
      inactiveChart?.ids.push(c1HealthScore.id);

      const churnChart = barChartDataSet.find((x) => x.label === 'Churn');
      churnChart?.ids.push(c1HealthScore.id);

      if (selectedFilter === filterOptions[0].value) {
        let goodPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_c2s_in_green);
        let fairPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_c2s_in_yellow);
        let badPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_c2s_in_red);
        let inactivePercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_c2s_in_grey);
        let churnPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_c2s_in_black);
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        goodChart?.data.push(goodPercentage);
        fairChart?.data.push(fairPercentage);
        badChart?.data.push(badPercentage);
        inactiveChart?.data.push(inactivePercentage);
        churnChart?.data.push(churnPercentage);

        goodChart?.counts.push(c1HealthScore?.total_c2s_in_green);
        fairChart?.counts.push(c1HealthScore?.total_c2s_in_yellow);
        badChart?.counts.push(c1HealthScore?.total_c2s_in_red);
        inactiveChart?.counts.push(c1HealthScore?.total_c2s_in_grey);
        churnChart?.counts.push(c1HealthScore?.total_c2s_in_black);
      } else {
        let goodPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_u2s_in_green);
        let fairPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_u2s_in_yellow);
        let badPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_u2s_in_red);
        let inactivePercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_u2s_in_grey);
        let churnPercentage = +formatNumberRoundOfDecimal(c1HealthScore.percent_u2s_in_black);
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        goodChart?.data.push(goodPercentage);
        fairChart?.data.push(fairPercentage);
        badChart?.data.push(badPercentage);
        inactiveChart?.data.push(inactivePercentage);
        churnChart?.data.push(churnPercentage);

        goodChart?.counts.push(c1HealthScore?.total_u2s_in_green);
        fairChart?.counts.push(c1HealthScore?.total_u2s_in_yellow);
        badChart?.counts.push(c1HealthScore?.total_u2s_in_red);
        inactiveChart?.counts.push(c1HealthScore?.total_u2s_in_grey);
        churnChart?.counts.push(c1HealthScore?.total_u2s_in_black);
      }
    }
    return {
      labels: listOfLabel,
      datasets: [...barChartDataSet],
    } as ChartData<'bar'>;
  });

  const customerOverviewBarChartDataSet = computed(() => {
    const listOfLabel: string[] = [];
    const chartDatasets = [...barChartDataSet];
    chartDatasets.forEach((x) => {
      x.data = [] as number[];
      x.ids = [] as string[];
      x.counts = [] as number[];
    });
    if (c2HealthScoreList.value.length <= 0) {
      return {
        labels: listOfLabel,
        datasets: [...chartDatasets],
      };
    }
    const dateList = [...new Set(c2HealthScoreList.value?.map((x) => x.date))].sort();
    for (let i = 0; i < dateList.length; i++) {
      const date = dateList[i];
      listOfLabel.push(getDateLabel(date));
      const c2HealthScore = c2HealthScoreList.value?.find((x) => x.date === date);
      if (!c2HealthScore) {
        continue;
      }
      const goodChart = chartDatasets.find((x) => x.label === 'Good');
      goodChart?.ids.push(c2HealthScore.id);
      const fairChart = chartDatasets.find((x) => x.label === 'Fair');
      fairChart?.ids.push(c2HealthScore.id);
      const badChart = chartDatasets.find((x) => x.label === 'Bad');
      badChart?.ids.push(c2HealthScore.id);
      const inactiveChart = chartDatasets.find((x) => x.label === 'Inactive');
      inactiveChart?.ids.push(c2HealthScore.id);
      const churnChart = chartDatasets.find((x) => x.label === 'Churn');
      churnChart?.ids.push(c2HealthScore.id);

      if (customerOverviewHealthTrendSelectedFilter.value?.value === filterOptionsCustomer[0].value) {
        let goodPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_u2s_in_green);
        let fairPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_u2s_in_yellow);
        let badPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_u2s_in_red);
        let inactivePercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_u2s_in_grey);
        let churnPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_u2s_in_black);
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        goodChart?.data.push(goodPercentage);
        fairChart?.data.push(fairPercentage);
        badChart?.data.push(badPercentage);
        inactiveChart?.data.push(inactivePercentage);
        churnChart?.data.push(churnPercentage);

        goodChart?.counts.push(c2HealthScore.total_u2s_in_green);
        fairChart?.counts.push(c2HealthScore.total_u2s_in_yellow);
        badChart?.counts.push(c2HealthScore.total_u2s_in_red);
        inactiveChart?.counts.push(c2HealthScore.total_u2s_in_grey);
        churnChart?.counts.push(c2HealthScore.total_u2s_in_black);
      } else if (customerOverviewHealthTrendSelectedFilter.value?.value === filterOptionsCustomer[1].value) {
        let goodPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_green_decision_makers);
        let fairPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_yellow_decision_makers);
        let inactivePercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_grey_decision_makers);
        let churnPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_black_decision_makers);
        let badPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_red_decision_makers);
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        goodChart?.data.push(goodPercentage);
        fairChart?.data.push(fairPercentage);
        badChart?.data.push(badPercentage);
        inactiveChart?.data.push(inactivePercentage);
        churnChart?.data.push(churnPercentage);

        goodChart?.counts.push(c2HealthScore.no_of_green_decision_makers);
        fairChart?.counts.push(c2HealthScore.no_of_yellow_decision_makers);
        inactiveChart?.counts.push(c2HealthScore.no_of_grey_decision_makers);
        badChart?.counts.push(c2HealthScore.no_of_red_decision_makers);
        churnChart?.counts.push(c2HealthScore.no_of_black_decision_makers);
      } else if (customerOverviewHealthTrendSelectedFilter.value?.value === filterOptionsCustomer[2].value) {
        let goodPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_green_influencers);
        let fairPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_yellow_influencers);
        let badPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_red_influencers);
        let inactivePercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_grey_influencers);
        let churnPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_black_influencers);
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        goodChart?.data.push(goodPercentage);
        fairChart?.data.push(fairPercentage);
        badChart?.data.push(badPercentage);
        inactiveChart?.data.push(inactivePercentage);
        churnChart?.data.push(churnPercentage);

        goodChart?.counts.push(c2HealthScore.no_of_green_influencers);
        fairChart?.counts.push(c2HealthScore.no_of_yellow_influencers);
        badChart?.counts.push(c2HealthScore.no_of_red_influencers);
        inactiveChart?.counts.push(c2HealthScore.no_of_grey_influencers);
        churnChart?.counts.push(c2HealthScore.no_of_black_influencers);
      } else {
        let goodPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_green_normal_users);
        let fairPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_yellow_normal_users);
        let badPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_red_normal_users);
        let inactivePercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_grey_normal_users);
        let churnPercentage = +formatNumberRoundOfDecimal(c2HealthScore.percent_of_black_normal_users);
        const total = goodPercentage + fairPercentage + badPercentage + inactivePercentage + churnPercentage;
        if (total !== 0) {
          const difference = Math.abs(total - 100);
          if (churnPercentage) {
            churnPercentage = total > 100 ? churnPercentage - difference : churnPercentage + difference;
          } else if (inactivePercentage) {
            inactivePercentage = total > 100 ? inactivePercentage - difference : inactivePercentage + difference;
          } else if (badPercentage) {
            badPercentage = total > 100 ? badPercentage - difference : badPercentage + difference;
          } else if (fairPercentage) {
            fairPercentage = total > 100 ? fairPercentage - difference : fairPercentage + difference;
          } else {
            goodPercentage = total > 100 ? goodPercentage - difference : goodPercentage + difference;
          }
        }
        goodChart?.data.push(goodPercentage);
        fairChart?.data.push(fairPercentage);
        badChart?.data.push(badPercentage);
        inactiveChart?.data.push(inactivePercentage);
        churnChart?.data.push(churnPercentage);

        goodChart?.counts.push(c2HealthScore.no_of_green_normal_users);
        fairChart?.counts.push(c2HealthScore.no_of_yellow_normal_users);
        badChart?.counts.push(c2HealthScore.no_of_red_normal_users);
        inactiveChart?.counts.push(c2HealthScore.no_of_grey_normal_users);
        churnChart?.counts.push(c2HealthScore.no_of_black_normal_users);
      }
    }
    return {
      labels: listOfLabel,
      datasets: [...chartDatasets],
    };
  });

  return {
    filterOptions,
    filterOptionsCustomer,
    dashboardBarChartDataSet,
    customerOverviewBarChartDataSet,
    barChartDataSet,
  };
};
