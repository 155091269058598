/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePlaybookRemedialStepDto } from '../model/';
// @ts-ignore
import { PlaybookRemedialStep } from '../model/';
// @ts-ignore
import { UpdatePlaybookRemedialStepDto } from '../model/';
/**
 * PlaybookRemedialStepApi - axios parameter creator
 * @export
 */
export const PlaybookRemedialStepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  Create a Playbook Remedial Step
         * @param {CreatePlaybookRemedialStepDto} createPlaybookRemedialStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerCreate: async (createPlaybookRemedialStepDto: CreatePlaybookRemedialStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlaybookRemedialStepDto' is not null or undefined
            assertParamExists('playbookRemedialStepControllerCreate', 'createPlaybookRemedialStepDto', createPlaybookRemedialStepDto)
            const localVarPath = `/api/playbook-remedial-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaybookRemedialStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Playbook Remedial Step
         * @param {string} [xTenantId] 
         * @param {string} [taskId] Optional parameter, if present then all remedial steps with same task id will be returned else return all remedial steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerFindAll: async (xTenantId?: string, taskId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-remedial-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Playbook-Remedial-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookRemedialStepControllerRemove', 'id', id)
            const localVarPath = `/api/playbook-remedial-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Playbook-Remedial-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookRemedialStepDto} updatePlaybookRemedialStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerUpdate: async (id: string, updatePlaybookRemedialStepDto: UpdatePlaybookRemedialStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookRemedialStepControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookRemedialStepDto' is not null or undefined
            assertParamExists('playbookRemedialStepControllerUpdate', 'updatePlaybookRemedialStepDto', updatePlaybookRemedialStepDto)
            const localVarPath = `/api/playbook-remedial-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookRemedialStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookRemedialStepApi - functional programming interface
 * @export
 */
export const PlaybookRemedialStepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookRemedialStepApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook Remedial Step
         * @param {CreatePlaybookRemedialStepDto} createPlaybookRemedialStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookRemedialStepControllerCreate(createPlaybookRemedialStepDto: CreatePlaybookRemedialStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookRemedialStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookRemedialStepControllerCreate(createPlaybookRemedialStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Playbook Remedial Step
         * @param {string} [xTenantId] 
         * @param {string} [taskId] Optional parameter, if present then all remedial steps with same task id will be returned else return all remedial steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookRemedialStepControllerFindAll(xTenantId?: string, taskId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookRemedialStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookRemedialStepControllerFindAll(xTenantId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Playbook-Remedial-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookRemedialStepControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookRemedialStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookRemedialStepControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Playbook-Remedial-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookRemedialStepDto} updatePlaybookRemedialStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookRemedialStepControllerUpdate(id: string, updatePlaybookRemedialStepDto: UpdatePlaybookRemedialStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookRemedialStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookRemedialStepControllerUpdate(id, updatePlaybookRemedialStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookRemedialStepApi - factory interface
 * @export
 */
export const PlaybookRemedialStepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookRemedialStepApiFp(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook Remedial Step
         * @param {CreatePlaybookRemedialStepDto} createPlaybookRemedialStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerCreate(createPlaybookRemedialStepDto: CreatePlaybookRemedialStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookRemedialStep> {
            return localVarFp.playbookRemedialStepControllerCreate(createPlaybookRemedialStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Playbook Remedial Step
         * @param {string} [xTenantId] 
         * @param {string} [taskId] Optional parameter, if present then all remedial steps with same task id will be returned else return all remedial steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerFindAll(xTenantId?: string, taskId?: string, options?: any): AxiosPromise<Array<PlaybookRemedialStep>> {
            return localVarFp.playbookRemedialStepControllerFindAll(xTenantId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Playbook-Remedial-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<PlaybookRemedialStep> {
            return localVarFp.playbookRemedialStepControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Playbook-Remedial-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookRemedialStepDto} updatePlaybookRemedialStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookRemedialStepControllerUpdate(id: string, updatePlaybookRemedialStepDto: UpdatePlaybookRemedialStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookRemedialStep> {
            return localVarFp.playbookRemedialStepControllerUpdate(id, updatePlaybookRemedialStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookRemedialStepControllerCreate operation in PlaybookRemedialStepApi.
 * @export
 * @interface PlaybookRemedialStepApiPlaybookRemedialStepControllerCreateRequest
 */
export interface PlaybookRemedialStepApiPlaybookRemedialStepControllerCreateRequest {
    /**
     * 
     * @type {CreatePlaybookRemedialStepDto}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerCreate
     */
    readonly createPlaybookRemedialStepDto: CreatePlaybookRemedialStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookRemedialStepControllerFindAll operation in PlaybookRemedialStepApi.
 * @export
 * @interface PlaybookRemedialStepApiPlaybookRemedialStepControllerFindAllRequest
 */
export interface PlaybookRemedialStepApiPlaybookRemedialStepControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional parameter, if present then all remedial steps with same task id will be returned else return all remedial steps
     * @type {string}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerFindAll
     */
    readonly taskId?: string
}

/**
 * Request parameters for playbookRemedialStepControllerRemove operation in PlaybookRemedialStepApi.
 * @export
 * @interface PlaybookRemedialStepApiPlaybookRemedialStepControllerRemoveRequest
 */
export interface PlaybookRemedialStepApiPlaybookRemedialStepControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookRemedialStepControllerUpdate operation in PlaybookRemedialStepApi.
 * @export
 * @interface PlaybookRemedialStepApiPlaybookRemedialStepControllerUpdateRequest
 */
export interface PlaybookRemedialStepApiPlaybookRemedialStepControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookRemedialStepDto}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerUpdate
     */
    readonly updatePlaybookRemedialStepDto: UpdatePlaybookRemedialStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookRemedialStepApiPlaybookRemedialStepControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlaybookRemedialStepApi - object-oriented interface
 * @export
 * @class PlaybookRemedialStepApi
 * @extends {BaseAPI}
 */
export class PlaybookRemedialStepApi extends BaseAPI {
    /**
     * 
     * @summary  Create a Playbook Remedial Step
     * @param {PlaybookRemedialStepApiPlaybookRemedialStepControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookRemedialStepApi
     */
    public playbookRemedialStepControllerCreate(requestParameters: PlaybookRemedialStepApiPlaybookRemedialStepControllerCreateRequest, options?: AxiosRequestConfig) {
        return PlaybookRemedialStepApiFp(this.configuration).playbookRemedialStepControllerCreate(requestParameters.createPlaybookRemedialStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Playbook Remedial Step
     * @param {PlaybookRemedialStepApiPlaybookRemedialStepControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookRemedialStepApi
     */
    public playbookRemedialStepControllerFindAll(requestParameters: PlaybookRemedialStepApiPlaybookRemedialStepControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookRemedialStepApiFp(this.configuration).playbookRemedialStepControllerFindAll(requestParameters.xTenantId, requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Playbook-Remedial-Step by id
     * @param {PlaybookRemedialStepApiPlaybookRemedialStepControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookRemedialStepApi
     */
    public playbookRemedialStepControllerRemove(requestParameters: PlaybookRemedialStepApiPlaybookRemedialStepControllerRemoveRequest, options?: AxiosRequestConfig) {
        return PlaybookRemedialStepApiFp(this.configuration).playbookRemedialStepControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Playbook-Remedial-Step by id
     * @param {PlaybookRemedialStepApiPlaybookRemedialStepControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookRemedialStepApi
     */
    public playbookRemedialStepControllerUpdate(requestParameters: PlaybookRemedialStepApiPlaybookRemedialStepControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookRemedialStepApiFp(this.configuration).playbookRemedialStepControllerUpdate(requestParameters.id, requestParameters.updatePlaybookRemedialStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
