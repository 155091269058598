import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Filler,
} from 'chart.js';
import { Flow, SankeyController } from 'chartjs-chart-sankey';

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  Flow,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  SankeyController,
  Tooltip,
  Filler,
);
