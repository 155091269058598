/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { U2FeatureDataDto } from '../model/';
// @ts-ignore
import { U2FeatureDataFilterDto } from '../model/';
/**
 * U2FeatureDataApi - axios parameter creator
 * @export
 */
export const U2FeatureDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all feature data against feature and date id
         * @param {U2FeatureDataFilterDto} u2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2FeatureDataControllerFindAllFeatureData: async (u2FeatureDataFilterDto: U2FeatureDataFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2FeatureDataFilterDto' is not null or undefined
            assertParamExists('u2FeatureDataControllerFindAllFeatureData', 'u2FeatureDataFilterDto', u2FeatureDataFilterDto)
            const localVarPath = `/api/u2-feature-data/history-u2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(u2FeatureDataFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2FeatureDataApi - functional programming interface
 * @export
 */
export const U2FeatureDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2FeatureDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of all feature data against feature and date id
         * @param {U2FeatureDataFilterDto} u2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2FeatureDataControllerFindAllFeatureData(u2FeatureDataFilterDto: U2FeatureDataFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2FeatureDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2FeatureDataControllerFindAllFeatureData(u2FeatureDataFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2FeatureDataApi - factory interface
 * @export
 */
export const U2FeatureDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2FeatureDataApiFp(configuration)
    return {
        /**
         * 
         * @summary List of all feature data against feature and date id
         * @param {U2FeatureDataFilterDto} u2FeatureDataFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2FeatureDataControllerFindAllFeatureData(u2FeatureDataFilterDto: U2FeatureDataFilterDto, xTenantId?: string, options?: any): AxiosPromise<U2FeatureDataDto> {
            return localVarFp.u2FeatureDataControllerFindAllFeatureData(u2FeatureDataFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2FeatureDataControllerFindAllFeatureData operation in U2FeatureDataApi.
 * @export
 * @interface U2FeatureDataApiU2FeatureDataControllerFindAllFeatureDataRequest
 */
export interface U2FeatureDataApiU2FeatureDataControllerFindAllFeatureDataRequest {
    /**
     * 
     * @type {U2FeatureDataFilterDto}
     * @memberof U2FeatureDataApiU2FeatureDataControllerFindAllFeatureData
     */
    readonly u2FeatureDataFilterDto: U2FeatureDataFilterDto

    /**
     * 
     * @type {string}
     * @memberof U2FeatureDataApiU2FeatureDataControllerFindAllFeatureData
     */
    readonly xTenantId?: string
}

/**
 * U2FeatureDataApi - object-oriented interface
 * @export
 * @class U2FeatureDataApi
 * @extends {BaseAPI}
 */
export class U2FeatureDataApi extends BaseAPI {
    /**
     * 
     * @summary List of all feature data against feature and date id
     * @param {U2FeatureDataApiU2FeatureDataControllerFindAllFeatureDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2FeatureDataApi
     */
    public u2FeatureDataControllerFindAllFeatureData(requestParameters: U2FeatureDataApiU2FeatureDataControllerFindAllFeatureDataRequest, options?: AxiosRequestConfig) {
        return U2FeatureDataApiFp(this.configuration).u2FeatureDataControllerFindAllFeatureData(requestParameters.u2FeatureDataFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
