<script setup lang="ts">
import { computed } from 'vue';
import { ICustomersTableCaret } from '@/types/customerslist.type';
import { useColorFormatter } from '@/composables/useColorFormatter';
import { useAmountFormatter } from '@/composables/useAmountFormatter';

const props = defineProps<{
  data: ICustomersTableCaret;
}>();

const { formatNumberRoundOfDecimal, roundOfPositiveString } = useAmountFormatter();
const { getColorCodeAgainstString, getCaretStyle, getCaretType, setVisibility } = useColorFormatter();

const visibility = computed(() => {
  if (props.data.valueString) {
    return props.data.valueString;
  }
  if (props.data.value !== undefined) {
    return props.data.value;
  }
  return undefined;
});
</script>

<template>
  <div class="tw-flex tw-items-baseline" :class="setVisibility(visibility)">
    <span
      v-if="props.data.valueString && props.data.valueString.length > 0"
      class="tw-block tw-text-xl tw-font-bold"
      :class="getColorCodeAgainstString(props.data.colorCode)"
    >
      {{ props.data.valueString }}
    </span>
    <span v-else class="tw-block tw-text-xl tw-font-bold" :class="getColorCodeAgainstString(props.data.colorCode)">
      {{ formatNumberRoundOfDecimal(props.data.value) }}
    </span>
    <span class="tw-ml-1" :class="setVisibility(props.data.percentage)">
      <q-icon
        :name="getCaretType(props.data.percentage)"
        class="tw-text-[0.625rem]"
        :class="getCaretStyle(props.data.percentage)"
      />
      <span class="tw-text-sm tw-text-dark-shade-1"> {{ roundOfPositiveString(props.data.percentage) }} </span>
    </span>
  </div>
</template>
