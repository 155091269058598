import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';

export const useCustomDateUtil = () => {
  const monthShortName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  /* Get Quarter start date against quarter start month
   * @param date
   * @param startMonth: 0-11 (where 0 is January)
   * @returns Quarter start date
   */
  function startOfCustomQuarter(date: Date, startMonth: number = 0) {
    const month = date.getMonth();
    const quarter = Math.floor(((12 + month - startMonth) % 12) / 3);

    // Calculate first month of the quarter
    const quarterStartMonth = startMonth + quarter * 3;

    // If quarterStartMonth > 11, we need to adjust the year
    if (quarterStartMonth > 11) {
      return startOfMonth(addMonths(date, -(month - (quarterStartMonth % 12))));
    }

    return startOfMonth(subMonths(date, month - quarterStartMonth));
  }

  /** Get Quarter end date against quarter start month
   * @param date
   * @param startMonth: 0-11 (where 0 is January)
   * @returns Quarter end date
   */
  function getCustomQuarterNumber(date: Date, startMonth: number = 0) {
    const month = date.getMonth();
    return Math.floor(((12 + month - startMonth) % 12) / 3) + 1;
  }

  function endOfCustomQuarter(date: Date, quarterStartMonth: number = 0) {
    // Normalize quarterStartMonth to be between 0 and 11
    quarterStartMonth = ((quarterStartMonth % 12) + 12) % 12;

    // Calculate months since quarter start
    const currentMonth = date.getMonth();
    const monthsSinceQuarterStart = (currentMonth - quarterStartMonth + 12) % 12;
    const monthsUntilQuarterEnd = 2 - (monthsSinceQuarterStart % 3);

    // Add months to reach quarter end and return end of that month
    return endOfMonth(addMonths(date, monthsUntilQuarterEnd));
  }

  /**
   * Gets the custom year number based on a start month
   * @param {Date} date - The date to check
   * @param {number} startMonth - Month number (1-12) when the year should start
   * @returns {number} The custom year number
   */
  function getCustomYear(date: Date, startMonth: number = 1) {
    const month = date.getMonth() + 1; // JavaScript months are 0-based
    const year = date.getFullYear();

    // If current month is before start month, we're in previous custom year
    return month < startMonth ? year : year + 1;
  }

  /**
   * Gets the start date of a custom year
   * @param {number} customYear - The custom year number
   * @param {number} startMonth - Month number (1-12) when the year should start
   * @returns {Date} The start date of the custom year
   */
  function getCustomYearStart(customYear: number, startMonth: number = 1) {
    // Custom year 2024 starting in month 4 (April) would be April 1, 2023
    return new Date(customYear - 1, startMonth - 1, 1);
  }

  /**
   * Gets the end date of a custom year
   * @param {number} customYear - The custom year number
   * @param {number} startMonth - Month number (1-12) when the year should start
   * @returns {Date} The end date of the custom year
   */
  function getCustomYearEnd(customYear: number, startMonth: number = 1) {
    // Custom year 2024 starting in month 4 would end on March 31, 2024
    const endDate = new Date(customYear, startMonth - 1, 1);
    endDate.setDate(endDate.getDate() - 1);
    return endDate;
  }

  /**
   * Checks if a date falls within a custom year
   * @param {Date} date - The date to check
   * @param {number} customYear - The custom year number
   * @param {number} startMonth - Month number (1-12) when the year should start
   * @returns {boolean} Whether the date falls within the custom year
   */
  function isInCustomYear(date: Date, customYear: number, startMonth: number = 1) {
    const start = getCustomYearStart(customYear, startMonth);
    const end = getCustomYearEnd(customYear, startMonth);
    return date >= start && date <= end;
  }

  /**
   * Gets an array of dates for all custom year boundaries within a date range
   * @param {Date} startDate - The start of the date range
   * @param {Date} endDate - The end of the date range
   * @param {number} startMonth - Month number (1-12) when the year should start
   * @returns {Date[]} Array of year boundary dates
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getCustomYearBoundaries(startDate: Date, endDate: Date, startMonth: number = 1) {
    const boundaries = [];
    let currentYear = getCustomYear(startDate, startMonth);
    const endYear = getCustomYear(endDate, startMonth);

    while (currentYear <= endYear) {
      boundaries.push(getCustomYearStart(currentYear, startMonth));
      currentYear++;
    }

    return boundaries;
  }

  return {
    monthShortName,
    startOfCustomQuarter,
    endOfCustomQuarter,
    getCustomQuarterNumber,
    getCustomYearStart,
    getCustomYearEnd,
    isInCustomYear,
    getCustomYearBoundaries,
  };
};
