import { customFieldsDefinitionApi } from '@/api';
import { CustomFieldsDefinition } from '@/api/client';
import { CustomFieldSourceEnum } from '@zapscale/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCustomFieldsDefinitionStore = defineStore('customFieldsDefinition', () => {
  const apiCustomFieldsDefinition = ref<CustomFieldsDefinition[]>();
  const peripheralCustomFieldsDefinition = ref<CustomFieldsDefinition[]>();
  const webCsvCustomFieldsDefinition = ref<CustomFieldsDefinition[]>();

  async function fetchWebCsvCustomFieldsDefinition() {
    try {
      const { data } = await customFieldsDefinitionApi.customFieldsDefinitionControllerFindAll({
        source: CustomFieldSourceEnum.web_csv,
      });

      webCsvCustomFieldsDefinition.value = data;
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchApiCustomFieldsDefinition() {
    try {
      const { data } = await customFieldsDefinitionApi.customFieldsDefinitionControllerFindAll({
        source: CustomFieldSourceEnum.api,
      });

      apiCustomFieldsDefinition.value = data;
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchPeripheralCustomFieldsDefinition() {
    try {
      const { data } = await customFieldsDefinitionApi.customFieldsDefinitionControllerFindAll({
        source: CustomFieldSourceEnum.peripheral,
      });

      peripheralCustomFieldsDefinition.value = data;
    } catch (e) {
      console.error(e);
    }
  }

  return {
    apiCustomFieldsDefinition,
    webCsvCustomFieldsDefinition,
    peripheralCustomFieldsDefinition,
    fetchWebCsvCustomFieldsDefinition,
    fetchApiCustomFieldsDefinition,
    fetchPeripheralCustomFieldsDefinition,
  };
});
