import { appNotificationsApi } from '@/api';
import { AppNotification } from '@/api/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';

export const appNotificationsQueryKeys = {
  list: () => ['list-app-notifications'] as const,
  readOne: () => ['read-one'] as const,
  readAll: () => ['read-all'] as const,
};

export const useFetchAppNotificationsQuery = () => {
  return useQuery({
    queryKey: appNotificationsQueryKeys.list(),
    queryFn: async () => {
      const { data } = await appNotificationsApi.appNotificationsControllerFindAll();
      return data;
    },
    refetchInterval: 10000,
  });
};

export const useReadOneQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: appNotificationsQueryKeys.readOne(),
    mutationFn: async (id: string) => {
      const { data } = await appNotificationsApi.appNotificationsControllerRead({ id: id });
      return data;
    },
    onSuccess: (data) => {
      const notifications = queryClient.getQueryData(appNotificationsQueryKeys.list()) as AppNotification[];
      const updatedNotifications = [...notifications];
      const index = updatedNotifications?.findIndex((x) => x.id === data.id) ?? -1;
      if (index > -1) {
        updatedNotifications?.splice(index, 1);
      }
      queryClient.setQueryData(appNotificationsQueryKeys.list(), updatedNotifications);
    },
  });
};

export const useReadAllQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: appNotificationsQueryKeys.readAll(),
    mutationFn: async (ids: string[]) => {
      const { data } = await appNotificationsApi.appNotificationsControllerReadAll({ ids: ids });
      return data;
    },
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData(appNotificationsQueryKeys.list(), []);
      }
    },
  });
};
