/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Cadence } from '../model/';
/**
 * CadenceApi - axios parameter creator
 * @export
 */
export const CadenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of cadence
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadenceControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cadence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find cadence by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadenceControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cadenceControllerFindOne', 'id', id)
            const localVarPath = `/api/cadence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CadenceApi - functional programming interface
 * @export
 */
export const CadenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CadenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of cadence
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cadenceControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Cadence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cadenceControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find cadence by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cadenceControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cadence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cadenceControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CadenceApi - factory interface
 * @export
 */
export const CadenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CadenceApiFp(configuration)
    return {
        /**
         * 
         * @summary List of cadence
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadenceControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Cadence>> {
            return localVarFp.cadenceControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find cadence by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadenceControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Cadence> {
            return localVarFp.cadenceControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cadenceControllerFindAll operation in CadenceApi.
 * @export
 * @interface CadenceApiCadenceControllerFindAllRequest
 */
export interface CadenceApiCadenceControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof CadenceApiCadenceControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for cadenceControllerFindOne operation in CadenceApi.
 * @export
 * @interface CadenceApiCadenceControllerFindOneRequest
 */
export interface CadenceApiCadenceControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof CadenceApiCadenceControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CadenceApiCadenceControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * CadenceApi - object-oriented interface
 * @export
 * @class CadenceApi
 * @extends {BaseAPI}
 */
export class CadenceApi extends BaseAPI {
    /**
     * 
     * @summary List of cadence
     * @param {CadenceApiCadenceControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadenceApi
     */
    public cadenceControllerFindAll(requestParameters: CadenceApiCadenceControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return CadenceApiFp(this.configuration).cadenceControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find cadence by id
     * @param {CadenceApiCadenceControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadenceApi
     */
    public cadenceControllerFindOne(requestParameters: CadenceApiCadenceControllerFindOneRequest, options?: AxiosRequestConfig) {
        return CadenceApiFp(this.configuration).cadenceControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
