/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateSubscriptionOptionDto } from '../model/';
// @ts-ignore
import { SubscriptionOption } from '../model/';
// @ts-ignore
import { UpdateSubscriptionOptionDto } from '../model/';
/**
 * SubscriptionOptionApi - axios parameter creator
 * @export
 */
export const SubscriptionOptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Subscription Option
         * @param {CreateSubscriptionOptionDto} createSubscriptionOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerCreate: async (createSubscriptionOptionDto: CreateSubscriptionOptionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSubscriptionOptionDto' is not null or undefined
            assertParamExists('subscriptionOptionsControllerCreate', 'createSubscriptionOptionDto', createSubscriptionOptionDto)
            const localVarPath = `/api/subscription-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubscriptionOptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Subscription Option
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerDelete: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscriptionOptionsControllerDelete', 'id', id)
            const localVarPath = `/api/subscription-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Subscription Options
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/subscription-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Subscription Option
         * @param {string} id 
         * @param {UpdateSubscriptionOptionDto} updateSubscriptionOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerUpdate: async (id: string, updateSubscriptionOptionDto: UpdateSubscriptionOptionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscriptionOptionsControllerUpdate', 'id', id)
            // verify required parameter 'updateSubscriptionOptionDto' is not null or undefined
            assertParamExists('subscriptionOptionsControllerUpdate', 'updateSubscriptionOptionDto', updateSubscriptionOptionDto)
            const localVarPath = `/api/subscription-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionOptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionOptionApi - functional programming interface
 * @export
 */
export const SubscriptionOptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionOptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Subscription Option
         * @param {CreateSubscriptionOptionDto} createSubscriptionOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionOptionsControllerCreate(createSubscriptionOptionDto: CreateSubscriptionOptionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionOptionsControllerCreate(createSubscriptionOptionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Subscription Option
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionOptionsControllerDelete(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionOptionsControllerDelete(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Subscription Options
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionOptionsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionOptionsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Subscription Option
         * @param {string} id 
         * @param {UpdateSubscriptionOptionDto} updateSubscriptionOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionOptionsControllerUpdate(id: string, updateSubscriptionOptionDto: UpdateSubscriptionOptionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionOptionsControllerUpdate(id, updateSubscriptionOptionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionOptionApi - factory interface
 * @export
 */
export const SubscriptionOptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionOptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Subscription Option
         * @param {CreateSubscriptionOptionDto} createSubscriptionOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerCreate(createSubscriptionOptionDto: CreateSubscriptionOptionDto, xTenantId?: string, options?: any): AxiosPromise<SubscriptionOption> {
            return localVarFp.subscriptionOptionsControllerCreate(createSubscriptionOptionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Subscription Option
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerDelete(id: string, xTenantId?: string, options?: any): AxiosPromise<SubscriptionOption> {
            return localVarFp.subscriptionOptionsControllerDelete(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Subscription Options
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<SubscriptionOption>> {
            return localVarFp.subscriptionOptionsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Subscription Option
         * @param {string} id 
         * @param {UpdateSubscriptionOptionDto} updateSubscriptionOptionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOptionsControllerUpdate(id: string, updateSubscriptionOptionDto: UpdateSubscriptionOptionDto, xTenantId?: string, options?: any): AxiosPromise<SubscriptionOption> {
            return localVarFp.subscriptionOptionsControllerUpdate(id, updateSubscriptionOptionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for subscriptionOptionsControllerCreate operation in SubscriptionOptionApi.
 * @export
 * @interface SubscriptionOptionApiSubscriptionOptionsControllerCreateRequest
 */
export interface SubscriptionOptionApiSubscriptionOptionsControllerCreateRequest {
    /**
     * 
     * @type {CreateSubscriptionOptionDto}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerCreate
     */
    readonly createSubscriptionOptionDto: CreateSubscriptionOptionDto

    /**
     * 
     * @type {string}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for subscriptionOptionsControllerDelete operation in SubscriptionOptionApi.
 * @export
 * @interface SubscriptionOptionApiSubscriptionOptionsControllerDeleteRequest
 */
export interface SubscriptionOptionApiSubscriptionOptionsControllerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerDelete
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for subscriptionOptionsControllerFindAll operation in SubscriptionOptionApi.
 * @export
 * @interface SubscriptionOptionApiSubscriptionOptionsControllerFindAllRequest
 */
export interface SubscriptionOptionApiSubscriptionOptionsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for subscriptionOptionsControllerUpdate operation in SubscriptionOptionApi.
 * @export
 * @interface SubscriptionOptionApiSubscriptionOptionsControllerUpdateRequest
 */
export interface SubscriptionOptionApiSubscriptionOptionsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSubscriptionOptionDto}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerUpdate
     */
    readonly updateSubscriptionOptionDto: UpdateSubscriptionOptionDto

    /**
     * 
     * @type {string}
     * @memberof SubscriptionOptionApiSubscriptionOptionsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * SubscriptionOptionApi - object-oriented interface
 * @export
 * @class SubscriptionOptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionOptionApi extends BaseAPI {
    /**
     * 
     * @summary Create Subscription Option
     * @param {SubscriptionOptionApiSubscriptionOptionsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionOptionApi
     */
    public subscriptionOptionsControllerCreate(requestParameters: SubscriptionOptionApiSubscriptionOptionsControllerCreateRequest, options?: AxiosRequestConfig) {
        return SubscriptionOptionApiFp(this.configuration).subscriptionOptionsControllerCreate(requestParameters.createSubscriptionOptionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Subscription Option
     * @param {SubscriptionOptionApiSubscriptionOptionsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionOptionApi
     */
    public subscriptionOptionsControllerDelete(requestParameters: SubscriptionOptionApiSubscriptionOptionsControllerDeleteRequest, options?: AxiosRequestConfig) {
        return SubscriptionOptionApiFp(this.configuration).subscriptionOptionsControllerDelete(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Subscription Options
     * @param {SubscriptionOptionApiSubscriptionOptionsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionOptionApi
     */
    public subscriptionOptionsControllerFindAll(requestParameters: SubscriptionOptionApiSubscriptionOptionsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return SubscriptionOptionApiFp(this.configuration).subscriptionOptionsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Subscription Option
     * @param {SubscriptionOptionApiSubscriptionOptionsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionOptionApi
     */
    public subscriptionOptionsControllerUpdate(requestParameters: SubscriptionOptionApiSubscriptionOptionsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return SubscriptionOptionApiFp(this.configuration).subscriptionOptionsControllerUpdate(requestParameters.id, requestParameters.updateSubscriptionOptionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
