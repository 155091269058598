<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue';
import { ChartData } from 'chart.js';
import { useDashboardStore } from '@/stores/dashboard';
import { useHealthTrendChart } from '@/composables/useHealthTrendChart';
import { useCustomerTypeTrendChart } from '@/composables/useCustomerTypeTrendChart';
import { useHealthMovementChart } from '@/composables/useHealthMovementChart';
import Greeter from '@/components/Dashboard/Greeter.vue';
import Overview from '@/components/Dashboard/Overview.vue';
import CustomerHealthTrend from '@/components/Charts/CustomerHealthTrend.vue';
import CustomerHealthMovement from '@/components/Charts/CustomerHealthMovement.vue';
import CustomerMovementTrend from '@/components/Charts/CustomerMovementTrend.vue';
import DashboardTable from '@/components/Dashboard/DashboardTable.vue';
import { useC2TypeStore } from '@/stores/c2Type';
import { useC1HealthScoreStore } from '@/stores/c1HealthScore';
import { useDateStore } from '@/stores/date';
import { DateInfo, DateTypeEnum, HealthColorEnum, Kpi, KpiNameEnum } from '@/api/client';
import { useC2TypeHealthScore } from '@/stores/c2TypeHealthScore';
import { useMovementTrendChart } from '@/composables/useMovementTrendChart';
import LineChart from '@/components/Charts/LineChart.vue';
import CustomerUserHealthD3 from '../components/Charts/CustomerUserHealthD3.vue';
import { useRoute, useRouter } from 'vue-router';
import { RouteName } from '@/router/route-name';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { useKpiStore } from '@/stores/kpi';
import { storeToRefs } from 'pinia';
import { IQueryParam } from '@/types/query.type';
import { useAccountStore } from '@/stores/account';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { QPage } from 'quasar';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const { fetchC2Types } = useC2TypeStore();
const kpiStore = useKpiStore();
const dashboardStore = useDashboardStore();
const customerTypeTrendChart = useCustomerTypeTrendChart();
const healthTrendChart = useHealthTrendChart();
const movementTrendChart = useMovementTrendChart();
const healthMovementChart = useHealthMovementChart();

const c1HealthScoreStore = useC1HealthScoreStore();
const c2TypeHealthScoreStore = useC2TypeHealthScore();

const { selectedLineChartFilter } = storeToRefs(dashboardStore);

const router = useRouter();
const route = useRoute();

const _accountStore = useAccountStore();
const { activeU1Id } = storeToRefs(_accountStore);

const { getDateString } = useDateFormatter();

const dateStore = useDateStore();
const { dateInfo } = storeToRefs(dateStore);
const { initialize, setupDateFromQueryDateString, getCustomerMovementTrendDateLabel } = dateStore;

watch(
  route,
  async (routeValue) => {
    const name = routeValue.name;
    if (name !== RouteName.Dashboard) {
      return;
    }
    const dateQuery = routeValue.query.date as string;
    const dateTypeQuery = routeValue.query.date_type as DateTypeEnum;
    if (dateInfo.value && dateInfo.value?.date === dateQuery && dateInfo.value?.type === dateTypeQuery) {
      return;
    }
    if (!dateQuery || !dateTypeQuery) {
      initialize();
    } else {
      setupDateFromQueryDateString(dateQuery, dateTypeQuery);
    }
    if (!dateInfo.value) {
      return;
    }
    const { date, type } = dateInfo.value;
    if (!date || !type) {
      return;
    }
    fetchApisDependsOnDateSelection(dateInfo.value);
  },
  {
    immediate: true,
    deep: true,
  },
);

onBeforeMount(async () => {
  Promise.all([kpiStore.fetchKpiList(), fetchC2Types(), dashboardStore.fetchC2Count(), _accountStore.fetchC1()]);
});

async function fetchApisDependsOnDateSelection(dateInfo: DateInfo) {
  await c1HealthScoreStore.fetchC1HealthScore(dateInfo);

  c1HealthScoreStore.fetchC2HealthScoreColorAggregation(dateInfo);

  await Promise.all([
    c2TypeHealthScoreStore.fetchC2TypeHealthScoresSameDay(dateInfo.date, dateInfo.type),
    c1HealthScoreStore.fetchC1HealthScoreList(dateInfo.date, dateInfo.type),
    c1HealthScoreStore.fetchC1HealthScoreCustomerMovementTrendList(dateInfo.date, dateInfo.type),
  ]);
}

async function updateRouteOnDateChange(dateString: string, dateType: DateTypeEnum) {
  if (!route.name) {
    return;
  }
  const queryParam = {
    ...route.query,
    date: dateString,
    date_type: dateType,
  } as IQueryParam;
  router.replace({
    name: route.name,
    query: { ...queryParam },
  });
}

const movementTrendDataset = computed(() => {
  let showAggregation = true;
  let c1HealthScores = c1HealthScoreStore.c1HealthScoreCustomerMovementTrendList;
  if (c1HealthScores.length === 0) {
    showAggregation = false;
    c1HealthScores = c1HealthScoreStore.c1HealthScoreList;
  }

  const barChartDataSet = [...movementTrendChart.barChartDataSet];
  barChartDataSet.forEach((x) => (x.data = [] as number[][]));

  let listOfLabel: string[] = [];
  const dateList = [...new Set(c1HealthScores.map((x) => x.date))].sort();
  const selectedFilter = dashboardStore.movementTrendSelectedFilter;
  for (let i = 0; i < dateList.length; i++) {
    const date = dateList[i];
    listOfLabel.push(getCustomerMovementTrendDateLabel(date, showAggregation));
    const c1HealthScore = c1HealthScores.find((x) => x.date === date);
    if (!c1HealthScore) {
      continue;
    }
    let newCustomerYAxisUpperLimit = 0;
    const totalChart = barChartDataSet.find((x) => x.label === 'Total Customers');
    if (selectedFilter === healthTrendChart.filterOptions[0].value) {
      if (c1HealthScore.total_c2s !== undefined && c1HealthScore.total_c2s > 0) {
        totalChart?.data.push([0, c1HealthScore.total_c2s]);
        newCustomerYAxisUpperLimit = c1HealthScore.total_c2s;
      } else {
        totalChart?.data.push([0, 0]);
      }
    } else {
      if (c1HealthScore.total_acv_of_all_c2s !== undefined && c1HealthScore.total_acv_of_all_c2s > 0) {
        totalChart?.data.push([0, c1HealthScore.total_acv_of_all_c2s]);
        newCustomerYAxisUpperLimit = c1HealthScore.total_acv_of_all_c2s;
      } else {
        totalChart?.data.push([0, 0]);
      }
    }

    const newChart = barChartDataSet.find((x) => x.label === 'New Customers');
    if (selectedFilter === healthTrendChart.filterOptions[0].value) {
      if (c1HealthScore.no_of_c2_new_addition !== undefined && c1HealthScore.no_of_c2_new_addition > 0) {
        newChart?.data.push([c1HealthScore.total_c2s, c1HealthScore.total_c2s + c1HealthScore.no_of_c2_new_addition]);
        newCustomerYAxisUpperLimit = c1HealthScore.total_c2s + c1HealthScore.no_of_c2_new_addition;
      } else {
        newChart?.data.push([0, 0]);
      }
    } else {
      if (c1HealthScore.new_addition_c2_acv !== undefined && c1HealthScore.new_addition_c2_acv > 0) {
        newChart?.data.push([
          c1HealthScore.total_c2s,
          c1HealthScore.total_acv_of_all_c2s + c1HealthScore.new_addition_c2_acv,
        ]);
        newCustomerYAxisUpperLimit = c1HealthScore.total_c2s + c1HealthScore.new_addition_c2_acv;
      } else {
        newChart?.data.push([0, 0]);
      }
    }

    const churnedChart = barChartDataSet.find((x) => x.label === 'Churned Customers');
    if (selectedFilter === healthTrendChart.filterOptions[0].value) {
      if (c1HealthScore.no_of_c2_churns !== undefined && c1HealthScore.no_of_c2_churns > 0) {
        churnedChart?.data.push([
          newCustomerYAxisUpperLimit - c1HealthScore.no_of_c2_churns,
          newCustomerYAxisUpperLimit,
        ]);
      } else {
        churnedChart?.data.push([0, 0]);
      }
    } else {
      if (c1HealthScore.black_churn_c2_acv !== undefined && c1HealthScore.black_churn_c2_acv > 0) {
        churnedChart?.data.push([
          newCustomerYAxisUpperLimit - c1HealthScore.black_churn_c2_acv,
          newCustomerYAxisUpperLimit,
        ]);
      } else {
        churnedChart?.data.push([0, 0]);
      }
    }
  }
  return {
    labels: listOfLabel,
    datasets: [...barChartDataSet],
  } as unknown as ChartData<'bar'>;
});

/* Prepare Sankey Data Health Movement Chart */
function onHealthMovementFilterChange(value: string) {
  const selection = healthMovementChart.filterOptions.find((x) => x.value === value);
  if (!selection) {
    return;
  }
  dashboardStore.updateHealthMovementSelectedFilter(selection);
}

const healthMovementSelectedFilter = computed(() => {
  const currentFilter = dashboardStore.healthMovementSelectedFilter;
  if (!currentFilter) {
    return healthMovementChart.filterOptions[0];
  }
  return currentFilter;
});
const healthMovementDataset = computed(() => {
  const c1healthScore = c1HealthScoreStore.c1HealthScore;
  if (!c1healthScore) {
    return {
      labels: [],
      datasets: [],
    } as ChartData<'sankey'>;
  }
  const selectedFilter = healthMovementSelectedFilter.value.value;
  return healthMovementChart.getC1HealthScoreMovementChartAgainstFilter(c1healthScore, selectedFilter);
});

function onBarItemClick(parentIndex: number, childIndex: number) {
  const dataset = healthTrendChart.dashboardBarChartDataSet.value.datasets[parentIndex] as unknown as {
    label: string;
    ids: string[];
  };
  const label = dataset.label;
  const c1HealthScoreId = dataset.ids[childIndex];
  const c1HealthScore = c1HealthScoreStore.c1HealthScoreList.find((x) => x.id === c1HealthScoreId);
  if (!c1HealthScore?.date) {
    return;
  }
  let health: HealthColorEnum | null = null;
  switch (label.toLowerCase()) {
    case 'good':
      health = HealthColorEnum.Green;
      break;
    case 'fair':
      health = HealthColorEnum.Yellow;
      break;
    case 'bad':
      health = HealthColorEnum.Red;
      break;
    case 'inactive':
      health = HealthColorEnum.Grey;
      break;
    case 'churn':
      health = HealthColorEnum.Black;
      break;
  }

  try {
    const dateString = getDateString(new Date(c1HealthScore.date));
    const queryParam = {
      date: dateString,
      date_type: dateInfo.value?.type,
      u1: [activeU1Id.value],
      kpi: [KpiNameEnum.Zs],
    } as IQueryParam;
    if (health) {
      queryParam.health = health;
    }
    switch (dashboardStore.healthTrendSelectedFilter) {
      case 'customers':
        router.push({
          name: RouteName.Customers,
          query: { ...queryParam },
        });
        break;
      case 'users':
        router.push({
          name: RouteName.Users,
          query: { ...queryParam },
        });
        break;
    }
  } catch (e) {
    console.error('error', e);
  }
}

/*Prepare Line Chart Data Type Trend Chart*/
const typeTrendDataset = computed(() => {
  const c1HealthScores = c1HealthScoreStore.c1HealthScoreList;
  const currentFilter = selectedLineChartFilter.value;
  return customerTypeTrendChart.populateC1HealthScoreCustomerTrendChart(c1HealthScores, currentFilter?.name);
});

function kpiOptionStyle(kpi: Kpi) {
  let padding = 16;
  const style = { 'padding-left': `${padding}px`, 'font-weight': 'normal' };
  if (kpi.kpi_level !== 0 && kpi.kpi_level !== 1) {
    padding = padding * kpi.kpi_level;
    style['padding-left'] = `${padding}px`;
  }
  const hasChildren = kpiStore.orderedActiveKpiList.filter((x) => x.parent_kpi_id === kpi.id)?.length > 0;
  if (hasChildren || kpi.kpi_level === 0 || kpi.kpi_level === 1) {
    style['font-weight'] = 'bold';
  }
  return style;
}
</script>

<template>
  <Greeter :header-name="'Dashboard'" :check-route="false" @on-date-change="updateRouteOnDateChange" />
  <q-page class="tw-px-6 tw-py-2">
    <div class="tw-grid tw-grid-cols-1 lg:tw-mb-3 lg:tw-grid-cols-9">
      <Overview class="tw-h-60 lg:tw-col-span-5" />
      <LineChart
        class="tw-mt-3 tw-h-60 lg:tw-col-span-4 lg:tw-ml-3 lg:tw-mt-0"
        :heading="t('your_business_kpi_trend')"
        :data-set="typeTrendDataset"
        :is-loading="c1HealthScoreStore.isLoadingList || c1HealthScoreStore.isLoading"
        :hide-labels="true"
      >
        <template v-slot:dropdown>
          <q-select
            outlined
            input-debounce="0"
            v-model="selectedLineChartFilter"
            :options="kpiStore.orderedActiveKpiList"
            :dense="true"
            option-value="name"
            :option-label="(opt) => (Object(opt) === opt && 'name' in opt ? t(`kpi.${opt.name}`) : '')"
            class="tw-col-span-5"
          >
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps" :style="kpiOptionStyle(scope.opt)" class="tw-min-w-[25rem]">
                <q-item-section> {{ t(`kpi.${scope.opt.name}`) }} </q-item-section>
              </q-item>
            </template>
          </q-select>
        </template>
      </LineChart>

      <!--<CustomerTypeTrend class="tw-mt-3 lg:tw-mt-0 tw-h-60 lg:tw-col-span-4 lg:tw-ml-3" />-->
    </div>
    <div class="tw-grid tw-grid-cols-1 lg:tw-mb-3 lg:tw-grid-cols-9 lg:tw-divide-x lg:tw-divide-gray-100 lg:tw-shadow">
      <CustomerHealthTrend
        class="tw-mt-3 lg:tw-col-span-5 lg:tw-mt-0"
        :data-set="healthTrendChart.dashboardBarChartDataSet.value"
        :is-loading="c1HealthScoreStore.isLoadingList || c1HealthScoreStore.isLoading"
        @on-bar-chart-item-click="onBarItemClick"
      />
      <CustomerUserHealthD3 class="tw-mt-3 lg:tw-col-span-4 lg:tw-mt-0 lg:tw-rounded-bl-none lg:tw-rounded-tl-none" />
    </div>
    <div class="tw-grid tw-grid-cols-1 lg:tw-mb-3 lg:tw-grid-cols-9 lg:tw-divide-x lg:tw-divide-gray-100 lg:tw-shadow">
      <CustomerMovementTrend
        class="tw-mt-3 lg:tw-col-span-5 lg:tw-mt-0"
        :data-set="movementTrendDataset"
        :is-loading="c1HealthScoreStore.isLoadingCustomerMovementTrendList || c1HealthScoreStore.isLoading"
      />
      <CustomerHealthMovement
        :heading="t('health_movements')"
        :data-set="healthMovementDataset"
        @on-filter-change="onHealthMovementFilterChange"
        :selected-filter="healthMovementSelectedFilter"
        :show-pill-toggle="true"
        :is-loading="c1HealthScoreStore.isLoading"
        class="tw-h-84 tw-mt-3 lg:tw-col-span-4 lg:tw-mt-0 lg:tw-rounded-bl-none lg:tw-rounded-tl-none"
      />
    </div>
    <DashboardTable />
  </q-page>
</template>
