/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PlaybookC1Email } from '../model/';
// @ts-ignore
import { UpdatePlaybookC1EmailDto } from '../model/';
/**
 * PlaybookC1EmailApi - axios parameter creator
 * @export
 */
export const PlaybookC1EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Playbook Email
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-c1-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Playbook Email
         * @param {string} id 
         * @param {UpdatePlaybookC1EmailDto} updatePlaybookC1EmailDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailControllerUpdate: async (id: string, updatePlaybookC1EmailDto: UpdatePlaybookC1EmailDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookC1EmailControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookC1EmailDto' is not null or undefined
            assertParamExists('playbookC1EmailControllerUpdate', 'updatePlaybookC1EmailDto', updatePlaybookC1EmailDto)
            const localVarPath = `/api/playbook-c1-email/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookC1EmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookC1EmailApi - functional programming interface
 * @export
 */
export const PlaybookC1EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookC1EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Playbook Email
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC1EmailControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookC1Email>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC1EmailControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Playbook Email
         * @param {string} id 
         * @param {UpdatePlaybookC1EmailDto} updatePlaybookC1EmailDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC1EmailControllerUpdate(id: string, updatePlaybookC1EmailDto: UpdatePlaybookC1EmailDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookC1Email>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC1EmailControllerUpdate(id, updatePlaybookC1EmailDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookC1EmailApi - factory interface
 * @export
 */
export const PlaybookC1EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookC1EmailApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Playbook Email
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<PlaybookC1Email>> {
            return localVarFp.playbookC1EmailControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Playbook Email
         * @param {string} id 
         * @param {UpdatePlaybookC1EmailDto} updatePlaybookC1EmailDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailControllerUpdate(id: string, updatePlaybookC1EmailDto: UpdatePlaybookC1EmailDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookC1Email> {
            return localVarFp.playbookC1EmailControllerUpdate(id, updatePlaybookC1EmailDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookC1EmailControllerFindAll operation in PlaybookC1EmailApi.
 * @export
 * @interface PlaybookC1EmailApiPlaybookC1EmailControllerFindAllRequest
 */
export interface PlaybookC1EmailApiPlaybookC1EmailControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailApiPlaybookC1EmailControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookC1EmailControllerUpdate operation in PlaybookC1EmailApi.
 * @export
 * @interface PlaybookC1EmailApiPlaybookC1EmailControllerUpdateRequest
 */
export interface PlaybookC1EmailApiPlaybookC1EmailControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailApiPlaybookC1EmailControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookC1EmailDto}
     * @memberof PlaybookC1EmailApiPlaybookC1EmailControllerUpdate
     */
    readonly updatePlaybookC1EmailDto: UpdatePlaybookC1EmailDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailApiPlaybookC1EmailControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlaybookC1EmailApi - object-oriented interface
 * @export
 * @class PlaybookC1EmailApi
 * @extends {BaseAPI}
 */
export class PlaybookC1EmailApi extends BaseAPI {
    /**
     * 
     * @summary List of Playbook Email
     * @param {PlaybookC1EmailApiPlaybookC1EmailControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC1EmailApi
     */
    public playbookC1EmailControllerFindAll(requestParameters: PlaybookC1EmailApiPlaybookC1EmailControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookC1EmailApiFp(this.configuration).playbookC1EmailControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Playbook Email
     * @param {PlaybookC1EmailApiPlaybookC1EmailControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC1EmailApi
     */
    public playbookC1EmailControllerUpdate(requestParameters: PlaybookC1EmailApiPlaybookC1EmailControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookC1EmailApiFp(this.configuration).playbookC1EmailControllerUpdate(requestParameters.id, requestParameters.updatePlaybookC1EmailDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
