/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedU2HealthScoreDto } from '../model/';
// @ts-ignore
import { RoleBaseUserPaginatedDto } from '../model/';
// @ts-ignore
import { U2HealthScore } from '../model/';
// @ts-ignore
import { U2HealthScoreSingleDto } from '../model/';
// @ts-ignore
import { U2IdDateInfo } from '../model/';
// @ts-ignore
import { UpdateU2InfoDto } from '../model/';
// @ts-ignore
import { UserListFilterDto } from '../model/';
/**
 * U2HealthScoreApi - axios parameter creator
 * @export
 */
export const U2HealthScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download user list
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerDownloadUserInfoCsv: async (userListFilterDto: UserListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userListFilterDto' is not null or undefined
            assertParamExists('u2HealthScoresControllerDownloadUserInfoCsv', 'userListFilterDto', userListFilterDto)
            const localVarPath = `/api/u2-health-score/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U2-Health-Score against filter and sorting with pagination 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFilter: async (userListFilterDto: UserListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userListFilterDto' is not null or undefined
            assertParamExists('u2HealthScoresControllerFilter', 'userListFilterDto', userListFilterDto)
            const localVarPath = `/api/u2-health-score/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Version 2 - List of u2 health score against filter and sorting with pagination 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFilterV2: async (userListFilterDto: UserListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userListFilterDto' is not null or undefined
            assertParamExists('u2HealthScoresControllerFilterV2', 'userListFilterDto', userListFilterDto)
            const localVarPath = `/api/v2/u2-health-score/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U2-Health-Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u2-health-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U2-Health-Score by date range
         * @param {string} u2Id 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindAllByDateRange: async (u2Id: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2Id' is not null or undefined
            assertParamExists('u2HealthScoresControllerFindAllByDateRange', 'u2Id', u2Id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('u2HealthScoresControllerFindAllByDateRange', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('u2HealthScoresControllerFindAllByDateRange', 'dateType', dateType)
            const localVarPath = `/api/u2-health-score/date-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (u2Id !== undefined) {
                localVarQueryParameter['u2_id'] = u2Id;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary U2-Health-Score against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2HealthScoresControllerFindOne', 'id', id)
            const localVarPath = `/api/u2-health-score/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get U2 Health Score by U2Id and DateInfo
         * @param {U2IdDateInfo} u2IdDateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindOneByU2IdAndDayId: async (u2IdDateInfo: U2IdDateInfo, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2IdDateInfo' is not null or undefined
            assertParamExists('u2HealthScoresControllerFindOneByU2IdAndDayId', 'u2IdDateInfo', u2IdDateInfo)
            const localVarPath = `/api/u2-health-score/find-by-u2id-and-dayid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(u2IdDateInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary U2-Health-Score against Id v2 method
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindOneV2: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2HealthScoresControllerFindOneV2', 'id', id)
            const localVarPath = `/api/v2/u2-health-score/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update U2 info against id
         * @param {string} id 
         * @param {UpdateU2InfoDto} updateU2InfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerUpdateU2: async (id: string, updateU2InfoDto: UpdateU2InfoDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2HealthScoresControllerUpdateU2', 'id', id)
            // verify required parameter 'updateU2InfoDto' is not null or undefined
            assertParamExists('u2HealthScoresControllerUpdateU2', 'updateU2InfoDto', updateU2InfoDto)
            const localVarPath = `/api/u2-health-score/{id}/update-user-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateU2InfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2HealthScoreApi - functional programming interface
 * @export
 */
export const U2HealthScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2HealthScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download user list
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerDownloadUserInfoCsv(userListFilterDto: UserListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerDownloadUserInfoCsv(userListFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U2-Health-Score against filter and sorting with pagination 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerFilter(userListFilterDto: UserListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedU2HealthScoreDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerFilter(userListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Version 2 - List of u2 health score against filter and sorting with pagination 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerFilterV2(userListFilterDto: UserListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseUserPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerFilterV2(userListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U2-Health-Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2HealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U2-Health-Score by date range
         * @param {string} u2Id 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerFindAllByDateRange(u2Id: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2HealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerFindAllByDateRange(u2Id, date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary U2-Health-Score against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2HealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get U2 Health Score by U2Id and DateInfo
         * @param {U2IdDateInfo} u2IdDateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerFindOneByU2IdAndDayId(u2IdDateInfo: U2IdDateInfo, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2HealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerFindOneByU2IdAndDayId(u2IdDateInfo, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary U2-Health-Score against Id v2 method
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerFindOneV2(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2HealthScoreSingleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerFindOneV2(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update U2 info against id
         * @param {string} id 
         * @param {UpdateU2InfoDto} updateU2InfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2HealthScoresControllerUpdateU2(id: string, updateU2InfoDto: UpdateU2InfoDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2HealthScoreSingleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2HealthScoresControllerUpdateU2(id, updateU2InfoDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2HealthScoreApi - factory interface
 * @export
 */
export const U2HealthScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2HealthScoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Download user list
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerDownloadUserInfoCsv(userListFilterDto: UserListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.u2HealthScoresControllerDownloadUserInfoCsv(userListFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U2-Health-Score against filter and sorting with pagination 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFilter(userListFilterDto: UserListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedU2HealthScoreDto> {
            return localVarFp.u2HealthScoresControllerFilter(userListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Version 2 - List of u2 health score against filter and sorting with pagination 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFilterV2(userListFilterDto: UserListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBaseUserPaginatedDto> {
            return localVarFp.u2HealthScoresControllerFilterV2(userListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U2-Health-Score
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<U2HealthScore>> {
            return localVarFp.u2HealthScoresControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U2-Health-Score by date range
         * @param {string} u2Id 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindAllByDateRange(u2Id: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<U2HealthScore>> {
            return localVarFp.u2HealthScoresControllerFindAllByDateRange(u2Id, date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary U2-Health-Score against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<U2HealthScore> {
            return localVarFp.u2HealthScoresControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get U2 Health Score by U2Id and DateInfo
         * @param {U2IdDateInfo} u2IdDateInfo 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindOneByU2IdAndDayId(u2IdDateInfo: U2IdDateInfo, xTenantId?: string, options?: any): AxiosPromise<U2HealthScore> {
            return localVarFp.u2HealthScoresControllerFindOneByU2IdAndDayId(u2IdDateInfo, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary U2-Health-Score against Id v2 method
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerFindOneV2(id: string, xTenantId?: string, options?: any): AxiosPromise<U2HealthScoreSingleDto> {
            return localVarFp.u2HealthScoresControllerFindOneV2(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update U2 info against id
         * @param {string} id 
         * @param {UpdateU2InfoDto} updateU2InfoDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2HealthScoresControllerUpdateU2(id: string, updateU2InfoDto: UpdateU2InfoDto, xTenantId?: string, options?: any): AxiosPromise<U2HealthScoreSingleDto> {
            return localVarFp.u2HealthScoresControllerUpdateU2(id, updateU2InfoDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2HealthScoresControllerDownloadUserInfoCsv operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsvRequest
 */
export interface U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsvRequest {
    /**
     * 
     * @type {UserListFilterDto}
     * @memberof U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsv
     */
    readonly userListFilterDto: UserListFilterDto

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsv
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsv
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsv
     */
    readonly descending?: boolean
}

/**
 * Request parameters for u2HealthScoresControllerFilter operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerFilterRequest
 */
export interface U2HealthScoreApiU2HealthScoresControllerFilterRequest {
    /**
     * 
     * @type {UserListFilterDto}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly userListFilterDto: UserListFilterDto

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilter
     */
    readonly after?: string
}

/**
 * Request parameters for u2HealthScoresControllerFilterV2 operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerFilterV2Request
 */
export interface U2HealthScoreApiU2HealthScoresControllerFilterV2Request {
    /**
     * 
     * @type {UserListFilterDto}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly userListFilterDto: UserListFilterDto

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFilterV2
     */
    readonly after?: string
}

/**
 * Request parameters for u2HealthScoresControllerFindAll operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerFindAllRequest
 */
export interface U2HealthScoreApiU2HealthScoresControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2HealthScoresControllerFindAllByDateRange operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerFindAllByDateRangeRequest
 */
export interface U2HealthScoreApiU2HealthScoresControllerFindAllByDateRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindAllByDateRange
     */
    readonly u2Id: string

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindAllByDateRange
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindAllByDateRange
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindAllByDateRange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2HealthScoresControllerFindOne operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerFindOneRequest
 */
export interface U2HealthScoreApiU2HealthScoresControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2HealthScoresControllerFindOneByU2IdAndDayId operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerFindOneByU2IdAndDayIdRequest
 */
export interface U2HealthScoreApiU2HealthScoresControllerFindOneByU2IdAndDayIdRequest {
    /**
     * 
     * @type {U2IdDateInfo}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindOneByU2IdAndDayId
     */
    readonly u2IdDateInfo: U2IdDateInfo

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindOneByU2IdAndDayId
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2HealthScoresControllerFindOneV2 operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerFindOneV2Request
 */
export interface U2HealthScoreApiU2HealthScoresControllerFindOneV2Request {
    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindOneV2
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerFindOneV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2HealthScoresControllerUpdateU2 operation in U2HealthScoreApi.
 * @export
 * @interface U2HealthScoreApiU2HealthScoresControllerUpdateU2Request
 */
export interface U2HealthScoreApiU2HealthScoresControllerUpdateU2Request {
    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerUpdateU2
     */
    readonly id: string

    /**
     * 
     * @type {UpdateU2InfoDto}
     * @memberof U2HealthScoreApiU2HealthScoresControllerUpdateU2
     */
    readonly updateU2InfoDto: UpdateU2InfoDto

    /**
     * 
     * @type {string}
     * @memberof U2HealthScoreApiU2HealthScoresControllerUpdateU2
     */
    readonly xTenantId?: string
}

/**
 * U2HealthScoreApi - object-oriented interface
 * @export
 * @class U2HealthScoreApi
 * @extends {BaseAPI}
 */
export class U2HealthScoreApi extends BaseAPI {
    /**
     * 
     * @summary Download user list
     * @param {U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerDownloadUserInfoCsv(requestParameters: U2HealthScoreApiU2HealthScoresControllerDownloadUserInfoCsvRequest, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerDownloadUserInfoCsv(requestParameters.userListFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U2-Health-Score against filter and sorting with pagination 
     * @param {U2HealthScoreApiU2HealthScoresControllerFilterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerFilter(requestParameters: U2HealthScoreApiU2HealthScoresControllerFilterRequest, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerFilter(requestParameters.userListFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Version 2 - List of u2 health score against filter and sorting with pagination 
     * @param {U2HealthScoreApiU2HealthScoresControllerFilterV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerFilterV2(requestParameters: U2HealthScoreApiU2HealthScoresControllerFilterV2Request, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerFilterV2(requestParameters.userListFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U2-Health-Score
     * @param {U2HealthScoreApiU2HealthScoresControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerFindAll(requestParameters: U2HealthScoreApiU2HealthScoresControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U2-Health-Score by date range
     * @param {U2HealthScoreApiU2HealthScoresControllerFindAllByDateRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerFindAllByDateRange(requestParameters: U2HealthScoreApiU2HealthScoresControllerFindAllByDateRangeRequest, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerFindAllByDateRange(requestParameters.u2Id, requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary U2-Health-Score against Id
     * @param {U2HealthScoreApiU2HealthScoresControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerFindOne(requestParameters: U2HealthScoreApiU2HealthScoresControllerFindOneRequest, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get U2 Health Score by U2Id and DateInfo
     * @param {U2HealthScoreApiU2HealthScoresControllerFindOneByU2IdAndDayIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerFindOneByU2IdAndDayId(requestParameters: U2HealthScoreApiU2HealthScoresControllerFindOneByU2IdAndDayIdRequest, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerFindOneByU2IdAndDayId(requestParameters.u2IdDateInfo, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary U2-Health-Score against Id v2 method
     * @param {U2HealthScoreApiU2HealthScoresControllerFindOneV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerFindOneV2(requestParameters: U2HealthScoreApiU2HealthScoresControllerFindOneV2Request, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerFindOneV2(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update U2 info against id
     * @param {U2HealthScoreApiU2HealthScoresControllerUpdateU2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2HealthScoreApi
     */
    public u2HealthScoresControllerUpdateU2(requestParameters: U2HealthScoreApiU2HealthScoresControllerUpdateU2Request, options?: AxiosRequestConfig) {
        return U2HealthScoreApiFp(this.configuration).u2HealthScoresControllerUpdateU2(requestParameters.id, requestParameters.updateU2InfoDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
