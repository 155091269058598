<script setup lang="ts">
import { RouteName } from '@/router/route-name';

const tabs = [
  {
    label: 'User Profile',
    to: { name: RouteName.ManageUserProfile },
  },
  {
    label: 'Developer Settings',
    to: { name: RouteName.ManageDeveloperSettings },
  },
];
</script>

<template>
  <q-tabs
    active-color="primary"
    align="left"
    no-caps
    outside-arrows
    mobile-arrows
    class="tw-h-16 tw-max-w-full tw-bg-light-shade-1 tw-font-bold tw-text-dark-shade-2"
  >
    <q-route-tab v-for="(tab, idx) in tabs" :key="idx" :to="tab.to" :ripple="false">
      {{ tab.label }}
    </q-route-tab>
  </q-tabs>
</template>

<style scoped lang="postcss">
:deep(.q-tab) {
  padding: 0 0.125rem;
  margin-right: 3rem;
}

:deep(.q-tab__label) {
  @apply tw-text-lg tw-font-bold;
}
</style>
