/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C1ProductEnvironment } from '../model/';
// @ts-ignore
import { CreateC1ProductEnvironmentDto } from '../model/';
// @ts-ignore
import { UpdateC1ProductEnvironmentDto } from '../model/';
/**
 * C1ProductEnvironmentApi - axios parameter creator
 * @export
 */
export const C1ProductEnvironmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create C1 Product Environment
         * @param {CreateC1ProductEnvironmentDto} createC1ProductEnvironmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ProductEnvironmentsControllerCreate: async (createC1ProductEnvironmentDto: CreateC1ProductEnvironmentDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC1ProductEnvironmentDto' is not null or undefined
            assertParamExists('c1ProductEnvironmentsControllerCreate', 'createC1ProductEnvironmentDto', createC1ProductEnvironmentDto)
            const localVarPath = `/api/c1-product-environments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC1ProductEnvironmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List C1 Product Environments
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ProductEnvironmentsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c1-product-environments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update C1 Product Environment
         * @param {string} id 
         * @param {UpdateC1ProductEnvironmentDto} updateC1ProductEnvironmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ProductEnvironmentsControllerUpdate: async (id: string, updateC1ProductEnvironmentDto: UpdateC1ProductEnvironmentDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1ProductEnvironmentsControllerUpdate', 'id', id)
            // verify required parameter 'updateC1ProductEnvironmentDto' is not null or undefined
            assertParamExists('c1ProductEnvironmentsControllerUpdate', 'updateC1ProductEnvironmentDto', updateC1ProductEnvironmentDto)
            const localVarPath = `/api/c1-product-environments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC1ProductEnvironmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C1ProductEnvironmentApi - functional programming interface
 * @export
 */
export const C1ProductEnvironmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C1ProductEnvironmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create C1 Product Environment
         * @param {CreateC1ProductEnvironmentDto} createC1ProductEnvironmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ProductEnvironmentsControllerCreate(createC1ProductEnvironmentDto: CreateC1ProductEnvironmentDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1ProductEnvironment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ProductEnvironmentsControllerCreate(createC1ProductEnvironmentDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List C1 Product Environments
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ProductEnvironmentsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C1ProductEnvironment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ProductEnvironmentsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update C1 Product Environment
         * @param {string} id 
         * @param {UpdateC1ProductEnvironmentDto} updateC1ProductEnvironmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ProductEnvironmentsControllerUpdate(id: string, updateC1ProductEnvironmentDto: UpdateC1ProductEnvironmentDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1ProductEnvironment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ProductEnvironmentsControllerUpdate(id, updateC1ProductEnvironmentDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C1ProductEnvironmentApi - factory interface
 * @export
 */
export const C1ProductEnvironmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C1ProductEnvironmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create C1 Product Environment
         * @param {CreateC1ProductEnvironmentDto} createC1ProductEnvironmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ProductEnvironmentsControllerCreate(createC1ProductEnvironmentDto: CreateC1ProductEnvironmentDto, xTenantId?: string, options?: any): AxiosPromise<C1ProductEnvironment> {
            return localVarFp.c1ProductEnvironmentsControllerCreate(createC1ProductEnvironmentDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List C1 Product Environments
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ProductEnvironmentsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<C1ProductEnvironment>> {
            return localVarFp.c1ProductEnvironmentsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update C1 Product Environment
         * @param {string} id 
         * @param {UpdateC1ProductEnvironmentDto} updateC1ProductEnvironmentDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ProductEnvironmentsControllerUpdate(id: string, updateC1ProductEnvironmentDto: UpdateC1ProductEnvironmentDto, xTenantId?: string, options?: any): AxiosPromise<C1ProductEnvironment> {
            return localVarFp.c1ProductEnvironmentsControllerUpdate(id, updateC1ProductEnvironmentDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c1ProductEnvironmentsControllerCreate operation in C1ProductEnvironmentApi.
 * @export
 * @interface C1ProductEnvironmentApiC1ProductEnvironmentsControllerCreateRequest
 */
export interface C1ProductEnvironmentApiC1ProductEnvironmentsControllerCreateRequest {
    /**
     * 
     * @type {CreateC1ProductEnvironmentDto}
     * @memberof C1ProductEnvironmentApiC1ProductEnvironmentsControllerCreate
     */
    readonly createC1ProductEnvironmentDto: CreateC1ProductEnvironmentDto

    /**
     * 
     * @type {string}
     * @memberof C1ProductEnvironmentApiC1ProductEnvironmentsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1ProductEnvironmentsControllerFindAll operation in C1ProductEnvironmentApi.
 * @export
 * @interface C1ProductEnvironmentApiC1ProductEnvironmentsControllerFindAllRequest
 */
export interface C1ProductEnvironmentApiC1ProductEnvironmentsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C1ProductEnvironmentApiC1ProductEnvironmentsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1ProductEnvironmentsControllerUpdate operation in C1ProductEnvironmentApi.
 * @export
 * @interface C1ProductEnvironmentApiC1ProductEnvironmentsControllerUpdateRequest
 */
export interface C1ProductEnvironmentApiC1ProductEnvironmentsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof C1ProductEnvironmentApiC1ProductEnvironmentsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC1ProductEnvironmentDto}
     * @memberof C1ProductEnvironmentApiC1ProductEnvironmentsControllerUpdate
     */
    readonly updateC1ProductEnvironmentDto: UpdateC1ProductEnvironmentDto

    /**
     * 
     * @type {string}
     * @memberof C1ProductEnvironmentApiC1ProductEnvironmentsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * C1ProductEnvironmentApi - object-oriented interface
 * @export
 * @class C1ProductEnvironmentApi
 * @extends {BaseAPI}
 */
export class C1ProductEnvironmentApi extends BaseAPI {
    /**
     * 
     * @summary Create C1 Product Environment
     * @param {C1ProductEnvironmentApiC1ProductEnvironmentsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1ProductEnvironmentApi
     */
    public c1ProductEnvironmentsControllerCreate(requestParameters: C1ProductEnvironmentApiC1ProductEnvironmentsControllerCreateRequest, options?: AxiosRequestConfig) {
        return C1ProductEnvironmentApiFp(this.configuration).c1ProductEnvironmentsControllerCreate(requestParameters.createC1ProductEnvironmentDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List C1 Product Environments
     * @param {C1ProductEnvironmentApiC1ProductEnvironmentsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1ProductEnvironmentApi
     */
    public c1ProductEnvironmentsControllerFindAll(requestParameters: C1ProductEnvironmentApiC1ProductEnvironmentsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C1ProductEnvironmentApiFp(this.configuration).c1ProductEnvironmentsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update C1 Product Environment
     * @param {C1ProductEnvironmentApiC1ProductEnvironmentsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1ProductEnvironmentApi
     */
    public c1ProductEnvironmentsControllerUpdate(requestParameters: C1ProductEnvironmentApiC1ProductEnvironmentsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C1ProductEnvironmentApiFp(this.configuration).c1ProductEnvironmentsControllerUpdate(requestParameters.id, requestParameters.updateC1ProductEnvironmentDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
