/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePlaybookC1EmailStepDto } from '../model/';
// @ts-ignore
import { PlaybookC1EmailStep } from '../model/';
// @ts-ignore
import { UpdatePlaybookC1EmailStepDto } from '../model/';
/**
 * PlaybookC1EmailStepApi - axios parameter creator
 * @export
 */
export const PlaybookC1EmailStepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  Create a Playbook C1 Email Step
         * @param {CreatePlaybookC1EmailStepDto} createPlaybookC1EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerCreate: async (createPlaybookC1EmailStepDto: CreatePlaybookC1EmailStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlaybookC1EmailStepDto' is not null or undefined
            assertParamExists('playbookC1EmailStepControllerCreate', 'createPlaybookC1EmailStepDto', createPlaybookC1EmailStepDto)
            const localVarPath = `/api/playbook-c1-email-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlaybookC1EmailStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Playbook C1 Email Steps
         * @param {string} [xTenantId] 
         * @param {string} [playbookC1EmailId] Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerFindAll: async (xTenantId?: string, playbookC1EmailId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook-c1-email-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (playbookC1EmailId !== undefined) {
                localVarQueryParameter['playbook_c1_email_id'] = playbookC1EmailId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Playbook-C1-Email-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookC1EmailStepControllerRemove', 'id', id)
            const localVarPath = `/api/playbook-c1-email-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Playbook-C1-Email-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookC1EmailStepDto} updatePlaybookC1EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerUpdate: async (id: string, updatePlaybookC1EmailStepDto: UpdatePlaybookC1EmailStepDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookC1EmailStepControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookC1EmailStepDto' is not null or undefined
            assertParamExists('playbookC1EmailStepControllerUpdate', 'updatePlaybookC1EmailStepDto', updatePlaybookC1EmailStepDto)
            const localVarPath = `/api/playbook-c1-email-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookC1EmailStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookC1EmailStepApi - functional programming interface
 * @export
 */
export const PlaybookC1EmailStepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookC1EmailStepApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook C1 Email Step
         * @param {CreatePlaybookC1EmailStepDto} createPlaybookC1EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC1EmailStepControllerCreate(createPlaybookC1EmailStepDto: CreatePlaybookC1EmailStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookC1EmailStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC1EmailStepControllerCreate(createPlaybookC1EmailStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Playbook C1 Email Steps
         * @param {string} [xTenantId] 
         * @param {string} [playbookC1EmailId] Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC1EmailStepControllerFindAll(xTenantId?: string, playbookC1EmailId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaybookC1EmailStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC1EmailStepControllerFindAll(xTenantId, playbookC1EmailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Playbook-C1-Email-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC1EmailStepControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookC1EmailStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC1EmailStepControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Playbook-C1-Email-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookC1EmailStepDto} updatePlaybookC1EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookC1EmailStepControllerUpdate(id: string, updatePlaybookC1EmailStepDto: UpdatePlaybookC1EmailStepDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybookC1EmailStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookC1EmailStepControllerUpdate(id, updatePlaybookC1EmailStepDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookC1EmailStepApi - factory interface
 * @export
 */
export const PlaybookC1EmailStepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookC1EmailStepApiFp(configuration)
    return {
        /**
         * 
         * @summary  Create a Playbook C1 Email Step
         * @param {CreatePlaybookC1EmailStepDto} createPlaybookC1EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerCreate(createPlaybookC1EmailStepDto: CreatePlaybookC1EmailStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookC1EmailStep> {
            return localVarFp.playbookC1EmailStepControllerCreate(createPlaybookC1EmailStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Playbook C1 Email Steps
         * @param {string} [xTenantId] 
         * @param {string} [playbookC1EmailId] Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerFindAll(xTenantId?: string, playbookC1EmailId?: string, options?: any): AxiosPromise<Array<PlaybookC1EmailStep>> {
            return localVarFp.playbookC1EmailStepControllerFindAll(xTenantId, playbookC1EmailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Playbook-C1-Email-Step by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<PlaybookC1EmailStep> {
            return localVarFp.playbookC1EmailStepControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Playbook-C1-Email-Step by id
         * @param {string} id 
         * @param {UpdatePlaybookC1EmailStepDto} updatePlaybookC1EmailStepDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookC1EmailStepControllerUpdate(id: string, updatePlaybookC1EmailStepDto: UpdatePlaybookC1EmailStepDto, xTenantId?: string, options?: any): AxiosPromise<PlaybookC1EmailStep> {
            return localVarFp.playbookC1EmailStepControllerUpdate(id, updatePlaybookC1EmailStepDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookC1EmailStepControllerCreate operation in PlaybookC1EmailStepApi.
 * @export
 * @interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerCreateRequest
 */
export interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerCreateRequest {
    /**
     * 
     * @type {CreatePlaybookC1EmailStepDto}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerCreate
     */
    readonly createPlaybookC1EmailStepDto: CreatePlaybookC1EmailStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookC1EmailStepControllerFindAll operation in PlaybookC1EmailStepApi.
 * @export
 * @interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerFindAllRequest
 */
export interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional parameter, if present then all c1 email steps with same c1 email id will be returned else return all c1 email steps
     * @type {string}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerFindAll
     */
    readonly playbookC1EmailId?: string
}

/**
 * Request parameters for playbookC1EmailStepControllerRemove operation in PlaybookC1EmailStepApi.
 * @export
 * @interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerRemoveRequest
 */
export interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookC1EmailStepControllerUpdate operation in PlaybookC1EmailStepApi.
 * @export
 * @interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerUpdateRequest
 */
export interface PlaybookC1EmailStepApiPlaybookC1EmailStepControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookC1EmailStepDto}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerUpdate
     */
    readonly updatePlaybookC1EmailStepDto: UpdatePlaybookC1EmailStepDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookC1EmailStepApiPlaybookC1EmailStepControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * PlaybookC1EmailStepApi - object-oriented interface
 * @export
 * @class PlaybookC1EmailStepApi
 * @extends {BaseAPI}
 */
export class PlaybookC1EmailStepApi extends BaseAPI {
    /**
     * 
     * @summary  Create a Playbook C1 Email Step
     * @param {PlaybookC1EmailStepApiPlaybookC1EmailStepControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC1EmailStepApi
     */
    public playbookC1EmailStepControllerCreate(requestParameters: PlaybookC1EmailStepApiPlaybookC1EmailStepControllerCreateRequest, options?: AxiosRequestConfig) {
        return PlaybookC1EmailStepApiFp(this.configuration).playbookC1EmailStepControllerCreate(requestParameters.createPlaybookC1EmailStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Playbook C1 Email Steps
     * @param {PlaybookC1EmailStepApiPlaybookC1EmailStepControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC1EmailStepApi
     */
    public playbookC1EmailStepControllerFindAll(requestParameters: PlaybookC1EmailStepApiPlaybookC1EmailStepControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookC1EmailStepApiFp(this.configuration).playbookC1EmailStepControllerFindAll(requestParameters.xTenantId, requestParameters.playbookC1EmailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Playbook-C1-Email-Step by id
     * @param {PlaybookC1EmailStepApiPlaybookC1EmailStepControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC1EmailStepApi
     */
    public playbookC1EmailStepControllerRemove(requestParameters: PlaybookC1EmailStepApiPlaybookC1EmailStepControllerRemoveRequest, options?: AxiosRequestConfig) {
        return PlaybookC1EmailStepApiFp(this.configuration).playbookC1EmailStepControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Playbook-C1-Email-Step by id
     * @param {PlaybookC1EmailStepApiPlaybookC1EmailStepControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookC1EmailStepApi
     */
    public playbookC1EmailStepControllerUpdate(requestParameters: PlaybookC1EmailStepApiPlaybookC1EmailStepControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookC1EmailStepApiFp(this.configuration).playbookC1EmailStepControllerUpdate(requestParameters.id, requestParameters.updatePlaybookC1EmailStepDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
