import { authApi, c1Api, c1MasterApi, membershipApi, u1Api } from '@/api';
import {
  ActiveDay,
  C1,
  C1Master,
  C2Segment,
  CustomViewDto,
  DepartmentDto,
  Membership,
  RoleDto,
  U1,
  U1AccessRightEnum,
  U1Master,
} from '@/api/client';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useDateStore } from './date';
import { addYears, isDate, parseISO } from 'date-fns';

export const useAccountStore = defineStore('account', () => {
  const account = ref<U1Master>();
  const memberships = ref<Membership[]>([]);
  const activeC1Master = ref<C1Master>();
  const departments = ref<DepartmentDto[]>([]);
  const roles = ref<RoleDto[]>([]);
  const activeU1 = ref<U1>();
  const c1 = ref<C1>();

  const activeDays = ref<ActiveDay>({ 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false });

  const onboardingCompletedAt = ref<Date>(addYears(new Date(), -19));

  const customerCustomViews = ref<CustomViewDto[]>([]);
  const segmentCustomViews = ref<CustomViewDto[]>([]);
  const defaultCustomView = ref<string | undefined>();
  const selectedSegmentViewId = ref<string | undefined>();

  const defaultSegment = ref<C2Segment>();
  const yearStartMonthIndex = ref<number>(0);
  const quarterStartMonthIndex = ref<number>(0);
  const { iDate } = storeToRefs(useDateStore());
  const roleBaseIntegrationDate = ref();

  /** If current user is member && role base access management is enable */
  const isRoleBaseAccessEnabled = computed(() => {
    if (!iDate.value?.endDate) {
      // return null;
      return import.meta.env.VITE_FF_ROLE_BASED_ACCESS === '1';
    }
    return import.meta.env.VITE_FF_ROLE_BASED_ACCESS === '1' && roleBaseIntegrationDate.value < iDate.value?.endDate;
  });

  const isAdmin = computed(() => {
    return activeU1.value?.access_right === U1AccessRightEnum.Admin;
  });

  const fetchAccount = async () => {
    try {
      const { data } = await authApi.authControllerUserInfo();
      account.value = data;
    } catch (error) {
      account.value = undefined;
      throw error;
    }
  };

  const setAccount = (value: U1Master | undefined) => {
    account.value = value;
  };

  const fetchActiveC1Master = async (id: string) => {
    const { data } = await c1MasterApi.c1MasterControllerFindOne({ id });
    setActiveC1Master(data);
  };

  const setActiveC1Master = (value: C1Master | undefined) => {
    activeC1Master.value = value;
  };

  const fetchMemberships = async () => {
    const { data } = await membershipApi.membershipsControllerFindAll();
    memberships.value = data;
  };

  const fetchDepartments = async () => {
    const { data } = await authApi.authControllerDepartments();
    departments.value = data;
  };

  const fetchActiveU1 = async () => {
    const { data } = await u1Api.u1ControllerGetProfile();

    updateMonthStartingIndex(data.quarter_starting_month, data.year_starting_month);
    defaultSegment.value = data.default_segment;
    customerCustomViews.value = data?.customer_custom_view || [];
    segmentCustomViews.value = data?.segment_custom_view || [];
    defaultCustomView.value = data?.customer_custom_view_id;
    selectedSegmentViewId.value = data?.segment_custom_view_id;
    if (data?.onboarding_completed_at) {
      const parseDate = parseISO(data?.onboarding_completed_at);
      if (isDate(parseDate)) {
        onboardingCompletedAt.value = parseDate;
      }
    } else {
      const currentDate = new Date();
      onboardingCompletedAt.value = addYears(currentDate, -19);
    }

    activeDays.value = data.active_days;

    if (!data.role_base_at) {
      roleBaseIntegrationDate.value = new Date();
    } else {
      roleBaseIntegrationDate.value = new Date(data.role_base_at);
    }
    activeU1.value = data;
  };

  function updateMonthStartingIndex(quarterMonthIndex: number, yearMonthIndex: number) {
    quarterStartMonthIndex.value = quarterMonthIndex || 0;
    yearStartMonthIndex.value = yearMonthIndex || 0;
  }

  const fetchRoles = async () => {
    const { data } = await authApi.authControllerRoles();
    roles.value = data;
  };

  const fetchC1 = async () => {
    const { data } = await c1Api.c1ControllerFindOne();
    c1.value = data;
  };

  const imageUrl = computed(() => {
    if (!account.value?.image_url) {
      return;
    }
    const url = new URL(account.value.image_url);
    url.searchParams.append(new Date(account.value.updated_at).getTime().toString(), '');
    return url.toString();
  });

  const activeU1Id = computed(() => {
    return activeU1.value?.id || '';
  });

  const addCustomerView = (customViewDto: CustomViewDto) => {
    customerCustomViews.value.push(customViewDto);
  };

  const removeCustomView = (viewId: string) => {
    customerCustomViews.value = customerCustomViews.value.filter((x) => x.id !== viewId);
  };

  const updateCustomView = (customViewDto: CustomViewDto) => {
    customerCustomViews.value = customerCustomViews.value.map((x) => {
      if (x.id === customViewDto.id) {
        return customViewDto;
      }
      return x;
    });
    // customerCustomViews.value.forEach((x) => {
    // if (x.id === customViewDto.id) {
    // console.log('found and updating');
    // x = { ...customViewDto } as CustomViewDto;
    // }
    // });
  };

  const updateDefaultViewId = (id?: string) => {
    defaultCustomView.value = id;
  };

  const updateSelectedSegmentViewId = (id?: string) => {
    selectedSegmentViewId.value = id;
  };

  const updateActiveDaysInfo = (_activeDays: ActiveDay) => {
    activeDays.value = _activeDays;
  };

  return {
    account,
    c1,
    fetchAccount,
    setAccount,
    fetchActiveC1Master,
    setActiveC1Master,
    activeC1Master,
    memberships,
    fetchMemberships,
    departments,
    fetchDepartments,
    roles,
    fetchRoles,
    activeU1,
    fetchActiveU1,
    fetchC1,
    imageUrl,
    activeU1Id,
    defaultSegment,
    isRoleBaseAccessEnabled,
    isAdmin,
    customerCustomViews,
    segmentCustomViews,
    addCustomerView,
    updateCustomView,
    removeCustomView,
    defaultCustomView,
    selectedSegmentViewId,
    updateDefaultViewId,
    updateSelectedSegmentViewId,
    onboardingCompletedAt,
    activeDays,
    updateActiveDaysInfo,
    quarterStartMonthIndex,
    yearStartMonthIndex,
    updateMonthStartingIndex,
  };
});
