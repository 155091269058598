/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppSuspectC2 } from '../model/';
// @ts-ignore
import { AppWiseOrphanSuspectsFilterDto } from '../model/';
// @ts-ignore
import { PaginatedOrphanSuspectDto } from '../model/';
/**
 * AppSuspectC2Api - axios parameter creator
 * @export
 */
export const AppSuspectC2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Filter app wise orphan app suspects
         * @param {AppWiseOrphanSuspectsFilterDto} appWiseOrphanSuspectsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFilterAllAppWiseOrphanSuspects: async (appWiseOrphanSuspectsFilterDto: AppWiseOrphanSuspectsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appWiseOrphanSuspectsFilterDto' is not null or undefined
            assertParamExists('appSuspectC2ControllerFilterAllAppWiseOrphanSuspects', 'appWiseOrphanSuspectsFilterDto', appWiseOrphanSuspectsFilterDto)
            const localVarPath = `/api/app-suspect-c2/filter-app-wise-orphan-suspects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appWiseOrphanSuspectsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of AppSuspectC2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-suspect-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all orphan app suspects by app id
         * @param {string} appTypeId 
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindAllAppWiseOrphanSuspects: async (appTypeId: string, appId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appTypeId' is not null or undefined
            assertParamExists('appSuspectC2ControllerFindAllAppWiseOrphanSuspects', 'appTypeId', appTypeId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('appSuspectC2ControllerFindAllAppWiseOrphanSuspects', 'appId', appId)
            const localVarPath = `/api/app-suspect-c2/app-wise-orphan-suspects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appTypeId !== undefined) {
                localVarQueryParameter['app_type_id'] = appTypeId;
            }

            if (appId !== undefined) {
                localVarQueryParameter['app_id'] = appId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get matched app suspect c2
         * @param {string} appTypeId 
         * @param {string} c2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindMatchedC2: async (appTypeId: string, c2Id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appTypeId' is not null or undefined
            assertParamExists('appSuspectC2ControllerFindMatchedC2', 'appTypeId', appTypeId)
            // verify required parameter 'c2Id' is not null or undefined
            assertParamExists('appSuspectC2ControllerFindMatchedC2', 'c2Id', c2Id)
            const localVarPath = `/api/app-suspect-c2/get-matched-app-suspect-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appTypeId !== undefined) {
                localVarQueryParameter['app_type_id'] = appTypeId;
            }

            if (c2Id !== undefined) {
                localVarQueryParameter['c2_id'] = c2Id;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all unique peripheral app ids
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindUniquePeripheralAppIds: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-suspect-c2/unique-peripheral-app-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppSuspectC2Api - functional programming interface
 * @export
 */
export const AppSuspectC2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppSuspectC2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Filter app wise orphan app suspects
         * @param {AppWiseOrphanSuspectsFilterDto} appWiseOrphanSuspectsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2ControllerFilterAllAppWiseOrphanSuspects(appWiseOrphanSuspectsFilterDto: AppWiseOrphanSuspectsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedOrphanSuspectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2ControllerFilterAllAppWiseOrphanSuspects(appWiseOrphanSuspectsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of AppSuspectC2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2ControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppSuspectC2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2ControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all orphan app suspects by app id
         * @param {string} appTypeId 
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2ControllerFindAllAppWiseOrphanSuspects(appTypeId: string, appId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppSuspectC2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2ControllerFindAllAppWiseOrphanSuspects(appTypeId, appId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get matched app suspect c2
         * @param {string} appTypeId 
         * @param {string} c2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2ControllerFindMatchedC2(appTypeId: string, c2Id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppSuspectC2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2ControllerFindMatchedC2(appTypeId, c2Id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all unique peripheral app ids
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSuspectC2ControllerFindUniquePeripheralAppIds(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSuspectC2ControllerFindUniquePeripheralAppIds(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppSuspectC2Api - factory interface
 * @export
 */
export const AppSuspectC2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppSuspectC2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Filter app wise orphan app suspects
         * @param {AppWiseOrphanSuspectsFilterDto} appWiseOrphanSuspectsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFilterAllAppWiseOrphanSuspects(appWiseOrphanSuspectsFilterDto: AppWiseOrphanSuspectsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedOrphanSuspectDto> {
            return localVarFp.appSuspectC2ControllerFilterAllAppWiseOrphanSuspects(appWiseOrphanSuspectsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of AppSuspectC2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AppSuspectC2>> {
            return localVarFp.appSuspectC2ControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all orphan app suspects by app id
         * @param {string} appTypeId 
         * @param {string} appId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindAllAppWiseOrphanSuspects(appTypeId: string, appId: string, xTenantId?: string, options?: any): AxiosPromise<Array<AppSuspectC2>> {
            return localVarFp.appSuspectC2ControllerFindAllAppWiseOrphanSuspects(appTypeId, appId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get matched app suspect c2
         * @param {string} appTypeId 
         * @param {string} c2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindMatchedC2(appTypeId: string, c2Id: string, xTenantId?: string, options?: any): AxiosPromise<Array<AppSuspectC2>> {
            return localVarFp.appSuspectC2ControllerFindMatchedC2(appTypeId, c2Id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all unique peripheral app ids
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSuspectC2ControllerFindUniquePeripheralAppIds(xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.appSuspectC2ControllerFindUniquePeripheralAppIds(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appSuspectC2ControllerFilterAllAppWiseOrphanSuspects operation in AppSuspectC2Api.
 * @export
 * @interface AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspectsRequest
 */
export interface AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspectsRequest {
    /**
     * 
     * @type {AppWiseOrphanSuspectsFilterDto}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly appWiseOrphanSuspectsFilterDto: AppWiseOrphanSuspectsFilterDto

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspects
     */
    readonly after?: string
}

/**
 * Request parameters for appSuspectC2ControllerFindAll operation in AppSuspectC2Api.
 * @export
 * @interface AppSuspectC2ApiAppSuspectC2ControllerFindAllRequest
 */
export interface AppSuspectC2ApiAppSuspectC2ControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appSuspectC2ControllerFindAllAppWiseOrphanSuspects operation in AppSuspectC2Api.
 * @export
 * @interface AppSuspectC2ApiAppSuspectC2ControllerFindAllAppWiseOrphanSuspectsRequest
 */
export interface AppSuspectC2ApiAppSuspectC2ControllerFindAllAppWiseOrphanSuspectsRequest {
    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindAllAppWiseOrphanSuspects
     */
    readonly appTypeId: string

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindAllAppWiseOrphanSuspects
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindAllAppWiseOrphanSuspects
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appSuspectC2ControllerFindMatchedC2 operation in AppSuspectC2Api.
 * @export
 * @interface AppSuspectC2ApiAppSuspectC2ControllerFindMatchedC2Request
 */
export interface AppSuspectC2ApiAppSuspectC2ControllerFindMatchedC2Request {
    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindMatchedC2
     */
    readonly appTypeId: string

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindMatchedC2
     */
    readonly c2Id: string

    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindMatchedC2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appSuspectC2ControllerFindUniquePeripheralAppIds operation in AppSuspectC2Api.
 * @export
 * @interface AppSuspectC2ApiAppSuspectC2ControllerFindUniquePeripheralAppIdsRequest
 */
export interface AppSuspectC2ApiAppSuspectC2ControllerFindUniquePeripheralAppIdsRequest {
    /**
     * 
     * @type {string}
     * @memberof AppSuspectC2ApiAppSuspectC2ControllerFindUniquePeripheralAppIds
     */
    readonly xTenantId?: string
}

/**
 * AppSuspectC2Api - object-oriented interface
 * @export
 * @class AppSuspectC2Api
 * @extends {BaseAPI}
 */
export class AppSuspectC2Api extends BaseAPI {
    /**
     * 
     * @summary Filter app wise orphan app suspects
     * @param {AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2Api
     */
    public appSuspectC2ControllerFilterAllAppWiseOrphanSuspects(requestParameters: AppSuspectC2ApiAppSuspectC2ControllerFilterAllAppWiseOrphanSuspectsRequest, options?: AxiosRequestConfig) {
        return AppSuspectC2ApiFp(this.configuration).appSuspectC2ControllerFilterAllAppWiseOrphanSuspects(requestParameters.appWiseOrphanSuspectsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of AppSuspectC2
     * @param {AppSuspectC2ApiAppSuspectC2ControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2Api
     */
    public appSuspectC2ControllerFindAll(requestParameters: AppSuspectC2ApiAppSuspectC2ControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppSuspectC2ApiFp(this.configuration).appSuspectC2ControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all orphan app suspects by app id
     * @param {AppSuspectC2ApiAppSuspectC2ControllerFindAllAppWiseOrphanSuspectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2Api
     */
    public appSuspectC2ControllerFindAllAppWiseOrphanSuspects(requestParameters: AppSuspectC2ApiAppSuspectC2ControllerFindAllAppWiseOrphanSuspectsRequest, options?: AxiosRequestConfig) {
        return AppSuspectC2ApiFp(this.configuration).appSuspectC2ControllerFindAllAppWiseOrphanSuspects(requestParameters.appTypeId, requestParameters.appId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get matched app suspect c2
     * @param {AppSuspectC2ApiAppSuspectC2ControllerFindMatchedC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2Api
     */
    public appSuspectC2ControllerFindMatchedC2(requestParameters: AppSuspectC2ApiAppSuspectC2ControllerFindMatchedC2Request, options?: AxiosRequestConfig) {
        return AppSuspectC2ApiFp(this.configuration).appSuspectC2ControllerFindMatchedC2(requestParameters.appTypeId, requestParameters.c2Id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all unique peripheral app ids
     * @param {AppSuspectC2ApiAppSuspectC2ControllerFindUniquePeripheralAppIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSuspectC2Api
     */
    public appSuspectC2ControllerFindUniquePeripheralAppIds(requestParameters: AppSuspectC2ApiAppSuspectC2ControllerFindUniquePeripheralAppIdsRequest = {}, options?: AxiosRequestConfig) {
        return AppSuspectC2ApiFp(this.configuration).appSuspectC2ControllerFindUniquePeripheralAppIds(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
