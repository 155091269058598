<script setup lang="ts">
import { useAuth } from '@/composables/useAuth';
import { useTitle } from '@vueuse/core';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { axiosInstance } from './api';
import { useProbe } from './composables/useProbe';
import { RouteName } from './router/route-name';
import { useAccountStore } from './stores/account';
import { Layout } from './types/layout';
import TailwindIndicator from './components/TailwindIndicator.vue';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import axios from 'axios';

window.__ZAPSCALE_APP_VERSION__ = import.meta.env.VITE_APP_VERSION as string;

const router = useRouter();
const route = useRoute();
const { authGuard, isAuthenticated } = useAuth();
const accountStore = useAccountStore();
const probe = useProbe();

router.beforeResolve(async (to) => {
  if (to.meta.title) {
    useTitle(`${to.meta.title} | Zapscale`);
  } else {
    useTitle('Zapscale');
  }

  if (to.meta.skipAuth) return true;

  await authGuard(to);
});

const layout = computed(() => {
  return (route?.meta?.layout as string) || Layout.Default;
});

axiosInstance.interceptors.request.use(
  (req) => {
    req.headers ||= {};

    if (accountStore.activeC1Master) {
      req.headers['x-tenant-id'] = accountStore.activeC1Master.id;
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    isAuthenticated.value = null;

    // if (error.response.status !== 401) {
    // return Promise.reject(error);
    // }
    //
    // await router.replace({ name: RouteName.Login });

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (error.response.status !== 401) {
      return Promise.reject(error);
    } else {
      await router.replace({ name: RouteName.Login });
    }
  },
);

onMounted(() => {
  probe.init();
});
</script>

<template>
  <component :is="layout">
    <router-view />
  </component>
  <TailwindIndicator />
  <VueQueryDevtools />
</template>
