/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { U1Role } from '../model/';
/**
 * U1RoleApi - axios parameter creator
 * @export
 */
export const U1RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of U1-Roles
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1RolesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/u1-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a U1-Role by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1RolesControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u1RolesControllerFindOne', 'id', id)
            const localVarPath = `/api/u1-roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U1RoleApi - functional programming interface
 * @export
 */
export const U1RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U1RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of U1-Roles
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1RolesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U1Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1RolesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a U1-Role by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u1RolesControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u1RolesControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U1RoleApi - factory interface
 * @export
 */
export const U1RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U1RoleApiFp(configuration)
    return {
        /**
         * 
         * @summary List of U1-Roles
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1RolesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<U1Role>> {
            return localVarFp.u1RolesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a U1-Role by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u1RolesControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<U1Role> {
            return localVarFp.u1RolesControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u1RolesControllerFindAll operation in U1RoleApi.
 * @export
 * @interface U1RoleApiU1RolesControllerFindAllRequest
 */
export interface U1RoleApiU1RolesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof U1RoleApiU1RolesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u1RolesControllerFindOne operation in U1RoleApi.
 * @export
 * @interface U1RoleApiU1RolesControllerFindOneRequest
 */
export interface U1RoleApiU1RolesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof U1RoleApiU1RolesControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U1RoleApiU1RolesControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * U1RoleApi - object-oriented interface
 * @export
 * @class U1RoleApi
 * @extends {BaseAPI}
 */
export class U1RoleApi extends BaseAPI {
    /**
     * 
     * @summary List of U1-Roles
     * @param {U1RoleApiU1RolesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1RoleApi
     */
    public u1RolesControllerFindAll(requestParameters: U1RoleApiU1RolesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return U1RoleApiFp(this.configuration).u1RolesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a U1-Role by id
     * @param {U1RoleApiU1RolesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U1RoleApi
     */
    public u1RolesControllerFindOne(requestParameters: U1RoleApiU1RolesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return U1RoleApiFp(this.configuration).u1RolesControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
