<script setup lang="ts">
import { DateTypeEnum } from '@/api/client';
import noAggregationData from '@/assets/no-aggregation-data.svg';
import { MessageSchema } from '@/lib/i18n';
import { useDateStore } from '@/stores/date';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const { dateInfo } = storeToRefs(useDateStore());

const content = computed(() => {
  if (dateInfo.value?.type === DateTypeEnum.Day) {
    return t('no_aggregation_data.content_day');
  }
  return t('no_aggregation_data.content');
});
</script>
<template>
  <div
    class="tw-flex tw-h-4/5 tw-flex-col tw-items-center tw-justify-center tw-bg-light-shade-1 md:tw-min-h-[36rem] lg:tw-min-h-[30rem] xl:tw-min-h-[37rem] 2xl:tw-min-h-[46rem]"
  >
    <q-img :src="noAggregationData" class="tw-w-96 lg:tw-w-48 xl:tw-w-80 2xl:tw-w-96"></q-img>
    <p class="tw-pb-2 tw-pt-12 tw-text-4xl tw-font-bold tw-text-[#43454B]">{{ t('no_aggregation_data.title') }}</p>
    <span class="tw-break-word tw-whitespace-pre-wrap tw-text-center tw-text-xl tw-font-light tw-text-[#3A3A3C]">
      {{ content }}
    </span>
  </div>
</template>
