/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CampaignDocumentSchema } from '../model/';
/**
 * CampaignDocumentsApi - axios parameter creator
 * @export
 */
export const CampaignDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDocumentControllerDeleteDocument: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignDocumentControllerDeleteDocument', 'id', id)
            const localVarPath = `/api/campaign-documents/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDocumentControllerFetchAllDocuments: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign-documents/findall-campaign-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignDocumentsApi - functional programming interface
 * @export
 */
export const CampaignDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignDocumentControllerDeleteDocument(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignDocumentControllerDeleteDocument(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignDocumentControllerFetchAllDocuments(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignDocumentSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignDocumentControllerFetchAllDocuments(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignDocumentsApi - factory interface
 * @export
 */
export const CampaignDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDocumentControllerDeleteDocument(id: string, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.campaignDocumentControllerDeleteDocument(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDocumentControllerFetchAllDocuments(xTenantId?: string, options?: any): AxiosPromise<Array<CampaignDocumentSchema>> {
            return localVarFp.campaignDocumentControllerFetchAllDocuments(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for campaignDocumentControllerDeleteDocument operation in CampaignDocumentsApi.
 * @export
 * @interface CampaignDocumentsApiCampaignDocumentControllerDeleteDocumentRequest
 */
export interface CampaignDocumentsApiCampaignDocumentControllerDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignDocumentsApiCampaignDocumentControllerDeleteDocument
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CampaignDocumentsApiCampaignDocumentControllerDeleteDocument
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for campaignDocumentControllerFetchAllDocuments operation in CampaignDocumentsApi.
 * @export
 * @interface CampaignDocumentsApiCampaignDocumentControllerFetchAllDocumentsRequest
 */
export interface CampaignDocumentsApiCampaignDocumentControllerFetchAllDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignDocumentsApiCampaignDocumentControllerFetchAllDocuments
     */
    readonly xTenantId?: string
}

/**
 * CampaignDocumentsApi - object-oriented interface
 * @export
 * @class CampaignDocumentsApi
 * @extends {BaseAPI}
 */
export class CampaignDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {CampaignDocumentsApiCampaignDocumentControllerDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignDocumentsApi
     */
    public campaignDocumentControllerDeleteDocument(requestParameters: CampaignDocumentsApiCampaignDocumentControllerDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return CampaignDocumentsApiFp(this.configuration).campaignDocumentControllerDeleteDocument(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CampaignDocumentsApiCampaignDocumentControllerFetchAllDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignDocumentsApi
     */
    public campaignDocumentControllerFetchAllDocuments(requestParameters: CampaignDocumentsApiCampaignDocumentControllerFetchAllDocumentsRequest = {}, options?: AxiosRequestConfig) {
        return CampaignDocumentsApiFp(this.configuration).campaignDocumentControllerFetchAllDocuments(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
