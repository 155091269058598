/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CopyPlaybookDto } from '../model/';
// @ts-ignore
import { Playbook } from '../model/';
// @ts-ignore
import { UpdatePlaybookDto } from '../model/';
// @ts-ignore
import { UpdatePlaybookV2Dto } from '../model/';
// @ts-ignore
import { UpdatePlaybookV2StatusDto } from '../model/';
/**
 * PlaybookApi - axios parameter creator
 * @export
 */
export const PlaybookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy current playbook with new trigger condition, task, message, audience and email
         * @param {CopyPlaybookDto} copyPlaybookDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerCopy: async (copyPlaybookDto: CopyPlaybookDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyPlaybookDto' is not null or undefined
            assertParamExists('playbookControllerCopy', 'copyPlaybookDto', copyPlaybookDto)
            const localVarPath = `/api/playbook/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyPlaybookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of playbooks
         * @param {string} [xTenantId] 
         * @param {string} [kpiId] Optional parameter kpi_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerFindAll: async (xTenantId?: string, kpiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playbook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpiId !== undefined) {
                localVarQueryParameter['kpi_id'] = kpiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Playbook by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookControllerFindOne', 'id', id)
            const localVarPath = `/api/playbook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Playbook v2 by id with nested objects
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerFindOneV2: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookControllerFindOneV2', 'id', id)
            const localVarPath = `/api/playbook/v2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a sub playbook item
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerSoftDeletePlayBookItem: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookControllerSoftDeletePlayBookItem', 'id', id)
            const localVarPath = `/api/playbook/softdelete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Playbook by id
         * @param {string} id 
         * @param {UpdatePlaybookDto} updatePlaybookDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerUpdate: async (id: string, updatePlaybookDto: UpdatePlaybookDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookControllerUpdate', 'id', id)
            // verify required parameter 'updatePlaybookDto' is not null or undefined
            assertParamExists('playbookControllerUpdate', 'updatePlaybookDto', updatePlaybookDto)
            const localVarPath = `/api/playbook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Playbook by id
         * @param {string} id 
         * @param {UpdatePlaybookV2Dto} updatePlaybookV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerUpdateV2: async (id: string, updatePlaybookV2Dto: UpdatePlaybookV2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookControllerUpdateV2', 'id', id)
            // verify required parameter 'updatePlaybookV2Dto' is not null or undefined
            assertParamExists('playbookControllerUpdateV2', 'updatePlaybookV2Dto', updatePlaybookV2Dto)
            const localVarPath = `/api/playbook/v2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookV2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Playbook status by id
         * @param {string} id 
         * @param {UpdatePlaybookV2StatusDto} updatePlaybookV2StatusDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerUpdateV2Status: async (id: string, updatePlaybookV2StatusDto: UpdatePlaybookV2StatusDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('playbookControllerUpdateV2Status', 'id', id)
            // verify required parameter 'updatePlaybookV2StatusDto' is not null or undefined
            assertParamExists('playbookControllerUpdateV2Status', 'updatePlaybookV2StatusDto', updatePlaybookV2StatusDto)
            const localVarPath = `/api/playbook/v2-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaybookV2StatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaybookApi - functional programming interface
 * @export
 */
export const PlaybookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaybookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy current playbook with new trigger condition, task, message, audience and email
         * @param {CopyPlaybookDto} copyPlaybookDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerCopy(copyPlaybookDto: CopyPlaybookDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerCopy(copyPlaybookDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of playbooks
         * @param {string} [xTenantId] 
         * @param {string} [kpiId] Optional parameter kpi_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerFindAll(xTenantId?: string, kpiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Playbook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerFindAll(xTenantId, kpiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Playbook by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Playbook v2 by id with nested objects
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerFindOneV2(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerFindOneV2(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a sub playbook item
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerSoftDeletePlayBookItem(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerSoftDeletePlayBookItem(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Playbook by id
         * @param {string} id 
         * @param {UpdatePlaybookDto} updatePlaybookDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerUpdate(id: string, updatePlaybookDto: UpdatePlaybookDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerUpdate(id, updatePlaybookDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Playbook by id
         * @param {string} id 
         * @param {UpdatePlaybookV2Dto} updatePlaybookV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerUpdateV2(id: string, updatePlaybookV2Dto: UpdatePlaybookV2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerUpdateV2(id, updatePlaybookV2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Playbook status by id
         * @param {string} id 
         * @param {UpdatePlaybookV2StatusDto} updatePlaybookV2StatusDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playbookControllerUpdateV2Status(id: string, updatePlaybookV2StatusDto: UpdatePlaybookV2StatusDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Playbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playbookControllerUpdateV2Status(id, updatePlaybookV2StatusDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaybookApi - factory interface
 * @export
 */
export const PlaybookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaybookApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy current playbook with new trigger condition, task, message, audience and email
         * @param {CopyPlaybookDto} copyPlaybookDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerCopy(copyPlaybookDto: CopyPlaybookDto, xTenantId?: string, options?: any): AxiosPromise<Playbook> {
            return localVarFp.playbookControllerCopy(copyPlaybookDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of playbooks
         * @param {string} [xTenantId] 
         * @param {string} [kpiId] Optional parameter kpi_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerFindAll(xTenantId?: string, kpiId?: string, options?: any): AxiosPromise<Array<Playbook>> {
            return localVarFp.playbookControllerFindAll(xTenantId, kpiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Playbook by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Playbook> {
            return localVarFp.playbookControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Playbook v2 by id with nested objects
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerFindOneV2(id: string, xTenantId?: string, options?: any): AxiosPromise<Playbook> {
            return localVarFp.playbookControllerFindOneV2(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a sub playbook item
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerSoftDeletePlayBookItem(id: string, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.playbookControllerSoftDeletePlayBookItem(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Playbook by id
         * @param {string} id 
         * @param {UpdatePlaybookDto} updatePlaybookDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerUpdate(id: string, updatePlaybookDto: UpdatePlaybookDto, xTenantId?: string, options?: any): AxiosPromise<Playbook> {
            return localVarFp.playbookControllerUpdate(id, updatePlaybookDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Playbook by id
         * @param {string} id 
         * @param {UpdatePlaybookV2Dto} updatePlaybookV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerUpdateV2(id: string, updatePlaybookV2Dto: UpdatePlaybookV2Dto, xTenantId?: string, options?: any): AxiosPromise<Playbook> {
            return localVarFp.playbookControllerUpdateV2(id, updatePlaybookV2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Playbook status by id
         * @param {string} id 
         * @param {UpdatePlaybookV2StatusDto} updatePlaybookV2StatusDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbookControllerUpdateV2Status(id: string, updatePlaybookV2StatusDto: UpdatePlaybookV2StatusDto, xTenantId?: string, options?: any): AxiosPromise<Playbook> {
            return localVarFp.playbookControllerUpdateV2Status(id, updatePlaybookV2StatusDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for playbookControllerCopy operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerCopyRequest
 */
export interface PlaybookApiPlaybookControllerCopyRequest {
    /**
     * 
     * @type {CopyPlaybookDto}
     * @memberof PlaybookApiPlaybookControllerCopy
     */
    readonly copyPlaybookDto: CopyPlaybookDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerCopy
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookControllerFindAll operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerFindAllRequest
 */
export interface PlaybookApiPlaybookControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional parameter kpi_id
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerFindAll
     */
    readonly kpiId?: string
}

/**
 * Request parameters for playbookControllerFindOne operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerFindOneRequest
 */
export interface PlaybookApiPlaybookControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookControllerFindOneV2 operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerFindOneV2Request
 */
export interface PlaybookApiPlaybookControllerFindOneV2Request {
    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerFindOneV2
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerFindOneV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookControllerSoftDeletePlayBookItem operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerSoftDeletePlayBookItemRequest
 */
export interface PlaybookApiPlaybookControllerSoftDeletePlayBookItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerSoftDeletePlayBookItem
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerSoftDeletePlayBookItem
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookControllerUpdate operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerUpdateRequest
 */
export interface PlaybookApiPlaybookControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookDto}
     * @memberof PlaybookApiPlaybookControllerUpdate
     */
    readonly updatePlaybookDto: UpdatePlaybookDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookControllerUpdateV2 operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerUpdateV2Request
 */
export interface PlaybookApiPlaybookControllerUpdateV2Request {
    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerUpdateV2
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookV2Dto}
     * @memberof PlaybookApiPlaybookControllerUpdateV2
     */
    readonly updatePlaybookV2Dto: UpdatePlaybookV2Dto

    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerUpdateV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for playbookControllerUpdateV2Status operation in PlaybookApi.
 * @export
 * @interface PlaybookApiPlaybookControllerUpdateV2StatusRequest
 */
export interface PlaybookApiPlaybookControllerUpdateV2StatusRequest {
    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerUpdateV2Status
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePlaybookV2StatusDto}
     * @memberof PlaybookApiPlaybookControllerUpdateV2Status
     */
    readonly updatePlaybookV2StatusDto: UpdatePlaybookV2StatusDto

    /**
     * 
     * @type {string}
     * @memberof PlaybookApiPlaybookControllerUpdateV2Status
     */
    readonly xTenantId?: string
}

/**
 * PlaybookApi - object-oriented interface
 * @export
 * @class PlaybookApi
 * @extends {BaseAPI}
 */
export class PlaybookApi extends BaseAPI {
    /**
     * 
     * @summary Copy current playbook with new trigger condition, task, message, audience and email
     * @param {PlaybookApiPlaybookControllerCopyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerCopy(requestParameters: PlaybookApiPlaybookControllerCopyRequest, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerCopy(requestParameters.copyPlaybookDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of playbooks
     * @param {PlaybookApiPlaybookControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerFindAll(requestParameters: PlaybookApiPlaybookControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerFindAll(requestParameters.xTenantId, requestParameters.kpiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Playbook by id
     * @param {PlaybookApiPlaybookControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerFindOne(requestParameters: PlaybookApiPlaybookControllerFindOneRequest, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Playbook v2 by id with nested objects
     * @param {PlaybookApiPlaybookControllerFindOneV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerFindOneV2(requestParameters: PlaybookApiPlaybookControllerFindOneV2Request, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerFindOneV2(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a sub playbook item
     * @param {PlaybookApiPlaybookControllerSoftDeletePlayBookItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerSoftDeletePlayBookItem(requestParameters: PlaybookApiPlaybookControllerSoftDeletePlayBookItemRequest, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerSoftDeletePlayBookItem(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Playbook by id
     * @param {PlaybookApiPlaybookControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerUpdate(requestParameters: PlaybookApiPlaybookControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerUpdate(requestParameters.id, requestParameters.updatePlaybookDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Playbook by id
     * @param {PlaybookApiPlaybookControllerUpdateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerUpdateV2(requestParameters: PlaybookApiPlaybookControllerUpdateV2Request, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerUpdateV2(requestParameters.id, requestParameters.updatePlaybookV2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Playbook status by id
     * @param {PlaybookApiPlaybookControllerUpdateV2StatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybookApi
     */
    public playbookControllerUpdateV2Status(requestParameters: PlaybookApiPlaybookControllerUpdateV2StatusRequest, options?: AxiosRequestConfig) {
        return PlaybookApiFp(this.configuration).playbookControllerUpdateV2Status(requestParameters.id, requestParameters.updatePlaybookV2StatusDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
