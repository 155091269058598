<script setup lang="ts">
import { computed, ref } from 'vue';
import { phCheck } from '@/phosphor-icons';
import { IDate } from '@/types/date.type';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/account';
import { useCustomDateUtil } from '@/composables/useCustomDateUtil';

const props = defineProps<{ years: number[]; currentYear: number; selectedDate: IDate }>();
const selection = ref<IDate>(props.selectedDate);
const emits = defineEmits(['onDateSelection']);

const { onboardingCompletedAt, yearStartMonthIndex } = storeToRefs(useAccountStore());
const { getIDateYearObj } = useDateFormatter();
const { getCustomYearBoundaries } = useCustomDateUtil();

const monthNumber = computed(() => {
  return yearStartMonthIndex.value + 1;
});

const yearList = computed(() => {
  const currentDate = new Date();
  const items = [] as IDate[];
  const startYear = Math.min(...props.years);
  let startDateN = new Date(startYear, yearStartMonthIndex.value, 1);
  if (onboardingCompletedAt.value && onboardingCompletedAt.value.getFullYear() === startYear) {
    startDateN = onboardingCompletedAt.value;
  }
  const values = getCustomYearBoundaries(startDateN, currentDate, monthNumber.value);

  for (let i = 0; i < values.length; i++) {
    const item = getIDateYearObj(values[i]);
    if (currentDate < item.endDate || startDateN > item.endDate) {
      continue;
    }
    items.push(item);
  }
  return items;
});

function onItemClick(year: IDate) {
  selection.value = year;
  emits('onDateSelection', year);
}

function isSelected(year: IDate) {
  const selected = selection.value;
  return selected?.endDate?.toString() === year.endDate?.toString();
}
</script>
<template>
  <div>
    <q-scroll-area style="height: 14rem" class="tw-h-full tw-w-full">
      <q-list>
        <q-item
          v-for="year in yearList"
          :key="year.startDate.toString()"
          clickable
          :active="isSelected(year)"
          @click="onItemClick(year)"
          active-class="my-menu-link"
        >
          <q-item-section>
            {{ year.text }}
          </q-item-section>
          <q-item-section side v-if="isSelected(year)">
            <q-icon :name="phCheck" class="tw-text-light-shade-1" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
  </div>
</template>
<style scoped lang="postcss">
.my-menu-link {
  @apply tw-bg-primary-dark tw-text-light-shade-1;
}
q-item__section--side {
  @apply tw-text-light-shade-1;
}
</style>
