<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    text: string;
    primary?: boolean;
  }>(),
  {
    primary: true,
  },
);

const cssColor = computed(() => {
  return props.primary ? 'tw-text-dark-shade-2' : 'tw-text-dark-shade-1';
});

const cssFontSize = computed(() => {
  return props.primary ? 'tw-text-2xl' : 'tw-text-xl';
});
</script>

<template>
  <div class="tw-mb-4 tw-font-bold" :class="[cssColor, cssFontSize]">
    <slot>{{ text }}</slot>
  </div>
</template>
