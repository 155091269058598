<script setup lang="ts">
import { computed } from 'vue';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { useRouter } from 'vue-router';
import { RouteName } from '@/router/route-name';
import { HealthColorEnum, SourceEnum } from '@/api/client';
import { useKpiStore } from '@/stores/kpi';
import { useDateStore } from '@/stores/date';
import { IQueryParam } from '@/types/query.type';
import { useAccountStore } from '@/stores/account';
import { storeToRefs } from 'pinia';
import { useDateFormatter } from '@/composables/useDateFormatter';

const props = defineProps<{
  source: SourceEnum;
  kpiId?: string;
  id?: string;
  name?: string;
  u1Id?: string;
  u1Name?: string;
  date: string;
  green: number;
  yellow: number;
  red: number;
  grey: number;
  black: number;
  greenCount: number;
  yellowCount: number;
  redCount: number;
  greyCount: number;
  blackCount: number;
  isClickable: boolean;
}>();
const { formatNumberRoundOfDecimal } = useAmountFormatter();
const router = useRouter();
const { kpiList } = useKpiStore();

const _dateStore = useDateStore();
const { iDate } = storeToRefs(_dateStore);

const _accountStore = useAccountStore();
const { activeU1Id, isRoleBaseAccessEnabled, isAdmin, defaultSegment } = storeToRefs(_accountStore);

const { getDateString } = useDateFormatter();

const kpiInfo = computed(() => {
  return kpiList.find((kpi) => kpi.id === props.kpiId);
});

const greenPercentage = computed(() => {
  return +formatNumberRoundOfDecimal(props.green);
});

const redPercentage = computed(() => {
  return +formatNumberRoundOfDecimal(props.red);
});

const yellowPercentage = computed(() => {
  return +formatNumberRoundOfDecimal(props.yellow);
});

const greyPercentage = computed(() => {
  return +formatNumberRoundOfDecimal(props.grey);
});

const blackPercentage = computed(() => {
  return +formatNumberRoundOfDecimal(props.black);
});

const width = (value?: number) => {
  if (value === undefined || value === null) {
    return '';
  }
  return `width: ${value}%`;
};

function onGreenUserClick() {
  navigateToUser(HealthColorEnum.Green);
}
function onRedUserClick() {
  navigateToUser(HealthColorEnum.Red);
}
function onGreyUserClick() {
  navigateToUser(HealthColorEnum.Grey);
}
function onYellowUserClick() {
  navigateToUser(HealthColorEnum.Yellow);
}
function onBlackUserClick() {
  navigateToUser(HealthColorEnum.Black);
}

function navigateToUser(health: HealthColorEnum) {
  if (!props.isClickable) {
    return;
  }

  let routeName = '';
  try {
    const date = new Date(props.date);
    const dateString = getDateString(date);
    const dateType = iDate.value?.type;
    if (!dateType) {
      return;
    }

    let query = { date: dateString, date_type: dateType } as IQueryParam;

    if (kpiInfo.value && health) {
      query.health = health;
      query.kpi = [kpiInfo.value?.name];
    }

    if (props.u1Id) {
      query.u1 = [...(query?.u1 || []), props.u1Id];
    }

    switch (props.source) {
      case SourceEnum.C1HealthScore:
        query = getCustomerQuery(query);
        routeName = RouteName.Customers;
        break;
      case SourceEnum.C2HealthScore:
        query = getUserQuery(query);
        routeName = RouteName.Users;
        break;
    }

    if (isRoleBaseAccessEnabled.value && !isAdmin.value && defaultSegment.value) {
      query.c2_segment = [defaultSegment.value?.id];
    } else {
      query.u1 = [activeU1Id.value];
    }

    if (routeName) {
      router.push({
        name: routeName,
        query: { ...query },
      });
    }
  } catch (e) {
    console.error('td bar graph', e);
  }
}

function getCustomerQuery(query: IQueryParam) {
  return query;
}

function getUserQuery(query: IQueryParam) {
  if (props.id) {
    query.c2 = [props.id];
  }
  return query;
}
</script>

<template>
  <div class="tw-flex tw-h-5 tw-w-36 tw-flex-nowrap tw-content-start tw-items-stretch">
    <div
      class="tw-cursor-pointer tw-flex-col tw-content-end"
      :style="width(greyPercentage)"
      v-if="greyPercentage > 0"
      @click="onGreyUserClick"
    >
      <div class="tw-h-3 tw-w-full tw-bg-grey-shade-1"></div>
      <div class="block tw-text-right tw-text-10">{{ greyPercentage }}%</div>
      <!-- <CommentToolTip :comment="props.greyCount.toString()" /> -->
    </div>

    <div
      class="tw-cursor-pointer tw-flex-col tw-content-end"
      :style="width(redPercentage)"
      v-if="redPercentage > 0"
      @click="onRedUserClick"
    >
      <div class="tw-h-3 tw-w-full tw-bg-data-viz-semantic-red-graph"></div>
      <div class="block tw-text-right tw-text-10">{{ redPercentage }}%</div>
      <!-- <CommentToolTip :comment="props.redCount.toString()" /> -->
    </div>
    <div
      class="tw-cursor-pointer tw-flex-col tw-content-end"
      :style="width(yellowPercentage)"
      v-if="yellowPercentage > 0"
      @click="onYellowUserClick"
    >
      <div class="tw-h-3 tw-w-full tw-bg-data-viz-semantic-yellow-graph"></div>
      <div class="block tw-text-right tw-text-10">{{ yellowPercentage }}%</div>
      <!-- <CommentToolTip :comment="props.yellowCount.toString()" /> -->
    </div>
    <div
      class="tw-cursor-pointer tw-flex-col tw-content-end"
      :style="width(greenPercentage)"
      v-if="greenPercentage > 0"
      @click="onGreenUserClick"
    >
      <div class="tw-h-3 tw-w-full tw-bg-data-viz-semantic-green-graph"></div>
      <div class="block tw-text-right tw-text-10">{{ greenPercentage }}%</div>
      <!-- <CommentToolTip :comment="props.greenCount.toString()" /> -->
    </div>
    <div
      class="tw-cursor-pointer tw-flex-col tw-content-end"
      :style="width(blackPercentage)"
      v-if="blackPercentage > 0"
      @click="onBlackUserClick"
    >
      <div class="tw-h-3 tw-w-full tw-bg-black"></div>
      <div class="block tw-text-right tw-text-10">{{ blackPercentage }}%</div>
      <!-- <CommentToolTip :comment="props.blackCount.toString()" /> -->
    </div>
  </div>
</template>
