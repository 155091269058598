/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppType } from '../model/';
// @ts-ignore
import { CreateAppTypeDto } from '../model/';
// @ts-ignore
import { UpdateAppTypeDto } from '../model/';
/**
 * AppTypeApi - axios parameter creator
 * @export
 */
export const AppTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an App Type
         * @param {CreateAppTypeDto} createAppTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerCreate: async (createAppTypeDto: CreateAppTypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppTypeDto' is not null or undefined
            assertParamExists('appTypesControllerCreate', 'createAppTypeDto', createAppTypeDto)
            const localVarPath = `/api/app-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List App Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an App Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appTypesControllerFindOne', 'id', id)
            const localVarPath = `/api/app-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an App Type
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appTypesControllerRemove', 'id', id)
            const localVarPath = `/api/app-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an App Type
         * @param {string} id 
         * @param {UpdateAppTypeDto} updateAppTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerUpdate: async (id: string, updateAppTypeDto: UpdateAppTypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appTypesControllerUpdate', 'id', id)
            // verify required parameter 'updateAppTypeDto' is not null or undefined
            assertParamExists('appTypesControllerUpdate', 'updateAppTypeDto', updateAppTypeDto)
            const localVarPath = `/api/app-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppTypeApi - functional programming interface
 * @export
 */
export const AppTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an App Type
         * @param {CreateAppTypeDto} createAppTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appTypesControllerCreate(createAppTypeDto: CreateAppTypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appTypesControllerCreate(createAppTypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List App Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appTypesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appTypesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an App Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appTypesControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appTypesControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an App Type
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appTypesControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appTypesControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an App Type
         * @param {string} id 
         * @param {UpdateAppTypeDto} updateAppTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appTypesControllerUpdate(id: string, updateAppTypeDto: UpdateAppTypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appTypesControllerUpdate(id, updateAppTypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppTypeApi - factory interface
 * @export
 */
export const AppTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an App Type
         * @param {CreateAppTypeDto} createAppTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerCreate(createAppTypeDto: CreateAppTypeDto, xTenantId?: string, options?: any): AxiosPromise<AppType> {
            return localVarFp.appTypesControllerCreate(createAppTypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List App Types
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<AppType>> {
            return localVarFp.appTypesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an App Type by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<AppType> {
            return localVarFp.appTypesControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an App Type
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.appTypesControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an App Type
         * @param {string} id 
         * @param {UpdateAppTypeDto} updateAppTypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTypesControllerUpdate(id: string, updateAppTypeDto: UpdateAppTypeDto, xTenantId?: string, options?: any): AxiosPromise<AppType> {
            return localVarFp.appTypesControllerUpdate(id, updateAppTypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appTypesControllerCreate operation in AppTypeApi.
 * @export
 * @interface AppTypeApiAppTypesControllerCreateRequest
 */
export interface AppTypeApiAppTypesControllerCreateRequest {
    /**
     * 
     * @type {CreateAppTypeDto}
     * @memberof AppTypeApiAppTypesControllerCreate
     */
    readonly createAppTypeDto: CreateAppTypeDto

    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appTypesControllerFindAll operation in AppTypeApi.
 * @export
 * @interface AppTypeApiAppTypesControllerFindAllRequest
 */
export interface AppTypeApiAppTypesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appTypesControllerFindOne operation in AppTypeApi.
 * @export
 * @interface AppTypeApiAppTypesControllerFindOneRequest
 */
export interface AppTypeApiAppTypesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appTypesControllerRemove operation in AppTypeApi.
 * @export
 * @interface AppTypeApiAppTypesControllerRemoveRequest
 */
export interface AppTypeApiAppTypesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for appTypesControllerUpdate operation in AppTypeApi.
 * @export
 * @interface AppTypeApiAppTypesControllerUpdateRequest
 */
export interface AppTypeApiAppTypesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAppTypeDto}
     * @memberof AppTypeApiAppTypesControllerUpdate
     */
    readonly updateAppTypeDto: UpdateAppTypeDto

    /**
     * 
     * @type {string}
     * @memberof AppTypeApiAppTypesControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * AppTypeApi - object-oriented interface
 * @export
 * @class AppTypeApi
 * @extends {BaseAPI}
 */
export class AppTypeApi extends BaseAPI {
    /**
     * 
     * @summary Create an App Type
     * @param {AppTypeApiAppTypesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTypeApi
     */
    public appTypesControllerCreate(requestParameters: AppTypeApiAppTypesControllerCreateRequest, options?: AxiosRequestConfig) {
        return AppTypeApiFp(this.configuration).appTypesControllerCreate(requestParameters.createAppTypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List App Types
     * @param {AppTypeApiAppTypesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTypeApi
     */
    public appTypesControllerFindAll(requestParameters: AppTypeApiAppTypesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AppTypeApiFp(this.configuration).appTypesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an App Type by Id
     * @param {AppTypeApiAppTypesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTypeApi
     */
    public appTypesControllerFindOne(requestParameters: AppTypeApiAppTypesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return AppTypeApiFp(this.configuration).appTypesControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an App Type
     * @param {AppTypeApiAppTypesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTypeApi
     */
    public appTypesControllerRemove(requestParameters: AppTypeApiAppTypesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return AppTypeApiFp(this.configuration).appTypesControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an App Type
     * @param {AppTypeApiAppTypesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTypeApi
     */
    public appTypesControllerUpdate(requestParameters: AppTypeApiAppTypesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return AppTypeApiFp(this.configuration).appTypesControllerUpdate(requestParameters.id, requestParameters.updateAppTypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
