export enum LineType {
  OVERALL_SCORE = 'Overall Score',
  TRIAL = 'Trial',
  ONBOARDING = 'Onboarding',
  STEADY = 'Steady',
}

export interface LineChartDataSet {
  label: LineType;
  value: number;
  color: string;
  isDashBorder: boolean;
  month: string;
}

export class LineChartType {
  static readonly TRIAL = new LineChartType('trial', '#00A2FF');
  static readonly ONBOARDING = new LineChartType('onboarding', '#8670FF');
  static readonly STEADY = new LineChartType('steady', '#B7C634');
  static readonly CUSTOM_C2_TYPE = new LineChartType('custom_c2_type', '#006DA3');
  static readonly DECISION_MAKER = new LineChartType('decision_maker', '#00A2FF');
  static readonly INFLUENCER = new LineChartType('influencer', '#8670FF');
  static readonly NORMAL_USER = new LineChartType('normal_user', '#B7C634');
  static readonly OVERALL_SCORE = new LineChartType('overall_score', '#006DA3');

  // private to disallow creating other instances of this type
  private constructor(private readonly name: string, public readonly color: string) {}

  toString() {
    return this.name;
  }
}

//TODO remove this section once all dependencies are removed
export interface ILineChartFilterOptions {
  label: string;
  value: string;
  isParent?: boolean | undefined;
}
