/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C1EmailIntegrationUpdateDto } from '../model/';
// @ts-ignore
import { C1Master } from '../model/';
/**
 * C1MasterApi - axios parameter creator
 * @export
 */
export const C1MasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerDisableTrackerClassic: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1MasterControllerDisableTrackerClassic', 'id', id)
            const localVarPath = `/api/c1-master/{id}/disable-tracker-classic`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all C1Master associated with the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c1-master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1MasterControllerFindOne', 'id', id)
            const localVarPath = `/api/c1-master/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} peripheralEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerIsEmailAvailable: async (peripheralEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'peripheralEmail' is not null or undefined
            assertParamExists('c1MasterControllerIsEmailAvailable', 'peripheralEmail', peripheralEmail)
            const localVarPath = `/api/c1-master/is-email-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (peripheralEmail !== undefined) {
                localVarQueryParameter['peripheral_email'] = peripheralEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerSkipOnboarding: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1MasterControllerSkipOnboarding', 'id', id)
            const localVarPath = `/api/c1-master/{id}/skip-onboarding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {C1EmailIntegrationUpdateDto} c1EmailIntegrationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerUpdatePeripheralEmail: async (id: string, c1EmailIntegrationUpdateDto: C1EmailIntegrationUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1MasterControllerUpdatePeripheralEmail', 'id', id)
            // verify required parameter 'c1EmailIntegrationUpdateDto' is not null or undefined
            assertParamExists('c1MasterControllerUpdatePeripheralEmail', 'c1EmailIntegrationUpdateDto', c1EmailIntegrationUpdateDto)
            const localVarPath = `/api/c1-master/{id}/update-peripheral-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c1EmailIntegrationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C1MasterApi - functional programming interface
 * @export
 */
export const C1MasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C1MasterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1MasterControllerDisableTrackerClassic(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1Master>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1MasterControllerDisableTrackerClassic(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all C1Master associated with the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1MasterControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C1Master>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1MasterControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1MasterControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1Master>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1MasterControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} peripheralEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1MasterControllerIsEmailAvailable(peripheralEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1MasterControllerIsEmailAvailable(peripheralEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1MasterControllerSkipOnboarding(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1Master>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1MasterControllerSkipOnboarding(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {C1EmailIntegrationUpdateDto} c1EmailIntegrationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1MasterControllerUpdatePeripheralEmail(id: string, c1EmailIntegrationUpdateDto: C1EmailIntegrationUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1Master>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1MasterControllerUpdatePeripheralEmail(id, c1EmailIntegrationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C1MasterApi - factory interface
 * @export
 */
export const C1MasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C1MasterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerDisableTrackerClassic(id: string, options?: any): AxiosPromise<C1Master> {
            return localVarFp.c1MasterControllerDisableTrackerClassic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all C1Master associated with the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerFindAll(options?: any): AxiosPromise<Array<C1Master>> {
            return localVarFp.c1MasterControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerFindOne(id: string, options?: any): AxiosPromise<C1Master> {
            return localVarFp.c1MasterControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} peripheralEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerIsEmailAvailable(peripheralEmail: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.c1MasterControllerIsEmailAvailable(peripheralEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerSkipOnboarding(id: string, options?: any): AxiosPromise<C1Master> {
            return localVarFp.c1MasterControllerSkipOnboarding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {C1EmailIntegrationUpdateDto} c1EmailIntegrationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1MasterControllerUpdatePeripheralEmail(id: string, c1EmailIntegrationUpdateDto: C1EmailIntegrationUpdateDto, options?: any): AxiosPromise<C1Master> {
            return localVarFp.c1MasterControllerUpdatePeripheralEmail(id, c1EmailIntegrationUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c1MasterControllerDisableTrackerClassic operation in C1MasterApi.
 * @export
 * @interface C1MasterApiC1MasterControllerDisableTrackerClassicRequest
 */
export interface C1MasterApiC1MasterControllerDisableTrackerClassicRequest {
    /**
     * 
     * @type {string}
     * @memberof C1MasterApiC1MasterControllerDisableTrackerClassic
     */
    readonly id: string
}

/**
 * Request parameters for c1MasterControllerFindOne operation in C1MasterApi.
 * @export
 * @interface C1MasterApiC1MasterControllerFindOneRequest
 */
export interface C1MasterApiC1MasterControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C1MasterApiC1MasterControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for c1MasterControllerIsEmailAvailable operation in C1MasterApi.
 * @export
 * @interface C1MasterApiC1MasterControllerIsEmailAvailableRequest
 */
export interface C1MasterApiC1MasterControllerIsEmailAvailableRequest {
    /**
     * 
     * @type {string}
     * @memberof C1MasterApiC1MasterControllerIsEmailAvailable
     */
    readonly peripheralEmail: string
}

/**
 * Request parameters for c1MasterControllerSkipOnboarding operation in C1MasterApi.
 * @export
 * @interface C1MasterApiC1MasterControllerSkipOnboardingRequest
 */
export interface C1MasterApiC1MasterControllerSkipOnboardingRequest {
    /**
     * 
     * @type {string}
     * @memberof C1MasterApiC1MasterControllerSkipOnboarding
     */
    readonly id: string
}

/**
 * Request parameters for c1MasterControllerUpdatePeripheralEmail operation in C1MasterApi.
 * @export
 * @interface C1MasterApiC1MasterControllerUpdatePeripheralEmailRequest
 */
export interface C1MasterApiC1MasterControllerUpdatePeripheralEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof C1MasterApiC1MasterControllerUpdatePeripheralEmail
     */
    readonly id: string

    /**
     * 
     * @type {C1EmailIntegrationUpdateDto}
     * @memberof C1MasterApiC1MasterControllerUpdatePeripheralEmail
     */
    readonly c1EmailIntegrationUpdateDto: C1EmailIntegrationUpdateDto
}

/**
 * C1MasterApi - object-oriented interface
 * @export
 * @class C1MasterApi
 * @extends {BaseAPI}
 */
export class C1MasterApi extends BaseAPI {
    /**
     * 
     * @param {C1MasterApiC1MasterControllerDisableTrackerClassicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1MasterApi
     */
    public c1MasterControllerDisableTrackerClassic(requestParameters: C1MasterApiC1MasterControllerDisableTrackerClassicRequest, options?: AxiosRequestConfig) {
        return C1MasterApiFp(this.configuration).c1MasterControllerDisableTrackerClassic(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all C1Master associated with the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1MasterApi
     */
    public c1MasterControllerFindAll(options?: AxiosRequestConfig) {
        return C1MasterApiFp(this.configuration).c1MasterControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {C1MasterApiC1MasterControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1MasterApi
     */
    public c1MasterControllerFindOne(requestParameters: C1MasterApiC1MasterControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C1MasterApiFp(this.configuration).c1MasterControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {C1MasterApiC1MasterControllerIsEmailAvailableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1MasterApi
     */
    public c1MasterControllerIsEmailAvailable(requestParameters: C1MasterApiC1MasterControllerIsEmailAvailableRequest, options?: AxiosRequestConfig) {
        return C1MasterApiFp(this.configuration).c1MasterControllerIsEmailAvailable(requestParameters.peripheralEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {C1MasterApiC1MasterControllerSkipOnboardingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1MasterApi
     */
    public c1MasterControllerSkipOnboarding(requestParameters: C1MasterApiC1MasterControllerSkipOnboardingRequest, options?: AxiosRequestConfig) {
        return C1MasterApiFp(this.configuration).c1MasterControllerSkipOnboarding(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {C1MasterApiC1MasterControllerUpdatePeripheralEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1MasterApi
     */
    public c1MasterControllerUpdatePeripheralEmail(requestParameters: C1MasterApiC1MasterControllerUpdatePeripheralEmailRequest, options?: AxiosRequestConfig) {
        return C1MasterApiFp(this.configuration).c1MasterControllerUpdatePeripheralEmail(requestParameters.id, requestParameters.c1EmailIntegrationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}
