<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import AppDrawer from '@/components/AppDrawer.vue';
import { useAccountStore } from '@/stores/account';
import ManageNavbar from '@/components/Manage/ManageNavbar.vue';

const accountStore = useAccountStore();
</script>

<template>
  <q-layout view="hHh LpR lFr">
    <app-header />

    <app-drawer v-if="accountStore.activeC1Master?.onboarding_completed_at" />

    <q-page-container class="tw-relative tw-pb-6">
      <div
        class="tw-fixed tw-left-0 tw-right-0 tw-z-50 tw-flex tw-flex-shrink-0 tw-items-center tw-border-b tw-bg-white tw-px-5 lg:tw-left-20"
      >
        <manage-navbar class="tw-px-8" />
      </div>
      <div class="tw-pt-14">
        <slot />
      </div>
    </q-page-container>
  </q-layout>
</template>
