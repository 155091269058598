/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { U2TypeHealthScore } from '../model/';
/**
 * U2TypeHealthScoresApi - axios parameter creator
 * @export
 */
export const U2TypeHealthScoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all U2-Type-Health-Score against particular date
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindAll: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindAll', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindAll', 'dateType', dateType)
            const localVarPath = `/api/u2-type-health-scores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of U2-Type-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindAllByDateRange: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindAllByDateRange', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindAllByDateRange', 'dateType', dateType)
            const localVarPath = `/api/u2-type-health-scores/date-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get U2-Type-Health-Score-against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindOne', 'id', id)
            const localVarPath = `/api/u2-type-health-scores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get U2-Type-Health-Score-against U2TypeId and C2TypeId
         * @param {string} u2TypeId 
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindOneByDate: async (u2TypeId: string, c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'u2TypeId' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindOneByDate', 'u2TypeId', u2TypeId)
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindOneByDate', 'c2TypeId', c2TypeId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindOneByDate', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('u2TypeHealthScoresControllerFindOneByDate', 'dateType', dateType)
            const localVarPath = `/api/u2-type-health-scores/{u2TypeId}/by-type-id`
                .replace(`{${"u2TypeId"}}`, encodeURIComponent(String(u2TypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2_type_id'] = c2TypeId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * U2TypeHealthScoresApi - functional programming interface
 * @export
 */
export const U2TypeHealthScoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = U2TypeHealthScoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of all U2-Type-Health-Score against particular date
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2TypeHealthScoresControllerFindAll(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2TypeHealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2TypeHealthScoresControllerFindAll(date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of U2-Type-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2TypeHealthScoresControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2TypeHealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2TypeHealthScoresControllerFindAllByDateRange(date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get U2-Type-Health-Score-against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2TypeHealthScoresControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2TypeHealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2TypeHealthScoresControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get U2-Type-Health-Score-against U2TypeId and C2TypeId
         * @param {string} u2TypeId 
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async u2TypeHealthScoresControllerFindOneByDate(u2TypeId: string, c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2TypeHealthScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.u2TypeHealthScoresControllerFindOneByDate(u2TypeId, c2TypeId, date, dateType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * U2TypeHealthScoresApi - factory interface
 * @export
 */
export const U2TypeHealthScoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = U2TypeHealthScoresApiFp(configuration)
    return {
        /**
         * 
         * @summary List of all U2-Type-Health-Score against particular date
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindAll(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<U2TypeHealthScore>> {
            return localVarFp.u2TypeHealthScoresControllerFindAll(date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of U2-Type-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<Array<U2TypeHealthScore>> {
            return localVarFp.u2TypeHealthScoresControllerFindAllByDateRange(date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get U2-Type-Health-Score-against Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<U2TypeHealthScore> {
            return localVarFp.u2TypeHealthScoresControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get U2-Type-Health-Score-against U2TypeId and C2TypeId
         * @param {string} u2TypeId 
         * @param {string} c2TypeId 
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        u2TypeHealthScoresControllerFindOneByDate(u2TypeId: string, c2TypeId: string, date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', xTenantId?: string, options?: any): AxiosPromise<U2TypeHealthScore> {
            return localVarFp.u2TypeHealthScoresControllerFindOneByDate(u2TypeId, c2TypeId, date, dateType, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for u2TypeHealthScoresControllerFindAll operation in U2TypeHealthScoresApi.
 * @export
 * @interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllRequest
 */
export interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAll
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAll
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2TypeHealthScoresControllerFindAllByDateRange operation in U2TypeHealthScoresApi.
 * @export
 * @interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllByDateRangeRequest
 */
export interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllByDateRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllByDateRange
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllByDateRange
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllByDateRange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2TypeHealthScoresControllerFindOne operation in U2TypeHealthScoresApi.
 * @export
 * @interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneRequest
 */
export interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for u2TypeHealthScoresControllerFindOneByDate operation in U2TypeHealthScoresApi.
 * @export
 * @interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDateRequest
 */
export interface U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDateRequest {
    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDate
     */
    readonly u2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDate
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDate
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDate
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDate
     */
    readonly xTenantId?: string
}

/**
 * U2TypeHealthScoresApi - object-oriented interface
 * @export
 * @class U2TypeHealthScoresApi
 * @extends {BaseAPI}
 */
export class U2TypeHealthScoresApi extends BaseAPI {
    /**
     * 
     * @summary List of all U2-Type-Health-Score against particular date
     * @param {U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2TypeHealthScoresApi
     */
    public u2TypeHealthScoresControllerFindAll(requestParameters: U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllRequest, options?: AxiosRequestConfig) {
        return U2TypeHealthScoresApiFp(this.configuration).u2TypeHealthScoresControllerFindAll(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of U2-Type-Health-Score by date range
     * @param {U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllByDateRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2TypeHealthScoresApi
     */
    public u2TypeHealthScoresControllerFindAllByDateRange(requestParameters: U2TypeHealthScoresApiU2TypeHealthScoresControllerFindAllByDateRangeRequest, options?: AxiosRequestConfig) {
        return U2TypeHealthScoresApiFp(this.configuration).u2TypeHealthScoresControllerFindAllByDateRange(requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get U2-Type-Health-Score-against Id
     * @param {U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2TypeHealthScoresApi
     */
    public u2TypeHealthScoresControllerFindOne(requestParameters: U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneRequest, options?: AxiosRequestConfig) {
        return U2TypeHealthScoresApiFp(this.configuration).u2TypeHealthScoresControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get U2-Type-Health-Score-against U2TypeId and C2TypeId
     * @param {U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof U2TypeHealthScoresApi
     */
    public u2TypeHealthScoresControllerFindOneByDate(requestParameters: U2TypeHealthScoresApiU2TypeHealthScoresControllerFindOneByDateRequest, options?: AxiosRequestConfig) {
        return U2TypeHealthScoresApiFp(this.configuration).u2TypeHealthScoresControllerFindOneByDate(requestParameters.u2TypeId, requestParameters.c2TypeId, requestParameters.date, requestParameters.dateType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
